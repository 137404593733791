import React from 'react';
import { Button, Dialog } from '@mui/material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';

type BatchTasksInfoModalDialogProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    taskInfo: Record<string, string>[];
};

export const BatchTasksInfoModalDialog = ({
    open,
    setOpen,
    taskInfo,
}: BatchTasksInfoModalDialogProps) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitleWithClose onClose={handleClose} id="modalTitle">
                Batch Task Information
            </DialogTitleWithClose>
            <table style={{ margin: '10px' }}>
                {taskInfo.map(i => (
                    <tr>
                        <td>{i.key}</td>
                        <td>{i.value}</td>
                    </tr>
                ))}
            </table>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '0 10px 10px 0',
                    gap: '10px',
                }}
            >
                <Button size="medium" onClick={handleClose}>
                    Close
                </Button>
            </div>
        </Dialog>
    );
};
