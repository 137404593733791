import ExcelJS, { Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import moment from 'moment';
import { client } from '~/ApolloClientWrapper';
import { LogoBase64 } from '~/components/Logo/LogoBase64';
import { displayBirthday, displayDateLocale } from '~/helpers';
import {
    FetchChatMessagesForMessageCenterDocument,
    FetchChatMessagesForMessageCenterQuery,
    FetchChatRoomsForMessageCenterQuery,
    FetchUsersForStaffPageQuery,
    OrderByDirectionEnum,
} from '~/schemaTypes';
import { formatContentRange, formatSheet } from './MessageCenterExcelTemplate';

const TABLE_ELEMENT_OFFSET = 1;
const WF_LOGO_OFFSET = 5;
const CHAT_MESSAGE_DAYS = 90;

const addWFLogo = (ws: Worksheet, imageId: number) => {
    ws.addImage(imageId, {
        tl: { col: 0, row: 0 },
        ext: { width: 350, height: 70 },
        editAs: 'absolute',
    });
    ws.getCell(1, 1).value = 'Wildflower Health';
};

const createMessageCenterExportSheet = async (
    workbook: Workbook,
    imageId: number,
    chatRoomData: FetchChatRoomsForMessageCenterQuery,
    userData: FetchUsersForStaffPageQuery,
) => {
    const messageCenterExportSheet = workbook.addWorksheet('Message Center Export');
    addWFLogo(messageCenterExportSheet, imageId);
    const filterDate = moment().subtract(CHAT_MESSAGE_DAYS, 'days').toISOString();
    const chatMessageQuery = async (): Promise<FetchChatMessagesForMessageCenterQuery> => {
        const res = await client
            .query({
                query: FetchChatMessagesForMessageCenterDocument,
                variables: {
                    input: {
                        filter: {
                            createdAfter: filterDate,
                        },
                        orderBy: {
                            order: OrderByDirectionEnum.Desc,
                            field: 'createdAt',
                        },
                    },
                },
            })
            .then(d => d.data);
        return res;
    };
    const messageCenterExportColumns = [
        {
            name: 'chatRoomId',
        },
        {
            name: 'messageId',
        },
        {
            name: 'timestampSent',
        },
        {
            name: 'messageCopy',
        },
        {
            name: 'messageAuthor',
        },
        {
            name: 'firstName',
        },
        {
            name: 'lastName',
        },
        {
            name: 'DOB',
        },
        {
            name: 'phoneNumber',
        },
        {
            name: 'zipCode',
        },
        {
            name: 'email',
        },
    ];

    const chatMessageData = (await chatMessageQuery())?.chatMessagesV2.results;
    const messageCenterExportRows = (
        await Promise.all(
            chatMessageData
                .filter(async message => {
                    return message?.id && message?.chatRoomId;
                })
                .map(async message => {
                    const chatRoom = chatRoomData?.chatRoomList?.results.find(
                        chatRoom => chatRoom.id === message.chatRoomId,
                    );
                    if (!chatRoom) return [];
                    const patientData = chatRoom.patient;
                    const senderName = message.sentByPatient
                        ? patientData?.fullName
                        : userData.getUsersByOrg.find(u => u.id === message.senderId)?.name ||
                          'Unknown';
                    const rowData = [
                        message.chatRoomId,
                        message.id,
                        message.createdAt,
                        message?.text,
                        senderName,
                        patientData?.firstName,
                        patientData?.lastName,
                        displayBirthday({ isoDateStr: patientData?.birthDate }),
                        patientData?.phoneNumber,
                        patientData?.mailingAddress?.code,
                        patientData?.email,
                    ];
                    return rowData;
                }),
        )
    ).filter(rowData => rowData.length);

    messageCenterExportSheet.addTable({
        name: 'MessageCenterExportTable',
        ref: `A${WF_LOGO_OFFSET}`,
        columns: messageCenterExportColumns,
        rows: messageCenterExportRows.length ? messageCenterExportRows : [[]],
    });
    formatSheet(messageCenterExportSheet);
    formatContentRange(
        messageCenterExportSheet,
        WF_LOGO_OFFSET + TABLE_ELEMENT_OFFSET,
        messageCenterExportSheet.rowCount,
        1,
        messageCenterExportSheet.columnCount,
    );
};

export const getMessageCenterWorkbook = async (
    chatRoomData: FetchChatRoomsForMessageCenterQuery,
    userData: FetchUsersForStaffPageQuery,
): Promise<void> => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Wildflower Health';
    const workbookDate = new Date();
    workbook.created = workbookDate;
    workbook.modified = workbookDate;
    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 0,
            visibility: 'visible',
        },
    ];
    const imageId = workbook.addImage({
        base64: LogoBase64,
        extension: 'jpeg',
    });

    await createMessageCenterExportSheet(workbook, imageId, chatRoomData, userData);

    // Save workbook
    workbook.xlsx.writeBuffer().then(data => {
        const fileDate = displayDateLocale({
            isoDateStr: new Date().toISOString(),
            format: 'MM-DD-YYYY',
        });
        const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `messageCenterExport_${fileDate}.xlsx`);
    });
};
