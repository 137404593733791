import React from 'react';
import { RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { motion, AnimatePresence } from 'framer-motion';
import { ExpandMore } from '@mui/icons-material';

interface MergeSectionProps {
    title: string;
    expanded: boolean;
    onToggle: () => void;
    groupSelection: 'source' | 'target' | 'custom' | 'merge' | 'donotmerge';
    onGroupSelectionChange: (
        value: 'source' | 'target' | 'custom' | 'merge' | 'donotmerge',
    ) => void;
    children: React.ReactNode;
    groupType?: 'default' | 'merge';
}

const sectionVariants = {
    open: {
        opacity: 1,
        height: 'auto',
        transition: {
            staggerChildren: 0.1,
            when: 'beforeChildren',
        },
    },
    closed: {
        opacity: 0,
        height: 0,
        transition: {
            staggerChildren: 0.1,
            staggerDirection: -1,
            when: 'afterChildren',
        },
    },
};

const MergeSection: React.FC<MergeSectionProps> = ({
    title,
    expanded,
    onToggle,
    groupSelection,
    onGroupSelectionChange,
    children,
    groupType,
}) => {
    return (
        <>
            <RadioGroup
                value={groupSelection}
                onClick={e => e.stopPropagation()}
                onChange={e =>
                    onGroupSelectionChange(
                        groupType === 'merge'
                            ? (e.target.value as 'merge' | 'donotmerge')
                            : (e.target.value as 'source' | 'target' | 'custom'),
                    )
                }
            >
                <div
                    className="bg-gray-100 grid grid-cols-4 gap-2 px-2 py-4 cursor-pointer"
                    onClick={onToggle}
                    onKeyDown={e => e.key === 'Enter' && onToggle()}
                    role="button"
                    tabIndex={0}
                >
                    <div className="col-span-1 flex items-center space-x-2">
                        <motion.div
                            animate={{ rotate: expanded ? 0 : -90 }}
                            className="text-gray-600"
                        >
                            <ExpandMore />
                        </motion.div>
                        <h3 className="text-sm font-semibold text-gray-900 truncate">{title}</h3>
                    </div>
                    {groupType === 'merge' ? (
                        <>
                            <div className="col-span-1 flex items-center">
                                <FormControlLabel
                                    value="merge"
                                    control={<Radio size="small" />}
                                    label="Merge"
                                />
                            </div>
                            <div className="col-span-1 flex items-center">
                                <FormControlLabel
                                    value="donotmerge"
                                    control={<Radio size="small" />}
                                    label="Don't Merge"
                                />
                            </div>
                        </>
                    ) : (
                        <>
                            <div className="col-span-1 flex items-center">
                                <FormControlLabel
                                    value="target"
                                    control={<Radio size="small" />}
                                    label="Use Destination Values"
                                />
                            </div>
                            <div className="col-span-1 flex items-center">
                                <FormControlLabel
                                    value="source"
                                    control={<Radio size="small" />}
                                    label="Use Additional Source Values"
                                />
                            </div>
                            <div className="col-span-1 flex items-center">
                                <FormControlLabel
                                    value="custom"
                                    control={<Radio size="small" />}
                                    label="Custom"
                                />
                            </div>
                        </>
                    )}
                </div>
            </RadioGroup>
            <AnimatePresence>
                {expanded && (
                    <motion.div
                        variants={sectionVariants}
                        initial="closed"
                        animate="open"
                        exit="closed"
                        className="divide-y divide-gray-200"
                    >
                        {children}
                    </motion.div>
                )}
            </AnimatePresence>
        </>
    );
};

export default MergeSection;
