import React from 'react';
import { GetSentMessageByPatientIdForPatientTranscriptQuery } from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type SentMessage = NonNullable<
    GetSentMessageByPatientIdForPatientTranscriptQuery['getSentMessageByPatientId']
>[0];

export const SentMessageCard = ({ sentMessage }: { sentMessage: SentMessage }) => {
    return (
        <BaseCard title="Sent Comms" data={sentMessage}>
            <p className="m-0">Email Title: {sentMessage?.emailTitle}</p>
            <p className="m-0">Email Body:</p>
            <p
                className="m-0"
                style={{ display: 'inline-block' }}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: sentMessage?.emailBody || '' }}
            />
            <p className="m-0">Send Status: {sentMessage?.sendStatus[0].status}</p>
        </BaseCard>
    );
};
