import React, { useState, useMemo, useEffect } from 'react';
import moment from 'moment';
import { Select, MenuItem, Grid, FormControl, Typography, Button } from '@mui/material';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { TriggerGlobalAlert, SuppressNextGlobalAlert } from '~/state';
import {
    AffiliateApprovalStatus,
    OnboardingStatus,
    useSetAffiliateApprovalStatusAffiliatePageMutation,
    AlertSeverity,
    useSetAffiliateOnboardingStatusMutation,
    useFetchVirtualCareAffiliateByIdForStatusesMenuQuery,
} from '~/schemaTypes';
import AwayMode from '~/views/AffiliateCare/components/AwayMode/AwayMode';
import Loading from '~/components/Loading/Loading';
import ApprovalStatus from './ApprovalStatus';

type StatusesMenuProps = {
    affiliateId: string;
};

const DATE_FORMAT = 'MMMM Do, YYYY LT [PST]';
const PST_TIMEZONE_OFFSET = -8;

const onboardingStatusesStr = [
    ['', 'Not Started'],
    [OnboardingStatus.Started, 'Onboarding is started'],
    [OnboardingStatus.FirstStepCompleted, 'The First step is completed'],
    [OnboardingStatus.SecondStepCompleted, 'The Second step is completed'],
    [OnboardingStatus.ThirdStepCompleted, 'The Third step is completed'],
    [OnboardingStatus.FourthStepCompleted, 'The Fourth step is completed'],
    [OnboardingStatus.Completed, 'Completed'],
];

const StatusesMenu: React.FC<StatusesMenuProps> = props => {
    const { affiliateId } = props;
    const { data, loading: fetchLoading } = useFetchVirtualCareAffiliateByIdForStatusesMenuQuery({
        variables: {
            input: {
                id: affiliateId,
            },
        },
    });
    const {
        awayMode,
        endAwayModeDate,
        startAwayModeDate,
        onboardingStatus,
        approvalStatus,
        createdAt,
        updatedAt,
    } = useMemo(
        () => ({
            awayMode: data?.virtualCareAffiliateById?.data?.awayMode || false,
            endAwayModeDate: data?.virtualCareAffiliateById?.data?.endAwayModeDate,
            startAwayModeDate: data?.virtualCareAffiliateById?.data?.startAwayModeDate,
            onboardingStatus: data?.virtualCareAffiliateById?.data?.onboardingStatus,
            approvalStatus: data?.virtualCareAffiliateById?.data?.approvalStatus,
            createdAt: data?.virtualCareAffiliateById?.data?.createdAt,
            updatedAt: data?.virtualCareAffiliateById?.data?.updatedAt,
        }),
        [data],
    );
    const [currentApprovalStatus, setApprovalStatus] = useState<AffiliateApprovalStatus | string>(
        '',
    );
    const [currentOnboardingStatus, setOnboardingStatus] = useState<OnboardingStatus | string>('');
    const [showOnboardingMenu, setShowOnboardingMenu] = useState(false);
    useEffect(() => {
        setOnboardingStatus(onboardingStatus || '');
    }, [onboardingStatus]);
    useEffect(() => {
        setApprovalStatus(approvalStatus || '');
    }, [approvalStatus]);
    const onboardingStatusStr = useMemo(() => {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const [_, statusStr] =
            onboardingStatusesStr.find(([status]) => status === currentOnboardingStatus) ||
            onboardingStatusesStr[0];
        return statusStr;
    }, [currentOnboardingStatus]);
    const [updateApprovalStatus, { loading: approvalLoading }] =
        useSetAffiliateApprovalStatusAffiliatePageMutation({
            onCompleted: data =>
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: `Approval Status was changed to ${data.setAffiliateApprovalStatus?.data?.approvalStatus}`,
                }),
        });
    const [updateOnboardingStatus] = useSetAffiliateOnboardingStatusMutation({
        onCompleted: data =>
            TriggerGlobalAlert({
                severity: AlertSeverity.Success,
                message: `Onboarding Status was changed to ${data.setAffiliateOnboardingStatus?.data?.onboardingStatus}`,
            }),
    });
    if (fetchLoading) {
        return <Loading />;
    }
    return (
        <OutlinedSection title="">
            <Grid container>
                <Grid item xs={12}>
                    <FormControl fullWidth>
                        <Select
                            value={currentApprovalStatus}
                            size="small"
                            displayEmpty
                            disabled={approvalLoading}
                            renderValue={(selected: AffiliateApprovalStatus | '') => {
                                if (!selected) {
                                    return <em>Approval Status</em>;
                                }
                                return <ApprovalStatus status={selected} />;
                            }}
                            onChange={e => {
                                const newApprovalStatus = e.target.value as AffiliateApprovalStatus;
                                setApprovalStatus(newApprovalStatus);
                                if (affiliateId) {
                                    SuppressNextGlobalAlert(false);
                                    updateApprovalStatus({
                                        variables: {
                                            input: {
                                                id: affiliateId,
                                                status: newApprovalStatus,
                                            },
                                        },
                                    });
                                }
                            }}
                        >
                            <MenuItem
                                key={AffiliateApprovalStatus.ApprovalNeeded}
                                value={AffiliateApprovalStatus.ApprovalNeeded}
                            >
                                <ApprovalStatus status={AffiliateApprovalStatus.ApprovalNeeded} />
                            </MenuItem>
                            <MenuItem
                                key={AffiliateApprovalStatus.Approved}
                                value={AffiliateApprovalStatus.Approved}
                            >
                                <ApprovalStatus status={AffiliateApprovalStatus.Approved} />
                            </MenuItem>
                            <MenuItem
                                key={AffiliateApprovalStatus.Denied}
                                value={AffiliateApprovalStatus.Denied}
                            >
                                <ApprovalStatus status={AffiliateApprovalStatus.Denied} />
                            </MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <FormControl fullWidth>
                    <Grid
                        xs={12}
                        item
                        container
                        pb={4}
                        columnSpacing={2}
                        justifyContent="center"
                        alignItems="center"
                        minHeight={70}
                    >
                        <Grid item xs={6} md={4}>
                            <Typography fontWeight="bold" textAlign="end">
                                Onboarding
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            {!showOnboardingMenu && (
                                <Button
                                    variant="text"
                                    color="inherit"
                                    fullWidth
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'flex-start',
                                        alignItems: 'center',
                                        cursor: 'default',
                                    }}
                                    onClick={() => setShowOnboardingMenu(true)}
                                >
                                    <Typography>{onboardingStatusStr}</Typography>
                                </Button>
                            )}
                            {showOnboardingMenu && (
                                <FormControl fullWidth style={{ padding: 0 }}>
                                    <Select
                                        size="small"
                                        defaultOpen
                                        value={currentOnboardingStatus}
                                        displayEmpty
                                        onChange={e => {
                                            const newOnboardingStatus = e.target.value as
                                                | OnboardingStatus
                                                | '';
                                            setShowOnboardingMenu(false);
                                            setOnboardingStatus(newOnboardingStatus);
                                            if (affiliateId) {
                                                SuppressNextGlobalAlert(false);
                                                updateOnboardingStatus({
                                                    variables: {
                                                        input: {
                                                            id: affiliateId,
                                                            status: newOnboardingStatus || null,
                                                        },
                                                    },
                                                });
                                            }
                                        }}
                                        onClose={() => setShowOnboardingMenu(false)}
                                    >
                                        {onboardingStatusesStr.map(([value, label]) => (
                                            <MenuItem value={value} key={value}>
                                                <Typography variant="body2">{label}</Typography>
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                            )}
                        </Grid>
                    </Grid>
                    <Grid xs={12} item container pb={2} columnSpacing={2} justifyContent="center">
                        <Grid item xs={6} md={4}>
                            <Typography fontWeight="bold" textAlign="end">
                                Created
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <Typography>
                                {moment(createdAt)
                                    .utcOffset(PST_TIMEZONE_OFFSET)
                                    .format(DATE_FORMAT)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={12} item container columnSpacing={2} justifyContent="center">
                        <Grid item xs={6} md={4}>
                            <Typography fontWeight="bold" textAlign="end">
                                Updated
                            </Typography>
                        </Grid>
                        <Grid item xs={6} md={8}>
                            <Typography>
                                {moment(updatedAt)
                                    .utcOffset(PST_TIMEZONE_OFFSET)
                                    .format(DATE_FORMAT)}
                            </Typography>
                        </Grid>
                    </Grid>
                    <Grid xs={12} item container pt={2} justifyContent="center">
                        <Grid item>
                            <AwayMode
                                awayMode={awayMode}
                                startAwayModeDate={startAwayModeDate}
                                endAwayModeDate={endAwayModeDate}
                                affiliateId={affiliateId}
                            />
                        </Grid>
                    </Grid>
                </FormControl>
            </Grid>
        </OutlinedSection>
    );
};

export default StatusesMenu;
