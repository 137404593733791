import React from 'react';
import { Checkbox, FormControlLabel, Grid, Radio, RadioGroup, TextField } from '@mui/material';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { FetchProfileDefsForMergeQuery } from '~/schemaTypes';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { parseISO } from 'date-fns';
import { MergeRow } from '../MergePatient';

export const renderCustomInput = ({
    row,
    index,
    handleFinalValueChange,
    profileDefsData,
}: {
    row: MergeRow;
    index: number;
    handleFinalValueChange: (index: number, value: string) => void;
    profileDefsData: FetchProfileDefsForMergeQuery | undefined;
}) => {
    const profileDef = profileDefsData?.userProfileDefsV2.results?.find(
        def => def.id === row.attribute,
    );

    if (!profileDef) {
        return (
            <TextField
                value={row.finalValue}
                onChange={e => handleFinalValueChange(index, e.target.value)}
                size="small"
                disabled={row.selectedOption !== 'custom'}
                fullWidth
            />
        );
    }

    switch (profileDef.valueType) {
        case 'bool':
            return (
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={row.finalValue.toLowerCase() === 'true'}
                            onChange={e =>
                                handleFinalValueChange(index, e.target.checked.toString())
                            }
                            disabled={row.selectedOption !== 'custom'}
                        />
                    }
                    label={profileDef.label?.en}
                />
            );

        case 'date':
            return (
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                    <DatePicker
                        label={profileDef.label?.en || 'Value'}
                        value={row.finalValue ? parseISO(row.finalValue) : null}
                        onChange={newDate => {
                            if (row.selectedOption === 'custom' || newDate) {
                                handleFinalValueChange(
                                    index,
                                    newDate?.toISOString().split('T')[0] || '',
                                );
                            }
                        }}
                        disabled={row.selectedOption !== 'custom'}
                        slotProps={{ textField: { size: 'small', fullWidth: true } }}
                    />
                </LocalizationProvider>
            );

        case 'num':
            return (
                <TextField
                    value={row.finalValue}
                    onChange={e => handleFinalValueChange(index, e.target.value)}
                    size="small"
                    type="number"
                    disabled={row.selectedOption !== 'custom'}
                    fullWidth
                    InputProps={{
                        inputProps: { min: 0, step: 1 },
                    }}
                />
            );

        case 'choice':
            return (
                <OutlinedSection outlineColor="secondary" title={profileDef.label?.en ?? ''}>
                    <RadioGroup
                        value={row.finalValue}
                        onChange={e => handleFinalValueChange(index, e.target.value)}
                    >
                        <Grid container spacing={2} direction="column">
                            {profileDef.choices?.map(choice => (
                                <Grid item key={choice?.id}>
                                    <FormControlLabel
                                        value={choice?.id}
                                        control={<Radio />}
                                        label={choice?.label?.en}
                                        disabled={row.selectedOption !== 'custom'}
                                    />
                                </Grid>
                            ))}
                        </Grid>
                    </RadioGroup>
                </OutlinedSection>
            );

        case 'choices':
            return (
                <OutlinedSection outlineColor="secondary" title={profileDef.label?.en ?? ''}>
                    <Grid container spacing={2} direction="column">
                        {profileDef.choices?.map(choice => (
                            <Grid item key={choice?.id}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={row.finalValue.split(',').includes(choice?.id)}
                                            value={choice?.id}
                                            onChange={({ target: { checked, value } }) => {
                                                const currentChoices = new Set(
                                                    row.finalValue ? row.finalValue.split(',') : [],
                                                );
                                                currentChoices[checked ? 'add' : 'delete'](value);
                                                handleFinalValueChange(
                                                    index,
                                                    Array.from(currentChoices).join(','),
                                                );
                                            }}
                                            disabled={row.selectedOption !== 'custom'}
                                        />
                                    }
                                    label={choice?.label?.en}
                                />
                            </Grid>
                        ))}
                    </Grid>
                </OutlinedSection>
            );

        default:
            return (
                <TextField
                    value={row.finalValue}
                    onChange={e => handleFinalValueChange(index, e.target.value)}
                    size="small"
                    disabled={row.selectedOption !== 'custom'}
                    fullWidth
                />
            );
    }
};
