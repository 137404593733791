import React from 'react';
import { GetTriggerFiredEventsForPatientTranscriptQuery } from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type TriggerFiredEvent = NonNullable<
    NonNullable<
        GetTriggerFiredEventsForPatientTranscriptQuery['getTriggerFiredEventsForPatientTranscript']
    >['items']
>[number];

export const TriggerFiredEventCard = ({ event }: { event: TriggerFiredEvent }) => {
    return (
        <BaseCard title="Trigger Fired Event" data={event}>
            <p className="m-0">{`Tag: ${event?.tag?.name || 'Unknown'}`}</p>
            <p className="m-0">{`Expression: ${event?.tag?.expression?.name || 'Unknown'}`}</p>
            {event?.dateTrigger && (
                <p className="m-0">{`Date Trigger: ${event.dateTrigger.name}`}</p>
            )}
            {event?.eventTrigger && (
                <p className="m-0">{`Event Trigger: ${event.eventTrigger.name}`}</p>
            )}
            {event?.trendTrigger && (
                <p className="m-0">{`Trend Trigger: ${event.trendTrigger.name}`}</p>
            )}
        </BaseCard>
    );
};
