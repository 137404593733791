import { Typography } from '@mui/material';
import React, { ReactNode } from 'react';
import { JsonDialog } from '~/views/Dashboard/Patients/Patient/PatientTranscript/components/JsonDialog';

export const BaseCard = ({
    data,
    title,
    children,
}: {
    data: any;
    title: string;
    children: ReactNode;
}) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <div className="flex justify-between">
                <Typography variant="h6">{title}</Typography>
                <JsonDialog data={data} />
            </div>
            {children}
        </div>
    );
};
