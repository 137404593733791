import { Autocomplete, TextField } from '@mui/material';
import React, { useState, useRef } from 'react';
import { useEligibilityConfigsUnitIdsForUnitIdsAutocompleteLazyQuery } from '~/schemaTypes';
import { Control, Controller } from 'react-hook-form';

const MIN_SEARCH_LENGTH = 3;

const UnitIdsAutocomplete = ({ control }: { control: Control }) => {
    const [searchValue, setSearchValue] = useState<string>('');
    const lastChange = useRef<ReturnType<typeof setTimeout> | null>(null);

    const [fetchEligibilityCheckResultsUnitIds, { data, loading }] =
        useEligibilityConfigsUnitIdsForUnitIdsAutocompleteLazyQuery();

    const handleUnitIdsChange = (value: string) => {
        setSearchValue(value);

        if (lastChange.current) {
            clearTimeout(lastChange.current);
        }

        lastChange.current = setTimeout(async () => {
            lastChange.current = null;
            if (value.length >= MIN_SEARCH_LENGTH) {
                await fetchEligibilityCheckResultsUnitIds({
                    variables: {
                        input: {
                            unitId: value,
                        },
                    },
                });
            }
        }, 500);
    };

    return (
        <Controller
            control={control}
            name="unitIds"
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    multiple
                    options={data?.eligibilityConfigsUnitIds.result ?? []}
                    filterSelectedOptions
                    value={value}
                    onChange={(_, v) => onChange(v)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Unit Ids"
                            onChange={e => handleUnitIdsChange(e.target.value)}
                        />
                    )}
                    loading={
                        loading ||
                        (searchValue.length >= MIN_SEARCH_LENGTH && Boolean(lastChange.current))
                    }
                    loadingText="Loading..."
                    noOptionsText={
                        searchValue.length >= MIN_SEARCH_LENGTH
                            ? 'No options'
                            : `Type at least ${MIN_SEARCH_LENGTH} characters`
                    }
                />
            )}
        />
    );
};

export default UnitIdsAutocomplete;
