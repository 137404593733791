import { Box, IconButton, Typography } from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import React from 'react';
import { makeStyles } from 'tss-react/mui';

type EligibilityCardProp = {
    title: string;
    onDelete?: () => void;
    disabled?: boolean;
    children: React.ReactNode;
};

const useStyles = makeStyles()({
    box: {
        position: 'relative',
        marginBottom: '20px',
        padding: '10px',
        border: '1px solid #ddd',
        borderRadius: '8px',
    },
    iconButton: {
        position: 'absolute',
        top: 8,
        right: 8,
    },
    title: {
        fontWeight: 'bold',
    },
});

const EligibilityCard = ({ title, onDelete, children, disabled }: EligibilityCardProp) => {
    const { classes } = useStyles();
    return (
        <Box className={classes.box}>
            {onDelete && !disabled && (
                <IconButton className={classes.iconButton} onClick={onDelete}>
                    <DeleteIcon />
                </IconButton>
            )}
            <Typography variant="subtitle1" className={classes.title}>
                {title}
            </Typography>
            {children}
        </Box>
    );
};

export default EligibilityCard;
