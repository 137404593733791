import { Autocomplete, TextField } from '@mui/material';
import { Controller, Control } from 'react-hook-form';
import React from 'react';

function AutocompleteEnum<T>({
    label,
    control,
    name,
    options,
    error,
    helperText,
    disabled,
}: {
    label: string;
    control: Control<any>;
    name: string;
    options: { key: string; value: T }[];
    error: boolean;
    helperText?: string;
    disabled?: boolean;
}) {
    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, ref } }) => (
                <Autocomplete
                    options={options}
                    getOptionLabel={option => option.value?.toString() ?? ''}
                    isOptionEqualToValue={(option, val) => option.value === val.value}
                    filterSelectedOptions
                    value={options.find(item => item.value === value) || null}
                    onChange={(_, v) => {
                        onChange(v ? v.value : null);
                    }}
                    disabled={disabled}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label={label}
                            placeholder={`Select ${label}`}
                            error={error}
                            helperText={helperText}
                            inputRef={ref}
                            disabled={disabled}
                        />
                    )}
                />
            )}
        />
    );
}

export default AutocompleteEnum;
