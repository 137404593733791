import {
    OrderByDirectionEnum,
    useEligibilityImportResultsV2ForEligibilityImportsQuery,
    EligibilityImportResultsV2ForEligibilityImportsQuery,
} from '~/schemaTypes';
import React, { useMemo, useRef, useState } from 'react';
import { PAGESIZE } from '~/constants';
import MaterialTable, { Column, OrderByCollection } from '@material-table/core';
import { createCustomSort } from '~/helpers/materialTableHelper';
import { errorRed, primaryColor, secondaryColor } from '~/theme/WildTheme';
import tableIcons from '~/helpers/tableIcons';
import _ from 'lodash';
import { Badge, TablePagination } from '@mui/material';
import { useAtom } from 'jotai/index';
import EligibilityImportResultFilterModal from '~/views/Dashboard/EligibilityImportResults/EligibilityImportResultFilterModal/EligibilityImportResultFilterModal';
import { useNavigate } from 'react-router-dom';
import { useUserPermissions } from '~/hooks';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import moment from 'moment';
import { EligibilityImportFiltersState } from './state';

type EligibilityImportResult =
    EligibilityImportResultsV2ForEligibilityImportsQuery['eligibilityImportResultsV2']['results'][0];

interface EligibilityImportResultFieldsOrder {
    field: keyof EligibilityImportResult;
    direction: OrderByDirectionEnum;
    isInit: boolean;
}

const initialOrder: EligibilityImportResultFieldsOrder = {
    field: 'createdAt',
    direction: OrderByDirectionEnum.Desc,
    isInit: true,
};

const EligibilityImportResults = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const history = useNavigate();
    const { pagePermissions } = useUserPermissions();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [order, setOrder] = useState<EligibilityImportResultFieldsOrder>(initialOrder);
    const [isFilterModal, setIsFilterModal] = useState<boolean>(false);
    const [filters] = useAtom(EligibilityImportFiltersState);

    const { tableColumns, columnIdToSortingFieldMap } = useMemo(() => {
        const tableColumns: ({
            field: keyof EligibilityImportResult;
        } & Column<EligibilityImportResult>)[] = [
            {
                title: 'Id',
                width: 'auto',
                field: 'id',
                customSort: createCustomSort('id'),
            },
            {
                title: 'App Bundle Id',
                width: 'auto',
                field: 'appBundleId',
                customSort: createCustomSort('appBundleId'),
                tooltip: 'Bundle ID of the application this eligibility import is for',
            },
            {
                title: 'Unit Id',
                width: 'auto',
                field: 'unitId',
                customSort: createCustomSort('unitId'),
                tooltip: 'ID of the unit this eligibility import belongs to',
            },
            {
                title: 'Is Success',
                field: 'success',
                type: 'boolean',
                cellStyle: (_, data) => ({ color: data.success ? secondaryColor : errorRed }),
                customSort: createCustomSort('success'),
                tooltip: 'Whether the eligibility import completed successfully',
            },
            {
                title: 'Records',
                width: 'auto',
                field: 'records',
                customSort: createCustomSort('records'),
            },
            {
                title: 'Created At',
                field: 'createdAt',
                render: rowData => moment(rowData.createdAt).format('MM/DD/YYYY HH:mm'),
                customSort: createCustomSort('createdAt'),
                tooltip: 'When this eligibility import was created',
            },
            {
                title: 'Updated At',
                field: 'updatedAt',
                render: rowData => moment(rowData.updatedAt).format('MM/DD/YYYY HH:mm'),
                customSort: createCustomSort('updatedAt'),
                tooltip: 'When this eligibility import was last updated',
            },
        ];

        const columnIdToSortingFieldMap = tableColumns.reduce((accum, item, index) => {
            accum[index] = item.field;
            return accum;
        }, {} as Record<number, keyof EligibilityImportResult>);

        return {
            tableColumns,
            columnIdToSortingFieldMap,
        };
    }, []);

    const { data, loading } = useEligibilityImportResultsV2ForEligibilityImportsQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        appBundleId: filters.appBundleId,
                        success: filters.success,
                    },
                    fieldsInList: {
                        unitId: filters.unitIds,
                    },
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                },
                pagination: {
                    limit: rowsPerPage,
                    skip: page * rowsPerPage,
                },
            },
        },
    });

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        if (!orderByCollection || orderByCollection.length === 0) {
            setOrder(initialOrder);
            return;
        }

        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];

        if (field) {
            setOrder({
                direction:
                    orderBy.orderDirection.toUpperCase() === OrderByDirectionEnum.Asc
                        ? OrderByDirectionEnum.Asc
                        : OrderByDirectionEnum.Desc,
                field,
                isInit: field !== order.field,
            });
        }
    };

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
        setPage(pageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    return (
        <>
            <div ref={tableRef}>
                <MaterialTable<EligibilityImportResult>
                    title="Eligibility Import Results"
                    icons={tableIcons}
                    isLoading={loading}
                    page={page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onOrderCollectionChange={handleOrderChange}
                    data={_.cloneDeep(data?.eligibilityImportResultsV2.results ?? [])}
                    actions={[
                        {
                            tooltip: 'Filter Eligibility Imports',
                            onClick: () => {
                                setIsFilterModal(true);
                            },
                            icon: () => (
                                <Badge
                                    badgeContent={
                                        Object.values(filters).filter(i => i !== undefined).length
                                    }
                                    color="error"
                                    style={{
                                        padding: '12px',
                                        backgroundColor: primaryColor,
                                        color: 'white',
                                        fontSize: '1rem',
                                        borderRadius: '1rem',
                                    }}
                                >
                                    Filter
                                </Badge>
                            ),
                            isFreeAction: true,
                        },
                        {
                            onClick: (_, data: EligibilityImportResult) => history(data.id),
                            hidden: !pagePermissions?.EligibilityImportResults.Read,
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View Eligibility Import Results',
                        },
                    ]}
                    columns={tableColumns}
                    options={{
                        search: false,
                        paging: true,
                        pageSize: rowsPerPage,
                        pageSizeOptions: [10, PAGESIZE, 50],
                        debounceInterval: 500,
                    }}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={data?.eligibilityImportResultsV2.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                    }}
                />
            </div>
            <EligibilityImportResultFilterModal
                isOpen={isFilterModal}
                onClose={() => setIsFilterModal(false)}
            />
        </>
    );
};

export default EligibilityImportResults;
