import { atomWithStorage } from 'jotai/utils';

export type EligibilityImportFilters = {
    appBundleId?: string;
    unitIds?: string[];
    success?: boolean;
};

export const EligibilityImportFiltersState = atomWithStorage<EligibilityImportFilters>(
    'EligibilityImportFilters',
    {},
);
