import {
    ChecklistItemModalBodyProps,
    PatientActionType,
} from '~/views/ConfigDashboard/AdvocateTasks/components/ChecklistItemModal/ChecklistItemModal';
import { ActionType, ChecklistItemForm } from '../types';

export const INITIAL_ACTION_TYPE: ActionType = { typeId: '', newActionTypeLabel: '' };

export const getInitialModalData = ({
    data,
    isCompleteMode,
    checked,
    completeModeActionTypes,
}: ChecklistItemModalBodyProps & {
    completeModeActionTypes: PatientActionType[];
}): ChecklistItemForm => {
    const {
        id,
        label = '',
        description = '',
        actionTypeIds = [],
        completedAt,
        completedBy,
        endSlaTimerOnComplete,
    } = data ?? {};

    const hasActionTypes = Boolean(actionTypeIds.length > 0);
    const isNewChecklistItem = Boolean(!id);

    const actionTypes: ActionType[] =
        actionTypeIds.length === 0
            ? [INITIAL_ACTION_TYPE]
            : actionTypeIds.map(typeId => ({ typeId }));

    if (
        !checked &&
        completeModeActionTypes.length === 1 &&
        actionTypes.length === 1 &&
        completeModeActionTypes[0].id === actionTypes[0].typeId
    ) {
        actionTypes[0].isChecked = true;
    }

    return {
        id,
        name: label,
        description,
        actionTypes,
        shouldCreateAction: !isCompleteMode && (isNewChecklistItem || hasActionTypes),
        completedAt: completedAt ?? new Date().toISOString(),
        completedBy,
        endSlaTimerOnComplete,
    };
};
