import ObjectId from 'bson-objectid';
import React, { useEffect, useState } from 'react';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { Button, Checkbox, Dialog, FormControlLabel, MenuItem, TextField } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import {
    useFetchConfigValueDefsForRichTextEditorSubstTokenLazyQuery,
    useFetchProfileDefsForRichTextEditorSubstTokenLazyQuery,
    useFetchSystemTokensForRichTextEditorSubstTokenLazyQuery,
    FetchConfigValueDefsForRichTextEditorSubstTokenQuery,
    FetchProfileDefsForRichTextEditorSubstTokenQuery,
    FetchSystemTokensForRichTextEditorSubstTokenQuery,
    SubstitutionItem,
} from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import _ from 'lodash';
import { valueTypes } from './RichTextEditorSubstTokenSection';

type RichTextEditorSubstTokenDialogProps = {
    isOpen: boolean;
    onClose: () => void;
    onSubstTokenAdd: (substToken: SubstitutionItem) => void;
    isEditMode?: boolean;
    initialFormValues?: SubstitutionItem;
};

export type SubstTokenFormInput = {
    valueType: valueTypes | undefined;
    missingValueText: string;
    profileDef?: string;
    configValueDef?: string;
    systemTokenDef?: string;
    failIfMissing?: boolean;
};

const substTokenFormToSubstTokenItemMapper = (
    formItem: SubstTokenFormInput,
    profileDefsData: FetchProfileDefsForRichTextEditorSubstTokenQuery | undefined,
    configDefsData: FetchConfigValueDefsForRichTextEditorSubstTokenQuery | undefined,
    systemTokensData: FetchSystemTokensForRichTextEditorSubstTokenQuery | undefined,
): SubstitutionItem => {
    const {
        valueType,
        missingValueText,
        profileDef,
        configValueDef,
        systemTokenDef,
        failIfMissing,
    } = formItem;
    let name = '';
    let result: SubstitutionItem = { missingValueText, failIfMissing, name };

    switch (valueType) {
        case valueTypes.profileDef:
            if (profileDef) {
                name =
                    profileDefsData?.userProfileDefs.find(({ id }) => id === formItem.profileDef)
                        ?.name || '';
                result = { ...result, profileVariableDefId: new ObjectId(profileDef), name };
            }
            break;
        case valueTypes.configDef:
            if (configValueDef) {
                name =
                    configDefsData?.configValueDefs.find(({ id }) => id === formItem.configValueDef)
                        ?.name || '';
                result = { ...result, configValueDefId: new ObjectId(configValueDef), name };
            }
            break;
        case valueTypes.systemToken:
            if (systemTokenDef) {
                name =
                    systemTokensData?.systemTokens.find(({ id }) => id === formItem.systemTokenDef)
                        ?.name || '';
                result = { ...result, systemTokenId: new ObjectId(systemTokenDef), name };
            }
            break;
        default:
            break;
    }

    return result;
};

const subsTokenItemToSubstTokenFormMapper = (substToken: SubstitutionItem): SubstTokenFormInput => {
    const {
        profileVariableDefId,
        configValueDefId,
        systemTokenId,
        missingValueText,
        failIfMissing,
    } = substToken;
    let result: SubstTokenFormInput = {
        valueType: undefined,
        missingValueText: missingValueText || '',
        failIfMissing: !!failIfMissing,
    };

    if (profileVariableDefId) {
        result = { ...result, profileDef: profileVariableDefId, valueType: valueTypes.profileDef };
    }
    if (configValueDefId) {
        result = { ...result, configValueDef: configValueDefId, valueType: valueTypes.configDef };
    }
    if (systemTokenId) {
        result = { ...result, systemTokenDef: systemTokenId, valueType: valueTypes.systemToken };
    }

    return result;
};

const RichTextEditorSubstTokenDialog: React.FC<RichTextEditorSubstTokenDialogProps> = ({
    isOpen = false,
    onClose,
    onSubstTokenAdd,
    isEditMode = false,
    initialFormValues,
}) => {
    const [valueTypeSelected, setValueTypeSelected] = useState<valueTypes | null>();
    const defaultValues = subsTokenItemToSubstTokenFormMapper(
        initialFormValues || {
            name: '',
            missingValueText: '',
            profileVariableDefId: '',
            configValueDefId: '',
            systemTokenId: '',
            failIfMissing: false,
        },
    );
    const { control, handleSubmit, register, reset } = useForm<SubstTokenFormInput>({
        defaultValues,
    });
    const [fetchProfileDefs, { data: profileDefsData, loading: profileDefsLoading }] =
        useFetchProfileDefsForRichTextEditorSubstTokenLazyQuery();
    const [fetchConfigDefs, { data: configDefsData, loading: configDefsLoading }] =
        useFetchConfigValueDefsForRichTextEditorSubstTokenLazyQuery();
    const [fetchSystemTokens, { data: systemTokensData, loading: systemTokensLoading }] =
        useFetchSystemTokensForRichTextEditorSubstTokenLazyQuery();
    const valueTypeChangeHandler = (value: valueTypes | undefined) => {
        switch (value) {
            case valueTypes.profileDef:
                fetchProfileDefs();
                break;
            case valueTypes.configDef:
                fetchConfigDefs();
                break;
            case valueTypes.systemToken:
                fetchSystemTokens();
                break;
            default:
                break;
        }
        setValueTypeSelected(value);
    };
    const closeDialogHandler = () => {
        onClose();
    };
    const onFormSubmit = async (formValues: SubstTokenFormInput) => {
        if (formValues.valueType === undefined) {
            return;
        }
        const namedSubstToken = substTokenFormToSubstTokenItemMapper(
            formValues,
            profileDefsData,
            configDefsData,
            systemTokensData,
        );
        onSubstTokenAdd(namedSubstToken);
        closeDialogHandler();
    };
    useEffect(() => {
        if (isEditMode && initialFormValues) {
            reset(defaultValues);
            const { valueType } = defaultValues;
            valueTypeChangeHandler(valueType);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditMode, initialFormValues, reset]);
    return (
        <Dialog open={isOpen} onClose={closeDialogHandler}>
            <form onSubmit={handleSubmit(onFormSubmit)}>
                <DialogTitle>Substitution Token</DialogTitle>
                <DialogContent>
                    <div>
                        <ReactHookFormSelect
                            control={control}
                            defaultValue=""
                            name="valueType"
                            variant="outlined"
                            label="Select Value Type"
                            fullWidth
                            margin="dense"
                            onChangeHandler={valueTypeChangeHandler}
                        >
                            <MenuItem key={1} value={valueTypes.profileDef}>
                                Profile Value
                            </MenuItem>
                            <MenuItem key={2} value={valueTypes.configDef}>
                                Config Value
                            </MenuItem>
                            <MenuItem key={3} value={valueTypes.systemToken}>
                                System Token
                            </MenuItem>
                        </ReactHookFormSelect>
                        {valueTypeSelected === valueTypes.profileDef &&
                            (profileDefsLoading ? (
                                <Loading height={80} />
                            ) : (
                                <ReactHookFormSelect
                                    control={control}
                                    defaultValue=""
                                    name="profileDef"
                                    variant="outlined"
                                    label="Profile Value Definition"
                                    fullWidth
                                    margin="dense"
                                >
                                    {profileDefsData &&
                                        _.sortBy(profileDefsData.userProfileDefs, 'name').map(
                                            ({ id, name }) => (
                                                <MenuItem key={`${name}input-${id}`} value={id}>
                                                    {name}
                                                </MenuItem>
                                            ),
                                        )}
                                </ReactHookFormSelect>
                            ))}

                        {valueTypeSelected === valueTypes.configDef &&
                            (configDefsLoading ? (
                                <Loading height={80} />
                            ) : (
                                <ReactHookFormSelect
                                    control={control}
                                    defaultValue=""
                                    name="configValueDef"
                                    variant="outlined"
                                    label="Config Value"
                                    fullWidth
                                    margin="dense"
                                >
                                    {configDefsData &&
                                        _.sortBy(configDefsData.configValueDefs, 'name').map(
                                            ({ id, name }) => (
                                                <MenuItem key={`${name}input-${id}`} value={id}>
                                                    {name}
                                                </MenuItem>
                                            ),
                                        )}
                                </ReactHookFormSelect>
                            ))}
                        {valueTypeSelected === valueTypes.systemToken &&
                            (systemTokensLoading ? (
                                <Loading height={200} />
                            ) : (
                                <ReactHookFormSelect
                                    control={control}
                                    defaultValue=""
                                    name="systemTokenDef"
                                    variant="outlined"
                                    label="System token"
                                    fullWidth
                                    margin="dense"
                                >
                                    {systemTokensData &&
                                        _.sortBy(systemTokensData.systemTokens, 'name').map(
                                            ({ id, name }) => (
                                                <MenuItem key={`${name}input-${id}`} value={id}>
                                                    {name}
                                                </MenuItem>
                                            ),
                                        )}
                                </ReactHookFormSelect>
                            ))}
                        <TextField
                            {...register('missingValueText')}
                            variant="outlined"
                            label="Default value"
                            fullWidth
                        />
                        <Controller
                            name="failIfMissing"
                            control={control}
                            render={({ field }) => (
                                <FormControlLabel
                                    label="Cancel the substitution if the value is not found?"
                                    control={
                                        <Checkbox
                                            {...field}
                                            checked={!!field.value}
                                            color="primary"
                                        />
                                    }
                                />
                            )}
                        />
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={closeDialogHandler} color="secondary" variant="outlined">
                        Cancel
                    </Button>
                    <Button
                        onClick={handleSubmit(onFormSubmit)}
                        color="primary"
                        variant="contained"
                    >
                        Add Token
                    </Button>
                </DialogActions>
            </form>
        </Dialog>
    );
};

export default RichTextEditorSubstTokenDialog;
