import { Autocomplete, Button, Grid, TextField, Typography } from '@mui/material';
import ObjectId from 'bson-objectid';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    ArticlesByCriteriaQuery,
    useArticleByIdForArticlePickerLazyQuery,
    useArticleCategoriesForArticlePickerQuery,
    useArticleGroupsForArticlePickerQuery,
    useArticlesByCriteriaLazyQuery,
} from '~/schemaTypes';
import { CarePlanEnum } from '~/selectors';
import { makeStyles } from 'tss-react/mui';

const defaultFormValues = {
    articleId: '',
    categoryId: '',
    groupId: '',
    textFilter: '',
    article: null,
};
type Articles = NonNullable<ArticlesByCriteriaQuery['articlePickerFetch']['data']>;

interface ArticlePickerParams {
    selectedArticleId: string;
    setSelectedArticleId: (articleId: string) => void;
    setRequired?: (required: boolean) => void;
    setSelectedArticleName?: (articleName: string) => void;
    required: boolean;
    disabled?: boolean;
    compact?: boolean;
}

type Category = {
    name: string;
    id: string;
};

type Article = {
    name: string;
    id: string;
};

type Group = {
    name: string;
    id: string;
};

interface ArticleFormInput {
    articleId: string;
    categoryId: string;
    groupId: string;
    textFilter: string;
}
const useStyles = makeStyles()({
    resetPadding: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    filterButtonWrapper: {
        marginTop: 10,
    },
    emptyState: {
        fontWeight: 'lighter',
    },
    articleSelect: {
        marginBottom: 10,
    },
    articleTextField: {
        paddingTop: 0,
        paddingBottom: 0,
    },
    error: {
        color: '#e74c3c',
    },
});

const ArticlePicker: React.FC<ArticlePickerParams> = ({
    selectedArticleId,
    setSelectedArticleId,
    setSelectedArticleName,
    required,
    setRequired,
    disabled = false,
    compact = false,
}) => {
    const { classes } = useStyles();
    const isEditMode = Boolean(selectedArticleId);
    const [filterDisabled, setFilterDisabled] = useState<boolean>(true);
    const [articlesNotFound, setArticlesNotFound] = useState<boolean>(false);
    const [isFilterInUse, setIsFilterInUse] = useState<boolean>(false);
    const [isArticleSelectedByUser, setIsArticleSelectedByUser] = useState<boolean>(false);
    const [articleCategories, setArticleCategories] = useState<Category[]>([]);
    const [articleGroups, setArticleGroups] = useState<Group[]>([]);
    const [articles, setArticles] = useState<Articles>([]);
    const [defaultArticle, setDefaultArticle] = useState<Article | null>(null);
    const [textFilter, setTextFilter] = useState<string>('');
    const [group, setGroup] = useState<Group | null>(null);
    const [category, setCategory] = useState<Category | null>(null);
    const [articleId, setArticleId] = useState<string>('');

    const isFilterById = Boolean(articleId);
    const isFilterByCriteria = Boolean(category || group || textFilter);
    const isFilterEnabled = isFilterById || isFilterByCriteria;

    const {
        control,
        register,
        setError,
        clearErrors,
        handleSubmit,

        formState: { errors },
    } = useForm({
        defaultValues: defaultFormValues,
    });

    const setDefaultArticleWrapper = useCallback(
        (selectedArticle: Article | null) => {
            setDefaultArticle(selectedArticle);
        },
        [setDefaultArticle],
    );

    const { loading: articleCategoriesLoading } = useArticleCategoriesForArticlePickerQuery({
        onCompleted: ({ articleCategorysV2 }) => {
            if (articleCategorysV2) {
                setArticleCategories(articleCategorysV2.results);
            }
        },
    });

    const { loading: articleGroupsLoading } = useArticleGroupsForArticlePickerQuery({
        onCompleted: ({ articleGroupsV2 }) => {
            if (articleGroupsV2) {
                setArticleGroups(articleGroupsV2.results);
            }
        },
    });
    const [articleById, { loading: articleByIdLoading }] = useArticleByIdForArticlePickerLazyQuery({
        onCompleted: ({ article }) => {
            if (article) {
                setArticles(() => [article]);
                setDefaultArticleWrapper(article);
            }
        },
        onError: () => {
            setArticlesNotFound(true);
        },
    });
    const [articlesByCriteria, { loading: articlesByCriteriaLoading }] =
        useArticlesByCriteriaLazyQuery({
            onCompleted: ({ articlePickerFetch: { data, total } }) => {
                if (data) {
                    const sortedArticles = _.sortBy(data, 'name');
                    setArticlesNotFound(!total);
                    setArticles(() => sortedArticles);
                    setDefaultArticleWrapper(sortedArticles[0]);
                }
            },
            fetchPolicy: 'network-only',
        });

    useEffect(() => {
        if (isFilterEnabled) {
            setFilterDisabled(false);
        } else {
            setFilterDisabled(true);
        }
    }, [isFilterEnabled]);

    useEffect(() => {
        if (articles.length) {
            setSelectedArticleId(articles[0]?.id);
        }
    }, [articles, setSelectedArticleId]);

    useEffect(() => {
        if (articles.length && setSelectedArticleName) {
            setSelectedArticleName(articles[0]?.name || '');
        }
    }, [articles, setSelectedArticleName]);

    useEffect(() => {
        if (isEditMode && !isArticleSelectedByUser) {
            articleById({ variables: { input: { id: selectedArticleId } } });
        }
    }, [isEditMode, articleById, selectedArticleId, isArticleSelectedByUser]);

    const handleFilter: SubmitHandler<ArticleFormInput> = ({
        articleId,
        categoryId,
        groupId,
        textFilter,
    }) => {
        setArticlesNotFound(false);
        setIsArticleSelectedByUser(true);
        setIsFilterInUse(true);
        if (setRequired) {
            setRequired(false);
        }
        if (articleId && !ObjectId.isValid(articleId)) {
            setError('articleId', {
                type: 'custom',
                message: 'Provided id is not a valid ObjectId',
            });
            return;
        }

        if (!articleId) {
            articlesByCriteria({
                variables: {
                    input: {
                        ...(categoryId && { categoryId }),
                        ...(groupId && { groupId }),
                        ...(textFilter && { searchTerm: textFilter }),
                    },
                },
            });
            return;
        }

        articleById({ variables: { input: { id: articleId } } });
    };

    const handleArticleChange = (val: { name: string | undefined; id: string } | null) => {
        const article = articles.find(item => item?.id === val?.id);

        setDefaultArticleWrapper(article || null);
        setIsArticleSelectedByUser(true);
        setSelectedArticleId(val?.id ?? '');
        if (setRequired) {
            setRequired(false);
        }
        if (setSelectedArticleName) {
            setSelectedArticleName(val?.name ?? '');
        }
    };

    if (articleCategoriesLoading) {
        return <Loading height={250} subtitle="Loading Article Categories..." />;
    }
    if (articleGroupsLoading) {
        return <Loading height={250} subtitle="Loading Article Groups..." />;
    }
    if (articleByIdLoading) {
        return <Loading height={250} subtitle="Fetching Article by id..." />;
    }
    if (articlesByCriteriaLoading) {
        return <Loading height={250} subtitle="Fetching Articles by criteria..." />;
    }

    return (
        <OutlinedSection title="Select Article" dataTest={CarePlanEnum.ARTICLE_PICKER}>
            <OutlinedSection title="Filter">
                <form onSubmit={handleSubmit(handleFilter)}>
                    <Grid container>
                        <Grid item container direction="column" xs={12} md={compact ? 12 : 10}>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    {...register('articleId')}
                                    label="Article Id"
                                    fullWidth
                                    margin="dense"
                                    onFocus={() => clearErrors('articleId')}
                                    className={classes.resetPadding}
                                    disabled={disabled || isFilterByCriteria}
                                    error={Boolean(errors.articleId)}
                                    helperText={errors.articleId?.message}
                                    value={articleId}
                                    onChange={({ target: { value } }) => setArticleId(value)}
                                />
                            </Grid>
                            <Grid item container>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="categoryId"
                                        defaultValue=""
                                        render={({ field: { onChange } }) => (
                                            <Autocomplete
                                                size="small"
                                                disabled={disabled || isFilterById}
                                                value={category}
                                                onChange={(_, val) => {
                                                    onChange(val?.id);
                                                    setCategory(
                                                        articleCategories.find(
                                                            ({ id }) => id === val?.id,
                                                        ) || null,
                                                    );
                                                }}
                                                getOptionLabel={selected => selected.name}
                                                options={articleCategories}
                                                isOptionEqualToValue={(option, val) =>
                                                    option.id === val.id
                                                }
                                                data-test={CarePlanEnum.ARTICLE_CATEGORY}
                                                renderInput={params => (
                                                    <TextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                                        {...params}
                                                        placeholder="Select Category"
                                                        label="Category"
                                                        className={classes.resetPadding}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={6}>
                                    <Controller
                                        control={control}
                                        name="groupId"
                                        defaultValue=""
                                        render={({ field: { onChange } }) => (
                                            <Autocomplete
                                                size="small"
                                                disabled={disabled || isFilterById}
                                                value={group}
                                                onChange={(_, val) => {
                                                    onChange(val?.id);
                                                    setGroup(
                                                        articleGroups.find(
                                                            ({ id }) => id === val?.id,
                                                        ) || null,
                                                    );
                                                }}
                                                getOptionLabel={selected => selected.name}
                                                options={articleGroups}
                                                isOptionEqualToValue={(option, val) =>
                                                    option.id === val.id
                                                }
                                                data-test={CarePlanEnum.ARTICLE_GROUP}
                                                renderInput={params => (
                                                    <TextField
                                                        variant="outlined"
                                                        margin="dense"
                                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                                        {...params}
                                                        placeholder="Select Group"
                                                        label="Group"
                                                        className={classes.resetPadding}
                                                        InputLabelProps={{ shrink: true }}
                                                    />
                                                )}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    {...register('textFilter')}
                                    label="Text Filter"
                                    fullWidth
                                    margin="dense"
                                    className={classes.resetPadding}
                                    disabled={disabled || isFilterById}
                                    value={textFilter}
                                    onChange={({ target: { value } }) => setTextFilter(value)}
                                />
                            </Grid>
                        </Grid>
                        <Grid
                            item
                            container
                            xs={12}
                            md={compact ? 12 : 2}
                            justifyContent="flex-end"
                            alignItems="flex-end"
                        >
                            <Grid item className={classes.filterButtonWrapper}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    disabled={disabled || filterDisabled}
                                    data-test={CarePlanEnum.FILTER}
                                    onClick={handleSubmit(handleFilter)}
                                >
                                    Filter
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                </form>
            </OutlinedSection>
            {articles.length > 0 && (
                <Controller
                    control={control}
                    name="article"
                    render={({ field: { onChange } }) => (
                        <Autocomplete
                            disabled={disabled}
                            data-test={CarePlanEnum.SELECT_ARTICLE}
                            className={classes.articleSelect}
                            size="small"
                            value={defaultArticle}
                            onChange={(_, val) => {
                                onChange(val?.id || '');
                                handleArticleChange(val);
                            }}
                            getOptionLabel={selected => selected.name ?? ''}
                            options={articles.map(o => ({
                                name: o?.name,
                                id: o?.id,
                            }))}
                            isOptionEqualToValue={(option, val) => option?.id === val?.id}
                            renderInput={params => (
                                <TextField
                                    variant="outlined"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    className={classes.articleTextField}
                                    placeholder="Select Article"
                                    label="Article"
                                    InputLabelProps={{ shrink: true }}
                                    error={required}
                                />
                            )}
                        />
                    )}
                />
            )}
            {articlesNotFound && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography className={classes.emptyState}>
                        Articles not found. Please refine your search criteria...
                    </Typography>
                </Grid>
            )}
            {!isEditMode && !isFilterInUse && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography className={classes.emptyState}>
                        Use filter to search articles...
                    </Typography>
                </Grid>
            )}
            {required && (
                <Grid container justifyContent="center" alignItems="center">
                    <Typography className={classes.error}>
                        Please search and select an Article
                    </Typography>
                </Grid>
            )}
        </OutlinedSection>
    );
};
export default ArticlePicker;
