import { FetchStaffByIdForStaffModalQuery } from '~/schemaTypes';
import { AffiliateUserFormInput, VCUserType, SecOrgItem } from './types';

export const getSecondaryOrgs = (orgs: SecOrgItem[]) =>
    orgs.map(o => ({
        orgId: o.orgId,
        name: o.name,
    }));

export const getUserWithBillComDataPayload = (input: AffiliateUserFormInput) => {
    const {
        email,
        name,
        affiliateId,
        userType,
        createVendor,
        vendorId,
        secOrgList,
        ...billComData
    } = input;
    if (createVendor) {
        return {
            name,
            email,
            billComData: {
                BillDotComNameOnCheck: billComData.nameOnCheck,
                BillDotComAddress1: billComData.address1,
                BillDotComAddress2: billComData.address2,
                BillDotComAddressCity: billComData.city,
                BillDotComAddressState: billComData.state,
                BillDotComAddressZip: billComData.zip,
                BillDotComName: billComData.vendorName,
            },
            secondaryOrgs: getSecondaryOrgs(secOrgList),
        };
    }
    return {
        name,
        email,
        billComVendorId: vendorId,
        secondaryOrgs: getSecondaryOrgs(secOrgList),
    };
};

const getSecOrgList = (user: FetchStaffByIdForStaffModalQuery['user']) =>
    (user?.secondaryOrgs || [])
        .filter(o => !!o?.orgId)
        .map(o => ({
            orgId: o?.orgId ?? '',
            name: o?.name ?? '',
        }));

export const getVcUserInitData = (user: FetchStaffByIdForStaffModalQuery['user']) => {
    if (user) {
        const secOrgList = getSecOrgList(user);
        return {
            name: user.name,
            email: user.email,
            affiliateId: user.affiliateId,
            userType: user.vcType || VCUserType.Doula,
            vendorId: user.billComVendorId,
            state: '',
            rootOrg: user.rootOrg.id,
            secOrgList,
        };
    }
    return {};
};

export const getGeneralUserInitData = (
    user: FetchStaffByIdForStaffModalQuery['user'],
    clone: boolean,
) => {
    if (user) {
        return {
            rootOrg: user.rootOrg.id,
            email: clone ? '' : user.email,
            smsNumber: clone ? '' : user.smsNumber ?? '',
            name: clone ? '' : user.name,
            title: clone ? '' : user.title ?? '',
            photo: clone ? '' : user.photo ?? '',
            secOrgList: getSecOrgList(user),
        };
    }
    return {};
};
