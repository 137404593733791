import {
    CheckCircleOutlineOutlined,
    DesktopMacOutlined,
    HomeWorkOutlined,
} from '@mui/icons-material';
import {
    Box,
    Button,
    ButtonBase,
    Card,
    CardContent,
    CircularProgress,
    Collapse,
    FormControl,
    FormHelperText,
    Grid,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    MenuItem,
    Select,
    TextField,
    Typography,
} from '@mui/material';
import { debounce } from '@mui/material/utils';
import React, { useEffect, useMemo, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import {
    AppointmentMethod as AppointmentMethodEnum,
    OrderByDirectionEnum,
    useFetchAffiliatesForAutocompleteForAppointmentViewLazyQuery,
} from '~/schemaTypes';
import ButtonContainer from '../components/ButtonContainer';
import FooterDivider from '../components/FooterDivider';
import SupportBlock from '../components/SupportBlock';
import ToggleButtonSwitch from '../components/ToggleButtonSwitch';
import { useVCFContext } from '../hooks/useVCF';

import useStyles from '../styles';

type AppointmentMethodProps = {
    stepHandler: () => void;
    stepLoading: boolean;
    backHandler: () => void;
};

type AffiliateOption = {
    title: string;
    value: string;
};

const NO_AFFILIATE_FOUND_LABEL = 'No Affiliate Found in Region';

const VirtualListItems = [
    'Best for prenatal appointments, general advice, or immediate guidance',
    'Offers same- or next-day appointments',
    'Can refer you to an IBCLC provider if an in-person consult is required',
];

const HomeListItems = [
    'Pairs you with an IBCLC consultant near you',
    'Best for hands-on assessment or physical examination/intervention',
    'Typical wait time: 1-3 days',
    'Travel fees may apply for home visits',
];

const ListItems: React.FC<{ items: string[] }> = ({ items }) => {
    const { classes } = useStyles();
    return (
        <List className={classes.list}>
            {items.map((item, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <ListItem key={index} className={classes.listItem}>
                    <ListItemText primary={item} />
                </ListItem>
            ))}
        </List>
    );
};

const NewAppointmentMethod: React.FC<AppointmentMethodProps> = props => {
    const { stepLoading, stepHandler, backHandler } = props;
    const { classes } = useStyles();
    const { isMobileView } = useVCFContext();
    const [autocompleteSearch, setAutocompleteSearch] = useState('');
    const [autocompleteOptions, setAutocompleteOptions] = useState<AffiliateOption[]>([]);
    const [shouldDisableAutocomplete, setShouldDisableAutocomplete] = useState(false);
    const [isEditZipCode, setIsEditZipCode] = useState(false);
    const [isWrongZipCode, setIsWrongZipCode] = useState(false);
    const [shouldDisableNextButton, setShouldDisableNextButton] = useState(false);
    const {
        register,
        formState: { errors },
        control,
        setValue,
        watch,
    } = useFormContext();

    const type = watch('appointmentMethod');
    const affiliateFilterZipCode = watch('affiliateFilterZipCode');
    const preferredProvider = watch('preferredProvider');
    const selectedAffiliate = watch('affiliate');

    const showProviderBlock = preferredProvider && type === AppointmentMethodEnum.AtHome;

    const [fetchAffiliates, { data: affiliates, loading: affiliatesLoading }] =
        useFetchAffiliatesForAutocompleteForAppointmentViewLazyQuery();

    const fetchAffiliatesDebounced = useMemo(
        () => {
            if (affiliateFilterZipCode && affiliateFilterZipCode.length === 5) {
                setIsWrongZipCode(false);
                return debounce(
                    (search: string) =>
                        fetchAffiliates({
                            variables: {
                                input: {
                                    search,
                                    patientZipCode: affiliateFilterZipCode,
                                    orderBy: {
                                        field: 'internalName',
                                        order: OrderByDirectionEnum.Asc,
                                    },
                                },
                            },
                        }),
                    500,
                );
            }
            return () => {
                setIsWrongZipCode(true);
                setAutocompleteSearch('');
            };
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [affiliateFilterZipCode],
    );

    /* Effects */
    useEffect(() => {
        if (affiliateFilterZipCode && affiliateFilterZipCode.length !== 5) {
            setValue('affiliate', '');
            setAutocompleteSearch('');
        }
    }, [affiliateFilterZipCode, setValue]);

    useEffect(() => {
        const affiliatesList = affiliates?.virtualCareAffiliates?.results || [];
        setAutocompleteOptions(
            (affiliatesList || []).map(a => ({
                title: a.externalName as string,
                value: a.affiliateId as string,
            })),
        );
        setShouldDisableAutocomplete(!autocompleteSearch && affiliatesList.length === 0);
    }, [affiliates?.virtualCareAffiliates?.results, autocompleteSearch]);

    useEffect(() => {
        fetchAffiliatesDebounced(autocompleteSearch);
    }, [autocompleteSearch, fetchAffiliatesDebounced]);

    useEffect(() => {
        if (showProviderBlock) {
            setValue('affiliateFilterZipCode', affiliateFilterZipCode, { shouldTouch: true });
            setShouldDisableNextButton(!selectedAffiliate);
        } else {
            setShouldDisableNextButton(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [showProviderBlock, selectedAffiliate]);

    const getSelectLabel = () => {
        if (
            !affiliateFilterZipCode ||
            affiliateFilterZipCode.length !== 5 ||
            autocompleteSearch ||
            affiliatesLoading
        ) {
            return NO_AFFILIATE_FOUND_LABEL;
        }

        return shouldDisableAutocomplete ? NO_AFFILIATE_FOUND_LABEL : 'Please select a Provider';
    };

    const renderSelectValue = (selected: string) => {
        if (affiliatesLoading) {
            return <CircularProgress size={20} />;
        }

        if (
            autocompleteOptions.length === 0 ||
            (affiliateFilterZipCode && affiliateFilterZipCode.length !== 5)
        ) {
            return NO_AFFILIATE_FOUND_LABEL;
        }

        const selectedOption = autocompleteOptions.find(option => option.value === selected);
        return selectedOption ? selectedOption.title : '';
    };

    return (
        <>
            <Grid container>
                <Grid item xs={12} className={classes.subHeader} textAlign="center">
                    <Typography paragraph variant="h6">
                        Please select your preferred appointment method (Virtual or In-Person)
                    </Typography>
                </Grid>
                <Grid
                    item
                    xs={12}
                    alignItems="center"
                    style={{ marginBottom: isMobileView ? '15px' : '60px' }}
                >
                    <Box mb={6} />
                    <Grid
                        container
                        spacing={2}
                        alignItems={isMobileView ? 'center' : 'stretch'}
                        direction={isMobileView ? 'column' : 'row'}
                        justifyContent="space-around"
                        style={{ margin: 0, width: '100%' }}
                    >
                        <Grid container alignItems="center" direction="column" xs={12} sm={6}>
                            <ButtonBase
                                onClick={() => {
                                    setValue('appointmentMethod', AppointmentMethodEnum.Virtual);
                                }}
                                style={{
                                    padding: isMobileView ? '0 30px' : '0 15px',
                                    width: '100%',
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    className={
                                        type === AppointmentMethodEnum.Virtual
                                            ? classes.selectedCard
                                            : ''
                                    }
                                    style={{
                                        padding: isMobileView ? 0 : '0 30px',
                                        width: '100%',
                                        minHeight: '265px',
                                    }}
                                >
                                    <CardContent style={{ paddingTop: '5px' }}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="space-around"
                                        >
                                            <CheckCircleOutlineOutlined
                                                style={{
                                                    fontSize: '40px',
                                                    color:
                                                        type === AppointmentMethodEnum.Virtual
                                                            ? 'black'
                                                            : 'white',
                                                }}
                                            />
                                            <Typography variant="h4">Virtual</Typography>
                                            <Typography variant="body2">
                                                Telehealth Appointment
                                            </Typography>
                                            <DesktopMacOutlined
                                                style={{
                                                    fontSize: isMobileView ? '60px' : '100px',
                                                    marginTop: '10px',
                                                }}
                                            />
                                            {isMobileView && (
                                                <Collapse
                                                    in={type === AppointmentMethodEnum.Virtual}
                                                >
                                                    <ListItems items={VirtualListItems} />
                                                </Collapse>
                                            )}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </ButtonBase>
                            {!isMobileView && <ListItems items={VirtualListItems} />}
                        </Grid>
                        <Grid xs={12} sm={6} container alignItems="center" direction="column">
                            <ButtonBase
                                onClick={() => {
                                    setValue('appointmentMethod', AppointmentMethodEnum.AtHome);
                                }}
                                style={{
                                    padding: isMobileView ? '0 30px' : '0 15px',
                                    width: '100%',
                                }}
                            >
                                <Card
                                    variant="outlined"
                                    className={
                                        type === AppointmentMethodEnum.AtHome
                                            ? classes.selectedCard
                                            : ''
                                    }
                                    style={{
                                        padding: isMobileView ? 0 : '0 30px',
                                        width: '100%',
                                        minHeight: '265px',
                                    }}
                                >
                                    <CardContent style={{ paddingTop: '5px' }}>
                                        <Grid
                                            container
                                            direction="column"
                                            alignItems="center"
                                            justifyContent="space-around"
                                            style={{ padding: '0 30px' }}
                                        >
                                            <CheckCircleOutlineOutlined
                                                style={{
                                                    fontSize: '40px',
                                                    color:
                                                        type === AppointmentMethodEnum.AtHome
                                                            ? 'black'
                                                            : 'white',
                                                }}
                                            />
                                            <Typography variant="h4">In-Person Consult</Typography>
                                            <Typography variant="body2">
                                                Be Seen At Home or In-Office
                                            </Typography>
                                            <HomeWorkOutlined
                                                style={{
                                                    fontSize: isMobileView ? '60px' : '100px',
                                                    marginTop: '30px',
                                                }}
                                            />
                                            {isMobileView && (
                                                <Collapse
                                                    in={type === AppointmentMethodEnum.AtHome}
                                                >
                                                    <ListItems items={HomeListItems} />
                                                </Collapse>
                                            )}
                                        </Grid>
                                    </CardContent>
                                </Card>
                            </ButtonBase>
                            {!isMobileView && <ListItems items={HomeListItems} />}
                            {type === AppointmentMethodEnum.AtHome && (
                                <Grid
                                    style={{
                                        marginTop: '15px',
                                        width: '100%',
                                        padding: '0 8px',
                                    }}
                                >
                                    <Controller
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <ToggleButtonSwitch
                                                onChange={(_, value) => {
                                                    if (value !== null) {
                                                        onChange(value);
                                                        setValue('preferredProvider', value);
                                                    }
                                                }}
                                                label="Do you have a preferred provider?"
                                                fullWidth
                                                reversedButtons
                                                value={value ?? false}
                                            />
                                        )}
                                        name="preferredProvider"
                                    />
                                    <FormHelperText error={!!errors.preferredProvider}>
                                        {errors.preferredProvider?.message as string}
                                    </FormHelperText>
                                </Grid>
                            )}
                            {showProviderBlock && (
                                <Grid style={{ width: '100%', padding: '0 15px' }}>
                                    {isEditZipCode && (
                                        <Grid
                                            md={12}
                                            xs={12}
                                            style={{
                                                display: 'flex',
                                                height: '40px',
                                                marginTop: '30px',
                                            }}
                                            alignItems="center"
                                        >
                                            <TextField
                                                fullWidth
                                                label="Zip Code"
                                                {...register('affiliateFilterZipCode')}
                                                error={!!errors.affiliateFilterZipCode}
                                                helperText={
                                                    errors.affiliateFilterZipCode?.message as string
                                                }
                                            />
                                        </Grid>
                                    )}
                                    <Grid
                                        style={{
                                            display: 'flex',
                                            paddingLeft: '10px',
                                            marginTop:
                                                isMobileView && isEditZipCode ? '30px' : '20px',
                                            height: '23px',
                                        }}
                                    >
                                        <Typography fontWeight="bold">
                                            Providers servicing Zip Code:
                                        </Typography>
                                        <Typography style={{ padding: '0 8px' }}>
                                            {affiliateFilterZipCode}
                                        </Typography>
                                        {!isEditZipCode && (
                                            <>
                                                <Typography component="span">(</Typography>
                                                <Button
                                                    variant="text"
                                                    onClick={() => setIsEditZipCode(true)}
                                                    style={{
                                                        padding: 0,
                                                        minWidth: 0,
                                                        height: '26px',
                                                    }}
                                                >
                                                    Edit
                                                </Button>
                                                <Typography component="span">)</Typography>
                                            </>
                                        )}
                                    </Grid>
                                    <Grid md={12} xs={12}>
                                        <Controller
                                            control={control}
                                            name="affiliate"
                                            defaultValue=""
                                            render={({ field: { onChange, value } }) => (
                                                <FormControl fullWidth>
                                                    <InputLabel id="affiliate-label">
                                                        {getSelectLabel()}
                                                    </InputLabel>
                                                    <Select
                                                        labelId="affiliate-label"
                                                        id="affiliate"
                                                        value={value || ''}
                                                        fullWidth
                                                        onChange={e => onChange(e.target.value)}
                                                        disabled={
                                                            shouldDisableAutocomplete ||
                                                            isWrongZipCode
                                                        }
                                                        label={getSelectLabel()}
                                                        renderValue={selected =>
                                                            renderSelectValue(selected)
                                                        }
                                                        MenuProps={{
                                                            classes: { paper: classes.menuPaper },
                                                            marginThreshold: null,
                                                            disableScrollLock: false,
                                                            anchorOrigin: {
                                                                vertical: 'top',
                                                                horizontal: 'center',
                                                            },
                                                            transformOrigin: {
                                                                vertical: 'bottom',
                                                                horizontal: 'center',
                                                            },
                                                            PaperProps: {
                                                                style: {
                                                                    maxHeight: 200,
                                                                    overflowY: 'auto',
                                                                },
                                                            },
                                                        }}
                                                    >
                                                        {affiliatesLoading ? (
                                                            <MenuItem disabled>
                                                                <CircularProgress size={20} />
                                                            </MenuItem>
                                                        ) : (
                                                            autocompleteOptions.map(option => (
                                                                <MenuItem
                                                                    key={option.value}
                                                                    value={option.value}
                                                                >
                                                                    {option.title}
                                                                </MenuItem>
                                                            ))
                                                        )}
                                                    </Select>
                                                </FormControl>
                                            )}
                                        />
                                    </Grid>
                                </Grid>
                            )}
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            <SupportBlock isMobileView={isMobileView} align="center" />
            <FooterDivider isMobileView={isMobileView} />
            <ButtonContainer
                nextButton={{
                    handler: stepHandler,
                    label:
                        type === AppointmentMethodEnum.OnDemand
                            ? 'Continue to App Store'
                            : 'Continue',
                    disabled: stepLoading || !type || shouldDisableNextButton,
                    loading: stepLoading,
                }}
                prevButton={{
                    handler: backHandler,
                    label: 'Back',
                }}
            />
        </>
    );
};

export default NewAppointmentMethod;
