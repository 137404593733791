import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Divider,
    FormControl,
    Grid,
    Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    AlertSeverity,
    useDeleteVirtualCareAffiliateMutation,
    useFetchVirtualCareAffiliateByIdQuery,
    useSubmitHubspotFormMutation,
    useUpdateVirtualCareAffiliatesMutation,
    VirtualCareAffiliatesUpdateInput,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { BackToVCAffiliatesList } from '~/views/ConfigDashboard/VCAffiliates/components/BackToVCAffiliatesList';
import { VCAffiliateForm } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliateForm';
import { VCAffiliateFormContextProvider } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliateFormContextProvider';
import { VCAffiliatesURLs } from '~/views/ConfigDashboard/VCAffiliates/components/VCAffiliatesURLs';
import { mapToFormValues } from '~/views/ConfigDashboard/VCAffiliates/mappers';
import Navigation, { TabsConfig } from '~/views/Dashboard/VCPatients/Navigation';
import { VCAffiliatesHistory } from './VCAffiliatesHistory/VCAffiliatesHistory';
import StatusesMenu from './components/StatusesMenu';

export const VCAffiliatesEdit = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const handleCancel = () => {
        navigate('/app-config/vcaffiliates');
    };
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);

    const { data, refetch } = useFetchVirtualCareAffiliateByIdQuery({
        variables: {
            input: {
                id: id ?? '',
            },
        },
        onError: () => {
            TriggerGlobalAlert({ severity: AlertSeverity.Error, message: 'Fetching failed' });
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });

    const [updateVirtualCareAffiliate] = useUpdateVirtualCareAffiliatesMutation({
        onCompleted: data => {
            if (data.updateVirtualCareAffiliate?.success) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Affiliate updated',
                });
                navigate('/app-config/vcaffiliates');
            } else {
                TriggerGlobalAlert({ severity: AlertSeverity.Error, message: 'Update failed' });
            }
        },
    });

    const [submitHubspotForm] = useSubmitHubspotFormMutation({
        onCompleted: data => {
            if (data.submitHubspotForAffiliate?.success) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Hubspot Form submitted',
                });
            } else {
                TriggerGlobalAlert({ severity: AlertSeverity.Error, message: 'Submit failed' });
            }
        },
    });

    const handleSubmit = (data: VirtualCareAffiliatesUpdateInput) => {
        if (id) {
            updateVirtualCareAffiliate({ variables: { input: { id, data } } });
        }
    };
    const [deleteVirtualCareAffiliate] = useDeleteVirtualCareAffiliateMutation({
        onCompleted: data => {
            if (data.deleteVirtualCareAffiliate?.success) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Affiliate was deleted',
                });
                navigate('/app-config/vcaffiliates');
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'Affiliate deletion failed',
                });
            }
        },
    });
    const handleDelete = () => {
        if (id) {
            TriggerGlobalConfirm({
                callback: () => deleteVirtualCareAffiliate({ variables: { input: id } }),
                message: 'Are you sure you want to delete this Affiliate?',
            });
        }
    };
    const affiliateFormValues = React.useMemo(() => mapToFormValues(data), [data]);

    const affiliateId = data?.virtualCareAffiliateById?.data?.affiliateId;
    const affiliateURL = data?.virtualCareAffiliateById?.affiliateURL;
    const affiliateSelfCheckURL = data?.virtualCareAffiliateById?.affiliateSelfCheckURL;
    const affiliateHistory = data?.virtualCareAffiliateById?.data?.history;
    const contactEmail = data?.virtualCareAffiliateById?.data?.contactEmail;

    const handleSubmitHubspot = async () => {
        if (affiliateId && contactEmail) {
            await submitHubspotForm({ variables: { input: { affiliateId, contactEmail } } });
            await refetch();
        }
        setIsModalOpen(false);
    };

    return (
        <>
            <Navigation
                currentTab={TabsConfig.config}
                affiliateId={affiliateId}
                id={id}
                name={data?.virtualCareAffiliateById?.data?.internalName || ''}
            />
            <BackToVCAffiliatesList />
            <Grid container alignItems="center" spacing={1}>
                <Grid item>
                    <Typography variant="h6" className="title" paddingLeft="10px">
                        Edit VC Affiliate
                    </Typography>
                </Grid>
                {affiliateId && contactEmail && (
                    <Grid item>
                        <Button
                            onClick={() => setIsModalOpen(true)}
                            color="secondary"
                            variant="outlined"
                        >
                            Submit Onboarding Email
                        </Button>
                    </Grid>
                )}
            </Grid>
            <Divider style={{ marginTop: '10px', marginBottom: '20px' }} />
            <Grid container columnSpacing={4} justifyContent="space-between">
                <Grid item xs={12} sm={6}>
                    <VCAffiliatesURLs
                        affiliateId={affiliateId}
                        affiliateURL={affiliateURL}
                        affiliateSelfCheckURL={affiliateSelfCheckURL}
                    />
                </Grid>
                <Grid item xs={12} sm={5}>
                    <StatusesMenu affiliateId={id as string} />
                </Grid>
            </Grid>
            <VCAffiliateFormContextProvider>
                <VCAffiliateForm
                    handleFormSubmit={handleSubmit}
                    values={affiliateFormValues}
                    isEdit
                >
                    <Grid xs={12} container item justifyContent="space-between">
                        <Grid>
                            <FormControl>
                                <Button variant="outlined" onClick={handleCancel}>
                                    Cancel
                                </Button>
                            </FormControl>
                            <FormControl>
                                <Button type="submit" color="secondary" variant="contained">
                                    Save
                                </Button>
                            </FormControl>
                        </Grid>
                        <Grid>
                            <FormControl>
                                <Button color="error" variant="contained" onClick={handleDelete}>
                                    Delete
                                </Button>
                            </FormControl>
                        </Grid>
                    </Grid>
                </VCAffiliateForm>
            </VCAffiliateFormContextProvider>
            <VCAffiliatesHistory history={affiliateHistory} />
            <Dialog
                scroll="paper"
                open={Boolean(isModalOpen)}
                fullWidth
                maxWidth="xs"
                aria-labelledby="form-dialog-title"
            >
                <DialogContent>
                    <Typography>
                        Are you sure you want to submit hubspot form for this affiliate?
                    </Typography>
                </DialogContent>
                <DialogActions style={{ justifyContent: 'flex-start', padding: '16px' }}>
                    <Button
                        onClick={handleSubmitHubspot}
                        type="submit"
                        color="secondary"
                        variant="contained"
                    >
                        Submit
                    </Button>
                    <Button
                        onClick={() => setIsModalOpen(false)}
                        color="secondary"
                        variant="outlined"
                    >
                        Cancel
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};
