import ExcelJS, { Table, Workbook, Worksheet } from 'exceljs';
import { saveAs } from 'file-saver';
import { format, toDate } from 'date-fns-tz';
import { LogoBase64 } from '~/components/Logo/LogoBase64';
import { displayDateLocale } from '~/helpers';
import { replaceHTMLTags } from '~/helpers/stringHelpers';
import {
    FetchCurrentUserForUseUserHookQuery,
    FetchMeasurementsForPatientMeasurementsPageDocument,
    FetchMeasurementsForPatientMeasurementsPageQuery,
    FetchPatientForPatientOverviewPageQuery,
    FetchSurveyResponsesExpandedForPatientSurveyPageDocument,
    FetchSurveyResponsesExpandedForPatientSurveyPageQuery,
    MeasurementType,
    SurveyResponseStatusType,
} from '~/schemaTypes';
import { LBS } from '~/components/WeightValue/WeightValue';
import { client } from '../../../../../ApolloClientWrapper';
import {
    MealOption,
    mealOptionHeader,
} from '../PatientMeasurements/BloodGlucose/BloodGlucoseMeasurementTable';
import {
    COLUMN_WIDTH,
    formatBackupLogo,
    formatContentRange,
    formatCustomWidthColumns,
    formatFlag,
    formatLongColumns,
    formatMediumLongColumns,
    formatSheet,
    formatShortColumns,
    formatTableBorders,
    formatTableTitle,
    formatTitle,
} from './PatientSummaryExcelTemplate';

type Patient = NonNullable<FetchPatientForPatientOverviewPageQuery['patient']>;
type CurrentUser = NonNullable<FetchCurrentUserForUseUserHookQuery['currentUser']>;
type getTablesType = { table: Table; worksheet: Worksheet };

const TABLE_TITLE_OFFSET = 1;
const TABLE_ELEMENT_OFFSET = 2;
const PATIENT_SUMMARY_HEADER_OFFSET = 3;
const WF_LOGO_OFFSET = 4;
const COLUMN_WIDTH_OFFSET = 5;
const FLAGGED_SYMBOL = '▲';

const dateRangeFilter = (date: Date, startDate: Date | null, endDate: Date | null) => {
    if (startDate && endDate) {
        return startDate.getTime() <= date.getTime() && endDate.getTime() >= date.getTime();
    }
    return true;
};

const addWFLogo = (ws: Worksheet, imageId: number) => {
    ws.addImage(imageId, {
        tl: { col: 0, row: 0 },
        ext: { width: 400, height: 70 },
        editAs: 'absolute',
    });
    ws.getCell(1, 1).value = 'Wildflower Health';
    formatBackupLogo(ws, 1, 1);
};

const createPatientSummaryInformationTable = (
    ws: Worksheet,
    patient: Patient,
    currentUser: CurrentUser,
    startRow: number,
): void => {
    const templateRows = [
        patient?.fullName ? ['PATIENT NAME', patient.fullName] : null,
        patient?.birthDate
            ? ['DOB', format(toDate(patient.birthDate.split('T')[0]), 'M/d/yyyy')]
            : null,
        // ['MRN', ''], // TODO: NOT IMPLEMENTED YET
        patient?.practice?.name ? ['PRACTICE', patient.practice.name] : null,
        patient?.practice?.providers?.length
            ? [
                  'PROVIDERS',
                  patient?.practice?.providers.map(obj => obj.provider.localizedName).join(', '),
              ]
            : null,
        ['SUMMARY DATE', new Date().toLocaleDateString()],
        currentUser?.name ? ['SENT BY', currentUser.name] : null, // person generating report
        currentUser?.smsNumber ? ['CONTACT PHONE', currentUser.smsNumber] : null, // HA's phone number
    ].filter(row => row != null);
    ws.insertRows(WF_LOGO_OFFSET + 1, templateRows);
    formatContentRange(ws, startRow, templateRows.length + startRow, 1, 2, true);
};

const createHealthAdvCoachingNotesSheet = async (
    workbook: Workbook,
    imageId: number,
    patient: Patient,
    currentUser: CurrentUser,
    startDate: Date | null,
    endDate: Date | null,
): Promise<void> => {
    const healthAdvCoachingNotesSheet = workbook.addWorksheet('Notes for Provider');
    addWFLogo(healthAdvCoachingNotesSheet, imageId);
    // Template table, returns starting row for additional content
    createPatientSummaryInformationTable(
        healthAdvCoachingNotesSheet,
        patient,
        currentUser,
        WF_LOGO_OFFSET,
    );
    const patientSummaryOffset =
        healthAdvCoachingNotesSheet.rowCount + PATIENT_SUMMARY_HEADER_OFFSET;
    healthAdvCoachingNotesSheet.getCell(`A${patientSummaryOffset}:D${patientSummaryOffset}`).value =
        'NOTES FOR PROVIDER';
    const healthAdvAndCoachingColumns = [
        {
            name: 'Date',
        },
        {
            name: 'Time',
        },
        {
            name: 'Notes',
        },
        {
            name: 'Health Advocate',
        },
    ];
    // Table
    healthAdvCoachingNotesSheet.addTable({
        name: 'HealthAdvAndCoachingNotesTable',
        ref: `A${healthAdvCoachingNotesSheet.rowCount + TABLE_ELEMENT_OFFSET}`,
        columns: healthAdvAndCoachingColumns,
        rows: patient?.notes.length
            ? patient?.notes
                  .filter(note => dateRangeFilter(new Date(note.createdAt), startDate, endDate))
                  .map(note => [
                      new Date(note.createdAt).toLocaleDateString(),
                      new Date(note.createdAt).toLocaleTimeString(),
                      typeof note.text === 'string' ? replaceHTMLTags(note.text) : note.text,
                      note.staffName,
                  ])
            : [[]],
    });
    formatSheet(healthAdvCoachingNotesSheet);
    formatTitle(healthAdvCoachingNotesSheet, patientSummaryOffset);
    formatContentRange(
        healthAdvCoachingNotesSheet,
        patientSummaryOffset + TABLE_ELEMENT_OFFSET,
        healthAdvCoachingNotesSheet.rowCount - 1,
        1,
        healthAdvCoachingNotesSheet.columnCount,
    );
    healthAdvCoachingNotesSheet.getTables().forEach(table => {
        const typedTable = (table as unknown as getTablesType).table;
        const startRow = Number.parseInt(typedTable.ref.replace(/[A-Z]+/, ''), 10);
        formatTableBorders(
            healthAdvCoachingNotesSheet,
            startRow,
            startRow + typedTable.rows.length,
            1,
            typedTable.columns.length,
        );
    });
    formatMediumLongColumns(healthAdvCoachingNotesSheet, 3);
    formatCustomWidthColumns(
        healthAdvCoachingNotesSheet,
        2,
        COLUMN_WIDTH.default + COLUMN_WIDTH_OFFSET,
    ); // widen column B to fit info
};

const createMeasurementsSheet = async (
    workbook: Workbook,
    imageId: number,
    patient: Patient,
    currentUser: CurrentUser,
    startDate: Date | null,
    endDate: Date | null,
): Promise<void> => {
    const measurementsData =
        async (): Promise<FetchMeasurementsForPatientMeasurementsPageQuery> => {
            const res = await client
                .query({
                    query: FetchMeasurementsForPatientMeasurementsPageDocument,
                    variables: {
                        patientInput: {
                            id: patient?.id,
                        },
                    },
                })
                .then(d => d.data);
            return res;
        };

    const tableTitleRows: { row: number; width: number }[] = [];
    const tableSubtitleRows: { row: number; startCol: number; width: number }[] = [];
    const measurementsSheet = workbook.addWorksheet('Measurements');
    const defaultWidthColumns: number[] = [];
    const flaggedCellRange: { row: number; startCol: number; height: number }[] = [];
    addWFLogo(measurementsSheet, imageId);
    createPatientSummaryInformationTable(measurementsSheet, patient, currentUser, WF_LOGO_OFFSET);
    const patientSummaryOffset = measurementsSheet.rowCount + PATIENT_SUMMARY_HEADER_OFFSET;
    measurementsSheet.getCell(`A${patientSummaryOffset}:D${patientSummaryOffset}`).value =
        'PATIENT AT-HOME MEASUREMENTS';
    const patientMeasurementsData = await measurementsData();
    const bloodPressureData = patientMeasurementsData.patient?.measurements
        ?.filter(m => m.value.bloodPressure != null)
        .filter(m => dateRangeFilter(new Date(m.takenDate), startDate, endDate))
        .map(measurement => {
            return [
                new Date(measurement.takenDate).toLocaleDateString(),
                new Date(measurement.takenDate).toLocaleTimeString(),
                `${measurement.value.bloodPressure?.systolic}/${measurement.value.bloodPressure?.diastolic} mm/Hg`,
                measurement.flagged ? FLAGGED_SYMBOL : null,
                measurement.value.bloodPressure?.pulse,
            ];
        });
    const bloodPressureColumns = [
        {
            name: 'Date',
        },
        {
            name: 'Time',
        },
        {
            name: 'Entry',
        },
        {
            name: 'Flag',
        },
        {
            name: 'Pulse',
        },
    ];
    tableTitleRows.push({
        row: measurementsSheet.rowCount + TABLE_ELEMENT_OFFSET,
        width: bloodPressureColumns.length,
    }); // Add Title location
    measurementsSheet.getCell(measurementsSheet.rowCount + TABLE_ELEMENT_OFFSET, 1).value =
        'Blood Pressure';
    const bloodPressureStartRow = measurementsSheet.rowCount + TABLE_TITLE_OFFSET;
    measurementsSheet.addTable({
        name: 'BloodPressureTable',
        ref: `A${bloodPressureStartRow}`,
        columns: bloodPressureColumns,
        rows: bloodPressureData?.length ? bloodPressureData : [[]],
    });
    flaggedCellRange.push({
        row: bloodPressureStartRow + 1,
        startCol: bloodPressureColumns.length - 1,
        height: bloodPressureData?.length || 0,
    });

    const bloodGlucoseData: [
        {
            takenDate: string;
            glucose?: number;
            dateTime?: string;
            mealTime?: string;
            mealOption?: string;
            meal?: string;
            notes?: string;
            flagged?: boolean;
        },
    ][] = Object.values(
        patientMeasurementsData.patient?.measurements
            .filter(m => m.type === MeasurementType.BloodGlucose)
            .filter(m => dateRangeFilter(new Date(m.takenDate), startDate, endDate))
            .map(m => {
                return {
                    takenDate: m.takenDate,
                    glucose: m.value.bloodGlucose?.glucose,
                    dateTime: m.value.bloodGlucose?.dateTime,
                    mealTime: m.value.bloodGlucose?.mealTime,
                    mealOption: m.value.bloodGlucose?.mealOption,
                    meal: m.value.bloodGlucose?.meal,
                    notes: m.value.bloodGlucose?.notes,
                    flagged: m.flagged,
                };
            })
            .reduce((r, c) => {
                const dateString = displayDateLocale({
                    isoDateStr: c.takenDate,
                    format: 'YYYY/MM/DD',
                });
                r[dateString] = r[dateString] || [];
                r[dateString].push(c);
                return r;
            }, Object.create(null)),
    );
    const bloodGlucoseFastingColumns = [
        {
            name: 'Date',
        },
        {
            name: 'Time',
        },
        {
            name: 'BGL',
        },
        {
            name: 'Flag',
        },
    ];
    const bloodGlucoseAfterMealColumns = [
        {
            name: 'Entry Time',
        },
        {
            name: 'BGL',
        },
        {
            name: 'Meal Time',
        },
        {
            name: 'Meal Notes',
        },
        {
            name: 'Other Notes',
        },
        {
            name: 'Flag',
        },
    ];
    tableTitleRows.push({
        row: measurementsSheet.rowCount + TABLE_ELEMENT_OFFSET,
        width:
            bloodGlucoseFastingColumns.length +
            bloodGlucoseAfterMealColumns.length * (Object.keys(MealOption).length - 1),
    }); // Add Title location
    measurementsSheet.getCell(measurementsSheet.rowCount + TABLE_ELEMENT_OFFSET, 1).value =
        'Blood Glucose';

    const subHeaderRow = measurementsSheet.rowCount + 1;
    tableSubtitleRows.push({
        row: subHeaderRow,
        startCol: 1,
        width: 1,
    });
    measurementsSheet.getCell(subHeaderRow, 2).value = mealOptionHeader.fasting;
    tableSubtitleRows.push({
        row: subHeaderRow,
        startCol: 2,
        width: bloodGlucoseFastingColumns.length,
    });
    for (let i = 1; i < Object.keys(mealOptionHeader).length; i++) {
        let subHeaderCol = 0;
        if (i === 1) {
            subHeaderCol = bloodGlucoseFastingColumns.length + 1;
        } else {
            subHeaderCol =
                bloodGlucoseFastingColumns.length +
                1 +
                bloodGlucoseAfterMealColumns.length * (i - 1);
        }
        measurementsSheet.getCell(subHeaderRow, subHeaderCol).value =
            Object.values(mealOptionHeader)[i];
        tableSubtitleRows.push({
            row: subHeaderRow,
            startCol: subHeaderCol,
            width: subHeaderCol + bloodGlucoseAfterMealColumns.length - 1,
        });
    }
    const bloodGlucoseFastingRows: any[][] = [];
    const bloodGlucosePostBreakfastRows: any[][] = [];
    const bloodGlucosePostLunchRows: any[][] = [];
    const bloodGlucosePostDinnerRows: any[][] = [];
    bloodGlucoseData
        .sort((a, b) => (new Date(a[0].takenDate) > new Date(b[0].takenDate) ? 1 : -1))
        .forEach(day => {
            const fastingMeasurements = day.filter(
                measurement => measurement.mealOption === MealOption.fasting,
            );
            const postBreakfastMeasurements = day.filter(
                measurement => measurement.mealOption === MealOption.postBreakfast,
            );
            const postLunchMeasurements = day.filter(
                measurement => measurement.mealOption === MealOption.postLunch,
            );
            const postDinnerMeasurements = day.filter(
                measurement => measurement.mealOption === MealOption.postDinner,
            );
            const maxRows = Math.max(
                fastingMeasurements.length,
                postBreakfastMeasurements.length,
                postLunchMeasurements.length,
                postDinnerMeasurements.length,
            );
            const dateTaken = new Date(day[0].takenDate).toLocaleDateString();
            for (let i = 0; i < maxRows; i++) {
                const nullRow = [null, null, null, null, null, null];
                const fastingRow: any[] =
                    fastingMeasurements.length > i
                        ? [
                              dateTaken,
                              new Date(fastingMeasurements[i].takenDate).toLocaleTimeString(),
                              `${fastingMeasurements[i].glucose} mg/dL`,
                              fastingMeasurements[i].flagged ? FLAGGED_SYMBOL : null,
                          ]
                        : [dateTaken, null, null, null];
                const postBreakfastRow: any[] =
                    postBreakfastMeasurements.length > i
                        ? [
                              new Date(
                                  postBreakfastMeasurements[i].dateTime || '',
                              ).toLocaleTimeString(),
                              `${postBreakfastMeasurements[i].glucose} mg/dL`,
                              new Date(
                                  postBreakfastMeasurements[i].mealTime || '',
                              ).toLocaleTimeString(),
                              postBreakfastMeasurements[i].meal,
                              postBreakfastMeasurements[i].notes,
                              postBreakfastMeasurements[i].flagged ? FLAGGED_SYMBOL : null,
                          ]
                        : nullRow;
                const postLunchRow: any[] =
                    postLunchMeasurements.length > i
                        ? [
                              new Date(
                                  postLunchMeasurements[i].dateTime || '',
                              ).toLocaleTimeString(),
                              `${postLunchMeasurements[i].glucose} mg/dL`,
                              new Date(
                                  postLunchMeasurements[i].mealTime || '',
                              ).toLocaleTimeString(),
                              postLunchMeasurements[i].meal,
                              postLunchMeasurements[i].notes,
                              postLunchMeasurements[i].flagged ? FLAGGED_SYMBOL : null,
                          ]
                        : nullRow;
                const postDinnerRow: any[] =
                    postDinnerMeasurements.length > i
                        ? [
                              new Date(
                                  postDinnerMeasurements[i].dateTime || '',
                              ).toLocaleTimeString(),
                              `${postDinnerMeasurements[i].glucose} mg/dL`,
                              new Date(
                                  postDinnerMeasurements[i].mealTime || '',
                              ).toLocaleTimeString(),
                              postDinnerMeasurements[i].meal,
                              postDinnerMeasurements[i].notes,
                              postDinnerMeasurements[i].flagged ? FLAGGED_SYMBOL : null,
                          ]
                        : nullRow;
                bloodGlucoseFastingRows.push(fastingRow);
                bloodGlucosePostBreakfastRows.push(postBreakfastRow);
                bloodGlucosePostLunchRows.push(postLunchRow);
                bloodGlucosePostDinnerRows.push(postDinnerRow);
            }
        });
    const bloodGlucoseRows = [
        bloodGlucoseFastingRows,
        bloodGlucosePostBreakfastRows,
        bloodGlucosePostLunchRows,
        bloodGlucosePostDinnerRows,
    ];
    for (let i = 0; i < Object.keys(mealOptionHeader).length; i++) {
        const subHeaderCol =
            bloodGlucoseFastingColumns.length + 1 + bloodGlucoseAfterMealColumns.length * (i - 1);
        if (i === 0) {
            measurementsSheet.addTable({
                name: `BloodGlucose${Object.values(MealOption)[i]}Table`,
                ref: `A${subHeaderRow + 1}`,
                columns: bloodGlucoseFastingColumns,
                rows: bloodGlucoseRows[i],
            });
        } else {
            measurementsSheet.addTable({
                name: `BloodGlucose${Object.values(MealOption)[i]}Table`,
                ref: `${String.fromCharCode(64 + subHeaderCol)}${subHeaderRow + 1}`,
                columns: bloodGlucoseAfterMealColumns,
                rows: bloodGlucoseRows[i],
            });
        }
        const columnCount =
            bloodGlucoseAfterMealColumns.length * i + bloodGlucoseFastingColumns.length;
        flaggedCellRange.push({
            row: subHeaderRow + 2,
            startCol: columnCount,
            height: bloodGlucoseRows[i].length,
        });
        defaultWidthColumns.push(
            columnCount - 2,
            columnCount - 3,
            columnCount - 4,
            columnCount - 5,
        );
    }
    const weightMeasurementsData = patientMeasurementsData.patient?.measurements
        .filter(m => m.value.weight != null)
        .filter(m => dateRangeFilter(new Date(m.createdAt), startDate, endDate))
        .map(m => {
            if (m.value.weight?.measure == null || m.value.weight?.units == null) return [];

            const weight = `${(m.value.weight.measure * LBS).toFixed(2)} ${
                m.value.weight.units ?? ''
            }`;
            return [
                new Date(m.createdAt).toLocaleDateString(),
                new Date(m.createdAt).toLocaleTimeString(),
                weight,
            ];
        });
    const weightColumns = [
        {
            name: 'Date',
        },
        {
            name: 'Time',
        },
        {
            name: 'Entry',
        },
    ];
    tableTitleRows.push({
        row: measurementsSheet.rowCount + TABLE_ELEMENT_OFFSET,
        width: weightColumns.length,
    }); // Add Title location
    measurementsSheet.getCell(measurementsSheet.rowCount + TABLE_ELEMENT_OFFSET, 1).value =
        'Weight';
    measurementsSheet.addTable({
        name: 'WeightTable',
        ref: `A${measurementsSheet.rowCount + TABLE_TITLE_OFFSET}`,
        columns: [
            {
                name: 'Date',
            },
            {
                name: 'Time',
            },
            {
                name: 'Entry',
            },
        ],
        rows: weightMeasurementsData?.length ? weightMeasurementsData : [[]],
    });
    formatSheet(measurementsSheet);
    formatTitle(measurementsSheet, patientSummaryOffset);
    formatContentRange(
        measurementsSheet,
        patientSummaryOffset + TABLE_ELEMENT_OFFSET,
        measurementsSheet.rowCount,
        1,
        measurementsSheet.columnCount,
    );
    tableTitleRows.forEach(({ row, width }) => formatTableTitle(measurementsSheet, row, 1, width));
    tableSubtitleRows.forEach(({ row, startCol, width }) =>
        formatTableTitle(measurementsSheet, row, startCol, width, 'secondary'),
    );
    measurementsSheet.getTables().forEach(table => {
        const typedTable = (table as unknown as getTablesType).table;
        const startRow = Number.parseInt(typedTable.ref.replace(/[A-Z]+/, ''), 10);
        const startCol = typedTable.ref.charCodeAt(0) - 64;
        formatTableBorders(
            measurementsSheet,
            startRow,
            startRow + typedTable.rows.length,
            startCol,
            startCol + typedTable.columns.length - 1,
        );
    });
    for (let i = 4; i <= measurementsSheet.columnCount; i++) {
        if (!defaultWidthColumns.includes(i)) {
            formatShortColumns(measurementsSheet, i);
        }
    }
    flaggedCellRange.forEach(cellRange => {
        formatFlag(measurementsSheet, cellRange.row, cellRange.startCol, cellRange.height);
    });
    formatCustomWidthColumns(measurementsSheet, 2, COLUMN_WIDTH.default + COLUMN_WIDTH_OFFSET); // widen column B to fit info
};

const createSurveysSheet = async (
    workbook: Workbook,
    imageId: number,
    patient: Patient,
    currentUser: CurrentUser,
    startDate: Date | null,
    endDate: Date | null,
): Promise<void> => {
    const surveyData = async (): Promise<FetchSurveyResponsesExpandedForPatientSurveyPageQuery> => {
        const res = await client
            .query({
                query: FetchSurveyResponsesExpandedForPatientSurveyPageDocument,
                variables: {
                    input: {
                        filter: {
                            fields: {
                                patientId: patient?.id,
                                status: SurveyResponseStatusType.Completed,
                            },
                        },
                    },
                },
            })
            .then(d => d.data);
        return res;
    };

    const surveysSheet = workbook.addWorksheet('Surveys');
    addWFLogo(surveysSheet, imageId);
    createPatientSummaryInformationTable(surveysSheet, patient, currentUser, WF_LOGO_OFFSET);
    const patientSummaryOffset = surveysSheet.rowCount + PATIENT_SUMMARY_HEADER_OFFSET;
    surveysSheet.getCell(`A${patientSummaryOffset}:D${patientSummaryOffset}`).value =
        'PATIENT SCREENINGS AND SURVEY RESULTS';
    const patientSurveyData = await surveyData();

    patientSurveyData?.surveyResponseExpandedsV2?.results
        .filter(surveyRes => surveyRes?.isCoreSurvey)
        .filter(survey => dateRangeFilter(new Date(survey.responseDate), startDate, endDate))
        .forEach(survey => {
            surveysSheet.addTable({
                name: `SurveyTable${survey.id}`,
                ref: `A${surveysSheet.rowCount + TABLE_ELEMENT_OFFSET}`,
                columns: [
                    {
                        name: survey.surveyType ?? '',
                    },
                    {
                        name: 'Value',
                    },
                ],
                rows: [
                    ['Date Taken', new Date(survey.responseDate || '').toLocaleDateString()],
                    ['Time Taken', new Date(survey.responseDate || '').toLocaleTimeString()],
                    survey.surveyScore ? ['Score', survey.surveyScore] : ['Score', 0],
                    ...(survey.answers
                        ? survey.answers.map(answer => {
                              let answerText = '';
                              if (answer.answerText) {
                                  answerText = answer.answerText;
                              } else if (answer.answerTexts) {
                                  answerText = JSON.stringify(answer.answerTexts);
                              }
                              return [
                                  answer.questionText,
                                  answerText !== '"no value was provided by this ProfileVariable"'
                                      ? answerText?.replace(/^['"](.+)['"]$/g, '$1')
                                      : null,
                              ];
                          })
                        : []),
                ].filter(row => row.length) ?? [[]],
            });
        });
    formatSheet(surveysSheet);
    formatTitle(surveysSheet, patientSummaryOffset);
    formatContentRange(
        surveysSheet,
        patientSummaryOffset + TABLE_ELEMENT_OFFSET,
        surveysSheet.rowCount,
        1,
        surveysSheet.columnCount,
    );
    surveysSheet.getTables().forEach(table => {
        const typedTable = (table as unknown as getTablesType).table;
        const startRow = Number.parseInt(typedTable.ref.replace(/[A-Z]+/, ''), 10);
        formatTableBorders(
            surveysSheet,
            startRow,
            startRow + typedTable.rows.length,
            1,
            typedTable.columns.length,
        );
    });
    formatLongColumns(surveysSheet, 2);
    formatCustomWidthColumns(surveysSheet, 1, COLUMN_WIDTH.default + 5);
};

export const getPatientSummaryWorkbook = async (
    patient: Patient,
    currentUser: CurrentUser,
    notesTab: boolean,
    measurementsTab: boolean,
    surveysTab: boolean,
    startDate: Date | null,
    endDate: Date | null,
): Promise<void> => {
    const workbook = new ExcelJS.Workbook();
    workbook.creator = 'Wildflower Health';
    const workbookDate = new Date();
    workbook.created = workbookDate;
    workbook.modified = workbookDate;
    workbook.views = [
        {
            x: 0,
            y: 0,
            width: 10000,
            height: 20000,
            firstSheet: 0,
            activeTab: 0,
            visibility: 'visible',
        },
    ];
    const imageId = workbook.addImage({
        base64: LogoBase64,
        extension: 'jpeg',
    });

    if (notesTab) {
        createHealthAdvCoachingNotesSheet(
            workbook,
            imageId,
            patient,
            currentUser,
            startDate,
            endDate,
        );
    }
    if (measurementsTab) {
        await createMeasurementsSheet(workbook, imageId, patient, currentUser, startDate, endDate);
    }
    if (surveysTab) {
        await createSurveysSheet(workbook, imageId, patient, currentUser, startDate, endDate);
    }

    // Save workbook
    workbook.xlsx.writeBuffer().then(data => {
        const fileDate = displayDateLocale({
            isoDateStr: new Date().toISOString(),
            format: 'MM-DD-YYYY',
        });
        const blob = new Blob([data], {
            type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });
        saveAs(blob, `patientDataSummary_${fileDate}.xlsx`);
    });
};
