import React, { useRef, useState, useMemo } from 'react';
import { useUserPermissions } from '~/hooks';
import { PAGESIZE } from '~/constants';
import {
    EligibilityConfigsV2ForEligibilityConfigsPageQuery,
    OrderByDirectionEnum,
    useEligibilityConfigsV2ForEligibilityConfigsPageQuery,
} from '~/schemaTypes';
import MaterialTable, { Column, OrderByCollection } from '@material-table/core';
import tableIcons from '~/helpers/tableIcons';
import _ from 'lodash';
import { Badge, TablePagination, Typography } from '@mui/material';
import { errorRed, primaryColor, secondaryColor } from '~/theme/WildTheme';
import { useAtom } from 'jotai/index';
import { createCustomSort } from '~/helpers/materialTableHelper';
import { useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import EligibilityConfigsFilterModal from './EligibilityConfigsFilterModal/EligibilityConfigsFilterModal';
import {
    EligibilityConfigFiltersState,
    EligibilityConfigsListQueryVariablesState,
    EligibilityConfigsState,
    initialOrder,
} from './state';

export type EligibilityConfig =
    EligibilityConfigsV2ForEligibilityConfigsPageQuery['eligibilityConfigsV2']['results'][0];

export interface EligibilityCheckResultFieldsOrder {
    field: keyof EligibilityConfig;
    direction: OrderByDirectionEnum;
    isInit: boolean;
}

const EligibilityConfigs = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const history = useNavigate();
    const location = useLocation();
    const { pagePermissions } = useUserPermissions();
    const [isFilterModal, setIsFilterModal] = useState<boolean>(false);
    const [filters] = useAtom(EligibilityConfigFiltersState);
    const [{ page, rowsPerPage, order }, setEligibilityConfigsState] =
        useAtom(EligibilityConfigsState);
    const [eligibilityConfigsListQueryVariables] = useAtom(
        EligibilityConfigsListQueryVariablesState,
    );

    const { tableColumns, columnIdToSortingFieldMap } = useMemo(() => {
        const columns: ({
            field: keyof EligibilityConfig;
        } & Column<EligibilityConfig>)[] = [
            {
                title: 'Name',
                width: 'auto',
                field: 'name',
                customSort: createCustomSort('name'),
                tooltip: 'Name of the eligibility configuration',
            },
            {
                title: 'App Bundle Id',
                width: 'auto',
                field: 'appBundleId',
                customSort: createCustomSort('appBundleId'),
                tooltip: 'Bundle ID of the application this eligibility config is for',
            },
            {
                title: 'Unit Id',
                width: 'auto',
                field: 'unitId',
                customSort: createCustomSort('unitId'),
                tooltip: 'ID of the unit this eligibility check belongs to',
            },
            {
                title: 'Suspend Eligibility Check',
                width: 'auto',
                field: 'suspendEligibilityCheck',
                type: 'boolean',
                cellStyle: (_, data) => ({
                    color: data.suspendEligibilityCheck ? secondaryColor : errorRed,
                }),
                customSort: createCustomSort('suspendEligibilityCheck'),
                tooltip: 'If true, the config will not process eligibility checks',
            },
            {
                title: 'Suspend Eligibility Import',
                width: 'auto',
                field: 'suspendEligibilityImport',
                type: 'boolean',
                cellStyle: (_, data) => ({
                    color: data.suspendEligibilityImport ? secondaryColor : errorRed,
                }),
                customSort: createCustomSort('suspendEligibilityImport'),
                tooltip: 'If true, the config will not process imports',
            },
            {
                title: 'Last 20 Check Results',
                width: 'auto',
                field: 'eligibilityCheckResults',
                tooltip: 'Success rate of the latest eligibility checks',
                sorting: false,
                render: data => {
                    const results = data?.eligibilityCheckResults || [];
                    const successfulChecks = results.filter(check => check?.success).length;
                    const totalChecks = results.length;
                    const successRate =
                        totalChecks > 0 ? Math.round((successfulChecks / totalChecks) * 100) : 0;

                    return (
                        <Typography>
                            {successfulChecks}/{totalChecks} successful ({successRate}%)
                        </Typography>
                    );
                },
            },
            {
                title: 'Last 20 Import Results',
                width: 'auto',
                field: 'eligibilityImportResults',
                tooltip: 'Success rate of the latest eligibility imports',
                sorting: false,
                render: data => {
                    const results = data?.eligibilityImportResults || [];
                    const successfulImports = results.filter(
                        importResult => importResult?.success,
                    ).length;
                    const totalImports = results.length;
                    const successRate =
                        totalImports > 0 ? Math.round((successfulImports / totalImports) * 100) : 0;

                    return (
                        <Typography sx={{ color: successfulImports === 0 ? errorRed : 'inherit' }}>
                            {successfulImports}/{totalImports} successful ({successRate}%)
                        </Typography>
                    );
                },
            },
        ];

        const columnIdToSortingFieldMap = columns.reduce((accum, item, index) => {
            accum[index] = item.field;
            return accum;
        }, {} as Record<number, keyof EligibilityConfig>);

        return { tableColumns: columns, columnIdToSortingFieldMap };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [history, location]);

    const { data, loading: dataLoading } = useEligibilityConfigsV2ForEligibilityConfigsPageQuery({
        variables: eligibilityConfigsListQueryVariables,
    });

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
        setEligibilityConfigsState(state => ({
            ...state,
            page: pageNumber,
        }));

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setEligibilityConfigsState(state => ({
            ...state,
            rowsPerPage: pageSize,
        }));
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        if (!orderByCollection || orderByCollection.length === 0) {
            setEligibilityConfigsState(state => ({
                ...state,
                order: { ...initialOrder },
            }));
            return;
        }

        const orderBy = orderByCollection[0];
        const field = columnIdToSortingFieldMap[orderBy.orderBy];

        if (field) {
            setEligibilityConfigsState(state => ({
                ...state,
                order: {
                    direction:
                        orderBy.orderDirection.toUpperCase() === OrderByDirectionEnum.Asc
                            ? OrderByDirectionEnum.Asc
                            : OrderByDirectionEnum.Desc,
                    field,
                    isInit: field !== order.field,
                },
            }));
        }
    };

    return (
        <>
            <div ref={tableRef}>
                <MaterialTable<EligibilityConfig>
                    title="Eligibility Configs"
                    icons={tableIcons}
                    isLoading={dataLoading}
                    page={page}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onOrderCollectionChange={handleOrderChange}
                    data={_.cloneDeep(data?.eligibilityConfigsV2.results) ?? []}
                    actions={[
                        {
                            tooltip: 'Filter Eligibility Configs',
                            onClick: () => {
                                setIsFilterModal(true);
                            },
                            icon: () => (
                                <Badge
                                    badgeContent={
                                        Object.values(filters).filter(i => i !== undefined).length
                                    }
                                    color="error"
                                    style={{
                                        padding: '12px',
                                        backgroundColor: primaryColor,
                                        color: 'white',
                                        fontSize: '1rem',
                                        borderRadius: '1rem',
                                    }}
                                >
                                    Filter
                                </Badge>
                            ),
                            isFreeAction: true,
                        },
                        {
                            onClick: (_, data: EligibilityConfig) => history(data.id),
                            hidden: !(
                                pagePermissions?.EligibilityConfigs.Read ||
                                pagePermissions?.EligibilityConfigs.Edit
                            ),
                            icon: () => (
                                <FontAwesomeIcon
                                    icon={
                                        pagePermissions?.EligibilityConfigs.Edit
                                            ? faPenToSquare
                                            : faEye
                                    }
                                />
                            ),
                            tooltip: pagePermissions?.EligibilityConfigs.Edit
                                ? 'Edit Eligibility Config'
                                : 'View Eligibility Config',
                        },
                    ]}
                    columns={tableColumns}
                    options={{
                        search: false,
                        paging: true,
                        pageSize: rowsPerPage,
                        pageSizeOptions: [10, PAGESIZE, 50],
                        debounceInterval: 500,
                    }}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={data?.eligibilityConfigsV2.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                    }}
                />
            </div>
            <EligibilityConfigsFilterModal
                isOpen={isFilterModal}
                onClose={() => setIsFilterModal(false)}
            />
        </>
    );
};

export default EligibilityConfigs;
