import React from 'react';
import { GetTriggerInterventionSetProfileValueForPatientTranscriptQuery } from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type TriggerInterventionSetProfileValue = NonNullable<
    NonNullable<
        GetTriggerInterventionSetProfileValueForPatientTranscriptQuery['getTriggerInterventionSetProfileValueForPatientTranscript']
    >['items']
>[number];

export const TriggerInterventionSetProfileValueCard = ({
    intervention,
}: {
    intervention: TriggerInterventionSetProfileValue;
}) => {
    return (
        <BaseCard title="Trigger Intervention Set Profile Value" data={intervention}>
            <p className="m-0">{`Profile: ${intervention?.profileDef?.name || 'Unknown'}`}</p>
            <p className="m-0">{`Value: ${
                intervention?.event?.data?.profileValue?.str ||
                intervention?.event?.data?.profileValue?.num ||
                intervention?.event?.data?.profileValue?.bool ||
                intervention?.event?.data?.profileValue?.date ||
                intervention?.event?.data?.profileValue?.choiceId ||
                'Unknown'
            }`}</p>
        </BaseCard>
    );
};
