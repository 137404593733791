import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { makeStyles } from 'tss-react/mui';
import { Add } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    TrendTriggerListDocument,
    TrendTriggerListQuery,
    useDeleteTrendTriggerMutation,
    useTrendTriggerListQuery,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { Link, Tooltip } from '@mui/material';

const useStyles = makeStyles()({
    root: {},
});
const TrendTriggers: React.FC = () => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const history = useNavigate();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const { data: triggerData, loading: triggersLoading } = useTrendTriggerListQuery({
        variables: { input: {} },
    });
    const [deleteTrendTrigger, { loading: deleteTagLoading }] = useDeleteTrendTriggerMutation({
        update: (cache, res) => {
            const triggersQuery = cache.readQuery<TrendTriggerListQuery>({
                query: TrendTriggerListDocument,
            });
            const filteredTriggers =
                triggersQuery?.trendTriggersV2.results.filter(
                    item => item?.id !== res.data?.deleteTrendTrigger?.id,
                ) ?? [];
            if (triggersQuery?.trendTriggersV2.results) {
                cache.writeQuery<TrendTriggerListQuery>({
                    query: TrendTriggerListDocument,
                    data: {
                        ...triggersQuery,
                        trendTriggersV2: { results: filteredTriggers },
                    },
                });
            }
        },
        awaitRefetchQueries: true,
        notifyOnNetworkStatusChange: true,
        refetchQueries: [
            {
                query: TrendTriggerListDocument,
                variables: { input: {} },
            },
        ],
    });

    const handleDeleteTrigger = (id: string) => {
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Trend Trigger?',
            callback: () => {
                deleteTrendTrigger({ variables: { id } });
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Trend Triggers"
                icons={tableIcons}
                isLoading={triggersLoading || deleteTagLoading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(triggerData?.trendTriggersV2.results) ?? []}
                actions={[
                    {
                        onClick: () => history('/app-config/trendtriggers/new'),
                        hidden: !pagePermissions?.TrendTriggers.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Trend Trigger',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/trendtriggers/${id}`),
                        hidden: !pagePermissions?.TrendTriggers.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Trend Trigger',
                    },
                    {
                        icon: () => <FontAwesomeIcon icon={faTrash} />,
                        onClick: (_, { id }: any) => handleDeleteTrigger(id),
                        tooltip: 'Delete Trend Trigger',
                        hidden: !pagePermissions?.TrendTriggers.Delete,
                    },
                ]}
                columns={[
                    {
                        title: 'Name',
                        field: 'name',
                        render: ({ id, name }: any) => (
                            <Tooltip title="Edit In New Window">
                                <Link
                                    style={{ textDecoration: 'underline' }}
                                    target="_blank"
                                    href={`/app-config/trendtriggers/${id}`}
                                    rel="noopener noreferrer"
                                >
                                    {name}
                                </Link>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Last Updated',
                        field: 'updatedAt',
                        render: ({ updatedAt }) => (
                            <>
                                {displayDate({
                                    isoDateStr: updatedAt,
                                })}
                            </>
                        ),
                    },
                ]}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100] }}
                localization={{ header: { actions: '' } }}
            />
        </div>
    );
};

export default TrendTriggers;
