import * as Yup from 'yup';
import { VCUserType } from '../types';
import { BILL_COM_USER_TYPES } from './constants';

export const STAFF_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required(),
    email: Yup.string().required(),
    userType: Yup.string()
        .oneOf([VCUserType.Doula, VCUserType.Affiliate, VCUserType.LactationConsultant])
        .required(),
    affiliateId: Yup.string().when(['userType'], ([userType], schema) => {
        if (userType === VCUserType.Affiliate) {
            return schema.required();
        }
        return schema.nullable();
    }),
    createVendor: Yup.boolean().optional(),
    vendorId: Yup.string().when(
        ['userType', 'createVendor'],
        ([userType, createVendor], schema) => {
            if (BILL_COM_USER_TYPES.includes(userType) && !createVendor) {
                return schema.required();
            }
            return schema.nullable();
        },
    ),
    nameOnCheck: Yup.string().when(
        ['userType', 'createVendor'],
        ([userType, createVendor], schema) => {
            if (BILL_COM_USER_TYPES.includes(userType) && createVendor) {
                return schema.required();
            }
            return schema.nullable();
        },
    ),
    address1: Yup.string().when(
        ['userType', 'createVendor'],
        ([userType, createVendor], schema) => {
            if (BILL_COM_USER_TYPES.includes(userType) && createVendor) {
                return schema.required();
            }
            return schema.nullable();
        },
    ),
    address2: Yup.string().when(
        ['userType', 'createVendor'],
        ([userType, createVendor], schema) => {
            if (BILL_COM_USER_TYPES.includes(userType) && createVendor) {
                return schema.optional();
            }
            return schema.nullable();
        },
    ),
    city: Yup.string().when(['userType', 'createVendor'], ([userType, createVendor], schema) => {
        if (BILL_COM_USER_TYPES.includes(userType) && createVendor) {
            return schema.required();
        }
        return schema.nullable();
    }),
    state: Yup.string().when(['userType', 'createVendor'], ([userType, createVendor], schema) => {
        if (BILL_COM_USER_TYPES.includes(userType) && createVendor) {
            return schema.required();
        }
        return schema.nullable();
    }),
    zip: Yup.string().when(['userType', 'createVendor'], ([userType, createVendor], schema) => {
        if (BILL_COM_USER_TYPES.includes(userType) && createVendor) {
            return schema.required();
        }
        return schema.nullable();
    }),
    vendorName: Yup.string().when(
        ['userType', 'createVendor'],
        ([userType, createVendor], schema) => {
            if (BILL_COM_USER_TYPES.includes(userType) && createVendor) {
                return schema.required();
            }
            return schema.nullable();
        },
    ),
});
