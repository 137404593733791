import React, { useEffect, useMemo } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import {
    GetChatRoomInput,
    useFetchChatRoomMessagesForMessageCenterQuery,
    useFetchReferenceLinksForMessageCenterPageQuery,
    ReferencePages,
} from '~/schemaTypes';
import ChatMessages from '../ChatMessages/ChatMessages';

import { RouteParams } from '../../Patients/Patient/PatientTodos/types';

type ChatRoomProps = {
    chatRoomId: string;
    setLinks?: any;
    handleUnread?: () => void;
};

const ChatRoom: React.FC<ChatRoomProps> = ({ chatRoomId, setLinks, handleUnread }) => {
    const { id } = useParams<RouteParams>();

    const fetchChatRoomById: GetChatRoomInput = useMemo(() => {
        return {
            ...(chatRoomId !== '' && { id: chatRoomId }),
            ...(chatRoomId === '' && { fields: { patientId: id } }),
        };
    }, [chatRoomId, id]);

    const { data: chatRoomData, loading: chatRoomDataLoading } =
        useFetchChatRoomMessagesForMessageCenterQuery({
            variables: {
                input: fetchChatRoomById,
            },
        });

    const { data: referenceLinksData, loading: referenceLinksLoading } =
        useFetchReferenceLinksForMessageCenterPageQuery({
            variables: {
                input: {
                    page: ReferencePages.PatientMessageCenter,
                },
            },
        });

    useEffect(() => {
        if (referenceLinksData && setLinks) {
            setLinks(referenceLinksData.getReferenceLinksForPage);
        }
    }, [referenceLinksData, setLinks]);

    if (chatRoomDataLoading || referenceLinksLoading) {
        return (
            <div className="main">
                <Loading />
            </div>
        );
    }

    return chatRoomData ? (
        <ChatMessages
            getChatRoomInput={fetchChatRoomById}
            chatRoomData={chatRoomData.chatRoom}
            handleUnread={handleUnread}
        />
    ) : null;
};

export default ChatRoom;
