/* eslint-disable react/react-in-jsx-scope */
import { VirtualCareSurvey } from '../VirtualCareSurvey';
import { useInitVCF, VCFContext } from '../hooks/useVCF';

const DOULA_FORM_DESCRIPTION = [
    `Welcome to comprehensive childbirth education and doula services through Wildflower Health. Please securely provide your health information below. After you complete this form, you can schedule your session. To ensure personalized support, we recommend scheduling an initial session where your doula can learn about your specific needs and birth goals.`,
    `We currently support doula services for a limited number of Cigna insured employers and certain states with active insurance. The estimate of your healthcare dollars for doula sessions will be arriving soon in your email inbox. If you have a high deductible, we have flexibility in our program to maximize your success with minimal sessions.`,
];

const DoulaVCF = () => {
    const contextProps = useInitVCF({
        isDoulaView: true,
        formDescriptionText: DOULA_FORM_DESCRIPTION,
    });
    return (
        <VCFContext.Provider value={contextProps}>
            <VirtualCareSurvey pathname="/care-connect/doula" />
        </VCFContext.Provider>
    );
};

export default DoulaVCF;
