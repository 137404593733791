import * as yup from 'yup';
import {
    EligibilityFileDataType,
    EligibilityFileProcessingType,
    EligibilityTransformFunctionType,
    EligibilityType,
} from '~/schemaTypes';
import { EligibilityConfigFormData } from './EligibilityConfigForm';

export const EligibilityConfigFromSchema: yup.ObjectSchema<EligibilityConfigFormData> = yup
    .object()
    .shape({
        __typename: yup.mixed().strip(),
        appBundleId: yup.string().nullable(),
        name: yup.string().required('name is required'),
        unitId: yup.string().required('unitId is required'),
        organizationId: yup.mixed().required('organization is required'),
        type: yup.mixed<EligibilityType>().required('type is required'),
        fileType: yup.mixed<EligibilityFileDataType>().nullable(),
        processingMethod: yup.mixed<EligibilityFileProcessingType>().nullable(),
        containHeader: yup.boolean().nullable(),
        containFooter: yup.boolean().nullable(),
        delimiter: yup.string().nullable(),
        configVersion: yup
            .string()
            .matches(/^[0-9a-fA-F]{24}$/, 'Invalid ObjectId')
            .nullable(),
        suspendEligibilityImport: yup.boolean().nullable(),
        suspendEligibilityCheck: yup.boolean().nullable(),

        sftpConfig: yup.object().shape({
            __typename: yup.mixed().strip(),
            host: yup.string().required('host is required'),
            port: yup
                .number()
                .typeError('port is required')
                .required('port is required')
                .integer()
                .positive('port must be a positive number'),
            username: yup.string().required('username is required'),
            password: yup.string().required('password is required'),
            directory: yup.string().required('directory is required'),
            fileMask: yup.string().nullable(),
            fileFilters: yup
                .array()
                .of(
                    yup.object().shape({
                        __typename: yup.mixed().strip(),
                        column: yup.string().nullable(),
                        index: yup.number().typeError('index must be a number').nullable(),
                        negate: yup.boolean().nullable(),
                        regex: yup.string().nullable(),
                        match: yup
                            .object()
                            .shape({
                                str: yup.string().nullable(),
                                num: yup.number().nullable(),
                                bool: yup.boolean().nullable(),
                                date: yup.string().nullable(),
                            })
                            .nullable(),
                        matches: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    str: yup.string().nullable(),
                                    num: yup.number().nullable(),
                                    bool: yup.boolean().nullable(),
                                    date: yup.string().nullable(),
                                }),
                            )
                            .nullable(),
                        transformFunctions: yup
                            .array()
                            .of(
                                yup.object().shape({
                                    dateFormat: yup.string().nullable(),
                                    replacement: yup.string().nullable(),
                                    search: yup.string().nullable(),
                                    type: yup
                                        .mixed<EligibilityTransformFunctionType>()
                                        .required('type is required'),
                                }),
                            )
                            .nullable(),
                    }),
                )
                .nullable(),
        }),

        columnMapConfig: yup
            .array()
            .of(
                yup.object().shape({
                    __typename: yup.mixed().strip(),
                    column: yup.string().nullable(),
                    index: yup.number().nullable(),
                    targetProfileDefId: yup
                        .mixed()
                        .required('Target Profile Definition ID is required'),
                    description: yup.string().nullable(),
                    legacyRequestField: yup.string().nullable(),
                    legacyResponseField: yup.string().nullable(),
                    isInput: yup.boolean().nullable(),
                    isOutput: yup.boolean().nullable(),
                    transformFunctions: yup
                        .array()
                        .of(
                            yup.object().shape({
                                type: yup
                                    .mixed<EligibilityTransformFunctionType>()
                                    .required('type is required'),
                                dateFormat: yup.string().nullable(),
                                search: yup.string().nullable(),
                                replacement: yup.string().nullable(),
                            }),
                        )
                        .nullable(),
                }),
            )
            .required('Column Map Config is required'),
    });
