/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Card,
    CardHeader,
    Dialog,
    FormHelperText,
    Grid,
    MenuItem,
    Checkbox,
    TextField,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add as AddIcon, ArrowBack, Save } from '@mui/icons-material';
import ObjectId from 'bson-objectid';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import SortableList, {
    ListItemType,
    SortableListOnChangeProps,
} from '~/components/SortableList/SortableList';
import {
    DateTrigger,
    DateTriggerListDocument,
    DateTriggerListQuery,
    InterventionDateType,
    InterventionInput,
    InterventionListDocument,
    InterventionListQuery,
    InterventionType,
    useCreateDateTriggerMutation,
    useDeleteInterventionMutation,
    useFetchDateTriggerLazyQuery,
    useFetchMessageCenterTemplatesForInterventionQuery,
    useInterventionListQuery,
    usePreFetchAppsTagsQuery,
    useProfileDefsListQuery,
    useUpdateDateTriggerMutation,
    useUpdateInterventionMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import _ from 'lodash';
import { OffsetType } from '~/enums/enums';
import InterventionsModal from '../InterventionsModal';

type InterventionListItem = NonNullable<InterventionListQuery['interventionsV2']>['results'][0];
type InterventionListProfileValue = NonNullable<InterventionListItem['profileValue']>;

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
    addInterventionButtonContainer: {
        margin: '20px 10px',
    },
}));

interface DateTriggerFormInput {
    id?: string;
    name: string;
    timelineId: string;
    offsetDays?: number;
    offsetMonths?: number;
    offsetYears?: number;
    gracePeriod: number;
    tagIds?: string[];
    offsetType: OffsetType;
}

const formValuesFromData = (item: DateTrigger): DateTriggerFormInput => {
    let offsetType = OffsetType.After;
    let offsetDays = item.offsetDays || 0;
    let offsetMonths = item.offsetMonths || 0;
    let offsetYears = item.offsetYears || 0;

    if (offsetDays + offsetMonths + offsetYears < 0) {
        offsetDays *= -1;
        offsetMonths *= -1;
        offsetYears *= -1;
        offsetType = OffsetType.Before;
    }
    return {
        name: item.name,
        offsetDays,
        offsetMonths,
        offsetYears,
        timelineId: item.timelineId,
        tagIds: item.tagIds || undefined,
        offsetType,
        gracePeriod: item.gracePeriod || 0,
    };
};

const formValuesDefault = (dateTriggerId: string): DateTriggerFormInput => {
    return {
        id: dateTriggerId,
        timelineId: '',
        name: '',
        offsetDays: 0,
        offsetMonths: 0,
        offsetYears: 0,
        offsetType: OffsetType.After,
        gracePeriod: 0,
    };
};

const triggerFromForm = (values: DateTriggerFormInput) => {
    let offsetDays = Number(values.offsetDays);
    let offsetMonths = Number(values.offsetMonths);
    let offsetYears = Number(values.offsetYears);
    if (values.offsetType === OffsetType.Before) {
        offsetDays *= -1;
        offsetMonths *= -1;
        offsetYears *= -1;
    }
    return {
        name: values.name,
        timelineId: values.timelineId,
        tagIds: values.tagIds,
        offsetDays,
        offsetMonths,
        offsetYears,
        gracePeriod: Math.abs(Number(values.gracePeriod)),
    };
};

const DateTriggerValidation = Yup.object().shape({
    name: Yup.string().required('Required'),
    timelineId: Yup.string().required('Required'),
});

const DateTriggerEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { id: urlId } = useParams<{ id: string }>();
    const [showInterventionModal, setShowInterventionModal] = useState(false);
    const [editInterventionId, setEditInterventionId] = useState('');
    const [isModified, setIsModified] = useState(false);
    const [nonRepeatable, setNonRepeatable] = useState(false);
    const isEditMode = urlId !== 'new';
    const [dateTriggerId, setDateTriggerId] = useState(
        isEditMode ? urlId : new ObjectId().toHexString(),
    );
    const [close, setClose] = useState(false);
    const [interventionPriority, setInterventionPriority] = useState(0);
    const [interventionSortableList, setInterventionSortableList] = useState<ListItemType[]>([]);
    const { data: preFetchData, loading: preFetchLoading } = usePreFetchAppsTagsQuery();
    const { data: profileDefData, loading: profileDataLoading } = useProfileDefsListQuery();
    const [fetchDateTriggerById, { data: dateTriggerData, loading: dateTriggerLoading }] =
        useFetchDateTriggerLazyQuery();

    const { data: messageCenterTemplateList, loading: messageCenterTemplatesLoading } =
        useFetchMessageCenterTemplatesForInterventionQuery();

    const [updateDateTrigger, { loading: updateDateTriggerLoading }] = useUpdateDateTriggerMutation(
        {
            onCompleted: () => {
                if (close) history(`/app-config/datetriggers`);
            },
            update: (cache, response) => {
                const updatedItem = response.data?.updateDateTrigger?.resourceUpdated;
                if (response.data?.updateDateTrigger?.success && updatedItem) {
                    const currentItems = cache.readQuery<DateTriggerListQuery>({
                        query: DateTriggerListDocument,
                    });
                    if (currentItems?.dateTriggersV2.results) {
                        cache.writeQuery<DateTriggerListQuery>({
                            query: DateTriggerListDocument,
                            data: {
                                dateTriggersV2: {
                                    results: [
                                        ...currentItems.dateTriggersV2.results.map(item => {
                                            if (item.id === updatedItem.id) {
                                                return updatedItem;
                                            }
                                            return item;
                                        }),
                                    ],
                                },
                            },
                        });
                    }
                }
            },
        },
    );

    const [createDateTrigger, { loading: createDateTriggerLoading }] = useCreateDateTriggerMutation(
        {
            onCompleted: data => {
                if (close) history(`/app-config/datetriggers`);
                else
                    history(
                        `/app-config/datetriggers/${data.createDateTrigger?.resourceCreated?.id}`,
                    );
            },
            update: (cache, response) => {
                const newItem = response.data?.createDateTrigger?.resourceCreated;
                setDateTriggerId(newItem?.id);
                if (response.data?.createDateTrigger?.success && newItem) {
                    const currentItems = cache.readQuery<DateTriggerListQuery>({
                        query: DateTriggerListDocument,
                    });
                    if (currentItems?.dateTriggersV2.results) {
                        cache.writeQuery<DateTriggerListQuery>({
                            query: DateTriggerListDocument,
                            data: {
                                dateTriggersV2: {
                                    __typename: currentItems.dateTriggersV2.__typename,
                                    results: [...currentItems.dateTriggersV2.results, newItem],
                                },
                            },
                        });
                    }
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: DateTriggerListDocument,
                    variables: {
                        input: {},
                    },
                },
            ],
        },
    );

    const { data: interventionData } = useInterventionListQuery({
        variables: {
            interventionListInput: { filter: { fields: { dateTriggerId } } },
        },
    });
    const [deleteIntervention] = useDeleteInterventionMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: InterventionListDocument,
                variables: {
                    interventionListInput: { filter: { fields: { dateTriggerId } } },
                },
            },
        ],
    });

    const [updateIntervention, { loading: updateInterventionLoading }] =
        useUpdateInterventionMutation();

    const {
        control,
        register,
        reset,
        handleSubmit,
        setError,

        formState: { errors },
    } = useForm<DateTriggerFormInput>({
        resolver: yupResolver(DateTriggerValidation as any),
        defaultValues: formValuesDefault(dateTriggerId ?? ''),
    });

    const getInterventionData = (intervention: InterventionListItem) => {
        const {
            advocateTaskTemplateId,
            choicesToUpdate,
            messageCenterTemplateId,
            type,
            conditionTypeId,
            profileDefId,
            profileValue,
            dateType,
            tagId,
        }: InterventionInput = intervention;

        const { bool, num, str, date, choices, choiceId }: any = profileValue;

        return {
            advocateTaskTemplateId,
            choicesToUpdate,
            messageCenterTemplateId,
            type,
            conditionTypeId,
            profileDefId,
            profileValue: { bool, num, str, date, choices, choiceId },
            dateType,
            tagId,
            priority: interventionSortableList.findIndex(item => item.id === intervention.id),
        };
    };

    const updateInterventions = async () => {
        const interventions: InterventionListQuery['interventionsV2']['results'] | undefined =
            interventionData?.interventionsV2?.results;
        if (!interventions) return;

        await Promise.all(
            interventions.map(intervention => {
                return updateIntervention({
                    variables: {
                        updateInterventionInput: {
                            id: intervention.id,
                            data: getInterventionData(intervention),
                        },
                    },
                });
            }),
        );
    };

    const onSubmit = async (values: DateTriggerFormInput) => {
        const data = triggerFromForm(values);
        if (data.offsetDays + data.offsetMonths + data.offsetYears === 0) {
            setError('offsetDays', { message: "Days, Months & Years can't all be zero." });
            return;
        }
        if (isEditMode && dateTriggerId) {
            await updateDateTrigger({
                variables: {
                    input: {
                        id: dateTriggerId,
                        data: {
                            ...data,
                            nonRepeatable,
                        },
                    },
                },
            });
            await updateInterventions();
        } else {
            await createDateTrigger({
                variables: {
                    input: {
                        ...data,
                        nonRepeatable,
                    },
                },
            });
            await updateInterventions();
        }
        setIsModified(false);
    };

    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the Date Trigger List?`,
                callback: () => {
                    history(`/app-config/datetriggers/`);
                },
            });
        else history(`/app-config/datetriggers/`);
    };

    const onEdit = () => {
        setIsModified(true);
    };

    const handleAddIntervention = () => {
        setInterventionPriority(interventionSortableList.length);
        setShowInterventionModal(true);
    };

    const handleEditIntervention = (listItem: ListItemType, index: number) => {
        setShowInterventionModal(true);
        setInterventionPriority(index);
        setEditInterventionId(listItem.id as string);
    };

    const handleChangeInterventions = ({ startIndex, endIndex }: SortableListOnChangeProps) => {
        const interventionistClone = [...interventionSortableList];
        const element = interventionistClone[startIndex];
        interventionistClone.splice(startIndex, 1);
        interventionistClone.splice(endIndex, 0, element);
        setInterventionSortableList(interventionistClone);
    };

    const handleDeleteIntervention = (id: string, index: number) => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: 'Do you really want to delete this Intervention?',
            callback: async () => {
                await deleteIntervention({ variables: { input: { id } } });
                setInterventionSortableList(
                    interventionSortableList.filter((_, groupIndex) => groupIndex !== index),
                );
            },
        });
    };

    const handleDeleteInterventionItemClick = (index: number) => {
        handleDeleteIntervention(interventionSortableList[index].id as string, index);
    };

    const formatTargetDate = (dateString: string) => {
        const [date] = dateString.split('T');
        const [year, month, day] = date.split('-');

        return `${month}/${day}/${year}`;
    };

    const renderTarget = useCallback(
        ({
            conditionType,
            profileDef,
            messageCenterTemplateId,
            messageTemplateId,
            messageTemplate,
            profileValue,
            dateType,
            advocateTaskTemplate,
            type,
            incrementValue,
        }: InterventionListItem): string => {
            const interventionProfileValue = { ...profileValue };
            if (conditionType) {
                return conditionType.name.en;
            }
            if (messageTemplateId) {
                return messageTemplate?.name ?? messageTemplateId;
            }
            if (messageCenterTemplateId) {
                return (
                    messageCenterTemplateList?.messageCenterTemplates.filter(
                        template => template.id === messageCenterTemplateId,
                    )?.[0]?.title ?? ''
                );
            }
            if (advocateTaskTemplate) {
                return advocateTaskTemplate.label;
            }

            if (!interventionProfileValue || !profileDef || !profileDef.choices) {
                return '';
            }
            if (type === InterventionType.IncrementPv) {
                return `Change ${profileDef?.name} by ${incrementValue}`;
            }
            const { choiceId, date, str, num, bool } = interventionProfileValue;
            const { valueType } = profileDef;
            const profileDefName = profileDef?.name;
            // Check if profile value is a string
            if (str) {
                return `${profileDefName}: ${str}`;
            }
            // Check if profile value is a number
            if (num) {
                return `${profileDefName}: ${num}`;
            }
            // Check if profile value is a date and is Today
            if (dateType === InterventionDateType.Today && valueType === 'date') {
                return `${profileDefName}: Today`;
            }
            // Check if profile value is a boolean
            if (bool !== null && dateType !== InterventionDateType.Fixed) {
                return `${profileDefName}: ${bool ? 'Yes' : 'No'}`;
            }
            interventionProfileValue.bool = null;
            // if the profile value is a fixed date
            try {
                const [[selectedKey]] = Object.entries(interventionProfileValue).filter(
                    ([, value]) => value !== 'InterventionProfileValue' && value !== null,
                );

                if (!choiceId) {
                    const value =
                        interventionProfileValue[selectedKey as keyof InterventionListProfileValue];
                    return `${profileDefName}: ${date ? formatTargetDate(value as string) : value}`;
                }
            } catch (e) {
                throw new Error('Invalid profile value');
            }

            const [selectedChoice] = profileDef.choices.filter(choice => choice?.id === choiceId);
            const choiceLabel = selectedChoice?.label?.en;

            return `${profileDefName}: ${choiceLabel}`;
        },
        [messageCenterTemplateList?.messageCenterTemplates],
    );

    const getInterventionSortableList = useCallback(
        (interventionData: InterventionListQuery | undefined): ListItemType[] => {
            const data = interventionData?.interventionsV2?.results;
            return data
                ? data
                      .map(item => ({
                          id: item.id,
                          label: {
                              en: `Type: ${item.type}. Target: ${renderTarget(item)}`,
                              es: item.type,
                          },
                          priority: item.priority as number,
                      }))
                      .sort((prev, next) => (prev.priority > next.priority ? 1 : -1))
                : [];
        },
        [renderTarget],
    );

    useEffect(() => {
        if (isEditMode) {
            fetchDateTriggerById({ variables: { input: { id: dateTriggerId } } });
            if (dateTriggerData && dateTriggerData.dateTrigger) {
                reset(formValuesFromData(dateTriggerData.dateTrigger));
                setNonRepeatable(dateTriggerData.dateTrigger.nonRepeatable || false);
            }
            setInterventionSortableList(getInterventionSortableList(interventionData));
        }
    }, [
        fetchDateTriggerById,
        interventionData,
        isEditMode,
        dateTriggerId,
        dateTriggerData,
        reset,
        urlId,
        getInterventionSortableList,
        profileDefData?.userProfileDefs,
    ]);

    if (
        dateTriggerLoading ||
        updateDateTriggerLoading ||
        preFetchLoading ||
        messageCenterTemplatesLoading ||
        !preFetchData ||
        profileDataLoading ||
        createDateTriggerLoading
    )
        return <Loading />;
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to Date Trigger List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardHeader
                            title={`${
                                isEditMode ? 'Edit' : 'Create'
                            } Date Trigger and Interventions`}
                        />
                        <OutlinedSection title="Name *">
                            <TextField
                                variant="outlined"
                                type="text"
                                fullWidth
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onEdit}
                            />
                        </OutlinedSection>
                        <Grid item xs={12}>
                            <ReactHookFormSelect
                                control={control}
                                defaultValue=""
                                name="timelineId"
                                variant="outlined"
                                label="Select Timeline *"
                                fullWidth
                                margin="dense"
                                disabled={isEditMode}
                                error={!!errors.timelineId}
                            >
                                {_.sortBy(preFetchData.whatsNewTimelines, 'label.en').map(
                                    ({ id, label }) => (
                                        <MenuItem key={id} value={id}>
                                            {label?.en}
                                        </MenuItem>
                                    ),
                                )}
                            </ReactHookFormSelect>
                            {errors?.timelineId && (
                                <FormHelperText error>{errors?.timelineId?.message}</FormHelperText>
                            )}
                            {preFetchData?.tags && (
                                <AutocompleteWithRecordOptions
                                    options={preFetchData.tags ?? []}
                                    valueKey="id"
                                    labelKey="name"
                                    control={control}
                                    name="tagIds"
                                    label="Tags"
                                    placeholder="Select tags ..."
                                    loading={!preFetchData?.tags}
                                    loadingText="Loading tags"
                                    error={Boolean(errors.tagIds)}
                                />
                            )}
                            <Grid item xs={12}>
                                <span>Activate On</span>
                                <div>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        label="Days *"
                                        margin="dense"
                                        {...register('offsetDays')}
                                        InputProps={{
                                            inputProps: { min: 0, step: 1 },
                                        }}
                                        defaultValue={0}
                                        error={Boolean(errors.offsetDays)}
                                        helperText={errors.offsetDays?.message}
                                    />
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        label="Months *"
                                        margin="dense"
                                        {...register('offsetMonths')}
                                        InputProps={{
                                            inputProps: { min: 0, step: 1 },
                                        }}
                                        defaultValue={0}
                                        error={Boolean(errors.offsetMonths)}
                                        helperText={errors.offsetMonths?.message}
                                    />
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        label="Years *"
                                        margin="dense"
                                        {...register('offsetYears')}
                                        InputProps={{
                                            inputProps: { min: 0, step: 1 },
                                        }}
                                        defaultValue={0}
                                        error={Boolean(errors.offsetYears)}
                                        helperText={errors.offsetYears?.message}
                                    />
                                    <ReactHookFormSelect
                                        control={control}
                                        name="offsetType"
                                        variant="outlined"
                                        label="Offset Type"
                                        margin="dense"
                                        defaultValue="Before"
                                        error={!!errors.offsetType}
                                    >
                                        {Object.values(OffsetType).map(u => (
                                            <MenuItem key={u} value={u}>
                                                {u}
                                            </MenuItem>
                                        ))}
                                    </ReactHookFormSelect>
                                    <TextField
                                        variant="outlined"
                                        type="number"
                                        label="Grace Period (days) *"
                                        margin="dense"
                                        {...register('gracePeriod')}
                                        InputProps={{
                                            inputProps: { min: 0, step: 1 },
                                        }}
                                        defaultValue={0}
                                        error={Boolean(errors.gracePeriod)}
                                        helperText={errors.gracePeriod?.message}
                                    />
                                </div>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <span>Non-Repeatable</span>
                            <Checkbox
                                color="primary"
                                checked={nonRepeatable}
                                onChange={v => setNonRepeatable(v.currentTarget.checked)}
                            />
                        </Grid>
                        {isEditMode && (
                            <Grid item className={classes.addInterventionButtonContainer}>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => handleAddIntervention()}
                                    startIcon={<AddIcon />}
                                >
                                    Add Intervention
                                </Button>
                            </Grid>
                        )}
                        {!updateInterventionLoading &&
                        interventionSortableList &&
                        interventionSortableList.length ? (
                            <Grid item>
                                <OutlinedSection title="Interventions">
                                    <SortableList
                                        list={interventionSortableList}
                                        droppableId="interventionOrder"
                                        onChange={handleChangeInterventions}
                                        onClick={handleEditIntervention}
                                        onDelete={handleDeleteInterventionItemClick}
                                    />
                                </OutlinedSection>
                            </Grid>
                        ) : null}

                        <div style={{ width: '100%', marginTop: '10px', textAlign: 'right' }}>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => handleSubmit(onSubmit)}
                            >
                                Save
                            </Button>
                            <Button
                                type="submit"
                                startIcon={<Save />}
                                color="secondary"
                                variant="contained"
                                onClick={() => {
                                    setClose(true);
                                    handleSubmit(onSubmit);
                                }}
                            >
                                Save &amp; Close
                            </Button>
                        </div>
                    </Card>
                </form>
            </Grid>
            <Dialog scroll="body" open={showInterventionModal} aria-labelledby="form-dialog-title">
                <InterventionsModal
                    setOpen={setShowInterventionModal}
                    setEditIntervention={setEditInterventionId}
                    id={editInterventionId}
                    dateTriggerId={dateTriggerId}
                    interventionPriority={interventionPriority}
                />
            </Dialog>
        </Grid>
    );
};

export default DateTriggerEditor;
