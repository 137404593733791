import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    FormControlLabel,
} from '@mui/material';
import React, { useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { MeasurementInputSource, MeasurementType } from '~/schemaTypes';

type FilterModalProps = {
    open: boolean;
    onClose: () => void;
    onSave: () => void;
};

export const FilterTrakerDataModal: React.FC<FilterModalProps> = ({ open, onClose, onSave }) => {
    const [filteredType, setFilteredType] = useState<MeasurementType | null>(
        (localStorage.getItem('filteredTrackerType') as MeasurementType) || undefined,
    );
    const [filteredSource, setFilteredSource] = useState<MeasurementInputSource | null>(
        (localStorage.getItem('filteredTrackerSource') as MeasurementInputSource) || undefined,
    );
    const [filterTestData, setFilterTestData] = useState<boolean>(
        localStorage.getItem('filterTrackerTestData') === 'true' || false,
    );

    const handleSave = () => {
        localStorage.setItem('filteredTrackerType', filteredType || '');
        localStorage.setItem('filteredTrackerSource', filteredSource || '');
        localStorage.setItem('filterTrackerTestData', `${filterTestData}`);

        const filtersAppliedCount =
            (filteredType ? 1 : 0) + (filterTestData ? 1 : 0) + (filteredSource ? 1 : 0);

        localStorage.setItem('filtersTrackerAppliedCount', String(filtersAppliedCount));
        onSave();
    };

    const handleClearFilters = () => {
        setFilteredType(null);
        setFilteredSource(null);
        setFilterTestData(false);
    };

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitleWithClose id="Filter Tracker Data" onClose={onClose}>
                Filter Tracker Data
            </DialogTitleWithClose>
            <DialogContent>
                <div>
                    Tracker Type:{' '}
                    <select
                        value={filteredType || ''}
                        key="filterType"
                        onChange={e => setFilteredType(e.target.value as MeasurementType)}
                    >
                        <option value="">All Types</option>
                        <option value={MeasurementType.BloodGlucose}>Blood Glucose</option>
                        <option value={MeasurementType.BloodPressure}>Blood Pressure</option>
                        <option value={MeasurementType.Weight}>Weight</option>
                    </select>
                </div>
                <div>
                    Data Source:{' '}
                    <select
                        value={filteredSource || ''}
                        key="filteredSource"
                        onChange={e => setFilteredSource(e.target.value as MeasurementInputSource)}
                    >
                        <option value="">All Sources</option>
                        <option value={MeasurementInputSource.Bluetooth}>Bluetooth</option>
                        <option value={MeasurementInputSource.Manual}>Manual</option>
                    </select>
                </div>
                <div>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={filterTestData}
                                onChange={() => {
                                    setFilterTestData(!filterTestData);
                                    localStorage.setItem(
                                        'filterTrackerTestData',
                                        `${!filterTestData}`,
                                    );
                                }}
                            />
                        }
                        label="Enable Test Data To Be Displayed"
                    />
                </div>
                <Button onClick={handleClearFilters}>Clear Filters</Button>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose} color="primary">
                    Close
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
