import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Grid,
    IconButton,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import {
    useVcExternalFormTemplatesForVcExternalFormTemplatesPageQuery,
    useSendVcExternalFormMutation,
    AlertSeverity,
    OrderByDirectionEnum,
    useVcExternalFormDataOnSendFormEmailModalLazyQuery,
    useFetchPatientByIdForSendFormEmailModalQuery,
} from '~/schemaTypes';
import { format } from 'date-fns';
import copy from 'copy-to-clipboard';
import { Link as LinkIcon } from '@mui/icons-material';
import Loading from '../../../../components/Loading/Loading';
import SentExternalFormModal from '../SentExternalFormModal/SentExternalFormModal';
import { SuppressNextGlobalAlert, TriggerGlobalAlert } from '../../../../state';
import AsyncActionButton from '../../../../components/AsyncActionButton/AsyncActionButton';

export type SendFormEmailModalProps = {
    patientId: string;
    isModalOpen: boolean;
    handleClose: () => void;
};

const SendFormEmailModal: React.FC<SendFormEmailModalProps> = ({
    patientId,
    isModalOpen,
    handleClose: handleCloseProp,
}) => {
    const [selectedTemplate, setSelectedTemplate] = useState<string | null>(null);
    const [lastSentFormDate, setLastSentFormDate] = useState<Date | null>(null);
    const [templateLink, setTemplateLink] = useState<string | null>(null);
    const [sentEmail, setSentEmail] = useState<string | null>(null);
    const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);

    const { data: templates, loading: loadingTemplates } =
        useVcExternalFormTemplatesForVcExternalFormTemplatesPageQuery();

    const { data: patientDataResponse, loading: patientDataLoading } =
        useFetchPatientByIdForSendFormEmailModalQuery({
            variables: {
                input: { id: patientId },
            },
        });

    const patientData = patientDataResponse?.patient;

    const templateName = useMemo(() => {
        const templatesData = templates?.vCExternalFormTemplatesV2.results;
        if (!templatesData) {
            return null;
        }
        return templatesData.find(({ id }) => id === selectedTemplate)?.name ?? null;
    }, [selectedTemplate, templates]);

    const [fetchFormData, { loading: loadingFormData }] =
        useVcExternalFormDataOnSendFormEmailModalLazyQuery({
            onCompleted: result => {
                if (!result) {
                    return;
                }

                setTemplateLink(result.vcExternalFormLinkForPatientId?.link ?? null);

                const data = result.vCExternalFormsV2.results;
                if (!data || !data.length) {
                    setLastSentFormDate(null);
                    return;
                }

                setLastSentFormDate(new Date(data[0].dateSent));
            },
        });

    const [sendForm, { loading: loadingFormSend }] = useSendVcExternalFormMutation({
        onCompleted: result => {
            SuppressNextGlobalAlert(true);
            if (!result.sendVCExternalForm?.success) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'Failed to Send Form Email',
                });
                return;
            }
            const data = result.sendVCExternalForm?.receiverEmail;
            if (!data) {
                return;
            }
            setSentEmail(data);
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            });
        },
        refetchQueries: ['VCExternalFormDataOnSendFormEmailModal'],
    });

    useEffect(() => {
        if (selectedTemplate) {
            fetchFormData({
                variables: {
                    formInput: {
                        filter: {
                            fields: {
                                templateId: selectedTemplate,
                                patientId,
                            },
                        },
                        orderBy: {
                            field: 'createdAt',
                            order: OrderByDirectionEnum.Desc,
                        },
                        pagination: {
                            skip: 0,
                            limit: 1,
                        },
                    },
                    linkInput: {
                        vcExternalFormTemplateId: selectedTemplate,
                        patientId,
                    },
                },
            });
        } else {
            setLastSentFormDate(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientId, selectedTemplate]);

    const handleSendForm = async () => {
        if (!selectedTemplate) {
            return null;
        }

        return sendForm({
            variables: {
                input: {
                    formTemplateId: selectedTemplate,
                    patientId,
                },
            },
        });
    };

    const handleClose = () => {
        handleCloseProp();
    };

    return (
        <>
            <Dialog
                scroll="paper"
                onClose={(_, reason) => reason !== 'backdropClick' && handleClose()}
                open={isModalOpen}
                maxWidth="xs"
                aria-labelledby="form-dialog-title"
            >
                {loadingTemplates || patientDataLoading ? (
                    <Loading />
                ) : (
                    <DialogContent>
                        <Grid container>
                            <Typography sx={{ marginBottom: 2, paddingLeft: 2 }}>
                                Send Form to: {patientData?.firstName}&nbsp;{patientData?.lastName}
                                :&nbsp;
                                {format(new Date(patientData?.birthDate), 'MM/dd/yyyy')}
                            </Typography>
                            <Grid container xs={12}>
                                <Grid item md={11} xs={10}>
                                    <TextField
                                        select
                                        label="Select Form"
                                        fullWidth
                                        value={selectedTemplate}
                                        onChange={event => {
                                            setSelectedTemplate(event.target.value as string);
                                        }}
                                    >
                                        {loadingTemplates ? (
                                            <Loading />
                                        ) : (
                                            templates?.vCExternalFormTemplatesV2.results.map(
                                                ({ name, id }) =>
                                                    id && (
                                                        <MenuItem key={id} value={id}>
                                                            {name}
                                                        </MenuItem>
                                                    ),
                                            )
                                        )}
                                    </TextField>
                                </Grid>
                                <Tooltip title="Copy Link">
                                    <IconButton
                                        title="Copy Link"
                                        sx={{ padding: 0 }}
                                        disabled={selectedTemplate === null || loadingFormData}
                                        onClick={() => {
                                            copy(`${templateLink}`);
                                            SuppressNextGlobalAlert(false);
                                            TriggerGlobalAlert({
                                                message: 'Email Link Copied to Clipboard',
                                                severity: AlertSeverity.Success,
                                            });
                                        }}
                                    >
                                        <LinkIcon />
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                            {lastSentFormDate && (
                                <Typography variant="body2" color="error" sx={{ paddingLeft: 2 }}>
                                    {loadingFormData
                                        ? 'Checking sent forms...'
                                        : `Last sent on ${format(lastSentFormDate, 'MM/dd/yyyy')}`}
                                </Typography>
                            )}
                        </Grid>
                        <DialogActions
                            sx={{
                                justifyContent: {
                                    xs: 'center',
                                    md: 'flex-start',
                                },
                                gap: 2,
                                marginTop: 2,
                                flexDirection: {
                                    xs: 'column',
                                    md: 'row',
                                },
                            }}
                            disableSpacing
                        >
                            <Button
                                fullWidth
                                variant="contained"
                                disabled={
                                    loadingFormSend || loadingFormData || selectedTemplate === null
                                }
                                onClick={() => {
                                    handleSendForm().then(data => {
                                        if (!data?.data?.sendVCExternalForm?.success) {
                                            return;
                                        }
                                        handleClose();
                                        setIsSuccessModalOpen(true);
                                    });
                                }}
                            >
                                <AsyncActionButton loading={loadingFormSend}>
                                    Send Email to Patient
                                </AsyncActionButton>
                            </Button>
                            <Button
                                fullWidth
                                variant="outlined"
                                onClick={() => {
                                    handleClose();
                                }}
                            >
                                Cancel
                            </Button>
                        </DialogActions>
                    </DialogContent>
                )}
            </Dialog>
            <SentExternalFormModal
                templateName={templateName}
                isModalOpen={isSuccessModalOpen}
                email={sentEmail}
                handleClose={() => {
                    setIsSuccessModalOpen(false);
                    setSelectedTemplate(null);
                }}
            />
        </>
    );
};

export default SendFormEmailModal;
