import React from 'react';
import { useLocation, Navigate } from 'react-router-dom';
import { useUser } from '~/hooks';
import { PortalVcUserType } from '~/schemaTypes';
import useUserPermissions from '~/hooks/useUserPermissions';
import history from 'history/browser';
import Loading from '../Loading/Loading';
import Login from '../Login/Login';
import { NavLinks } from '../PortalNav/PortalNav';
import InactiveLogin from '../Login/InactiveLogin';

interface AuthWrapperProps {
    children: React.ReactElement<any, any> | null;
}

interface VCViewProps {
    children: React.ReactElement<any, any> | null;
}

export const VCRouteSuffixMap = new Map([
    [PortalVcUserType.Affiliate, '/affiliate-care-portal'],
    [PortalVcUserType.Doula, '/doula'],
    [PortalVcUserType.LactationConsultant, '/lactation-consultant-portal'],
]);

const getRouteSuffixByVCType = (type: PortalVcUserType) => VCRouteSuffixMap.get(type) ?? '';

export const AuthWrapper: React.FC<AuthWrapperProps> = ({ children }) => {
    const { pagePermissions, loading: userPermissionsLoading } = useUserPermissions();
    const { data: userData } = useUser();
    const location = useLocation();
    const navRoute = NavLinks.find(({ path }) => path === location.pathname);

    if (!userData?.currentUser && !pagePermissions && !userPermissionsLoading) {
        return <Login />;
    }

    if (userPermissionsLoading) {
        return <Loading height={600} />;
    }

    if (userData?.currentUser && pagePermissions && navRoute) {
        if (navRoute.hide(pagePermissions)) {
            history.replace('/');
        }
    }

    return <InactiveLogin>{children}</InactiveLogin>;
};

export const VCViewWrapper: React.FC<VCViewProps> = ({ children }) => {
    const { data: userData } = useUser();
    const location = useLocation();
    const isParent = (parentPaths: string[]) => parentPaths.includes(location.pathname);
    const vcType = userData?.currentUser?.vcType;
    if (vcType) {
        const VCPaths = [...VCRouteSuffixMap.values()];
        const startFromIncorrectVCSuffix = VCPaths.some(
            path => location.pathname.startsWith(path) && path !== getRouteSuffixByVCType(vcType),
        );
        if (startFromIncorrectVCSuffix) {
            return <Navigate to="/portal/welcome" />;
        }
        switch (vcType) {
            case PortalVcUserType.Doula:
                if (isParent(['/doula', '/doula/portal'])) {
                    return <Navigate to="/doula/portal/welcome" />;
                }
                if (!location.pathname.includes(getRouteSuffixByVCType(PortalVcUserType.Doula))) {
                    return <Navigate to={`/doula${location.pathname}`} />;
                }
                break;
            case PortalVcUserType.LactationConsultant:
                if (
                    isParent([
                        '/lactation-consultant-portal',
                        '/lactation-consultant-portal/portal',
                    ])
                ) {
                    return <Navigate to="/lactation-consultant-portal/portal/welcome" />;
                }
                if (
                    !location.pathname.includes(
                        getRouteSuffixByVCType(PortalVcUserType.LactationConsultant),
                    )
                ) {
                    return <Navigate to={`/lactation-consultant-portal${location.pathname}`} />;
                }
                break;
            case PortalVcUserType.Affiliate:
                if (
                    !location.pathname.includes(getRouteSuffixByVCType(PortalVcUserType.Affiliate))
                ) {
                    return <Navigate to="/affiliate-care-portal/landing" />;
                }
                break;
            default:
                return children;
        }
    } else if (userData?.currentUser) {
        const VCPaths = [...VCRouteSuffixMap.values()];
        const startFromVCSuffix = VCPaths.some(path => location.pathname.startsWith(path));
        if (startFromVCSuffix) {
            return <Navigate to="/portal/welcome" />;
        }
    }
    return children;
};
