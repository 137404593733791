import React, { useMemo } from 'react';
import { Autocomplete, Grid, TextField, Chip } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import _ from 'lodash';
import {
    FetchCurrentUserForStaffModalQuery,
    FetchOrgListForReferenceLinkPageQuery,
} from '~/schemaTypes';
import { ROOT_ORG_ID } from '~/constants';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { StaffFormInput } from '../types';

type OrgPickerProps = {
    currentUserQuery?: FetchCurrentUserForStaffModalQuery;
    allOrgsData?: FetchOrgListForReferenceLinkPageQuery;
    disabledTopLevelPractice?: boolean;
    defaultTopLevelPracticeId?: string;
    disabledSecOrgsId?: string[];
};

const OrgPicker: React.FC<OrgPickerProps> = props => {
    const {
        disabledSecOrgsId = [],
        currentUserQuery,
        allOrgsData,
        disabledTopLevelPractice = false,
    } = props;
    const {
        control,
        formState: { errors },
        watch,
        setValue,
    } = useFormContext<StaffFormInput>();
    const [rootOrg, secOrgList] = watch(['rootOrg', 'secOrgList']);
    const value = useMemo(
        () => ({
            value: rootOrg,
            name:
                currentUserQuery?.currentUser?.organizations.find(o => o.id === rootOrg)
                    ?.brandingName || '',
        }),
        [rootOrg, currentUserQuery?.currentUser?.organizations],
    );
    const primaryOrgOptions = useMemo(
        () =>
            _.sortBy(currentUserQuery?.currentUser?.organizations, 'brandingName').map(o => ({
                name: o.brandingName,
                value: o.id,
            })) ?? [],
        [currentUserQuery?.currentUser?.organizations],
    );
    const secondaryOrgOptions = useMemo(
        () =>
            (allOrgsData &&
                allOrgsData.organizations
                    .slice()
                    .sort((a, b) => {
                        if (a.brandingName < b.brandingName) return -1;
                        if (a.brandingName === b.brandingName) return 0;
                        return 1;
                    })
                    .filter(o => o.id !== rootOrg)
                    .map(org => ({
                        orgId: org.id,
                        name: org.brandingName,
                    }))) ||
            [],
        [allOrgsData, rootOrg],
    );
    return (
        <>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name="rootOrg"
                    render={({ field: { onChange } }) => (
                        <Autocomplete
                            size="small"
                            onChange={(_, org) => {
                                onChange(org?.value);
                            }}
                            getOptionLabel={selected => selected.name ?? ''}
                            fullWidth
                            disabled={disabledTopLevelPractice}
                            value={value}
                            options={primaryOrgOptions}
                            renderInput={params => (
                                <TextField
                                    variant="outlined"
                                    margin="dense"
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    placeholder="Select your practice"
                                    error={!!errors.rootOrg}
                                    helperText={errors.rootOrg?.message}
                                    label="Top Level Practice"
                                    InputLabelProps={{ shrink: true }}
                                />
                            )}
                        />
                    )}
                />
            </Grid>
            {rootOrg !== ROOT_ORG_ID && (
                <OutlinedSection title="Select Secondary Orgs">
                    <Autocomplete
                        multiple
                        filterSelectedOptions
                        value={secOrgList || []}
                        options={secondaryOrgOptions}
                        onChange={(_, val) => {
                            setValue('secOrgList', val);
                        }}
                        isOptionEqualToValue={(option, value) => option?.orgId === value?.orgId}
                        getOptionLabel={tag => tag?.name ?? ''}
                        renderInput={params => (
                            <TextField
                                variant="outlined"
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                label="Secondary Orgs"
                                placeholder="Select Secondary Orgs"
                            />
                        )}
                        renderTags={(tagValue, getTagProps) =>
                            tagValue.map((option, index) => {
                                const { key, ...tagProps } = getTagProps({ index });
                                return (
                                    <Chip
                                        key={key}
                                        label={option.name}
                                        {...tagProps}
                                        disabled={disabledSecOrgsId.includes(option.orgId)}
                                    />
                                );
                            })
                        }
                        disableClearable={!!disabledSecOrgsId.length}
                    />
                </OutlinedSection>
            )}
        </>
    );
};

export { OrgPicker };
