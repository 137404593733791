export const CARD_TYPES = [
    'Tracker',
    'ProfileValue',
    'PatientNotes',
    'AdvocateTasks',
    'SurveyResponseExpanded',
    'ChatMessages',
    'SentComms',
    'ResolvedTagEvent',
    'TriggerFiredEvent',
    'ExpressionEvaluated',
    'TriggerInterventionStartEpisode',
    'TriggerInterventionEndEpisode',
    'TriggerInterventionSetProfileValue',
    'TriggerInterventionSendMessageCenter',
    'TriggerInterventionCreateAdvocateTask',
    'TriggerInterventionClearProfileValue',
    'TriggerInterventionClearProfileChoice',
] as const;
