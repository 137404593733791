// This component has a UI Test
import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Link, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, Assignment, Code, FileCopy, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useCallback, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import RawModal from '~/components/RawModal/RawModal';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AlertSeverity,
    SurveyDef,
    SurveyDefListDocument,
    useDeleteSurveyDefMutation,
    useSurveyDefListQuery,
    useSurveyDefViewerLazyQuery,
    useSurveyDefWhereUsedLazyQuery,
} from '~/schemaTypes';
import { SurveyEnum } from '~/selectors';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';

import { CloneSurveyModal } from './CloneSurveyModal';

const useStyles = makeStyles()({
    root: {},
});

const EMPTY_JSON_STR = '{}';

const SurveyDefs: React.FC = () => {
    const { classes } = useStyles();

    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [isCloneDialogOpen, setCloneDialogOpen] = useState<boolean>(false);
    const [selectedSurvey, setSelectedSurvey] = useState<SurveyDef>();
    const [showUsageModal, setShowUsageModal] = useState(false);
    const [showSurveyDefModal, setShowSurveyDefModal] = useState(false);
    const [surveyDefRawData, setSurveyDefRawData] = useState<string>(EMPTY_JSON_STR);

    const history = useNavigate();

    const { data: surveyDefsData, loading: surveyDefsLoading } = useSurveyDefListQuery();
    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useSurveyDefWhereUsedLazyQuery();
    const [deleteItem, { loading: deleteItemLoading }] = useDeleteSurveyDefMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: SurveyDefListDocument,
                variables: {},
            },
        ],
    });
    const [surveyDefViewer] = useSurveyDefViewerLazyQuery({
        onCompleted: data => {
            if (data.surveyDefViewer?.raw) {
                setShowSurveyDefModal(true);
                setSurveyDefRawData(data.surveyDefViewer.raw || EMPTY_JSON_STR);
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: 'No data found for this survey',
                });
            }
        },
        onError: () => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Server error. Please, try again later',
            });
        },
        fetchPolicy: 'network-only',
    });
    const handleDelete = () => {
        // eslint-disable-next-line no-restricted-globals, no-alert
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedSurvey?.name}'?`,
            callback: () => {
                deleteItem({ variables: { id: selectedSurvey?.id } });
                setShowUsageModal(false);
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const handleShowUsage = (item: SurveyDef) => {
        whereUsed({
            variables: {
                id: item?.id,
            },
        });
        setShowUsageModal(true);
    };
    const handleSurveyDefViewer = useCallback(
        (item: SurveyDef) => {
            surveyDefViewer({
                variables: {
                    id: item?.id,
                },
            });
        },
        [surveyDefViewer],
    );
    const handleCloseSurveyDefViewer = useCallback(() => {
        setShowSurveyDefModal(false);
        setSurveyDefRawData(EMPTY_JSON_STR);
    }, []);

    return (
        <div className={classes.root} data-test={SurveyEnum.CONTAINER} ref={tableRef}>
            <MaterialTable
                title="Surveys"
                icons={tableIcons}
                isLoading={surveyDefsLoading || deleteItemLoading || whereUsedLoading}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                data={_.cloneDeep(surveyDefsData?.surveyDefs) ?? []}
                actions={[
                    {
                        onClick: () => history('/app-config/surveys/new'),
                        hidden: !pagePermissions?.SurveyDefs.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Survey',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/surveys/${id}`),
                        hidden: !pagePermissions?.SurveyDefs.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Survey',
                    },
                    {
                        onClick: (_, item) => {
                            setCloneDialogOpen(true);
                            setSelectedSurvey(item);
                        },
                        hidden: !pagePermissions?.SurveyDefs.Edit,
                        icon: () => <FileCopy />,
                        tooltip: 'Clone Survey',
                    },
                    {
                        onClick: (_, { id }: any) =>
                            history(`/app-config/surveys/surveytaker/${id}`),
                        hidden: !pagePermissions?.SurveyDefs.Read,
                        icon: () => <Assignment />,
                        tooltip: 'Take Survey',
                    },
                    {
                        onClick: (_e, item) => {
                            setSelectedSurvey(item);
                            handleShowUsage(item);
                        },
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View References',
                    },
                    {
                        onClick: (_, item) => {
                            setSelectedSurvey(item);
                            handleSurveyDefViewer(item);
                        },
                        icon: () => <Code />,
                        tooltip: 'Survey Details ',
                    },
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Id copied to clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Name',
                        field: 'name',
                        render: ({ id, name }: any) => (
                            <Tooltip title="Edit In New Window">
                                <Link
                                    style={{ textDecoration: 'underline' }}
                                    target="_blank"
                                    href={`/app-config/surveys/${id}`}
                                    rel="noopener noreferrer"
                                >
                                    {name}
                                </Link>
                            </Tooltip>
                        ),
                    },
                    { title: 'Survey Type', field: 'surveyType' },
                    { title: '# of Sections', field: 'divisions.length' },
                    {
                        title: 'Last Updated',
                        field: 'updatedAt',
                        render: ({ updatedAt }) => (
                            <>
                                {displayDate({
                                    isoDateStr: updatedAt,
                                })}
                            </>
                        ),
                    },
                ]}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100] }}
                localization={{ header: { actions: '' } }}
            />
            {selectedSurvey && (
                <CloneSurveyModal
                    isOpen={isCloneDialogOpen}
                    surveyId={selectedSurvey.id}
                    onClose={() => setCloneDialogOpen(false)}
                />
            )}
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedSurvey?.name}`}
                    canDelete={pagePermissions?.SurveyDefs.Delete || false}
                    usageData={whereUsedData?.surveyDefWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
            <Dialog
                scroll="paper"
                open={showSurveyDefModal}
                fullWidth
                maxWidth="lg"
                aria-labelledby="form-dialog-title"
            >
                <RawModal
                    setShow={handleCloseSurveyDefViewer}
                    rawData={surveyDefRawData}
                    title="Survey Config"
                />
            </Dialog>
        </div>
    );
};

export default SurveyDefs;
