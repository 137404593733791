import React from 'react';
import { GetTriggerInterventionClearProfileValueForPatientTranscriptQuery } from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type TriggerInterventionClearProfileValue = NonNullable<
    NonNullable<
        GetTriggerInterventionClearProfileValueForPatientTranscriptQuery['getTriggerInterventionClearProfileValueForPatientTranscript']
    >['items']
>[number];

export const TriggerInterventionClearProfileValueCard = ({
    intervention,
}: {
    intervention: TriggerInterventionClearProfileValue;
}) => {
    return (
        <BaseCard title="Trigger Intervention Clear Profile Value" data={intervention}>
            <p className="m-0">{`Profile: ${intervention?.profileDef?.name || 'Unknown'}`}</p>
            <p className="m-0">{`Value: ${
                intervention?.event?.data?.updatedProfileValue?.str ||
                intervention?.event?.data?.updatedProfileValue?.num ||
                intervention?.event?.data?.updatedProfileValue?.bool ||
                intervention?.event?.data?.updatedProfileValue?.date ||
                intervention?.event?.data?.updatedProfileValue?.choiceId ||
                'Unknown'
            }`}</p>
        </BaseCard>
    );
};
