import React, { useEffect, useState } from 'react';
import { Dialog, Link as MuiLink, Typography } from '@mui/material';
import { HaPermission, useFetchPatientProfileVariablesQuery } from '~/schemaTypes';
import PatientProfileVariableModal from '~/views/Dashboard/Patients/Patient/PatientProfileVariables/PatientProfileVariableModal/PatientProfileVariableModal';
import { getPVToEditCOnvertedWeight } from '~/views/Dashboard/Patients/Patient/PatientProfileVariables/PatientProfileVariables';
import Loading from '~/components/Loading/Loading';
import {
    PatientProfileVariableLinkProps,
    PatientProfileVariableModalDataProps,
    UpdatedProfileVariable,
} from '~/views/Dashboard/Patients/PatientProfileVariableLink/types';
import { getUpdatedProfileVariableValue } from '~/views/Dashboard/Patients/PatientProfileVariableLink/utils';
import { useUser, useUserPermissions } from '~/hooks';
import { WEIGHT_UNIT_DEFAULT } from '~/components/WeightValue/WeightValue';

const PatientProfileVariableModalData = ({
    patientId,
    profileVariableDefId,
    appBundleId,
    onChange,
    onClose,
}: PatientProfileVariableModalDataProps) => {
    const { pagePermissions } = useUserPermissions();
    const { data: userData } = useUser();
    const [supportedUnitName, setSupportedUnitName] = useState<string>(WEIGHT_UNIT_DEFAULT);

    const { data: { patientProfileVariables } = {}, loading } =
        useFetchPatientProfileVariablesQuery({
            variables: { patientId },
        });

    useEffect(() => {
        if (userData?.currentUser) {
            const currentOrgWeightUnit =
                userData?.currentUser?.currentOrganization.organizationMeasurementConfig?.weight
                    ?.units;
            const rootOrgWeightUnit =
                userData?.currentUser?.rootOrg.organizationMeasurementConfig?.weight?.units;
            const weightUnitFromOrg = currentOrgWeightUnit ?? rootOrgWeightUnit;
            if (weightUnitFromOrg) {
                const weightUnit = weightUnitFromOrg ?? WEIGHT_UNIT_DEFAULT;
                setSupportedUnitName(weightUnit);
            }
        }
    }, [userData]);

    if (loading) {
        return <Loading />;
    }

    const rowPatientProfileVariable = patientProfileVariables?.find(
        i => i.profileVariableDefId === profileVariableDefId,
    );

    if (!pagePermissions || !patientProfileVariables || !rowPatientProfileVariable) {
        return null;
    }

    const isViewOnly =
        !pagePermissions.PatientProfileVariables.Edit &&
        rowPatientProfileVariable.haPermission !== HaPermission.Edit;

    const closeHandler = (data: UpdatedProfileVariable) => {
        if (data) {
            onChange(data);
        }
        onClose();
    };

    return (
        <PatientProfileVariableModal
            patientProfileVariable={getPVToEditCOnvertedWeight(
                patientProfileVariables,
                profileVariableDefId,
                supportedUnitName,
            )}
            patientId={patientId}
            closeHandler={closeHandler}
            viewOnly={isViewOnly}
            appBundleId={appBundleId}
        />
    );
};

const PatientProfileVariableLink = ({
    patientId,
    children,
    profileVariableDefId,
    appBundleId,
}: PatientProfileVariableLinkProps) => {
    const [isOpened, setIsOpened] = useState<boolean>(false);
    const { pagePermissions } = useUserPermissions();
    const [updatedProfileVariable, setUpdatedProfileVariable] =
        useState<UpdatedProfileVariable | null>(null);

    const openHandler = () => setIsOpened(true);

    const closeHandler = () => setIsOpened(false);

    const changeHandler = (value: UpdatedProfileVariable) => {
        if (value) {
            setUpdatedProfileVariable(value);
        }
    };

    const updatedProfileVariableValue = updatedProfileVariable
        ? getUpdatedProfileVariableValue(updatedProfileVariable)
        : null;

    return (
        <>
            {pagePermissions?.ProfileValueOrigin.Read ? (
                <MuiLink onClick={openHandler} underline="hover">
                    {children(updatedProfileVariableValue)}
                </MuiLink>
            ) : (
                <Typography>{children(updatedProfileVariableValue)}</Typography>
            )}
            <Dialog scroll="paper" open={isOpened} onClose={closeHandler} fullWidth>
                <PatientProfileVariableModalData
                    patientId={patientId}
                    profileVariableDefId={profileVariableDefId}
                    appBundleId={appBundleId}
                    onClose={closeHandler}
                    onChange={changeHandler}
                />
            </Dialog>
        </>
    );
};

export default PatientProfileVariableLink;
