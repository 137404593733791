export const createCustomSort = <T>(fieldName: keyof T) => {
    return (a: T, b: T) => {
        const valueA = a[fieldName];
        const valueB = b[fieldName];

        if (valueA === valueB) return 0;
        if (valueA === null || valueA === undefined) return -1;
        if (valueB === null || valueB === undefined) return 1;

        if (typeof valueA === 'string' && typeof valueB === 'string') {
            return valueA.localeCompare(valueB);
        }

        return valueA > valueB ? 1 : -1;
    };
};
