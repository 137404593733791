import MomentUtils from '@date-io/moment';
import { formatISO } from 'date-fns';
import { TZDate } from '@date-fns/tz';
import moment from 'moment';

export const isToday = (date: Date): boolean => {
    const today = new Date();
    return (
        date.getDate() === today.getDate() &&
        date.getMonth() === today.getMonth() &&
        date.getFullYear() === today.getFullYear()
    );
};

export const buildMomentUtils = (timeZone: string): any => {
    moment.tz.setDefault(timeZone);
    return MomentUtils;
};

export const displayDate = ({
    isoDateStr,
    format = 'MM/DD/YYYY',
    timeZone = 'UTC',
    displayTimeZone = false,
}: {
    isoDateStr: string;
    format?: string;
    timeZone?: string;
    displayTimeZone?: boolean;
}): string => {
    return `${moment.utc(isoDateStr).tz(timeZone).format(format)}${
        displayTimeZone ? ` ${timeZone}` : ''
    }`;
};

export const displayDateLocale = ({
    isoDateStr,
    format = 'MM/DD/YYYY',
}: {
    isoDateStr: string;
    format?: string;
}): string => {
    return moment.utc(isoDateStr).local().format(format);
};

export const displayBirthday = ({
    isoDateStr,
    format = 'MM/DD/YYYY',
}: {
    isoDateStr: string;
    format?: string;
}): string => {
    return displayDate({ isoDateStr, format, timeZone: 'UTC' });
};

export const displayDateWithAbbrTimeZone = ({
    isoDateStr,
    format = 'MM/DD/YYYY',
    timeZone = 'UTC',
}: {
    isoDateStr: string;
    format?: string;
    timeZone?: string;
}): string => {
    return `${moment.utc(isoDateStr).tz(timeZone).format(format)} ${moment()
        .tz(timeZone)
        .zoneAbbr()} `;
};

export const dateToISOString = (date: string | Date) => {
    return `${formatISO(new Date(date), { representation: 'date' })}T00:00:00+00:00`;
};

export const displayDateWithLocalTimeZoneAbbr = ({
    isoDateStr,
    format = 'MM/DD/yyyy hh:mm:ss a',
}: {
    isoDateStr: string;
    format?: string;
}) => {
    return `${moment.utc(isoDateStr).local().format(format)} ${
        new Date()
            .toLocaleTimeString('en-us', {
                timeZoneName: 'short',
            })
            .split(' ')[2]
    }`;
};
export const displayDateAndTimeFromParts = (date: string): string => {
    return `${date.substring(5, 7)}/${date.substring(8, 10)}/${date.substring(
        0,
        4,
    )} ${date.substring(11, 16)}`;
};
export const constructDateFromParts = (
    date: string,
    hours: number,
    mins: number,
    timezone: string,
): string => {
    const year = Number(date.substring(0, 4));
    const month = Number(date.substring(5, 7));
    const day = Number(date.substring(8, 10));
    const tzDate = new TZDate(year, month - 1, day, hours, mins, timezone);
    const utcDate = new TZDate(tzDate, 'UTC').toISOString();
    return utcDate;
};
