import { Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import useUser from '~/hooks/useUser';

export const LBS = 2.20462;
export const WEIGHT_UNIT_DEFAULT = 'lb';

type WeightValueProps = {
    value: number;
    showUnit?: boolean;
};

const WeightValue: React.FC<WeightValueProps> = ({ value, showUnit = false }) => {
    const { data: userData } = useUser();
    const [convertedValue, setConvertedValue] = useState<number>(value * LBS);
    const [unit, setUnit] = useState<string>(WEIGHT_UNIT_DEFAULT);

    useEffect(() => {
        const weightUnitFromOrg =
            userData?.currentUser?.currentOrganization.organizationMeasurementConfig?.weight
                ?.units ??
            userData?.currentUser?.rootOrg.organizationMeasurementConfig?.weight?.units;
        const weightUnit = weightUnitFromOrg ?? WEIGHT_UNIT_DEFAULT;
        setUnit(weightUnit);
        if (weightUnit !== WEIGHT_UNIT_DEFAULT) {
            setConvertedValue(value); // show in kg
        }
    }, [userData, value]);

    return (
        <Typography>
            {convertedValue.toFixed(2)}
            {showUnit && ` (${unit})`}
        </Typography>
    );
};

export default WeightValue;
