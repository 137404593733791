import React, { useCallback, useEffect } from 'react';
import {
    Grid,
    TextField,
    DialogContent,
    Button,
    DialogActions,
    InputLabel,
    FormControl,
    Select,
    MenuItem,
    FormHelperText,
    FormGroup,
    FormControlLabel,
    Checkbox,
} from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import LoadingButton from '@mui/lab/LoadingButton';
import SaveIcon from '@mui/icons-material/Save';
import { Controller, useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import { TriggerGlobalAlert, SuppressNextGlobalAlert, TriggerGlobalConfirm } from '~/state';
import Loading from '~/components/Loading/Loading';
import {
    AlertSeverity,
    ModifiedBenefitsHealthPlan,
    ModifiedBenefitsType,
    VcGroupModifiedBenefits,
    useCreateVcGroupModifiedBenefitsMutation,
    useDeleteVcGroupModifiedBenefitsMutation,
    useUpdateVcGroupModifiedBenefitsMutation,
    useVcGroupModifiedBenefitsLazyQuery,
} from '~/schemaTypes';
import { VC_GROUP_MODIFIED_BENEFITS_SCHEMA } from './yupSchema';
import ToggleButtonSwitch from '../../../components/ToggleButtonSwitch/ToggleButtonSwitch';
import { useUserPermissions } from '../../../hooks';

type VCGroupModifiedBenefitsEditModalProps = {
    id?: string;
    onClose: () => void;
};

type VCGroupModifiedBenefitsFormInput = Pick<
    VcGroupModifiedBenefits,
    'name' | 'groupId' | 'healthPlan' | 'maxAllottedVisits' | 'type' | 'isWhitelisted'
>;

export const VCGroupModifiedBenefitsEditModal: React.FC<VCGroupModifiedBenefitsEditModalProps> = ({
    id,
    onClose,
}) => {
    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
        control,
        watch,
    } = useForm<VCGroupModifiedBenefitsFormInput>({
        resolver: yupResolver(VC_GROUP_MODIFIED_BENEFITS_SCHEMA as any),
    });

    const name = watch('name');
    const type = watch('type');
    const healthPlan = watch('healthPlan');
    const { pagePermissions } = useUserPermissions();

    const [fetchVCGroupModifiedBenefits, { loading: vcGroupModifiedBenefitsLoading }] =
        useVcGroupModifiedBenefitsLazyQuery({
            onCompleted: data => {
                const fetchedData = data.vCGroupModifiedBenefits;
                if (fetchedData) {
                    setValue('name', fetchedData.name);
                    setValue('groupId', fetchedData.groupId);
                    setValue('type', fetchedData.type);
                    setValue('healthPlan', fetchedData.healthPlan);
                    if (fetchedData.maxAllottedVisits) {
                        setValue('maxAllottedVisits', fetchedData.maxAllottedVisits);
                    }
                    if (fetchedData.isWhitelisted) {
                        setValue('isWhitelisted', fetchedData.isWhitelisted);
                    }
                }
            },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
        });

    useEffect(() => {
        if (id) {
            fetchVCGroupModifiedBenefits({ variables: { input: { id } } });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [id]);

    const [createVcGroupModifiedBenefits, { loading: createLoading }] =
        useCreateVcGroupModifiedBenefitsMutation({
            onCompleted: data => {
                if (!data.createVCGroupModifiedBenefits?.success) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message:
                            data.createVCGroupModifiedBenefits?.message ?? 'Unknown Error Occurred',
                    });
                    return;
                }
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Entry created successfully',
                });
                onClose();
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
            refetchQueries: ['VCGroupModifiedBenefitsForVCGroupModifiedBenefitsPage'],
        });

    const [updateVcGroupModifiedBenefits, { loading: updateLoading }] =
        useUpdateVcGroupModifiedBenefitsMutation({
            onCompleted: data => {
                if (!data.updateVCGroupModifiedBenefits?.success) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message:
                            data.updateVCGroupModifiedBenefits?.message ?? 'Unknown Error Occurred',
                    });
                    return;
                }
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Entry updated successfully',
                });
                onClose();
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
            refetchQueries: ['VCGroupModifiedBenefitsForVCGroupModifiedBenefitsPage'],
        });

    const [deleteVcGroupModifiedBenefits] = useDeleteVcGroupModifiedBenefitsMutation({
        onCompleted: data => {
            if (!data.deleteVCGroupModifiedBenefits?.success) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message:
                        data.deleteVCGroupModifiedBenefits?.message ?? 'Unknown Error Occurred',
                });
                return;
            }
            TriggerGlobalAlert({
                severity: AlertSeverity.Success,
                message: 'Entry deleted successfully',
            });
            onClose();
        },
        onError: error => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: error.message,
            });
        },
        refetchQueries: ['VCGroupModifiedBenefitsForVCGroupModifiedBenefitsPage'],
    });

    const deleteItem = useCallback(
        (id: string) => {
            TriggerGlobalConfirm({
                message: `Do you want to delete VC Group Modified Benefits entry ${id}?`,
                callback: () => {
                    SuppressNextGlobalAlert(true);
                    deleteVcGroupModifiedBenefits({
                        variables: {
                            input: {
                                id,
                            },
                        },
                    });
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [],
    );

    const onSubmit = (data: VCGroupModifiedBenefitsFormInput) => {
        SuppressNextGlobalAlert(true);
        const payload = {
            name: data.name,
            groupId: data.groupId,
            healthPlan: data.healthPlan,
            type: data.type,
            ...(data.type === ModifiedBenefitsType.ModifiedBenefits
                ? {
                      maxAllottedVisits: data.maxAllottedVisits,
                      isWhitelisted: data.isWhitelisted ?? false,
                  }
                : {
                      maxAllottedVisits: null,
                      isWhitelisted: null,
                  }),
        };
        if (id) {
            updateVcGroupModifiedBenefits({
                variables: {
                    input: {
                        id,
                        data: payload,
                    },
                },
            });
        } else {
            createVcGroupModifiedBenefits({
                variables: {
                    input: payload,
                },
            });
        }
    };

    if (vcGroupModifiedBenefitsLoading) {
        return <Loading />;
    }

    return (
        <div>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => onClose()}>
                {id ? `Edit ${name}` : 'Add Lactation Group'}
            </DialogTitleWithClose>
            <DialogContent dividers>
                {(createLoading || updateLoading) && <Loading />}
                {!(createLoading || updateLoading) && (
                    <form noValidate onSubmit={handleSubmit(onSubmit)}>
                        <Grid container direction="column">
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Name"
                                    fullWidth
                                    margin="dense"
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            </Grid>
                            <Grid item>
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    label="Group Id"
                                    fullWidth
                                    margin="dense"
                                    {...register('groupId')}
                                    error={!!errors.groupId}
                                    helperText={errors.groupId?.message}
                                />
                            </Grid>
                            <Grid item>
                                <FormControl fullWidth>
                                    <InputLabel id="health-plan-label">Health Plan</InputLabel>
                                    <Controller
                                        name="healthPlan"
                                        control={control}
                                        defaultValue={healthPlan}
                                        render={({ field: { value } }) => (
                                            <Select
                                                label="Health Plan"
                                                variant="outlined"
                                                labelId="health-plan-label"
                                                id="health-plan"
                                                value={value}
                                                {...register('healthPlan')}
                                                error={!!errors.healthPlan}
                                            >
                                                <MenuItem value={ModifiedBenefitsHealthPlan.Cigna}>
                                                    Cigna
                                                </MenuItem>
                                                <MenuItem value={ModifiedBenefitsHealthPlan.Anthem}>
                                                    Anthem
                                                </MenuItem>
                                            </Select>
                                        )}
                                    />
                                </FormControl>
                            </Grid>
                            <Grid item>
                                <Controller
                                    render={({ field: { onChange, value } }) => (
                                        <ToggleButtonSwitch
                                            buttons={[
                                                {
                                                    label: 'Modified Benefits',
                                                    value: ModifiedBenefitsType.ModifiedBenefits,
                                                },
                                                {
                                                    label: 'Out of Network',
                                                    value: ModifiedBenefitsType.OutOfNetwork,
                                                },
                                            ]}
                                            onChange={(_, value) => {
                                                if (value !== null) {
                                                    onChange(value);
                                                }
                                            }}
                                            value={value}
                                        />
                                    )}
                                    name="type"
                                    control={control}
                                />
                                <FormHelperText error={!!errors.type}>
                                    {errors.type?.message as string}
                                </FormHelperText>
                            </Grid>
                            {type === ModifiedBenefitsType.ModifiedBenefits && (
                                <>
                                    <Grid item>
                                        <TextField
                                            variant="outlined"
                                            type="number"
                                            label="Maximum Allotted Visits Per Pregnancy"
                                            fullWidth
                                            margin="dense"
                                            {...register('maxAllottedVisits')}
                                            error={!!errors.maxAllottedVisits}
                                            helperText={errors.maxAllottedVisits?.message}
                                            InputProps={{
                                                inputProps: {
                                                    min: 1,
                                                    max: 366,
                                                },
                                            }}
                                        />
                                    </Grid>
                                    <Grid item>
                                        <FormGroup style={{ paddingLeft: 12 }}>
                                            <Controller
                                                control={control}
                                                name="isWhitelisted"
                                                render={({ field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                checked={field.value ?? false}
                                                            />
                                                        }
                                                        label="Add Group ID to Whitelist"
                                                    />
                                                )}
                                            />
                                        </FormGroup>
                                    </Grid>
                                </>
                            )}
                        </Grid>
                    </form>
                )}
            </DialogContent>
            <DialogActions>
                <Grid container justifyContent="space-between" padding={2}>
                    <Grid item>
                        <Grid container gap={1}>
                            <LoadingButton
                                startIcon={<SaveIcon />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                                loading={createLoading || updateLoading}
                                onClick={() => handleSubmit(onSubmit)()}
                            >
                                Save
                            </LoadingButton>
                            <Button onClick={() => onClose()} color="secondary" variant="outlined">
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid item>
                        {pagePermissions?.VcGroupModifiedBenefits.Delete && id && (
                            <Button
                                onClick={async () => {
                                    deleteItem(id);
                                }}
                                color="error"
                                variant="contained"
                            >
                                Delete
                            </Button>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </div>
    );
};
