import MaterialTable, { MTableAction } from '@material-table/core';
import { Loop, SimCardDownloadOutlined } from '@mui/icons-material';
import {
    Button,
    Checkbox,
    Dialog,
    FormControlLabel,
    Grid,
    Link,
    TablePagination,
    Typography,
} from '@mui/material';
import moment from 'moment/moment';
import React, { useCallback, useRef, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import { keyframes } from 'tss-react';
import { makeStyles } from 'tss-react/mui';
import {
    AffiliateCareInvoicesV2Query,
    AffiliateInvoicePaymentStatus,
    AlertSeverity,
    BatchTaskStatus,
    BatchTaskType,
    OrderByDirectionEnum,
    useAffiliateCareInvoicesV2Query,
    useAffiliateInvoicesCreateBatchTaskMutation,
    useFetchInvoicesExportToCsvLazyQuery,
} from '~/schemaTypes';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { useAffiliateCareView, useUser, useVcUserView } from '~/hooks';
import { TriggerGlobalAlert, SuppressNextGlobalAlert } from '~/state';
import { PAGESIZE } from '~/constants';
import ExportInvocesModal from './components/ExportInvoicesModal';

type InvoicesProps = {
    affiliateId?: string;
};

export const useStyles = makeStyles()({
    rotating: {
        animation: `${keyframes`
        from {
            transform: rotate(360deg);
        }
        to {
            transform: rotate(0deg);
        }
        `} 1s linear infinite`,
    },
});

const Invoices: React.FC<InvoicesProps> = props => {
    const { affiliateId } = props;

    const { data: userData } = useUser();
    const { isVcUser, isLcView } = useVcUserView();
    const tableRef = useRef<HTMLDivElement>(null);
    const { userAffiliateId, isAffiliateView } = useAffiliateCareView();
    const { classes } = useStyles();

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(PAGESIZE);
    const [onlyUnpaidFilter, setOnlyUnpaidFilter] = useState(false);
    const [openExportInvoicesModal, setOpenExportInvoicesModal] = useState(false);

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const handleChangePage = (_: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
        setPage(pageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const { data, loading } = useAffiliateCareInvoicesV2Query({
        variables: {
            input: {
                filter: {
                    fields: {
                        ...(isAffiliateView && { affiliateId: userAffiliateId }),
                        ...(affiliateId && { affiliateId }),
                        ...(isVcUser && { userId: userData?.currentUser?.id }),
                    },
                    ...(onlyUnpaidFilter && {
                        fieldsInList: {
                            paymentStatus: [
                                AffiliateInvoicePaymentStatus.PartialPayment,
                                AffiliateInvoicePaymentStatus.InProcess,
                                AffiliateInvoicePaymentStatus.Open,
                                AffiliateInvoicePaymentStatus.Scheduled,
                            ],
                        },
                    }),
                },
                orderBy: {
                    field: 'createdAt',
                    order: OrderByDirectionEnum.Desc,
                },
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
            },
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });

    const [fetchCSVExport, { loading: csvExportLoading }] = useFetchInvoicesExportToCsvLazyQuery({
        fetchPolicy: 'no-cache',
        onCompleted: data => {
            const csvContent = data?.invoicesExportToCSV?.data;
            const fileName = data?.invoicesExportToCSV?.fileName;
            if (csvContent) {
                const parsedContent = csvContent.filter(c => c !== null) as string[];
                const forBLob: ArrayLike<number> = new Uint8Array(
                    parsedContent.map(c => parseInt(c, 10)),
                );
                const { buffer } = new Uint8Array(forBLob);
                const blob = new Blob([buffer], { type: 'text/csv' });
                const url = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = url;
                a.download = fileName ?? 'invoices.csv';
                a.click();
                a.remove();
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Warning,
                    message: 'Recieved empty response for csv documetn from the server.',
                });
            }
        },
        onError: () => {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Failed to export Invoices.',
            });
        },
    });

    const [runRecheckJob, { loading: createJobLoading }] =
        useAffiliateInvoicesCreateBatchTaskMutation({
            onCompleted: () =>
                TriggerGlobalAlert({
                    message: 'Job will be run',
                    severity: AlertSeverity.Success,
                }),
        });

    const refreshPaymentStatusHandler = useCallback(() => {
        SuppressNextGlobalAlert(true);
        runRecheckJob({
            variables: {
                input: {
                    type: BatchTaskType.InvoicePaymentStatusRecheck,
                    status: BatchTaskStatus.NotStarted,
                    addedBy: userData?.currentUser?.name ?? '',
                    priority: 5,
                    runAfter: new Date(),
                },
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    const handleExport = (startDate: string, endDate: string) => {
        if (csvExportLoading) return;
        fetchCSVExport({
            variables: {
                input: {
                    startDate,
                    endDate,
                },
            },
        });
    };

    return (
        <Grid container>
            <Grid item xs={12} ref={tableRef}>
                <MaterialTable<AffiliateCareInvoicesV2Query['affiliateInvoicesV2']['results'][0]>
                    title="Invoices"
                    columns={[
                        {
                            title: 'Invoice ID',
                            field: 'id',
                            render: ({ id }) => (
                                <Link to={id} component={RouterLink}>
                                    {id}
                                </Link>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Affiliate',
                            field: 'AffiliateId',
                            render: ({ affiliate }) => (
                                <Link
                                    to={`/app-config/vcaffiliates/${affiliate?.id}`}
                                    component={RouterLink}
                                >
                                    {affiliate?.internalName}
                                </Link>
                            ),
                            sorting: false,
                            hidden: isAffiliateView || !!affiliateId || isVcUser,
                        },
                        {
                            title: 'Created At',
                            field: 'createdAt',
                            render: ({ createdAt }) => (
                                <Typography data-test={createdAt}>
                                    {moment(createdAt).local().format('MM/DD/YYYY hh:mm A')}
                                </Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Payment Status',
                            field: 'paymentStatus',
                            render: ({ paymentStatus }) => (
                                <Typography data-test={paymentStatus}>
                                    {paymentStatus === AffiliateInvoicePaymentStatus.PaidInFull
                                        ? 'Paid'
                                        : 'Unpaid'}
                                </Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'PaymentTotal',
                            field: 'paymentTotal',
                            render: ({ paymentTotal }) => (
                                <Typography data-test={paymentTotal}>${paymentTotal}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Batch Claim ID',
                            field: 'batchClaimId',
                            render: ({ batchClaimId }) => (
                                <Typography data-test={batchClaimId}>{batchClaimId}</Typography>
                            ),
                            sorting: false,
                        },
                    ]}
                    data={
                        data?.affiliateInvoicesV2.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    options={{
                        search: false,
                        paging: true,
                        pageSize: rowsPerPage,
                        pageSizeOptions: [10, PAGESIZE, 50],
                    }}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    totalCount={data?.affiliateInvoicesV2.total}
                    page={page}
                    isLoading={loading}
                    actions={[
                        {
                            tooltip: 'Export Invoices',
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick: () => {},
                            icon: () => (
                                <Button
                                    variant="outlined"
                                    onClick={() => setOpenExportInvoicesModal(true)}
                                    style={{ marginRight: '20px' }}
                                >
                                    {csvExportLoading ? (
                                        <Loop className={classes.rotating} />
                                    ) : (
                                        <SimCardDownloadOutlined />
                                    )}{' '}
                                    Export Invoices
                                </Button>
                            ),
                            hidden: !isAffiliateView && !isLcView,
                            disabled: csvExportLoading,
                            isFreeAction: true,
                        },
                        {
                            isFreeAction: true,
                            icon: () => (
                                <FormControlLabel
                                    label="Only show unpaid invoices"
                                    control={
                                        <Checkbox
                                            checked={onlyUnpaidFilter}
                                            onChange={event =>
                                                setOnlyUnpaidFilter(event.target.checked)
                                            }
                                        />
                                    }
                                />
                            ),
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick: () => {},
                        },
                        {
                            hidden: isAffiliateView || isVcUser,
                            // eslint-disable-next-line @typescript-eslint/no-empty-function
                            onClick: () => {},
                            isFreeAction: true,
                            icon: () => (
                                <AsyncActionButton loading={createJobLoading}>
                                    <Button
                                        onClick={refreshPaymentStatusHandler}
                                        variant="outlined"
                                        disabled={createJobLoading}
                                    >
                                        Refresh Bill.com Payment Status
                                    </Button>
                                </AsyncActionButton>
                            ),
                        },
                    ]}
                    components={{
                        Action: props => {
                            const {
                                action: { isFreeAction, icon, hidden },
                            } = props;
                            if (isFreeAction && !hidden) {
                                return icon();
                            }
                            return <MTableAction {...props} />;
                        },
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={data?.affiliateInvoicesV2?.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                    }}
                />
            </Grid>
            <Dialog scroll="paper" open={openExportInvoicesModal}>
                <ExportInvocesModal
                    onClose={() => setOpenExportInvoicesModal(false)}
                    onExport={handleExport}
                />
            </Dialog>
        </Grid>
    );
};

export default Invoices;
