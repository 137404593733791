import { useMemo, useCallback } from 'react';
import { useVcUserView } from '~/hooks';
import { FilterFields } from './types';
import { PORTAL_FILTERS, AFFILIATE_FILTERS, DOULA_FILTERS, LC_FILTERS } from './constants';

const isEnabledFilter = (filterName: FilterFields, currentFilterSet: FilterFields[]) => {
    return currentFilterSet.includes(filterName);
};

const useFilters = (isAffiliateCareView: boolean) => {
    const { isDoulaView, isLcView } = useVcUserView();
    const currentFilterSet = useMemo(() => {
        if (isLcView) {
            return LC_FILTERS;
        }
        if (isDoulaView) {
            return DOULA_FILTERS;
        }
        if (isAffiliateCareView) {
            return AFFILIATE_FILTERS;
        }
        return PORTAL_FILTERS;
    }, [isDoulaView, isAffiliateCareView, isLcView]);
    const showFilterField = useCallback(
        (fieldName: FilterFields) => {
            return isEnabledFilter(fieldName, currentFilterSet);
        },
        [currentFilterSet],
    );
    const filteredTags = showFilterField(FilterFields.filteredTags)
        ? localStorage.getItem(FilterFields.filteredTags)
        : '';
    const filteredAdvocate = showFilterField(FilterFields.filteredAdvocate)
        ? localStorage.getItem(FilterFields.filteredAdvocate)
        : '';
    const filteredFirstName = showFilterField(FilterFields.filteredFirstName)
        ? localStorage.getItem(FilterFields.filteredFirstName)
        : '';
    const filteredLastName = showFilterField(FilterFields.filteredLastName)
        ? localStorage.getItem(FilterFields.filteredLastName)
        : '';
    const filteredRiskScore = showFilterField(FilterFields.filteredRiskScore)
        ? localStorage.getItem(FilterFields.filteredRiskScore)
        : '';
    const filteredExternalId = showFilterField(FilterFields.filteredExternalId)
        ? localStorage.getItem(FilterFields.filteredExternalId)
        : '';
    const filteredBirthDate = showFilterField(FilterFields.filteredBirthDate)
        ? localStorage.getItem(FilterFields.filteredBirthDate)
        : '';
    const filteredEmail = showFilterField(FilterFields.filteredEmail)
        ? localStorage.getItem(FilterFields.filteredEmail)
        : '';
    const filteredPhoneNumber = showFilterField(FilterFields.filteredPhoneNumber)
        ? localStorage.getItem(FilterFields.filteredPhoneNumber)
        : '';
    const filteredMemberId = showFilterField(FilterFields.filteredMemberId)
        ? localStorage.getItem(FilterFields.filteredMemberId)
        : '';
    const filterTestData = showFilterField(FilterFields.filterTestData)
        ? localStorage.getItem(FilterFields.filterTestData) === 'true'
        : false;
    const filteredClinics = showFilterField(FilterFields.filteredClinics)
        ? localStorage.getItem(FilterFields.filteredClinics)
        : '';
    const filteredAffiliateId = showFilterField(FilterFields.filteredAffiliateId)
        ? localStorage.getItem(FilterFields.filteredAffiliateId)
        : '';
    const filteredLanguageChoiceIds = showFilterField(FilterFields.filteredLanguage)
        ? localStorage.getItem(FilterFields.filteredLanguage)
        : '';
    return {
        values: {
            filteredTags,
            filteredAdvocate,
            filteredFirstName,
            filteredLastName,
            filteredRiskScore,
            filteredExternalId,
            filteredBirthDate,
            filteredEmail,
            filteredPhoneNumber,
            filteredMemberId,
            filterTestData,
            filteredClinics,
            filteredAffiliateId,
            filteredLanguageChoiceIds,
        },
        filters: [...currentFilterSet],
        activeFilterCount: [
            filteredTags,
            filteredAdvocate,
            filteredFirstName,
            filteredLastName,
            filteredRiskScore,
            filteredExternalId,
            filteredBirthDate,
            filteredEmail,
            filteredPhoneNumber,
            filteredMemberId,
            filterTestData,
            filteredClinics,
            filteredAffiliateId,
            filteredLanguageChoiceIds,
        ].filter(value => value).length,
    };
};

export default useFilters;
