import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { ClaimSubmittedVisitType } from '~/schemaTypes';
import { AffiliateClaim } from '../types';

const AffiliateForm = () => {
    const {
        control,
        formState: { errors },
    } = useFormContext<AffiliateClaim>();
    return (
        <Grid item xs={7}>
            <Controller
                control={control}
                name="serviceType"
                defaultValue=""
                render={({ field: { onChange, value } }) => (
                    <FormControl fullWidth error={!!errors.serviceType}>
                        <InputLabel id="serviceType">Service Type</InputLabel>
                        <Select
                            value={value}
                            fullWidth
                            onChange={e => onChange(e.target.value)}
                            error={!!errors.serviceType}
                            labelId="serviceType"
                            label="Service Type"
                        >
                            <MenuItem
                                key={ClaimSubmittedVisitType.Home}
                                value={ClaimSubmittedVisitType.Home}
                            >
                                Home
                            </MenuItem>
                            <MenuItem
                                key={ClaimSubmittedVisitType.Office}
                                value={ClaimSubmittedVisitType.Office}
                            >
                                Office
                            </MenuItem>
                            <MenuItem
                                key={ClaimSubmittedVisitType.Virtual}
                                value={ClaimSubmittedVisitType.Virtual}
                            >
                                Virtual
                            </MenuItem>
                        </Select>
                        <FormHelperText>{errors.serviceType?.message}</FormHelperText>
                    </FormControl>
                )}
            />
        </Grid>
    );
};

export default AffiliateForm;
