import React from 'react';
import { GetPatientNotesForPatientTranscriptPageQuery } from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type PatientNote = NonNullable<
    GetPatientNotesForPatientTranscriptPageQuery['getPatientNotesForPatientTranscript']
>[0];

export const PatientNoteCard = ({ patientNote }: { patientNote: PatientNote }) => {
    return (
        <BaseCard title="Note" data={patientNote}>
            <p className="m-0">Title: {patientNote?.title}</p>
            <p className="m-0">Created By: {patientNote?.staffName}</p>
            <p className="m-0">Note:</p>
            <p
                className="m-0"
                style={{ display: 'inline-block' }}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: patientNote?.text || '' }}
            />
        </BaseCard>
    );
};
