import get from 'lodash/get';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Box,
    Button,
    DialogContent,
    FormControl,
    Grid,
    IconButton,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableFooter,
    TableHead,
    TablePagination,
    TableRow,
    TextField,
    Typography,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from 'tss-react/mui';
import {
    FirstPage,
    KeyboardArrowLeft,
    KeyboardArrowRight,
    LastPage,
    Search,
} from '@mui/icons-material';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    OrderByDirectionEnum,
    Patient,
    PatientFilterFilterInput,
    useFetchPatientsForSearchModalLazyQuery,
    useFetchPatientByIdForSearchModalLazyQuery,
} from '~/schemaTypes';

import { PatientSortingFields } from '~/views/Dashboard/Patients/types';
import { formSchema } from './validation';

export interface SearchPatientFormInput {
    firstName: string;
    lastName: string;
    patientId: string;
}

interface IPatientSearchModalProps {
    patientsList: Patient[];
    totalPatientsCount: number;
    selectedPatient: Patient | null;
    patientsNotFound: boolean;
    setPatientsList: (patients: Patient[]) => void;
    setShowPatientSearchModal: (isOpen: boolean) => void;
    setTotalPatientsCount: (count: number) => void;
    setSelectedPatient: (patient: Patient) => void;
    setPatientsNotFound: (value: boolean) => void;
    additionalColumns?: { title: string; name: string }[];
}

const useStyles = makeStyles()(theme => ({
    tableTitle: {
        paddingTop: theme.spacing(2),
        paddingLeft: theme.spacing(1),
        fontWeight: 700,
    },
    head: {
        background: 'rgb(0 0 0 / 46%)',
    },
    tableCell: {
        color: 'white',
    },
    tableSelectButton: {
        background: 'white',
        color: 'black',
        border: '1px solid black',
        '&:hover': {
            background: 'white',
        },
    },
    notFoundWrap: {
        margin: '10px 0',
        padding: theme.spacing(2),
    },
    notFound: {
        textAlign: 'center',
        fontWeight: 600,
        color: theme.colors.ErrorRed,
    },
}));

interface TablePaginationActionsProps {
    count: number;
    page: number;
    rowsPerPage: number;
    onPageChange: (event: React.MouseEvent<HTMLButtonElement>, newPage: number) => void;
}

function TablePaginationActions(props: TablePaginationActionsProps) {
    const theme = useTheme();
    const { count, page, rowsPerPage, onPageChange } = props;

    const handleFirstPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, 0);
    };

    const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page - 1);
    };

    const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, page + 1);
    };

    const handleLastPageButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
    };

    return (
        <Box sx={{ flexShrink: 0, ml: 2.5 }}>
            <IconButton
                onClick={handleFirstPageButtonClick}
                disabled={page === 0}
                aria-label="first page"
                size="large"
            >
                {theme.direction === 'rtl' ? <LastPage /> : <FirstPage />}
            </IconButton>
            <IconButton
                onClick={handleBackButtonClick}
                disabled={page === 0}
                aria-label="previous page"
                size="large"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
            </IconButton>
            <IconButton
                onClick={handleNextButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="next page"
                size="large"
            >
                {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
            </IconButton>
            <IconButton
                onClick={handleLastPageButtonClick}
                disabled={page >= Math.ceil(count / rowsPerPage) - 1}
                aria-label="last page"
                size="large"
            >
                {theme.direction === 'rtl' ? <FirstPage /> : <LastPage />}
            </IconButton>
        </Box>
    );
}

const PatientSearchModal: React.FC<IPatientSearchModalProps> = ({
    patientsList,
    selectedPatient,
    totalPatientsCount,
    patientsNotFound,
    setPatientsList,
    setShowPatientSearchModal,
    setTotalPatientsCount,
    setSelectedPatient,
    setPatientsNotFound,
    additionalColumns,
}) => {
    const { classes } = useStyles();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalPatientsCount) : 0;

    const patientSearchMethods = useForm<SearchPatientFormInput>({
        resolver: yupResolver(formSchema as any),
    });
    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
    } = patientSearchMethods;

    const firstName = watch('firstName');
    const lastName = watch('lastName');
    const patientId = watch('patientId');

    const [fetchPatients, { loading: fetchPatientsLoading }] =
        useFetchPatientsForSearchModalLazyQuery({
            onCompleted: data => {
                if (data?.patientsV2?.results.length) {
                    setPatientsList(data.patientsV2.results as Patient[]);
                    setPatientsNotFound(false);
                    setTotalPatientsCount(data.patientsV2.total);
                } else {
                    setPatientsList([]);
                    setPatientsNotFound(true);
                    setTotalPatientsCount(0);
                }
            },
            fetchPolicy: 'network-only',
        });

    const [fetchPatient, { loading: patientLoading }] = useFetchPatientByIdForSearchModalLazyQuery({
        onCompleted: data => {
            if (data.patient) {
                setPatientsList([data.patient] as Patient[]);
                setPatientsNotFound(false);
                setTotalPatientsCount(1);
            } else {
                setPatientsList([]);
                setPatientsNotFound(true);
                setTotalPatientsCount(0);
            }
        },
        fetchPolicy: 'network-only',
    });

    const searchPatients = ({
        firstName,
        lastName,
        patientId,
    }: {
        firstName: string;
        lastName: string;
        patientId: string;
    }) => {
        if (patientId) {
            fetchPatient({
                variables: {
                    input: {
                        id: patientId,
                    },
                },
            });
        } else {
            fetchPatients({
                variables: {
                    input: {
                        pagination: {
                            skip: page,
                            limit: rowsPerPage,
                        },
                        orderBy: {
                            field: PatientSortingFields.createdAt,
                            order: OrderByDirectionEnum.Desc,
                        },
                        filter: {
                            fields: {
                                ...(firstName ? { firstName } : null),
                                ...(lastName ? { lastName } : null),
                            },
                        } as PatientFilterFilterInput | null,
                    },
                },
            });
        }
    };

    useEffect(() => {
        if (firstName || lastName || patientId) {
            searchPatients({ firstName, lastName, patientId });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [page, rowsPerPage]);

    const handleChangePage = (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const onSelectPatient = (patient: Patient) => {
        setSelectedPatient(patient);
        setShowPatientSearchModal(false);
    };

    return (
        <Grid>
            <DialogTitleWithClose
                id="Patient Search"
                onClose={() => setShowPatientSearchModal(false)}
            >
                Patient search
            </DialogTitleWithClose>
            <DialogContent dividers>
                <form noValidate onSubmit={handleSubmit(searchPatients)}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                id="firstName"
                                label="First Name"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.firstName}
                                helperText={errors.firstName?.message}
                                {...register('firstName')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                id="lastName"
                                label="Last Name"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.lastName}
                                helperText={errors.lastName?.message}
                                {...register('lastName')}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                id="patientId"
                                label="Id"
                                type="text"
                                margin="dense"
                                fullWidth
                                error={!!errors.patientId}
                                helperText={errors.patientId?.message}
                                {...register('patientId')}
                            />
                        </Grid>
                    </Grid>
                    <Grid item container justifyContent="space-between">
                        <FormControl variant="outlined">
                            <Button
                                endIcon={<Search />}
                                color="primary"
                                variant="contained"
                                type="submit"
                                disabled={fetchPatientsLoading || patientLoading}
                            >
                                Search
                            </Button>
                        </FormControl>
                    </Grid>
                    {!fetchPatientsLoading && !patientLoading && patientsNotFound && (
                        <Grid container justifyContent="center" className={classes.notFoundWrap}>
                            <Typography className={classes.notFound}>Patients not found</Typography>
                        </Grid>
                    )}

                    {(fetchPatientsLoading || patientLoading) && (
                        <Loading
                            height={250}
                            subtitle={`Fetching patient${fetchPatientsLoading ? 's' : ''}`}
                        />
                    )}
                    {!fetchPatientsLoading && !patientLoading && patientsList.length > 0 ? (
                        <Grid>
                            <Typography paragraph className={classes.tableTitle}>
                                Results
                            </Typography>
                            <TableContainer component={Paper}>
                                <Table aria-label="customized table">
                                    <TableHead className={classes.head}>
                                        <TableRow>
                                            <TableCell className={classes.tableCell} align="center">
                                                First Name
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="center">
                                                Last Name
                                            </TableCell>
                                            <TableCell className={classes.tableCell} align="center">
                                                Birth date
                                            </TableCell>
                                            {additionalColumns &&
                                                additionalColumns.map(column => (
                                                    <TableCell
                                                        className={classes.tableCell}
                                                        align="center"
                                                    >
                                                        {column.title}
                                                    </TableCell>
                                                ))}
                                            <TableCell
                                                className={classes.tableCell}
                                                align="center"
                                            />
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {patientsList.map(patient => (
                                            <TableRow key={patient.email}>
                                                <TableCell align="center">
                                                    {patient.firstName}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {patient.lastName}
                                                </TableCell>
                                                <TableCell align="center">
                                                    {moment(patient.birthDate).format('MM-DD-YYYY')}
                                                </TableCell>
                                                {additionalColumns &&
                                                    additionalColumns.map(column => (
                                                        <TableCell align="center">
                                                            {get(patient, column.name)}
                                                        </TableCell>
                                                    ))}
                                                <TableCell align="center">
                                                    <Button
                                                        className={classes.tableSelectButton}
                                                        variant="contained"
                                                        type="button"
                                                        onClick={() => onSelectPatient(patient)}
                                                        disabled={
                                                            selectedPatient?.id === patient.id
                                                        }
                                                    >
                                                        Select
                                                    </Button>
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                        {emptyRows > 0 && (
                                            <TableRow style={{ height: 53 * emptyRows }}>
                                                <TableCell colSpan={6} />
                                            </TableRow>
                                        )}
                                    </TableBody>
                                    <TableFooter>
                                        <TableRow>
                                            <TablePagination
                                                rowsPerPageOptions={[
                                                    5,
                                                    10,
                                                    25,
                                                    { label: 'All', value: -1 },
                                                ]}
                                                colSpan={3}
                                                count={totalPatientsCount}
                                                rowsPerPage={rowsPerPage}
                                                page={page}
                                                SelectProps={{
                                                    inputProps: {
                                                        'aria-label': 'rows per page',
                                                    },
                                                    native: true,
                                                }}
                                                onPageChange={handleChangePage}
                                                onRowsPerPageChange={handleChangeRowsPerPage}
                                                ActionsComponent={TablePaginationActions}
                                            />
                                        </TableRow>
                                    </TableFooter>
                                </Table>
                            </TableContainer>
                        </Grid>
                    ) : null}
                </form>
            </DialogContent>
        </Grid>
    );
};

export default PatientSearchModal;
