import { faClose, faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import MaterialTable from '@material-table/core';
import {
    Button,
    Chip,
    Dialog,
    DialogActions,
    DialogTitle,
    Grid,
    IconButton,
    Tab,
    Tabs,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import parse from 'html-react-parser';
import _ from 'lodash';
import React, { Dispatch, SetStateAction, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import * as Yup from 'yup';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import DateEditor from '~/components/DateEditor/DateEditor';
import Loading from '~/components/Loading/Loading';
import RichTextEditor from '~/components/RichTextEditor/RichTextEditor';
import { displayDate } from '~/helpers';
import { toBase64 } from '~/helpers/base64Helper';
import tableIcons from '~/helpers/tableIcons';
import { useVcUserView, useUser, useUserPermissions } from '~/hooks';
import {
    FetchPatientNotesForPatientNotesModalQuery,
    useCreateNewPatientNoteFromPatientNotesModalMutation,
    useFetchPatientNotesForPatientNotesModalLazyQuery,
    usePatientNoteTagListQuery,
    useUpdatePatientNoteOnPatientsNotesModalMutation,
} from '~/schemaTypes';
import { cognitoRefreshTokens } from '~/state/auth/cognito.svc';
import { INIT_DOULA_TAG, INIT_LC_TAG } from './constants';

type PatientNote = NonNullable<FetchPatientNotesForPatientNotesModalQuery['patient']>['notes'][0];

type PatientNotesModalProps = {
    handleClose: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    patientId?: string;
};

enum PatientNotesTabs {
    noteForm = 'noteForm',
    notesList = 'notesList',
}

interface PatientNotesFormInput {
    id?: string;
    text: string;
    title: string;
    noteType?: (string | null)[];
    noteSource?: string;
    staffName?: string;
    NPI?: string;
}

const PATIENT_NOTES_VALIDATION_SCHEMA = Yup.object({
    title: Yup.string().required(),
    text: Yup.string().required(),
}).noUnknown(false);

const useStyles = makeStyles()({
    root: {
        padding: '0 2rem 1rem',
    },
    tableWrap: {
        margin: '10px 0',
    },
});

let timeoutId: number | undefined;

const debounce = (callback: () => void, delay: number) => {
    return () => {
        clearTimeout(timeoutId);
        timeoutId = window.setTimeout(callback, delay);
    };
};

const PatientNotesModal: React.FC<PatientNotesModalProps> = ({
    isModalOpen,
    handleClose,
    patientId,
}) => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const { isDoulaView, isLcView, isVcUser } = useVcUserView();
    const currentUser = useUser();
    const tableRef = useRef<HTMLDivElement>(null);
    const [tab, setTab] = useState<PatientNotesTabs>(PatientNotesTabs.noteForm);
    const [isOpenEditNoteModal, setIsOpenEditNoteModal] = useState<boolean>(false);
    const [selectedNote, setSelectedNote] = useState<PatientNote | null>(null);
    const [noteDate, setNoteDate] = useState<string>(new Date().toISOString());
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [dataLoading, setDataLoading] = useState(true);

    const [getPatient, { data: patientData, loading }] =
        useFetchPatientNotesForPatientNotesModalLazyQuery();

    const { data: noteTagsData } = usePatientNoteTagListQuery();

    const [createPatientNote, { loading: creatingPatientNote }] =
        useCreateNewPatientNoteFromPatientNotesModalMutation({
            onCompleted: ({ createPatientNotes }) => {
                if (createPatientNotes && createPatientNotes.success) {
                    setTab(PatientNotesTabs.notesList);
                }
            },
        });

    const [updatePatientNote, { loading: updatingPatientNote }] =
        useUpdatePatientNoteOnPatientsNotesModalMutation({
            onCompleted: ({ updatePatientNotes }) => {
                if (updatePatientNotes && updatePatientNotes.success) {
                    setTab(PatientNotesTabs.notesList);
                    setSelectedNote(null);
                }
            },
        });

    const {
        control,
        register,
        handleSubmit,
        reset,
        setValue,

        formState: { errors },
    } = useForm<PatientNotesFormInput>({
        resolver: yupResolver(PATIENT_NOTES_VALIDATION_SCHEMA as any),
    });

    useEffect(() => {
        if (patientId) {
            getPatient({
                variables: {
                    input: {
                        id: patientId,
                    },
                },
            });
        }
    }, [patientId, getPatient]);

    useEffect(() => {
        if (selectedNote) {
            const { title, text, date, staffName, noteType, noteSource, NPI } = selectedNote;
            setIsOpenEditNoteModal(true);
            reset({
                title: title ?? '',
                text: text ?? '',
                staffName: staffName ?? '',
                noteType: noteType ?? [],
                noteSource: noteSource ?? '',
                NPI: NPI ?? '',
            });
            setNoteDate(date);
        } else {
            setIsOpenEditNoteModal(false);
            reset({
                title: '',
                text: '',
                noteType: [],
            });
            setNoteDate(new Date().toISOString());
        }
    }, [selectedNote, reset]);

    useEffect(() => {
        const updateStaffName = async () => {
            if (isVcUser && currentUser) {
                await setValue('staffName', currentUser.data?.currentUser?.name);
            }
        };

        const updateNoteType = async () => {
            const predefinedTag = noteTagsData?.patientNoteTagsV2.results?.find(item => {
                if (isDoulaView) {
                    return item.name === INIT_DOULA_TAG;
                }
                if (isLcView) {
                    return item.name === INIT_LC_TAG;
                }
                return false;
            });
            if (predefinedTag) {
                await setValue('noteType', [predefinedTag.id]);
            }
        };

        const initialize = async () => {
            await Promise.all([updateStaffName(), updateNoteType()]);
            setDataLoading(false);
        };

        initialize();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser, isDoulaView, noteTagsData?.patientNoteTagsV2.results, isLcView]);

    const refreshHandler = () => {
        cognitoRefreshTokens();
    };

    const createNote = (patientNote: PatientNotesFormInput) => {
        if (patientId) {
            createPatientNote({
                variables: {
                    input: {
                        ...patientNote,
                        text: toBase64(patientNote.text),
                        patientId,
                        date: noteDate,
                    },
                },
            });
        }
    };

    const updateNote = (patientNote: PatientNotesFormInput) => {
        if (selectedNote?.id) {
            updatePatientNote({
                variables: {
                    input: {
                        id: selectedNote.id,
                        data: {
                            noteType: patientNote.noteType,
                        },
                    },
                },
            });
        }
    };

    const handleCancel = () => {
        handleClose(false);
    };

    const onSubmit = (patientNote: PatientNotesFormInput) => {
        const saveNote = selectedNote?.id ? updateNote : createNote;
        saveNote(patientNote);
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const renderNoteForm = () => (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <TextField
                {...register('title')}
                variant="outlined"
                disabled={isOpenEditNoteModal}
                label="Reason"
                type="title"
                fullWidth
                error={!!errors.title?.message}
                helperText={errors.title?.message}
                InputLabelProps={{ shrink: true }}
            />
            <DateEditor
                title="Date"
                setSelectedDate={d => setNoteDate(d)}
                initialDate={noteDate}
                disabled={isOpenEditNoteModal}
            />
            <AutocompleteWithRecordOptions
                label="Note Types"
                name="noteType"
                options={_.sortBy(noteTagsData?.patientNoteTagsV2.results, ['name']) ?? []}
                valueKey="id"
                labelKey="name"
                control={control}
                placeholder="Select tags..."
            />
            {!isVcUser && (
                <TextField
                    {...register('noteSource')}
                    variant="outlined"
                    disabled={isOpenEditNoteModal}
                    label="Note Source"
                    type="noteSource"
                    fullWidth
                    error={!!errors.noteSource}
                    helperText={errors.noteSource?.message}
                    InputLabelProps={{ shrink: true }}
                />
            )}

            <TextField
                {...register('staffName')}
                variant="outlined"
                disabled={isOpenEditNoteModal}
                label="Staff Name"
                type="staffName"
                fullWidth
                error={!!errors.staffName}
                helperText={errors.staffName?.message}
                InputLabelProps={{ shrink: true }}
            />
            {isOpenEditNoteModal && (
                <TextField
                    {...register('NPI')}
                    variant="outlined"
                    disabled
                    label="NPI"
                    type="NPI"
                    fullWidth
                    error={!!errors.NPI}
                    helperText={errors.NPI?.message}
                    InputLabelProps={{ shrink: true }}
                />
            )}
            <Grid item xs={12} style={{ padding: '10px' }}>
                <RichTextEditor
                    onChange={value => {
                        debounce(refreshHandler, 10000)();
                        setValue('text', value);
                    }}
                    initialValue={selectedNote?.text ?? ''}
                    placeholder="Comment"
                    label="Comment"
                    disabled={isOpenEditNoteModal}
                    textArea={isVcUser}
                />
            </Grid>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <AsyncActionButton loading={creatingPatientNote || updatingPatientNote}>
                    <Button type="submit" color="secondary" variant="contained">
                        Save
                    </Button>
                </AsyncActionButton>
            </DialogActions>
        </form>
    );

    if (dataLoading) {
        return <div>Loading...</div>;
    }

    const isUpdating = creatingPatientNote || updatingPatientNote;
    return (
        <Dialog
            scroll="paper"
            onClose={(_, reason) => reason !== 'backdropClick' && handleClose(false)}
            open={isModalOpen}
            fullWidth
            maxWidth="lg"
            aria-labelledby="form-dialog-title"
        >
            {loading && <Loading subtitle="Loading notes..." />}
            {isUpdating && <Loading subtitle="Updating notes..." />}
            {patientData && !isUpdating && (
                <div className={classes.root}>
                    <div style={{ justifyContent: 'space-between', display: 'flex' }}>
                        <DialogTitle id="form-dialog-title">
                            {patientData?.patient?.firstName}&apos;s Notes
                        </DialogTitle>
                        <Tooltip title="Close">
                            <IconButton onClick={() => handleCancel()}>
                                <FontAwesomeIcon icon={faClose} />
                            </IconButton>
                        </Tooltip>
                    </div>
                    <Tabs
                        value={tab}
                        onChange={(e: React.ChangeEvent<unknown>, newValue: PatientNotesTabs) =>
                            setTab(newValue)
                        }
                    >
                        <Tab label="Notes" value={PatientNotesTabs.notesList} />
                        {pagePermissions?.PatientNotes.Edit && (
                            <Tab label="Add New Note" value={PatientNotesTabs.noteForm} />
                        )}
                    </Tabs>
                    {tab === PatientNotesTabs.notesList && (
                        <div className={classes.tableWrap} ref={tableRef}>
                            <MaterialTable
                                title="Notes"
                                icons={tableIcons}
                                columns={[
                                    { title: 'Title', field: 'title' },
                                    {
                                        title: 'Date',
                                        render: ({ date }) => (
                                            <Typography>
                                                {date && displayDate({ isoDateStr: date })}
                                            </Typography>
                                        ),
                                        customSort: (a, b) => {
                                            const dateA = new Date(a?.date ?? '').getTime();
                                            const dateB = new Date(b?.date ?? '').getTime();

                                            if (dateA === dateB) {
                                                const createdAtA = new Date(
                                                    a?.createdAt ?? '',
                                                ).getTime();
                                                const createdAtB = new Date(
                                                    b?.createdAt ?? '',
                                                ).getTime();
                                                return createdAtA - createdAtB;
                                            }

                                            return dateA - dateB;
                                        },
                                        defaultSort: 'desc',
                                        headerStyle: {
                                            textAlign: 'right',
                                        },
                                        cellStyle: {
                                            textAlign: 'right',
                                        },
                                        width: 32,
                                    },
                                    { title: 'Staff Name', field: 'staffName' },
                                    {
                                        title: 'Note Source',
                                        field: 'noteSource',
                                        hidden: isVcUser,
                                    },
                                    {
                                        title: 'Note Type',
                                        field: 'noteType',
                                        sorting: false,
                                        render: ({ noteType }) => {
                                            if (noteType && noteType.length > 0) {
                                                const noteTagsSet = new Set(noteType);
                                                return noteTagsData?.patientNoteTagsV2.results
                                                    ?.filter(item => noteTagsSet.has(item.id))
                                                    .map(tag => (
                                                        <Chip key={tag.id} label={tag.name} />
                                                    ));
                                            }
                                            return [];
                                        },
                                    },
                                ]}
                                data={patientData.patient?.notes ?? []}
                                localization={{ header: { actions: '' } }}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                options={{
                                    pageSize: rowsPerPage,
                                    grouping: true,
                                    pageSizeOptions: [25, 50, 100],
                                }}
                                detailPanel={rowData => {
                                    const {
                                        rowData: { text },
                                    } = rowData;
                                    return (
                                        <div style={{ padding: '10px 20px' }}>{parse(text)}</div>
                                    );
                                }}
                                actions={[
                                    {
                                        onClick: (_e: any, note: any) => {
                                            setSelectedNote(note);
                                        },
                                        hidden: !pagePermissions?.PatientNotes.Edit,
                                        icon: () => <FontAwesomeIcon icon={faEye} />,
                                        tooltip: 'View',
                                    },
                                ]}
                            />
                        </div>
                    )}
                    {tab === PatientNotesTabs.noteForm && renderNoteForm()}
                    <Dialog open={isOpenEditNoteModal} onClose={() => setSelectedNote(null)}>
                        <DialogTitle id="form-dialog-title">View Note Details</DialogTitle>
                        {renderNoteForm()}
                    </Dialog>
                </div>
            )}
        </Dialog>
    );
};

export default PatientNotesModal;
