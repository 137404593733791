import React from 'react';
import { ChoiceConfiguration, mapProfileVariableValue } from '~/helpers/getStringifiedValue';
import {
    GetProfileValueHistoriesByPatientIdForPatientTranscriptQuery,
    UserProfileValueType,
} from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type ProfileValue = NonNullable<
    GetProfileValueHistoriesByPatientIdForPatientTranscriptQuery['getProfileValueHistoriesByPatientId']
>[0];

export const ProfileValueCard = ({ profileValue }: { profileValue: ProfileValue }) => {
    return (
        <BaseCard title="Profile Change" data={profileValue}>
            <p className="m-0">
                {profileValue?.profileDef?.name}:{' '}
                {profileValue?.val != null &&
                    profileValue?.type != null &&
                    mapProfileVariableValue(
                        profileValue?.val,
                        profileValue?.type as unknown as UserProfileValueType,
                        {
                            choices: profileValue?.profileDef?.choices?.filter(
                                choice => choice != null,
                            ),
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            choiceValueType: profileValue?.profileDef?.choiceValueType,
                        } as ChoiceConfiguration,
                        {
                            isDateTime: profileValue?.profileDef?.isDateTime,
                        },
                    )}
            </p>
        </BaseCard>
    );
};
