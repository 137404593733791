import Color from '@tiptap/extension-color';
import Paragraph from '@tiptap/extension-paragraph';
import Placeholder from '@tiptap/extension-placeholder';
import TextStyle from '@tiptap/extension-text-style';
import Underline from '@tiptap/extension-underline';
import { EditorContent, useEditor, Editor } from '@tiptap/react';
import StarterKit from '@tiptap/starter-kit';
import React, { useEffect, useState } from 'react';
import FontSize from 'tiptap-extension-font-size';
import { makeStyles } from 'tss-react/mui';
import { SubstitutionItem } from '~/schemaTypes';
import { primaryColor } from '~/theme/WildTheme';
import { RichTextEditorBubbleMenu } from './RichTextEditorBubbleMenu';
import { CustomImage, CustomLink } from './RichTextEditorCustomExtensions';
import { RichTextEditorFloatingMenu } from './RichTextEditorFloatingMenu';
import RichTextEditorMenuBar from './RichTextEditorMenuBar/RichTextEditorMenuBar';
import { RichTextEditorSubstTokenSection } from './RichTextEditorSubstToken/RichTextEditorSubstTokenSection';

const useStyles = makeStyles<{ textArea?: boolean }>()((_theme, { textArea }) => ({
    editor: {
        maxHeight: '100%',
        outline: `1px solid ${primaryColor}`,
        borderRadius: '4px',
        width: '100%',
        maxWidth: '100%',
        padding: '0 10px 5px 10px',
    },
    editorContent: {
        outline: '1px solid black',
        borderRadius: '4px',
        overflow: 'auto',
        maxHeight: '500px',
        margin: '5px',
        minHeight: textArea ? '80px' : 'auto',
        '& .tiptap img': {
            height: 'auto',
            maxWidth: '100%',
        },
        '& .tiptap': {
            margin: '-16px 0 -16px 0',
            padding: '0px 4px 0px 4px',
        },
        '& .tiptap p:first-of-type.is-editor-empty::before': {
            color: '#adb5bd',
            content: 'attr(data-placeholder)',
            float: 'left',
            height: 0,
            pointerEvents: 'none',
        },
    },
}));

export type RichTextEditorCommands = {
    commands: {
        setContent: (value: string) => void;
        clearContent: () => void;
        setSubstitutions?: (items: SubstitutionItem[]) => void;
    };
};

export type RichTextEditorProps = {
    initialValue?: string;
    onChange?: (value: string, substitutions?: SubstitutionItem[]) => void;
    hideMenuBar?: boolean;
    hideFloatingMenu?: boolean;
    hideBubbleMenu?: boolean;
    disabled?: boolean;
    allowImages?: boolean;
    allowLinks?: boolean;
    clear?: boolean;
    setClear?: (clear: boolean) => void;
    placeholder?: string;
    label?: string;
    onInit?: (params: RichTextEditorCommands) => void;
    useSubstitutionTokens?: boolean;
    substitutions?: SubstitutionItem[];
    textArea?: boolean;
};

const RichTextEditor = ({
    initialValue,
    onChange,
    hideMenuBar = false,
    hideFloatingMenu = true,
    hideBubbleMenu = true,
    disabled = false,
    allowImages = true,
    allowLinks = true,
    clear = false,
    setClear,
    placeholder = 'Type something...',
    label,
    onInit,
    useSubstitutionTokens = false,
    substitutions,
    textArea = false,
}: RichTextEditorProps) => {
    const { classes } = useStyles({ textArea });
    const [editorSubst, setEditorSubst] = useState<SubstitutionItem[]>(substitutions || []);
    const editor = useEditor({
        extensions: [
            StarterKit,
            CustomImage.configure({ inline: true }),
            Paragraph,
            CustomLink,
            Placeholder.configure({ placeholder }),
            TextStyle,
            Color,
            Underline,
            FontSize,
        ],
        content: initialValue,
        onUpdate: ({ editor }) => {
            if (onChange) {
                onChange(editor.getHTML(), editorSubst);
            }
        },
        editable: !disabled,
    });

    useEffect(() => {
        if (onInit && editor) {
            // if (useSubstitutionTokens) {
            //     onInit({
            //         commands: {
            //             setContent: (value: string) => editor.commands.setContent(value),
            //             clearContent: () => editor.commands.clearContent(),
            //             setSubstitutions: (items: SubstitutionItem[]) => {
            //                 setEditorSubst(items);
            //             },
            //         },
            //     });
            // } else {
            onInit({
                commands: {
                    setContent: (value: string) => editor.commands.setContent(value),
                    clearContent: () => editor.commands.clearContent(),
                    setSubstitutions: (items: SubstitutionItem[]) => {
                        setEditorSubst(items);
                    },
                },
            });
            // }
        }
    }, [onInit, editor]);

    const insertSubAsLinkHandler = (token: string, name: string) => {
        if (editor) {
            const { state } = editor;
            const { from, to } = state.selection;
            let nodeHTML;
            if (to > from) {
                state.doc.nodesBetween(from, to, node => {
                    const tempEditor = new Editor({
                        extensions: [StarterKit, CustomImage],
                        content: {
                            type: 'doc',
                            content: [node.toJSON()],
                        },
                    });
                    nodeHTML = tempEditor.getHTML();
                });
            }
            const link = `<a href="${token}" title="${name}">${nodeHTML || name}</a>`;
            editor.chain().focus().extendMarkRange('link').insertContent(link).run();
        }
    };

    React.useEffect(() => {
        if (editor && clear && setClear) {
            editor.commands.clearContent();
            setClear(false);
        }
    }, [clear, editor, setClear]);

    if (!editor) {
        return null;
    }
    const substTokensChangeHandler = (substTokens: SubstitutionItem[]) => {
        setEditorSubst(substTokens);
        const aliasToRemain = substTokens.map(substToken => `@@${substToken.substitutionId}@@`);
        let editorContent = editor.getHTML();
        const matches = editorContent.match(/@@[^@]+@@/g);
        if (matches) {
            matches.forEach(match => {
                if (!aliasToRemain.includes(match)) {
                    editorContent = editorContent.replace(match, '');
                }
            });
        }
        editor.commands.setContent(editorContent);
        if (onChange) {
            onChange(editorContent, substTokens);
        }
    };

    return (
        <div
            className={classes.editor}
            style={disabled ? { color: 'rgba(0, 0, 0, 0.38)', pointerEvents: 'none' } : {}}
        >
            {useSubstitutionTokens && (
                <RichTextEditorSubstTokenSection
                    onSubstTokensChange={substTokensChangeHandler}
                    predefinedSubstTokens={editorSubst}
                    onInsertToText={(alias: string) =>
                        editor.chain().focus().insertContent(alias).run()
                    }
                    onInsertAsLink={insertSubAsLinkHandler}
                />
            )}
            {label && <div>{label}</div>}
            {!hideMenuBar && (
                <RichTextEditorMenuBar
                    editor={editor}
                    allowImages={allowImages}
                    allowLinks={allowLinks}
                />
            )}
            {!hideFloatingMenu && (
                <RichTextEditorFloatingMenu editor={editor} tippyOptions={{ duration: 100 }} />
            )}
            {!hideBubbleMenu && <RichTextEditorBubbleMenu editor={editor} />}
            <EditorContent
                editor={editor}
                onClick={() => editor.chain().focus().run()}
                className={classes.editorContent}
            />
        </div>
    );
};

export default RichTextEditor;
