export const messageCenterTokensResolver = (
    messageText: string,
    patientName: string,
    orgName: string,
    haName: string,
    currentUserName?: string,
): string => {
    const messageCenterTokenToPatientDataMap = {
        '@@PATIENT_NAME@@': patientName,
        '@@ORGANIZATION_NAME@@': orgName,
        '@@HA_NAME@@': haName,
        '@@CURRENT_USER@@': currentUserName,
    };

    for (const [token, patientVal] of Object.entries(messageCenterTokenToPatientDataMap)) {
        const regex = new RegExp(token, 'g');
        messageText = messageText?.replace(regex, patientVal ?? '');
    }
    return messageText;
};
