import React from 'react';
import { Editor } from '@tiptap/react';
import { Button, Dialog, DialogTitle } from '@mui/material';

type RichTextEditorRawHTMLDialogProps = {
    editor: Editor;
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
};

export const RichTextEditorRawHTMLDialog = ({
    editor,
    open,
    setOpen,
}: RichTextEditorRawHTMLDialogProps) => {
    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>Raw HTML</DialogTitle>
            <textarea rows={30}>{editor.getHTML()}</textarea>
            <div
                style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    margin: '0 10px 10px 0',
                    gap: '10px',
                }}
            >
                <Button size="medium" onClick={handleClose}>
                    Close
                </Button>
            </div>
        </Dialog>
    );
};
