import React, { useEffect } from 'react';
import { useAtom } from 'jotai/index';
import {
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
} from '@mui/material';

import { Controller, useForm } from 'react-hook-form';
import AppBundleIdSelect from '~/views/Dashboard/EligibilityImportResults/EligibilityImportResultFilterModal/AppBundleIdSelect/AppBundleIdSelect';
import { EligibilityImportFiltersState, EligibilityImportFilters } from '../state';
import UnitIdsAutocomplete from './UnitIdsAutocomplete/UnitIdsAutocomplete';

const initialState: EligibilityImportFilters = {
    appBundleId: '',
    unitIds: [],
    success: false,
};

const EligibilityImportResultFilterModal = ({
    onClose,
    isOpen,
}: {
    onClose: () => void;
    isOpen: boolean;
}) => {
    const { control, getValues, setValue, reset } = useForm<EligibilityImportFilters>({
        defaultValues: {
            ...initialState,
        },
    });

    const [filters, setFilters] = useAtom(EligibilityImportFiltersState);

    useEffect(() => {
        if (isOpen) {
            setValue('appBundleId', filters.appBundleId || initialState.appBundleId);
            setValue('unitIds', filters.unitIds || initialState.unitIds);
            setValue('success', filters.success || initialState.success);
        }
    }, [isOpen, filters, setValue]);

    const handleClearFiltersClick = () => {
        reset();
    };

    const handleCloseClick = () => {
        onClose();
    };

    const handleSaveClick = () => {
        const values = getValues();

        setFilters({
            appBundleId: values.appBundleId || undefined,
            unitIds: values.unitIds?.length ? values.unitIds : undefined,
            success: getValues('success') || undefined,
        });

        onClose();
    };

    return (
        <Dialog open={isOpen} onClose={handleCloseClick} fullWidth>
            <DialogTitle>Filter Eligibility Imports</DialogTitle>
            <DialogContent>
                <AppBundleIdSelect control={control} name="appBundleId" />
                <UnitIdsAutocomplete control={control} />
                <Controller
                    name="success"
                    control={control}
                    render={({ field: { onChange, value } }) => (
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={value ?? false}
                                    onChange={e => onChange(e.target.checked)}
                                />
                            }
                            label="Success"
                        />
                    )}
                />
                <div>
                    <Button onClick={handleClearFiltersClick}>Clear Filters</Button>
                </div>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCloseClick} color="primary">
                    Close
                </Button>
                <Button onClick={handleSaveClick} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default EligibilityImportResultFilterModal;
