import { faEye } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable, { MTableToolbar } from '@material-table/core';
import { Link as LinkIcon, Refresh as RefreshIcon } from '@mui/icons-material';
import {
    Dialog,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TablePagination,
    Tooltip,
    Typography,
} from '@mui/material';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { PAGESIZE } from '~/constants';
import {
    AlertSeverity,
    EligibilityResultFlow,
    OrderByDirectionEnum,
    OrganizationVcEligibilityEligibilityResultsQuery,
    useOrganizationVcEligibilityEligibilityResultsLazyQuery,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import ViewSelectedResult from './ViewSelectedResult';

type EligibilityResult = NonNullable<
    OrganizationVcEligibilityEligibilityResultsQuery['eligibilityResults']
>['results'][0];

const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

const VCFEligibility: React.FC = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const query = useQuery();
    const id = query.get('id');
    const [typeFilter, setTypeFilter] = useState<EligibilityResultFlow | ''>('');
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [showViewModal, setShowViewModal] = useState<boolean>(false);
    const [showFailureResults, setShowFailureResults] = useState<boolean>(true);
    const [selectedId, setSelectedId] = useState<string>();
    const [searchText, setSearchText] = useState<string>(id ?? '');

    const [fetchEligibilityResults, { data: eligibilityResults, loading }] =
        useOrganizationVcEligibilityEligibilityResultsLazyQuery({
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
        });
    const fetchEligibilityResultsPayload = useMemo(
        () => ({
            variables: {
                input: {
                    filter: {
                        fields: {
                            passed: showFailureResults ? false : undefined,
                            ...(typeFilter && { type: typeFilter }),
                        },
                    },
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                    pagination: {
                        skip: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                    ...(searchText && { search: searchText }),
                    // ...(id && { search: id }),
                },
            },
        }),
        [showFailureResults, page, rowsPerPage, searchText, typeFilter],
    );

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleCopyLink = (id: string) => {
        const baseUrl = `${window.location.origin}${window.location.pathname}`;
        const link = `${baseUrl}?id=${id}`;
        navigator.clipboard.writeText(link).then(() => {
            TriggerGlobalAlert({
                message: 'The Link is Copied!',
                severity: AlertSeverity.Success,
            });
        });
    };

    useEffect(() => {
        fetchEligibilityResults(fetchEligibilityResultsPayload);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [fetchEligibilityResultsPayload]);

    const resultViewHandler = useCallback((_: any, item: EligibilityResult) => {
        setSelectedId(item.id);
        setShowViewModal(true);
    }, []);

    return (
        <div style={{ marginRight: '1rem' }} ref={tableRef}>
            <Grid>
                <MaterialTable<EligibilityResult>
                    columns={[
                        {
                            title: '_id',
                            field: 'id',
                            render: ({ id }) => (
                                <div
                                    style={{ display: 'flex', alignItems: 'center', width: '100%' }}
                                >
                                    <Typography data-test={id} style={{ flexGrow: 1 }}>
                                        {id}
                                    </Typography>
                                    <Tooltip title="Copy Link">
                                        <IconButton onClick={() => handleCopyLink(id)}>
                                            <LinkIcon />
                                        </IconButton>
                                    </Tooltip>
                                </div>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Passed',
                            field: 'passed',
                            render: ({ passed, refresh }) => (
                                <Grid container alignItems="center" gap={1}>
                                    <Typography data-test={passed}>
                                        {passed ? 'True' : 'False'}
                                    </Typography>
                                    {refresh && <RefreshIcon />}
                                </Grid>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'Type',
                            field: 'type',
                            render: ({ type }) => <Typography data-test={type}>{type}</Typography>,
                            sorting: false,
                            customFilterAndSearch: () => true,
                        },
                        {
                            title: 'First Name',
                            field: 'firstName',
                            render: ({ firstName }) => (
                                <Typography data-test={firstName}>{firstName}</Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'Last Name',
                            field: 'lastName',
                            render: ({ lastName }) => (
                                <Typography data-test={lastName}>{lastName}</Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                        {
                            title: 'Date Time',
                            field: 'createdAt',
                            render: ({ createdAt }) => (
                                <Typography data-test={createdAt}>
                                    {moment(createdAt).local().format('MM-DD-YYYY hh:mm A')}
                                </Typography>
                            ),
                            sorting: false,
                            searchable: false,
                        },
                    ]}
                    data={
                        eligibilityResults?.eligibilityResults?.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    title="Virtual Care Eligibility requests"
                    options={{
                        search: true,
                        paginationPosition: 'bottom',
                        pageSize: rowsPerPage,
                        pageSizeOptions: [25, 50, 100],
                        debounceInterval: 500,
                        searchText,
                    }}
                    isLoading={loading}
                    localization={{
                        header: {
                            actions: '',
                        },
                        toolbar: {
                            searchPlaceholder: 'Search by _id, First Name, Last Name',
                        },
                    }}
                    actions={[
                        {
                            onClick: resultViewHandler,
                            icon: () => <FontAwesomeIcon icon={faEye} />,
                            tooltip: 'View Eligibility Result',
                        },
                    ]}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    onSearchChange={setSearchText}
                    components={{
                        Pagination: props => (
                            <TablePagination
                                {...props}
                                count={eligibilityResults?.eligibilityResults?.total ?? 0}
                                page={page}
                                onPageChange={handleChangePage}
                            />
                        ),
                        Toolbar: props => (
                            <Grid container justifyContent="space-between" alignItems="center">
                                <Grid item xs={7}>
                                    <MTableToolbar {...props} />
                                </Grid>
                                <Grid item minWidth={170}>
                                    <FormControl fullWidth>
                                        <InputLabel id="type-filter-label" shrink>
                                            Type
                                        </InputLabel>
                                        <Select
                                            labelId="type-filter-label"
                                            id="type-filter"
                                            label="Type"
                                            value={typeFilter}
                                            size="small"
                                            displayEmpty
                                            onChange={evt =>
                                                setTypeFilter(
                                                    evt.target.value as EligibilityResultFlow,
                                                )
                                            }
                                        >
                                            <MenuItem value="">All</MenuItem>
                                            <MenuItem value={EligibilityResultFlow.CignaDoula}>
                                                Cigna Doula
                                            </MenuItem>
                                            <MenuItem value={EligibilityResultFlow.CignaLactation}>
                                                Cigna Lactation
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item>
                                    <Grid container columnSpacing={1}>
                                        <FormControlLabel
                                            control={
                                                <Switch
                                                    checked={showFailureResults}
                                                    onChange={(_, checked) =>
                                                        setShowFailureResults(checked)
                                                    }
                                                    inputProps={{ 'aria-label': 'controlled' }}
                                                />
                                            }
                                            label="Only Show Failures"
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                        ),
                    }}
                />
            </Grid>
            {showViewModal && (
                <Dialog open={showViewModal} scroll="paper" fullWidth maxWidth="lg">
                    <ViewSelectedResult resultId={selectedId} setOpen={setShowViewModal} />
                </Dialog>
            )}
        </div>
    );
};

export default VCFEligibility;
