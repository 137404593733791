import React from 'react';
import { Divider, Grid } from '@mui/material';
import SectionHeader from './SectionHeader';

type SectionProps = {
    show?: boolean;
    title: string;
    children: React.ReactNode;
};

const Section: React.FC<SectionProps> = props => {
    const { show = true, title, children } = props;
    if (!show) {
        return null;
    }
    return (
        <Grid item xs={12}>
            <Grid container spacing={1}>
                <SectionHeader title={title} />
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    justifyContent="center"
                    alignItems="center"
                    style={{ minHeight: 200 }}
                >
                    {children}
                </Grid>
            </Grid>
        </Grid>
    );
};

export default Section;
