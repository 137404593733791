import React from 'react';
import { GetTriggerInterventionStartEpisodesForPatientTranscriptQuery } from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type TriggerInterventionStartEpisode = NonNullable<
    NonNullable<
        GetTriggerInterventionStartEpisodesForPatientTranscriptQuery['getTriggerInterventionStartEpisodesForPatientTranscript']
    >['items']
>[number];

export const TriggerInterventionStartEpisodeCard = ({
    intervention,
}: {
    intervention: TriggerInterventionStartEpisode;
}) => {
    return (
        <BaseCard title="Trigger Intervention Start Episode" data={intervention}>
            <p className="m-0">
                {`Condition: ${intervention?.conditionType?.name?.en || 'Unknown'}`}
            </p>
            <p className="m-0">
                {`Intervention Type: ${intervention?.event?.data?.intervention?.type || 'Unknown'}`}
            </p>
        </BaseCard>
    );
};
