import React from 'react';
import PatientClaims from '~/views/AffiliateCare/Claims/PatientClaims/PatientClaims';
import { useVcUserView } from '~/hooks';

const Claims = () => {
    const { getUrlWithViewPrefix } = useVcUserView();
    return (
        <PatientClaims
            invoicePathName={getUrlWithViewPrefix('/portal/invoices')}
            patientPathName={getUrlWithViewPrefix('/portal/patients')}
        />
    );
};

export default Claims;
