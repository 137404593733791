import { Button, Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { Close } from '@mui/icons-material';
import { JsonViewer } from '@textea/json-viewer';
import React, { useState } from 'react';

export const JsonDialog = ({ data }: { data: any }) => {
    const [open, setOpen] = useState(false);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Button onClick={handleOpen} variant="text" size="small">
                View Json Data
            </Button>
            <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth scroll="paper">
                <DialogTitle sx={{ m: 0, p: 2 }}>
                    JSON Data
                    <IconButton
                        aria-label="close"
                        onClick={handleClose}
                        sx={{
                            position: 'absolute',
                            right: 8,
                            top: 8,
                        }}
                    >
                        <Close />
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <JsonViewer value={JSON.parse(JSON.stringify(data))} />
                </DialogContent>
            </Dialog>
        </div>
    );
};
