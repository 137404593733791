import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import copy from 'copy-to-clipboard';
import moment from 'moment';
import { Grid, IconButton, Tooltip, Typography, Link } from '@mui/material';
import MaterialTable from '@material-table/core';
import { InfoOutlined } from '@mui/icons-material';
import { AffiliateCareSubmittedClaimsV2Query, AlertSeverity } from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import { useUserPermissions, useVcUserView } from '~/hooks';
import ServiceType from '~/views/AffiliateCare/components/ServiceType/ServiceType';

type ClaimsProps = {
    claims?: AffiliateCareSubmittedClaimsV2Query;
    portalView?: boolean;
    sectionTitle: React.ReactNode;
    isVcUser: boolean;
};

const Claims: React.FC<ClaimsProps> = ({ claims, isVcUser, sectionTitle, portalView = false }) => {
    const { pagePermissions } = useUserPermissions();
    const { isDoulaView } = useVcUserView();
    return (
        <Grid item xs={12}>
            <MaterialTable<AffiliateCareSubmittedClaimsV2Query['submittedClaimsV2']['results'][0]>
                title={sectionTitle}
                actions={[
                    {
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onClick: () => {},
                        icon: () => (
                            <Tooltip
                                title={
                                    <Grid padding={1}>
                                        <Typography paragraph variant="body2">
                                            Why does this number differ from Your Patient Claims?
                                        </Typography>
                                        <Typography paragraph variant="body2">
                                            Total Completed Visits is calculated for the patient
                                            across all affiliates in our system
                                        </Typography>
                                    </Grid>
                                }
                                placement="right"
                            >
                                <InfoOutlined />
                            </Tooltip>
                        ),
                        isFreeAction: true,
                        hidden: isVcUser,
                    },
                ]}
                columns={[
                    {
                        title: 'Claim ID',
                        field: 'id',
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Claim Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                        sorting: false,
                    },
                    {
                        title: 'InvoiceId',
                        field: 'affiliateInvoiceId',
                        render: ({ affiliateInvoiceId, affiliateId }) =>
                            affiliateInvoiceId && (
                                <Link
                                    to={
                                        portalView
                                            ? `/app-config/vcaffiliates/invoices/${affiliateId}/${affiliateInvoiceId}`
                                            : `/affiliate-care-portal/invoices/${affiliateInvoiceId}`
                                    }
                                    component={RouterLink}
                                >
                                    {affiliateInvoiceId}
                                </Link>
                            ),
                        sorting: false,
                        hidden: !pagePermissions?.AffiliateCareInvoices.Read && !portalView,
                    },
                    {
                        title: 'Date Submitted',
                        field: 'createdAt',
                        render: ({ createdAt }) => (
                            <Typography data-test={createdAt}>
                                {moment(createdAt).local().format('MM-DD-YYYY hh:mm A')}
                            </Typography>
                        ),
                        sorting: false,
                    },
                    {
                        title: 'Visit Date',
                        field: 'ServiceFrom_1',
                        render: ({ ServiceFrom_1: visitDate }) =>
                            visitDate && (
                                <Typography data-test={visitDate}>
                                    {moment(visitDate, 'M/D/YYYY').format('MM-DD-YYYY')}
                                </Typography>
                            ),
                        sorting: false,
                    },
                    {
                        title: 'Consult Type',
                        field: 'consultType',
                        render: ({ consultType }) => <Typography>{consultType}</Typography>,
                        sorting: false,
                        searchable: false,
                        hidden: !isDoulaView,
                    },
                    {
                        title: 'Duration',
                        field: 'duration',
                        render: ({ duration }) => <Typography>{duration}</Typography>,
                        sorting: false,
                        searchable: false,
                        hidden: !isDoulaView,
                    },
                    {
                        title: 'Service Type',
                        field: 'service_type',
                        render: ({ service_type: serviceType }) => (
                            <ServiceType type={serviceType} />
                        ),
                        sorting: false,
                        searchable: false,
                    },
                ]}
                data={
                    claims?.submittedClaimsV2.results.map((o: any) => ({
                        ...o,
                    })) || []
                }
                options={{
                    search: false,
                    paging: false,
                    toolbarButtonAlignment: 'left',
                }}
            />
        </Grid>
    );
};

export default Claims;
