import {
    AlertSeverity,
    EligibilityFileDataType,
    EligibilityFileProcessingType,
    EligibilityType,
    EligibilityConfigsV2ForEligibilityConfigsPageDocument,
    EligibilityConfigsV2ForEligibilityConfigsPageQuery,
    useUpdateEligibilityConfigForEligibilityConfigViewMutation,
    FetchEligibilityConfigForEligibilityConfigViewQuery,
} from '~/schemaTypes';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Controller, useFieldArray, useForm } from 'react-hook-form';
import {
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import { makeStyles } from 'tss-react/mui';
import { TriggerGlobalAlert } from '~/state';
import { useAtom } from 'jotai/index';
import { EligibilityConfigsListQueryVariablesState } from '~/views/Dashboard/EligibilityConfigs/state';
import Loading from '~/components/Loading/Loading';
import { yupResolver } from '@hookform/resolvers/yup';
import AppBundleIdSelect from '~/views/Dashboard/EligibilityConfigs/EligibilityConfig/EligibilityConfigForm/AppBundleIdSelect/AppBundleIdSelect';
import { useUserPermissions } from '~/hooks';
import TargetProfileDefSelect from './TargetProfileDefSelect/TargetProfileDefSelect';
import AutocompleteEnum from './AutocompleteEnum/AutocompleteEnum';
import OrganizationSelect from './OrganizationSelect/OrganizationSelect';
import EligibilityPassword from './EligibilityPassword/EligibilityPassword';
import TransformFunctions from './TransformFunctions/TransformFunctions';
import EligibilityCard from './EligibilityCard/EligibilityCard';
import { EligibilityConfigFromSchema } from './yupSchema';

const useStyles = makeStyles()({
    checkBox: {
        marginTop: 8,
        marginLeft: 10,
    },
    link: {
        paddingLeft: 10,
    },
    select: {
        marginTop: 8,
    },
});

export type EligibilityConfigFormData = NonNullable<
    NonNullable<
        NonNullable<
            FetchEligibilityConfigForEligibilityConfigViewQuery['eligibilityConfig']
        >['data']
    >
>;

const getEmptyColumnMapConfig = (): EligibilityConfigFormData['columnMapConfig'][0] => {
    return {
        column: '',
        index: 0,
        description: '',
        legacyRequestField: '',
        legacyResponseField: '',
        isInput: false,
        isOutput: false,
        targetProfileDefId: null,
    };
};

const getEmptyFileFilter = (): NonNullable<
    EligibilityConfigFormData['sftpConfig']['fileFilters']
>[0] => {
    return {
        column: '',
        index: 0,
        negate: false,
        transformFunctions: [],
    };
};

const EligibilityConfigForm = ({
    id,
    data,
}: {
    id: string;
    data: NonNullable<EligibilityConfigFormData>;
}) => {
    const { classes } = useStyles();
    const history = useNavigate();
    const { pagePermissions } = useUserPermissions();

    const [eligibilityConfigsListQueryVariables] = useAtom(
        EligibilityConfigsListQueryVariablesState,
    );

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: data,
        resolver: yupResolver(EligibilityConfigFromSchema),
    });

    const {
        fields: columnMapConfigs,
        append: appendColumnMapConfig,
        remove: removeColumnMapConfig,
    } = useFieldArray({
        control,
        name: 'columnMapConfig',
    });

    const {
        fields: fileFilters,
        append: appendFileFilter,
        remove: removeFileFilter,
    } = useFieldArray({
        control,
        name: 'sftpConfig.fileFilters',
    });

    const [updateEligibilityConfig, { loading: updateEligibilityConfigLoading }] =
        useUpdateEligibilityConfigForEligibilityConfigViewMutation({
            onCompleted: () => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Success,
                    message: 'Eligibility Config has been updated',
                });
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
            update: (cache, response) => {
                const updatedEligibilityConfig = response.data?.updateEligibilityConfig?.resource;
                if (updatedEligibilityConfig) {
                    const currentEligibilityConfigs =
                        cache.readQuery<EligibilityConfigsV2ForEligibilityConfigsPageQuery>({
                            query: EligibilityConfigsV2ForEligibilityConfigsPageDocument,
                            variables: eligibilityConfigsListQueryVariables,
                        });
                    if (currentEligibilityConfigs?.eligibilityConfigsV2.results) {
                        cache.writeQuery<EligibilityConfigsV2ForEligibilityConfigsPageQuery>({
                            query: EligibilityConfigsV2ForEligibilityConfigsPageDocument,
                            data: {
                                eligibilityConfigsV2: {
                                    ...currentEligibilityConfigs.eligibilityConfigsV2,
                                    results:
                                        currentEligibilityConfigs.eligibilityConfigsV2.results.map(
                                            eligibilityConfig => {
                                                if (
                                                    eligibilityConfig.id !==
                                                    updatedEligibilityConfig.id
                                                ) {
                                                    return eligibilityConfig;
                                                }
                                                const updated: EligibilityConfigsV2ForEligibilityConfigsPageQuery['eligibilityConfigsV2']['results'][0] =
                                                    {
                                                        id: updatedEligibilityConfig.id,
                                                        name: updatedEligibilityConfig.name,
                                                        appBundleId:
                                                            updatedEligibilityConfig.appBundleId,
                                                        unitId: updatedEligibilityConfig.unitId,
                                                        suspendEligibilityImport:
                                                            eligibilityConfig.suspendEligibilityImport,
                                                        suspendEligibilityCheck:
                                                            eligibilityConfig.suspendEligibilityCheck,
                                                        eligibilityCheckResults:
                                                            eligibilityConfig.eligibilityCheckResults?.map(
                                                                i => ({
                                                                    id: i?.id,
                                                                    lastName: i?.lastName,
                                                                    firstName: i?.firstName,
                                                                }),
                                                            ),
                                                        eligibilityImportResults:
                                                            eligibilityConfig.eligibilityImportResults?.map(
                                                                i => ({ id: i?.id }),
                                                            ),
                                                        createdAt: eligibilityConfig.createdAt,
                                                    };
                                                return updated;
                                            },
                                        ),
                                },
                            },
                        });
                    }
                }
            },
        });

    const handleBackClick = () => {
        history(-1);
    };

    const handleSubmitForm = async (data: EligibilityConfigFormData) => {
        await updateEligibilityConfig({
            variables: {
                input: {
                    id,
                    update: data,
                },
            },
        });
    };

    const isEdit = pagePermissions?.EligibilityConfigs.Edit;

    return (
        <>
            {updateEligibilityConfigLoading && <Loading />}
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Button component={Link} startIcon={<ArrowBack />} onClick={handleBackClick}>
                        Back to Eligibility Configs
                    </Button>
                </Grid>
                <Card>
                    <Typography variant="h6" paragraph>
                        {isEdit ? 'Edit Eligibility Config' : 'View Eligibility Config'}
                    </Typography>
                    <form onSubmit={handleSubmit(handleSubmitForm)}>
                        <Grid container>
                            <Grid item xs={4}>
                                <Controller
                                    name="name"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            type="text"
                                            label="name"
                                            fullWidth
                                            margin="dense"
                                            inputRef={ref}
                                            error={!!errors.name}
                                            helperText={errors.name?.message}
                                            disabled={!isEdit}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4} className={classes.select}>
                                <AppBundleIdSelect
                                    control={control}
                                    name="appBundleId"
                                    error={!!errors.appBundleId}
                                    helperText={errors.appBundleId?.message}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="unitId"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            type="text"
                                            label="unitId"
                                            fullWidth
                                            margin="dense"
                                            inputRef={ref}
                                            error={!!errors.unitId}
                                            helperText={errors.unitId?.message}
                                            disabled={!isEdit}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <OrganizationSelect
                                    control={control}
                                    name="organizationId"
                                    error={!!errors.organizationId}
                                    helperText={errors.organizationId?.message?.toString()}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AutocompleteEnum
                                    label="type"
                                    control={control}
                                    name="type"
                                    options={Object.entries(EligibilityType).map(
                                        ([key, value]) => ({
                                            key,
                                            value,
                                        }),
                                    )}
                                    error={!!errors.type}
                                    helperText={errors.type?.message}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AutocompleteEnum
                                    label="fileType"
                                    control={control}
                                    name="fileType"
                                    options={Object.entries(EligibilityFileDataType).map(
                                        ([key, value]) => ({
                                            key,
                                            value,
                                        }),
                                    )}
                                    error={!!errors.fileType}
                                    helperText={errors.fileType?.message}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <AutocompleteEnum
                                    label="processingMethod"
                                    control={control}
                                    name="processingMethod"
                                    options={Object.entries(EligibilityFileProcessingType).map(
                                        ([key, value]) => ({
                                            key,
                                            value,
                                        }),
                                    )}
                                    error={!!errors.processingMethod}
                                    helperText={errors.processingMethod?.message}
                                    disabled={!isEdit}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="containHeader"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            label="containHeader"
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={Boolean(field.value)}
                                                    className={classes.checkBox}
                                                    disabled={!isEdit}
                                                />
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="containFooter"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            label="containFooter"
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={Boolean(field.value)}
                                                    className={classes.checkBox}
                                                    disabled={!isEdit}
                                                />
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="delimiter"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            type="text"
                                            label="delimiter"
                                            fullWidth
                                            margin="dense"
                                            inputRef={ref}
                                            error={!!errors.delimiter}
                                            helperText={errors.delimiter?.message}
                                            disabled={!isEdit}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="suspendEligibilityImport"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            label="suspendEligibilityImport"
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={Boolean(field.value)}
                                                    className={classes.checkBox}
                                                    disabled={!isEdit}
                                                />
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="suspendEligibilityCheck"
                                    control={control}
                                    render={({ field }) => (
                                        <FormControlLabel
                                            label="suspendEligibilityCheck"
                                            control={
                                                <Checkbox
                                                    {...field}
                                                    checked={Boolean(field.value)}
                                                    className={classes.checkBox}
                                                    disabled={!isEdit}
                                                />
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={4}>
                                <Controller
                                    name="configVersion"
                                    control={control}
                                    render={({ field: { ref, ...field } }) => (
                                        <TextField
                                            {...field}
                                            variant="outlined"
                                            type="text"
                                            label="configVersion"
                                            fullWidth
                                            margin="dense"
                                            onChange={e => {
                                                field.onChange(e.target.value || null);
                                            }}
                                            inputRef={ref}
                                            error={!!errors.configVersion}
                                            helperText={errors.configVersion?.message?.toString()}
                                            disabled={!isEdit}
                                        />
                                    )}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <EligibilityCard title="sftpConfig" disabled={!isEdit}>
                                    <Grid container>
                                        <Grid item xs={4}>
                                            <Controller
                                                name="sftpConfig.host"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="outlined"
                                                        type="text"
                                                        label="host"
                                                        fullWidth
                                                        margin="dense"
                                                        inputRef={ref}
                                                        error={!!errors.sftpConfig?.host}
                                                        helperText={
                                                            errors.sftpConfig?.host?.message
                                                        }
                                                        disabled={!isEdit}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Controller
                                                name="sftpConfig.port"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="outlined"
                                                        type="number"
                                                        label="port"
                                                        fullWidth
                                                        margin="dense"
                                                        inputRef={ref}
                                                        error={!!errors.sftpConfig?.port}
                                                        helperText={
                                                            errors.sftpConfig?.port?.message
                                                        }
                                                        disabled={!isEdit}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Controller
                                                name="sftpConfig.username"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="outlined"
                                                        type="text"
                                                        label="username"
                                                        fullWidth
                                                        margin="dense"
                                                        inputRef={ref}
                                                        error={!!errors.sftpConfig?.username}
                                                        helperText={
                                                            errors.sftpConfig?.username?.message
                                                        }
                                                        disabled={!isEdit}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <EligibilityPassword
                                                control={control}
                                                name="sftpConfig.password"
                                                error={!!errors.sftpConfig?.password}
                                                helperText={errors.sftpConfig?.password?.message?.toString()}
                                                disabled={!isEdit}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Controller
                                                name="sftpConfig.directory"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="outlined"
                                                        type="text"
                                                        label="directory"
                                                        fullWidth
                                                        margin="dense"
                                                        inputRef={ref}
                                                        error={!!errors.sftpConfig?.directory}
                                                        helperText={
                                                            errors.sftpConfig?.directory?.message
                                                        }
                                                        disabled={!isEdit}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid item xs={4}>
                                            <Controller
                                                name="sftpConfig.fileMask"
                                                control={control}
                                                render={({ field: { ref, ...field } }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="outlined"
                                                        type="text"
                                                        label="fileMask"
                                                        fullWidth
                                                        margin="dense"
                                                        inputRef={ref}
                                                        error={!!errors.sftpConfig?.fileMask}
                                                        helperText={
                                                            errors.sftpConfig?.fileMask?.message
                                                        }
                                                        disabled={!isEdit}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                        <Grid container>
                                            {fileFilters.map((fileFilter, filterIndex) => (
                                                <Grid item xs={12} key={fileFilter.id}>
                                                    <EligibilityCard
                                                        title="fileFilter"
                                                        onDelete={() =>
                                                            removeFileFilter(filterIndex)
                                                        }
                                                        disabled={!isEdit}
                                                    >
                                                        <Grid container>
                                                            <Grid item xs={4}>
                                                                <Controller
                                                                    name={`sftpConfig.fileFilters.${filterIndex}.column`}
                                                                    control={control}
                                                                    render={({
                                                                        field: { ref, ...field },
                                                                    }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            variant="outlined"
                                                                            type="text"
                                                                            label="column"
                                                                            fullWidth
                                                                            margin="dense"
                                                                            inputRef={ref}
                                                                            error={
                                                                                !!errors.sftpConfig
                                                                                    ?.fileFilters?.[
                                                                                    filterIndex
                                                                                ]?.column
                                                                            }
                                                                            helperText={
                                                                                !!errors.sftpConfig
                                                                                    ?.fileFilters?.[
                                                                                    filterIndex
                                                                                ]?.column?.message
                                                                            }
                                                                            disabled={!isEdit}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Controller
                                                                    name={`sftpConfig.fileFilters.${filterIndex}.index`}
                                                                    control={control}
                                                                    render={({
                                                                        field: { ref, ...field },
                                                                    }) => (
                                                                        <TextField
                                                                            {...field}
                                                                            variant="outlined"
                                                                            type="number"
                                                                            label="index"
                                                                            fullWidth
                                                                            margin="dense"
                                                                            inputRef={ref}
                                                                            onChange={e => {
                                                                                const value = e
                                                                                    .target.value
                                                                                    ? +e.target
                                                                                          .value
                                                                                    : null;
                                                                                field.onChange(
                                                                                    value,
                                                                                );
                                                                            }}
                                                                            error={
                                                                                !!errors.sftpConfig
                                                                                    ?.fileFilters?.[
                                                                                    filterIndex
                                                                                ]?.index
                                                                            }
                                                                            helperText={
                                                                                !!errors.sftpConfig
                                                                                    ?.fileFilters?.[
                                                                                    filterIndex
                                                                                ]?.index?.message
                                                                            }
                                                                            disabled={!isEdit}
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                            <Grid item xs={4}>
                                                                <Controller
                                                                    name={`sftpConfig.fileFilters.${filterIndex}.negate`}
                                                                    control={control}
                                                                    render={({ field }) => (
                                                                        <FormControlLabel
                                                                            label="negate"
                                                                            control={
                                                                                <Checkbox
                                                                                    {...field}
                                                                                    checked={Boolean(
                                                                                        field.value,
                                                                                    )}
                                                                                    className={
                                                                                        classes.checkBox
                                                                                    }
                                                                                    disabled={
                                                                                        !isEdit
                                                                                    }
                                                                                />
                                                                            }
                                                                        />
                                                                    )}
                                                                />
                                                            </Grid>
                                                        </Grid>
                                                        <TransformFunctions
                                                            control={control}
                                                            name={`sftpConfig.fileFilters.${filterIndex}.transformFunctions`}
                                                            errors={errors}
                                                            disabled={!isEdit}
                                                        />
                                                    </EligibilityCard>
                                                </Grid>
                                            ))}
                                        </Grid>
                                        <Button
                                            color="secondary"
                                            variant="contained"
                                            onClick={() => appendFileFilter(getEmptyFileFilter())}
                                            disabled={!isEdit}
                                        >
                                            add fileFilter
                                        </Button>
                                    </Grid>
                                </EligibilityCard>
                            </Grid>

                            <Grid item xs={12}>
                                {columnMapConfigs.map((columnMapConfig, columnMapConfigIndex) => (
                                    <EligibilityCard
                                        key={columnMapConfig.id}
                                        title="columnMapConfig"
                                        onDelete={() => removeColumnMapConfig(columnMapConfigIndex)}
                                        disabled={!isEdit}
                                    >
                                        <Grid container>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name={`columnMapConfig.${columnMapConfigIndex}.column`}
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <TextField
                                                            {...field}
                                                            variant="outlined"
                                                            type="text"
                                                            label="column"
                                                            fullWidth
                                                            margin="dense"
                                                            inputRef={ref}
                                                            error={
                                                                !!errors?.columnMapConfig?.[
                                                                    columnMapConfigIndex
                                                                ]?.column
                                                            }
                                                            helperText={
                                                                !!errors?.columnMapConfig?.[
                                                                    columnMapConfigIndex
                                                                ]?.column?.message
                                                            }
                                                            disabled={!isEdit}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name={`columnMapConfig.${columnMapConfigIndex}.index`}
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <TextField
                                                            {...field}
                                                            variant="outlined"
                                                            type="number"
                                                            label="index"
                                                            fullWidth
                                                            margin="dense"
                                                            inputRef={ref}
                                                            onChange={e => {
                                                                const value = e.target.value
                                                                    ? +e.target.value
                                                                    : null;
                                                                field.onChange(value);
                                                            }}
                                                            error={
                                                                !!errors?.columnMapConfig?.[
                                                                    columnMapConfigIndex
                                                                ]?.index
                                                            }
                                                            helperText={
                                                                !!errors?.columnMapConfig?.[
                                                                    columnMapConfigIndex
                                                                ]?.index?.message
                                                            }
                                                            disabled={!isEdit}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name={`columnMapConfig.${columnMapConfigIndex}.legacyRequestField`}
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <TextField
                                                            {...field}
                                                            variant="outlined"
                                                            type="text"
                                                            label="legacyRequestField"
                                                            fullWidth
                                                            margin="dense"
                                                            inputRef={ref}
                                                            error={
                                                                !!errors?.columnMapConfig?.[
                                                                    columnMapConfigIndex
                                                                ]?.legacyRequestField
                                                            }
                                                            helperText={
                                                                !!errors?.columnMapConfig?.[
                                                                    columnMapConfigIndex
                                                                ]?.legacyRequestField?.message
                                                            }
                                                            disabled={!isEdit}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name={`columnMapConfig.${columnMapConfigIndex}.legacyResponseField`}
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <TextField
                                                            {...field}
                                                            variant="outlined"
                                                            type="text"
                                                            label="legacyResponseField"
                                                            fullWidth
                                                            margin="dense"
                                                            inputRef={ref}
                                                            error={
                                                                !!errors?.columnMapConfig?.[
                                                                    columnMapConfigIndex
                                                                ]?.legacyResponseField
                                                            }
                                                            helperText={
                                                                !!errors?.columnMapConfig?.[
                                                                    columnMapConfigIndex
                                                                ]?.legacyResponseField?.message
                                                            }
                                                            disabled={!isEdit}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name={`columnMapConfig.${columnMapConfigIndex}.isInput`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            label="isInput"
                                                            control={
                                                                <Checkbox
                                                                    {...field}
                                                                    checked={Boolean(field.value)}
                                                                    className={classes.checkBox}
                                                                    disabled={!isEdit}
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name={`columnMapConfig.${columnMapConfigIndex}.isOutput`}
                                                    control={control}
                                                    render={({ field }) => (
                                                        <FormControlLabel
                                                            label="isOutput"
                                                            control={
                                                                <Checkbox
                                                                    {...field}
                                                                    checked={Boolean(field.value)}
                                                                    className={classes.checkBox}
                                                                    disabled={!isEdit}
                                                                />
                                                            }
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={4}>
                                                <Controller
                                                    name={`columnMapConfig.${columnMapConfigIndex}.description`}
                                                    control={control}
                                                    render={({ field: { ref, ...field } }) => (
                                                        <TextField
                                                            {...field}
                                                            variant="outlined"
                                                            type="text"
                                                            label="description"
                                                            fullWidth
                                                            margin="dense"
                                                            inputRef={ref}
                                                            error={
                                                                !!errors?.columnMapConfig?.[
                                                                    columnMapConfigIndex
                                                                ]?.description
                                                            }
                                                            helperText={
                                                                errors?.columnMapConfig?.[
                                                                    columnMapConfigIndex
                                                                ]?.description?.message
                                                            }
                                                            disabled={!isEdit}
                                                        />
                                                    )}
                                                />
                                            </Grid>
                                            <Grid item xs={8}>
                                                <TargetProfileDefSelect
                                                    control={control}
                                                    name={`columnMapConfig.${columnMapConfigIndex}.targetProfileDefId`}
                                                    value={watch(
                                                        `columnMapConfig.${columnMapConfigIndex}.targetProfileDefId`,
                                                    )}
                                                    error={
                                                        !!errors?.columnMapConfig?.[
                                                            columnMapConfigIndex
                                                        ]?.targetProfileDefId
                                                    }
                                                    helperText={errors?.columnMapConfig?.[
                                                        columnMapConfigIndex
                                                    ]?.targetProfileDefId?.message?.toString()}
                                                    disabled={!isEdit}
                                                />
                                            </Grid>
                                            <TransformFunctions
                                                control={control}
                                                name={`columnMapConfig.${columnMapConfigIndex}.transformFunctions`}
                                                errors={errors}
                                                disabled={!isEdit}
                                            />
                                        </Grid>
                                    </EligibilityCard>
                                ))}
                            </Grid>
                            <Grid item>
                                <Button
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => appendColumnMapConfig(getEmptyColumnMapConfig())}
                                    disabled={!isEdit}
                                >
                                    add columnMapConfig
                                </Button>
                            </Grid>
                        </Grid>
                        <Grid
                            container
                            item
                            xs={12}
                            justifyContent="flex-end"
                            spacing={2}
                            direction="row"
                        >
                            <Grid item>
                                <Button
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    disabled={updateEligibilityConfigLoading || !isEdit}
                                >
                                    Save
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    onClick={handleBackClick}
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    disabled={updateEligibilityConfigLoading || !isEdit}
                                >
                                    Save &amp; Close
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </Card>
            </Grid>
        </>
    );
};

export default EligibilityConfigForm;
