import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    Tooltip,
} from '@mui/material';
import {
    Add as AddIcon,
    Delete as DeleteIcon,
    Done as DoneIcon,
    Save as SaveIcon,
} from '@mui/icons-material';
import DatePicker from '@mui/lab/DatePicker';
import _ from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from 'react-hook-form';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import {
    FetchPatientActionTypesForChecklistModalPageQuery,
    useCreatePatientActionTypeForChecklistModalMutation,
    useFetchPatientActionTypesForChecklistModalPageQuery,
} from '~/schemaTypes';
import RichTextEditor from '~/components/RichTextEditor/RichTextEditor';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import Loading from '~/components/Loading/Loading';
import { getInitialModalData, INITIAL_ACTION_TYPE, toSubmitInput } from './helpers';
import { useStyles } from './styles';
import { ChecklistItemForm, ChecklistItemModalPropsType } from './types';
import { CHECKLIST_ITEM_SCHEMA, CREATE_NEW_TYPE } from './yupSchema';

export type PatientActionType = NonNullable<
    NonNullable<
        FetchPatientActionTypesForChecklistModalPageQuery['patientActionTypesV2']
    >['results'][0]
>;

interface ChecklistItemModalForm {
    name: string;
    shouldCreateAction: boolean;
    description?: string | null | undefined;
    actionTypes: {
        typeId: string;
        newActionTypeLabel?: string;
        isChecked?: boolean;
    }[];
    completedAt?: string | null;
    endSlaTimerOnComplete?: boolean | null;
}

export type ChecklistItemModalBodyProps = ChecklistItemModalPropsType & {
    patientActionTypes: PatientActionType[];
};

const ChecklistItemModalBody = (props: ChecklistItemModalBodyProps) => {
    const {
        isEditMode,
        data,
        closeModal,
        onSubmit,
        onDelete,
        checked,
        isCompleteMode,
        patientActions,
        completedAt,
        completedBy,
        isTemplate = false,
        patientActionTypes,
    } = props;

    const [newActionTypes, setNewActionType] = useState<PatientActionType[]>([]);
    const [selectedActionTypes, setSelectedActionTypes] = useState<string[]>([
        ...(data?.actionTypeIds || []),
    ]);
    const [actionTypeIndexesInUse, setActionTypeIndexesInUse] = useState<string[]>([]);
    const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState<boolean>(false);
    const { classes } = useStyles();
    const handleCancel = () => closeModal();

    const completeModeActionTypes = isCompleteMode
        ? patientActionTypes.filter(actionType => {
              if (checked) {
                  return patientActions?.some(
                      action => action.typeId === actionType.id && action.stepId === data?.id,
                  );
              }

              return data?.actionTypeIds.includes(actionType.id);
          }) ?? []
        : [];

    const {
        control,
        register,
        watch,
        handleSubmit: handleFormSubmit,
        setValue,

        formState: { errors },
    } = useForm<ChecklistItemModalForm>({
        resolver: yupResolver(CHECKLIST_ITEM_SCHEMA(isCompleteMode || false) as any),
        defaultValues: getInitialModalData({ ...props, completeModeActionTypes }),
    });

    const {
        fields: actionTypes,
        append: appendActionType,
        remove: removeActionType,
    } = useFieldArray({
        control,
        name: 'actionTypes',
    });

    const shouldCreateAction = watch('shouldCreateAction');
    const watchedActionTypes = watch('actionTypes');

    if (watchedActionTypes.length === 0) {
        appendActionType({ ...INITIAL_ACTION_TYPE });
    }

    const [createPatientActionType] = useCreatePatientActionTypeForChecklistModalMutation({
        awaitRefetchQueries: true,
        refetchQueries: ['FetchPatientActionTypesForChecklistModalPage'],
    });

    const saveActionType = async (
        newActionType: string,
    ): Promise<PatientActionType | null | undefined> => {
        const newPatientActionTypeData = await createPatientActionType({
            variables: {
                input: { label: newActionType },
            },
        });

        return newPatientActionTypeData.data?.createPatientActionType?.resourceCreated;
    };

    const saveActionTypeId = async (newActionType: string): Promise<string> => {
        const newPatientActionType = await saveActionType(newActionType);

        if (newPatientActionType) {
            setNewActionType(state => {
                state.push(newPatientActionType);
                return state;
            });
        }

        return Promise.resolve(newPatientActionType?.id ?? '');
    };

    const handleActionTypeClick = (id: string, index: number) => {
        setSelectedActionTypes(state => {
            const nextState = [...state];
            nextState[index] = id;
            return nextState;
        });
    };

    const handleDeleteActionType = (index: number) => {
        removeActionType(index);
        setSelectedActionTypes(state => {
            return state.filter(id => id !== state[index]);
        });
    };

    const handleSubmit: SubmitHandler<ChecklistItemForm> = async form => {
        const checkedActionTypes = form.actionTypes.filter(item => item.isChecked);
        setIsSubmitButtonDisabled(false);
        setActionTypeIndexesInUse([]);
        let patientActionTypeIds: string[] = [];
        const actionTypeIdsInUse = form.actionTypes.reduce((acc: string[], curr, index) => {
            const actionTypeInUse = patientActionTypes.find(
                type => type.label.toLowerCase() === curr.newActionTypeLabel?.toLowerCase(),
            );

            if (actionTypeInUse) {
                acc.push(index.toString());
                return acc;
            }

            return acc;
        }, []);
        if (actionTypeIdsInUse.length) {
            setActionTypeIndexesInUse(actionTypeIdsInUse);
            return;
        }
        setIsSubmitButtonDisabled(true);
        if (form.shouldCreateAction) {
            patientActionTypeIds = await Promise.all(
                form.actionTypes.map(actionType =>
                    actionType.typeId === CREATE_NEW_TYPE && actionType.newActionTypeLabel
                        ? saveActionTypeId(actionType.newActionTypeLabel)
                        : Promise.resolve(actionType.typeId),
                ),
            );
        } else if (isCompleteMode && form.actionTypes) {
            patientActionTypeIds = form.actionTypes.map(actionType => actionType.typeId);
        }
        onSubmit(
            toSubmitInput(
                {
                    ...data,
                    ...form,
                    ...(checked
                        ? { completedAt, completedBy }
                        : { completeAt: new Date().toLocaleString() }),
                    ...(isCompleteMode && { name: data?.label, description: data?.description }),
                },
                patientActionTypeIds,
            ),
            newActionTypes,
            checkedActionTypes,
        );
    };

    return (
        <div>
            <form className={classes.root} onSubmit={handleFormSubmit(handleSubmit)}>
                <DialogTitleWithClose id="form-dialog-title" onClose={handleCancel}>
                    {isCompleteMode && <span>Complete Step</span>}
                    {!isCompleteMode && <span>{isEditMode ? 'Edit' : 'Add'} Step</span>}
                </DialogTitleWithClose>
                <DialogContent className="formCont">
                    <Grid item xs={12} style={{ marginTop: '8px' }}>
                        <RichTextEditor
                            onChange={value => setValue('name', value)}
                            placeholder="Name"
                            disabled={isCompleteMode}
                            initialValue={data?.label || ''}
                            label="Name"
                        />
                    </Grid>
                    {!isCompleteMode && (
                        <Controller
                            name="shouldCreateAction"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormControlLabel
                                    label="Create Action On Completion"
                                    control={
                                        <Checkbox
                                            checked={value}
                                            onChange={e => onChange(e.target.checked)}
                                        />
                                    }
                                />
                            )}
                        />
                    )}
                    {isTemplate ? (
                        <Controller
                            name="endSlaTimerOnComplete"
                            control={control}
                            render={({ field: { value, onChange } }) => (
                                <FormControlLabel
                                    label="End SLA Timer On Complete"
                                    control={
                                        <Checkbox
                                            checked={value || false}
                                            onChange={e => onChange(e.target.checked)}
                                        />
                                    }
                                />
                            )}
                        />
                    ) : (
                        data?.endSlaTimerOnComplete && (
                            <Controller
                                name="endSlaTimerOnComplete"
                                control={control}
                                render={({ field: { value } }) => (
                                    <FormControlLabel
                                        label="End SLA Timer On Complete"
                                        control={<Checkbox checked={value || false} />}
                                    />
                                )}
                            />
                        )
                    )}
                    {shouldCreateAction && (
                        <Grid>
                            {actionTypes.map(({ typeId, id }, index) => (
                                <Grid key={id}>
                                    <Grid container wrap="nowrap" alignItems="center">
                                        <ReactHookFormSelect
                                            control={control}
                                            defaultValue={typeId}
                                            name={`actionTypes.${index}.typeId`}
                                            variant="outlined"
                                            label="Action Type"
                                            fullWidth
                                            margin="dense"
                                        >
                                            <MenuItem
                                                key={CREATE_NEW_TYPE}
                                                value={CREATE_NEW_TYPE}
                                                className={classes.actionType}
                                            >
                                                {CREATE_NEW_TYPE}
                                            </MenuItem>
                                            {_.sortBy(patientActionTypes, 'label').map(
                                                ({ id, label }) => (
                                                    <MenuItem
                                                        key={id}
                                                        value={id}
                                                        disabled={selectedActionTypes.includes(id)}
                                                        onClick={() =>
                                                            handleActionTypeClick(id, index)
                                                        }
                                                    >
                                                        {label}
                                                    </MenuItem>
                                                ),
                                            )}
                                        </ReactHookFormSelect>
                                        {actionTypes.length !== 1 && (
                                            <DeleteIcon
                                                className={classes.actionTypeBtn}
                                                onClick={() => handleDeleteActionType(index)}
                                            />
                                        )}
                                        {index === actionTypes.length - 1 && (
                                            <AddIcon
                                                onClick={() =>
                                                    appendActionType({ ...INITIAL_ACTION_TYPE })
                                                }
                                                className={classes.actionTypeBtn}
                                            />
                                        )}
                                    </Grid>
                                    {errors.actionTypes &&
                                        errors.actionTypes[index]?.typeId?.message && (
                                            <FormHelperText error className={classes.helperMessage}>
                                                {errors.actionTypes[index]?.typeId?.message}
                                            </FormHelperText>
                                        )}
                                    {watchedActionTypes[index] &&
                                        watchedActionTypes[index].typeId === CREATE_NEW_TYPE && (
                                            <>
                                                <TextField
                                                    variant="outlined"
                                                    label="New Action Type Name *"
                                                    fullWidth
                                                    margin="dense"
                                                    {...register(
                                                        `actionTypes.${index}.newActionTypeLabel`,
                                                    )}
                                                    error={Boolean(
                                                        errors.actionTypes &&
                                                            errors.actionTypes[index]
                                                                ?.newActionTypeLabel?.message,
                                                    )}
                                                    helperText={
                                                        errors.actionTypes &&
                                                        errors.actionTypes[index]
                                                            ?.newActionTypeLabel?.message
                                                    }
                                                />
                                                {actionTypeIndexesInUse[index] && (
                                                    <Grid className={classes.validationError}>
                                                        Action Type Name already used. Please
                                                        change.
                                                    </Grid>
                                                )}
                                            </>
                                        )}
                                </Grid>
                            ))}
                        </Grid>
                    )}
                    {isCompleteMode && completeModeActionTypes.length > 0 && (
                        <OutlinedSection
                            title="Select Action Types"
                            className={classes.actionTypesOutlinedSection}
                        >
                            <Grid>
                                {completeModeActionTypes.map(({ label, id }) => (
                                    <Controller
                                        name={`actionTypes.${actionTypes.findIndex(
                                            actionType => actionType.typeId === id,
                                        )}.isChecked`}
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControlLabel
                                                label={label}
                                                control={
                                                    <Checkbox
                                                        checked={value ?? false}
                                                        onChange={e => onChange(e.target.checked)}
                                                        disabled={checked}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                ))}
                                {errors.actionTypes && errors.actionTypes[0]?.typeId?.message && (
                                    <FormHelperText error className={classes.helperMessage}>
                                        {errors.actionTypes[0]?.typeId?.message}
                                    </FormHelperText>
                                )}
                            </Grid>
                        </OutlinedSection>
                    )}
                    {isCompleteMode && (
                        <Grid>
                            <Controller
                                control={control}
                                name="completedAt"
                                render={({ field: { onChange, value } }) => (
                                    <Tooltip
                                        title={`Time is in ${moment()
                                            .tz(Intl.DateTimeFormat().resolvedOptions().timeZone)
                                            .zoneAbbr()} time zone`}
                                    >
                                        <DatePicker
                                            defaultValue={
                                                data?.completedAt
                                                    ? new Date(data?.completedAt)
                                                    : new Date()
                                            }
                                            fullWidth
                                            variant="inline"
                                            margin="dense"
                                            label="Completed Date"
                                            onChange={onChange}
                                            openTo="date"
                                            value={value}
                                            ampm={false}
                                            minutesStep={5}
                                            format="MM/dd/yyyy HH:mm a"
                                        />
                                    </Tooltip>
                                )}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} style={{ marginTop: '8px' }}>
                        <RichTextEditor
                            onChange={value => setValue('description', value)}
                            placeholder="Default Note Text (optional)"
                            disabled={isCompleteMode}
                            initialValue={data?.description || ''}
                            label="Default Note Text (optional)"
                        />
                    </Grid>
                </DialogContent>
                <DialogActions>
                    <div className={classes.dialogActions}>
                        <div>
                            {!isCompleteMode && (
                                <Button
                                    startIcon={isEditMode ? <SaveIcon /> : <AddIcon />}
                                    type="submit"
                                    color="secondary"
                                    variant="contained"
                                    disabled={isSubmitButtonDisabled}
                                >
                                    {isEditMode ? 'Save' : 'Create'}
                                </Button>
                            )}
                            {isCompleteMode && (
                                <Button
                                    startIcon={checked ? <DoneIcon /> : null}
                                    type="submit"
                                    color={checked ? 'secondary' : 'primary'}
                                    variant="contained"
                                >
                                    {checked ? 'Completed' : 'Mark as Complete'}
                                </Button>
                            )}
                            <Button onClick={handleCancel} color="secondary" variant="outlined">
                                Cancel
                            </Button>
                        </div>
                        <div>
                            {!isCompleteMode && onDelete && data?.id && (
                                <Button
                                    startIcon={<DeleteIcon />}
                                    onClick={() => onDelete(data)}
                                    color="primary"
                                    variant="contained"
                                >
                                    Delete
                                </Button>
                            )}
                        </div>
                    </div>
                </DialogActions>
            </form>
        </div>
    );
};

const CheckListItemModal = (props: ChecklistItemModalPropsType) => {
    const { data, loading } = useFetchPatientActionTypesForChecklistModalPageQuery();

    return (
        <>
            {loading && <Loading />}
            {!loading && (
                <ChecklistItemModalBody
                    {...props}
                    patientActionTypes={data?.patientActionTypesV2.results ?? []}
                />
            )}
        </>
    );
};

export default CheckListItemModal;
