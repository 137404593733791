import MaterialTable from '@material-table/core';
import { Add } from '@mui/icons-material';
import { Chip, Dialog, Typography } from '@mui/material';
import parse from 'html-react-parser';
import React, { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';
import Loading from '~/components/Loading/Loading';
import tableIcons from '~/helpers/tableIcons';
import {
    ReferencePages,
    useFetchPatientNotesForPatientNotesQuery,
    useFetchReferenceLinksForPatientNotesPageQuery,
    usePatientNoteTagListQuery,
} from '~/schemaTypes';

import { useVcUserView, useUserPermissions } from '~/hooks';
import { displayDate } from '../../../../../helpers';
import PatientNotesModal from '../../PatientNotesModal/PatientNotesModal';

const useStyles = makeStyles()({
    root: {},
});

type RouteParams = {
    id: string;
};

type PatientNotesProps = {
    setLinks?: any;
};

const PatientNotes: React.FC<PatientNotesProps> = ({ setLinks }) => {
    const { id } = useParams<RouteParams>();
    const { classes } = useStyles();
    const [showNotesModal, setShowNotesModal] = useState<boolean>(false);
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const { isVcUser } = useVcUserView();
    const { pagePermissions } = useUserPermissions();
    const { data: noteTagsData } = usePatientNoteTagListQuery();

    const noteTags = noteTagsData?.patientNoteTagsV2.results;

    const { data: patientData, loading: patientDataLoading } =
        useFetchPatientNotesForPatientNotesQuery({
            variables: { input: { id } },
        });

    const { data: refereneLinksData, loading: refereneLinksLoading } =
        useFetchReferenceLinksForPatientNotesPageQuery({
            variables: {
                input: {
                    page: ReferencePages.PatientNotes,
                },
            },
        });

    useEffect(() => {
        if (refereneLinksData && setLinks) {
            setLinks(refereneLinksData.getReferenceLinksForPage);
        }
    }, [refereneLinksData, setLinks]);

    if (patientDataLoading || refereneLinksLoading) {
        return <Loading />;
    }

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Notes"
                icons={tableIcons}
                actions={[
                    {
                        onClick: () => setShowNotesModal(true),
                        icon: () => <Add />,
                        tooltip: 'Add New Note',
                        isFreeAction: true,
                        hidden: !pagePermissions?.PatientNotes.Edit,
                    },
                ]}
                columns={[
                    { title: 'Title', field: 'title' },
                    {
                        title: 'Date',
                        render: ({ date }) => (
                            <Typography>{date && displayDate({ isoDateStr: date })}</Typography>
                        ),
                        customSort: (a, b) => {
                            const dateA = new Date(a?.date ?? '').getTime();
                            const dateB = new Date(b?.date ?? '').getTime();

                            if (dateA === dateB) {
                                const createdAtA = new Date(a?.createdAt ?? '').getTime();
                                const createdAtB = new Date(b?.createdAt ?? '').getTime();
                                return createdAtA - createdAtB;
                            }

                            return dateA - dateB;
                        },
                        defaultSort: 'desc',
                        headerStyle: {
                            textAlign: 'right',
                        },
                        cellStyle: {
                            textAlign: 'right',
                        },
                        width: 32,
                    },
                    { title: 'Staff Name', field: 'staffName' },
                    {
                        title: 'Note Source',
                        field: 'noteSource',
                        hidden: isVcUser,
                    },
                    {
                        title: 'Note Type',
                        field: 'noteType',
                        sorting: false,
                        render: ({ noteType }) => {
                            if (noteType && noteType.length > 0) {
                                const noteTagsSet = new Set(noteType);
                                return noteTags
                                    ?.filter(item => noteTagsSet.has(item.id))
                                    .map(tag => <Chip key={tag.id} label={tag.name} />);
                            }
                            return [];
                        },
                    },
                ]}
                data={patientData?.patient?.notes ?? []}
                localization={{ header: { actions: '' } }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage, grouping: true, pageSizeOptions: [25, 50, 100] }}
                detailPanel={data => {
                    const { rowData } = data;
                    return <div style={{ padding: '10px 20px' }}>{parse(rowData.text)}</div>;
                }}
            />
            <Dialog
                scroll="paper"
                open={showNotesModal}
                fullWidth
                aria-labelledby="form-dialog-title"
            >
                <PatientNotesModal
                    handleClose={() => setShowNotesModal(false)}
                    isModalOpen={showNotesModal}
                    patientId={id}
                />
            </Dialog>
        </div>
    );
};

export default PatientNotes;
