/* eslint-disable camelcase */
/* eslint-disable react/no-danger */
import { Button, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack } from '@mui/icons-material';
import React, { useEffect, useState } from 'react';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { ArticleApprovalListQuery, useArticleByIdQuery } from '~/schemaTypes';

type Approval = NonNullable<ArticleApprovalListQuery['articleApprovalList']>['results'][0];

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
}));

const ArticleReview: React.FC<{
    approval: Approval | undefined;
    closeReview: () => void;
}> = props => {
    const { approval, closeReview } = props;
    const { classes } = useStyles();
    const [content, setContent] = useState<{ type: string; content: string }[]>();
    const { data: articleData, loading: articleLoading } = useArticleByIdQuery({
        variables: { input: { id: approval?.articleId } },
    });
    useEffect(() => {
        const article = articleData?.article;
        if (article) {
            const variant = article.variants?.find(
                v =>
                    v.language === approval?.language &&
                    v.readingLevel === approval.readingLevel &&
                    (v.appBundleId === approval.appBundleId || !v.appBundleId),
            );
            if (variant) {
                const newContent: { type: string; content: string }[] = [];
                if (variant.titleMarkup) {
                    newContent.push({
                        type: 'Title',
                        content: variant.titleMarkup,
                    });
                }
                if (variant.summaryMarkup) {
                    newContent.push({
                        type: 'Summary',
                        content: variant.summaryMarkup,
                    });
                }
                if (variant.bodyMarkup) {
                    newContent.push({
                        type: 'Body',
                        content: variant.bodyMarkup,
                    });
                }
                setContent(newContent);
            }
        }
    }, [articleData, approval]);
    if (articleLoading) return <Loading />;
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button onClick={closeReview} startIcon={<ArrowBack />}>
                    Back to Article Approval
                </Button>
            </Grid>
            {approval && (
                <div>
                    <div>
                        {approval.articleName}/{approval.language}/{approval.readingLevel}/
                        {approval.appBundleId}
                    </div>
                    {content?.map(c => {
                        return (
                            <OutlinedSection title={c.type}>
                                <div
                                    style={{ display: 'inline-block' }}
                                    dangerouslySetInnerHTML={{ __html: c.content }}
                                />
                            </OutlinedSection>
                        );
                    })}
                </div>
            )}
        </Grid>
    );
};

export default ArticleReview;
