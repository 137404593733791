import _ from 'lodash';
import { Button, Grid, TextField } from '@mui/material';
import { Control, Controller, FieldErrors, useFieldArray } from 'react-hook-form';
import React from 'react';
import { EligibilityConfigFormData } from '~/views/Dashboard/EligibilityConfigs/EligibilityConfig/EligibilityConfigForm/EligibilityConfigForm';
import {
    EligibilityTransformFunctionType,
    FetchEligibilityConfigForEligibilityConfigViewQuery,
} from '~/schemaTypes';
import EligibilityCard from '~/views/Dashboard/EligibilityConfigs/EligibilityConfig/EligibilityConfigForm/EligibilityCard/EligibilityCard';
import AutocompleteEnum from '~/views/Dashboard/EligibilityConfigs/EligibilityConfig/EligibilityConfigForm/AutocompleteEnum/AutocompleteEnum';
import { makeStyles } from 'tss-react/mui';

type TransformFunctionsProps = {
    control: Control<any>;
    name: string;
    errors: FieldErrors<
        NonNullable<
            NonNullable<
                NonNullable<
                    FetchEligibilityConfigForEligibilityConfigViewQuery['eligibilityConfig']
                >['data']
            >
        >
    >;
    disabled?: boolean;
};

type TransformFunction = NonNullable<
    EligibilityConfigFormData['columnMapConfig'][0]['transformFunctions']
>[0];

const getEmptyTransformFunction = (): Omit<TransformFunction, 'type'> & {
    type: TransformFunction['type'] | null;
} => ({
    type: null,
    dateFormat: '',
    search: '',
    replacement: '',
});

const useStyles = makeStyles()({
    appendButton: {
        marginBottom: 15,
    },
});

const TransformFunctions = ({ control, name, errors, disabled }: TransformFunctionsProps) => {
    const { classes } = useStyles();

    const {
        fields: transformFunctions,
        remove: removeTransformFunction,
        append: appendTransformFunction,
    } = useFieldArray({
        control,
        name,
    });
    return (
        <Grid container>
            <Button
                className={classes.appendButton}
                color="secondary"
                variant="contained"
                onClick={() => appendTransformFunction(getEmptyTransformFunction())}
                disabled={disabled}
            >
                add transformFunction
            </Button>
            <Grid container spacing={2}>
                {transformFunctions?.map((item, transformFunctionsIndex) => (
                    <Grid item xs={3} key={item.id}>
                        <EligibilityCard
                            title="transformFunction"
                            onDelete={() => removeTransformFunction(transformFunctionsIndex)}
                            disabled={disabled}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <AutocompleteEnum
                                        label="type"
                                        control={control}
                                        name={`${name}.${transformFunctionsIndex}.type`}
                                        options={Object.entries(
                                            EligibilityTransformFunctionType,
                                        ).map(([key, value]) => ({
                                            key,
                                            value,
                                        }))}
                                        error={
                                            !!_.get(
                                                errors,
                                                `${name}.${transformFunctionsIndex}.type`,
                                            )
                                        }
                                        helperText={_.get(
                                            errors,
                                            `${name}.${transformFunctionsIndex}.type.message`,
                                        )}
                                        disabled={disabled}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name={`${name}.${transformFunctionsIndex}.dateFormat`}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                type="text"
                                                label="dateFormat"
                                                fullWidth
                                                margin="dense"
                                                error={
                                                    !!_.get(
                                                        errors,
                                                        `${name}.${transformFunctionsIndex}.dateFormat`,
                                                    )
                                                }
                                                helperText={_.get(
                                                    errors,
                                                    `${name}.${transformFunctionsIndex}.dateFormat.message`,
                                                )}
                                                disabled={disabled}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name={`${name}.${transformFunctionsIndex}.search`}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                type="text"
                                                label="search"
                                                fullWidth
                                                margin="dense"
                                                error={
                                                    !!_.get(
                                                        errors,
                                                        `${name}.${transformFunctionsIndex}.search`,
                                                    )
                                                }
                                                helperText={_.get(
                                                    errors,
                                                    `${name}.${transformFunctionsIndex}.search.message`,
                                                )}
                                                disabled={disabled}
                                            />
                                        )}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Controller
                                        name={`${name}.${transformFunctionsIndex}.replacement`}
                                        control={control}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                variant="outlined"
                                                type="text"
                                                label="replacement"
                                                fullWidth
                                                margin="dense"
                                                error={
                                                    !!_.get(
                                                        errors,
                                                        `${name}.${transformFunctionsIndex}.replacement`,
                                                    )
                                                }
                                                helperText={_.get(
                                                    errors,
                                                    `${name}.${transformFunctionsIndex}.replacement.message`,
                                                )}
                                                disabled={disabled}
                                            />
                                        )}
                                    />
                                </Grid>
                            </Grid>
                        </EligibilityCard>
                    </Grid>
                ))}
            </Grid>
        </Grid>
    );
};

export default TransformFunctions;
