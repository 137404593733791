import { useMemo } from 'react';
import { FetchCurrentUserForUseUserHookQuery } from '../schemaTypes';

type CurrentUser = FetchCurrentUserForUseUserHookQuery['currentUser'];

export const useCheckPractice = (currentUser?: CurrentUser, patientOrgId?: string) => {
    return useMemo(() => {
        if (!patientOrgId || !currentUser) {
            return true;
        }

        const {
            rootOrg: { id: rootOrgId },
            currentOrganization: { id: currentOrgId },
            currentOrganizations,
        } = currentUser;

        return (
            currentOrgId !== rootOrgId &&
            currentOrgId !== patientOrgId &&
            !currentOrganizations.find(({ id }) => id === patientOrgId)
        );
    }, [currentUser, patientOrgId]);
};
