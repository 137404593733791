import React from 'react';
import { Grid, Typography, Card } from '@mui/material';
import {
    AffiliateCarePatientQuery,
    EligibilityRefreshOrigin,
    PatientInsurancePlan,
} from '~/schemaTypes';
import RecheckEligibilityButton from '~/views/AffiliateCare/components/RecheckEligibilityButton/RecheckEligibilityButton';
import CoverageType from '~/views/AffiliateCare/Patients/components/CoverageType';
import { useVcUserView } from '~/hooks';
import { isAnthemInsurancePlan } from '~/utils/lactationServicesEligibility';

type InsuranceDetailsProps = {
    patient?: AffiliateCarePatientQuery;
    insurancePlanName: string;
    activeInsurancePlan: PatientInsurancePlan | null;
    isEligible: boolean;
};

const InsuranceDetails: React.FC<InsuranceDetailsProps> = props => {
    const { patient, insurancePlanName, isEligible, activeInsurancePlan } = props;
    const { isDoulaView } = useVcUserView();
    const hideRecheckButton = isEligible || isAnthemInsurancePlan(activeInsurancePlan);
    return (
        <Grid item xs={12}>
            <Card style={{ marginBottom: 0, padding: 0 }}>
                <Grid
                    item
                    xs={12}
                    container
                    spacing={2}
                    alignItems="flex-start"
                    sx={{ paddingX: 4, paddingBottom: 4 }}
                >
                    <Grid item xs={12}>
                        <Typography
                            gutterBottom
                            variant="body1"
                            sx={{ textDecoration: 'underline' }}
                        >
                            Basic Plan Information
                        </Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography color="primary" gutterBottom variant="body1">
                            Plan Name:
                        </Typography>
                        <Typography variant="subtitle1">{insurancePlanName}</Typography>
                    </Grid>
                    <Grid item xs={4}>
                        <Typography color="primary" gutterBottom variant="body1">
                            Group Name:
                        </Typography>
                        <Typography variant="subtitle1">
                            {activeInsurancePlan?.groupName || 'None'}
                        </Typography>
                    </Grid>
                    {isDoulaView && (
                        <Grid item xs={4}>
                            <Typography color="primary" gutterBottom variant="body1">
                                Type:
                            </Typography>
                            <CoverageType activeInsurancePlan={activeInsurancePlan} />
                        </Grid>
                    )}
                    <Grid
                        item
                        xs={4}
                        sx={{ ...(isEligible ? {} : { backgroundColor: '#f2dada' }) }}
                    >
                        <Typography color="primary" gutterBottom variant="body1">
                            Eligibility Dates:
                        </Typography>
                        <Grid container alignItems="center">
                            <Typography variant="subtitle1">
                                {activeInsurancePlan
                                    ? `${activeInsurancePlan.eligibilityStartDate} - ${activeInsurancePlan.eligibilityEndDate}`
                                    : 'None'}
                            </Typography>
                            {hideRecheckButton || (
                                <RecheckEligibilityButton
                                    origin={EligibilityRefreshOrigin.AdminPortal}
                                    patientId={patient?.patient?.id}
                                />
                            )}
                        </Grid>
                    </Grid>
                    <Grid item xs={8}>
                        <Typography color="primary" gutterBottom variant="body1">
                            Group ID:
                        </Typography>
                        <Typography variant="subtitle1">
                            {activeInsurancePlan?.groupNumber || 'None'}
                        </Typography>
                    </Grid>
                </Grid>
            </Card>
        </Grid>
    );
};

export default InsuranceDetails;
