import { faCancel, faCheck, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useUserPermissions } from '~/hooks';
import { useSavePatientInternalMemoMutation } from '~/schemaTypes';

type PatientInternalMemoProps = {
    patientId: string;
    internalMemo?: string;
};

export const PatientInternalMemo: React.FC<PatientInternalMemoProps> = ({
    patientId,
    internalMemo,
}) => {
    const [editing, setEditing] = useState(false);
    const [memo, setMemo] = useState(internalMemo);
    const [saveMemo] = useSavePatientInternalMemoMutation();
    const { pagePermissions } = useUserPermissions();
    const handleSave = () => {
        saveMemo({ variables: { patientId, internalMemo: memo } });
        setEditing(false);
    };
    return (
        <div>
            {!editing && (
                <Typography>
                    Internal memo: {memo}
                    {pagePermissions?.PatientDetails.Edit && (
                        <Button onClick={() => setEditing(true)} title="Edit memo">
                            <FontAwesomeIcon icon={faPenToSquare} title="Edit memo" />
                        </Button>
                    )}
                </Typography>
            )}
            {editing && (
                <>
                    <input value={memo} onChange={e => setMemo(e.target.value)} />
                    <Button fullWidth={false} onClick={handleSave}>
                        <FontAwesomeIcon icon={faCheck} />
                    </Button>
                    <Button fullWidth={false} onClick={() => setEditing(false)}>
                        <FontAwesomeIcon icon={faCancel} />
                    </Button>
                </>
            )}
        </div>
    );
};
