import {
    Checkbox,
    Dialog,
    FormControlLabel,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import React, { Dispatch, SetStateAction, useEffect, useState } from 'react';
import { useFetchPatientQuestionsLazyQuery } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import _ from 'lodash';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';

type QListModalProps = {
    handleClose: Dispatch<SetStateAction<boolean>>;
    isModalOpen: boolean;
    patientId?: string;
};

export const QListModal: React.FC<QListModalProps> = ({ patientId, handleClose, isModalOpen }) => {
    const [filterAnsweredQuestions, setFilterAnsweredQuestions] = useState<boolean>(false);

    const [getPatient, { data: patientData, loading }] = useFetchPatientQuestionsLazyQuery();
    useEffect(() => {
        if (patientId) {
            getPatient({
                variables: {
                    input: {
                        id: patientId,
                    },
                },
            });
        }
    }, [patientId, getPatient]);
    if (loading) {
        return <Loading />;
    }
    return (
        <Dialog open={isModalOpen} onClose={handleClose} fullWidth>
            <DialogTitleWithClose id="questionDialog" onClose={() => handleClose(true)}>
                <Typography variant="h5">
                    {patientData?.patient?.firstName}
                    &apos;s Questions
                </Typography>
            </DialogTitleWithClose>
            <div>
                <div className="headerRow">
                    <FormControlLabel
                        style={{
                            margin: 0,
                        }}
                        label="View Answered Questions"
                        control={
                            <Switch
                                checked={filterAnsweredQuestions}
                                onChange={e => setFilterAnsweredQuestions(e.target.checked)}
                            />
                        }
                    />
                </div>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">Submitted</TableCell>
                            <TableCell align="center">Question</TableCell>
                            <TableCell align="center">Answered</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {_(patientData?.patient?.questions)
                            .filter(({ isDone }) => (!filterAnsweredQuestions ? !isDone : true))
                            .sortBy('submittedAt')
                            .reverse()
                            .value()
                            .map(({ id, text, isDone, submittedAt }) => (
                                <TableRow key={id}>
                                    <TableCell align="center">
                                        {submittedAt && new Date(submittedAt).toLocaleDateString()}
                                    </TableCell>
                                    <TableCell align="center">{text}</TableCell>
                                    <TableCell align="center">
                                        <Checkbox checked={isDone} />
                                    </TableCell>
                                </TableRow>
                            ))}
                    </TableBody>
                </Table>
                {patientData?.patient?.questions.length === 0 && (
                    <div className="noQuestions">
                        <Typography>This patient currently has no questions</Typography>
                    </div>
                )}
            </div>
        </Dialog>
    );
};
