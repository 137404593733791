import React, { useCallback, useMemo, useState } from 'react';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import {
    Grid,
    IconButton,
    TablePagination,
    Tooltip,
    Typography,
    Select,
    FormControl,
    InputLabel,
    MenuItem,
    Link,
    Button,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { SuppressNextGlobalAlert, TriggerGlobalAlert } from '~/state';
import {
    AlertSeverity,
    OrderByDirectionEnum,
    useFetchVirtualCareAffiliatesQuery,
    AffiliateApprovalStatus,
    useAffiliateAwayModeCreateBatchTaskMutation,
    BatchTaskType,
    BatchTaskStatus,
} from '~/schemaTypes';
import { InfoOutlined } from '@mui/icons-material';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import MaterialTable, {
    OrderByCollection,
    MTableAction,
    MTableActions,
} from '@material-table/core';
import {
    DEFAULT_ORDER_DIRECTION,
    OrderByFields,
    OrderDirectionMap,
    ROWS_PER_PAGE,
} from '~/views/ConfigDashboard/VCAffiliates/constants/constants';
import { useUserPermissions, useUser } from '~/hooks';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import ApprovalStatus from './components/ApprovalStatus';

export const VCAffiliates: React.FC = () => {
    const { pagePermissions } = useUserPermissions();
    const { data: userData } = useUser();
    const navigate = useNavigate();
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(ROWS_PER_PAGE);
    const [orderDirection, setOrderDirection] =
        useState<OrderByDirectionEnum>(DEFAULT_ORDER_DIRECTION);
    const [search, setSearch] = useState<string>();
    const [approvalStatusFilter, setApprovalStatusFilter] = useState<AffiliateApprovalStatus | ''>(
        '',
    );
    const onSearchChange = useCallback((searchString: string) => {
        setSearch(searchString);
    }, []);
    const fetchVariables = useMemo(() => {
        const filter = approvalStatusFilter
            ? {
                  fieldsInList: {
                      approvalStatus: [approvalStatusFilter],
                  },
              }
            : null;
        return {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: OrderByFields.name,
                    order: orderDirection,
                },
                ...(search && { search }),
                ...(filter && { filter }),
            },
        };
    }, [orderDirection, page, rowsPerPage, search, approvalStatusFilter]);
    const { loading, data } = useFetchVirtualCareAffiliatesQuery({
        variables: fetchVariables,
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });

    const onChangePage = useCallback(
        (_: React.MouseEvent<HTMLButtonElement>, pageNumber: number) => {
            setPage(() => pageNumber);
        },
        [],
    );
    const onChangeRowsPerPage = useCallback((pageSize: number) => {
        setRowsPerPage(() => pageSize);
    }, []);
    const onOrderChange = useCallback(
        (orderByCollection: OrderByCollection[]) => {
            const orderOptions = orderByCollection[0];
            if (orderOptions) {
                const { orderDirection: direction } = orderOptions;
                if (direction.toUpperCase() !== orderDirection) {
                    setOrderDirection(OrderDirectionMap[direction]);
                }
            }
        },
        [orderDirection],
    );
    const [runAwayModeJob, { loading: awayModeJobLoading }] =
        useAffiliateAwayModeCreateBatchTaskMutation({
            onCompleted: () =>
                TriggerGlobalAlert({
                    message: 'Job will be run',
                    severity: AlertSeverity.Success,
                }),
        });

    const runAwayModeJobHandler = useCallback(() => {
        SuppressNextGlobalAlert(true);
        runAwayModeJob({
            variables: {
                input: {
                    type: BatchTaskType.AffiliateAwayModeStatusRebuild,
                    status: BatchTaskStatus.NotStarted,
                    addedBy: userData?.currentUser?.name ?? '',
                    priority: 5,
                    runAfter: new Date(),
                },
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userData]);

    return (
        <MaterialTable
            title="VC Affiliates"
            actions={[
                {
                    icon: () => <FontAwesomeIcon icon={faPlus} />,
                    isFreeAction: true,
                    onClick: () => {
                        navigate('/app-config/vcaffiliates/new');
                    },
                    tooltip: 'Add New Entry',
                },
                {
                    hidden: !pagePermissions?.VirtualCareZipCodeMap.Edit,
                    // eslint-disable-next-line @typescript-eslint/no-empty-function
                    onClick: () => {},
                    isFreeAction: true,
                    icon: () => (
                        <AsyncActionButton loading={awayModeJobLoading}>
                            <Button
                                onClick={runAwayModeJobHandler}
                                variant="outlined"
                                disabled={awayModeJobLoading}
                            >
                                Refresh Away Mode Status
                            </Button>
                        </AsyncActionButton>
                    ),
                },
            ]}
            columns={[
                {
                    title: 'Name',
                    field: 'internalName',
                    render: ({ internalName, id }) => (
                        <Link
                            underline="always"
                            component={RouterLink}
                            to={`/app-config/vcaffiliates/${id}`}
                        >
                            {internalName}
                        </Link>
                    ),
                    sorting: true,
                    customFilterAndSearch: () => true,
                },
                {
                    title: 'ID',
                    field: 'id',
                    render: ({ id, affiliateId }) => (
                        <Grid container alignItems="center">
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'VC Affiliate ID is copied to clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                            <Typography data-test={id}>{affiliateId}</Typography>
                        </Grid>
                    ),
                    sorting: false,
                    searchable: false,
                },
                {
                    title: 'Approval',
                    field: 'approvalStatus',
                    render: ({ approvalStatus }) => (
                        <ApprovalStatus status={approvalStatus} iconPlace="left" />
                    ),
                    sorting: false,
                    customFilterAndSearch: () => true,
                },
            ]}
            data={data?.virtualCareAffiliates?.results ?? []}
            options={{
                search: true,
                pageSize: ROWS_PER_PAGE,
                pageSizeOptions: [ROWS_PER_PAGE, 20, 50],
                actionsColumnIndex: -1,
                debounceInterval: 500,
            }}
            onSearchChange={onSearchChange}
            localization={{ header: { actions: '' } }}
            isLoading={loading}
            onRowsPerPageChange={onChangeRowsPerPage}
            onOrderCollectionChange={onOrderChange}
            totalCount={data?.virtualCareAffiliates?.total}
            components={{
                Pagination: props => (
                    <TablePagination
                        {...props}
                        count={data?.virtualCareAffiliates?.total ?? 0}
                        page={page}
                        onPageChange={onChangePage}
                    />
                ),
                Action: props => {
                    if (props?.action?.position === 'toolbar' && props?.action?.firstAction) {
                        return (
                            <Grid container minWidth={250} alignItems="center">
                                <Grid item minWidth={170} xs={10}>
                                    <FormControl fullWidth>
                                        <InputLabel id="approval-status-filter-label" shrink>
                                            Approval Status
                                        </InputLabel>
                                        <Select
                                            labelId="approval-status-filter-label"
                                            id="approval-status-filter"
                                            value={approvalStatusFilter}
                                            label="Approval Status"
                                            size="small"
                                            displayEmpty
                                            onChange={evt =>
                                                setApprovalStatusFilter(
                                                    evt.target.value as AffiliateApprovalStatus,
                                                )
                                            }
                                        >
                                            <MenuItem value="">All</MenuItem>
                                            <MenuItem
                                                value={AffiliateApprovalStatus.ApprovalNeeded}
                                            >
                                                Approval Needed
                                            </MenuItem>
                                            <MenuItem value={AffiliateApprovalStatus.Denied}>
                                                Denied
                                            </MenuItem>
                                        </Select>
                                    </FormControl>
                                </Grid>
                                <Grid item xs={2}>
                                    <MTableAction {...props} />
                                </Grid>
                            </Grid>
                        );
                    }
                    return <MTableAction {...props} />;
                },
                Actions: props => {
                    const propsWithActionIndex = {
                        ...props,
                        actions: props?.actions.map((a: any, index: number) => ({
                            ...a,
                            firstAction: index === 0,
                        })),
                    };
                    return <MTableActions {...propsWithActionIndex} />;
                },
            }}
        />
    );
};
