import { Button, Checkbox, FormControlLabel, Grid, MenuItem, Typography } from '@mui/material';
import { Save } from '@mui/icons-material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import WeeklyDatePicker from '~/components/WeeklyDatePicker/WeeklyDatePicker';
import { OrgTreeNode } from '~/hooks/useOrgTree';
import { AlertSeverity } from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import { OrgState } from '~/views/ConfigDashboard/Organizations/Organizations';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import moment, { Moment } from 'moment';
import {
    getDatesForReport,
    getReportingListOptionsItem,
    getReportNameForReportingJob,
} from './helpers';
import { generateOrganizationReport } from './OrganizationReportingCSV';

export const reportingListOptions = {
    patientListExport: 'AHS Employee Patient List Export',
    patientListExportForAxia: 'Axia Employee Patient List Export',
    intakeSurveyResults: 'AHS Employee Intake Form Export',
    lifelineReport: 'Lifeline Patient List Export',
    allPatientList: 'All Patient List',
    htcBillingDataReport: 'HTC Employee Patient List Export for Billing',
    htcGenericDataReport: 'HTC Generic App Patient List Export for Billing',
    bhfMonthlyInvoicing: 'BHF Monthly Invoicing',
    pregnancyPatientList: 'AHS Employee New Pregnancy Check-In Export',
    highRiskEPDSReport: 'BHF Postpartum High Risk EPDS Export',
    progenyBillingReport: 'ProgenyHealth Patient List Export for Billing',
    sshpCaseReport: 'SSHP Patient Export for Case Management',
    cignaChatDataReport: 'Cigna HPHB Messenger Data YTD',
    stPetersBillingReport: "St. Peter's Patient List for Billing",
    standardizedHMReport: 'All HM-Eligible Patient List',
    hpnBillingReport: 'HPN Patient List for Billing',
    hpnSupplementalDataFeedPregnant: 'HPN Supplemental Data Feed - Pregnant',
    hpnSupplementalDataFeedPostpartum: 'HPN Supplemental Data Feed - Postpartum',
    bhfAppointmentUtilizationReport: 'BHF Appointment Utilization Report',
};

const AutoSendToClientReports = [
    reportingListOptions.bhfMonthlyInvoicing,
    reportingListOptions.sshpCaseReport,
    reportingListOptions.hpnSupplementalDataFeedPregnant,
    reportingListOptions.hpnSupplementalDataFeedPostpartum,
];

export const HTC_ORGANIZATION_NAME = 'HTC';
export const AHS_ORGANIZATION_NAME = 'AHS';
export const HTC_HEALTH_ADVOCATE_ORGANIZATION_NAME = 'SoCal Wildflower Pregnancy Advocate';
export const LIFELINE_ORGANIZATION_NAME = 'Lifeline';
export const AXIA_ORGANIZATION_NAME = 'Axia';
export const BHF_ORGANIZATION_NAME = 'Building Healthy Families';
export const AXIA_BRANDING_NAME = 'MyAxia Pregnancy';
export const HTC_HA_BRANDING_NAME = 'HTC Wildflower Health Advocate';
export const WHASN_ORGANIZATION_NAME = 'WHASN';
export const UNLV_ORGANIZATION_NAME = 'UNLV Cares';
export const PROGENY_HEALTH_BRANDING_NAME = 'ProgenyHealth';
export const ST_PETERS_BRANDING_NAME = "Saint Peter's";

type OrganizationConfigProps = {
    orgState: OrgState;
    orgNode: Omit<OrgTreeNode, 'level'>;
    ancestorsBrandingNames: Array<OrgTreeNode['org']['brandingName']>;
};

const OrganizationReporting: React.FC<OrganizationConfigProps> = ({
    orgState,
    orgNode,
    ancestorsBrandingNames,
}) => {
    const {
        control,
        watch,
        setValue,
        resetField,
        getValues,
        formState: { errors },
    } = useForm<any>({
        defaultValues: {
            autoSendToClient: true,
        },
    });
    const startDate = watch('startDate');
    const endDate = watch('endDate');
    const reportMonthDate = watch('reportMonthDate');
    const reportYearDate = watch('reportYearDate');
    const reportWeekDate = watch('reportWeekDate');
    const isMonthlyReportDatesSelected = reportMonthDate && reportYearDate;
    const [isReportLoading, setIsReportLoading] = useState(false);
    const reportingOptionSelection = watch('reportingOptionSelection');

    const isBhfReportingOptionSelected =
        reportingOptionSelection === reportingListOptions.bhfMonthlyInvoicing;
    const isHtcReportingOptionSelected =
        reportingOptionSelection === reportingListOptions.htcBillingDataReport;
    const isHtcGenericAppOptionSelected =
        reportingOptionSelection === reportingListOptions.htcGenericDataReport;
    const isHtcPregnancyOptionSelected =
        reportingOptionSelection === reportingListOptions.pregnancyPatientList;
    const isEPDSReportSelected =
        reportingOptionSelection === reportingListOptions.highRiskEPDSReport;
    const isProgenyBillingReportSelected =
        reportingOptionSelection === reportingListOptions.progenyBillingReport;
    const isStPetersReportSelected =
        reportingOptionSelection === reportingListOptions.stPetersBillingReport;
    const isHPNReportSelected = reportingOptionSelection === reportingListOptions.hpnBillingReport;
    const isHPNSupplementalDataFeedPregnantReportSelected =
        reportingOptionSelection === reportingListOptions.hpnSupplementalDataFeedPregnant;
    const isHPNSupplementalDataFeedPostpartumReportSelected =
        reportingOptionSelection === reportingListOptions.hpnSupplementalDataFeedPostpartum;
    const isBhfAppointmentUtilizationReportSelected =
        reportingOptionSelection === reportingListOptions.bhfAppointmentUtilizationReport;

    const useDateRange: boolean = watch('useDateRange');

    const reportingListOptionsItems = getReportingListOptionsItem(
        orgState,
        orgNode,
        ancestorsBrandingNames,
    );

    const defaultReportingOptionValue = Object.values(reportingListOptionsItems)[0];

    useEffect(() => {
        resetField('autoSendToClient');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportingOptionSelection]);

    const isReportByWeek = isHtcPregnancyOptionSelected || isEPDSReportSelected;
    const isReportByMonth =
        !isReportByWeek &&
        (isBhfReportingOptionSelected ||
            isHtcReportingOptionSelected ||
            isHtcGenericAppOptionSelected ||
            isProgenyBillingReportSelected ||
            isStPetersReportSelected ||
            isHPNReportSelected ||
            isHPNSupplementalDataFeedPregnantReportSelected ||
            isHPNSupplementalDataFeedPostpartumReportSelected ||
            isBhfAppointmentUtilizationReportSelected);

    const submitReportButtonDisabled =
        isReportLoading ||
        (isReportByMonth && !isMonthlyReportDatesSelected) ||
        (isReportByWeek && !reportWeekDate);

    const handleDownloadOrganizationReporting = async () => {
        const downloadErrorMessage = 'Unable to download Organization Reporting CSV.';
        const { reportStartDate, reportEndDate } = getDatesForReport(
            startDate,
            endDate,
            reportMonthDate,
            reportYearDate,
            isReportByMonth,
            isReportByWeek,
            reportWeekDate,
        );

        try {
            setIsReportLoading(true);
            if (Object.values(reportingListOptionsItems).includes(reportingOptionSelection)) {
                const autoSendToClient = getValues('autoSendToClient');
                const res = await generateOrganizationReport(
                    orgNode.org.id,
                    getReportNameForReportingJob(reportingListOptions, reportingOptionSelection),
                    reportStartDate,
                    reportEndDate,
                    autoSendToClient,
                );
                if (res?.status === 200) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Success,
                        message: 'Reporting job started',
                    });
                } else {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message: "Reporting job wasn't started",
                    });
                }
                return;
            }
            if (!reportingOptionSelection) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `${downloadErrorMessage} Reporting Option is not defined.`,
                });
            } else {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: downloadErrorMessage,
                });
            }
        } catch (e) {
            TriggerGlobalAlert({ severity: AlertSeverity.Error, message: downloadErrorMessage });
        } finally {
            setIsReportLoading(false);
        }
    };

    useEffect(() => {
        if (!Object.values(reportingListOptionsItems).includes(reportingOptionSelection)) {
            setValue('reportingOptionSelection', defaultReportingOptionValue);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportingListOptionsItems]);
    useEffect(() => {
        resetField('reportMonthDate', { keepDirty: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportYearDate]);

    return (
        <div>
            <Grid container>
                <Grid item xs={12} md={10}>
                    <div>
                        <Typography variant="h6" paragraph>
                            Report
                        </Typography>
                        <ReactHookFormSelect
                            control={control}
                            name="reportingOptionSelection"
                            variant="outlined"
                            defaultValue={defaultReportingOptionValue}
                            label="Selection type *"
                            fullWidth
                            margin="dense"
                        >
                            {Object.values(reportingListOptionsItems).map(value => (
                                <MenuItem key={value} value={value} data-test={value}>
                                    {value}
                                </MenuItem>
                            ))}
                        </ReactHookFormSelect>
                        {!(isReportByMonth || isReportByWeek) && (
                            <Grid container item xs={12}>
                                <Controller
                                    name="useDateRange"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <FormControlLabel
                                            label="Use Date Range"
                                            control={
                                                <Checkbox
                                                    checked={value}
                                                    onChange={e => onChange(e.target.checked)}
                                                />
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid>
                            {isReportByWeek && (
                                <LocalizationProvider dateAdapter={AdapterMoment}>
                                    <Controller
                                        name="reportWeekDate"
                                        control={control}
                                        defaultValue={null}
                                        render={({ field }) => (
                                            <WeeklyDatePicker
                                                {...field}
                                                label="Week"
                                                disableFuture
                                                format="MM/DD/YYYY"
                                            />
                                        )}
                                    />
                                </LocalizationProvider>
                            )}
                            {isReportByMonth && (
                                <>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Controller
                                            name="reportMonthDate"
                                            control={control}
                                            defaultValue={null}
                                            render={({ field }) => (
                                                <DesktopDatePicker
                                                    {...field}
                                                    label="Month"
                                                    openTo="month"
                                                    format="MM"
                                                    views={['month']}
                                                    minDate={
                                                        reportYearDate
                                                            ? reportYearDate.clone().startOf('year')
                                                            : moment().startOf('year')
                                                    }
                                                    maxDate={
                                                        reportYearDate
                                                            ? reportYearDate.clone().endOf('year')
                                                            : moment().endOf('year')
                                                    }
                                                    disableFuture
                                                    slotProps={{
                                                        textField: {
                                                            helperText: errors.reportMonthDate
                                                                ?.message as string,
                                                            size: 'small',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Controller
                                            name="reportYearDate"
                                            defaultValue={null}
                                            control={control}
                                            render={({ field }) => (
                                                <DesktopDatePicker
                                                    {...field}
                                                    label="Year"
                                                    openTo="year"
                                                    format="YYYY"
                                                    views={['year']}
                                                    disableFuture
                                                    slotProps={{
                                                        textField: {
                                                            helperText: errors.reportYearDate
                                                                ?.message as string,
                                                            size: 'small',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </>
                            )}
                            {!(isReportByMonth || isReportByWeek) && (
                                <>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Controller
                                            name="startDate"
                                            defaultValue={null}
                                            control={control}
                                            render={({ field }) => (
                                                <DesktopDatePicker
                                                    {...field}
                                                    label="Start Date"
                                                    openTo="day"
                                                    format="MM/DD/YYYY"
                                                    disabled={!useDateRange}
                                                    showDaysOutsideCurrentMonth
                                                    slotProps={{
                                                        textField: {
                                                            helperText: errors.startDate
                                                                ?.message as string,
                                                            size: 'small',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                    <LocalizationProvider dateAdapter={AdapterMoment}>
                                        <Controller
                                            name="endDate"
                                            defaultValue={null}
                                            control={control}
                                            render={({ field }) => (
                                                <DesktopDatePicker<Moment>
                                                    {...field}
                                                    label="End Date"
                                                    openTo="day"
                                                    format="MM/DD/YYYY"
                                                    minDate={startDate || moment()}
                                                    disabled={!useDateRange}
                                                    showDaysOutsideCurrentMonth
                                                    slotProps={{
                                                        textField: {
                                                            helperText: errors.endDate
                                                                ?.message as string,
                                                            size: 'small',
                                                        },
                                                    }}
                                                />
                                            )}
                                        />
                                    </LocalizationProvider>
                                </>
                            )}
                        </Grid>
                        {AutoSendToClientReports.includes(reportingOptionSelection) && (
                            <Grid container item xs={12}>
                                <Controller
                                    name="autoSendToClient"
                                    control={control}
                                    render={({ field: { value, onChange } }) => (
                                        <FormControlLabel
                                            label="Auto-send to client repository"
                                            control={
                                                <Checkbox
                                                    checked={value}
                                                    onChange={e => onChange(e.target.checked)}
                                                />
                                            }
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid item style={{ textAlign: 'left' }}>
                            <Button
                                startIcon={<Save />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                                onClick={handleDownloadOrganizationReporting}
                                disabled={submitReportButtonDisabled}
                            >
                                Generate Report
                            </Button>
                        </Grid>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default OrganizationReporting;
