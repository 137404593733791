import React, { useState } from 'react';
import {
    OrderByDirectionEnum,
    useFetchUserProfileDefsV2ForEligibilityConfigViewQuery,
} from '~/schemaTypes';
import { Control, Controller } from 'react-hook-form';
import { Autocomplete, Button, Dialog, TextField, Grid } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import ProfileDefsModal from '~/views/ConfigDashboard/ProfileDefs/ProfileDefsModal/ProfileDefsModal';

const useStyles = makeStyles()({
    root: {
        marginTop: 8,
        marginBottom: 4,
    },
    linkRoot: {
        display: 'inline-flex',
        alignItems: 'center',
    },
    link: {
        paddingLeft: 10,
    },
});

const TargetProfileDefSelect = ({
    control,
    name,
    value,
    error,
    helperText,
    disabled,
}: {
    control: Control<any>;
    name: string;
    value: string;
    error: boolean;
    helperText?: string;
    disabled?: boolean;
}) => {
    const [isProfileDefModal, setIsProfileDefModal] = useState(false);
    const { classes } = useStyles();

    const { data, loading } = useFetchUserProfileDefsV2ForEligibilityConfigViewQuery({
        variables: {
            input: {
                orderBy: {
                    field: 'name',
                    order: OrderByDirectionEnum.Asc,
                },
            },
        },
    });

    const items = data?.userProfileDefsV2.results ?? [];
    const item = data?.userProfileDefsV2.results.find(item => item.id === value);
    const linkTxt = item ? `${item.name} (${item.valueType})` : '';

    return (
        <Grid container spacing={2}>
            <Grid item xs={6}>
                <Controller
                    control={control}
                    name={name}
                    render={({ field: { onChange, value, ref } }) => (
                        <Autocomplete
                            className={classes.root}
                            options={items}
                            getOptionLabel={option => option.name ?? ''}
                            isOptionEqualToValue={(option, val) => option.id === val.id}
                            filterSelectedOptions
                            value={items.find(item => item.id === value) || null}
                            onChange={(_, v) => onChange(v ? v.id : null)}
                            renderInput={params => (
                                <TextField
                                    {...params}
                                    label="Target ProfileDef"
                                    placeholder="Select Target ProfileDef"
                                    inputRef={ref}
                                    error={error}
                                    helperText={helperText}
                                    disabled={disabled}
                                />
                            )}
                            loading={loading}
                            loadingText="Loading..."
                            noOptionsText="No options"
                            disabled={disabled}
                        />
                    )}
                />
            </Grid>
            {value && (
                <Grid item xs={6} alignContent="center">
                    <div className={classes.linkRoot}>
                        <span>link:</span>
                        {loading && <span className={classes.link}>loading ...</span>}
                        {!loading && (
                            <Button
                                color="primary"
                                variant="text"
                                onClick={() => setIsProfileDefModal(true)}
                            >
                                {linkTxt}
                            </Button>
                        )}
                    </div>
                </Grid>
            )}
            {isProfileDefModal && !disabled && (
                <Dialog scroll="body" open={isProfileDefModal} fullWidth maxWidth="lg">
                    <ProfileDefsModal
                        setOpen={() => setIsProfileDefModal(false)}
                        setEditProfileDefId={() => setIsProfileDefModal(false)}
                        id={item?.id}
                    />
                </Dialog>
            )}
        </Grid>
    );
};

export default TargetProfileDefSelect;
