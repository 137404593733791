import { AwayModeStatus } from '~/views/AffiliateCare/components/AwayMode/enums';
import moment from 'moment/moment';

export const getMode = (
    vacationMode: boolean,
    endVacationDate?: Date,
    startVacationDate?: Date,
) => {
    if (vacationMode) {
        return AwayModeStatus.vacation;
    }
    if (!endVacationDate || !startVacationDate) {
        return AwayModeStatus.off;
    }
    if (moment().isBefore(moment(startVacationDate).startOf('day'))) {
        return AwayModeStatus.scheduled;
    }
    return AwayModeStatus.off;
};
