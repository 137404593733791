import React from 'react';
import { GetExpressionEvaluatedForPatientTranscriptQuery } from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type ExpressionEvaluated = NonNullable<
    NonNullable<
        GetExpressionEvaluatedForPatientTranscriptQuery['getExpressionEvaluatedForPatientTranscript']
    >['items']
>[number];

export const ExpressionEvaluatedCard = ({ event }: { event: ExpressionEvaluated }) => {
    return (
        <BaseCard title="Expression Evaluated" data={event}>
            <p className="m-0">{`Expression: ${event?.expression?.name || 'Unknown'}`}</p>
            <p className="m-0">{`Description: ${event?.expression?.description || 'Unknown'}`}</p>
            <p className="m-0">{`State: ${event?.event?.data?.state || 'Unknown'}`}</p>
        </BaseCard>
    );
};
