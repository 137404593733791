/* eslint-disable camelcase */
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import { Save } from '@mui/icons-material';
import React, { Dispatch, SetStateAction, useCallback, useEffect, useState } from 'react';
import { Controller, FieldError, useForm } from 'react-hook-form';
import * as Yup from 'yup';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import Loading from '~/components/Loading/Loading';
import {
    AdvocateTaskTemplateWhenType,
    CommType,
    InterventionDateType,
    InterventionInput,
    InterventionListDocument,
    InterventionProfileValueInput,
    InterventionType,
    PreFetchInterventionDataQuery,
    useCreateInterventionMutation,
    useFetchAdvocateTaskTemplatesForInterventionQuery,
    useFetchInterventionLazyQuery,
    usePreFetchInterventionDataQuery,
    UserProfileValueType,
    useUpdateInterventionMutation,
} from '~/schemaTypes';
import { ProfileDefEnum } from '~/selectors';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import _ from 'lodash';
import { ClientSelectionType } from '~/enums/enums';

type Choices = NonNullable<
    NonNullable<PreFetchInterventionDataQuery['userProfileDefs'][0]>['choices']
>[0];

const toClientSelectionType = (
    includeApps: string[] | null | undefined,
    excludeApps: string[] | null | undefined,
): ClientSelectionType => {
    if (includeApps && includeApps.length > 0) return ClientSelectionType.IncludeCertainApps;
    if (excludeApps && excludeApps.length > 0) return ClientSelectionType.ExcludeCertainApps;
    return ClientSelectionType.IncludeAllApps;
};

interface InterventionFormInput {
    type: InterventionType;
    conditionTypeId?: string | null;
    profileDefId?: string | null;
    profileValue?: InterventionProfileValueInput | null;
    advocateTaskTemplateId?: string | null;
    choicesToUpdate?: (string | null)[] | null | undefined;
    includeApps: string[];
    excludeApps: string[];
    incrementValue?: number;
}

const formValuesDefault = (): InterventionFormInput => {
    return {
        type: InterventionType.StartEpisode,
        conditionTypeId: null,
        profileDefId: null,
        profileValue: {
            choiceId: null,
            str: null,
            num: null,
            bool: false,
            date: null,
        },
        advocateTaskTemplateId: null,
        choicesToUpdate: null,
        includeApps: [],
        excludeApps: [],
        incrementValue: 0,
    };
};

const InterventionValidation = Yup.object({
    type: Yup.string().oneOf(Object.values(InterventionType)).required('Required'),
})
    .defined()
    .noUnknown(false);

type InterventionsModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    setEditIntervention: Dispatch<SetStateAction<string>>;
    id?: string | undefined;
    tagId?: string;
    dateTriggerId?: string;
    eventTriggerId?: string;
    trendTriggerId?: string;
    interventionPriority: number;
};

type SelectedValues = {
    str?: string | null;
    num?: number | null;
    bool?: boolean | null;
    date?: string | null;
};

const SelectedValuesDefaults: SelectedValues = {
    str: null,
    num: null,
    bool: null,
    date: null,
};

const profileValueType = (
    profileData: PreFetchInterventionDataQuery['userProfileDefs'] | undefined,
    profileDefId: string,
) => profileData?.find((item: any) => item.id === profileDefId)?.valueType;

const InterventionsModal: React.FC<InterventionsModalProps> = ({
    setOpen,
    setEditIntervention,
    id: interventionId,
    tagId,
    dateTriggerId,
    eventTriggerId,
    trendTriggerId,
    interventionPriority,
}) => {
    const [valueType, setValueType] = useState<InterventionType>(InterventionType.StartEpisode);
    const [dateType, setDateType] = useState<InterventionDateType | null>(
        InterventionDateType.NotApplicable,
    );
    const [conditionTypeId, setConditionTypeId] = useState<string | null>(null);
    const [messageTemplateId, setMessageTemplateId] = useState<string | null>(null);
    const [messageCenterTemplateId, setMessageCenterTemplateId] = useState<string | null>(null);
    const [advocateTaskTemplateId, setAdvocateTaskTemplateId] = useState<string | null>(null);
    const [profileDefId, setProfileDefId] = useState<string | null>(null);
    const [profileDefChoiceId, setProfileDefChoiceId] = useState<string | null>(null);
    const [selectedValues, setSelectedValues] = useState<SelectedValues | null>(
        SelectedValuesDefaults,
    );
    const [choicesToUpdate, setChoicesToUpdate] = useState<(string | null)[]>([]);
    const [clientSelectionType, setClientSelectionType] = useState<ClientSelectionType>(
        ClientSelectionType.IncludeAllApps,
    );
    const [messagePatientCommType, setMessagePatientCommType] = useState<CommType | null>(null);

    const {
        control,
        reset,
        handleSubmit,
        register,
        formState: { errors },
    } = useForm<InterventionInput>({
        resolver: yupResolver(InterventionValidation),
        defaultValues: formValuesDefault(),
    });

    const processDateTypeValue = (
        profileData: PreFetchInterventionDataQuery['userProfileDefs'] | undefined,
        profileId: string,
    ) => {
        if (
            profileValueType(profileData, profileId) === UserProfileValueType.Date ||
            profileValueType(profileData, profileId) === UserProfileValueType.Dates
        ) {
            setDateType(InterventionDateType.Today);
        } else {
            setDateType(InterventionDateType.NotApplicable);
        }
    };

    const [fetchInterventionById, { data: interventionData, loading: interventionLoading }] =
        useFetchInterventionLazyQuery({
            onCompleted: ({ intervention }) => {
                if (intervention) {
                    const {
                        type,
                        conditionTypeId,
                        profileDefId,
                        profileValue,
                        dateType,
                        advocateTaskTemplateId,
                        choicesToUpdate,
                        includeApps,
                        excludeApps,
                        commType: commTypeRecieved,
                        incrementValue,
                    } = intervention;
                    reset({
                        type: type ?? InterventionType.StartEpisode,
                        conditionTypeId: conditionTypeId ?? null,
                        profileDefId: profileDefId ?? null,
                        profileValue: profileValue ?? null,
                        advocateTaskTemplateId: advocateTaskTemplateId ?? null,
                        includeApps,
                        excludeApps,
                        incrementValue,
                    });
                    setValueType(type);
                    setDateType(dateType ?? null);
                    setConditionTypeId(conditionTypeId);
                    setProfileDefId(profileDefId);
                    setProfileDefChoiceId(profileValue?.choiceId ?? null);
                    setSelectedValues({ ...profileValue });
                    setAdvocateTaskTemplateId(advocateTaskTemplateId);
                    setChoicesToUpdate(choicesToUpdate ?? []);
                    setClientSelectionType(
                        toClientSelectionType(intervention.includeApps, intervention.excludeApps),
                    );
                    setMessagePatientCommType(commTypeRecieved ?? null);
                }
            },
            fetchPolicy: 'network-only',
        });

    const [updateIntervention, { loading: updateInterventionLoading }] =
        useUpdateInterventionMutation({
            onCompleted: data => {
                if (data.updateIntervention?.success) {
                    setOpen(false);
                    setEditIntervention('');
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: InterventionListDocument,
                    variables: {
                        interventionListInput: { filter: { fields: { tagId } } },
                    },
                },
            ],
        });

    const [createIntervention, { loading: createInterventionLoading }] =
        useCreateInterventionMutation({
            onCompleted: data => {
                if (data.createIntervention?.success) {
                    setOpen(false);
                    setEditIntervention('');
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: InterventionListDocument,
                    variables: {
                        interventionListInput: {
                            filter: {
                                fields: { tagId, dateTriggerId, eventTriggerId, trendTriggerId },
                            },
                        },
                    },
                },
            ],
        });

    const { data: preFetchData, loading: preFetchLoading } = usePreFetchInterventionDataQuery();

    const { data: advocateTaskTemplatesData, loading: advocateTaskTemplatesLoading } =
        useFetchAdvocateTaskTemplatesForInterventionQuery({
            variables: {
                input: {
                    filter: { fields: { whenType: AdvocateTaskTemplateWhenType.Intervention } },
                },
            },
        });

    const onSubmit = (form: any) => {
        const selectedChoice = preFetchData?.userProfileDefs
            ?.find((item: any) => item.id === profileDefId)
            ?.choices?.find((choice: any) => choice.id === profileDefChoiceId);
        let boolVal = null;
        if (profileDefId && selectedChoice === undefined) {
            const selectedProfileValueType = profileValueType(
                preFetchData?.userProfileDefs,
                profileDefId,
            );
            boolVal =
                selectedProfileValueType === UserProfileValueType.Bool ||
                selectedProfileValueType === UserProfileValueType.Bools
                    ? false
                    : null;
        }
        const interventionData: InterventionInput = {
            ...form,
            tagId,
            dateTriggerId,
            eventTriggerId,
            trendTriggerId,
            type: valueType,
            conditionTypeId,
            profileDefId,
            messageCenterTemplateId,
            messageTemplateId,
            advocateTaskTemplateId,
            dateType,
            choicesToUpdate,
            priority: interventionPriority,
            profileValue: {
                choiceId: selectedChoice?.id,
                str: selectedChoice?.value?.str || selectedValues?.str,
                num: selectedChoice?.value?.num || selectedValues?.num,
                bool: selectedChoice?.value?.bool || selectedValues?.bool || boolVal,
                date:
                    dateType === InterventionDateType.Today
                        ? null
                        : selectedChoice?.value?.date || selectedValues?.date,
            },
            commType: messagePatientCommType,
            incrementValue: Number(form.incrementValue),
        };
        if (clientSelectionType === ClientSelectionType.IncludeAllApps) {
            interventionData.includeApps = [];
            interventionData.excludeApps = [];
        }
        if (clientSelectionType === ClientSelectionType.IncludeCertainApps)
            interventionData.excludeApps = [];
        if (clientSelectionType === ClientSelectionType.ExcludeCertainApps)
            interventionData.includeApps = [];
        if (interventionId) {
            updateIntervention({
                variables: {
                    updateInterventionInput: {
                        id: interventionId,
                        data: interventionData,
                    },
                },
            });
        } else {
            createIntervention({
                variables: {
                    createInterventionInput: interventionData,
                },
            });
        }
    };

    const handleCancel = () => {
        setOpen(false);
        setEditIntervention('');
    };

    const formatDate = (dateTime: string) => dateTime.split('T')[0];

    const dateTypeOptions = useCallback(
        () =>
            Object.values(InterventionDateType).filter(
                dateType => dateType !== InterventionDateType.NotApplicable,
            ),
        [],
    );

    const updateChoices = (item: Choices | null) => {
        const choisesIds = new Set(choicesToUpdate || []);
        if (item) {
            if (choicesToUpdate && choicesToUpdate.length > 0) {
                if (choicesToUpdate?.find(choiceId => choiceId === item.id)) {
                    choisesIds.delete(item.id);
                } else {
                    choisesIds.add(item.id);
                }
                setChoicesToUpdate(Array.from(choisesIds));
            } else {
                setChoicesToUpdate([item.id]);
            }
        }
    };

    const isItemChecked = (item: Choices | null) => {
        return item && item.id ? !!choicesToUpdate?.find(choiceId => choiceId === item.id) : false;
    };

    useEffect(() => {
        if (interventionId) {
            fetchInterventionById({ variables: { interventionInput: { id: interventionId } } });
        }
    }, [fetchInterventionById, interventionId]);

    if (
        preFetchLoading ||
        interventionLoading ||
        createInterventionLoading ||
        updateInterventionLoading ||
        advocateTaskTemplatesLoading
    )
        return <Loading />;

    return (
        <form noValidate onSubmit={handleSubmit(onSubmit)}>
            <DialogTitleWithClose id="form-dialog-title" onClose={() => setOpen(false)}>
                {interventionId ? 'Edit Intervention' : 'Create Intervention'}
            </DialogTitleWithClose>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="type"
                            render={({ field: { onChange }, field }) => (
                                <Autocomplete
                                    {...field}
                                    size="small"
                                    onChange={(_, val) => {
                                        setProfileDefId(null);
                                        setConditionTypeId(null);
                                        setAdvocateTaskTemplateId(null);
                                        onChange(val);
                                        setValueType(val || InterventionType.StartEpisode);
                                    }}
                                    getOptionLabel={selected => selected}
                                    options={Object.values(InterventionType)}
                                    isOptionEqualToValue={(option, val) => option === val}
                                    renderInput={params => (
                                        <TextField
                                            variant="outlined"
                                            margin="dense"
                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                            {...params}
                                            placeholder="Select Type"
                                            label="Type"
                                            InputLabelProps={{ shrink: true }}
                                            data-test={ProfileDefEnum.MODAL_VALUE_TYPE_DROPDOWN}
                                        />
                                    )}
                                />
                            )}
                        />
                    </Grid>
                    {valueType === InterventionType.SetProfileValue && (
                        <>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="profileDefId"
                                    defaultValue=""
                                    render={({ field: { onChange }, field }) => (
                                        <Autocomplete
                                            size="small"
                                            {...field}
                                            onChange={(_, val) => {
                                                onChange(val);
                                                setProfileDefId(val);
                                                setSelectedValues({
                                                    ...SelectedValuesDefaults,
                                                });
                                                processDateTypeValue(
                                                    preFetchData?.userProfileDefs,
                                                    val,
                                                );
                                            }}
                                            options={
                                                preFetchData?.userProfileDefs
                                                    ?.filter(
                                                        def =>
                                                            def.valueType !==
                                                            UserProfileValueType.Choices,
                                                    )
                                                    .map((item: any) => item.id) ?? []
                                            }
                                            getOptionLabel={id =>
                                                preFetchData?.userProfileDefs?.find(
                                                    (item: any) => item.id === id,
                                                )?.name ?? ''
                                            }
                                            defaultValue={profileDefId}
                                            isOptionEqualToValue={(option, val) => option === val}
                                            renderInput={params => (
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...params}
                                                    placeholder="Select Profile Definition"
                                                    label="Select Profile Definition"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            {profileDefId &&
                                profileValueType(preFetchData?.userProfileDefs, profileDefId) ===
                                    UserProfileValueType.Choice && (
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="profileValue"
                                            render={({ field: { onChange }, field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    size="small"
                                                    onChange={(_, val) => {
                                                        onChange(val);
                                                        setProfileDefChoiceId(val);
                                                    }}
                                                    options={
                                                        preFetchData?.userProfileDefs
                                                            ?.find(
                                                                (item: any) =>
                                                                    item.id === profileDefId,
                                                            )
                                                            ?.choices?.map(
                                                                (item: any) => item.id,
                                                            ) ?? []
                                                    }
                                                    getOptionLabel={id =>
                                                        preFetchData?.userProfileDefs
                                                            ?.find(
                                                                (item: any) =>
                                                                    item.id === profileDefId,
                                                            )
                                                            ?.choices?.find(
                                                                (choice: any) => choice.id === id,
                                                            )?.label?.en ?? ''
                                                    }
                                                    defaultValue={profileDefChoiceId}
                                                    fullWidth
                                                    isOptionEqualToValue={(option, val) =>
                                                        option === val
                                                    }
                                                    renderInput={params => (
                                                        <TextField
                                                            variant="outlined"
                                                            margin="dense"
                                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                                            {...params}
                                                            placeholder="Select Value"
                                                            label="Select Value"
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                            {profileDefId &&
                                (profileValueType(preFetchData?.userProfileDefs, profileDefId) ===
                                    UserProfileValueType.Str ||
                                    profileValueType(
                                        preFetchData?.userProfileDefs,
                                        profileDefId,
                                    ) === UserProfileValueType.Strs) && (
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="profileValue"
                                            render={({ field: { onChange }, field }) => (
                                                <TextField
                                                    {...field}
                                                    variant="outlined"
                                                    name="Select Value"
                                                    label="Select Value"
                                                    type="text"
                                                    margin="dense"
                                                    value={selectedValues?.str}
                                                    onChange={e => {
                                                        onChange(e.currentTarget.value);
                                                        setSelectedValues({
                                                            ...SelectedValuesDefaults,
                                                            str: e.currentTarget.value,
                                                        });
                                                        setDateType(
                                                            InterventionDateType.NotApplicable,
                                                        );
                                                    }}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                            {profileDefId &&
                                (profileValueType(preFetchData?.userProfileDefs, profileDefId) ===
                                    UserProfileValueType.Num ||
                                    profileValueType(
                                        preFetchData?.userProfileDefs,
                                        profileDefId,
                                    ) === UserProfileValueType.Nums) && (
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="profileValue"
                                            render={({ field: { onChange }, field }) => (
                                                <TextField
                                                    {...field}
                                                    variant="outlined"
                                                    name="Select Value"
                                                    label="Select Value"
                                                    type="number"
                                                    margin="dense"
                                                    value={selectedValues?.num}
                                                    onChange={e => {
                                                        onChange(e.currentTarget.value);
                                                        setSelectedValues({
                                                            ...SelectedValuesDefaults,
                                                            num: parseInt(
                                                                e.currentTarget.value,
                                                                10,
                                                            ),
                                                        });
                                                        setDateType(
                                                            InterventionDateType.NotApplicable,
                                                        );
                                                    }}
                                                    fullWidth
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                            {profileDefId &&
                                (profileValueType(preFetchData?.userProfileDefs, profileDefId) ===
                                    UserProfileValueType.Bool ||
                                    profileValueType(
                                        preFetchData?.userProfileDefs,
                                        profileDefId,
                                    ) === UserProfileValueType.Bools) && (
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="profileValue"
                                            render={({ field: { onChange }, field }) => (
                                                <FormControlLabel
                                                    control={
                                                        <Checkbox
                                                            {...field}
                                                            checked={selectedValues?.bool ?? false}
                                                            color="primary"
                                                            onChange={(_, val) => {
                                                                onChange(val);
                                                                setSelectedValues({
                                                                    ...SelectedValuesDefaults,
                                                                    bool: val,
                                                                });
                                                                setDateType(
                                                                    InterventionDateType.NotApplicable,
                                                                );
                                                            }}
                                                        />
                                                    }
                                                    label={
                                                        preFetchData?.userProfileDefs?.find(
                                                            (item: any) => item.id === profileDefId,
                                                        )?.label?.en ?? ''
                                                    }
                                                />
                                            )}
                                        />
                                    </Grid>
                                )}
                            {profileDefId &&
                                (profileValueType(preFetchData?.userProfileDefs, profileDefId) ===
                                    UserProfileValueType.Date ||
                                    profileValueType(
                                        preFetchData?.userProfileDefs,
                                        profileDefId,
                                    ) === UserProfileValueType.Dates) && (
                                    <Grid item xs={12}>
                                        <Controller
                                            control={control}
                                            name="dateType"
                                            render={({ field: { onChange }, field }) => (
                                                <Autocomplete
                                                    {...field}
                                                    size="small"
                                                    onChange={(_, val) => {
                                                        onChange(val);
                                                        setDateType(
                                                            val || InterventionDateType.Today,
                                                        );
                                                    }}
                                                    getOptionLabel={selected => selected}
                                                    options={dateTypeOptions()}
                                                    isOptionEqualToValue={(option, val) =>
                                                        option === val
                                                    }
                                                    value={dateType}
                                                    renderInput={params => (
                                                        <TextField
                                                            variant="outlined"
                                                            margin="dense"
                                                            // eslint-disable-next-line react/jsx-props-no-spreading
                                                            {...params}
                                                            placeholder="Select Type"
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    )}
                                                />
                                            )}
                                        />
                                        {dateType === InterventionDateType.Fixed && (
                                            <Controller
                                                control={control}
                                                name="profileValue"
                                                render={({ field: { onChange }, field }) => (
                                                    <TextField
                                                        {...field}
                                                        variant="outlined"
                                                        type="date"
                                                        margin="dense"
                                                        value={
                                                            selectedValues?.date &&
                                                            formatDate(selectedValues?.date)
                                                        }
                                                        onChange={e => {
                                                            onChange(e.currentTarget.value);
                                                            setSelectedValues({
                                                                ...SelectedValuesDefaults,
                                                                date: `${e.currentTarget.value}T00:00:00.000Z`,
                                                            });
                                                        }}
                                                        fullWidth
                                                    />
                                                )}
                                            />
                                        )}
                                    </Grid>
                                )}
                        </>
                    )}
                    {(valueType === InterventionType.SetProfileChoice ||
                        valueType === InterventionType.ClearProfileChoice) && (
                        <>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="profileDefId"
                                    defaultValue=""
                                    render={({ field: { onChange }, field }) => (
                                        <Autocomplete
                                            {...field}
                                            size="small"
                                            onChange={(_, val) => {
                                                onChange(val);
                                                setProfileDefId(val);
                                            }}
                                            options={
                                                preFetchData?.userProfileDefs
                                                    ?.filter(
                                                        def =>
                                                            def.valueType ===
                                                            UserProfileValueType.Choices,
                                                    )
                                                    .map((item: any) => item.id) ?? []
                                            }
                                            getOptionLabel={id =>
                                                preFetchData?.userProfileDefs?.find(
                                                    (item: any) => item.id === id,
                                                )?.name ?? ''
                                            }
                                            defaultValue={profileDefId}
                                            isOptionEqualToValue={(option, val) => option === val}
                                            renderInput={params => (
                                                <TextField
                                                    variant="outlined"
                                                    margin="dense"
                                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                                    {...params}
                                                    placeholder="Select Profile Definition"
                                                    label="Select Profile Definition"
                                                    InputLabelProps={{ shrink: true }}
                                                />
                                            )}
                                        />
                                    )}
                                />
                            </Grid>
                            {profileDefId &&
                                preFetchData?.userProfileDefs
                                    ?.find((profileDef: any) => profileDef.id === profileDefId)
                                    ?.choices?.map(item => (
                                        <Grid item xs={12}>
                                            <Controller
                                                control={control}
                                                name="profileValue"
                                                render={({ field: { onChange }, field }) => (
                                                    <FormControlLabel
                                                        control={
                                                            <Checkbox
                                                                {...field}
                                                                checked={isItemChecked(item)}
                                                                color="primary"
                                                                onChange={(_, val) => {
                                                                    onChange(val);
                                                                    updateChoices(item);
                                                                    setDateType(
                                                                        InterventionDateType.NotApplicable,
                                                                    );
                                                                }}
                                                            />
                                                        }
                                                        label={item?.label?.en}
                                                    />
                                                )}
                                            />
                                        </Grid>
                                    ))}
                        </>
                    )}
                    {valueType === InterventionType.ClearProfileValue && (
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="profileDefId"
                                defaultValue=""
                                render={({ field: { onChange }, field }) => (
                                    <Autocomplete
                                        {...field}
                                        size="small"
                                        onChange={(_, val) => {
                                            onChange(val);
                                            setProfileDefId(val);
                                        }}
                                        options={
                                            preFetchData?.userProfileDefs.map(item => item.id) ?? []
                                        }
                                        getOptionLabel={id =>
                                            preFetchData?.userProfileDefs?.find(
                                                (item: any) => item.id === id,
                                            )?.name ?? ''
                                        }
                                        defaultValue={profileDefId}
                                        isOptionEqualToValue={(option, val) => option === val}
                                        renderInput={params => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                placeholder="Select Profile Definition"
                                                label="Select Profile Definition"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {valueType === InterventionType.IncrementPv && (
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="profileDefId"
                                defaultValue=""
                                render={({ field: { onChange }, field }) => (
                                    <Autocomplete
                                        {...field}
                                        size="small"
                                        onChange={(_, val) => {
                                            onChange(val);
                                            setProfileDefId(val);
                                        }}
                                        options={
                                            preFetchData?.userProfileDefs
                                                .filter(
                                                    item =>
                                                        item.valueType === UserProfileValueType.Num,
                                                )
                                                .map(item => item.id) ?? []
                                        }
                                        getOptionLabel={id =>
                                            preFetchData?.userProfileDefs?.find(
                                                (item: any) => item.id === id,
                                            )?.name ?? ''
                                        }
                                        defaultValue={profileDefId}
                                        isOptionEqualToValue={(option, val) => option === val}
                                        renderInput={params => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                placeholder="Select Profile Definition"
                                                label="Select Profile Definition"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                )}
                            />
                            <TextField
                                variant="outlined"
                                type="number"
                                {...register('incrementValue')}
                                label="Value to add to PV (can be negative)"
                                fullWidth
                                margin="dense"
                                error={!!errors.incrementValue}
                                helperText={errors.incrementValue?.message}
                            />
                        </Grid>
                    )}
                    {valueType === InterventionType.MessagePatient && (
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="messageTemplateId"
                                defaultValue=""
                                render={({ field: { onChange }, field }) => (
                                    <Autocomplete
                                        {...field}
                                        size="small"
                                        onChange={(_, val) => {
                                            onChange(val);
                                            setMessageTemplateId(val);
                                        }}
                                        fullWidth
                                        options={
                                            _.sortBy(
                                                preFetchData?.messageTemplatesV2?.results,
                                                'name',
                                            ).map((item: any) => item.id) ?? []
                                        }
                                        getOptionLabel={id =>
                                            preFetchData?.messageTemplatesV2?.results?.find(
                                                (item: any) => item.id === id,
                                            )?.name ?? ''
                                        }
                                        isOptionEqualToValue={(option, val) => option === val}
                                        defaultValue={
                                            interventionData?.intervention?.messageTemplateId
                                        }
                                        renderInput={params => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                placeholder="Select Email Template"
                                                label="Select Email Template"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                )}
                            />
                            <OutlinedSection title="Communication Type">
                                <select
                                    value={messagePatientCommType || ''}
                                    onChange={event => {
                                        const {
                                            target: { value },
                                        } = event;
                                        if (value === 'none') {
                                            setMessagePatientCommType(null);
                                        } else {
                                            setMessagePatientCommType(
                                                event.target.value as CommType,
                                            );
                                        }
                                    }}
                                >
                                    <option key="none" value="none">
                                        none
                                    </option>
                                    {Object.values(CommType).map(type => (
                                        <option key={type} value={type}>
                                            {type}
                                        </option>
                                    ))}
                                </select>
                            </OutlinedSection>
                        </Grid>
                    )}
                    {(valueType === InterventionType.StartEpisode ||
                        valueType === InterventionType.EndEpisode) && (
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="conditionTypeId"
                                defaultValue=""
                                render={({ field: { onChange }, field }) => (
                                    <Autocomplete
                                        {...field}
                                        size="small"
                                        onChange={(_, val) => {
                                            onChange(val);
                                            setConditionTypeId(val);
                                        }}
                                        fullWidth
                                        options={
                                            _.sortBy(preFetchData?.conditionTypes, 'name.en').map(
                                                (item: any) => item.id,
                                            ) ?? []
                                        }
                                        getOptionLabel={id =>
                                            preFetchData?.conditionTypes?.find(
                                                (item: any) => item.id === id,
                                            )?.name.en ?? ''
                                        }
                                        isOptionEqualToValue={(option, val) => option === val}
                                        defaultValue={
                                            interventionData?.intervention?.conditionTypeId
                                        }
                                        renderInput={params => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                placeholder="Select Condition"
                                                label="Select Condition"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {valueType === InterventionType.SendMessageCenterTemplate && (
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="messageCenterTemplateId"
                                defaultValue=""
                                render={({ field: { onChange }, field }) => (
                                    <Autocomplete
                                        {...field}
                                        size="small"
                                        onChange={(_, val) => {
                                            onChange(val);
                                            setMessageCenterTemplateId(val);
                                        }}
                                        fullWidth
                                        options={
                                            preFetchData?.messageCenterTemplates?.map(
                                                (item: any) => item.id,
                                            ) ?? []
                                        }
                                        getOptionLabel={id =>
                                            preFetchData?.messageCenterTemplates?.find(
                                                (item: any) => item.id === id,
                                            )?.title ?? ''
                                        }
                                        isOptionEqualToValue={(option, val) => option === val}
                                        defaultValue={
                                            interventionData?.intervention?.messageCenterTemplateId
                                        }
                                        renderInput={params => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                placeholder="Select Message Center Template"
                                                label="Select Message Center Template"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {valueType === InterventionType.AdvocateTaskTemplate && (
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="advocateTaskTemplateId"
                                defaultValue=""
                                render={({ field: { onChange }, field }) => (
                                    <Autocomplete
                                        {...field}
                                        size="small"
                                        onChange={(_, val) => {
                                            onChange(val);
                                            setAdvocateTaskTemplateId(val);
                                        }}
                                        fullWidth
                                        options={
                                            advocateTaskTemplatesData?.advocateTaskTemplatesV2?.results.map(
                                                (item: any) => item.id,
                                            ) ?? []
                                        }
                                        getOptionLabel={id =>
                                            advocateTaskTemplatesData?.advocateTaskTemplatesV2?.results.find(
                                                (item: any) => item.id === id,
                                            )?.label ?? ''
                                        }
                                        isOptionEqualToValue={(option, val) => option === val}
                                        defaultValue={
                                            interventionData?.intervention?.advocateTaskTemplateId
                                        }
                                        renderInput={params => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                placeholder="Select Advocate Task Template"
                                                label="Select Advocate Task Template"
                                                InputLabelProps={{ shrink: true }}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    <Grid container item xs={12}>
                        <OutlinedSection title="App">
                            Selection type *
                            <select
                                value={clientSelectionType}
                                onChange={e =>
                                    setClientSelectionType(e.target.value as ClientSelectionType)
                                }
                            >
                                <option value={ClientSelectionType.IncludeAllApps}>
                                    Include All Apps
                                </option>
                                <option value={ClientSelectionType.IncludeCertainApps}>
                                    Include Some Apps
                                </option>
                                <option value={ClientSelectionType.ExcludeCertainApps}>
                                    Exclude Some Apps
                                </option>
                            </select>
                            {clientSelectionType === ClientSelectionType.IncludeCertainApps && (
                                <AutocompleteWithRecordOptions
                                    options={
                                        _.sortBy(
                                            preFetchData?.applicationsV2.results,
                                            'appBundleId',
                                        ) ?? []
                                    }
                                    valueKey="appBundleId"
                                    labelKey="appBundleId"
                                    control={control}
                                    name="includeApps"
                                    label="Include apps *"
                                    placeholder="Select apps to include..."
                                    required
                                    loading={!preFetchData?.applicationsV2.results}
                                    loadingText="Loading..."
                                    error={Boolean(errors.includeApps)}
                                    helperText={
                                        (errors.includeApps as FieldError | undefined)?.message
                                    }
                                />
                            )}
                            {clientSelectionType === ClientSelectionType.ExcludeCertainApps && (
                                <AutocompleteWithRecordOptions
                                    options={
                                        _.sortBy(
                                            preFetchData?.applicationsV2.results,
                                            'appBundleId',
                                        ) ?? []
                                    }
                                    valueKey="appBundleId"
                                    labelKey="appBundleId"
                                    control={control}
                                    name="excludeApps"
                                    label="Exclude apps *"
                                    placeholder="Select apps to exclude..."
                                    required
                                    loading={!preFetchData?.applicationsV2.results}
                                    loadingText="Loading..."
                                    error={Boolean(errors.excludeApps)}
                                    helperText={
                                        (errors.excludeApps as FieldError | undefined)?.message
                                    }
                                />
                            )}
                        </OutlinedSection>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button type="submit" color="secondary" variant="contained" startIcon={<Save />}>
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};

export default InterventionsModal;
