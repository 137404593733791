import React, { memo } from 'react';
import { Typography, TypographyProps } from '@mui/material';
import { PatientInsurancePlan, InsurancePlanType } from '~/schemaTypes';
import { getActiveInsurancePlanData } from '~/utils/lactationServicesEligibility';

type CoverageTypeProps = {
    insurancePlans?: (PatientInsurancePlan | null)[];
    activeInsurancePlan?: PatientInsurancePlan | null;
    styleProps?: TypographyProps;
};

const CoverageType: React.FC<CoverageTypeProps> = props => {
    const { insurancePlans, activeInsurancePlan, styleProps } = props;
    const insurancePlan = activeInsurancePlan ?? getActiveInsurancePlanData(insurancePlans);
    if (!insurancePlan) {
        return null;
    }
    let typeName;
    switch (insurancePlan.type) {
        case InsurancePlanType.Anthem:
            typeName = 'Anthem';
            break;
        case InsurancePlanType.Cigna:
            typeName = 'Cigna';
            break;
        default:
            typeName = '';
    }
    return <Typography {...styleProps}>{typeName}</Typography>;
};

export default memo(CoverageType);
