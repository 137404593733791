import * as Yup from 'yup';
import { ModifiedBenefitsType } from '../../../schemaTypes';

export const VC_GROUP_MODIFIED_BENEFITS_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Name is a required'),
    groupId: Yup.string().required('Group ID is a required field'),
    healthPlan: Yup.string().required('Health Plan is a required field'),
    type: Yup.string().required('Select one of the type options'),
    maxAllottedVisits: Yup.number()
        .min(1)
        .max(366)
        .when('type', ([type], schema) => {
            return type === ModifiedBenefitsType.ModifiedBenefits
                ? schema.required('Max Allotted Visits is a required field')
                : schema;
        }),
    isWhitelisted: Yup.boolean(),
});
