import EligibilityImportResultForm from '~/views/Dashboard/EligibilityImportResults/EligibilityImportResult/EligibilityImportResultForm/EligibilityImportResultForm';
import { useEligibilityImportResultForEligibilityImportQuery } from '~/schemaTypes';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import React from 'react';

const EligibilityImportResult = () => {
    const { id } = useParams<{ id: string }>();
    const { data, loading } = useEligibilityImportResultForEligibilityImportQuery({
        variables: {
            input: {
                id,
            },
        },
        fetchPolicy: 'network-only',
    });

    if (loading) {
        return <Loading />;
    }

    if (!data?.eligibilityImportResult?.data || !id) {
        return <div>Eligibility Config not found</div>;
    }

    return <EligibilityImportResultForm data={data.eligibilityImportResult.data} />;
};

export default EligibilityImportResult;
