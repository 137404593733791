import React, { useState, useMemo, useCallback } from 'react';
import { Controller, useForm } from 'react-hook-form';
import moment from 'moment';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, DialogActions, DialogContent, Grid, Typography } from '@mui/material';
import { DeleteForever } from '@mui/icons-material';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import DateInput from '~/components/DateInput/DateInput';
import AsyncActionButton from '~/components/AsyncActionButton/AsyncActionButton';
import ToggleButtonSwitch from '~/components/ToggleButtonSwitch/ToggleButtonSwitch';
import {
    useUpdateAwayModeDatesMutation,
    FetchCurrentUserForUseUserHookDocument,
    FetchVirtualCareAffiliateByIdForStatusesMenuDocument,
} from '~/schemaTypes';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';
import { AwayModeStatus } from './enums';
import { AWAY_MODE_FORM_SCHEMA } from './yupSchema';
import { AwayModeFormInput } from './types';
import { DATE_FORMAT } from './constants';
import { AwaySign, ScheduledSign } from './icons';

type VacationModeDialogProps = {
    mode: AwayModeStatus;
    onClose: () => void;
    startDate?: Date;
    endDate?: Date;
    affiliateId: string;
};

const AwayModeDialog: React.FC<VacationModeDialogProps> = props => {
    const { onClose, endDate, startDate, mode: initMode, affiliateId } = props;
    const { isAffiliateView } = useAffiliateCareView();
    const [showDateRange, setShowDateRange] = useState(
        [AwayModeStatus.scheduled, AwayModeStatus.vacation].includes(initMode),
    );
    const {
        control,
        formState: { errors, isDirty },
        watch,
        handleSubmit,
        setValue,
    } = useForm<AwayModeFormInput>({
        resolver: yupResolver(AWAY_MODE_FORM_SCHEMA() as any),
        defaultValues: {
            endDate: endDate ? moment(endDate).format(DATE_FORMAT) : '',
            mode: initMode,
            startDate: startDate
                ? moment(startDate).format(DATE_FORMAT)
                : moment().format(DATE_FORMAT),
        },
        context: {
            startDateHidden: !showDateRange,
        },
    });
    const refetchQueies = useMemo(() => {
        if (isAffiliateView) {
            return [
                {
                    query: FetchCurrentUserForUseUserHookDocument,
                },
            ];
        }
        return [
            {
                query: FetchVirtualCareAffiliateByIdForStatusesMenuDocument,
                variables: { input: { id: affiliateId } },
            },
        ];
    }, [isAffiliateView, affiliateId]);
    const mode = watch('mode');
    const [setAwayMode, { loading }] = useUpdateAwayModeDatesMutation({
        awaitRefetchQueries: true,
        refetchQueries: refetchQueies,
        onCompleted: data => {
            if (data.updateAwayModeDates?.success) {
                onClose();
            }
        },
    });
    const titleText = useMemo(() => {
        switch (mode) {
            case AwayModeStatus.vacation:
                return (
                    <>
                        <AwaySign />
                        &nbsp; Away
                    </>
                );
            case AwayModeStatus.scheduled:
                return (
                    <>
                        <ScheduledSign />
                        &nbsp; Scheduled
                    </>
                );
            default:
                return <>OFF</>;
        }
    }, [mode]);
    const showStartDate =
        (mode === AwayModeStatus.off && showDateRange) ||
        [AwayModeStatus.vacation, AwayModeStatus.scheduled].includes(mode);
    const showDateRangeToggle = mode === AwayModeStatus.off;
    const showDeleteButton = [AwayModeStatus.vacation, AwayModeStatus.scheduled].includes(mode);
    const saveButtonTitle = useMemo(() => {
        if (!showDateRange && mode === AwayModeStatus.off) {
            return 'Enable Away Mode';
        }
        if (mode === AwayModeStatus.off) {
            return 'Schedule Away Mode';
        }
        return 'Update Schedule';
    }, [mode, showDateRange]);
    const onSubmit = useCallback(
        (data: AwayModeFormInput) => {
            setAwayMode({
                variables: {
                    input: {
                        id: affiliateId,
                        startAwayModeDate: moment(data.startDate).local().format(DATE_FORMAT),
                        endAwayModeDate: moment(data.endDate).local().format(DATE_FORMAT),
                    },
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [affiliateId],
    );
    const onDelete = useCallback(() => {
        setAwayMode({
            variables: {
                input: {
                    id: affiliateId,
                    remove: true,
                },
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affiliateId]);
    return (
        <>
            <DialogTitleWithClose id="away-mode-dialog" onClose={onClose}>
                <Grid container rowSpacing={1}>
                    <Grid item>
                        <Typography variant="h6">Away Mode: {titleText}</Typography>
                    </Grid>
                    <Grid item>
                        <Typography variant="subtitle2" px={2}>
                            Sometimes you need to get away. We get it! When you enable away mode, we
                            will not route patients to you during the specified period of time.
                        </Typography>
                    </Grid>
                </Grid>
            </DialogTitleWithClose>
            <DialogContent dividers>
                <Grid container>
                    {showDateRangeToggle && (
                        <Grid item xs={12}>
                            <ToggleButtonSwitch
                                value={showDateRange}
                                onChange={(_, value) => {
                                    if (value !== null) {
                                        if (value) {
                                            setValue('startDate', '');
                                        } else {
                                            setValue('startDate', moment().format(DATE_FORMAT));
                                        }
                                        setShowDateRange(value);
                                    }
                                }}
                                buttons={[
                                    {
                                        label: 'Enable Immediately',
                                        value: false,
                                    },
                                    {
                                        label: 'Schedule Away Mode',
                                        value: true,
                                    },
                                ]}
                            />
                        </Grid>
                    )}
                    <Grid item xs={12} container>
                        {showStartDate && (
                            <Grid item xs={6}>
                                <Controller
                                    control={control}
                                    name="startDate"
                                    render={({ field }) => (
                                        <DateInput
                                            label="First Day Away"
                                            field={field}
                                            error={errors.startDate}
                                            inputProps={{
                                                fullWidth: true,
                                                InputLabelProps: {
                                                    shrink: true,
                                                },
                                            }}
                                        />
                                    )}
                                />
                            </Grid>
                        )}
                        <Grid item xs={6}>
                            <Controller
                                control={control}
                                name="endDate"
                                render={({ field }) => (
                                    <DateInput
                                        label="Last Day Away"
                                        field={field}
                                        error={errors.endDate}
                                        inputProps={{
                                            fullWidth: true,
                                            InputLabelProps: {
                                                shrink: true,
                                            },
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions>
                <Grid
                    container
                    spacing={2}
                    item
                    xs={12}
                    flexDirection="row"
                    justifyContent="space-between"
                >
                    <Grid item container spacing={1} xs="auto">
                        <Grid item>
                            <AsyncActionButton loading={loading}>
                                <Button
                                    variant="contained"
                                    onClick={handleSubmit(onSubmit)}
                                    color="primary"
                                    disabled={!isDirty || loading}
                                >
                                    {saveButtonTitle}
                                </Button>
                            </AsyncActionButton>
                        </Grid>
                        <Grid item>
                            <Button
                                variant="outlined"
                                onClick={onClose}
                                color="secondary"
                                disabled={loading}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                    <Grid container item justifyContent="flex-end" xs>
                        {showDeleteButton && (
                            <Grid item>
                                <AsyncActionButton loading={loading}>
                                    <Button
                                        variant="contained"
                                        onClick={onDelete}
                                        color="error"
                                        {...(mode === AwayModeStatus.scheduled && {
                                            startIcon: <DeleteForever />,
                                        })}
                                        disabled={loading}
                                    >
                                        {mode === AwayModeStatus.vacation && 'Turn Off Away Mode'}
                                        {mode === AwayModeStatus.scheduled && 'Remove Schedule'}
                                    </Button>
                                </AsyncActionButton>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </DialogActions>
        </>
    );
};

export default AwayModeDialog;
