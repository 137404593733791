import { Typography } from '@mui/material';
import React from 'react';
import { GetChatMessagesForPatientTranscriptQuery } from '~/schemaTypes';
import { JsonDialog } from '~/views/Dashboard/Patients/Patient/PatientTranscript/components/JsonDialog';

type ChatMessage = NonNullable<
    GetChatMessagesForPatientTranscriptQuery['getChatMessagesForPatientTranscript']
>[0];

export const ChatMessageCard = ({ chatMessage }: { chatMessage: ChatMessage }) => {
    return (
        <div className="shadow-[0_1px_5px_0_rgb(0_0_0_/_0.5)] rounded flex flex-col p-2">
            <div className="flex justify-between">
                <Typography variant="h6">Chat Message</Typography>
                <JsonDialog data={chatMessage} />
            </div>
            <p
                className="m-0"
                style={{ display: 'inline-block' }}
                // eslint-disable-next-line
                dangerouslySetInnerHTML={{ __html: chatMessage?.text || '' }}
            />
            <p className="m-0">
                Sent by: {chatMessage?.sentByPatient ? 'Patient' : `${chatMessage?.user?.name}`}
            </p>
            {!chatMessage?.sentByPatient && (
                <p className="m-0">
                    Patient read: {chatMessage?.readByPatient ? 'Read' : 'Unread'}
                </p>
            )}
        </div>
    );
};
