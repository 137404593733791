/* eslint-disable radix */
import {
    Badge,
    Checkbox,
    IconButton,
    TablePagination,
    Tooltip,
    Typography,
    Link,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { InfoOutlined, Warning, Restore } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import MaterialTable, { MaterialTableProps } from '@material-table/core';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import Loading from '~/components/Loading/Loading';
import { displayBirthday, displayDateWithAbbrTimeZone } from '~/helpers/dateHelpers';
import tableIcons from '~/helpers/tableIcons';
import useUserPermissions from '~/hooks/useUserPermissions';
import {
    AlertSeverity,
    FetchMeasurementsForMeasurementsPageQueryQuery,
    FetchMeasurementsForMeasurementsPageQueryQueryVariables,
    MeasurementType,
    ReferencePages,
    useFetchMeasurementsForMeasurementsPageQueryQuery,
    useUpdateMeasurementReviewedMutation,
    useFetchReferenceLinksForMeasurmentsPageQuery,
    MeasurementInputSource,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import { PAGESIZE } from '~/constants';
import { primaryColor } from '~/theme/WildTheme';
import WeightValue from '~/components/WeightValue/WeightValue';
import { FilterTrakerDataModal } from './FilterTrackerDataModal';
import { MeasurementHistoryModal } from './MeasurementHistoryModal';

type Measurement = NonNullable<
    NonNullable<
        NonNullable<FetchMeasurementsForMeasurementsPageQueryQuery>['measurementsTrackers']
    >['results'][0]
>;

type BloodPressureMeasurement = NonNullable<NonNullable<Measurement['value']>['bloodPressure']>;

type MeasurementsProps = {
    setLinks?: any;
};

const useStyles = makeStyles()(_theme => ({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
    searchChips: {
        padding: 0,
        display: 'flex',
        alignItems: 'center',
    },
    horizontalAlign: {
        display: 'flex',
        alignItems: 'center',
    },
}));

const Measurements: React.FC<MeasurementsProps> = ({ setLinks }) => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);

    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [openFilterModal, setOpenFilterModal] = useState<boolean>(false);
    const [openHistoryModal, setOpenHistoryModal] = useState<boolean>(false);
    const [updatingMeasurementId, setUpdatingMeasurementId] = useState<string>('');
    const [measurementHistoryId, setMeasurementHistoryId] = useState<string>('');
    const [updateMeasurementLoading, setUpdateMeasurementLoading] = useState<boolean>(false);
    const filterTestData = localStorage.getItem('filterTrackerTestData') === 'true' || false;

    const [updateMeasurement] = useUpdateMeasurementReviewedMutation();

    const filteredType = localStorage.getItem('filteredTrackerType') as MeasurementType;
    const filteredSource = localStorage.getItem('filteredTrackerSource') as MeasurementInputSource;

    const fetchMeasurementsForMeasurementsPageQueryVariables: FetchMeasurementsForMeasurementsPageQueryQueryVariables =
        {
            input: {
                skip: page * rowsPerPage,
                limit: rowsPerPage,
                filterTestData,
                type: filteredType,
                source: filteredSource,
            },
        };

    const {
        data: measurementsData,
        loading: measurementsLoading,
        refetch,
    } = useFetchMeasurementsForMeasurementsPageQueryQuery({
        variables: fetchMeasurementsForMeasurementsPageQueryVariables,
        fetchPolicy: 'network-only',
    });

    const { data: referenceLinksData, loading: referenceLinksLoading } =
        useFetchReferenceLinksForMeasurmentsPageQuery({
            variables: {
                input: {
                    page: ReferencePages.Measurements,
                },
            },
        });

    useEffect(() => {
        if (referenceLinksData && setLinks) {
            setLinks(referenceLinksData.getReferenceLinksForPage);
        }
    }, [referenceLinksData, setLinks]);

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };
    const handleFilterSave = () => {
        setPage(0);
        setOpenFilterModal(false);
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const checkMeasurmentItem = useCallback(
        async (reviewed: boolean, id: string) => {
            setUpdatingMeasurementId(id);
            setUpdateMeasurementLoading(true);
            await updateMeasurement({
                variables: {
                    input: {
                        id,
                        reviewed: !reviewed,
                    },
                },
            });
            await refetch();
            setUpdateMeasurementLoading(false);
        },
        [refetch, updateMeasurement],
    );

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Tracker Data"
                isLoading={measurementsLoading || referenceLinksLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        tooltip: 'Filter',
                        onClick: () => {
                            setOpenFilterModal(true);
                        },
                        icon: () => (
                            <Badge
                                invisible={
                                    parseInt(
                                        localStorage.getItem('filtersTrackerAppliedCount') ?? '',
                                        10,
                                    ) === 0
                                }
                                badgeContent={localStorage.getItem('filtersTrackerAppliedCount')}
                                color="error"
                                style={{
                                    padding: '12px',
                                    backgroundColor: primaryColor,
                                    color: 'white',
                                    fontSize: '1rem',
                                    borderRadius: '1rem',
                                }}
                            >
                                Filter
                            </Badge>
                        ),
                        isFreeAction: true,
                    },
                ]}
                columns={[
                    {
                        title: 'Reviewed',
                        field: 'reviewed',
                        align: 'center',
                        sorting: false,
                        render: ({ reviewed, id }) =>
                            updatingMeasurementId === id && updateMeasurementLoading ? (
                                <Loading height={50} />
                            ) : (
                                <Checkbox
                                    checked={reviewed ?? false}
                                    disabled={!pagePermissions?.Measurements.Edit}
                                    onChange={() => checkMeasurmentItem(reviewed, id)}
                                />
                            ),
                    },
                    {
                        title: 'Id',
                        field: 'id',
                        align: 'center',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Measurement Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Name',
                        field: 'patient.fullName',
                        align: 'left',
                        sorting: false,
                        render: (
                            { patient }: any, // TODO: check this any
                        ) => (
                            <Tooltip title="View Patient">
                                <Link
                                    style={{ textDecoration: 'underline' }}
                                    target="_blank"
                                    href={`/portal/patients/${patient?.id}/patient-details`}
                                    rel="noopener noreferrer"
                                >
                                    {patient.fullName}
                                </Link>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'External Id',
                        field: 'externalId',
                        align: 'center',
                        sorting: false,
                        render: ({ patient }) => {
                            if (patient?.externalId) {
                                return (
                                    <Tooltip title={patient?.externalId ?? ''}>
                                        <IconButton
                                            onClick={() => {
                                                copy(`${patient?.externalId}`);
                                                TriggerGlobalAlert({
                                                    message: 'External Id Copied to Clipboard',
                                                    severity: AlertSeverity.Success,
                                                });
                                            }}
                                            size="large"
                                        >
                                            <InfoOutlined />
                                        </IconButton>
                                    </Tooltip>
                                );
                            }
                            return '';
                        },
                    },
                    {
                        title: 'DOB',
                        field: 'patient.birthDate',
                        render: ({ patient }) => {
                            return (
                                <Typography>
                                    {patient?.birthDate &&
                                        displayBirthday({ isoDateStr: patient?.birthDate })}
                                </Typography>
                            );
                        },
                        sorting: false,
                    },
                    {
                        title: 'Type',
                        field: 'type',
                        sorting: false,
                    },
                    {
                        title: 'Date of Test',
                        render: ({ takenDate, patient, timezoneName }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: takenDate,
                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                    timeZone:
                                        timezoneName ??
                                        patient?.practice.timezone ??
                                        Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        sorting: false,
                    },
                    {
                        title: 'Date of Report',
                        render: ({ updatedAt }) => (
                            <Typography>
                                {displayDateWithAbbrTimeZone({
                                    isoDateStr: updatedAt,
                                    format: 'MM/DD/yyyy, hh:mm:ss A',
                                    timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                                })}
                            </Typography>
                        ),
                        sorting: false,
                    },
                    {
                        title: 'Flagged',
                        render: ({ flagged }) => {
                            if (flagged) {
                                return <Warning />;
                            }
                            return <div />;
                        },
                        sorting: false,
                    },
                    { title: 'Device', field: 'device.localizedName', sorting: false },
                    {
                        title: 'Input Type',
                        field: 'inputType',
                        sorting: false,
                    },
                    {
                        title: 'Comment',
                        field: 'comment',
                        sorting: false,
                    },
                    {
                        title: 'Value',
                        sorting: false,
                        render: ({ type, value, externalId, hasHistory }) => {
                            const measurementBlock = () => {
                                switch (type) {
                                    case MeasurementType.Movement:
                                        return (
                                            <Typography>
                                                {' '}
                                                {value?.movement?.duration} (hr:min:sec)
                                            </Typography>
                                        );
                                    case MeasurementType.BloodPressure:
                                        if (value?.bloodPressure) {
                                            const { systolic, diastolic, pulse } =
                                                value.bloodPressure as BloodPressureMeasurement;
                                            return (
                                                <Typography>
                                                    {systolic &&
                                                        diastolic &&
                                                        `${systolic}/${diastolic} mmHg`}
                                                    {systolic && diastolic && pulse && <br />}
                                                    {pulse && `${pulse} bpm`}
                                                </Typography>
                                            );
                                        }
                                        break;
                                    case MeasurementType.Weight: {
                                        if (value?.weight?.measure) {
                                            return (
                                                <WeightValue
                                                    value={value.weight.measure}
                                                    showUnit
                                                />
                                            );
                                        }
                                        return <div />;
                                    }
                                    case MeasurementType.BloodGlucose: {
                                        if (value?.bloodGlucose?.glucose) {
                                            return (
                                                <Typography>
                                                    {' '}
                                                    {`${value?.bloodGlucose?.glucose} mg/dL`}
                                                </Typography>
                                            );
                                        }
                                        return <div />;
                                    }
                                    case MeasurementType.DailyMeasurement: {
                                        const dailyMeasurement = `Glucose: ${value?.dailyMeasurement?.glucose}, Mood Level:
                                                ${value?.dailyMeasurement?.moodLevel}, Pain Level:
                                                ${value?.dailyMeasurement?.painLevel}, Steps:
                                                ${value?.dailyMeasurement?.steps}, Temperature:
                                                ${value?.dailyMeasurement?.temperature} C, Start of
                                                sleep: ${value?.dailyMeasurement?.sleepStarts}, End of
                                                sleep: ${value?.dailyMeasurement?.sleepEnds}, Notes:
                                                ${value?.dailyMeasurement?.notes}`;
                                        return (
                                            <Typography>
                                                <Tooltip title={dailyMeasurement ?? ''}>
                                                    <IconButton size="large">
                                                        <InfoOutlined />
                                                    </IconButton>
                                                </Tooltip>
                                            </Typography>
                                        );
                                    }
                                    default:
                                        return <div />;
                                }
                                return <div />;
                            };
                            return (
                                <div className={classes.horizontalAlign}>
                                    {measurementBlock()}
                                    {hasHistory && (
                                        <Tooltip title="History">
                                            <IconButton
                                                onClick={() => {
                                                    setMeasurementHistoryId(externalId);
                                                    setOpenHistoryModal(true);
                                                }}
                                            >
                                                <Restore />
                                            </IconButton>
                                        </Tooltip>
                                    )}
                                </div>
                            );
                        },
                    },
                    { title: '', field: 'patient.id', hidden: true },
                ]}
                data={measurementsData?.measurementsTrackers?.results ?? []}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={measurementsData?.measurementsTrackers?.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    search: false,
                    exportAllData: pagePermissions?.Measurements.Export,
                    paginationPosition: 'both',
                }}
            />
            <FilterTrakerDataModal
                open={openFilterModal}
                onClose={() => setOpenFilterModal(false)}
                onSave={handleFilterSave}
            />
            <MeasurementHistoryModal
                open={openHistoryModal}
                onClose={() => setOpenHistoryModal(false)}
                id={measurementHistoryId}
            />
        </div>
    );
};

export default Measurements;
