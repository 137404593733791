import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Add, InfoOutlined } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { PAGESIZE } from '~/constants';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import { AlertSeverity, AudienceListQuery, useAudienceListQuery } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';
import { IconButton, Tooltip } from '@mui/material';
import copy from 'copy-to-clipboard';
import { TriggerGlobalAlert } from '~/state';
import { useStyles } from './styles';
import { AudienceModal } from './AudienceModal';

type Audience = NonNullable<AudienceListQuery['audiences'][0]>;

const AudienceList: React.FC = () => {
    const { classes } = useStyles();
    const { data, loading } = useAudienceListQuery();
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [selectedAudience, setSelectedAudience] = useState<Audience | null>(null);
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    if (loading) {
        return <Loading />;
    }
    return (
        <>
            <div className={classes.root} ref={tableRef}>
                <MaterialTable<Audience>
                    title="Audiences"
                    icons={tableIcons}
                    data={_.cloneDeep(data?.audiences) ?? []}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    actions={[
                        {
                            onClick: () => setModalOpen(true),
                            icon: () => <Add />,
                            tooltip: 'Add Audience',
                            isFreeAction: true,
                            hidden: !pagePermissions?.Audience.Edit,
                        },
                        {
                            onClick: (_, item) => {
                                const isSingleItemSelected = !Array.isArray(item);
                                if (isSingleItemSelected) {
                                    setModalOpen(true);
                                    setSelectedAudience(item);
                                }
                            },
                            icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                            tooltip: 'Edit Audience',
                            hidden: !pagePermissions?.Audience.Edit,
                        },
                    ]}
                    columns={[
                        {
                            title: 'Id',
                            field: 'id',
                            sorting: false,
                            render: ({ id }) => (
                                <Tooltip title={id}>
                                    <IconButton
                                        onClick={() => {
                                            copy(`${id}`);
                                            TriggerGlobalAlert({
                                                message: 'Audience Id copied to clipboard',
                                                severity: AlertSeverity.Success,
                                            });
                                        }}
                                        size="large"
                                    >
                                        <InfoOutlined />
                                    </IconButton>
                                </Tooltip>
                            ),
                        },
                        {
                            title: 'Name',
                            field: 'name',
                        },
                        {
                            title: 'Description',
                            field: 'description',
                        },
                    ]}
                    options={{ pageSize: rowsPerPage }}
                    localization={{ header: { actions: '' } }}
                />
            </div>
            <AudienceModal
                isOpen={isModalOpen}
                item={selectedAudience || null}
                onClose={() => {
                    setModalOpen(false);
                    setSelectedAudience(null);
                }}
            />
        </>
    );
};

export default AudienceList;
