import { yupResolver } from '@hookform/resolvers/yup';
import { Grid } from '@mui/material';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { FormProvider, useForm as useFormReact } from 'react-hook-form';
import Loading from '~/components/Loading/Loading';
import {
    AlertSeverity,
    DestinationNames,
    InsurancePlan,
    useCheckEligibilityVirtualCareFormMutation,
    useCreateOrUpdateVirtualCareFormMutation,
    useVcfAtHomeAvailableLazyQuery,
} from '~/schemaTypes';
import { SuppressNextGlobalAlert, TriggerGlobalAlert, TriggerGlobalBanner } from '~/state';
import SuccessScreen from '~/views/VirtualCareSurvey/steps/SuccessScreen';
import VirtualCareSurveyWrapper from './components/VirtualCareSurveyWrapper';
import {
    DOULA_ELIGIBILITY_DENIED_MSG,
    GENERAL_ELIGIBILITY_DENIED_MSG,
    OUT_OF_NETWORK_ELIGIBILITY_DENIED_MSG,
} from './constants';
import { isOlderThanTwoYears, preparePayload } from './helpers';
import useForm from './hooks/useForm';
import { useVCFContext } from './hooks/useVCF';
import useVCFRouter from './hooks/useVCFRouter';
import AffiliateThank from './steps/AffiliateThank';
import Confirmation from './steps/Confirmation';
import NewAppointmentMethod from './steps/NewAppointmentMethod';
import PacifyConfirmation from './steps/PacifyConfirmation';
import PatientInfo from './steps/PatientInfo';
import PatientInfoContact from './steps/PatientInfoContact';
import useStyles from './styles';
import { Steps, VirtualCareSurveyInput } from './types';
import VirtualCareSurveySchemas from './yupSchema';

SuppressNextGlobalAlert(true);

const defaultStepState = {
    [Steps.PATIENT_INFO]: false,
    [Steps.PATIENT_INFO_CONTACT]: false,
    [Steps.APPOINTMENT_METHOD]: false,
    [Steps.CONFIRMATION]: true,
    [Steps.PACIFY_CONFIRMATION]: true,
    [Steps.AFFILIATE_THANK]: true,
    [Steps.SUCCESS_SCREEN]: false,
};

type VirtualCareSurveyProps = {
    baseUrl?: string;
    pathname: string;
    header?: React.ReactNode;
};

const isEligibleForBreastPump = (selfCheck: boolean, data: VirtualCareSurveyInput) => {
    return (
        !selfCheck && !data.breastPump && data.sex !== 'male' && isOlderThanTwoYears(data.birthDate)
    );
};

export const VirtualCareSurvey: React.FC<VirtualCareSurveyProps> = props => {
    const { baseUrl, pathname, header } = props;
    const { classes } = useStyles();
    const {
        isAffiliateView,
        isDoulaView,
        step,
        showLoading,
        setShowLoading,
        showFormDescription,
        formDescriptionText,
        affiliateId,
    } = useVCFContext();
    const {
        setStepHandler,
        selfCheck,
        setValidSteps,
        validSteps,
        predefinedFirstName,
        predefinedLastName,
        getNextStep,
        formSteps,
        isFormStep,
        setSearchParams,
        affililateIdApta,
    } = useVCFRouter({ pathname, baseUrl });
    const isApta = Boolean(affililateIdApta);
    const [eligibilityRequestId, setEligibilityRequestId] = useState<string | null>(null);
    const [eligibilityGroupNumber, setEligibilityGroupNumber] = useState<string | null>(null);
    const [insurancePlan, setInsurancePlan] = useState<InsurancePlan | null>(null);
    const [patientId, setPatientId] = useState<string>();
    const [selfSelected, setSelfSelected] = useState<boolean>(true);
    const [customSuccessText, setCustomSuccessText] = useState<string>();
    const [fetchAtHomeOptionEnabled, { loading: settingsLoading }] = useVcfAtHomeAvailableLazyQuery(
        {
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
        },
    );
    const formContainer = useRef<HTMLDivElement>(null);
    const formProps = useFormReact<VirtualCareSurveyInput>({
        resolver: yupResolver(VirtualCareSurveySchemas(isAffiliateView, selfCheck)[step] as any),
        defaultValues: {
            insuredAddressSameAsPatient: true,
            state: '',
            insuredState: '',
            babyMultiples: undefined,
            babyArrived: undefined,
        },
    });
    const { handleSubmit, setValue, watch, reset } = formProps;
    const [zipCode, insuredAddressSameAsPatient] = watch([
        'zipCode',
        'insuredAddressSameAsPatient',
    ]);
    useForm({ formProps, selfSelected });

    /* Queries */
    const [createOrUpdate, { loading: createVCFLoading }] =
        useCreateOrUpdateVirtualCareFormMutation({
            onCompleted: data => {
                if (data.createOrUpdateVirtualCareForm?.data) {
                    const { firstName, lastName, email, birthDate, patientId, Destination } =
                        data.createOrUpdateVirtualCareForm.data;
                    if (!firstName || !lastName || !email || !patientId || !birthDate) {
                        TriggerGlobalAlert({
                            severity: AlertSeverity.Error,
                            message: 'Something went wrong. Please try again.',
                        });
                        return;
                    }
                    const { redirectUrl, successMessage } = data.createOrUpdateVirtualCareForm;
                    setPatientId(patientId);
                    if (Destination === DestinationNames.HubspotDoula && redirectUrl) {
                        window.location.href = redirectUrl;
                    } else if (Destination === DestinationNames.WIldflower && redirectUrl) {
                        window.location.href = redirectUrl;
                    } else if (Destination === DestinationNames.RouteToSuccessScreen) {
                        setCustomSuccessText(successMessage || '');
                        setStepHandler(Steps.SUCCESS_SCREEN);
                        setShowLoading(false);
                        reset();
                    } else if (Destination === DestinationNames.Affiliate) {
                        setStepHandler(Steps.AFFILIATE_THANK);
                        setShowLoading(false);
                        reset();
                    } else if (Destination === redirectUrl && redirectUrl != null) {
                        window.location.href = redirectUrl;
                    } else {
                        setShowLoading(false);
                    }
                }
            },
            onError: error => {
                setShowLoading(false);
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
        });

    const [checkEligibility, { loading: checkEligibilityLoading }] =
        useCheckEligibilityVirtualCareFormMutation({
            onCompleted: data => {
                if (data.checkVCFEligibility?.isEligible) {
                    if (data.checkVCFEligibility.insurancePlan) {
                        setInsurancePlan(data.checkVCFEligibility.insurancePlan);
                    }
                    setEligibilityRequestId(data.checkVCFEligibility?.resultId ?? null);
                    setEligibilityGroupNumber(data.checkVCFEligibility.groupNumber ?? null);
                    setValidSteps(prev => ({
                        ...prev,
                        [Steps.PATIENT_INFO]: true,
                    }));
                    const nextStep = getNextStep(Steps.PATIENT_INFO);
                    if (nextStep !== false) {
                        setStepHandler(nextStep);
                    }
                } else if (data.checkVCFEligibility?.message === 'Eligibility failed') {
                    TriggerGlobalBanner({
                        open: true,
                        severity: AlertSeverity.Error,
                        actionText: '',
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        callback: () => {},
                        message: isDoulaView
                            ? DOULA_ELIGIBILITY_DENIED_MSG
                            : GENERAL_ELIGIBILITY_DENIED_MSG,
                    });
                } else if (data.checkVCFEligibility?.message === 'Group Out of Network') {
                    TriggerGlobalBanner({
                        open: true,
                        severity: AlertSeverity.Error,
                        actionText: '',
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        callback: () => {},
                        message: OUT_OF_NETWORK_ELIGIBILITY_DENIED_MSG,
                    });
                } else if (data.checkVCFEligibility?.message) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message: data.checkVCFEligibility?.message,
                    });
                }
            },
        });

    /* CallBacks */
    const firstStepHandler = useCallback(
        (data: VirtualCareSurveyInput) => {
            SuppressNextGlobalAlert(true);
            const affiliateData = { id: affiliateId, selfCheck };
            const payload = preparePayload({
                formData: data,
                insuredAddressSameAsPatient,
                isAffiliateView,
                isDoulaView,
                affiliateData,
            });
            checkEligibility({
                variables: {
                    input: payload,
                },
            });
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [insuredAddressSameAsPatient, affiliateId, selfCheck, isAffiliateView, isDoulaView],
    );

    const showFillPrevStepAlert = useCallback(() => {
        TriggerGlobalAlert({
            severity: AlertSeverity.Error,
            message: 'Please fill the previous step. Click on "Back" button',
        });
    }, []);

    const thankBackHandler = useCallback(() => {
        setSearchParams(state => {
            state.set('firstName', '');
            state.set('lastName', '');
            return state;
        });
        setStepHandler(Steps.PATIENT_INFO);
        setValidSteps(defaultStepState);
        setPatientId('');
        setEligibilityRequestId('');
        setEligibilityGroupNumber(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    /* Effects */
    useEffect(() => {
        formContainer?.current?.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }, [step]);

    useEffect(() => {
        if (createVCFLoading) {
            setShowLoading(true);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [createVCFLoading]);

    useEffect(() => {
        setValue('firstName', predefinedFirstName);
        setValue('lastName', predefinedLastName);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [predefinedLastName, predefinedFirstName]);

    /* Submit Handler */
    const onSubmit = (formData: VirtualCareSurveyInput, skipValidationCheck?: boolean) => {
        if (!skipValidationCheck && formSteps.filter(s => !validSteps[s]).length > 0) {
            showFillPrevStepAlert();
            return;
        }
        let affiliateIdForData = affiliateId ?? formData.affiliate;
        if (
            !affiliateIdForData &&
            formData.sex !== 'Male' &&
            isOlderThanTwoYears(formData.birthDate)
        ) {
            affiliateIdForData = affililateIdApta ?? undefined;
        }

        const affiliateData = {
            id: affiliateIdForData,
            affiliateIdApta: affililateIdApta ?? undefined,
            selfCheck,
        };
        const eligibilityData = {
            eligibilityRequestId,
            eligibilityGroupNumber,
        };
        const payload = preparePayload({
            formData,
            insurancePlan,
            insuredAddressSameAsPatient,
            isApta,
            isDoulaView,
            isAffiliateView,
            eligibilityData,
            affiliateData,
        });
        SuppressNextGlobalAlert(true);
        createOrUpdate({
            variables: {
                input: payload,
            },
        });
    };

    /* Second Step Handlers */
    const secondStepHandler = async (data: VirtualCareSurveyInput) => {
        if (!validSteps[Steps.PATIENT_INFO]) {
            showFillPrevStepAlert();
            return;
        }
        setValidSteps(prev => ({
            ...prev,
            [Steps.PATIENT_INFO_CONTACT]: true,
        }));
        const result = await fetchAtHomeOptionEnabled({
            variables: {
                input: {
                    zipCode,
                },
            },
        });
        const nextStep = getNextStep(Steps.PATIENT_INFO_CONTACT, {
            atHomeOptionEnabled: result.data?.VCFAtHomeAvailable?.available,
        });

        if (isEligibleForBreastPump(selfCheck, data)) {
            data.shouldSendBreastPumpEmail = true;
        }

        if (nextStep !== false) {
            setStepHandler(nextStep);
        } else {
            onSubmit(data, true);
        }
    };

    /* Third Step Handlers */
    const thirdStepHandler = (data: VirtualCareSurveyInput) => {
        if (!validSteps[Steps.PATIENT_INFO] || !validSteps[Steps.PATIENT_INFO_CONTACT]) {
            showFillPrevStepAlert();
            return;
        }
        setValidSteps(prev => ({
            ...prev,
            [Steps.APPOINTMENT_METHOD]: true,
        }));

        if (isEligibleForBreastPump(selfCheck, data)) {
            data.shouldSendBreastPumpEmail = true;
        }

        onSubmit(data, true);
    };

    if (showLoading) {
        return <Loading />;
    }

    return (
        <VirtualCareSurveyWrapper
            header={header}
            showFormDescription={showFormDescription}
            formDescriptionText={formDescriptionText}
        >
            {isFormStep && (
                <Grid item xs={12}>
                    <FormProvider {...formProps}>
                        <form className={classes.fullHeight}>
                            <Grid
                                className={classes.fullHeight}
                                alignItems="flex-start"
                                container
                                rowSpacing={{ md: 1, xs: 0 }}
                            >
                                {step === Steps.PATIENT_INFO && (
                                    <PatientInfo
                                        stepHandler={handleSubmit(firstStepHandler)}
                                        setSelfSelected={setSelfSelected}
                                        stepLoading={checkEligibilityLoading}
                                        selfSelected={selfSelected}
                                        selfCheck={selfCheck}
                                    />
                                )}
                                {step === Steps.PATIENT_INFO_CONTACT && (
                                    <PatientInfoContact
                                        backHandler={() => setStepHandler(Steps.PATIENT_INFO)}
                                        stepHandler={handleSubmit(secondStepHandler)}
                                        stepLoading={showLoading || settingsLoading}
                                        selfCheck={selfCheck}
                                    />
                                )}
                                {step === Steps.APPOINTMENT_METHOD && (
                                    <NewAppointmentMethod
                                        backHandler={() =>
                                            setStepHandler(Steps.PATIENT_INFO_CONTACT)
                                        }
                                        stepHandler={handleSubmit(thirdStepHandler)}
                                        stepLoading={showLoading}
                                    />
                                )}
                            </Grid>
                        </form>
                    </FormProvider>
                </Grid>
            )}
            {step === Steps.CONFIRMATION && <Confirmation classes={classes} />}
            {step === Steps.PACIFY_CONFIRMATION && (
                <PacifyConfirmation backHandler={() => setStepHandler(Steps.APPOINTMENT_METHOD)} />
            )}
            {step === Steps.AFFILIATE_THANK && patientId && (
                <AffiliateThank backHandler={thankBackHandler} patientId={patientId} />
            )}
            {step === Steps.SUCCESS_SCREEN && (
                <SuccessScreen customSuccessText={customSuccessText} patientId={patientId ?? ''} />
            )}
        </VirtualCareSurveyWrapper>
    );
};
