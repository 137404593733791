import React from 'react';
import VCInvoice from '~/views/AffiliateCare/Invoices/Invoice/Invoice';
import { useVcUserView } from '~/hooks';

const Invoice = () => {
    const { getUrlWithViewPrefix } = useVcUserView();
    return (
        <VCInvoice
            patientRedirectUrl={getUrlWithViewPrefix('/portal/patients')}
            invoicesRedirectUrl={getUrlWithViewPrefix('/portal/invoices')}
        />
    );
};

export default Invoice;
