import { Grid, MenuItem, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    OnSuccessfulEligibility,
    OnSuccessfulZipCodeMatch,
    VirtualCareAffiliatesEntity,
} from '~/schemaTypes';
import PreviewSuccessScreen from '~/views/AffiliateCare/Onboarding/steps/Routing/components/PreviewSuccessScreen';
import { eligibilitySuccessText, zipCodeSuccessText, zipCodeWithUrlSuccessText } from '../helpers';
import Editor from './Editor';

const urlPattern = /^(http:\/\/|https:\/\/)/;

const useStyles = makeStyles()(() => ({
    select: {
        '.MuiSelect-select': {
            whiteSpace: 'break-spaces !important',
        },
    },
    menuItem: {
        whiteSpace: 'break-spaces !important',
    },
}));

type RoutingFormProps = {
    affiliate: Pick<
        VirtualCareAffiliatesEntity,
        'externalName' | 'supportPhone' | 'contactName' | 'supportEmail' | 'website'
    > | null;
    disabled?: boolean;
    showPreview?: boolean;
    showZipCode?: boolean;
    watchSupportData?: boolean;
};

const replacePlaceholders = (
    message: string,
    affiliate?: Pick<
        VirtualCareAffiliatesEntity,
        'supportPhone' | 'supportEmail' | 'website'
    > | null,
) => {
    if (!affiliate || !message) {
        return message;
    }

    let newStr = message.replace(/\\n/g, '<br/>');

    const placeholders = [
        '{AffiliateWebsite}',
        '{AffiliatePhoneNumber}',
        '{AffiliateSupportEmail}',
    ];
    const replacers = [
        affiliate.website || '',
        affiliate.supportPhone || '',
        affiliate.supportEmail || '',
    ];

    placeholders.forEach((placeholder, index) => {
        newStr = newStr.replaceAll(placeholder, replacers[index]);
    });

    return newStr;
};

const RoutingForm: React.FC<RoutingFormProps> = props => {
    const {
        affiliate,
        disabled = true,
        showPreview = true,
        showZipCode = false,
        watchSupportData = false,
    } = props;
    const {
        control,
        formState: { errors, defaultValues },
        resetField,
        watch,
        setValue,
        setError,
        clearErrors,
    } = useFormContext();
    const { classes } = useStyles();
    const [displayCustomSuccessText, setDisplayCustomSuccessText] = useState('');
    const [displayZipCodeMatchCustomSuccessString, setDisplayZipCodeMatchCustomSuccessString] =
        useState('');
    const [editorInitContent, setEditorInitContent] = useState(
        defaultValues?.zipCodeMatchCustomSuccessString || '',
    );

    const clearDependentValues = () => {
        if (!disabled) {
            resetField('customSuccessText', { defaultValue: '' });
            resetField('routingURL', { defaultValue: '' });
        }
    };
    const onSuccessfulEligibilityWatch = watch('onSuccessfulEligibility');
    const onSuccessfulZipCodeMatch = watch('onSuccessfulZipCodeMatch');
    const customSuccessText = watch('customSuccessText');
    const routingUrlWatch = watch('routingURL');
    const zipCodeCustomMessage = watch('zipCodeMatchCustomSuccessString');

    useEffect(() => {
        if (!defaultValues?.zipCodeMatchCustomSuccessString && showZipCode && affiliate) {
            if (onSuccessfulEligibilityWatch === OnSuccessfulEligibility.RouteToLanding) {
                setEditorInitContent(zipCodeSuccessText(affiliate));
            } else if (onSuccessfulEligibilityWatch === OnSuccessfulEligibility.RouteDirectly) {
                setEditorInitContent(
                    zipCodeWithUrlSuccessText({
                        ...affiliate,
                        routingURL: routingUrlWatch,
                    }),
                );
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSuccessfulEligibilityWatch, affiliate, routingUrlWatch]);

    useEffect(() => {
        if (
            onSuccessfulEligibilityWatch === OnSuccessfulEligibility.RouteDirectly &&
            routingUrlWatch &&
            !urlPattern.test(routingUrlWatch)
        ) {
            setError('routingURL', {
                type: 'manual',
                message:
                    'Please enter a URL starting with either `http://` or `https://`  at the beginning of the URL.',
            });
        } else {
            clearErrors('routingURL');
        }
    }, [clearErrors, onSuccessfulEligibilityWatch, routingUrlWatch, setError]);

    useEffect(() => {
        if (showZipCode && defaultValues?.zipCodeMatchCustomSuccessString) {
            setEditorInitContent(defaultValues.zipCodeMatchCustomSuccessString);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [defaultValues?.zipCodeMatchCustomSuccessString]);

    useEffect(() => {
        if (
            onSuccessfulEligibilityWatch === OnSuccessfulEligibility.RouteToLanding &&
            (!defaultValues?.customSuccessText || watchSupportData)
        ) {
            setValue('customSuccessText', eligibilitySuccessText(affiliate));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [onSuccessfulEligibilityWatch, affiliate, watchSupportData]);

    useEffect(() => {
        if (showZipCode) {
            setValue('zipCodeMatchCustomSuccessString', editorInitContent);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editorInitContent]);

    useEffect(() => {
        const updatedText = replacePlaceholders(customSuccessText, affiliate);
        setDisplayCustomSuccessText(updatedText);
    }, [customSuccessText, affiliate]);

    useEffect(() => {
        const updatedZipCodeMatchCustomSuccessString = replacePlaceholders(
            zipCodeCustomMessage,
            affiliate,
        );
        setDisplayZipCodeMatchCustomSuccessString(updatedZipCodeMatchCustomSuccessString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affiliate]);

    const editorDataReady = true;
    return (
        <>
            <Grid item xs={12} container>
                <OutlinedSection title="On Successful Eligibility">
                    <Grid item xs={12}>
                        <Controller
                            control={control}
                            name="onSuccessfulEligibility"
                            render={({ field }) => (
                                <TextField
                                    {...field}
                                    select
                                    fullWidth
                                    error={!!errors.onSuccessfulEligibility}
                                    helperText={
                                        errors.onSuccessfulEligibility && 'Please select an option'
                                    }
                                    onChange={e => {
                                        clearDependentValues();
                                        field.onChange(e);
                                    }}
                                    className={classes.select}
                                >
                                    <MenuItem
                                        key={OnSuccessfulEligibility.RouteToLanding}
                                        value={OnSuccessfulEligibility.RouteToLanding}
                                        className={classes.menuItem}
                                    >
                                        <Typography>
                                            I would like to route the patient to a success screen
                                        </Typography>
                                    </MenuItem>
                                    <MenuItem
                                        key={OnSuccessfulEligibility.RouteDirectly}
                                        value={OnSuccessfulEligibility.RouteDirectly}
                                        className={classes.menuItem}
                                    >
                                        <Typography>
                                            I would like to route the patient to my scheduling URL
                                        </Typography>
                                    </MenuItem>
                                </TextField>
                            )}
                        />
                    </Grid>
                    {onSuccessfulEligibilityWatch === OnSuccessfulEligibility.RouteDirectly ? (
                        <Grid item xs={12}>
                            <Controller
                                control={control}
                                name="routingURL"
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        fullWidth
                                        label="Routing URL"
                                        error={!!errors.routingURL}
                                        helperText={errors.routingURL?.message as string}
                                    />
                                )}
                            />
                        </Grid>
                    ) : null}

                    {/* in case of Landing Page URL selected */}
                    {onSuccessfulEligibilityWatch === OnSuccessfulEligibility.RouteToLanding ? (
                        <>
                            <Grid item xs={12}>
                                <Controller
                                    control={control}
                                    name="customSuccessText"
                                    render={({ field }) => (
                                        <TextField
                                            {...field}
                                            value={displayCustomSuccessText}
                                            onChange={e => {
                                                field.onChange(e);
                                                setValue('customSuccessText', e.target.value, {
                                                    shouldValidate: true,
                                                });
                                                setDisplayCustomSuccessText(
                                                    replacePlaceholders(e.target.value, affiliate),
                                                );
                                            }}
                                            fullWidth
                                            multiline
                                            rows={6}
                                            label="On Successful Eligibility Custom Success Text"
                                            error={!!errors.customSuccessText}
                                            helperText={errors.customSuccessText?.message as string}
                                            disabled={disabled}
                                        />
                                    )}
                                />
                            </Grid>
                            {showPreview && (
                                <PreviewSuccessScreen
                                    affiliateName={affiliate?.externalName}
                                    customSuccessText={replacePlaceholders(
                                        customSuccessText,
                                        affiliate,
                                    )}
                                />
                            )}
                        </>
                    ) : null}
                </OutlinedSection>
            </Grid>
            {showZipCode && (
                <Grid item xs={12} container>
                    <OutlinedSection title="Zip Code Routing Custom Success Text">
                        {onSuccessfulZipCodeMatch === OnSuccessfulZipCodeMatch.ToSuccessScreen && (
                            <Grid item xs={12}>
                                {editorDataReady && (
                                    <Editor
                                        content={displayZipCodeMatchCustomSuccessString}
                                        setFormValue={(value: string) => {
                                            setValue('zipCodeMatchCustomSuccessString', value, {
                                                shouldValidate: true,
                                            });
                                        }}
                                        error={
                                            errors.zipCodeMatchCustomSuccessString
                                                ?.message as string
                                        }
                                    />
                                )}
                            </Grid>
                        )}
                    </OutlinedSection>
                </Grid>
            )}
        </>
    );
};

export default RoutingForm;
export { eligibilitySuccessText, zipCodeSuccessText, zipCodeWithUrlSuccessText };
