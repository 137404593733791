import React, { memo } from 'react';
import copy from 'copy-to-clipboard';
import { Grid, Typography, TextField, Tooltip, IconButton } from '@mui/material';
import { TriggerGlobalAlert } from '~/state';
import { AlertSeverity } from '~/schemaTypes';
import { ContentCopy } from '@mui/icons-material';

type AffiliateUrlProps = {
    url: string;
};

const AffiliateUrl: React.FC<AffiliateUrlProps> = props => {
    const { url } = props;
    return (
        <Grid
            item
            xs={12}
            container
            flexDirection="column"
            alignItems="center"
            justifyContent="center"
        >
            <Grid item>
                <Typography variant="body1">
                    Sharable link to have patients check their own eligibility
                </Typography>
            </Grid>
            <Grid container item alignItems="center" width="100%">
                <Grid item xs={11}>
                    <TextField disabled value={url} size="small" fullWidth sx={{ padding: 0 }} />
                </Grid>
                <Grid item xs={1}>
                    <Tooltip title={url}>
                        <IconButton
                            onClick={() => {
                                copy(`${url}`);
                                TriggerGlobalAlert({
                                    message: 'VC Affiliate URL is copied to clipboard',
                                    severity: AlertSeverity.Success,
                                });
                            }}
                            size="large"
                        >
                            <ContentCopy />
                        </IconButton>
                    </Tooltip>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default memo(AffiliateUrl);
