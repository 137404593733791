import React, { memo } from 'react';
import { Grid, Typography } from '@mui/material';
import useStyles from '../styles';
import { useVCFContext } from '../hooks/useVCF';

type HeaderProps = {
    showFormDescription: boolean;
    subHeading?: React.ReactNode;
    formDescriptionText?: string | string[];
};

const VC_FORM_DESCRIPTION = `
Securely provide your health information below. This will help us match you to
the right resources for you and your health experience. After you complete this
form, you can schedule your virtual appointment. We currently accept Cigna
insurance, at no additional cost for Cigna customers.`;

const Header: React.FC<HeaderProps> = props => {
    const { classes } = useStyles();
    const { formDescriptionText, showFormDescription, subHeading } = props;
    const { logoPath, isMobileView } = useVCFContext();
    const description = Array.isArray(formDescriptionText)
        ? formDescriptionText
        : [formDescriptionText ?? VC_FORM_DESCRIPTION];
    return (
        <Grid className={classes.imageContainer} item xs={12}>
            <Grid item xs={8} md={6} style={{ textAlign: 'center' }}>
                <img src={logoPath} alt="logo" className={classes.image} />
            </Grid>
            {subHeading}
            {showFormDescription && (
                <Grid item container xs={12} rowSpacing={1} justifyContent="center">
                    {description.map(text => (
                        <Grid item>
                            <Typography variant={isMobileView ? 'body2' : 'body1'}>
                                {text}
                            </Typography>
                        </Grid>
                    ))}
                </Grid>
            )}
        </Grid>
    );
};

export default memo(Header);
