import _ from 'lodash';
import { yupResolver } from '@hookform/resolvers/yup';
import { Autocomplete, Button, Card, CardHeader, Grid, MenuItem, TextField } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { ArrowBack, Save } from '@mui/icons-material';
import ObjectId from 'bson-objectid';
import React, { useEffect, useState, useMemo } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { useUserPermissions } from '~/hooks';
import {
    ReferencePages,
    ReferenceLinkInput,
    ReferenceLinksListDocument,
    ReferenceLinkUpdateInput,
    useCreateReferenceLinkMutation,
    useFetchReferenceLinkLazyQuery,
    useUpdateReferenceLinkMutation,
    useFetchRolesForReferenceLinkPageQuery,
    useFetchOrgListForReferenceLinkPageQuery,
    useFetchReferenceLinkCategoriesForReferenceLinkPageQuery,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { REFERENCE_LINK_VALIDATION_SCHEMA } from './yupSchema';

export const Priorities = Array.from({ length: 5 }, (_, i) => i + 1);

const useStyles = makeStyles()(theme => ({
    root: {},
    fab: {
        position: 'absolute',
        top: theme.spacing(12),
        right: theme.spacing(4),
    },
}));

interface ReferenceLinkFormInput {
    id?: string;
    name: string;
    category?: string | null;
    roles: string[];
    pages: ReferencePages[];
    url: string;
    organizations: string[];
    priority: number;
}

const formValuesFromData = (instance: any) => {
    return {
        name: instance.label,
        category: instance.categoryId,
        roles: instance.roleId,
        pages: instance.pages,
        url: instance.url,
        organizations: instance.organizationId,
        priority: instance.sortField,
    };
};

const formValuesDefault = (referenceLinkId: string) => {
    return {
        id: referenceLinkId,
        name: '',
        category: null,
        roles: [],
        pages: [],
        url: '',
        organizations: [],
        priority: 5,
    };
};

const formValuesToCreate = (form: ReferenceLinkFormInput): ReferenceLinkInput => {
    const { name, category, organizations, roles, pages, url, priority } = form;

    return {
        categoryId: category || null,
        label: name,
        organizationId: organizations,
        pages,
        roleId: roles,
        sortField: priority,
        url,
    };
};

const formValuesToUpdate = (form: ReferenceLinkFormInput): ReferenceLinkUpdateInput => {
    const { name, category, organizations, roles, pages, url, priority } = form;

    return {
        categoryId: category || null,
        label: name,
        organizationId: organizations,
        pages: pages ?? [],
        roleId: roles,
        sortField: priority,
        url,
    };
};

const ReferenceLinkEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const { id: urlId } = useParams<{ id: string }>();
    const [isModified, setIsModified] = useState(false);
    const [close, setClose] = useState(false);
    const { pagePermissions } = useUserPermissions();
    const canEdit = pagePermissions?.ReferenceLinks.Edit || false;
    const isEditMode = urlId !== 'new';
    const [referenceLinkId] = useState(isEditMode ? urlId : new ObjectId().toHexString());

    const {
        control,
        register,
        reset,
        handleSubmit,
        formState: { errors },
    } = useForm<ReferenceLinkFormInput>({
        resolver: yupResolver(REFERENCE_LINK_VALIDATION_SCHEMA as any),
        defaultValues: formValuesDefault(referenceLinkId ?? ''),
    });

    const { data: orgs, loading: orgsLoading } = useFetchOrgListForReferenceLinkPageQuery();
    const { data: roles, loading: rolesLoading } = useFetchRolesForReferenceLinkPageQuery();
    const { data: categories, loading: categoriesLoading } =
        useFetchReferenceLinkCategoriesForReferenceLinkPageQuery();

    const [fetchReferenceLinkById, { data: referenceLinkData, loading: referenceLinkLoading }] =
        useFetchReferenceLinkLazyQuery();

    const [createReferenceLink, { loading: createReferenceLinkLoading }] =
        useCreateReferenceLinkMutation({
            onCompleted: () => {
                if (close) history(`/app-config/referencelinks`);
            },
            refetchQueries: [{ query: ReferenceLinksListDocument }],
        });

    const [updateReferenceLink, { loading: updateReferenceLinkLoading }] =
        useUpdateReferenceLinkMutation({
            onCompleted: () => {
                if (close) history(`/app-config/referencelinks`);
            },
            refetchQueries: [{ query: ReferenceLinksListDocument }],
        });

    useEffect(() => {
        if (isEditMode) {
            fetchReferenceLinkById({ variables: { input: { id: referenceLinkId } } });

            if (referenceLinkData && referenceLinkData.referenceLink) {
                reset(formValuesFromData(referenceLinkData.referenceLink));
            }
        }
    }, [fetchReferenceLinkById, isEditMode, referenceLinkId, referenceLinkData, reset]);

    const organizationsList = useMemo(() => {
        return _.sortBy(orgs?.organizations, ['name']);
    }, [orgs]);

    const categoryList = useMemo(() => {
        return _.sortBy(categories?.referenceLinkCategorys, ['name']);
    }, [categories]);

    const referencePagesList = useMemo(() => {
        return _.sortBy(
            Object.values(ReferencePages).map(item => ({
                id: item,
                label: item,
            })),
            ['label'],
        );
    }, []);

    const rolesByOrganizationList = useMemo(() => {
        const rolesList = roles?.roles.map(item => {
            const connectedOrgName = orgs?.organizations.find(
                org => org.id === item.organizationId,
            )?.brandingName;
            return {
                ...item,
                name: `${item.name}${connectedOrgName ? ` - ${connectedOrgName}` : ''}`,
            };
        });
        return _.sortBy(rolesList, ['name']);
    }, [roles?.roles, orgs?.organizations]);

    const onSubmit = (values: any) => {
        if (isEditMode && referenceLinkId) {
            updateReferenceLink({
                variables: {
                    input: {
                        id: referenceLinkId,
                        data: formValuesToUpdate(values),
                    },
                },
            });
        } else if (referenceLinkId) {
            createReferenceLink({
                variables: {
                    input: formValuesToCreate(values),
                },
            });
        }
    };

    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the Reference Link List?`,
                callback: () => {
                    history(`/app-config/referencelinks/`);
                },
            });
        else history(`/app-config/referencelinks/`);
    };
    const onEdit = () => {
        setIsModified(true);
    };
    if (
        referenceLinkLoading &&
        createReferenceLinkLoading &&
        updateReferenceLinkLoading &&
        orgsLoading &&
        rolesLoading &&
        categoriesLoading
    )
        return <Loading />;
    return (
        <Grid container spacing={2} className={classes.root}>
            <Grid item xs={12}>
                <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                    Back to Reference Links List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <form noValidate onSubmit={handleSubmit(onSubmit)}>
                    <Card>
                        <CardHeader
                            title={isEditMode ? 'Edit Reference Link' : 'Create Reference Link'}
                        />
                        <OutlinedSection title="Name *">
                            <TextField
                                id="name"
                                label=""
                                variant="outlined"
                                type="text"
                                fullWidth
                                required
                                margin="dense"
                                {...register('name')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onEdit}
                                disabled={!canEdit}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Organizations *">
                            <AutocompleteWithRecordOptions
                                options={_.sortBy(organizationsList, 'brandingName') ?? []}
                                valueKey="id"
                                labelKey="brandingName"
                                control={control}
                                name="organizations"
                                label=""
                                required
                                error={!!errors.organizations}
                                helperText={errors.organizations?.message}
                                placeholder="Select organization..."
                                disabled={!canEdit}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Category">
                            <Controller
                                control={control}
                                name="category"
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        size="small"
                                        onChange={(_, val) => {
                                            onChange(val?.id);
                                        }}
                                        getOptionLabel={selected => selected?.name ?? ''}
                                        isOptionEqualToValue={(option, val) =>
                                            option?.id === val?.id
                                        }
                                        options={categoryList ?? []}
                                        value={
                                            categoryList.find(category => category.id === value) ??
                                            null
                                        }
                                        defaultValue={categoryList.find(
                                            category =>
                                                category.id ===
                                                referenceLinkData?.referenceLink?.categoryId,
                                        )}
                                        renderInput={params => (
                                            <TextField
                                                variant="outlined"
                                                margin="dense"
                                                // eslint-disable-next-line react/jsx-props-no-spreading
                                                {...params}
                                                placeholder="Select Category"
                                                label=""
                                                InputLabelProps={{ shrink: true }}
                                                disabled={!canEdit}
                                            />
                                        )}
                                    />
                                )}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Roles *">
                            <AutocompleteWithRecordOptions
                                options={rolesByOrganizationList ?? []}
                                valueKey="id"
                                labelKey="name"
                                control={control}
                                name="roles"
                                label=""
                                required
                                error={!!errors.roles}
                                helperText={errors.roles?.message}
                                placeholder="Select role..."
                                disabled={!canEdit}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="Pages to display on *">
                            <AutocompleteWithRecordOptions
                                options={referencePagesList ?? []}
                                valueKey="id"
                                labelKey="label"
                                control={control}
                                name="pages"
                                label=""
                                required
                                error={!!errors.pages}
                                helperText={errors.pages?.message}
                                placeholder="Select page..."
                                disabled={!canEdit}
                            />
                        </OutlinedSection>
                        <OutlinedSection title="URL *">
                            <TextField
                                id="url"
                                label=""
                                variant="outlined"
                                type="text"
                                fullWidth
                                required
                                margin="dense"
                                {...register('url')}
                                error={!!errors.name}
                                helperText={errors.name?.message}
                                onChange={onEdit}
                                disabled={!canEdit}
                            />
                        </OutlinedSection>
                        <Grid container item xs={12}>
                            <ReactHookFormSelect
                                control={control}
                                name="priority"
                                variant="outlined"
                                label="Priority"
                                margin="dense"
                                fullWidth
                                defaultValue=""
                                disabled={!canEdit}
                            >
                                {Priorities.map(i => (
                                    <MenuItem key={i} value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                        {canEdit && (
                            <div style={{ width: '100%', textAlign: 'right', marginTop: '20px' }}>
                                <Button
                                    type="submit"
                                    startIcon={<Save />}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => handleSubmit(onSubmit)}
                                    style={{ marginRight: '10px' }}
                                >
                                    Save
                                </Button>
                                <Button
                                    type="submit"
                                    startIcon={<Save />}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        setClose(true);
                                        handleSubmit(onSubmit);
                                    }}
                                >
                                    Save &amp; Close
                                </Button>
                            </div>
                        )}
                    </Card>
                </form>
            </Grid>
        </Grid>
    );
};

export default ReferenceLinkEditor;
