import React from 'react';
import { useParams, Link as RouterLink } from 'react-router-dom';
import { Breadcrumbs, Grid, Typography, Link } from '@mui/material';
import {
    useAffiliateCarePatientQuery,
    useAffiliateCareSubmittedClaimsV2Query,
    OrderByDirectionEnum,
} from '~/schemaTypes';
import {
    getActiveInsurancePlanData,
    isEligibleForLactationServices,
} from '~/utils/lactationServicesEligibility';
import Loading from '~/components/Loading/Loading';
import { useUser, useUserPermissions, useAffiliateCareView, useVcUserView } from '~/hooks';
import { useInitVCF, VCFContext } from '~/views/VirtualCareSurvey/hooks/useVCF';
import Claims from './Claims';
import OverviewSection from '../components/OverviewSection';
import Section from '../components/Section';
import InsuranceDetails from '../components/InsuranceDetails';
import UpcomingAppointments from '../components/UpcomingAppointments';

type RouteParams = {
    id: string;
};

type PatientProps = {
    affiliateId?: string;
    portalView?: boolean;
};

const Patient: React.FC<PatientProps> = props => {
    const { affiliateId, portalView = false } = props;
    const { id } = useParams<RouteParams>();
    const { userAffiliateId } = useAffiliateCareView();
    const currentAffiliateId = portalView ? affiliateId : userAffiliateId;
    const { pagePermissions } = useUserPermissions();
    const { data: userData } = useUser();
    const { data: patient, loading: patientLoading } = useAffiliateCarePatientQuery({
        variables: {
            patientInput: {
                id,
            },
            appointmentInput: {
                excludePatientCreated: true,
                orderBy: { field: 'startDate', order: OrderByDirectionEnum.Desc },
            },
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });
    const { isDoulaView, isVcUser } = useVcUserView();
    const { data: claims, loading: claimsLoading } = useAffiliateCareSubmittedClaimsV2Query({
        variables: {
            input: {
                filter: {
                    fields: {
                        ...(!isVcUser && { affiliateId: currentAffiliateId }),
                        ...(isVcUser && { userId: userData?.currentUser?.id }),
                        patientId: id,
                    },
                },
                orderBy: {
                    field: 'createdAt',
                    order: OrderByDirectionEnum.Desc,
                },
            },
        },
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });

    const activeInsurancePlan = getActiveInsurancePlanData(patient?.patient?.insurancePlans ?? []);
    const allottedLactationVisits = activeInsurancePlan?.allottedLactationVisits;
    const isEligible = isEligibleForLactationServices(activeInsurancePlan);
    const showClaimSection =
        pagePermissions?.DoulaInvoices.Read ||
        pagePermissions?.VirtualCareClaims.Read ||
        pagePermissions?.LcInvoices.Read ||
        portalView;
    const showInsuranceDetailsSection = isVcUser;
    const showUpcomingAppointmentsSection = isVcUser;

    const VCFInitProps = { isDoulaView };
    const VCFContextProps = useInitVCF(VCFInitProps);
    if (patientLoading || claimsLoading) {
        return <Loading subtitle="Loading patient data" />;
    }

    let insurancePlanName = activeInsurancePlan?.name;
    if (!insurancePlanName) {
        insurancePlanName = activeInsurancePlan ? 'Unknown' : 'None';
    }

    const totalClaimsSubmittedLabel = `${patient?.patient?.totalClaimsSubmitted ?? 0} ${
        allottedLactationVisits ? `of ${allottedLactationVisits}` : ''
    }`;
    const claimsSectionTitle = isVcUser
        ? 'Completed Appointments'
        : `Patient Claims (Total Completed Visits: ${totalClaimsSubmittedLabel})`;

    return (
        <Grid container spacing={1}>
            <Grid item xs={12}>
                {!isVcUser && (
                    <Breadcrumbs>
                        <Link
                            to={
                                portalView
                                    ? `/app-config/vcaffiliates/patients/${affiliateId}`
                                    : '/affiliate-care-portal/patients'
                            }
                            component={RouterLink}
                            underline="hover"
                            color="inherit"
                        >
                            <Typography variant="h6">Patients</Typography>
                        </Link>
                        <Typography>{patient?.patient?.fullName}</Typography>
                    </Breadcrumbs>
                )}
            </Grid>
            <VCFContext.Provider value={VCFContextProps}>
                <Grid container spacing={2} item>
                    <Grid item xs={12}>
                        <OverviewSection
                            isEligible={isEligible}
                            portalView={portalView}
                            patientId={id as string}
                            affiliateId={currentAffiliateId}
                            patient={patient}
                            insurancePlanName={insurancePlanName}
                            activeInsurancePlan={activeInsurancePlan}
                        />
                    </Grid>
                    <Section title="Insurance Details" show={showInsuranceDetailsSection}>
                        <InsuranceDetails
                            isEligible={isEligible}
                            patient={patient}
                            insurancePlanName={insurancePlanName}
                            activeInsurancePlan={activeInsurancePlan}
                        />
                    </Section>
                    <Section title="Upcoming Appontments" show={showUpcomingAppointmentsSection}>
                        <UpcomingAppointments patient={patient} />
                    </Section>
                    <Section title="" show={showClaimSection}>
                        <Claims
                            sectionTitle={claimsSectionTitle}
                            isVcUser={isVcUser}
                            claims={claims}
                            portalView={portalView}
                        />
                    </Section>
                </Grid>
            </VCFContext.Provider>
        </Grid>
    );
};

export default Patient;
