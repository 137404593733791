import React from 'react';
import { GetTriggerInterventionEndEpisodesForPatientTranscriptQuery } from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type TriggerInterventionEndEpisode = NonNullable<
    NonNullable<
        GetTriggerInterventionEndEpisodesForPatientTranscriptQuery['getTriggerInterventionEndEpisodesForPatientTranscript']
    >['items']
>[number];

export const TriggerInterventionEndEpisodeCard = ({
    intervention,
}: {
    intervention: TriggerInterventionEndEpisode;
}) => {
    return (
        <BaseCard title="Trigger Intervention End Episode" data={intervention}>
            <p className="m-0">
                {`Condition: ${intervention?.conditionType?.name?.en || 'Unknown'}`}
            </p>
            <p className="m-0">
                {`Intervention Type: ${intervention?.event?.data?.intervention?.type || 'Unknown'}`}
            </p>
        </BaseCard>
    );
};
