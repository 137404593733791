import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { Save as SaveIcon } from '@mui/icons-material';
import React, { useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { AlertSeverity, useCreatePatientTodoTaskMutation } from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import { DesktopDatePicker } from '@mui/x-date-pickers';
import { FIELD_NAMES, PRIORITIES } from '../enums';
import { mapFormInputToCreateQueryInput } from '../helpers';
import { INotifyIntervalUnit, IToDoEditFormInput } from '../interfaces';
import { useDefStyles } from '../styles';
import { RouteParams } from '../types';
import { IFormEditable } from './interfaces';
import { CREATE_TODO_FORM_INPUT } from './validators';

interface PatientTodoCreateModalProps {
    closeHandler: () => void;
}

export const PatientTodoCreateModal: React.FC<PatientTodoCreateModalProps> = ({ closeHandler }) => {
    const { classes } = useDefStyles();

    const defaultEditable: IFormEditable = {
        title: { en: '' },
        description: '',
        dueDate: new Date(),
        priority: PRIORITIES[4],
        isEssential: false,
    };
    const [EditableData, setEditableData] = useState<IFormEditable>(defaultEditable);

    const [notifyInterval, setNotifyInterval] = useState<INotifyIntervalUnit>({
        days: 0,
        months: 0,
        years: 0,
    });
    const {
        register,
        handleSubmit,
        control,

        formState: { errors },
    } = useForm<IToDoEditFormInput>({
        resolver: yupResolver(CREATE_TODO_FORM_INPUT as any),
    });

    const { id: patientId } = useParams<RouteParams>();

    const [createPatientTodo, { loading: createPatientTodoLoading }] =
        useCreatePatientTodoTaskMutation({
            onCompleted: async data => {
                const {
                    createHACreatedTodoTask,
                    createHACreatedTodoTask: { resourceCreated },
                } = data;
                if (resourceCreated === null || EditableData === null) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message: `TODO create went wrong: ${createHACreatedTodoTask.message}`,
                    });
                }
            },
            onError: error => {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `${error}`,
                });
            },
        });

    const onSubmit = async (data: IToDoEditFormInput) => {
        if (patientId === undefined) {
            return;
        }
        const defaultsOverrides = {
            ...data,
            [FIELD_NAMES.dueDate]: EditableData.dueDate,
            [FIELD_NAMES.priority]: EditableData?.priority ?? data[FIELD_NAMES.priority],
            [FIELD_NAMES.isEssential]: EditableData?.isEssential ?? data[FIELD_NAMES.isEssential],
        };
        const queryInput = mapFormInputToCreateQueryInput(defaultsOverrides, patientId);
        await createPatientTodo({
            variables: {
                input: {
                    ...queryInput,
                },
            },
        });
        closeHandler();
    };
    if (createPatientTodoLoading) return <Loading />;
    return (
        <form className={classes.root} onSubmit={handleSubmit(onSubmit)}>
            <DialogTitle>Create Todo (HA Created)</DialogTitle>
            <DialogContent>
                <Grid container>
                    <OutlinedSection title="Title">
                        <Grid item xs={12}>
                            <TextField
                                required
                                variant="outlined"
                                placeholder="English"
                                type="text"
                                margin="dense"
                                defaultValue={EditableData.title.en}
                                className={classes.inputField}
                                fullWidth
                                {...register('title.en')}
                                error={!!errors[FIELD_NAMES.title]}
                                onChange={e => {
                                    const current = EditableData.title;
                                    setEditableData({
                                        ...EditableData,
                                        title: {
                                            ...current,
                                            en: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                variant="outlined"
                                placeholder="Spanish"
                                type="text"
                                margin="dense"
                                defaultValue={EditableData.title?.es}
                                className={classes.inputField}
                                fullWidth
                                {...register('title.es')}
                                error={!!errors[FIELD_NAMES.title]}
                                onChange={e => {
                                    const current = EditableData.title;
                                    setEditableData({
                                        ...EditableData,
                                        title: {
                                            ...current,
                                            es: e.target.value,
                                        },
                                    });
                                }}
                            />
                        </Grid>
                    </OutlinedSection>
                    <Grid item xs={12}>
                        <TextField
                            variant="outlined"
                            label="Description"
                            fullWidth
                            multiline
                            rows={5}
                            margin="dense"
                            defaultValue={EditableData.description}
                            className={classes.inputField}
                            {...register(FIELD_NAMES.description)}
                            error={!!errors[FIELD_NAMES.description]}
                            onChange={e => {
                                setEditableData({
                                    ...EditableData,
                                    description: e.target.value,
                                });
                            }}
                        />
                    </Grid>
                </Grid>
                <OutlinedSection title="Timing and order">
                    <Grid container alignItems="center">
                        <Grid item xs={2}>
                            <InputLabel>Date to complete</InputLabel>
                        </Grid>
                        <Grid item xs={10}>
                            <Controller
                                name={FIELD_NAMES.dueDate}
                                control={control}
                                defaultValue={EditableData.dueDate}
                                render={({ field }) => (
                                    <DesktopDatePicker
                                        {...field}
                                        label="Start Date (UTC)"
                                        className={classes.inputField}
                                        defaultValue={new Date()}
                                        onChange={(e: Date) => {
                                            setEditableData({
                                                ...EditableData,
                                                dueDate: e,
                                            });
                                        }}
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item xs={1}>
                            <InputLabel color="primary">Show</InputLabel>
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                type="number"
                                label="Days *"
                                defaultValue={notifyInterval.days}
                                className={classes.inputField}
                                {...register(FIELD_NAMES.effectiveDateDaysInterval)}
                                onChange={e => {
                                    setNotifyInterval({
                                        days: parseInt(e.target.value, 10),
                                        months: notifyInterval.months,
                                        years: notifyInterval.years,
                                    });
                                }}
                                error={!!errors[FIELD_NAMES.effectiveDateDaysInterval]}
                                InputProps={{
                                    inputProps: { min: 0, step: 1 },
                                }}
                                margin="dense"
                                style={{
                                    maxWidth: '12em',
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                type="number"
                                label="Months *"
                                defaultValue={notifyInterval.months}
                                className={classes.inputField}
                                {...register(FIELD_NAMES.effectiveDateMonthsInterval)}
                                onChange={e => {
                                    setNotifyInterval({
                                        days: notifyInterval.days,
                                        months: parseInt(e.target.value, 10),
                                        years: notifyInterval.years,
                                    });
                                }}
                                error={!!errors[FIELD_NAMES.effectiveDateMonthsInterval]}
                                InputProps={{
                                    inputProps: { min: 0, step: 1 },
                                }}
                                margin="dense"
                                style={{
                                    maxWidth: '12em',
                                }}
                            />
                        </Grid>
                        <Grid item xs={3}>
                            <TextField
                                variant="outlined"
                                type="number"
                                label="Years *"
                                defaultValue={notifyInterval.years}
                                className={classes.inputField}
                                {...register(FIELD_NAMES.effectiveDateYearsInterval)}
                                onChange={e => {
                                    setNotifyInterval({
                                        days: notifyInterval.days,
                                        months: notifyInterval.months,
                                        years: parseInt(e.target.value, 10),
                                    });
                                }}
                                error={!!errors[FIELD_NAMES.effectiveDateYearsInterval]}
                                InputProps={{
                                    inputProps: { min: 0, step: 1 },
                                }}
                                margin="dense"
                                style={{
                                    maxWidth: '12em',
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <InputLabel>Before Date To Complete</InputLabel>
                        </Grid>
                    </Grid>
                    <Grid container alignItems="center">
                        <Grid item xs={6}>
                            <FormControl variant="outlined" fullWidth>
                                <InputLabel id="priorities-label">Priority</InputLabel>
                                <Controller
                                    name={FIELD_NAMES.priority}
                                    control={control}
                                    render={({ field: { onChange, ref } }) => (
                                        <Select
                                            className={classes.inputField}
                                            error={!!errors[FIELD_NAMES.priority]}
                                            value={EditableData.priority}
                                            variant="outlined"
                                            fullWidth
                                            ref={ref}
                                            onChange={e => {
                                                if (EditableData === null) return;
                                                const priority = e.target.value;
                                                if (typeof priority !== 'number') return;
                                                onChange(e);
                                                setEditableData({
                                                    ...EditableData,
                                                    priority,
                                                });
                                            }}
                                        >
                                            {Object.values(PRIORITIES).map(i => (
                                                <MenuItem key={i} value={i}>
                                                    {i}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    )}
                                />
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <Controller
                                name={FIELD_NAMES.isEssential}
                                control={control}
                                defaultValue={false}
                                render={({ field: { onChange, ref } }) => (
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={EditableData.isEssential}
                                                color="primary"
                                                ref={ref}
                                                onChange={e => {
                                                    if (EditableData === null) return;
                                                    setEditableData({
                                                        ...EditableData,
                                                        isEssential: Boolean(e.target.checked),
                                                    });
                                                    onChange(e.target.checked);
                                                }}
                                            />
                                        }
                                        label="Essential"
                                    />
                                )}
                            />
                        </Grid>
                    </Grid>
                </OutlinedSection>
            </DialogContent>

            <DialogActions>
                <Button onClick={closeHandler} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    name={FIELD_NAMES.submitTodo}
                    startIcon={<SaveIcon />}
                    type="submit"
                    color="secondary"
                    variant="contained"
                >
                    Save
                </Button>
            </DialogActions>
        </form>
    );
};
