import React, { useCallback, useState } from 'react';
import { Snackbar, Alert } from '@mui/material';
import {
    useWaystarEligibilityStatusSettingsLazyQuery,
    useSettingsForVirtualCareFormQuery,
    AlertSeverity,
} from '~/schemaTypes';

const EXCLUDED_ENVS = ['prod', 'uat'];

const EligibilityInfoLabel = () => {
    const [showLabel, setShowLabel] = useState(false);
    const [fetchEligibilitySetting, { data: eligibilityData }] =
        useWaystarEligibilityStatusSettingsLazyQuery({
            onCompleted: () => {
                setShowLabel(true);
            },
        });
    const useMockData = eligibilityData?.VCFSettings?.useMockData;
    useSettingsForVirtualCareFormQuery({
        onCompleted: data => {
            if (data.settings.env && !EXCLUDED_ENVS.includes(data.settings.env)) {
                fetchEligibilitySetting();
            }
        },
    });
    const hideLabel = useCallback(() => {
        setShowLabel(false);
    }, []);
    const message = useMockData
        ? 'Eligibility disabled.  Mocked data used for insurance plan.'
        : 'Eligibility enabled.';
    return (
        <Snackbar
            key="eligibilityEnabled"
            open={showLabel}
            anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            sx={{
                width: 'fit-content',
            }}
        >
            <Alert
                severity={useMockData ? AlertSeverity.Warning : AlertSeverity.Info}
                onClose={hideLabel}
            >
                {message}
            </Alert>
        </Snackbar>
    );
};

export { EligibilityInfoLabel };
