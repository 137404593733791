import React, { useRef, useState } from 'react';
import { Dialog, Grid, Link, Typography } from '@mui/material';
import MaterialTable from '@material-table/core';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus } from '@fortawesome/free-solid-svg-icons';
import {
    ModifiedBenefitsType,
    useVcGroupModifiedBenefitsForVcGroupModifiedBenefitsPageQuery,
    VcGroupModifiedBenefits,
} from '../../../schemaTypes';
import { useUserPermissions } from '../../../hooks';
import { VCGroupModifiedBenefitsEditModal } from './VCGroupModifiedBenefitsEditModal';

type ModalData = {
    isOpen: boolean;
    id?: string;
};

const VCGroupModifiedBenefits: React.FC = () => {
    const tableRef = useRef<HTMLDivElement>(null);
    const { pagePermissions } = useUserPermissions();
    const [searchText, setSearchText] = useState<string>();
    const { data: groupBenefits, loading: groupBenefitsLoading } =
        useVcGroupModifiedBenefitsForVcGroupModifiedBenefitsPageQuery({
            variables: {
                input: { ...(searchText && { search: searchText }) },
            },
            fetchPolicy: 'network-only',
            nextFetchPolicy: 'network-only',
        });

    const [editModalData, setEditModalData] = useState<ModalData>({ isOpen: false });

    const displayModifiedBenefitsType = (type: ModifiedBenefitsType) => {
        switch (type) {
            case ModifiedBenefitsType.ModifiedBenefits: {
                return 'Modified Benefits';
            }
            case ModifiedBenefitsType.OutOfNetwork: {
                return 'Out of Network';
            }
            default: {
                return '';
            }
        }
    };

    return (
        <div style={{ marginRight: '1rem' }} ref={tableRef}>
            <Grid>
                <MaterialTable<VcGroupModifiedBenefits>
                    title="Lactation Group Modified Benefits or Out of Network"
                    actions={[
                        {
                            icon: () => <FontAwesomeIcon icon={faPlus} />,
                            isFreeAction: true,
                            onClick: () => setEditModalData({ isOpen: true }),
                            tooltip: 'Create New Entry',
                            hidden: !pagePermissions?.VcGroupModifiedBenefits.Edit,
                        },
                    ]}
                    columns={[
                        {
                            title: 'Name',
                            field: 'name',
                            render: ({ id, name }) =>
                                pagePermissions?.VcGroupModifiedBenefits.Edit ? (
                                    // eslint-disable-next-line jsx-a11y/anchor-is-valid
                                    <Link
                                        component="button"
                                        onClick={() => setEditModalData({ isOpen: true, id })}
                                    >
                                        <Typography data-test={name}>{name}</Typography>
                                    </Link>
                                ) : (
                                    <Typography data-test={name}>{name}</Typography>
                                ),
                            sorting: false,
                        },
                        {
                            title: 'Group Id',
                            field: 'groupId',
                            render: ({ groupId }) => (
                                <Typography data-test={groupId}>{groupId}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Health Plan',
                            field: 'healthPlan',
                            render: ({ healthPlan }) => (
                                <Typography data-test={healthPlan}>{healthPlan}</Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Type',
                            field: 'type',
                            render: ({ type }) => (
                                <Typography data-test={type}>
                                    {displayModifiedBenefitsType(type)}
                                </Typography>
                            ),
                            sorting: false,
                        },
                        {
                            title: 'Whitelist',
                            field: 'isWhitelisted',
                            render: ({ isWhitelisted }) => (
                                <Typography data-test={isWhitelisted}>
                                    {isWhitelisted ? 'True' : 'False'}
                                </Typography>
                            ),
                            sorting: false,
                        },
                    ]}
                    data={
                        groupBenefits?.searchVCGroupModifiedBenefits?.results.map((o: any) => ({
                            ...o,
                        })) || []
                    }
                    options={{
                        search: true,
                        paging: false,
                    }}
                    onSearchChange={searchText => {
                        setSearchText(searchText);
                    }}
                    localization={{
                        header: { actions: '' },
                        toolbar: {
                            searchPlaceholder: 'Name or Group Id',
                        },
                    }}
                    isLoading={groupBenefitsLoading}
                />
            </Grid>
            {editModalData.isOpen && (
                <Dialog open={editModalData.isOpen} scroll="paper">
                    <VCGroupModifiedBenefitsEditModal
                        id={editModalData.id}
                        onClose={() => {
                            setEditModalData({ isOpen: false });
                        }}
                    />
                </Dialog>
            )}
        </div>
    );
};

export default VCGroupModifiedBenefits;
