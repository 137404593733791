import DatePicker from '@mui/lab/DatePicker';
import {
    Autocomplete,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    Grid,
    TextField,
} from '@mui/material';
import moment from 'moment';
import React, { useEffect, useState, useCallback } from 'react';
import Loading from '~/components/Loading/Loading';
import {
    useCareTeamMembersV2ForPatientFilterQuery,
    useUserProfileDefForPatientsFilterQuery,
    useFetchAffiliatesForFilterPatientsModalQuery,
    useFetchTagsForFilterPatientsModalQuery,
    useGetUsersByPermissionForFilterPatientsModalQuery,
    UserProfileDefForPatientsFilterQuery,
} from '~/schemaTypes';
import { PatientEnum } from '~/selectors';
import { useUser, useVcUserView } from '~/hooks';
import AffiliateFilterInput from './AffiliateFilterInput';
import { FilterFields } from './types';
import useFilters from './useFilters';

const REGISTRATION_LANGUAGE_DEF_ID = '6746186e8233340175db4b32';

type FilterPatientsModalProps = {
    open: boolean;
    onClose: () => void;
    isAffiliateCareView?: boolean;
};

type Choice = NonNullable<
    NonNullable<UserProfileDefForPatientsFilterQuery['userProfileDef']>['choices']
>[number];

const getSelectedClinicIds = (careTeamMembersIds: string[]) => {
    const storedClinics = localStorage.getItem(FilterFields.filteredClinics);
    if (!storedClinics) return [];

    return storedClinics
        .split(',')
        .filter(str => str.length > 1)
        .filter(id => careTeamMembersIds.includes(id));
};

const setSelectedClinicIdsInLocalStorage = (selectedClinicIds: string[]) => {
    localStorage.setItem(FilterFields.filteredClinics, selectedClinicIds.join(','));
};

export const FilterPatientsModal: React.FC<FilterPatientsModalProps> = ({
    open,
    onClose,
    isAffiliateCareView = false,
}) => {
    const { data: userData } = useUser();
    const { isVcUser, isLcView } = useVcUserView();
    const { filters } = useFilters(isAffiliateCareView);
    const showFilterField = useCallback(
        (fieldName: FilterFields) => {
            return filters.includes(fieldName);
        },
        [filters],
    );
    const [selectedTagIds, setSelectedTagIds] = useState<string[]>(
        localStorage
            .getItem(FilterFields.filteredTags)
            ?.split(',')
            .filter(str => str.length > 1) ?? [],
    );
    const [selectedLanguageChoiceIds, setSelectedLanguageChoiceIds] = useState<string[]>(
        localStorage
            .getItem(FilterFields.filteredLanguage)
            ?.split(',')
            .filter(str => str.length > 1) ?? [],
    );
    const [selectedAdvocate, setSelectedAdvocate] = useState<string>(
        localStorage.getItem(FilterFields.filteredAdvocate) ?? '',
    );
    const [enableAdvocateFilter, setEnableAdvocateFilter] = useState<boolean>(
        localStorage.getItem(FilterFields.enableAdvocateFilter) === 'true' || false,
    );
    const [filteredFirstName, setFilteredFirstName] = useState<string>(
        localStorage.getItem(FilterFields.filteredFirstName) ?? '',
    );
    const [filteredLastName, setFilteredLastName] = useState<string>(
        localStorage.getItem(FilterFields.filteredLastName) ?? '',
    );
    const [filteredExternalId, setFilteredExternalId] = useState<string>(
        localStorage.getItem(FilterFields.filteredExternalId) ?? '',
    );
    const [filteredBirthDate, setFilteredBirthDate] = useState<string>(
        localStorage.getItem(FilterFields.filteredBirthDate) ?? '',
    );
    const [filteredEmail, setFilteredEmail] = useState<string>(
        localStorage.getItem(FilterFields.filteredEmail) ?? '',
    );
    const [filteredPhoneNumber, setFilteredPhoneNumber] = useState<string>(
        localStorage.getItem(FilterFields.filteredPhoneNumber) ?? '',
    );

    const [filteredMemberId, setFilteredMemberId] = useState<string>(
        localStorage.getItem(FilterFields.filteredMemberId) ?? '',
    );
    const [filterTestData, setFilterTestData] = useState<boolean>(
        localStorage.getItem(FilterFields.filterTestData) === 'true' || false,
    );
    const [filteredAffiliateId, setFilteredAffiliateId] = useState<string>(
        localStorage.getItem(FilterFields.filteredAffiliateId) ?? '',
    );
    const [selectedClinicIds, setSelectedClinicIds] = useState<string[]>(
        localStorage
            .getItem(FilterFields.filteredClinics)
            ?.split(',')
            .filter(str => str.length > 1) ?? [],
    );

    const { data: tags, loading: tagsLoading } = useFetchTagsForFilterPatientsModalQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        isPatientFilter: true,
                    },
                },
            },
        },
        skip: isVcUser,
    });

    const { data: selectedAffiliate } = useFetchAffiliatesForFilterPatientsModalQuery({
        variables: {
            input: {
                filter: {
                    fieldsInList: {
                        affiliateId: [filteredAffiliateId],
                    },
                },
            },
        },
        skip: !isVcUser && !isAffiliateCareView,
    });

    const { data: advocates, loading: advocatesLoading } =
        useGetUsersByPermissionForFilterPatientsModalQuery({
            variables: {
                permission: 'isHealthAdvocate',
            },
            skip: isVcUser,
        });

    const { data: registrationLanguageDefinition, loading: loadingRegistrationLanguageDefinition } =
        useUserProfileDefForPatientsFilterQuery({
            variables: {
                input: { id: REGISTRATION_LANGUAGE_DEF_ID },
            },
            skip: isVcUser,
        });

    const { data: careTeamMembers, loading: careTeamMembersLoading } =
        useCareTeamMembersV2ForPatientFilterQuery({
            variables: {
                input: {
                    careTeamMemberTypeId: '63a238a917b9e55f5f1379ba',
                },
            },
            onCompleted(data) {
                const careTeamMembersIds = data.careTeamMembersByCurrentOrg.map(
                    member => member.id,
                );
                const selectedClinicIds = getSelectedClinicIds(careTeamMembersIds);
                setSelectedClinicIdsInLocalStorage(selectedClinicIds);
                setSelectedClinicIds(selectedClinicIds);
            },
            skip: isVcUser,
        });

    useEffect(() => {
        if (userData && careTeamMembers?.careTeamMembersByCurrentOrg) {
            const careTeamMembersIds = careTeamMembers.careTeamMembersByCurrentOrg.map(
                member => member.id,
            );
            const selectedClinicIds = getSelectedClinicIds(careTeamMembersIds);
            setSelectedClinicIdsInLocalStorage(selectedClinicIds);
            setSelectedClinicIds(selectedClinicIds);
        }
    }, [userData, careTeamMembers?.careTeamMembersByCurrentOrg]);

    const handleSelectTag = (event: React.ChangeEvent<unknown>, value: any) => {
        setSelectedTagIds(value);
    };

    const handleSelectAdvocate = (event: React.ChangeEvent<unknown>, value: any) => {
        setSelectedAdvocate(value);
    };

    const handleSelectClinic = (event: React.ChangeEvent<unknown>, value: any) => {
        setSelectedClinicIds(value);
    };

    const handleSave = () => {
        localStorage.setItem(FilterFields.filteredTags, `${selectedTagIds.join(',')}`);
        if (enableAdvocateFilter && selectedAdvocate) {
            localStorage.setItem(FilterFields.filteredAdvocate, `${selectedAdvocate}`);
        } else {
            localStorage.removeItem(FilterFields.filteredAdvocate);
        }
        localStorage.setItem(FilterFields.filteredFirstName, `${filteredFirstName}`);
        localStorage.setItem(FilterFields.filteredLastName, `${filteredLastName}`);
        localStorage.setItem(FilterFields.filteredExternalId, `${filteredExternalId}`);
        localStorage.setItem(FilterFields.filteredBirthDate, `${filteredBirthDate}`);
        localStorage.setItem(FilterFields.filteredEmail, `${filteredEmail}`);
        localStorage.setItem(FilterFields.filteredPhoneNumber, `${filteredPhoneNumber}`);
        localStorage.setItem(FilterFields.filteredMemberId, `${filteredMemberId}`);
        localStorage.setItem(FilterFields.filterTestData, `${filterTestData}`);
        if (isAffiliateCareView || isLcView) {
            localStorage.setItem(FilterFields.filteredAffiliateId, `${filteredAffiliateId}`);
        } else {
            localStorage.removeItem(FilterFields.filteredAffiliateId);
        }
        localStorage.setItem(FilterFields.filteredClinics, `${selectedClinicIds.join(',')}`);
        localStorage.setItem(
            FilterFields.filteredLanguage,
            `${selectedLanguageChoiceIds.join(',')}`,
        );
        onClose();
    };

    const handleClearFilters = () => {
        setSelectedTagIds([]);
        setSelectedAdvocate('');
        setFilteredFirstName('');
        setFilteredLastName('');
        setFilteredExternalId('');
        setFilteredBirthDate('');
        setFilteredEmail('');
        setFilteredPhoneNumber('');
        setFilteredMemberId('');
        setFilterTestData(false);
        setFilteredAffiliateId('');
        setSelectedClinicIds([]);
        setSelectedLanguageChoiceIds([]);
    };

    if (
        tagsLoading ||
        advocatesLoading ||
        careTeamMembersLoading ||
        loadingRegistrationLanguageDefinition
    ) {
        return <Loading />;
    }

    const registrationLanguageChoices =
        registrationLanguageDefinition?.userProfileDef?.choices ?? [];

    return (
        <Dialog
            open={open}
            onClose={onClose}
            fullWidth
            data-test={PatientEnum.FILTER_PATIENTS_MODAL}
        >
            <DialogTitle>Filter Patients</DialogTitle>
            <DialogContent>
                {showFilterField(FilterFields.filteredTags) && (
                    <Autocomplete
                        multiple
                        options={tags?.tagsV2.results.map(tag => tag.id) ?? []}
                        getOptionLabel={tag =>
                            tags?.tagsV2.results.find(t => t.id === tag)?.name ?? ''
                        }
                        filterSelectedOptions
                        value={selectedTagIds as string[]}
                        onChange={handleSelectTag}
                        disableCloseOnSelect
                        renderInput={params => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <TextField {...params} label="Filter Tags" variant="outlined" />
                        )}
                    />
                )}
                {showFilterField(FilterFields.enableAdvocateFilter) && (
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={enableAdvocateFilter}
                                onChange={() => {
                                    setEnableAdvocateFilter(!enableAdvocateFilter);
                                    localStorage.setItem(
                                        FilterFields.enableAdvocateFilter,
                                        `${!enableAdvocateFilter}`,
                                    );
                                }}
                            />
                        }
                        label="Enable Assigned Health Advocate Filter"
                    />
                )}
                {showFilterField(FilterFields.filteredAdvocate) && (
                    <Autocomplete
                        options={
                            advocates?.getUsersByPermission
                                ?.map(user => user?.id)
                                .concat(['unassigned']) ?? []
                        }
                        getOptionLabel={user =>
                            advocates?.getUsersByPermission?.find(u => u?.id === user)?.name ?? user
                        }
                        filterSelectedOptions
                        disabled={!enableAdvocateFilter}
                        value={selectedAdvocate as string}
                        onChange={handleSelectAdvocate}
                        renderInput={params => (
                            <TextField
                                // eslint-disable-next-line react/jsx-props-no-spreading
                                {...params}
                                label="Filter Assigned Health Advocates"
                                variant="outlined"
                            />
                        )}
                    />
                )}
                <Grid container spacing={1}>
                    {showFilterField(FilterFields.filteredFirstName) && (
                        <Grid item xs={6}>
                            <TextField
                                label="Filter First Name"
                                variant="outlined"
                                fullWidth
                                value={filteredFirstName}
                                data-test={PatientEnum.FILTER_FIRST_NAME}
                                onChange={e => {
                                    setFilteredFirstName(e.target.value);
                                    localStorage.setItem(
                                        FilterFields.filteredFirstName,
                                        e.target.value,
                                    );
                                }}
                            />
                        </Grid>
                    )}
                    {showFilterField(FilterFields.filteredLastName) && (
                        <Grid item xs={6}>
                            <TextField
                                label="Filter Last Name"
                                variant="outlined"
                                fullWidth
                                value={filteredLastName}
                                data-test={PatientEnum.FILTER_LAST_NAME}
                                onChange={e => {
                                    setFilteredLastName(e.target.value);
                                    localStorage.setItem(
                                        FilterFields.filteredLastName,
                                        e.target.value,
                                    );
                                }}
                            />
                        </Grid>
                    )}
                    {showFilterField(FilterFields.filteredExternalId) && (
                        <Grid item xs={6}>
                            <TextField
                                label="Filter External ID"
                                variant="outlined"
                                fullWidth
                                value={filteredExternalId}
                                onChange={e => {
                                    setFilteredExternalId(e.target.value);
                                    localStorage.setItem(
                                        FilterFields.filteredExternalId,
                                        e.target.value,
                                    );
                                }}
                            />
                        </Grid>
                    )}
                    {showFilterField(FilterFields.filteredBirthDate) && (
                        <Grid item xs={6}>
                            <DatePicker
                                id="date-picker-dialog"
                                label="Birth Date"
                                autoOk
                                margin="dense"
                                variant="inline"
                                format="MM/dd/yyyy"
                                InputLabelProps={{ shrink: true }}
                                KeyboardButtonProps={{
                                    'aria-label': 'change date',
                                }}
                                value={filteredBirthDate ? moment(filteredBirthDate) : null}
                                onChange={(e: Date) => {
                                    setFilteredBirthDate(e ? moment(e).format('MM/DD/YYYY') : '');
                                    localStorage.setItem(
                                        FilterFields.filteredBirthDate,
                                        e?.toDateString() ?? '',
                                    );
                                }}
                            />
                        </Grid>
                    )}
                    {showFilterField(FilterFields.filteredEmail) && (
                        <Grid item xs={6}>
                            <TextField
                                label="Filter Email"
                                variant="outlined"
                                fullWidth
                                value={filteredEmail}
                                onChange={e => {
                                    setFilteredEmail(e.target.value);
                                    localStorage.setItem(
                                        FilterFields.filteredEmail,
                                        e.target.value,
                                    );
                                }}
                            />
                        </Grid>
                    )}
                    {showFilterField(FilterFields.filteredPhoneNumber) && (
                        <Grid item xs={6}>
                            <TextField
                                label="Filter Phone Number"
                                variant="outlined"
                                fullWidth
                                value={filteredPhoneNumber}
                                type="number"
                                onChange={e => {
                                    setFilteredPhoneNumber(e.target.value);
                                    localStorage.setItem(
                                        FilterFields.filteredPhoneNumber,
                                        e.target.value,
                                    );
                                }}
                            />
                        </Grid>
                    )}
                    {showFilterField(FilterFields.filteredMemberId) && (
                        <Grid item xs={6}>
                            <TextField
                                label="Filter Member ID"
                                variant="outlined"
                                fullWidth
                                value={filteredMemberId}
                                onChange={e => {
                                    setFilteredMemberId(e.target.value);
                                    localStorage.setItem(
                                        FilterFields.filteredMemberId,
                                        e.target.value,
                                    );
                                }}
                            />
                        </Grid>
                    )}
                </Grid>
                {showFilterField(FilterFields.filteredClinics) && (
                    <Autocomplete
                        multiple
                        options={
                            careTeamMembers?.careTeamMembersByCurrentOrg.map(member => member.id) ??
                            []
                        }
                        getOptionLabel={member =>
                            careTeamMembers?.careTeamMembersByCurrentOrg.find(m => m.id === member)
                                ?.label.en ?? ''
                        }
                        filterSelectedOptions
                        value={selectedClinicIds as string[]}
                        onChange={handleSelectClinic}
                        disableCloseOnSelect
                        renderInput={params => (
                            // eslint-disable-next-line react/jsx-props-no-spreading
                            <TextField {...params} label="Filter Clinics" variant="outlined" />
                        )}
                    />
                )}
                {showFilterField(FilterFields.filteredLanguage) && (
                    <Autocomplete
                        multiple
                        options={registrationLanguageChoices}
                        getOptionLabel={(choice: Choice) => choice?.label?.en ?? ''}
                        filterSelectedOptions
                        value={registrationLanguageChoices.filter(choice =>
                            selectedLanguageChoiceIds.includes(choice?.id),
                        )}
                        onChange={(_, newChoices: Choice[]) => {
                            const newIds = newChoices.map(choice => choice?.id);
                            setSelectedLanguageChoiceIds(newIds);
                        }}
                        disableCloseOnSelect
                        renderInput={params => (
                            <TextField {...params} label="Filter Languages" variant="outlined" />
                        )}
                    />
                )}
                {showFilterField(FilterFields.filteredAffiliateId) && (
                    <Grid item xs={12}>
                        <AffiliateFilterInput
                            onChange={(affiliateId: string) => {
                                setFilteredAffiliateId(affiliateId);
                                localStorage.setItem(FilterFields.filteredAffiliateId, affiliateId);
                            }}
                            affiliateId={
                                selectedAffiliate?.virtualCareAffiliates?.results[0]?.affiliateId ||
                                ''
                            }
                            affiliateName={
                                selectedAffiliate?.virtualCareAffiliates?.results[0]
                                    ?.internalName || ''
                            }
                        />
                    </Grid>
                )}
                {showFilterField(FilterFields.filterTestData) && (
                    <Grid item xs={12}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={filterTestData}
                                    onChange={() => {
                                        setFilterTestData(!filterTestData);
                                        localStorage.setItem(
                                            FilterFields.filterTestData,
                                            `${!filterTestData}`,
                                        );
                                    }}
                                />
                            }
                            label="Enable Test Data To Be Displayed"
                        />
                    </Grid>
                )}
                <Button onClick={handleClearFilters}>Clear Filters</Button>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        setSelectedTagIds(
                            localStorage
                                .getItem(FilterFields.filteredTags)
                                ?.split(',')
                                .filter(str => str.length > 1) ?? [],
                        );
                        setSelectedAdvocate(
                            localStorage.getItem(FilterFields.filteredAdvocate) ?? '',
                        );
                        setSelectedClinicIds(
                            localStorage
                                .getItem(FilterFields.filteredClinics)
                                ?.split(',')
                                .filter(str => str.length > 1) ?? [],
                        );
                        onClose();
                    }}
                    color="primary"
                >
                    Close
                </Button>
                <Button
                    onClick={handleSave}
                    color="primary"
                    variant="contained"
                    data-test={PatientEnum.SUBMIT_FILTER}
                >
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
