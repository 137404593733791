// This component has a UI Test
import MaterialTable, { Column, MaterialTableProps, OrderByCollection } from '@material-table/core';
import {
    Add,
    Download,
    FilterList,
    InfoOutlined,
    Loop,
    MoreVert,
    Search,
} from '@mui/icons-material';
import {
    Badge,
    Box,
    CircularProgress,
    Dialog,
    IconButton,
    InputAdornment,
    Menu,
    MenuItem,
    Link as MuiLink,
    TablePagination,
    TextField,
    Tooltip,
    Typography,
} from '@mui/material';
import copy from 'copy-to-clipboard';
import { format, toDate } from 'date-fns-tz';
import { useAtom } from 'jotai';
import moment from 'moment';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import { PAGESIZE } from '~/constants';
import { UserProfileDefsEnum } from '~/enums/UserProfileDefsEnum';
import tableIcons from '~/helpers/tableIcons';
import { useVcUserView, useUserPermissions } from '~/hooks';
import {
    AlertSeverity,
    EligibilityRefreshOrigin,
    FetchPatientsForPatientsPageQueryQuery,
    FetchPatientsForPatientsPageQueryQueryVariables,
    OrderByDirectionEnum,
    PatientFilterFilterInput,
    ProfileValueFilterComparison,
    ProfileValueFilterType,
    useDeletePatientOnPatientsPageMutation,
    useFetchPatientsExportQuery,
    useFetchPatientsForPatientsPageQueryQuery,
    useResendPatientInviteOnPatientsPageMutation,
    useResetPatientPasswordOnPatientsPageMutation,
    useUsersV2ForPatientsPageQuery,
} from '~/schemaTypes';
import { PatientEnum } from '~/selectors';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';
import { primaryColor } from '~/theme/WildTheme';
import { isString, toFormattedDateString } from '~/utils/dateValue';
import CoverageExpiration from '~/views/AffiliateCare/Patients/components/CoverageExpiration';
import CoverageType from '~/views/AffiliateCare/Patients/components/CoverageType';
import debounce from 'lodash/debounce';
import AssignToAffiliateModal from './AssignToAffiliateModal/AssignToAffiliateModal';
import { FilterColumnsModal } from './FilterColumnsModal/FilterColumnsModal';
import { FilterPatientsModal } from './FilterPatientsModal/FilterPatientsModal';
import useFilters from './FilterPatientsModal/useFilters';
import usePatientsViewData from './hooks';
import { AddToMassUpdateModal } from './MassUpdateModal/AddToMassUpdateModal';
import PatientModal from './PatientModal/PatientModal';
import PatientNotesModal from './PatientNotesModal/PatientNotesModal';
import PatientProfileVariableLink from './PatientProfileVariableLink/PatientProfileVariableLink';
import { PatientsHiddenColumnsAtom } from './Patients.context';
import SendFormEmailModal from './SendFormEmailModal/SendFormEmailModal';
import useStyles from './styles';
import {
    DOULA_COLUMNS,
    GENERAL_COLUMNS,
    GridColumns,
    IPatientOrderChange,
    PatientSortingFields,
    VC_COLUMNS,
    LC_COLUMNS,
} from './types';
import VBCPatientModal from './VBCPatientModal/VBCPatientModal';
import { QListModal } from './QListModal/QListModal';

type Patient = NonNullable<
    NonNullable<FetchPatientsForPatientsPageQueryQuery['filteredPatients']>['results'][0]
>;

const EXPORT_COUNT_LIMIT = 100;

const Patients: React.FC = () => {
    const { classes } = useStyles();
    const tableRef = useRef<HTMLDivElement>(null);
    const { isVirtualCareView } = usePatientsViewData();
    const { isDoulaView, isVcUser, isLcView } = useVcUserView();
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [order, setOrder] = useState<IPatientOrderChange>({
        field: PatientSortingFields.createdAt,
        direction: OrderByDirectionEnum.Desc,
        isInit: true,
        fieldIsProfileDefId: false,
    });
    const [searchInput, setSearchInput] = useState<string>('');

    const [filter] = useState<IFilterChange<Patient> | null>(null);
    const [openFilterPatientsModal, setOpenFilterPatientsModal] = useState<boolean>(false);
    const [hiddenColumns] = useAtom(PatientsHiddenColumnsAtom);
    const {
        values: {
            filteredTags,
            filteredAdvocate,
            filteredFirstName,
            filteredLastName,
            filteredRiskScore,
            filteredExternalId,
            filteredBirthDate,
            filteredEmail,
            filteredPhoneNumber,
            filteredMemberId,
            filterTestData,
            filteredClinics,
            filteredAffiliateId,
            filteredLanguageChoiceIds,
        },
        activeFilterCount,
    } = useFilters(isVirtualCareView);

    const filterFields = useMemo(() => {
        const fields: string[] = [];
        if (filteredTags) {
            fields.push('tagIds');
        }
        if (filteredAdvocate) {
            fields.push('assignedHealthAdvocate');
        }
        if (filteredAffiliateId) {
            fields.push('affiliateIds');
        }
        return fields;
    }, [filteredTags, filteredAdvocate, filteredAffiliateId]);

    const filterValues = useMemo(() => {
        const values: (string | null)[][] = [];
        if (filteredTags) {
            values.push(filteredTags?.split(',') || ['']);
        }
        if (filteredAdvocate) {
            if (filteredAdvocate === 'unassigned') {
                values.push([null]);
            } else {
                values.push([filteredAdvocate || '']);
            }
        }
        if (filteredAffiliateId) {
            values.push([filteredAffiliateId]);
        }
        return values;
    }, [filteredTags, filteredAdvocate, filteredAffiliateId]);

    // IMPORTANT: all patient entity related fields must use default filtering functionality from base resolver SYS-8582
    const filtersFromFilterModal = useMemo<IFilterChange<Patient>>(() => {
        const processed: IFilterChange<Patient> = {
            fields: {},
        };
        if (filteredFirstName) {
            processed.fields.firstName = filteredFirstName;
        }
        if (filteredLastName) {
            processed.fields.lastName = filteredLastName;
        }
        if (filteredRiskScore) {
            processed.fields.riskScore = filteredRiskScore;
        }
        if (filteredEmail) {
            processed.fields.email = filteredEmail;
        }
        if (filteredPhoneNumber) {
            processed.fields.phoneNumber = filteredPhoneNumber;
        }
        if (filteredExternalId) {
            processed.fields.externalId = filteredExternalId;
        }
        if (filteredBirthDate) {
            processed.fields.birthDate = filteredBirthDate;
        }
        if (filteredMemberId) {
            processed.fields.memberId = filteredMemberId;
        }
        return processed;
    }, [
        filteredFirstName,
        filteredLastName,
        filteredRiskScore,
        filteredEmail,
        filteredPhoneNumber,
        filteredExternalId,
        filteredBirthDate,
        filteredMemberId,
    ]);

    const filterProfile = useMemo(() => {
        const filterProfileData = [];

        if (filteredLanguageChoiceIds) {
            const languageChoiceIdsFilterData = filteredLanguageChoiceIds
                .split(',')
                .map(languageChoiceId => ({
                    valueType: ProfileValueFilterType.Choice,
                    comparison: ProfileValueFilterComparison.Equals,
                    profileDefId: '6746186e8233340175db4b32',
                    valueId: languageChoiceId,
                }));
            filterProfileData.push(...languageChoiceIdsFilterData);
        }

        if (filteredClinics) {
            const clinicsFilterData = filteredClinics.split(',').map(clinic => ({
                valueType: ProfileValueFilterType.Str,
                comparison: ProfileValueFilterComparison.Equals,
                profileDefId: '63a238a9cfd11ba4d06fad87',
                valueStr: clinic,
            }));
            filterProfileData.push(...clinicsFilterData);
        }

        return filterProfileData.length > 0 ? filterProfileData : undefined;
    }, [filteredClinics, filteredLanguageChoiceIds]);

    const fetchPatientsForPatientsPageQueryVariables: FetchPatientsForPatientsPageQueryQueryVariables =
        {
            input: {
                pagination: {
                    skip: page * rowsPerPage,
                    limit: rowsPerPage,
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                    fieldIsProfileDefId: order.fieldIsProfileDefId,
                },
                filter: { ...filter, ...filtersFromFilterModal } as PatientFilterFilterInput | null,
                fields: filterFields,
                values: filterValues,
                filterTestData,
                filterProfile,
                ...(searchInput && { search: searchInput.trim() }),
            },
            vcUser: isVcUser,
        };

    const {
        data: patientsPageData,
        loading: patientsDataLoading,
        refetch: refetchPatientsPageData,
    } = useFetchPatientsForPatientsPageQueryQuery({
        variables: fetchPatientsForPatientsPageQueryVariables,
        fetchPolicy: 'network-only',
        notifyOnNetworkStatusChange: true, // triggers loading to change even when calling refetch https://www.apollographql.com/docs/react/data/queries/#inspecting-loading-states
    });

    const hiddenColumn = useCallback(
        (column: keyof typeof hiddenColumns) => {
            if (hiddenColumns[column]) {
                return true;
            }
            if (isLcView && !LC_COLUMNS.includes(column)) {
                return true;
            }
            if (isDoulaView && !DOULA_COLUMNS.includes(column)) {
                return true;
            }
            if (isVirtualCareView && !VC_COLUMNS.includes(column)) {
                return true;
            }
            if (!isVcUser && !isVirtualCareView) {
                return !GENERAL_COLUMNS.includes(column);
            }
            return false;
        },
        [isVirtualCareView, hiddenColumns, isDoulaView, isLcView, isVcUser],
    );

    const [resendPatientInvite, { loading: patientInviteLoading }] =
        useResendPatientInviteOnPatientsPageMutation();

    const [resetPatientPassword, { loading: patientResetPasswordLoading }] =
        useResetPatientPasswordOnPatientsPageMutation();

    const [deletePatient, { loading: deletePatientLoading }] =
        useDeletePatientOnPatientsPageMutation({
            onCompleted: () => {
                refetchPatientsPageData();
            },
        });

    const { data: usersForAssignedHealthAdvocate, loading: usersToAssignLoading } =
        useUsersV2ForPatientsPageQuery();

    // const [importOpen, setImportOpen] = useState<boolean>(false);
    const [questionsModalOpen, setQuestionsModalOpen] = useState<boolean>(false);
    const [showPatientModal, setShowPatientModal] = useState(false);
    const [showMassUpdateModal, setShowMassUpdateModal] = useState(false);
    const [editPatientId, setEditPatientId] = useState<string>('');
    const [selectedPatient, setSelectedPatient] = useState<Patient>();
    const [patientResendLoadingId, setPatientResendLoadingId] = useState<string>('');
    const [patientResetPasswordLoadingId, setPatientResetPasswordLoadingId] = useState<string>('');
    const { pagePermissions } = useUserPermissions();
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [isPatientNotesModalOpen, setIsPatientNotesModalOpen] = useState<boolean>(false);
    const [hiddenColumnsAnchorEl, setHiddenColumnsAnchorEl] = useState<null | HTMLElement>(null);
    const [exportingStatus, setExportingStatus] = useState(false);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isAssignToAffiliateModalOpen, setIsAssignToAffiliateModalOpen] =
        useState<boolean>(false);
    const [isSendFormEmailModalOpen, setIsSendFormEmailModalOpen] = useState(false);

    const handleOpenHiddenColumnsModal = (event: React.MouseEvent<HTMLButtonElement>) => {
        setHiddenColumnsAnchorEl(event.currentTarget);
    };

    const VBC_PLATFORM = 'VBC';

    const handleResendInvite = async (id: string) => {
        resendPatientInvite({
            variables: {
                input: {
                    id,
                },
            },
        });
    };

    const handleResetPassword = async (id: string) => {
        resetPatientPassword({
            variables: {
                input: {
                    id,
                },
            },
        });
    };

    const handleMoreInfoClick = (event: any, patient: Patient) => {
        setSelectedPatient(patient);
        setAnchorEl(event.currentTarget);
    };

    const getDirection = (order: string): OrderByDirectionEnum =>
        order.toUpperCase() === OrderByDirectionEnum.Asc
            ? OrderByDirectionEnum.Asc
            : OrderByDirectionEnum.Desc;

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const onSearchChange = useCallback((searchString: string) => {
        setSearchInput(searchString);
    }, []);

    const debouncedRefetch = useMemo(
        () =>
            debounce(() => {
                refetchPatientsPageData();
            }, 500),
        [refetchPatientsPageData],
    );

    useEffect(() => {
        debouncedRefetch();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [searchInput]);

    useEffect(() => {
        if (openFilterPatientsModal) {
            setSearchInput('');
        }
    }, [openFilterPatientsModal]);

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    const getHeaderStyle = (field: PatientSortingFields): React.CSSProperties => ({
        fontWeight: order.field === field ? 'bold' : 'initial',
    });
    const getRiskColorStyles = (riskValue: number): React.CSSProperties => {
        const defaultStyle = {
            backgroundColor: 'white',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
            minHeight: 86,
            height: '100%',
            flexDirection: 'column' as any,
            paddingTop: '16px',
            paddingBottom: '16px',
            lineHeight: 1,
            fontWeight: 'normal',
        };

        switch (riskValue) {
            case 1:
                defaultStyle.backgroundColor = '#D9EAD3';
                break;
            case 2:
            case 3:
                defaultStyle.backgroundColor = '#FFF2CC';
                break;
            case 4:
            case 5:
                defaultStyle.backgroundColor = '#E6B8AF';
                defaultStyle.fontWeight = 'bold';
                break;
            case 6:
                defaultStyle.backgroundColor = '#D9D3E9';
                defaultStyle.fontWeight = 'bold';
                break;
            default:
                break;
        }

        return defaultStyle;
    };

    let PatientAddModal = null;
    let showAddPatientButton = true;
    const parentPlatform = patientsPageData?.currentUser?.currentOrganization?.appPlatform ?? null;
    const parentBundleId = patientsPageData?.currentUser?.currentOrganization?.appBundleId ?? null;
    const usersForAssignedHealthAdvocateList = usersForAssignedHealthAdvocate?.usersV2?.results;
    const isVBCPlatform = parentPlatform === VBC_PLATFORM;
    if (isVcUser) {
        showAddPatientButton = false;
    }
    if (isVBCPlatform) {
        if (parentBundleId == null) {
            showAddPatientButton = false;
        } else {
            PatientAddModal = (
                <VBCPatientModal
                    setOpen={setShowPatientModal}
                    onCreateCompleted={refetchPatientsPageData}
                    bundleId={parentBundleId}
                    setEditPatientId={setEditPatientId}
                    id={editPatientId}
                />
            );
        }
    } else {
        PatientAddModal = (
            <PatientModal
                setOpen={setShowPatientModal}
                setEditPatientId={setEditPatientId}
                id={editPatientId}
                onCreateCompleted={refetchPatientsPageData}
            />
        );
    }

    const { refetch: fetchPatientsBuffer } = useFetchPatientsExportQuery({
        fetchPolicy: 'no-cache',
        // prevent query execution on each render
        // lazy query executes on each variable update
        skip: true,
    });

    const riskScoreEnabled =
        patientsPageData?.currentUser?.currentOrganization?.configOptions?.riskScoreEnabled ??
        false;

    const handleDownloadPatientsCsv = async () => {
        if (exportingStatus) return;

        setExportingStatus(true);

        const buildExcludedColumns = () => {
            return Object.keys(
                Object.fromEntries(
                    Object.entries(hiddenColumns).filter(([column, cValue]) => {
                        if (!isVirtualCareView) {
                            return cValue;
                        }
                        return !VC_COLUMNS.includes(column as unknown as GridColumns) || cValue;
                    }),
                ),
            );
        };

        const { data } = await fetchPatientsBuffer({
            input: {
                filter: {
                    ...filter,
                    ...filtersFromFilterModal,
                } as PatientFilterFilterInput | null,
                fields: filterFields,
                values: filterValues,
                filterTestData,
                exclude: buildExcludedColumns(),
                filterProfile,
            },
        });

        setExportingStatus(false);
        const csvData = data.exportPatients?.data;
        if (csvData) {
            const { buffer } = new Uint8Array(csvData as unknown as Buffer);
            const blob = new Blob([buffer]);
            const link = document.createElement('a');
            link.setAttribute('href', window.URL.createObjectURL(blob));
            link.setAttribute('download', data.exportPatients?.fileName || '');
            document.body.appendChild(link);
            link.click();
            link.remove();
        }
    };

    useEffect(() => {
        if ((patientsDataLoading || deletePatientLoading || usersToAssignLoading) && !searchInput) {
            setIsLoading(true);
        } else {
            setIsLoading(false);
        }
    }, [patientsDataLoading, deletePatientLoading, usersToAssignLoading, searchInput]);

    const columns: Column<Patient>[] = [
        {
            title: 'Id',
            field: 'id',
            width: 32,
            sorting: false,
            align: 'center',
            hidden: !isVirtualCareView && !isVcUser,
            render: ({ id }) => (
                <Tooltip title={id}>
                    <IconButton
                        onClick={() => {
                            copy(`${id}`);
                            TriggerGlobalAlert({
                                message: 'Patient Id Copied to Clipboard',
                                severity: AlertSeverity.Success,
                            });
                        }}
                        size="large"
                    >
                        <InfoOutlined />
                    </IconButton>
                </Tooltip>
            ),
        },
        {
            title: 'Invitation Code',
            field: 'invitationCode',
            width: 32,
            sorting: false,
            align: 'center',
            hidden: hiddenColumn(GridColumns.invitationCode),
        },
        {
            title: 'External Id',
            field: 'externalId',
            align: 'center',
            sorting: false,
            hidden: hiddenColumn(GridColumns.externalId),
            render: ({ externalId }) => {
                if (externalId) {
                    return (
                        <Tooltip title={externalId ?? ''}>
                            <IconButton
                                onClick={() => {
                                    copy(`${externalId}`);
                                    TriggerGlobalAlert({
                                        message: 'External Id Copied to Clipboard',
                                        severity: AlertSeverity.Success,
                                    });
                                }}
                                size="large"
                            >
                                <InfoOutlined />
                            </IconButton>
                        </Tooltip>
                    );
                }
                return '';
            },
        },
        {
            title: 'First Name',
            field: 'firstName',
            width: 32,
            render: ({ id, firstName }) =>
                pagePermissions?.PatientDetails.Read ? (
                    <MuiLink
                        to={`/portal/patients/${id}`}
                        component={Link}
                        className={classes.tasksButton}
                        underline="hover"
                    >
                        <Typography>{firstName}</Typography>
                    </MuiLink>
                ) : (
                    <Typography>{firstName}</Typography>
                ),
            customSort: (a, b) => {
                return a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase());
            },
            headerStyle: getHeaderStyle(PatientSortingFields.firstName),
            hidden: hiddenColumn(GridColumns.firstName),
        },
        {
            title: 'Last Name',
            field: 'lastName',
            width: 32,
            render: ({ id, lastName }) =>
                pagePermissions?.PatientDetails.Read ? (
                    <MuiLink
                        to={`/portal/patients/${id}`}
                        component={Link}
                        className={classes.tasksButton}
                        underline="hover"
                    >
                        <Typography>{lastName}</Typography>
                    </MuiLink>
                ) : (
                    <Typography>{lastName}</Typography>
                ),
            customSort: (a, b) => {
                return a.lastName.toLowerCase().localeCompare(b.lastName.toLowerCase());
            },
            headerStyle: getHeaderStyle(PatientSortingFields.lastName),
            hidden: hiddenColumn(GridColumns.lastName),
        },
        {
            title: 'Risk Score',
            field: 'riskScore',
            render: ({ riskScore }) => (
                <Typography sx={getRiskColorStyles(riskScore?.value ?? 0)}>
                    {riskScore?.tierName && (
                        <>
                            <span>{riskScore?.tierName}</span>
                            <br />
                        </>
                    )}
                    <span>{riskScore?.value}</span>
                </Typography>
            ),
            customSort: (a, b) => {
                const firstVal = a.riskScore?.value ?? 0;
                const secondVal = b.riskScore?.value ?? 0;
                return firstVal - secondVal;
            },
            width: 32,
            cellStyle: { padding: 0 },
            headerStyle: getHeaderStyle(PatientSortingFields.riskScore),
            hidden:
                !riskScoreEnabled ||
                !pagePermissions?.PatientRiskScore.Read ||
                hiddenColumn(GridColumns.riskScore),
        },
        {
            title: 'Created',
            field: 'createdAt',
            render: ({ createdAt }) => (
                <Typography>{createdAt && moment.utc(createdAt).format('MM/DD/yyyy')}</Typography>
            ),
            customSort: (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime(),
            headerStyle: getHeaderStyle(PatientSortingFields.createdAt),
            hidden: hiddenColumn(GridColumns.createdAt),
        },
        {
            title: 'Assigned Health Advocate',
            field: 'assignedHealthAdvocate',
            render: ({ assignedHealthAdvocate }) => (
                <Typography>
                    {
                        usersForAssignedHealthAdvocateList?.find(
                            user => user.id === assignedHealthAdvocate,
                        )?.name
                    }
                </Typography>
            ),
            headerStyle: getHeaderStyle(PatientSortingFields.assignedHealthAdvocate),
            hidden: hiddenColumn(GridColumns.assignedHealthAdvocate),
        },
        {
            title: 'Open Tasks',
            field: 'openAdvocateTaskCount',
            disableClick: !pagePermissions?.HealthAdvocate.Read,
            render: ({ openAdvocateTaskCount, id }) =>
                pagePermissions?.HealthAdvocate?.Read ? (
                    <MuiLink
                        to={`/portal/patients/${id}/ha-tasks`}
                        component={Link}
                        className={classes.tasksButton}
                        underline="hover"
                    >
                        <Typography>{openAdvocateTaskCount}</Typography>
                    </MuiLink>
                ) : (
                    <Typography>{openAdvocateTaskCount}</Typography>
                ),
            hidden: hiddenColumn(GridColumns.openAdvocateTaskCount),
            sorting: false,
        },
        {
            title: 'Open Critical Tasks',
            field: 'openCriticalAdvocateTaskCount',
            disableClick: !pagePermissions?.HealthAdvocate.Read,
            render: ({ openCriticalAdvocateTaskCount, id }) =>
                pagePermissions?.HealthAdvocate.Read ? (
                    <MuiLink
                        to={`/portal/patients/${id}/ha-tasks`}
                        component={Link}
                        className={classes.tasksButton}
                        underline="hover"
                    >
                        <Typography>{openCriticalAdvocateTaskCount}</Typography>
                    </MuiLink>
                ) : (
                    <Typography>{openCriticalAdvocateTaskCount}</Typography>
                ),
            hidden: hiddenColumn(GridColumns.openCriticalAdvocateTaskCount),
            sorting: false,
        },
        {
            title: 'Call Status',
            width: 32,
            sorting: false,
            align: 'center',
            hidden: hiddenColumn(GridColumns.patientCallStatus),
            render: ({ id, patientCallStatus, appBundleId }) => (
                <PatientProfileVariableLink
                    patientId={id}
                    appBundleId={appBundleId || ''}
                    profileVariableDefId={UserProfileDefsEnum.CALL_STATUS}
                >
                    {updatedPatientCallStatus => (
                        <Typography>{updatedPatientCallStatus ?? patientCallStatus}</Typography>
                    )}
                </PatientProfileVariableLink>
            ),
        },
        {
            title: 'Birth Date',
            field: 'birthDate',
            render: ({ birthDate, id, appBundleId }) => (
                <PatientProfileVariableLink
                    patientId={id}
                    appBundleId={appBundleId || ''}
                    profileVariableDefId={UserProfileDefsEnum.BIRTH_DATE}
                >
                    {updatedBirthDate => (
                        <Typography>
                            {(updatedBirthDate ?? birthDate) &&
                                format(
                                    // dates are recieved as UTC ISO strings by default, we need to screen out the timestamp to avoid recalculation
                                    toDate((updatedBirthDate ?? birthDate).split('T')[0]),
                                    'MM/dd/yyyy',
                                )}
                        </Typography>
                    )}
                </PatientProfileVariableLink>
            ),
            customSort: (a, b) =>
                new Date(a.birthDate ?? '').getTime() - new Date(b.birthDate ?? '').getTime(),
            headerStyle: getHeaderStyle(PatientSortingFields.birthDate),
            hidden: hiddenColumn(GridColumns.birthDate),
        },
        {
            title: 'Coverage Type',
            field: 'insurancePlans.type',
            hidden: hiddenColumn(GridColumns.coverageType),
            sorting: false,
            render: ({ insurancePlans }) => <CoverageType insurancePlans={insurancePlans ?? []} />,
        },
        {
            title: 'Organization',
            field: 'practice.name',
            sorting: false,
            render: ({ id, practice }) =>
                pagePermissions?.PatientCareTeam.Read ? (
                    <MuiLink
                        to={`/portal/patients/${id}/care-team`}
                        component={Link}
                        className={classes.tasksButton}
                        underline="hover"
                    >
                        <Typography>{practice.name}</Typography>
                    </MuiLink>
                ) : (
                    <Typography>{practice.name}</Typography>
                ),
            hidden: hiddenColumn(GridColumns.organization),
        },
        {
            title: 'Clinic',
            field: 'clinic',
            sorting: false,
            render: ({ id, clinic }) => {
                return (
                    <MuiLink
                        to={`/portal/patients/${id}/care-team`}
                        component={Link}
                        className={classes.tasksButton}
                        underline="hover"
                    >
                        <Typography>{clinic}</Typography>
                    </MuiLink>
                );
            },
            hidden: hiddenColumn(GridColumns.clinic),
        },
        {
            title: 'Onboarding Completed',
            field: 'onboardingCompleteDate',
            render: ({ onboardingCompleteDate }) => (
                <Typography>
                    {onboardingCompleteDate
                        ? moment.utc(onboardingCompleteDate).format('MM/DD/yyyy')
                        : 'Not Complete'}
                </Typography>
            ),
            customSort: (a, b) =>
                new Date(a.onboardingCompleteDate).getTime() -
                new Date(b.onboardingCompleteDate).getTime(),
            hidden: hiddenColumn(GridColumns.onboardingCompleteDate),
            headerStyle: getHeaderStyle(PatientSortingFields.onboardingCompleteDate),
        },
        {
            title: 'Is Pregnant',
            field: 'isPregnant',
            render: ({ isPregnant, id, appBundleId }) => (
                <PatientProfileVariableLink
                    patientId={id}
                    appBundleId={appBundleId || ''}
                    profileVariableDefId={UserProfileDefsEnum.IS_PREGNANT}
                >
                    {updatedIsPregnant => (
                        <Typography>{updatedIsPregnant ?? isPregnant ? 'Yes' : 'No'}</Typography>
                    )}
                </PatientProfileVariableLink>
            ),
            customSort: (a, b) => (a.isPregnant ? 1 : 0) - (b.isPregnant ? 1 : 0),
            hidden: hiddenColumn(GridColumns.isPregnant),
            headerStyle: getHeaderStyle(PatientSortingFields.isPregnant),
        },
        {
            title: 'Due Date',
            field: 'dueDate',
            render: ({ dueDate, id, appBundleId }) => (
                <PatientProfileVariableLink
                    patientId={id}
                    appBundleId={appBundleId || ''}
                    profileVariableDefId={UserProfileDefsEnum.DUE_DATE}
                >
                    {updatedDueDate => {
                        const dateValue = updatedDueDate ?? dueDate;
                        let formattedDateString = '';
                        if (isString(dateValue)) {
                            formattedDateString = toFormattedDateString(dateValue);
                        }
                        return <Typography>{formattedDateString}</Typography>;
                    }}
                </PatientProfileVariableLink>
            ),
            customSort: (a, b) =>
                new Date(a.dueDate ?? '').getTime() - new Date(b.dueDate ?? '').getTime(),
            hidden: hiddenColumn(GridColumns.dueDate),
            headerStyle: getHeaderStyle(PatientSortingFields.dueDate),
        },
        {
            title: 'Delivery Date',
            field: 'deliveryDate',
            render: ({ deliveryDate, id, appBundleId }) => (
                <PatientProfileVariableLink
                    patientId={id}
                    appBundleId={appBundleId || ''}
                    profileVariableDefId={UserProfileDefsEnum.DELIVERY_DATE}
                >
                    {updatedDeliveryDate => {
                        const dateValue = updatedDeliveryDate ?? deliveryDate;
                        let formattedDateString = '';
                        if (isString(dateValue)) {
                            formattedDateString = toFormattedDateString(dateValue);
                        }
                        return <Typography>{formattedDateString}</Typography>;
                    }}
                </PatientProfileVariableLink>
            ),
            customSort: (a, b) =>
                new Date(a.deliveryDate ?? '').getTime() - new Date(b.deliveryDate ?? '').getTime(),
            hidden: hiddenColumn(GridColumns.deliveryDate),
            headerStyle: getHeaderStyle(PatientSortingFields.deliveryDate),
        },
        {
            title: 'Coverage Expiration',
            field: 'insurancePlans',
            render: ({ insurancePlans, id }) => (
                <CoverageExpiration
                    insurancePlans={insurancePlans ?? []}
                    patientId={id}
                    isDoulaView={isDoulaView}
                    origin={
                        isDoulaView
                            ? EligibilityRefreshOrigin.AffiliatePortal
                            : EligibilityRefreshOrigin.PatientPortal
                    }
                />
            ),
            cellStyle: { padding: 0 },
            hidden: hiddenColumn(GridColumns.coverageExpiration),
        },
        {
            title: 'Email',
            field: 'email',
            hidden: hiddenColumn(GridColumns.email),
        },
        {
            title: 'Phone Number',
            field: 'phoneNumber',
            hidden: hiddenColumn(GridColumns.phoneNumber),
            sorting: false,
        },
        {
            title: 'Registration Language',
            field: 'language',
            sorting: false,
            align: 'center',
            hidden: hiddenColumn(GridColumns.language),
        },
        {
            title: 'Patient ID',
            field: 'id',
            width: 32,
            sorting: false,
            align: 'center',
            hidden: hiddenColumn(GridColumns.id),
        },
        {
            title: 'Affiliate(s)',
            field: 'affiliates.internalName',
            sorting: false,
            hidden: hiddenColumn(GridColumns.affiliates),
            render: ({ affiliates, id }) =>
                affiliates?.filter(Boolean).map((affiliate, index, filteredAffiliates) => (
                    <Box sx={{ display: 'flex' }}>
                        {isVcUser ? (
                            <Typography>{affiliate?.internalName}</Typography>
                        ) : (
                            <MuiLink
                                to={`/app-config/vcaffiliates/patients/${affiliate?.affiliateId}/${id}`}
                                component={Link}
                                className={classes.tasksButton}
                                underline="hover"
                            >
                                <Typography>{affiliate?.internalName}</Typography>
                            </MuiLink>
                        )}
                        {index !== filteredAffiliates?.length - 1 ? ', ' : ''}
                    </Box>
                )),
        },
    ];

    const handleOrderChange = (orderByCollection: OrderByCollection[]) => {
        // material table passes empty array some times, which causes undefined error
        if (!orderByCollection || orderByCollection.length === 0) {
            return;
        }
        const orderBy = orderByCollection[0];

        const field = columns.find((_, index) => index === orderBy.orderBy)?.field;

        let isInit = false;

        if (field) {
            if (field !== order.field) {
                isInit = true;
            }
            const direction = getDirection(orderBy.orderDirection);

            const stateObject: IPatientOrderChange = {
                direction,
                field: field as IPatientOrderChange['field'],
                isInit,
                fieldIsProfileDefId: false,
            };

            setOrder(stateObject);
        }
    };

    return (
        <div
            className={classes.root}
            data-test={PatientEnum.PATIENTS_CONTAINER}
            ref={tableRef}
            style={{ overflow: 'auto' }}
        >
            {(isDoulaView || isLcView) && (
                <TextField
                    variant="outlined"
                    placeholder="First, Last or Email"
                    value={searchInput}
                    onChange={e => onSearchChange(e.target.value)}
                    style={{ position: 'absolute', right: '230px', top: '35px', zIndex: 1 }}
                    margin="normal"
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <Search />
                            </InputAdornment>
                        ),
                    }}
                />
            )}
            <MaterialTable<Patient>
                isLoading={isLoading}
                icons={tableIcons as MaterialTableProps<any>['icons']}
                actions={[
                    {
                        tooltip: 'Filter Patients',
                        onClick: () => {
                            setOpenFilterPatientsModal(true);
                        },
                        icon: () => (
                            <Badge
                                badgeContent={activeFilterCount}
                                color="error"
                                data-test={PatientEnum.FILTER_PATIENTS_BUTTON}
                                style={{
                                    padding: '12px',
                                    backgroundColor: primaryColor,
                                    color: 'white',
                                    fontSize: '1rem',
                                    borderRadius: '1rem',
                                }}
                            >
                                Filter Patients
                            </Badge>
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: event => {
                            handleOpenHiddenColumnsModal(event);
                        },
                        tooltip: 'Filter Columns',
                        icon: () => <FilterList />,
                        isFreeAction: true,
                    },
                    {
                        onClick: () => {
                            setEditPatientId('');
                            setShowPatientModal(true);
                        },
                        hidden: !pagePermissions?.PatientDetails.Edit || !showAddPatientButton,
                        icon: () => <Add />,
                        tooltip: !patientsPageData?.currentUser?.currentOrganization
                            .allowPatientCreation
                            ? 'Patient creation not allowed'
                            : 'Add New Patient',
                        isFreeAction: true,
                        disabled:
                            !patientsPageData?.currentUser?.currentOrganization
                                .allowPatientCreation,
                    },
                    {
                        onClick: (e, patient) => handleMoreInfoClick(e, patient as Patient),
                        icon: () => <MoreVert />,
                    },
                    {
                        tooltip: 'Export CSV',
                        onClick: handleDownloadPatientsCsv,
                        icon: () =>
                            exportingStatus ? <Loop className={classes.rotating} /> : <Download />,
                        isFreeAction: true,
                        hidden:
                            !pagePermissions?.PatientDetails.Export ||
                            (patientsPageData?.filteredPatients?.total ?? 0) > EXPORT_COUNT_LIMIT,
                    },
                ]}
                columns={columns}
                data={patientsPageData?.filteredPatients?.results ?? []}
                onOrderCollectionChange={handleOrderChange}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={patientsPageData?.filteredPatients?.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{
                    pageSize: rowsPerPage,
                    pageSizeOptions: [25, 50, 100],
                    search: false,
                    exportAllData: pagePermissions?.PatientDetails.Export,
                    paginationPosition: 'both',
                    actionsColumnIndex: isDoulaView ? -1 : 0,
                }}
                localization={{
                    header: {
                        actions: isDoulaView ? '' : 'Actions',
                    },
                }}
                title="Patients"
            />
            <AddToMassUpdateModal
                isOpen={showMassUpdateModal}
                onClose={() => {
                    setShowMassUpdateModal(false);
                }}
                patientId={selectedPatient?.id}
            />

            <Dialog
                scroll="paper"
                open={showPatientModal}
                fullWidth
                aria-labelledby="form-dialog-title"
                data-test={PatientEnum.PATIENT_MODAL}
            >
                {PatientAddModal}
            </Dialog>
            <Menu
                id="patient-drop-down"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={() => {
                    setAnchorEl(null);
                }}
            >
                {pagePermissions?.PatientDetails.Read && (
                    <MenuItem to={`/portal/patients/${selectedPatient?.id}`} component={Link}>
                        View Patient Details
                    </MenuItem>
                )}
                {pagePermissions?.VcExternalForm.Edit && (isVirtualCareView || isVcUser) && (
                    <MenuItem
                        onClick={() => {
                            setIsSendFormEmailModalOpen(true);
                        }}
                    >
                        Send Form
                    </MenuItem>
                )}
                {pagePermissions?.Appointments.Read && (
                    <MenuItem
                        to={`/portal/patients/${selectedPatient?.id}/appointments`}
                        component={Link}
                    >
                        Appointments
                    </MenuItem>
                )}
                {pagePermissions?.Measurements.Read && !isVirtualCareView && (
                    <MenuItem
                        to={`/portal/patients/${selectedPatient?.id}/measurements`}
                        component={Link}
                    >
                        Measurements
                    </MenuItem>
                )}
                {pagePermissions?.SurveyResponses.Read && !isVirtualCareView && (
                    <MenuItem
                        to={`/portal/patients/${selectedPatient?.id}/survey-responses`}
                        component={Link}
                    >
                        Surveys
                    </MenuItem>
                )}
                {pagePermissions?.PatientQuestions.Read && !isVirtualCareView && (
                    <MenuItem
                        onClick={() => {
                            setQuestionsModalOpen(true);
                            setAnchorEl(null);
                        }}
                    >
                        Patient Q-List
                    </MenuItem>
                )}
                {pagePermissions?.MessageCenter.Read && !isVirtualCareView && (
                    <MenuItem to={`/portal/patients/${selectedPatient?.id}/chat`} component={Link}>
                        Message Center
                    </MenuItem>
                )}
                {pagePermissions?.PatientNotes.Read && (
                    <MenuItem to={`/portal/patients/${selectedPatient?.id}/notes`} component={Link}>
                        Notes
                    </MenuItem>
                )}
                {pagePermissions?.PatientDetails.Edit && (
                    <MenuItem
                        onClick={() => {
                            setEditPatientId(selectedPatient?.id);
                            setShowPatientModal(true);
                        }}
                    >
                        Edit Patient
                    </MenuItem>
                )}
                {(isVirtualCareView || isLcView) && (
                    <MenuItem
                        disabled={
                            !isLcView &&
                            !!selectedPatient?.affiliateIds &&
                            selectedPatient?.affiliateIds?.length !== 0
                        }
                        onClick={() => {
                            setIsAssignToAffiliateModalOpen(true);
                            setAnchorEl(null);
                        }}
                    >
                        Assign Patient to Affiliate
                    </MenuItem>
                )}
                {pagePermissions?.PatientDetails.Edit && (
                    <MenuItem
                        disabled={
                            (patientInviteLoading &&
                                patientResendLoadingId === selectedPatient?.id) ||
                            !selectedPatient?.invitationCode
                        }
                        onClick={() => {
                            TriggerGlobalConfirm({
                                message: `Are you sure you want to resend invite?`,
                                callback: () => {
                                    handleResendInvite(selectedPatient?.id);
                                    setPatientResendLoadingId(selectedPatient?.id);
                                },
                            });
                        }}
                    >
                        <span>Resend Invite</span>
                        {patientInviteLoading && patientResendLoadingId === selectedPatient?.id && (
                            <CircularProgress size={20} style={{ marginLeft: 5 }} />
                        )}
                    </MenuItem>
                )}
                {pagePermissions?.PatientDetails.Edit && (
                    <MenuItem
                        disabled={
                            patientResetPasswordLoading &&
                            patientResetPasswordLoadingId === selectedPatient?.id
                        }
                        onClick={() => {
                            TriggerGlobalConfirm({
                                message: `Are you sure you want to reset the password?`,
                                callback: () => {
                                    handleResetPassword(selectedPatient?.id);
                                    setPatientResetPasswordLoadingId(selectedPatient?.id);
                                },
                            });
                        }}
                    >
                        <span>Reset Password</span>
                        {patientResetPasswordLoading &&
                            patientResetPasswordLoadingId === selectedPatient?.id && (
                                <CircularProgress size={20} style={{ marginLeft: 5 }} />
                            )}
                    </MenuItem>
                )}
                {pagePermissions?.MassUpdate.Edit && !isVirtualCareView && (
                    <MenuItem
                        onClick={() => {
                            setShowMassUpdateModal(true);
                            setAnchorEl(null);
                        }}
                    >
                        Add to Mass Update List
                    </MenuItem>
                )}

                {pagePermissions?.PatientDetails.Delete && (
                    <MenuItem
                        onClick={() => {
                            if (selectedPatient) {
                                const { id, firstName, lastName } = selectedPatient;
                                TriggerGlobalConfirm({
                                    message: `Do you want to delete the patient ${firstName} ${lastName}`,
                                    callback: () => {
                                        deletePatient({
                                            variables: {
                                                input: {
                                                    id,
                                                },
                                            },
                                        });
                                    },
                                });
                            }
                            setAnchorEl(null);
                        }}
                        className={classes.deletePatientTitle}
                    >
                        Delete Patient
                    </MenuItem>
                )}
            </Menu>
            {isAssignToAffiliateModalOpen && (
                <AssignToAffiliateModal
                    isOpen={isAssignToAffiliateModalOpen}
                    handleClose={() => setIsAssignToAffiliateModalOpen(false)}
                    onCompleteHandle={refetchPatientsPageData}
                    patientId={selectedPatient?.id}
                    patientFullName={selectedPatient?.fullName ?? ''}
                    patientBirthDate={selectedPatient?.birthDate}
                    patientZipCode={selectedPatient?.mailingAddress?.code ?? ''}
                    patientAffiliates={selectedPatient?.affiliateIds ?? []}
                />
            )}
            {isPatientNotesModalOpen && (
                <PatientNotesModal
                    handleClose={() => setIsPatientNotesModalOpen(false)}
                    isModalOpen={isPatientNotesModalOpen}
                    patientId={selectedPatient?.id}
                />
            )}
            {!isVcUser && selectedPatient && (
                <QListModal
                    patientId={selectedPatient.id}
                    isModalOpen={questionsModalOpen}
                    handleClose={() => setQuestionsModalOpen(false)}
                />
            )}
            {selectedPatient && (isVirtualCareView || isVcUser) && (
                <SendFormEmailModal
                    isModalOpen={isSendFormEmailModalOpen}
                    handleClose={() => {
                        setIsSendFormEmailModalOpen(false);
                    }}
                    patientId={selectedPatient.id}
                />
            )}
            <FilterColumnsModal
                hiddenColumnsAnchorEl={hiddenColumnsAnchorEl}
                setHiddenColumnsAnchorEl={setHiddenColumnsAnchorEl}
                isAffiliateCareView={isVirtualCareView}
            />
            <FilterPatientsModal
                open={openFilterPatientsModal}
                onClose={() => setOpenFilterPatientsModal(false)}
                isAffiliateCareView={isVirtualCareView}
            />
        </div>
    );
};

export default Patients;
