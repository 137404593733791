import { EligibilityImportResultForEligibilityImportQuery } from '~/schemaTypes';
import {
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { makeStyles } from 'tss-react/mui';

type EligibilityImportResult = NonNullable<
    NonNullable<EligibilityImportResultForEligibilityImportQuery['eligibilityImportResult']>['data']
>;

const useStyles = makeStyles()({
    checkBox: {
        marginTop: 8,
        marginLeft: 10,
    },
});

const EligibilityImportResultForm = ({ data }: { data: EligibilityImportResult }) => {
    const history = useNavigate();
    const location = useLocation();
    const { classes } = useStyles();

    const handleBackClick = () => {
        history(-1);
    };

    const getBackButtonLabel = () => {
        const fromPathname = location.state?.from?.pathname;

        if (fromPathname?.includes('eligibility-configs')) {
            return 'Back to Eligibility Configs';
        }

        return 'Back to Eligibility Imports';
    };

    return (
        <Grid container spacing={2} item xs={12}>
            <Grid item xs={12}>
                <Button component={Link} startIcon={<ArrowBack />} onClick={handleBackClick}>
                    {getBackButtonLabel()}
                </Button>
            </Grid>
            <Card>
                <Typography variant="h6" paragraph>
                    View Eligibility Import Result
                </Typography>
                <Grid container>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            type="text"
                            label="unitId"
                            fullWidth
                            margin="dense"
                            value={data.unitId ?? ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            type="text"
                            label="appBundleId"
                            fullWidth
                            margin="dense"
                            value={data.appBundleId ?? ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            type="text"
                            label="configVersion"
                            fullWidth
                            margin="dense"
                            value={data.configVersion ?? ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            variant="outlined"
                            type="number"
                            label="records"
                            fullWidth
                            margin="dense"
                            value={data?.records}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <FormControlLabel
                            label="success"
                            control={
                                <Checkbox
                                    checked={Boolean(data.success)}
                                    className={classes.checkBox}
                                    disabled
                                />
                            }
                        />
                    </Grid>

                    <OutlinedSection title="processedFiles">
                        <ul>
                            {data.processedFiles?.map(processedFile => (
                                <li key={processedFile}>{processedFile}</li>
                            ))}
                        </ul>
                    </OutlinedSection>
                    <OutlinedSection title="logs">
                        <ul>
                            {data.logs?.map(log => (
                                <li key={log}>{log}</li>
                            ))}
                        </ul>
                    </OutlinedSection>
                </Grid>
            </Card>
        </Grid>
    );
};

export default EligibilityImportResultForm;
