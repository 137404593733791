import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Card,
    Divider,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { ArrowBack, Save as SaveIcon } from '@mui/icons-material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import ArticlePicker from '~/components/ArticlePicker/ArticlePicker';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { PAGESIZE } from '~/constants';
import {
    ArticlePromotionsForArticlePromotionListPageDocument,
    ArticlePromotionsForArticlePromotionListPageQuery,
    ArticlePromotionType,
    useArticlePromotionForArticlePromotionPageLazyQuery,
    useCreateArticlePromotionForArticlePromotionPageMutation,
    useFetchTimelinesForArticlePromotionPageQuery,
    usePreFetchAppsTagsTimelinesForArticlePromotionPageQuery,
    useUpdateArticlePromotionForArticlePromotionPageMutation,
    WhatsNewTimelineType,
    TimeUnit,
    ArticlePromotionForArticlePromotionPageQuery,
    ArticlePromotionInput,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { ClientSelectionType, OffsetType } from '~/enums/enums';
import DateEditor from '~/components/DateEditor/DateEditor';
import * as Yup from 'yup';
import { makeStyles } from 'tss-react/mui';

const articlePromotionTypeMapperToViewItem: {
    [key in ArticlePromotionType]: string;
} = {
    [ArticlePromotionType.FixedDate]: 'Fixed Date',
    [ArticlePromotionType.Timeline]: 'Timeline',
};

const getOffsets = (
    offsetDays: number,
    offsetMonths: number,
    offsetYears: number,
): { days: number; months: number; years: number; type: OffsetType } => {
    return {
        days: Math.abs(offsetDays),
        months: Math.abs(offsetMonths),
        years: Math.abs(offsetYears),
        type:
            offsetDays >= 0 && offsetMonths >= 0 && offsetYears >= 0
                ? OffsetType.After
                : OffsetType.Before,
    };
};
const getDefaultFormValues = (): Partial<ArticlePromotionFormInput> => ({
    articleId: '',
    priority: 5,
    clientSelectionType: ClientSelectionType.IncludeAllApps,
    includeApps: [],
    excludeApps: [],
    tagIds: [],
    type: undefined,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    timelineId: '',
    offsetDays: 0,
    offsetMonths: 0,
    offsetYears: 0,
    duration: 1,
});

const toClientSelectionType = ({
    excludeApps,
    includeApps,
}: ArticlePromotionItem): ClientSelectionType => {
    if (includeApps && includeApps.length > 0) return ClientSelectionType.IncludeCertainApps;
    if (excludeApps && excludeApps.length > 0) return ClientSelectionType.ExcludeCertainApps;
    return ClientSelectionType.IncludeAllApps;
};

const toFormValuesFromArticlePromotion = (
    promotion?: ArticlePromotionItem,
): ArticlePromotionFormInput => {
    const offset = getOffsets(
        promotion?.offsetDays ?? 0,
        promotion?.offsetMonths ?? 0,
        promotion?.offsetYears ?? 0,
    );

    return {
        articleId: promotion?.articleId ?? '',
        title: promotion?.title ?? '',
        titleSp: promotion?.titleSp ?? '',
        priority: promotion?.priority ?? 5,
        clientSelectionType: promotion
            ? toClientSelectionType(promotion)
            : ClientSelectionType.IncludeAllApps,
        includeApps: promotion?.includeApps ?? [],
        excludeApps: promotion?.excludeApps ?? [],
        tagIds: promotion?.tagIds ?? [],
        type: promotion?.type ?? null,
        startDate: promotion?.startDate ?? new Date().toISOString(),
        endDate: promotion?.endDate ?? new Date().toISOString(),
        timelineId: promotion?.timelineId ?? '',
        offsetDays: offset.days,
        offsetMonths: offset.months,
        offsetYears: offset.years,
        offsetType: offset.type,
        duration: promotion?.duration ?? 1,
        durationTimeUnit: promotion?.durationTimeUnit || TimeUnit.Days,
    };
};

const ARTICLE_PROMOTION_SCHEMA = Yup.object().shape({
    priority: Yup.number().required('Priority field is required'),
    tags: Yup.array().of(Yup.string()),
    clientSelectionType: Yup.mixed().oneOf(Object.values(ClientSelectionType)).required(),
    includeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            return clientSelectionType === ClientSelectionType.IncludeCertainApps
                ? schema.min(1, 'Must select at least one app to include').required()
                : schema;
        }),
    excludeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            return clientSelectionType === ClientSelectionType.ExcludeCertainApps
                ? schema.min(1, 'Must select at least one app to exclude').required()
                : schema;
        }),
    type: Yup.mixed().oneOf(Object.values(ArticlePromotionType)).required(),
    timelineId: Yup.string().when('type', ([type], schema) => {
        return type === ArticlePromotionType.Timeline
            ? schema.required('Timeline is required')
            : schema;
    }),
});

const toIncludeOrExclude = (articlePromotion: Partial<ArticlePromotionFormInput>) => {
    const { clientSelectionType, includeApps, excludeApps } = articlePromotion ?? {};
    switch (clientSelectionType) {
        case ClientSelectionType.IncludeCertainApps:
            return {
                includeApps: includeApps ?? [],
                excludeApps: [],
            };
        case ClientSelectionType.ExcludeCertainApps:
            return {
                includeApps: [],
                excludeApps: excludeApps ?? [],
            };
        case ClientSelectionType.IncludeAllApps:
        default:
            return {
                includeApps: [],
                excludeApps: [],
            };
    }
};

const toRequestInput = (
    form: Partial<ArticlePromotionFormInput>,
    selectedArticle: string,
): ArticlePromotionInput => {
    const {
        tagIds,
        type,
        startDate,
        endDate,
        priority,
        timelineId,
        duration,
        durationTimeUnit,
        offsetDays: offsetDaysInput,
        offsetMonths: offsetMonthsInput,
        offsetYears: offsetYearsInput,
        offsetType,
        title,
        titleSp,
    } = form;
    const offsetDays =
        offsetType === OffsetType.After ? Number(offsetDaysInput) : -(offsetDaysInput ?? 0);
    const offsetMonths =
        offsetType === OffsetType.After ? Number(offsetMonthsInput) : -(offsetMonthsInput ?? 0);
    const offsetYears =
        offsetType === OffsetType.After ? Number(offsetYearsInput) : -(offsetYearsInput ?? 0);
    return {
        ...toIncludeOrExclude(form),
        articleId: selectedArticle,
        title,
        titleSp,
        priority: priority != null ? priority : 5,
        tagIds,
        type,
        startDate,
        endDate,
        timelineId: timelineId || null,
        offsetDays,
        offsetMonths,
        offsetYears,
        duration: typeof duration === 'string' ? Number(duration) : duration,
        durationTimeUnit,
    };
};

interface ArticlePromotionFormInput {
    articleId: string;
    title: string;
    titleSp: string;
    priority: number;
    clientSelectionType?: ClientSelectionType | null;
    includeApps?: string[] | null;
    excludeApps?: string[] | null;
    tagIds: string[];
    type?: ArticlePromotionType | null;
    startDate?: string | null;
    endDate?: string | null;
    timelineId: string;
    duration: number;
    durationTimeUnit: TimeUnit;
    offsetDays: number;
    offsetMonths: number;
    offsetYears: number;
    offsetType: OffsetType;
}

type ArticlePromotionItem = NonNullable<
    ArticlePromotionForArticlePromotionPageQuery['articlePromotion']
>;

const LOADING_APPS_TEXT = 'Loading apps...';
const LOADING_TAGS_TEXT = 'Loading tags...';
const Priorities = Array.from({ length: 6 }, (_, i) => i);

const useStyles = makeStyles()({
    helperMessage: {
        margin: '-10px 0 0 24px',
    },
    actionButtons: {
        marginTop: 5,
        marginLeft: 5,
        marginRight: 5,
    },
    sectionLabel: {
        display: 'inline-block',
        paddingLeft: 10,
    },
    offsetWrap: {
        display: 'flex',
        alignContent: 'center',
    },
    divider: {
        marginBottom: 20,
    },
    offsetDate: {
        width: 90,
        marginRight: 10,
    },
    timeDropdown: {
        width: 150,
        marginRight: 10,
    },
});

export const ArticlePromotionEditor: React.FC = () => {
    const { classes } = useStyles();
    const history = useNavigate();
    const [close, setClose] = useState(false);
    const [selectedArticleId, setSelectedArticleId] = useState<string>('');
    const [articleRequired, setArticleRequired] = useState<boolean>(false);
    const { id: articlePromotionId } = useParams<{ id: string }>();
    const isEditMode = articlePromotionId !== 'new';

    const useFormMethods = useForm({
        resolver: yupResolver(ARTICLE_PROMOTION_SCHEMA as any),
        defaultValues: getDefaultFormValues(),
    });

    const {
        control,
        register,
        handleSubmit: handleFormSubmit,
        formState: { errors },
        watch,
        reset,
    } = useFormMethods;

    const [getArticlePromotion, { loading: articlePromotionLoading }] =
        useArticlePromotionForArticlePromotionPageLazyQuery({
            onCompleted: ({ articlePromotion }) => {
                if (articlePromotion) {
                    const formValues = toFormValuesFromArticlePromotion(articlePromotion);
                    reset({ ...formValues });
                    setSelectedArticleId(articlePromotion.articleId);
                }
            },
        });

    const { data: timelines, loading: timelinesLoading } =
        useFetchTimelinesForArticlePromotionPageQuery();

    const { data: preFetchData, loading: preFetchLoading } =
        usePreFetchAppsTagsTimelinesForArticlePromotionPageQuery();

    const [createArticlePromotion, { loading: createArticlePromotionLoading }] =
        useCreateArticlePromotionForArticlePromotionPageMutation({
            onCompleted: data => {
                if (close) {
                    history('/app-config/articlepromotions/');
                } else {
                    history(
                        `/app-config/articlepromotions/${
                            data.createArticlePromotion?.resourceCreated?.id ?? 'new'
                        }`,
                    );
                }
            },
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        setClose(false);
                    },
                    message: `There was a problem saving the article promotion: ${error.message}`,
                });
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: ArticlePromotionsForArticlePromotionListPageDocument,
                    variables: {
                        input: {
                            pagination: {
                                skip: 0,
                                limit: PAGESIZE,
                            },
                            filter: null,
                        },
                    },
                },
            ],
        });

    const [updateArticlePromotion, { loading: updateArticlePromotionLoading }] =
        useUpdateArticlePromotionForArticlePromotionPageMutation({
            onError: error => {
                TriggerGlobalConfirm({
                    callback: () => {
                        setClose(false);
                    },
                    message: `There was a problem saving the article: ${error.message}`,
                });
            },
            onCompleted: () => {
                if (close) {
                    history('/app-config/articlepromotions/');
                }
            },
            update: (cache, response) => {
                const updatedArticlePromotion =
                    response.data?.updateArticlePromotion?.resourceUpdated;
                if (response.data?.updateArticlePromotion?.success && updatedArticlePromotion) {
                    const currentArticlePromotions =
                        cache.readQuery<ArticlePromotionsForArticlePromotionListPageQuery>({
                            query: ArticlePromotionsForArticlePromotionListPageDocument,
                        });
                    if (currentArticlePromotions?.articlePromotionsV2) {
                        cache.writeQuery<ArticlePromotionsForArticlePromotionListPageQuery>({
                            query: ArticlePromotionsForArticlePromotionListPageDocument,
                            data: {
                                articlePromotionsV2: {
                                    ...currentArticlePromotions.articlePromotionsV2,
                                    results: [
                                        ...currentArticlePromotions.articlePromotionsV2.results.map(
                                            articlePromotion => {
                                                if (
                                                    articlePromotion.id ===
                                                    updatedArticlePromotion.id
                                                ) {
                                                    return updatedArticlePromotion;
                                                }
                                                return articlePromotion;
                                            },
                                        ),
                                    ],
                                },
                            },
                        });
                    }
                }
            },
            awaitRefetchQueries: true,
        });

    useEffect(() => {
        if (isEditMode) {
            getArticlePromotion({ variables: { input: { id: articlePromotionId } } });
        }
    }, [articlePromotionId, getArticlePromotion, isEditMode]);

    const clientSelectionType = watch('clientSelectionType');
    const type = watch('type');

    const handleSubmit: SubmitHandler<Partial<ArticlePromotionFormInput>> = form => {
        if (!selectedArticleId) {
            setArticleRequired(true);
            return;
        }

        const requestInput = toRequestInput(form, selectedArticleId);

        if (isEditMode && articlePromotionId) {
            updateArticlePromotion({
                variables: {
                    input: {
                        id: articlePromotionId,
                        data: requestInput,
                    },
                },
            });
        } else {
            createArticlePromotion({
                variables: {
                    input: requestInput,
                },
            });
        }
    };

    if (
        preFetchLoading ||
        createArticlePromotionLoading ||
        updateArticlePromotionLoading ||
        articlePromotionLoading ||
        timelinesLoading
    ) {
        return <Loading />;
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Button
                    component={Link}
                    to="/app-config/articlepromotions/"
                    startIcon={<ArrowBack />}
                >
                    Back to Article Promotion List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Typography variant="h6" paragraph>
                        {isEditMode ? 'Edit' : 'Create'} Article Promotion
                    </Typography>
                    <Divider className={classes.divider} />
                    <form onSubmit={handleFormSubmit(handleSubmit)}>
                        <Grid item>
                            <ArticlePicker
                                selectedArticleId={selectedArticleId}
                                setSelectedArticleId={setSelectedArticleId}
                                required={articleRequired}
                                setRequired={setArticleRequired}
                            />
                        </Grid>
                        <OutlinedSection title="Carousel Title">
                            <TextField
                                variant="outlined"
                                type="text"
                                label="English (leave blank to use article title)"
                                fullWidth
                                margin="dense"
                                {...register('title')}
                                error={Boolean(errors.title)}
                                helperText={errors.title?.message}
                            />
                            <TextField
                                variant="outlined"
                                type="text"
                                label="Spanish"
                                fullWidth
                                margin="dense"
                                {...register('titleSp')}
                                error={Boolean(errors.titleSp)}
                                helperText={errors.titleSp?.message}
                            />
                        </OutlinedSection>
                        <Grid item>
                            <ReactHookFormSelect
                                control={control}
                                name="priority"
                                variant="outlined"
                                label="Priority"
                                margin="dense"
                                fullWidth
                                defaultValue=""
                            >
                                {Priorities.map(i => (
                                    <MenuItem key={i} value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                        </Grid>
                        <Grid container item xs={12}>
                            <OutlinedSection title="App">
                                <ReactHookFormSelect
                                    control={control}
                                    name="clientSelectionType"
                                    variant="outlined"
                                    defaultValue=""
                                    label="Selection type *"
                                    fullWidth
                                    disabled={isEditMode}
                                    margin="dense"
                                    error={Boolean(errors.clientSelectionType)}
                                >
                                    {Object.values(ClientSelectionType).map(m => (
                                        <MenuItem key={m} value={m} data-test={m}>
                                            {m}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                {errors?.clientSelectionType && (
                                    <FormHelperText error className={classes.helperMessage}>
                                        {errors?.clientSelectionType?.message}
                                    </FormHelperText>
                                )}
                                {clientSelectionType === ClientSelectionType.IncludeCertainApps && (
                                    <AutocompleteWithRecordOptions
                                        options={
                                            _.sortBy(
                                                preFetchData?.applicationsV2.results,
                                                'appBundleId',
                                            ) ?? []
                                        }
                                        valueKey="appBundleId"
                                        labelKey="appBundleId"
                                        control={control}
                                        name="includeApps"
                                        label="Include apps *"
                                        placeholder="Select apps to include..."
                                        required
                                        disabled={isEditMode}
                                        loading={!preFetchData?.applicationsV2.results}
                                        loadingText={LOADING_APPS_TEXT}
                                        error={Boolean(errors.includeApps)}
                                        helperText={
                                            (errors.includeApps as FieldError | undefined)?.message
                                        }
                                    />
                                )}
                                {clientSelectionType === ClientSelectionType.ExcludeCertainApps && (
                                    <AutocompleteWithRecordOptions
                                        options={
                                            _.sortBy(
                                                preFetchData?.applicationsV2.results,
                                                'appBundleId',
                                            ) ?? []
                                        }
                                        valueKey="appBundleId"
                                        labelKey="appBundleId"
                                        control={control}
                                        name="excludeApps"
                                        label="Exclude apps *"
                                        placeholder="Select apps to exclude..."
                                        required
                                        disabled={isEditMode}
                                        loading={!preFetchData?.applicationsV2.results}
                                        loadingText={LOADING_APPS_TEXT}
                                        error={Boolean(errors.excludeApps)}
                                        helperText={
                                            (errors.excludeApps as FieldError | undefined)?.message
                                        }
                                    />
                                )}
                            </OutlinedSection>
                        </Grid>
                        {preFetchData?.tags && (
                            <AutocompleteWithRecordOptions
                                options={preFetchData.tags ?? []}
                                valueKey="id"
                                labelKey="name"
                                control={control}
                                name="tagIds"
                                label="Tags"
                                placeholder="Select tags ..."
                                loading={!preFetchData?.tags}
                                loadingText={LOADING_TAGS_TEXT}
                                error={Boolean(errors.tagIds)}
                            />
                        )}
                        <Grid container item xs={12}>
                            <ReactHookFormSelect
                                control={control}
                                defaultValue=""
                                name="type"
                                variant="outlined"
                                label="How to show"
                                fullWidth
                                margin="dense"
                                error={!!errors.type}
                            >
                                {Object.values(ArticlePromotionType).map(type => (
                                    <MenuItem key={type} value={type} data-test={type}>
                                        {
                                            articlePromotionTypeMapperToViewItem[
                                                type as ArticlePromotionType
                                            ]
                                        }
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            {errors?.type && (
                                <FormHelperText error className={classes.helperMessage}>
                                    {errors?.type?.message}
                                </FormHelperText>
                            )}
                        </Grid>
                        {type === ArticlePromotionType.Timeline && (
                            <Grid>
                                <OutlinedSection title="When: Timeline">
                                    <Grid item xs={12}>
                                        <ReactHookFormSelect
                                            control={control}
                                            defaultValue=""
                                            name="timelineId"
                                            variant="outlined"
                                            label="Select Timeline"
                                            fullWidth
                                            margin="dense"
                                            error={!!errors.timelineId}
                                        >
                                            {_.sortBy(
                                                timelines?.whatsNewTimelinesV2?.results.filter(
                                                    t => t.type === WhatsNewTimelineType.Profile,
                                                ),
                                                'label.en',
                                            ).map(({ id, label }) => (
                                                <MenuItem key={id} value={id}>
                                                    {label?.en}
                                                </MenuItem>
                                            ))}
                                        </ReactHookFormSelect>
                                        {errors?.timelineId && (
                                            <FormHelperText error className={classes.helperMessage}>
                                                {errors?.timelineId?.message}
                                            </FormHelperText>
                                        )}
                                        <Grid item xs={12}>
                                            <span className={classes.sectionLabel}>
                                                In Feed From
                                            </span>
                                            <div className={classes.offsetWrap}>
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    label="Days *"
                                                    margin="dense"
                                                    {...register('offsetDays')}
                                                    InputProps={{
                                                        inputProps: { min: 0, step: 1 },
                                                    }}
                                                    className={classes.offsetDate}
                                                    defaultValue={0}
                                                    error={Boolean(errors.offsetDays)}
                                                    helperText={errors.offsetDays?.message}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    label="Months *"
                                                    margin="dense"
                                                    {...register('offsetMonths')}
                                                    InputProps={{
                                                        inputProps: { min: 0, step: 1 },
                                                    }}
                                                    className={classes.offsetDate}
                                                    defaultValue={0}
                                                    error={Boolean(errors.offsetMonths)}
                                                    helperText={errors.offsetMonths?.message}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    label="Years *"
                                                    margin="dense"
                                                    {...register('offsetYears')}
                                                    InputProps={{
                                                        inputProps: { min: 0, step: 1 },
                                                    }}
                                                    className={classes.offsetDate}
                                                    defaultValue={0}
                                                    error={Boolean(errors.offsetYears)}
                                                    helperText={errors.offsetYears?.message}
                                                />
                                                <ReactHookFormSelect
                                                    className={classes.timeDropdown}
                                                    control={control}
                                                    name="offsetType"
                                                    variant="outlined"
                                                    label="Offset Type"
                                                    margin="dense"
                                                    defaultValue="Before"
                                                    error={!!errors.offsetType}
                                                >
                                                    {Object.values(OffsetType).map(u => (
                                                        <MenuItem key={u} value={u}>
                                                            {u}
                                                        </MenuItem>
                                                    ))}
                                                </ReactHookFormSelect>
                                                {errors?.offsetType && (
                                                    <FormHelperText
                                                        error
                                                        className={classes.helperMessage}
                                                    >
                                                        {errors?.offsetType?.message}
                                                    </FormHelperText>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <span className={classes.sectionLabel}>Show For</span>
                                            <div className={classes.offsetWrap}>
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    margin="dense"
                                                    InputProps={{
                                                        inputProps: { min: 1, step: 1 },
                                                    }}
                                                    {...register('duration')}
                                                    className={classes.offsetDate}
                                                    defaultValue={1}
                                                    error={Boolean(errors.duration)}
                                                    helperText={errors.duration?.message}
                                                />
                                                <ReactHookFormSelect
                                                    className={classes.timeDropdown}
                                                    control={control}
                                                    name="durationTimeUnit"
                                                    variant="outlined"
                                                    label="Time Unit"
                                                    margin="dense"
                                                    defaultValue="Days"
                                                    error={!!errors.durationTimeUnit}
                                                >
                                                    {Object.values(TimeUnit).map(u => (
                                                        <MenuItem key={u} value={u}>
                                                            {u}
                                                        </MenuItem>
                                                    ))}
                                                </ReactHookFormSelect>
                                            </div>
                                        </Grid>
                                    </Grid>
                                </OutlinedSection>
                            </Grid>
                        )}
                        {type === ArticlePromotionType.FixedDate && (
                            <Grid item>
                                <OutlinedSection title="When: Fixed Date">
                                    <Controller
                                        control={control}
                                        name="startDate"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DateEditor
                                                    title="Start Date"
                                                    setSelectedDate={d => onChange(d)}
                                                    initialDate={value || new Date().toISOString()}
                                                />
                                            );
                                        }}
                                    />
                                    {errors?.startDate && (
                                        <FormHelperText error className={classes.helperMessage}>
                                            {errors?.startDate?.message}
                                        </FormHelperText>
                                    )}
                                    <Controller
                                        control={control}
                                        name="endDate"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DateEditor
                                                    title="End Date"
                                                    setSelectedDate={d => onChange(d)}
                                                    initialDate={value || new Date().toISOString()}
                                                />
                                            );
                                        }}
                                    />
                                    {errors?.endDate && (
                                        <FormHelperText error className={classes.helperMessage}>
                                            {errors?.endDate?.message}
                                        </FormHelperText>
                                    )}
                                </OutlinedSection>
                            </Grid>
                        )}
                        <Grid
                            container
                            item
                            spacing={2}
                            justifyContent="flex-end"
                            direction="row"
                            className={classes.actionButtons}
                            alignItems="flex-end"
                        >
                            <Button
                                onClick={handleFormSubmit(handleSubmit)}
                                startIcon={<SaveIcon />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => {
                                    setClose(true);
                                    handleFormSubmit(handleSubmit);
                                }}
                                startIcon={<SaveIcon />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                            >
                                Save &amp; Close
                            </Button>
                        </Grid>
                    </form>
                </Card>
            </Grid>
        </Grid>
    );
};
