import { faBold, faItalic, faStrikethrough } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton, Paper, Tooltip } from '@mui/material';
import { BubbleMenu, Editor } from '@tiptap/react';
import React from 'react';
import { primaryColor } from '~/theme/WildTheme';

export const RichTextEditorBubbleMenu = ({ editor }: { editor: Editor }) => {
    return (
        <BubbleMenu editor={editor}>
            <Paper elevation={2}>
                <Tooltip title="Bold">
                    <IconButton
                        type="button"
                        size="small"
                        onClick={() => editor.chain().focus().toggleBold().run()}
                        disabled={!editor.can().chain().focus().toggleBold().run()}
                        style={
                            editor.isActive('bold') ? { color: primaryColor } : { color: 'grey' }
                        }
                    >
                        <FontAwesomeIcon icon={faBold} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Italic">
                    <IconButton
                        type="button"
                        size="small"
                        onClick={() => editor.chain().focus().toggleItalic().run()}
                        disabled={!editor.can().chain().focus().toggleItalic().run()}
                        style={
                            editor.isActive('italic') ? { color: primaryColor } : { color: 'grey' }
                        }
                    >
                        <FontAwesomeIcon icon={faItalic} />
                    </IconButton>
                </Tooltip>
                <Tooltip title="Strike">
                    <IconButton
                        type="button"
                        size="small"
                        onClick={() => editor.chain().focus().toggleStrike().run()}
                        disabled={!editor.can().chain().focus().toggleStrike().run()}
                        style={
                            editor.isActive('strike') ? { color: primaryColor } : { color: 'grey' }
                        }
                    >
                        <FontAwesomeIcon icon={faStrikethrough} />
                    </IconButton>
                </Tooltip>
            </Paper>
        </BubbleMenu>
    );
};
