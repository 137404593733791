import React, { useState } from 'react';
import { FormControl, FormHelperText, IconButton, InputLabel, OutlinedInput } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import { Control, Controller } from 'react-hook-form';
import { makeStyles } from 'tss-react/mui';

type EligibilityPasswordProps = {
    control: Control<any>;
    name: string;
    error: boolean;
    helperText?: string;
    disabled?: boolean;
};

const useStyles = makeStyles()({
    root: {
        marginTop: 8,
        marginBottom: 4,
    },
});

const EligibilityPassword = ({
    control,
    name,
    error,
    helperText,
    disabled,
}: EligibilityPasswordProps) => {
    const [showPassword, setShowPassword] = useState(false);
    const { classes } = useStyles();

    return (
        <Controller
            name={name}
            control={control}
            render={({ field: { ref, ...field } }) => (
                <FormControl variant="outlined" fullWidth className={classes.root}>
                    <InputLabel error={error}>password</InputLabel>
                    <OutlinedInput
                        {...field}
                        type={showPassword ? 'text' : 'password'}
                        endAdornment={
                            <IconButton edge="end" onClick={() => setShowPassword(!showPassword)}>
                                {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                        }
                        label="password"
                        inputRef={ref}
                        error={error}
                        disabled={disabled}
                    />
                    {error && <FormHelperText error>{helperText}</FormHelperText>}
                </FormControl>
            )}
        />
    );
};

export default EligibilityPassword;
