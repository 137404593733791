import { Card, Grid, Typography } from '@mui/material';
import React, { ReactElement } from 'react';
import { PatientInternalMemo } from '~/views/Dashboard/Patients/Patient/PatientInternalMemo';
import { PatientHeaderItemDisplay } from './PatientHeaderItemDisplay/PatientHeaderItemDisplay';

export type PatientHeaderItem = {
    label?: string | null | undefined;
    value?: string | null | undefined;
};

type PatientHeaderProps = {
    patientId: string;
    internalMemo?: string;
    patientHeaderItems?: PatientHeaderItem[] | [];
};

const PatientHeaderComponent: React.FC<PatientHeaderProps> = ({
    patientId,
    internalMemo,
    patientHeaderItems,
}): ReactElement | null => {
    const uniqueHeaderItems = new Map<string, PatientHeaderItem>();
    // eslint-disable-next-line array-callback-return
    patientHeaderItems?.map(header => {
        if (header.label) {
            uniqueHeaderItems.set(header.label, header);
        }
    });
    const headerItems = Array.from(uniqueHeaderItems.values());

    return (
        <Grid item xs={12} style={{ marginBottom: '16px' }}>
            <PatientInternalMemo patientId={patientId} internalMemo={internalMemo} />
            <Card style={{ marginBottom: 0 }}>
                <Grid container>
                    {headerItems &&
                        headerItems.length > 0 &&
                        headerItems.map((item: any, index) => (
                            // eslint-disable-next-line react/no-array-index-key
                            <Grid item xs={2} sm={4} md={4} key={index}>
                                <Typography color="primary" gutterBottom variant="body1">
                                    <b>{item.label}</b>:
                                </Typography>
                                <Typography
                                    variant="subtitle1"
                                    paddingRight={1}
                                    style={{ wordWrap: 'break-word' }}
                                >
                                    <PatientHeaderItemDisplay item={item} />
                                </Typography>
                            </Grid>
                        ))}
                </Grid>
            </Card>
        </Grid>
    );
};

export default PatientHeaderComponent;
