import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Grid, Link } from '@mui/material';
import AwayMode from '~/views/AffiliateCare/components/AwayMode/AwayMode';
import useAffiliateCareView from '~/hooks/useAffiliateCareView';

const ActionButtons = () => {
    const { userAffiliateId, isDenied, awayMode, startAwayModeDate, endAwayModeDate, id } =
        useAffiliateCareView();
    const history = useNavigate();
    const internalUrl = `/affiliate-care-portal/patients/${userAffiliateId}/1?sc=true`;
    return (
        <Grid item xs={12} container>
            <Grid item xs={11} container flexDirection="row" justifyContent="space-between">
                <Grid container item spacing={1} xs="auto">
                    <Grid item>
                        <Link href={internalUrl} underline="hover">
                            <Button variant="outlined">Check Patients Eligibility</Button>
                        </Link>
                    </Grid>
                    <Grid item>
                        <Button
                            onClick={() => history('/affiliate-care-portal/claims/build-claims')}
                            disabled={isDenied}
                            variant="outlined"
                        >
                            Submit Claims for Reimbursement
                        </Button>
                    </Grid>
                </Grid>
                <Grid container item justifyContent="flex-end" xs>
                    <Grid item>
                        <AwayMode
                            awayMode={awayMode}
                            startAwayModeDate={startAwayModeDate}
                            endAwayModeDate={endAwayModeDate}
                            affiliateId={id}
                        />
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
};

export default ActionButtons;
