import { FetchPatientForMergeQuery, Patient } from '~/schemaTypes';
import { displayDateWithLocalTimeZoneAbbr } from '~/helpers';
import { MergeRow } from '../MergePatient';

interface PatientAttributeRowsParams {
    sourcePatient: Patient;
    targetPatient: FetchPatientForMergeQuery['patient'];
}

export const getPatientAttributeRows = ({
    sourcePatient,
    targetPatient,
}: PatientAttributeRowsParams): MergeRow[] => {
    const formatAddress = (address: typeof sourcePatient.mailingAddress) => {
        if (!address) return null;
        return [address.street1, address.city, address.state, address.code]
            .filter(Boolean)
            .join(', ');
    };

    return [
        {
            attribute: 'ID',
            attributeDisplay: 'ID',
            sourceValue: sourcePatient.id,
            targetValue: targetPatient?.id,
            finalValue: targetPatient?.id,
            selectedOption: 'target',
        },
        {
            attribute: 'Patient Attributes',
            attributeDisplay: 'Patient Attributes',
            sourceValue: '',
            targetValue: '',
            finalValue: '',
            selectedOption: 'target',
        },
        {
            attribute: 'firstName',
            attributeDisplay: 'First Name',
            sourceValue: sourcePatient.firstName,
            targetValue: targetPatient?.firstName,
            finalValue: targetPatient?.firstName || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'lastName',
            attributeDisplay: 'Last Name',
            sourceValue: sourcePatient.lastName,
            targetValue: targetPatient?.lastName,
            finalValue: targetPatient?.lastName || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'email',
            attributeDisplay: 'Email',
            sourceValue: sourcePatient.email || null,
            targetValue: targetPatient?.email || null,
            finalValue: targetPatient?.email || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'phoneNumber',
            attributeDisplay: 'Phone Number',
            sourceValue: sourcePatient.phoneNumber || null,
            targetValue: targetPatient?.phoneNumber || null,
            finalValue: targetPatient?.phoneNumber || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'insuranceId',
            attributeDisplay: 'Insurance ID',
            sourceValue: sourcePatient.insuranceId || null,
            targetValue: targetPatient?.insuranceId || null,
            finalValue: targetPatient?.insuranceId || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'insuranceName',
            attributeDisplay: 'Insurance Name',
            sourceValue: sourcePatient.insuranceName || null,
            targetValue: targetPatient?.insuranceName || null,
            finalValue: targetPatient?.insuranceName || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'groupNumber',
            attributeDisplay: 'Group Number',
            sourceValue: sourcePatient.groupNumber || null,
            targetValue: targetPatient?.groupNumber || null,
            finalValue: targetPatient?.groupNumber || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'memberId',
            attributeDisplay: 'Member ID',
            sourceValue: sourcePatient.memberId || null,
            targetValue: targetPatient?.memberId || null,
            finalValue: targetPatient?.memberId || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'mailingAddress',
            attributeDisplay: 'Mailing Address',
            sourceValue: formatAddress(sourcePatient.mailingAddress),
            targetValue: formatAddress(targetPatient?.mailingAddress),
            finalValue: formatAddress(targetPatient?.mailingAddress) || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'birthDate',
            attributeDisplay: 'Birth Date',
            sourceValue: sourcePatient.birthDate || null,
            targetValue: targetPatient?.birthDate || null,
            finalValue: targetPatient?.birthDate || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'practiceId',
            attributeDisplay: 'Practice Id',
            sourceValue: sourcePatient.practice?.id || null,
            targetValue: targetPatient?.practice?.id || null,
            finalValue: targetPatient?.practice?.id || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'hgPatientId',
            attributeDisplay: 'HG Patient ID',
            sourceValue: sourcePatient.hgPatientId || null,
            targetValue: targetPatient?.hgPatientId || null,
            finalValue: targetPatient?.hgPatientId || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'insuranceGroupNumber',
            attributeDisplay: 'Insurance Group Number',
            sourceValue: sourcePatient.insuranceGroupNumber || null,
            targetValue: targetPatient?.insuranceGroupNumber || null,
            finalValue: targetPatient?.insuranceGroupNumber || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'insuredFirstName',
            attributeDisplay: 'Insured First Name',
            sourceValue: sourcePatient.insuredFirstName || null,
            targetValue: targetPatient?.insuredFirstName || null,
            finalValue: targetPatient?.insuredFirstName || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'insuredLastName',
            attributeDisplay: 'Insured Last Name',
            sourceValue: sourcePatient.insuredLastName || null,
            targetValue: targetPatient?.insuredLastName || null,
            finalValue: targetPatient?.insuredLastName || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'insuredPhoneNumber',
            attributeDisplay: 'Insured Phone Number',
            sourceValue: sourcePatient.insuredPhoneNumber || null,
            targetValue: targetPatient?.insuredPhoneNumber || null,
            finalValue: targetPatient?.insuredPhoneNumber || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'insuredAddress',
            attributeDisplay: 'Insured Address',
            sourceValue: formatAddress(sourcePatient.insuredAddress),
            targetValue: formatAddress(targetPatient?.insuredAddress),
            finalValue: formatAddress(targetPatient?.insuredAddress) || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'dueDate',
            attributeDisplay: 'Due Date',
            sourceValue: sourcePatient.dueDate || null,
            targetValue: targetPatient?.dueDate || null,
            finalValue: targetPatient?.dueDate || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'deliveryDate',
            attributeDisplay: 'Delivery Date',
            sourceValue: sourcePatient.deliveryDate || null,
            targetValue: targetPatient?.deliveryDate || null,
            finalValue: targetPatient?.deliveryDate || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'gender',
            attributeDisplay: 'Gender',
            sourceValue: sourcePatient.gender || null,
            targetValue: targetPatient?.gender || null,
            finalValue: targetPatient?.gender || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'race',
            attributeDisplay: 'Race',
            sourceValue: sourcePatient.race || null,
            targetValue: targetPatient?.race || null,
            finalValue: targetPatient?.race || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'patientRelationshipToInsured',
            attributeDisplay: 'Relationship to Insured',
            sourceValue: sourcePatient.patientRelationshipToInsured || null,
            targetValue: targetPatient?.patientRelationshipToInsured || null,
            finalValue: targetPatient?.patientRelationshipToInsured || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'virtualCarePhoneNumber',
            attributeDisplay: 'Virtual Care Phone',
            sourceValue: sourcePatient.virtualCarePhoneNumber || null,
            targetValue: targetPatient?.virtualCarePhoneNumber || null,
            finalValue: targetPatient?.virtualCarePhoneNumber || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'externalId',
            attributeDisplay: 'External Id',
            sourceValue: sourcePatient.externalId || null,
            targetValue: targetPatient?.externalId || null,
            finalValue: targetPatient?.externalId || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'redoxEhrId',
            attributeDisplay: 'Redox Ehr Id',
            sourceValue: sourcePatient.redoxEhrId || null,
            targetValue: targetPatient?.redoxEhrId || null,
            finalValue: targetPatient?.redoxEhrId || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'redoxSourceId',
            attributeDisplay: 'Redox Source Id',
            sourceValue: sourcePatient.redoxSourceId || null,
            targetValue: targetPatient?.redoxSourceId || null,
            finalValue: targetPatient?.redoxSourceId || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
        {
            attribute: 'appBundleId',
            attributeDisplay: 'App Bundle Id',
            sourceValue: sourcePatient.appBundleId || null,
            targetValue: targetPatient?.appBundleId || null,
            finalValue: targetPatient?.appBundleId || '',
            selectedOption: 'target',
            isPatientAttribute: true,
        },
    ];
};

export const displayAttribute = (row: MergeRow, value: string | null) => {
    return value != null &&
        (row.attribute === 'birthDate' ||
            row.attribute === 'dueDate' ||
            row.attribute === 'deliveryDate')
        ? displayDateWithLocalTimeZoneAbbr({ isoDateStr: value })
        : value;
};
