import React from 'react';
import { GetTriggerInterventionClearProfileChoiceForPatientTranscriptQuery } from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type TriggerInterventionClearProfileChoice = NonNullable<
    NonNullable<
        GetTriggerInterventionClearProfileChoiceForPatientTranscriptQuery['getTriggerInterventionClearProfileChoiceForPatientTranscript']
    >['items']
>[number];

export const TriggerInterventionClearProfileChoiceCard = ({
    intervention,
}: {
    intervention: TriggerInterventionClearProfileChoice;
}) => {
    return (
        <BaseCard title="Trigger Intervention Clear Profile Choice" data={intervention}>
            <p className="m-0">{`Profile: ${intervention?.profileDef?.name || 'Unknown'}`}</p>
            <p className="m-0">
                {`Choices to Update: ${
                    intervention?.event?.data?.choicesToUpdate?.join(', ') || 'Unknown'
                }`}
            </p>
        </BaseCard>
    );
};
