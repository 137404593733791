import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Avatar,
    Button,
    Divider,
    Fade,
    IconButton,
    Tab,
    Tabs,
    Tooltip,
    Typography,
} from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, ExpandMore } from '@mui/icons-material';
import React, { useState } from 'react';
import { OrgTreeNode } from '~/hooks/useOrgTree';
import useUserPermissions from '~/hooks/useUserPermissions';

import { OrgState } from '../Organizations';
import OrganizationDetails from './OrganizationDetails/OrganizationDetails';
import OrganizationPatientHeader from './OrganizationPatientHeader/OrganizationPatientHeader';
import OrganizationReportingJobs from './OrganizationRepornigJobs/OrganizationReportingJobs';
import OrganizationReporting from './OrganizationReporting/OrganizationReporting';
import useVCEligibility from './OrganizationVCF/useVCEligibility';
import { OrganizationVCF } from './OrganizationVCF';

enum OrganizationCardTabs {
    info = 'info',
    config = 'config',
    subOrgs = 'subOrgs',
    patientHeader = 'patientHeader',
    reporting = 'reporting',
    reportingJobs = 'reportingJobs',
    VirtualCareForm = 'VCF',
}

const useStyles = makeStyles()(() => ({
    root: {
        width: '100%',
    },
    summary: {
        height: 75,
        '& .headerCont': {
            display: 'flex',
            alignItems: 'center',
            '& > *:not(:last-child)': {
                marginRight: 20,
            },
        },
    },
    body: {
        display: 'flex',
        flexDirection: 'column',
        paddingRight: 0,
        '& > .subPracticesCont': {
            padding: 30,
            display: 'flex',
            flexDirection: 'column',
            '& > *:not(:last-child)': {
                marginBottom: 20,
            },
            '& > .subPracticeEmpty': {
                alignSelf: 'center',
                padding: 30,
                width: 400,
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                textAlign: 'center',
            },
        },
    },
}));

type OrganizationCardProps = {
    orgNode: OrgTreeNode;
    ancestors?: Array<OrgTreeNode['org']['brandingName']>;
    setModalOpen: React.Dispatch<React.SetStateAction<boolean>>;
    setEditOrgId: React.Dispatch<React.SetStateAction<string>>;
    setParentId: React.Dispatch<React.SetStateAction<string>>;
    setParentAppBundleId: React.Dispatch<React.SetStateAction<string | null>>;
    orgState: OrgState;
    onOrgStateUpdate: (nodeId: OrgTreeNode['org']['id']) => () => void;
    setOrgDetailsSection: React.Dispatch<React.SetStateAction<string>>;
};

const OrganizationCard: React.FC<OrganizationCardProps> = ({
    orgNode,
    ancestors = [],
    setEditOrgId,
    setParentId,
    setParentAppBundleId,
    setModalOpen,
    orgState,
    onOrgStateUpdate,
    setOrgDetailsSection,
}) => {
    const { org, orgChildren, level } = orgNode;
    const { classes } = useStyles();
    const [currentTab, setCurrentTab] = useState<OrganizationCardTabs>(
        orgChildren.length === 0 ? OrganizationCardTabs.info : OrganizationCardTabs.subOrgs,
    );

    const { pagePermissions } = useUserPermissions();

    const { id, logo, brandingName, appBundleId } = org;

    const onAccordionChange = () => {
        onOrgStateUpdate(id)();
    };
    const { isVCOrg } = useVCEligibility({ ancestors: org.ancestors });

    const getAncestorsBrandingNames = (): Array<OrgTreeNode['org']['brandingName']> => {
        const { brandingName }: { brandingName: string } = org;
        if (orgChildren && !ancestors.length) {
            return [org.brandingName];
        }
        if (orgChildren && ancestors.length) {
            return [...ancestors, brandingName];
        }
        return ancestors;
    };

    const ancestorsBrandingNames = getAncestorsBrandingNames();

    return (
        <Fade in timeout={level * 400}>
            <Accordion
                className={classes.root}
                elevation={0}
                expanded={orgState[id] || false}
                onChange={onAccordionChange}
                key={id}
            >
                <AccordionSummary
                    className={classes.summary}
                    expandIcon={level !== 0 ? <ExpandMore /> : null}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <div className="headerCont">
                        <Avatar src={logo ?? ''}>{brandingName[0]}</Avatar>
                        <div>
                            <Typography variant="h6">{brandingName}</Typography>
                            <Typography variant="subtitle2">
                                {orgChildren.length} Sub Org
                                {orgChildren.length > 1 || orgChildren.length === 0 ? 's' : ''}
                            </Typography>
                        </div>
                        <Tooltip title={`Edit ${brandingName}`}>
                            <IconButton
                                disabled={!pagePermissions?.Practices.Edit}
                                onClick={(e: any) => {
                                    e.stopPropagation();
                                    setModalOpen(true);
                                    setEditOrgId(id);
                                    setOrgDetailsSection('');
                                }}
                                size="large"
                            >
                                <FontAwesomeIcon icon={faPenToSquare} />
                            </IconButton>
                        </Tooltip>
                    </div>
                </AccordionSummary>
                <AccordionDetails className={classes.body}>
                    <Tabs
                        value={currentTab}
                        onChange={(e: React.ChangeEvent<unknown>, newValue: OrganizationCardTabs) =>
                            setCurrentTab(newValue)
                        }
                    >
                        <Tab label="Organization Info" value={OrganizationCardTabs.info} />
                        <Tab label="Sub Orgs" value={OrganizationCardTabs.subOrgs} />
                        <Tab label="Patient Header" value={OrganizationCardTabs.patientHeader} />
                        <Tab label="Reporting" value={OrganizationCardTabs.reporting} />
                        <Tab label="Reporting Jobs" value={OrganizationCardTabs.reportingJobs} />
                        {isVCOrg && (
                            <Tab
                                label="Virtual Care Forms"
                                value={OrganizationCardTabs.VirtualCareForm}
                            />
                        )}
                        <Button
                            disabled={!pagePermissions?.Practices.Edit}
                            color="secondary"
                            style={{ marginLeft: 10 }}
                            startIcon={<Add />}
                            onClick={() => {
                                setModalOpen(true);
                                setParentId(id);
                                setParentAppBundleId(appBundleId ?? null);
                                setOrgDetailsSection('');
                            }}
                        >
                            Add Sub Org
                        </Button>
                    </Tabs>
                    <Divider />
                    {currentTab === OrganizationCardTabs.info && (
                        <OrganizationDetails
                            orgNode={orgNode}
                            level={level}
                            setEditOrgId={setEditOrgId}
                            setModalOpen={setModalOpen}
                            setOrgDetailsSection={setOrgDetailsSection}
                        />
                    )}
                    {/* {currentTab === OrganizationCardTabs.config && (
                        <OrganizationConfig orgId={id} />
                    )} */}
                    {currentTab === OrganizationCardTabs.subOrgs && (
                        <div className="subPracticesCont">
                            {orgChildren.length !== 0 ? (
                                orgChildren.map(org => (
                                    <OrganizationCard
                                        ancestors={ancestorsBrandingNames}
                                        orgNode={org}
                                        setEditOrgId={setEditOrgId}
                                        setModalOpen={setModalOpen}
                                        setParentId={setParentId}
                                        setParentAppBundleId={setParentAppBundleId}
                                        orgState={orgState}
                                        onOrgStateUpdate={onOrgStateUpdate}
                                        setOrgDetailsSection={setOrgDetailsSection}
                                    />
                                ))
                            ) : (
                                <div className="subPracticeEmpty">
                                    <Typography paragraph variant="h6">
                                        No Sub Orgs
                                    </Typography>
                                    <Button
                                        disabled={!pagePermissions?.Practices.Edit}
                                        variant="outlined"
                                        color="secondary"
                                        onClick={() => {
                                            setModalOpen(true);
                                            setParentId(id);
                                            setParentAppBundleId(appBundleId ?? null);
                                            setOrgDetailsSection('');
                                        }}
                                    >
                                        Add Sub Org
                                    </Button>
                                </div>
                            )}
                        </div>
                    )}
                    {currentTab === OrganizationCardTabs.reporting && (
                        <OrganizationReporting
                            ancestorsBrandingNames={ancestorsBrandingNames}
                            orgState={orgState}
                            orgNode={{ org, orgChildren }}
                        />
                    )}
                    {currentTab === OrganizationCardTabs.reportingJobs && (
                        <OrganizationReportingJobs orgNode={{ org, orgChildren }} />
                    )}
                    {currentTab === OrganizationCardTabs.patientHeader && (
                        <OrganizationPatientHeader orgNode={{ org, orgChildren, level }} />
                    )}
                    {currentTab === OrganizationCardTabs.VirtualCareForm && <OrganizationVCF />}
                </AccordionDetails>
            </Accordion>
        </Fade>
    );
};

export default OrganizationCard;
