import React, { useCallback, useEffect, useRef, useMemo } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { Typography, Grid, Divider, List, ListItem } from '@mui/material';
import { yupResolver } from '@hookform/resolvers/yup';
import { useOnboarding } from '~/views/AffiliateCare/Onboarding/useOboarding';
import {
    FetchAffiliateDocument,
    OnSuccessfulEligibility,
    useUpdateRoutingInfoForAffiliateMutation,
    OnSuccessfulZipCodeMatch,
} from '~/schemaTypes';
import { FormInput } from './types';
import { ROUTING_FORM_SCHEMA } from './yupSchema';
import RoutingForm from './components/RoutingForm';
import { eligibilitySuccessText, zipCodeSuccessText, zipCodeWithUrlSuccessText } from './helpers';
import ContactInformation from './components/ContactInformation/ContactInformation';

const Routing: React.FC = () => {
    const { setIsValidStep, affiliate, setLoading, setStepCb } = useOnboarding();
    const isValidUpdate = useRef(true);
    const formData = useForm<FormInput>({
        resolver: yupResolver(ROUTING_FORM_SCHEMA as any),
        defaultValues: {
            onSuccessfulEligibility:
                affiliate?.onSuccessfulEligibility ?? OnSuccessfulEligibility.RouteToLanding,
            customSuccessText: eligibilitySuccessText(affiliate),
            routingURL: affiliate?.routingURL,
            website: affiliate?.website || '',
            supportPhone: affiliate?.supportPhone || '',
            supportEmail: affiliate?.supportEmail || '',
        },
    });
    const {
        handleSubmit,
        formState: { isValid: isValidForm, isDirty },
        resetField,
        watch,
    } = formData;
    const [supportPhone, supportEmail, website] = watch([
        'supportPhone',
        'supportEmail',
        'website',
    ]);
    useEffect(() => {
        if (affiliate) {
            resetField('customSuccessText', { defaultValue: eligibilitySuccessText(affiliate) });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affiliate?.supportEmail, affiliate?.supportPhone, affiliate?.website]);
    useEffect(() => {
        setIsValidStep(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    const [updateRouting] = useUpdateRoutingInfoForAffiliateMutation({
        refetchQueries: [
            {
                query: FetchAffiliateDocument,
                variables: { input: { affiliateId: affiliate?.affiliateId } },
            },
        ],
    });
    const affiliateDataForRouting = useMemo(
        () => ({
            externalName: affiliate?.externalName || '',
            supportPhone,
            supportEmail,
            website,
            contactName: affiliate?.contactName || '',
        }),
        [affiliate, supportEmail, supportPhone, website],
    );
    const onSubmit = useCallback(
        async (data: FormInput) => {
            if (affiliate) {
                setLoading(true);
                try {
                    const payload = {
                        onboarding: true,
                        data: {
                            ...data,
                            ...(data.onSuccessfulEligibility ===
                                OnSuccessfulEligibility.RouteToLanding && {
                                routingURL: null,
                                zipCodeMatchCustomSuccessString: zipCodeSuccessText({
                                    ...affiliate,
                                    supportEmail: data.supportEmail,
                                    supportPhone: data.supportPhone,
                                    routingURL: data.routingURL,
                                    website: data.website,
                                }),
                            }),
                            ...(data.onSuccessfulEligibility ===
                                OnSuccessfulEligibility.RouteDirectly && {
                                customSuccessText: null,
                                zipCodeMatchCustomSuccessString: zipCodeWithUrlSuccessText({
                                    ...affiliate,
                                    supportEmail: data.supportEmail,
                                    supportPhone: data.supportPhone,
                                    routingURL: data.routingURL,
                                    website: data.website,
                                }),
                            }),
                            onSuccessfulZipCodeMatch: OnSuccessfulZipCodeMatch.ToSuccessScreen,
                        },
                        id: affiliate?.id,
                    };
                    const response = await updateRouting({
                        variables: {
                            input: payload,
                        },
                    });
                    isValidUpdate.current = !!response.data?.updateRoutingInfoForAffiliate?.success;
                } catch (err) {
                    setIsValidStep(false);
                    isValidUpdate.current = false;
                }
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [affiliate],
    );
    const isValid = !isDirty || (isValidForm && isDirty);
    useEffect(() => {
        setIsValidStep(isValid);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isValid]);
    const updateRoutingHandler = useCallback(async () => {
        if (!affiliate) {
            return false;
        }
        setLoading(true);
        await handleSubmit(onSubmit, () => setIsValidStep(false))();
        setLoading(false);
        return isValidForm && isValidUpdate.current;
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [affiliate, isValidForm]);
    useEffect(() => {
        setStepCb(() => async () => updateRoutingHandler());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [updateRoutingHandler]);
    return (
        <FormProvider {...formData}>
            <Grid item container xs={12} rowSpacing={5} flexDirection="column">
                <Grid item container xs={12} rowSpacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Support Contact Information</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle2">
                            Who should the patient reach out to schedule appointments?
                        </Typography>
                    </Grid>
                    <Grid item xs={8} container>
                        <ContactInformation />
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={12} container rowSpacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h6">Vanity URL Functionality</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1">
                            We give you a unique url to a form that you can give to patients to
                            verify their insurance eligibility. We offer two options when the
                            patient completes the form:
                            <List>
                                <ListItem>
                                    1. Have the client land on a success landing page with your
                                    contact information to schedule a consult
                                </ListItem>
                                <ListItem>
                                    2. Automatically redirect the client to your scheduling URL
                                </ListItem>
                            </List>
                            Which option would you prefer?
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={9} lg={7} xl={6}>
                        <RoutingForm affiliate={affiliateDataForRouting} watchSupportData />
                    </Grid>
                </Grid>
            </Grid>
        </FormProvider>
    );
};

export default Routing;
