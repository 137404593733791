import { ClockType, HighlightIcon, HighlightType, TimeUnit } from '~/schemaTypes';
import { ClientSelectionType, OffsetType } from '~/enums/enums';
import { Highlight, HighlightFormInput } from '../types/types';

const getOffsets = (
    offsetDays: number,
    offsetMonths: number,
    offsetYears: number,
): { days: number; months: number; years: number; type: OffsetType } => {
    return {
        days: Math.abs(offsetDays),
        months: Math.abs(offsetMonths),
        years: Math.abs(offsetYears),
        type:
            offsetDays >= 0 && offsetMonths >= 0 && offsetYears >= 0
                ? OffsetType.After
                : OffsetType.Before,
    };
};

export const getDefaultFormValues = (): HighlightFormInput => ({
    name: '',
    type: HighlightType.AppLink,
    clockType: ClockType.Timeline,
    startDate: new Date().toISOString(),
    endDate: new Date().toISOString(),
    timelineId: null,
    offsetDays: 0,
    offsetMonths: 0,
    offsetYears: 0,
    offsetType: OffsetType.After,
    duration: 1,
    durationTimeUnit: TimeUnit.Days,
    priority: 5,
    clientSelectionType: ClientSelectionType.IncludeAllApps,
    includeApps: [],
    excludeApps: [],
    tagIds: [],
    icon: HighlightIcon.None,
    imageContentId: null,
    simpleContentId: null,
    closeable: true,
    highlighted: false,
    shouldSendNotification: false,
    notificationText: null,
    linkText: null,
    displayAsModal: false,
    displayAsModalButtonText: null,
    useMessageTemplate: false,
    messageTemplateId: null,
});

const toClientSelectionType = ({ excludeApps, includeApps }: Highlight): ClientSelectionType => {
    if (includeApps && includeApps.length > 0) return ClientSelectionType.IncludeCertainApps;
    if (excludeApps && excludeApps.length > 0) return ClientSelectionType.ExcludeCertainApps;
    return ClientSelectionType.IncludeAllApps;
};

export const toFormValuesFromHighlight = (item?: Highlight): HighlightFormInput => {
    if (!item) {
        return getDefaultFormValues();
    }
    const offset = getOffsets(
        item?.offsetDays ?? 0,
        item?.offsetMonths ?? 0,
        item?.offsetYears ?? 0,
    );

    return {
        name: item.name || '',
        type: item.type,
        clockType: item.clockType,
        startDate: item.startDate,
        endDate: item.endDate,
        timelineId: item.timelineId,
        offsetDays: offset.days,
        offsetMonths: offset.months,
        offsetYears: offset.years,
        offsetType: offset.type,
        duration: item.duration,
        durationTimeUnit: item.durationTimeUnit,
        priority: item.priority,
        clientSelectionType: toClientSelectionType(item),
        includeApps: item.includeApps,
        excludeApps: item.excludeApps,
        tagIds: item.tags || [],
        icon: item.icon,
        imageContentId: item.imageContentId,
        simpleContentId: item.simpleContentId,
        closeable: item.closeable,
        highlighted: item.highlighted,
        shouldSendNotification: item.shouldSendNotification,
        notificationText: { en: item.notificationText?.en, es: item.notificationText?.es },
        linkText: { en: item.linkText?.en, es: item.linkText?.es },
        displayAsModal: item.displayAsModal ? item.displayAsModal : false,
        displayAsModalButtonText: {
            en: item.displayAsModalButtonText?.en,
            es: item.displayAsModalButtonText?.es,
        },
        messageTemplateId: item.messageTemplateId,
        useMessageTemplate: item.useMessageTemplate,
    };
};
