import React, { useMemo } from 'react';
import { Grid, Typography } from '@mui/material';
import MaterialTable from '@material-table/core';
import { AffiliateCarePatientQuery } from '~/schemaTypes';
import moment from 'moment/moment';
import { displayDate } from '~/helpers';
import { primaryColor } from '~/theme/WildTheme';

type UpcomingAppointmentsProps = {
    patient?: AffiliateCarePatientQuery;
};

const UpcomingAppointments: React.FC<UpcomingAppointmentsProps> = props => {
    const { patient } = props;
    const upcomingAppointments = useMemo(
        () =>
            (patient?.patient?.appointments || []).filter(appt =>
                moment(appt.startDate).isAfter(moment(new Date())),
            ),
        [patient?.patient?.appointments],
    );
    return (
        <Grid item xs={12}>
            <MaterialTable
                columns={[
                    {
                        title: 'Staff Name',
                        field: 'staffName',
                        render: ({ staffName }) => (
                            <Typography gutterBottom variant="body1">
                                {staffName}
                            </Typography>
                        ),
                        sorting: false,
                    },
                    {
                        title: 'Start Date/Time',
                        field: 'startDate',
                        render: ({ startDate }) => (
                            <Typography gutterBottom variant="body1">
                                {displayDate({
                                    isoDateStr: startDate,
                                    format: 'MM/DD/YYYY h:mma',
                                    displayTimeZone: true,
                                    timeZone: 'EST',
                                })}
                            </Typography>
                        ),
                        sorting: false,
                    },
                    {
                        title: 'Appointment Type',
                        field: 'description',
                        render: ({ description }) => (
                            <Typography gutterBottom variant="body1">
                                {description || 'Description not provided'}
                            </Typography>
                        ),
                        sorting: false,
                    },
                ]}
                data={upcomingAppointments}
                localization={{
                    body: {
                        emptyDataSourceMessage: 'No upcoming appointments',
                    },
                }}
                options={{
                    search: false,
                    paging: false,
                    showTitle: false,
                    headerStyle: {
                        color: primaryColor,
                    },
                    draggable: false,
                    toolbar: false,
                }}
            />
        </Grid>
    );
};

export default UpcomingAppointments;
