import React from 'react';
import { format, toDate } from 'date-fns-tz';
import { Card, Divider, Grid, Typography } from '@mui/material';
import RecheckEligibilityButton from '~/views/AffiliateCare/components/RecheckEligibilityButton/RecheckEligibilityButton';
import {
    EligibilityRefreshOrigin,
    PatientInsurancePlan,
    AffiliateCarePatientQuery,
} from '~/schemaTypes';
import { useVcUserView } from '~/hooks';
import { isAnthemInsurancePlan } from '~/utils/lactationServicesEligibility';
import InsuranceOverview from './InsuranceOverview';
import BabySection from './BabySection';

type OverviewSectionProps = {
    isEligible: boolean;
    portalView: boolean;
    patientId: string;
    affiliateId?: string;
    patient?: AffiliateCarePatientQuery;
    insurancePlanName: string;
    activeInsurancePlan: PatientInsurancePlan | null;
};

const OverviewSection: React.FC<OverviewSectionProps> = props => {
    const {
        isEligible,
        portalView,
        patientId,
        affiliateId,
        patient,
        insurancePlanName,
        activeInsurancePlan,
    } = props;
    const { isDoulaView, isVcUser } = useVcUserView();
    const eligibleServiceTitleText = isDoulaView ? 'Doula Services' : 'Lactation Services';
    const hideRecheckButton = isEligible || isAnthemInsurancePlan(activeInsurancePlan);
    return (
        <Card style={{ marginBottom: 0, padding: 0 }}>
            <Grid
                container
                alignItems="center"
                sx={{
                    padding: 4,
                    paddingBottom: 2,
                    ...(isEligible ? {} : { backgroundColor: '#f2dada' }),
                }}
            >
                <Typography variant="h6">
                    Patient Info &ndash;
                    {isEligible
                        ? ` Eligible for ${eligibleServiceTitleText}.`
                        : ` Insurance Expired. Not Currently Eligible for ${eligibleServiceTitleText}.`}
                </Typography>
                {hideRecheckButton || (
                    <RecheckEligibilityButton
                        origin={
                            portalView
                                ? EligibilityRefreshOrigin.AdminPortal
                                : EligibilityRefreshOrigin.AffiliatePortal
                        }
                        patientId={patientId}
                        affiliateId={affiliateId}
                    />
                )}
            </Grid>
            <Grid
                container
                spacing={2}
                alignItems="flex-start"
                sx={{ paddingX: 4, paddingBottom: 4 }}
            >
                <Grid item xs={12}>
                    <Divider />
                </Grid>
                <Grid item xs={3}>
                    <Typography color="primary" gutterBottom variant="body1">
                        Full Name:
                    </Typography>
                    <Typography variant="subtitle1">{patient?.patient?.fullName}</Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography color="primary" gutterBottom variant="body1">
                        Email:
                    </Typography>
                    <Typography variant="subtitle1">{patient?.patient?.email}</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography color="primary" gutterBottom variant="body1">
                        DOB:
                    </Typography>
                    <Typography variant="subtitle1">
                        {patient?.patient?.birthDate &&
                            format(toDate(patient?.patient?.birthDate.split('T')[0]), 'MM/dd/yyyy')}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography color="primary" gutterBottom variant="body1">
                        Date Created:
                    </Typography>
                    <Typography variant="subtitle1">
                        {patient?.patient?.createdAt &&
                            format(toDate(patient?.patient?.createdAt.split('T')[0]), 'MM/dd/yyyy')}
                    </Typography>
                </Grid>
                <Grid item xs={6}>
                    <Typography color="primary" gutterBottom variant="body1">
                        Phone Number:
                    </Typography>
                    <Typography variant="subtitle1">
                        {patient?.patient?.virtualCarePhoneNumber ?? patient?.patient?.phoneNumber}
                    </Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography color="primary" gutterBottom variant="body1">
                        Address:
                    </Typography>
                    <Typography variant="subtitle1">
                        {patient?.patient?.mailingAddress?.street1 && (
                            <Typography noWrap>
                                {patient?.patient?.mailingAddress?.street1}
                            </Typography>
                        )}
                        {patient?.patient?.mailingAddress?.street2 && (
                            <Typography noWrap>
                                {patient?.patient?.mailingAddress?.street2}
                            </Typography>
                        )}
                        {patient?.patient?.mailingAddress?.city &&
                        patient?.patient?.mailingAddress?.state &&
                        patient?.patient?.mailingAddress?.code ? (
                            <Typography noWrap>
                                {patient?.patient?.mailingAddress?.city},{' '}
                                {patient?.patient?.mailingAddress?.state}{' '}
                                {patient?.patient?.mailingAddress?.code}{' '}
                            </Typography>
                        ) : (
                            patient?.patient?.mailingAddress?.city ||
                            patient?.patient?.mailingAddress?.state
                        )}
                    </Typography>
                </Grid>
                {!isVcUser && (
                    <InsuranceOverview
                        insurancePlanName={insurancePlanName}
                        activeInsurancePlan={activeInsurancePlan}
                        isEligible={isEligible}
                        portalView={portalView}
                        patientId={patientId}
                        affiliateId={affiliateId}
                    />
                )}
                <BabySection patient={patient} fullView={isVcUser} />
            </Grid>
        </Card>
    );
};

export default OverviewSection;
