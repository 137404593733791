import React from 'react';
import {
    OrderByDirectionEnum,
    useFetchApplicationsV2ForEligibilityConfigFilterModalQuery,
} from '~/schemaTypes';
import { Control, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

type OrganizationSelectProps = {
    control: Control<any>;
    name: string;
};

const AppBundleIdSelect = ({ control, name }: OrganizationSelectProps) => {
    const { data, loading } = useFetchApplicationsV2ForEligibilityConfigFilterModalQuery({
        variables: {
            input: {
                orderBy: {
                    field: 'appBundleId',
                    order: OrderByDirectionEnum.Asc,
                },
            },
        },
    });

    const items = data?.applicationsV2.results ?? [];

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value } }) => (
                <Autocomplete
                    options={items}
                    getOptionLabel={option => option.appBundleId ?? ''}
                    isOptionEqualToValue={(option, val) => option.appBundleId === val.appBundleId}
                    filterSelectedOptions
                    value={items.find(item => item.appBundleId === value) || null}
                    onChange={(_, v) => onChange(v ? v.appBundleId : null)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="App Bundle Id"
                            placeholder="Select App Bundle Id"
                        />
                    )}
                    loading={loading}
                    loadingText="Loading..."
                    noOptionsText="No options"
                />
            )}
        />
    );
};

export default AppBundleIdSelect;
