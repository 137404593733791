import {
    Autocomplete,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    TextField,
} from '@mui/material';
import _ from 'lodash';
import React, { useState } from 'react';
import ArticlePicker from '~/components/ArticlePicker/ArticlePicker';
import Loading from '~/components/Loading/Loading';
import {
    ApprovalStatus,
    Language,
    ReadingLevel,
    useArticleGroupsForArticleApprovalPageQuery,
    usePreFetchAppsQuery,
} from '~/schemaTypes';

type ArticleApprovalFilterProps = {
    open: boolean;
    onClose: (filtered: boolean) => void;
};

export const ArticleApprovalFilter: React.FC<ArticleApprovalFilterProps> = ({ open, onClose }) => {
    const [selectedArticleId, setSelectedArticleId] = useState<string>(
        localStorage.getItem('filteredArticleId') ?? '',
    );
    const [filteredLanguage, setFilteredLanguage] = useState<string>(
        localStorage.getItem('filteredLanguage') ?? '',
    );
    const [filteredReadingLevel, setFilteredReadingLevel] = useState<string>(
        localStorage.getItem('filteredReadingLevel') ?? '',
    );
    const [filteredStatus, setFilteredStatus] = useState<string>(
        localStorage.getItem('filteredStatus') ?? '',
    );
    const [filteredApp, setFilteredApp] = useState<string>(
        localStorage.getItem('filteredApp') ?? '',
    );
    const [filteredGroups, setFilteredGroups] = useState<string[]>(
        localStorage
            .getItem('filteredGroups')
            ?.split(',')
            .filter(str => str.length > 1) ?? [],
    );
    const [filteredOperator, setFilteredOperator] = useState<string>(
        localStorage.getItem('filteredOperator') ?? 'OR',
    );

    const { data: appList, loading: appListLoading } = usePreFetchAppsQuery();

    const { data: groupList, loading: groupListLoading } =
        useArticleGroupsForArticleApprovalPageQuery();

    const handleSave = () => {
        localStorage.setItem('filteredArticleId', selectedArticleId);
        localStorage.setItem('filteredLanguage', filteredLanguage);
        localStorage.setItem('filteredReadingLevel', filteredReadingLevel);
        localStorage.setItem('filteredStatus', filteredStatus);
        localStorage.setItem('filteredApp', filteredApp);
        localStorage.setItem('filteredGroups', filteredGroups.join(','));
        if (filteredGroups.length > 0) {
            localStorage.setItem('filteredOperator', filteredOperator);
        } else {
            localStorage.removeItem('filteredOperator');
        }

        const filtersAppliedCount =
            (selectedArticleId ? 1 : 0) +
            (filteredLanguage ? 1 : 0) +
            (filteredReadingLevel ? 1 : 0) +
            (filteredStatus ? 1 : 0) +
            (filteredApp ? 1 : 0) +
            filteredGroups.length;

        localStorage.setItem('filtersAppliedCount', String(filtersAppliedCount));
        onClose(true);
    };

    const handleClearFilters = () => {
        setSelectedArticleId('');
        setFilteredLanguage('');
        setFilteredReadingLevel('');
        setFilteredStatus('');
        setFilteredApp('');
        setFilteredGroups([]);
        setFilteredOperator('OR');
    };
    if (appListLoading || groupListLoading) {
        return <Loading />;
    }

    return (
        <Dialog open={open} onClose={onClose} fullWidth>
            <DialogTitle>Filter Article Approvals</DialogTitle>
            <DialogContent>
                <Grid container spacing={1}>
                    <Grid item xs={12}>
                        <div style={{ margin: '5px' }}>
                            <span style={{ paddingRight: '5px' }}>App:</span>
                            <select
                                value={filteredApp}
                                onChange={e => setFilteredApp(e.target.value)}
                            >
                                <option value="">All Apps</option>
                                {_.sortBy(appList?.applicationsV2.results, 'appBundleId')
                                    .filter(a => a.isActive)
                                    .map(a => (
                                        <option value={a.appBundleId}>{a.appBundleId}</option>
                                    ))}
                            </select>
                        </div>
                        <div style={{ margin: '5px' }}>
                            <span style={{ paddingRight: '5px' }}>Status:</span>
                            <select
                                value={filteredStatus}
                                onChange={e => setFilteredStatus(e.target.value)}
                            >
                                <option value="">Any Status</option>
                                <option value={ApprovalStatus.Approved}>Approved</option>
                                <option value={ApprovalStatus.Denied}>Denied</option>
                                <option value={ApprovalStatus.Pending}>Pending</option>
                            </select>
                        </div>
                        <div style={{ margin: '5px' }}>
                            <span style={{ paddingRight: '5px' }}>Language:</span>
                            <select
                                value={filteredLanguage}
                                onChange={e => setFilteredLanguage(e.target.value)}
                            >
                                <option value="">Any Language</option>
                                <option value={Language.En}>English</option>
                                <option value={Language.Es}>Spanish</option>
                            </select>
                            <span style={{ marginLeft: '5px', paddingRight: '5px' }}>
                                Reading Level:
                            </span>
                            <select
                                value={filteredReadingLevel}
                                onChange={e => setFilteredReadingLevel(e.target.value)}
                            >
                                <option value="">Any Reading Level</option>
                                <option value={ReadingLevel.Eighth}>Eighth</option>
                                <option value={ReadingLevel.Fifth}>fifth</option>
                            </select>
                        </div>
                        <ArticlePicker
                            selectedArticleId={selectedArticleId}
                            setSelectedArticleId={setSelectedArticleId}
                            required={false}
                        />
                    </Grid>
                    {!selectedArticleId && (
                        <Grid item xs={12}>
                            <Autocomplete
                                multiple
                                options={
                                    _.sortBy(groupList?.articleGroupsV2.results, 'name').map(
                                        group => group.id,
                                    ) ?? []
                                }
                                getOptionLabel={group =>
                                    groupList?.articleGroupsV2.results.find(g => g.id === group)
                                        ?.name ?? ''
                                }
                                filterSelectedOptions
                                value={filteredGroups as string[]}
                                onChange={(_event, value) => {
                                    setFilteredGroups(value);
                                }}
                                renderInput={params => (
                                    <TextField
                                        // eslint-disable-next-line react/jsx-props-no-spreading
                                        {...params}
                                        label="Filter By Article Group"
                                        variant="outlined"
                                    />
                                )}
                            />
                        </Grid>
                    )}
                    {filteredGroups.length > 1 && (
                        <div style={{ margin: '5px' }}>
                            <span style={{ paddingRight: '5px' }}>Group Operator:</span>
                            <select
                                value={filteredOperator}
                                onChange={e => setFilteredOperator(e.target.value)}
                            >
                                <option value="AND">And</option>
                                <option value="OR">Or</option>
                            </select>
                        </div>
                    )}
                </Grid>
                <Button onClick={handleClearFilters}>Clear Filters</Button>
            </DialogContent>
            <DialogActions>
                <Button
                    onClick={() => {
                        onClose(false);
                    }}
                    color="primary"
                >
                    Close
                </Button>
                <Button onClick={handleSave} color="primary" variant="contained">
                    Save
                </Button>
            </DialogActions>
        </Dialog>
    );
};
