/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { useApolloClient } from '@apollo/client';
import {
    Autocomplete,
    AppBar,
    Badge,
    Divider,
    Grid,
    IconButton,
    Link,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    TextField,
    Tooltip,
    Typography,
    Box,
} from '@mui/material';
import {
    ArrowDropDown,
    ArrowDropUp,
    FiberManualRecord,
    NotificationsNone,
    Clear,
} from '@mui/icons-material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import { ROOT_ORG_ID } from '~/constants';
import { PagePermissions } from '~/hooks/useUserPermissions';
import { useAffiliateCareView, useUserPermissions, useUser, useVcUserView } from '~/hooks';
import {
    FetchCurrentUserForUseUserHookDocument,
    FetchCurrentUserForUseUserHookQuery,
    FetchOrganizationsFromCurrentUserQuery,
    useAdvocateNotificationsByUserLazyQuery,
    useCurrentUserSubscriptionSubscription,
    useFetchOrganizationsFromCurrentUserQuery,
    useUpdateAdvocateNotificationMutation,
    useUpdateCurrentOrgInNavMutation,
} from '~/schemaTypes';
import { GlobalLoading, SuppressNextGlobalAlert } from '~/state';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faEnvelopeOpen } from '@fortawesome/free-solid-svg-icons';
import { useDashboard } from '../../views/Dashboard/DashboardProvider';
import { useStyles } from './styles';
import { isLargeOrg } from '../../helpers/isLargeOrg';
import { SessionCountdown } from './SessionCountdown';

export const ALL_ORGS_ID = '000000000000000000000003';

type Organization = NonNullable<
    FetchOrganizationsFromCurrentUserQuery['currentUser']
>['organizations'][0];

type NavLinkType = {
    path: string;
    label: string;
    hide: (userPermissions: PagePermissions) => boolean;
};

const millesecondsRange = {
    msInDay: 86400000,
    msInHour: 3600000,
    msInMinute: 60000,
};

export const NavLinks: NavLinkType[] = [
    {
        path: '/patients',
        label: 'Patients',
        hide: userPermissions => !userPermissions.PatientList.Read,
    },
    {
        path: '/appointments',
        label: 'Appointments',
        hide: userPermissions => !userPermissions.Appointments.Read,
    },
    {
        path: '/measurements',
        label: 'Tracker Data',
        hide: userPermissions => !userPermissions.Measurements.Read,
    },
    {
        path: '/surveys',
        label: 'Survey Responses',
        hide: userPermissions => !userPermissions.SurveyResponses.Read,
    },
    {
        path: '/orders',
        label: 'Orders',
        hide: userPermissions => !userPermissions.Orders.Read,
    },
    {
        path: '/staff',
        label: 'Staff',
        hide: userPermissions => !userPermissions.Staff.Read,
    },
    {
        path: '/orgs',
        label: 'Organizations',
        hide: userPermissions => !userPermissions.Practices.Read,
    },
    {
        path: '/plans',
        label: 'Health Plans',
        hide: userPermissions => !userPermissions.HealthPlans.Read,
    },
    {
        path: '/providers',
        label: 'Providers',
        hide: userPermissions => !userPermissions.Providers.Read,
    },
    {
        path: '/devices',
        label: 'Devices',
        hide: userPermissions => !userPermissions.Devices.Read,
    },
];

type PortalNavProps = {
    height?: string;
};

const PortalNav: React.FC<PortalNavProps> = ({ height = '70px' }) => {
    const dashboard = useDashboard();
    const { classes } = useStyles({
        height,
    });
    const client = useApolloClient();
    const history = useNavigate();
    const { isAffiliateView } = useAffiliateCareView();
    const { isVcUser } = useVcUserView();
    const showOrgPicker = !(isAffiliateView || isVcUser);
    const [popupOpen, setPopupOpen] = useState(false);
    const [orgId, setOrgId] = useState('');

    const { data: userData, refetch: refetchUser } = useUser();
    const { pagePermissions } = useUserPermissions();
    const location = useLocation();
    const [updateCurrentOrg] = useUpdateCurrentOrgInNavMutation({
        onCompleted: async () => {
            const isHATasksPage = location.pathname.includes('portal/advocate-task-list');
            const isMeasurementsPage = location.pathname.includes('portal/measurements');
            const isSurveysPage = location.pathname.includes('portal/surveys');

            // Temporary fix to avoid crashing (see SYS-12982)
            if ((isHATasksPage || isMeasurementsPage || isSurveysPage) && isLargeOrg(orgId)) {
                history('/');
                dashboard.setOrgId(orgId);
                return;
            }

            const isPatientPage = location.pathname.includes('/patients');
            const isPatientApptsPage = isPatientPage && location.pathname.includes('/appointments');
            const isClearState =
                isPatientApptsPage || location.pathname.includes('/message-center');
            if (isClearState) {
                await client.clearStore();
                await refetchUser();
                dashboard.setOrgId(orgId);
            } else {
                try {
                    GlobalLoading({
                        open: true,
                        message: 'Switching Orgs...',
                    });

                    await client.resetStore().finally(() => dashboard.setOrgId(orgId));

                    GlobalLoading({
                        open: false,
                        message: 'Switching Orgs...',
                    });
                } catch (error) {
                    GlobalLoading({
                        open: false,
                        message: 'Switching Orgs...',
                    });
                }
            }
            if (isPatientPage) {
                history('/portal/patients');
            }
        },
    });

    useCurrentUserSubscriptionSubscription({
        shouldResubscribe: true,
        onData: ({ client, data }) => {
            const { cache } = client;
            const newOrUpdatedUnreadMessages =
                data.data?.currentUserSubscription?.numberOfUnreadChatMessages;
            const userQuery = cache.readQuery<FetchCurrentUserForUseUserHookQuery>({
                query: FetchCurrentUserForUseUserHookDocument,
            });
            if (userQuery?.currentUser?.id && newOrUpdatedUnreadMessages) {
                cache.writeQuery({
                    query: FetchCurrentUserForUseUserHookDocument,
                    data: {
                        currentUser: {
                            ...userQuery.currentUser,
                            newMessageNotificationCount: newOrUpdatedUnreadMessages,
                        },
                    },
                });
            }
        },
    });

    const [updateAdvocateNotification] = useUpdateAdvocateNotificationMutation({});

    const [orgList, setOrgList] = useState<Array<{ org: Organization; level: number }>>([]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [newNotifications, setNewNotifications] = useState(0);
    const [shouldShowNotifications, setShouldShowNotifications] = useState(false);

    const handleOrgSelect = ({ orgId }: { orgId: string }): void => {
        setOrgId(orgId);
        if (userData?.currentUser?.id) {
            updateCurrentOrg({
                variables: {
                    input: {
                        id: userData.currentUser.id,
                        data: {
                            rootOrg: userData.currentUser.rootOrg.id,
                            currentOrgId: orgId,
                            email: userData.currentUser.email,
                            name: userData.currentUser.name,
                        },
                    },
                },
            });
        }
    };

    const [
        getAdvocateNotifications,
        { data: advocateNotificationsData, loading: advocateNotificationsLoading },
    ] = useAdvocateNotificationsByUserLazyQuery({
        variables: {
            input: {
                userId: userData?.currentUser?.id,
                periodInDays: 7,
            },
        },
        fetchPolicy: 'no-cache',
    });

    const handleAdvocateNotificationsMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
        getAdvocateNotifications();
    };

    const handleAdvocateNotificationClick = (taskId: string) => {
        updateAdvocateNotification({
            variables: {
                input: {
                    id: taskId,
                    data: {
                        viewed: true,
                    },
                },
            },
        });
    };

    const handleAdvocateNotificationsMenuClose = () => {
        setAnchorEl(null);
    };

    useEffect(() => {
        const shouldShow =
            advocateNotificationsData?.advocateNotificationsByUser &&
            advocateNotificationsData?.advocateNotificationsByUser.length > 0;

        setShouldShowNotifications(Boolean(shouldShow));
    }, [advocateNotificationsData]);

    const { data: orgsData } = useFetchOrganizationsFromCurrentUserQuery({});
    useEffect(() => {
        const orgs = orgsData?.currentUser?.organizations;
        if (orgs) {
            const root = orgs.find(
                ({ id }: { id: string }) => id === userData?.currentUser?.rootOrg.id,
            );
            if (root) {
                const ret: Array<{ org: Organization; level: number }> = [];
                const addOrg = (org: Organization, level: number) => {
                    if (orgs) {
                        if (ret.find(r => r.org.id === org.id) === undefined) {
                            ret.push({ org, level });
                        }
                        _(orgs)
                            .filter(o => o.parentId === org.id)
                            .sortBy('brandingName')
                            .forEach(child => {
                                addOrg(child, level + 1);
                            });
                    }
                };
                addOrg(root, 0);
                if (
                    userData?.currentUser?.secondaryOrgs &&
                    userData?.currentUser?.secondaryOrgs.length > 0
                ) {
                    const allOrgs = orgs.find(o => o.brandingName === 'All Orgs');
                    if (allOrgs) {
                        ret.unshift({ org: allOrgs, level: 0 });
                    }
                    for (const subOrg of userData?.currentUser?.secondaryOrgs) {
                        const orgToAdd = orgs.find(o => o.id === subOrg?.orgId);
                        if (orgToAdd) {
                            addOrg(orgToAdd, 0);
                        }
                    }
                }
                setOrgList(ret);
            }
        }

        if (userData?.currentUser?.newTaskNotificationCount) {
            setNewNotifications(userData?.currentUser?.newTaskNotificationCount);
        }
    }, [userData, orgsData, setOrgList]);

    const handleOrgSwitch = (orgId: string) => {
        SuppressNextGlobalAlert(true);
        handleOrgSelect({ orgId });
    };

    const getNotificationCreatedTime = (createdAt: string) => {
        const creationDate = new Date(createdAt);
        const currentDate = new Date();
        const diff = Math.floor(currentDate.getTime() - creationDate.getTime());

        if (diff > millesecondsRange.msInDay) {
            return `${Math.round(diff / millesecondsRange.msInDay)}d`;
        }

        if (diff > millesecondsRange.msInHour) {
            return `${Math.round(diff / millesecondsRange.msInHour)}h`;
        }

        const minutes = Math.round(diff / millesecondsRange.msInMinute);

        return minutes < 1 ? '1m' : `${minutes}m`;
    };

    return (
        <AppBar className={classes.root} color="primary" position="relative">
            <Grid
                container
                spacing={1}
                alignItems="center"
                justifyContent="space-between"
                wrap="nowrap"
                style={{ height, marginTop: 0, marginBottom: 0 }}
            >
                <Grid item>
                    {showOrgPicker && (
                        <Autocomplete
                            disableClearable
                            options={
                                orgList.map(p => ({
                                    name: p.org.brandingName,
                                    value: p.org.id,
                                    level: p.level,
                                    unreadMessages: p.org.unreadMessagesCount,
                                })) ?? []
                            }
                            size="small"
                            className={classes.orgDropDown}
                            getOptionLabel={selected => selected.name ?? ''}
                            onChange={(_, o) => {
                                if (o && o.value) {
                                    handleOrgSwitch(o.value);
                                }
                            }}
                            clearIcon={<Clear htmlColor="white" fontSize="small" />}
                            onOpen={() => {
                                setPopupOpen(true);
                            }}
                            onClose={() => {
                                setPopupOpen(false);
                            }}
                            value={{
                                name: userData?.currentUser?.currentOrganization?.brandingName,
                                value: userData?.currentUser?.currentOrganization?.id,
                                level: 0,
                                unreadMessages:
                                    userData?.currentUser?.currentOrganization?.unreadMessagesCount,
                            }}
                            popupIcon={
                                <Badge
                                    badgeContent={
                                        userData?.currentUser?.numberOfUnreadMessages ?? 0
                                    }
                                    color="error"
                                >
                                    {popupOpen ? (
                                        <ArrowDropUp htmlColor="white" />
                                    ) : (
                                        <ArrowDropDown htmlColor="white" />
                                    )}
                                </Badge>
                            }
                            renderOption={(props, option) => (
                                <Box
                                    component="li"
                                    style={{
                                        display: 'flex',
                                        flexDirection: 'column',
                                        flexWrap: 'wrap',
                                        alignItems: 'flex-start',
                                    }}
                                    {...props}
                                >
                                    <Badge badgeContent={option.unreadMessages} color="error">
                                        <span
                                            className={classes.dropDownOption}
                                            style={{ paddingLeft: `${option.level}em` }}
                                            data-test={option.name}
                                        >
                                            {option.name}
                                        </span>
                                    </Badge>
                                </Box>
                            )}
                            renderInput={params => (
                                <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    variant="outlined"
                                    className={classes.orgInput}
                                />
                            )}
                        />
                    )}
                </Grid>
                <Grid item xs={3}>
                    <Grid container spacing={1} wrap="nowrap" justifyContent="flex-end">
                        <SessionCountdown />
                        {((!isAffiliateView && pagePermissions?.HealthAdvocate.Read) ||
                            pagePermissions?.HealthAdvocateSupervisor.Read) && (
                            <Grid item>
                                <Tooltip title="Advocate Notifications">
                                    <IconButton
                                        color="inherit"
                                        onClick={handleAdvocateNotificationsMenuOpen}
                                        size="large"
                                    >
                                        <Badge badgeContent={newNotifications ?? 0} color="error">
                                            <NotificationsNone />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                                <Menu
                                    id="menu-appbar"
                                    anchorEl={anchorEl}
                                    transformOrigin={{
                                        vertical: 'top',
                                        horizontal: 'right',
                                    }}
                                    open={Boolean(anchorEl)}
                                    onClose={handleAdvocateNotificationsMenuClose}
                                    className={classes.advocateNotificationsMenu}
                                >
                                    <div className={classes.notificationsHeader}>
                                        <Typography
                                            variant="body1"
                                            align="center"
                                            className={classes.notificationsHeaderText}
                                        >
                                            Notifications
                                        </Typography>
                                    </div>
                                    <div className={classes.advocateNotificationsContainer}>
                                        {advocateNotificationsLoading ? (
                                            <Loading height={200} />
                                        ) : (
                                            <div>
                                                {!shouldShowNotifications ? (
                                                    <div>
                                                        <Typography
                                                            variant="body1"
                                                            align="center"
                                                            className={
                                                                classes.emptyNotificationsList
                                                            }
                                                        >
                                                            There are no notifications
                                                        </Typography>
                                                    </div>
                                                ) : (
                                                    advocateNotificationsData?.advocateNotificationsByUser?.map(
                                                        notification => (
                                                            <Tooltip
                                                                title={`${notification?.patient?.fullName} - Priority ${notification?.advocateTask?.priority} Task - ${notification?.advocateTask?.label}`}
                                                                key={notification?.advocateTask?.id}
                                                            >
                                                                <Link
                                                                    onClick={() =>
                                                                        handleAdvocateNotificationClick(
                                                                            notification?.id,
                                                                        )
                                                                    }
                                                                    component={NavLink}
                                                                    to={`/portal/advocate-tasks/${notification?.advocateTask?.id}`}
                                                                    underline="hover"
                                                                >
                                                                    <MenuItem
                                                                        className={
                                                                            classes.notificationItemContainer
                                                                        }
                                                                    >
                                                                        <div
                                                                            className={
                                                                                classes.isViewed
                                                                            }
                                                                        >
                                                                            {!notification?.viewed && (
                                                                                <ListItemIcon
                                                                                    className={
                                                                                        classes.isViewedIcon
                                                                                    }
                                                                                >
                                                                                    <FiberManualRecord fontSize="small" />
                                                                                </ListItemIcon>
                                                                            )}
                                                                        </div>
                                                                        <div
                                                                            className={
                                                                                classes.taskInfoContainer
                                                                            }
                                                                        >
                                                                            <ListItemText
                                                                                className={
                                                                                    classes.taskInfo
                                                                                }
                                                                            >
                                                                                {`${notification?.patient?.fullName} - Priority ${notification?.advocateTask?.priority} Task`}
                                                                            </ListItemText>
                                                                            <ListItemText
                                                                                className={
                                                                                    classes.taskLabel
                                                                                }
                                                                            >
                                                                                {
                                                                                    notification
                                                                                        ?.advocateTask
                                                                                        ?.label
                                                                                }
                                                                            </ListItemText>
                                                                        </div>
                                                                        <ListItemText
                                                                            className={
                                                                                classes.notificationTime
                                                                            }
                                                                        >
                                                                            {getNotificationCreatedTime(
                                                                                notification?.createdAt,
                                                                            )}
                                                                        </ListItemText>
                                                                    </MenuItem>
                                                                    <Divider
                                                                        className={
                                                                            classes.dividerColor
                                                                        }
                                                                    />
                                                                </Link>
                                                            </Tooltip>
                                                        ),
                                                    )
                                                )}
                                            </div>
                                        )}
                                    </div>
                                </Menu>
                            </Grid>
                        )}
                        {!isAffiliateView && pagePermissions?.MessageCenter.Read && (
                            <Grid item>
                                <Tooltip title="Message Center (unread)">
                                    <IconButton
                                        component={NavLink}
                                        to="/portal/message-center/false"
                                        color="inherit"
                                        size="large"
                                    >
                                        <Badge
                                            badgeContent={
                                                userData?.currentUser?.currentOrganization.id ===
                                                    ROOT_ORG_ID ||
                                                userData?.currentUser?.currentOrganization.id ===
                                                    ALL_ORGS_ID
                                                    ? userData.currentUser.numberOfUnreadMessages
                                                    : userData?.currentUser?.currentOrganization
                                                          .unreadMessagesCount ?? 0
                                            }
                                            color="error"
                                        >
                                            <FontAwesomeIcon icon={faEnvelope} />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                        {!isAffiliateView && pagePermissions?.MessageCenter.Read && (
                            <Grid item>
                                <Tooltip title="Message Center (unresolved)">
                                    <IconButton
                                        component={NavLink}
                                        to="/portal/message-center/true"
                                        color="inherit"
                                        size="large"
                                    >
                                        <Badge
                                            badgeContent={
                                                userData?.currentUser?.openConversationsCount ?? 0
                                            }
                                            color="success"
                                        >
                                            <FontAwesomeIcon icon={faEnvelopeOpen} />
                                        </Badge>
                                    </IconButton>
                                </Tooltip>
                            </Grid>
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </AppBar>
    );
};

export default PortalNav;
