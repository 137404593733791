import * as Yup from 'yup';
import { OnSuccessfulEligibility } from '~/schemaTypes';

const urlPattern = /^(http:\/\/|https:\/\/)/;

export const ROUTING_FORM_SCHEMA = Yup.object().shape({
    onSuccessfulEligibility: Yup.string().required(),
    routingURL: Yup.string().when(
        ['onSuccessfulEligibility'],
        ([onSuccessfulEligibility], schema) => {
            if (onSuccessfulEligibility === OnSuccessfulEligibility.RouteDirectly) {
                return schema
                    .required()
                    .matches(urlPattern, 'URL must start with http:// or https://');
            }
            return schema.nullable();
        },
    ),
    customSuccessText: Yup.string().when(
        ['onSuccessfulEligibility'],
        ([onSuccessfulEligibility], schema) => {
            if (onSuccessfulEligibility === OnSuccessfulEligibility.RouteToLanding) {
                return schema.required();
            }
            return schema.nullable();
        },
    ),
});
