import { Button, DialogActions, DialogContent, Grid, TextField, useTheme } from '@mui/material';
import React, { useState } from 'react';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';

type ExportInvocesModalProps = {
    onExport: (startDate: string, endDate: string) => void;
    onClose: () => void;
};

const ExportInvocesModal: React.FC<ExportInvocesModalProps> = ({ onExport, onClose }) => {
    const theme = useTheme();
    const primaryColor = theme.palette.primary.main;

    const [customDate, setCustomDate] = useState(false);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [activeButton, setActiveButton] = useState<number | null>(null);

    const formatDate = (date: Date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

    const handleMonthClick = (monthsAgo: number) => {
        const currentDate = new Date();
        const start = new Date(currentDate.getFullYear(), currentDate.getMonth() - monthsAgo, 1);
        const end = new Date(currentDate.getFullYear(), currentDate.getMonth() - monthsAgo + 1, 0);
        setStartDate(formatDate(start));
        setEndDate(formatDate(end));
        setActiveButton(monthsAgo);
        setCustomDate(false);
    };

    const handleCustomClick = () => {
        setCustomDate(true);
        setStartDate(null);
        setEndDate(null);
        setActiveButton(null);
    };

    const handleExport = () => {
        if (startDate && endDate) {
            onExport(startDate, endDate);
            onClose();
        }
    };

    const getMonthLabel = (monthsAgo: number) => {
        const currentDate = new Date();
        const date = new Date(currentDate.getFullYear(), currentDate.getMonth() - monthsAgo, 1);
        return date.toLocaleString('default', { month: 'long' });
    };

    return (
        <>
            <DialogTitleWithClose id="export-invoices-modal" onClose={() => onClose()}>
                Select Date range for Export
            </DialogTitleWithClose>
            <DialogContent>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={3}>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleMonthClick(1)}
                                    fullWidth
                                    style={{
                                        backgroundColor:
                                            activeButton === 1 ? primaryColor : 'inherit',
                                        color: activeButton === 1 ? 'white' : 'inherit',
                                    }}
                                >
                                    {getMonthLabel(1)}
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleMonthClick(2)}
                                    fullWidth
                                    style={{
                                        backgroundColor:
                                            activeButton === 2 ? primaryColor : 'inherit',
                                        color: activeButton === 2 ? 'white' : 'inherit',
                                    }}
                                >
                                    {getMonthLabel(2)}
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="outlined"
                                    onClick={() => handleMonthClick(3)}
                                    fullWidth
                                    style={{
                                        backgroundColor:
                                            activeButton === 3 ? primaryColor : 'inherit',
                                        color: activeButton === 3 ? 'white' : 'inherit',
                                    }}
                                >
                                    {getMonthLabel(3)}
                                </Button>
                            </Grid>
                            <Grid item xs={3}>
                                <Button
                                    variant="outlined"
                                    onClick={handleCustomClick}
                                    fullWidth
                                    style={{
                                        backgroundColor: customDate ? primaryColor : 'inherit',
                                        color: customDate ? 'white' : 'inherit',
                                    }}
                                >
                                    Custom
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={2}>
                            <Grid item xs={6}>
                                <TextField
                                    label="Start Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    value={startDate || ''}
                                    onChange={e => setStartDate(e.target.value)}
                                    disabled={!customDate}
                                    fullWidth
                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    label="End Date"
                                    type="date"
                                    InputLabelProps={{ shrink: true }}
                                    value={endDate || ''}
                                    onChange={e => setEndDate(e.target.value)}
                                    disabled={!customDate}
                                    fullWidth
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                    justifyContent: 'flex-start',
                    padding: '24px',
                }}
            >
                <Button
                    onClick={handleExport}
                    color="secondary"
                    variant="contained"
                    style={{ marginRight: '8px' }}
                    disabled={!startDate || !endDate}
                >
                    Export
                </Button>
                <Button
                    onClick={() => onClose()}
                    color="secondary"
                    variant="outlined"
                    style={{ marginRight: '8px' }}
                >
                    Cancel
                </Button>
            </DialogActions>
        </>
    );
};

export default ExportInvocesModal;
