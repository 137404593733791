import { faEye, faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Dialog, IconButton, Link, TablePagination, Tooltip } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { Add, InfoOutlined } from '@mui/icons-material';
import copy from 'copy-to-clipboard';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomFilter, { IFilterChange } from '~/components/CustomFilter/CustomFilter';
import WhereUsedModal from '~/components/WhereUsedModal/WhereUsedModal';
import { PAGESIZE } from '~/constants';
import { displayDate } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUserPermissions } from '~/hooks';
import {
    AlertSeverity,
    ContentDirectory,
    ContentDirectoryListDocument,
    ContentDirectoryListFilterInput,
    ContentDirectoryListQuery,
    ContentDirectoryListQueryVariables,
    useContentDirectoryListQuery,
    useContentDirectoryWhereUsedLazyQuery,
    useDeleteContentDirectoryMutation,
} from '~/schemaTypes';
import { TriggerGlobalAlert, TriggerGlobalConfirm } from '~/state';

type ContentDirectoriesList = NonNullable<
    ContentDirectoryListQuery['contentDirectorysV2']['results'][0]
>;

const useStyles = makeStyles()({
    root: {
        position: 'relative',
        height: '100%',
        '& .MuiTableSortLabel-root:hover .MuiTableSortLabel-icon': {
            opacity: '0',
        },
    },
});

const ContentDirectorys: React.FC = () => {
    const { classes } = useStyles();
    const { pagePermissions } = useUserPermissions();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(PAGESIZE);
    const [filter, setFilter] = useState<IFilterChange<ContentDirectoriesList> | null>(null);
    const [selectedItem, setSelectedItem] = useState<ContentDirectory>();
    const [showUsageModal, setShowUsageModal] = useState(false);

    const history = useNavigate();

    const [whereUsed, { data: whereUsedData, loading: whereUsedLoading }] =
        useContentDirectoryWhereUsedLazyQuery();
    const contentDirectoryListQueryVariables: ContentDirectoryListQueryVariables = {
        input: {
            pagination: {
                skip: page * rowsPerPage,
                limit: rowsPerPage,
            },
            filter: filter as ContentDirectoryListFilterInput | null,
        },
    };

    const [deleteItem, { loading: deleteLoading }] = useDeleteContentDirectoryMutation({
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: ContentDirectoryListDocument,
                variables: contentDirectoryListQueryVariables,
            },
        ],
    });

    const handleShowUsage = (item: ContentDirectory) => {
        whereUsed({ variables: { id: item.id } });
        setSelectedItem(item);
        setShowUsageModal(true);
    };
    const handleDelete = () => {
        TriggerGlobalConfirm({
            message: `Do you really want to delete '${selectedItem?.name}'?`,
            callback: () => {
                deleteItem({ variables: { id: selectedItem?.id } });
            },
        });
        setShowUsageModal(false);
    };

    const { data: dataList, loading: listLoading } = useContentDirectoryListQuery({
        variables: contentDirectoryListQueryVariables,
    });

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, page: number) => {
        event?.preventDefault();
        setPage(page);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);
        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable
                title="Content Directories"
                icons={tableIcons}
                isLoading={listLoading || deleteLoading || whereUsedLoading}
                data={dataList?.contentDirectorysV2.results ?? []}
                components={{
                    Pagination: props => (
                        <TablePagination
                            {...props}
                            count={dataList?.contentDirectorysV2.total ?? 0}
                            page={page}
                            onPageChange={handleChangePage}
                        />
                    ),
                }}
                actions={[
                    {
                        onClick: event => {
                            event.preventDefault();
                        },
                        hidden: !pagePermissions?.Articles.Edit,
                        icon: () => (
                            <CustomFilter<ContentDirectoriesList>
                                setFilter={setFilter}
                                fields={['name']}
                                defaultField="name"
                            />
                        ),
                        isFreeAction: true,
                    },
                    {
                        onClick: () => history('/app-config/contentdirectory/new'),
                        hidden: !pagePermissions?.Articles.Edit,
                        icon: () => <Add />,
                        tooltip: 'Add Content Directory',
                        isFreeAction: true,
                    },
                    {
                        onClick: (_, { id }: any) => history(`/app-config/contentdirectory/${id}`),
                        hidden: !pagePermissions?.Articles.Edit,
                        icon: () => <FontAwesomeIcon icon={faPenToSquare} />,
                        tooltip: 'Edit Content Directory',
                    },
                    {
                        onClick: (_e, item) => {
                            setSelectedItem(item as ContentDirectory);
                            handleShowUsage(item as ContentDirectory);
                        },
                        icon: () => <FontAwesomeIcon icon={faEye} />,
                        tooltip: 'View References',
                    },
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        sorting: false,
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        TriggerGlobalAlert({
                                            message: 'Id copied to clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlined />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Name',
                        field: 'name',
                        customSort: (a, b) => {
                            if (a.name === undefined || b.name === undefined) {
                                return 0;
                            }
                            return a.name.toLowerCase().localeCompare(b.name.toLowerCase());
                        },
                        render: ({ id, name }: ContentDirectory) => (
                            <Tooltip title="Edit In New Window">
                                <Link
                                    style={{ textDecoration: 'underline' }}
                                    target="_blank"
                                    href={`/app-config/contentdirectory/${id}`}
                                    rel="noopener noreferrer"
                                >
                                    {name}
                                </Link>
                            </Tooltip>
                        ),
                    },
                    { title: 'Item Count', field: 'items.length' },
                    {
                        title: 'Last Updated',
                        field: 'updatedAt',
                        render: ({ updatedAt }) => (
                            <>
                                {displayDate({
                                    isoDateStr: updatedAt,
                                })}
                            </>
                        ),
                    },
                ]}
                onRowsPerPageChange={handleChangeRowsPerPage}
                options={{ pageSize: rowsPerPage, pageSizeOptions: [25, 50, 100], search: false }}
            />
            <Dialog scroll="paper" open={!whereUsedLoading && showUsageModal}>
                <WhereUsedModal
                    onClose={() => setShowUsageModal(false)}
                    onDelete={handleDelete}
                    title={`Usage for ${selectedItem?.name}`}
                    canDelete={pagePermissions?.ContentDirectory.Delete || false}
                    usageData={whereUsedData?.contentDirectoryWhereUsed?.dependencies || []}
                    permissions={pagePermissions}
                />
            </Dialog>
        </div>
    );
};

export default ContentDirectorys;
