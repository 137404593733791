import { Button, Grid, IconButton, Link, Tooltip, Typography } from '@mui/material';
import { makeStyles } from 'tss-react/mui';
import { InfoOutlined as InfoOutlinedIcon, Link as LinkIcon } from '@mui/icons-material';
import MaterialTable from '@material-table/core';
import React, { useRef, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import tableIcons from '~/helpers/tableIcons';
import {
    useVcExternalFormsForPatientFormsPageLazyQuery,
    OrderByDirectionEnum,
    VcExternalFormStatus,
    AlertSeverity,
    VcExternalFormsForPatientFormsPageQuery,
} from '~/schemaTypes';
import { useUserPermissions } from '~/hooks';
import copy from 'copy-to-clipboard';
import { format } from 'date-fns';
import { SuppressNextGlobalAlert, TriggerGlobalAlert } from '../../../../../state';
import ResendFormButton from './ResendFormButton';
import SendFormEmailModal from '../../SendFormEmailModal/SendFormEmailModal';

const useStyles = makeStyles()({
    root: {},
});

type RouteParams = {
    id: string;
};

type VCExternalFormTableData =
    VcExternalFormsForPatientFormsPageQuery['vCExternalFormsV2']['results']['0'];

const PatientVCExternalForms: React.FC = () => {
    const { id } = useParams<RouteParams>();
    const { classes } = useStyles();
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(10);
    const { pagePermissions } = useUserPermissions();
    const [isSendFormEmailModalOpen, setIsSendFormEmailModalOpen] = useState(false);

    const [fetchForms, { loading: loadingForms }] = useVcExternalFormsForPatientFormsPageLazyQuery({
        fetchPolicy: 'no-cache',
        nextFetchPolicy: 'no-cache',
    });

    const loadFormsData = (page: number, rowsPerPage: number) => {
        return fetchForms({
            variables: {
                input: {
                    filter: {
                        fields: {
                            patientId: id,
                        },
                    },
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                    pagination: {
                        skip: page * rowsPerPage,
                        limit: rowsPerPage,
                    },
                },
            },
        });
    };

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };

    return (
        <div className={classes.root} ref={tableRef}>
            <MaterialTable<VCExternalFormTableData>
                title="Forms"
                icons={tableIcons}
                actions={[
                    {
                        hidden: !pagePermissions?.VcExternalForm.Edit,
                        icon: () => (
                            <Button
                                variant="outlined"
                                onClick={() => {
                                    setIsSendFormEmailModalOpen(true);
                                }}
                            >
                                Send Form
                            </Button>
                        ),
                        isFreeAction: true,
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        onClick: () => {},
                    },
                ]}
                columns={[
                    {
                        title: 'Id',
                        field: 'id',
                        width: 32,
                        align: 'center',
                        render: ({ id }) => (
                            <Tooltip title={id}>
                                <IconButton
                                    onClick={() => {
                                        copy(`${id}`);
                                        SuppressNextGlobalAlert(false);
                                        TriggerGlobalAlert({
                                            message: 'VC External Form Id Copied to Clipboard',
                                            severity: AlertSeverity.Success,
                                        });
                                    }}
                                    size="large"
                                >
                                    <InfoOutlinedIcon />
                                </IconButton>
                            </Tooltip>
                        ),
                    },
                    {
                        title: 'Name',
                        render: ({ id, status, template }) => {
                            return status === VcExternalFormStatus.Sent ? (
                                template?.name
                            ) : (
                                <Link component={NavLink} to={id} underline="always">
                                    {template?.name}
                                </Link>
                            );
                        },
                    },
                    {
                        title: 'Status',
                        render: ({ status, emailedLink, template, id }) => {
                            return (
                                <Grid container alignItems="center" gap={1}>
                                    <Grid item flexGrow={1}>
                                        <Typography>{status}</Typography>
                                    </Grid>
                                    {status === VcExternalFormStatus.Sent && (
                                        <>
                                            {pagePermissions?.VcExternalForm.Edit && (
                                                <Tooltip title="Send Again?">
                                                    <ResendFormButton
                                                        templateName={template?.name ?? null}
                                                        formId={id}
                                                    />
                                                </Tooltip>
                                            )}
                                            <Tooltip title="Copy Link">
                                                <IconButton
                                                    title="Copy Link"
                                                    sx={{ padding: 0 }}
                                                    onClick={() => {
                                                        copy(`${emailedLink}`);
                                                        SuppressNextGlobalAlert(false);
                                                        TriggerGlobalAlert({
                                                            message:
                                                                'Emailed Link Copied to Clipboard',
                                                            severity: AlertSeverity.Success,
                                                        });
                                                    }}
                                                >
                                                    <LinkIcon />
                                                </IconButton>
                                            </Tooltip>
                                        </>
                                    )}
                                </Grid>
                            );
                        },
                        width: 170,
                    },
                    {
                        title: 'Date',
                        render: ({ dateSent, dateTaken, status }) => {
                            const date =
                                status === VcExternalFormStatus.Sent ? dateSent : dateTaken;
                            return (
                                <Typography>
                                    {date && format(new Date(date), 'MM/dd/yyyy h:mm a')}
                                </Typography>
                            );
                        },
                    },
                ]}
                data={({ page, pageSize }) =>
                    loadFormsData(page, pageSize).then(response => {
                        const data = response.data?.vCExternalFormsV2;
                        return {
                            data: data?.results ?? [],
                            page,
                            totalCount: data?.total ?? 0,
                        };
                    })
                }
                localization={{ header: { actions: '' } }}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                isLoading={loadingForms}
                options={{
                    pageSize: rowsPerPage,
                    search: false,
                    maxColumnSort: 0,
                    grouping: false,
                    pageSizeOptions: [10, 25, 50],
                }}
            />
            {id && (
                <SendFormEmailModal
                    isModalOpen={isSendFormEmailModalOpen}
                    handleClose={() => {
                        setIsSendFormEmailModalOpen(false);
                    }}
                    patientId={id}
                />
            )}
        </div>
    );
};

export default PatientVCExternalForms;
