import { yupResolver } from '@hookform/resolvers/yup';
import {
    Autocomplete,
    Button,
    Card,
    Checkbox,
    Divider,
    FormControlLabel,
    FormHelperText,
    Grid,
    MenuItem,
    TextField,
    Typography,
} from '@mui/material';
import { ArrowBack, Save as SaveIcon } from '@mui/icons-material';
import _ from 'lodash';
import React, { useEffect, useState } from 'react';
import { Controller, FieldError, SubmitHandler, useForm } from 'react-hook-form';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { AutocompleteWithRecordOptions } from '~/components/AutocompleteWithRecordOptions/AutocompleteWithRecordOptions';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import ReactHookFormSelect from '~/components/ReactHookFormSelect/ReactHookFormSelect';
import { PAGESIZE } from '~/constants';
import {
    ClockType,
    HighlightsForListPageDocument,
    useAppsTagsTimelinesForHighlightsPageQuery,
    useGetHighlightLazyQuery,
    WhatsNewTimelineType,
    HighlightType,
    ContentType,
    HighlightIcon,
    useSaveHighlightMutation,
    TimeUnit,
    UserProfileValueType,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import ImageContentPicker from '~/components/ImageContentPicker/ImageContentPicker';
import NonImageContentPicker from '~/components/NonImageContentPicker/NonImageContentPicker';
import { LocalizedStringTextFields } from '~/components/LocalizedStringTextFields/LocalizedStringTextFields';
import RichTextEditor, { RichTextEditorCommands } from '~/components/RichTextEditor/RichTextEditor';
import { toBase64 } from '~/helpers/base64Helper';
import { ClientSelectionType, OffsetType } from '~/enums/enums';
import DateEditor from '~/components/DateEditor/DateEditor';
import * as Yup from 'yup';
import { useStyles } from './styles';
import { HighlightFormInput } from './types/types';
import { toFormValuesFromHighlight, getDefaultFormValues } from './helpers/toFormValues';
import { toUpdateInput } from './helpers/toRequestInput';

const LOADING_APPS_TEXT = 'Loading apps...';
const LOADING_TAGS_TEXT = 'Loading tags...';
const Priorities = Array.from({ length: 6 }, (_, i) => i);

const HIGHLIGHT_PROMOTION_SCHEMA = Yup.object().shape({
    priority: Yup.number().required('Priority field is required'),
    tags: Yup.array().of(Yup.string()),
    clientSelectionType: Yup.mixed().oneOf(Object.values(ClientSelectionType)).required(),
    includeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            if (clientSelectionType === ClientSelectionType.IncludeCertainApps) {
                return schema.min(1, 'Must select at least one app to include').required();
            }
            return schema;
        }),
    excludeApps: Yup.array()
        .of(Yup.string())
        .when('clientSelectionType', ([clientSelectionType], schema) => {
            if (clientSelectionType === ClientSelectionType.ExcludeCertainApps) {
                return schema.min(1, 'Must select at least one app to exclude').required();
            }
            return schema;
        }),
    type: Yup.mixed().oneOf(Object.values(HighlightType)).required(),
    timelineId: Yup.string()
        .nullable()
        .when('clockType', ([clockType], schema) => {
            if (clockType === ClockType.Timeline) {
                return schema.required('Timeline is required');
            }
            return schema;
        }),
    displayAsModal: Yup.boolean(),
});

const toName = (icon: HighlightIcon): string => {
    switch (icon) {
        case HighlightIcon.AppUpdate:
            return 'App Update';
        case HighlightIcon.Appointment:
            return 'Appointment';
        case HighlightIcon.Message:
            return 'Message';
        case HighlightIcon.None:
            return 'None';
        case HighlightIcon.Personalize:
            return 'Personalize';
        case HighlightIcon.Reminder:
            return 'Reminder';
        case HighlightIcon.Resource:
            return 'Resource';
        case HighlightIcon.Survey:
            return 'Survey';
        case HighlightIcon.ToDo:
            return 'To Do';
        case HighlightIcon.WhoToCall:
            return 'Who To Call';
        default:
            return 'Unknown';
    }
};

const HighlightEditor: React.FC = () => {
    const { classes } = useStyles();
    const history = useNavigate();
    const [close, setClose] = useState(false);
    const [profileLink, setProfileLink] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState<HighlightIcon>(HighlightIcon.None);
    const [selectedImageId, setSelectedImageId] = useState<string>();
    const [selectedContentId, setSelectedContentId] = useState<string>();
    const [selectedProfileDefId, setSelectedProfileDefId] = useState<string>();
    const [selectedSurveyId, setSelectedSurveyId] = useState<string>();
    const [bodyEditorCommandsEn, setBodyEditorCommandsEn] = useState<RichTextEditorCommands | null>(
        null,
    );
    const [bodyEditorCommandsEs, setBodyEditorCommandsEs] = useState<RichTextEditorCommands | null>(
        null,
    );
    const [titleEn, setTitleEn] = useState<string>();
    const [titleEs, setTitleEs] = useState<string>();
    const [headerEn, setHeaderEn] = useState<string>();
    const [headerEs, setHeaderEs] = useState<string>();
    const [bodyEn, setBodyEn] = useState<string>();
    const [bodyEs, setBodyEs] = useState<string>();
    const { id: highlightId } = useParams<{ id: string }>();
    const isEditMode = highlightId !== 'new';
    const [selectedMessageTemplate, setSelectedMessageTemplate] = useState<{
        id: string;
        name: string;
    } | null>(null);

    const useFormMethods = useForm<HighlightFormInput>({
        resolver: yupResolver(HIGHLIGHT_PROMOTION_SCHEMA as any),
        defaultValues: getDefaultFormValues(),
    });

    const {
        control,
        register,
        handleSubmit: handleFormSubmit,
        formState: { errors },
        watch,
        reset,
        setValue,
        setError,
    } = useFormMethods;

    const { data: preFetchData, loading: preFetchLoading } =
        useAppsTagsTimelinesForHighlightsPageQuery();
    const [getHighlight, { loading: highlightLoading }] = useGetHighlightLazyQuery({
        onCompleted: ({ highlight }) => {
            if (highlight) {
                const formValues = toFormValuesFromHighlight(highlight);
                reset({ ...formValues });
                setSelectedIcon(highlight.icon);
                setSelectedSurveyId(highlight.surveyId);
                setSelectedContentId(highlight.simpleContentId);
                setSelectedProfileDefId(highlight.profileDefId);
                setHeaderEn(highlight.headerMarkup?.en || undefined);
                setHeaderEs(highlight.headerMarkup?.es || undefined);
                setTitleEn(highlight.titleMarkup?.en || undefined);
                setTitleEs(highlight.titleMarkup?.es || undefined);
                setBodyEn(highlight.bodyMarkup?.en || undefined);
                setBodyEs(highlight.bodyMarkup?.es || undefined);
                setSelectedImageId(highlight?.imageContentId);
                bodyEditorCommandsEn?.commands.setContent(highlight.bodyMarkup?.en || '');
                bodyEditorCommandsEs?.commands.setContent(highlight.bodyMarkup?.es || '');
                setProfileLink(highlight.profileDefId !== null);
                const messageTemplates = preFetchData?.messageTemplatesV2 ?? null;
                const highlightMessageTemplate = messageTemplates?.results.find(
                    mt => mt.id === highlight.messageTemplateId,
                );
                if (highlightMessageTemplate) {
                    setSelectedMessageTemplate(highlightMessageTemplate);
                }
            }
        },
    });

    const [saveHighlight, { loading: saveHighlightLoading }] = useSaveHighlightMutation({
        onCompleted: data => {
            if (close) {
                history('/app-config/highlights/');
            } else {
                history(`/app-config/highlights/${data.saveHighlight?.resource?.id ?? 'new'}`);
            }
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    setClose(false);
                },
                message: `There was a problem saving the highlight: ${error.message}`,
            });
        },
        awaitRefetchQueries: true,
        refetchQueries: [
            {
                query: HighlightsForListPageDocument,
                variables: {
                    input: {
                        pagination: {
                            skip: 0,
                            limit: PAGESIZE,
                        },
                        filter: null,
                    },
                },
            },
        ],
    });

    useEffect(() => {
        if (isEditMode) {
            getHighlight({ variables: { input: { id: highlightId } } });
        } else {
            bodyEditorCommandsEn?.commands.clearContent();
            bodyEditorCommandsEs?.commands.clearContent();
        }
    }, [
        highlightId,
        getHighlight,
        isEditMode,
        bodyEditorCommandsEn?.commands,
        bodyEditorCommandsEs?.commands,
    ]);

    const clientSelectionType = watch('clientSelectionType');
    const clockType = watch('clockType');
    const displayAsModal = watch('displayAsModal');
    const highlightType = watch('type');
    const sendNotification = watch('shouldSendNotification');
    const useMessageTemplate = watch('useMessageTemplate');

    const handleSubmit: SubmitHandler<Partial<HighlightFormInput>> = form => {
        if (highlightType === HighlightType.AppLink) {
            if (!profileLink && !selectedContentId) {
                setError('simpleContentId', {
                    type: 'custom',
                    message: 'An App Link selection is required',
                });
                return;
            }
            if (profileLink) {
                let error = false;
                if (!selectedProfileDefId) {
                    setError('profileDefId', {
                        type: 'custom',
                        message: 'A Profile Definition is required',
                    });
                    error = true;
                }
                if (!form.linkText?.en) {
                    setError('linkText', {
                        type: 'custom',
                        message: 'Link text is required',
                    });
                    error = true;
                }
                if (error) {
                    return;
                }
            }
        }
        saveHighlight({
            variables: {
                input: {
                    id: isEditMode ? highlightId : undefined,
                    data: {
                        ...toUpdateInput(form),
                        icon: selectedIcon,
                        surveyId: selectedSurveyId,
                        imageContentId: selectedImageId?.length !== 0 ? selectedImageId : undefined,
                        simpleContentId: profileLink ? undefined : selectedContentId,
                        profileDefId: profileLink ? selectedProfileDefId : undefined,
                        titleMarkup: { en: toBase64(titleEn), es: toBase64(titleEs) },
                        headerMarkup: { en: toBase64(headerEn), es: toBase64(headerEs) },
                        bodyMarkup: { en: toBase64(bodyEn), es: toBase64(bodyEs) },
                    },
                },
            },
        });
    };
    const linkTypeChange = (useProfile: boolean) => {
        setProfileLink(useProfile);
        if (useProfile) {
            setSelectedContentId(undefined);
        } else {
            setSelectedProfileDefId(undefined);
        }
    };
    if (preFetchLoading || saveHighlightLoading || highlightLoading) {
        return <Loading />;
    }
    return (
        <Grid container>
            <Grid item xs={12}>
                <Button component={Link} to="/app-config/highlights/" startIcon={<ArrowBack />}>
                    Back to Highlights List
                </Button>
            </Grid>
            <Grid item xs={12}>
                <Card>
                    <Typography variant="h6" paragraph>
                        {isEditMode ? 'Edit' : 'Create'} Highlight
                    </Typography>
                    <Divider className={classes.divider} />
                    <form onSubmit={handleFormSubmit(handleSubmit)}>
                        <Grid item>
                            <OutlinedSection title="Name *">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    defaultValue=""
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                />
                            </OutlinedSection>
                        </Grid>
                        <Grid container item xs={12}>
                            <ReactHookFormSelect
                                control={control}
                                defaultValue=""
                                name="type"
                                variant="outlined"
                                label="Highlight Type"
                                fullWidth
                                margin="dense"
                                disabled={isEditMode}
                                error={!!errors.type}
                            >
                                <MenuItem
                                    key={HighlightType.AppLink}
                                    value={HighlightType.AppLink}
                                    data-test={HighlightType.AppLink}
                                >
                                    Application Link
                                </MenuItem>
                                <MenuItem
                                    key={HighlightType.QuestionCard}
                                    value={HighlightType.QuestionCard}
                                    data-test={HighlightType.QuestionCard}
                                >
                                    Question Card
                                </MenuItem>
                                <MenuItem
                                    key={HighlightType.Alert}
                                    value={HighlightType.Alert}
                                    data-test={HighlightType.Alert}
                                >
                                    Alert
                                </MenuItem>
                                <MenuItem
                                    key={HighlightType.PushNotification}
                                    value={HighlightType.PushNotification}
                                    data-test={HighlightType.PushNotification}
                                >
                                    Push Notification
                                </MenuItem>
                            </ReactHookFormSelect>
                        </Grid>

                        <Grid container item xs={12}>
                            <OutlinedSection title="App">
                                <ReactHookFormSelect
                                    control={control}
                                    name="clientSelectionType"
                                    variant="outlined"
                                    defaultValue=""
                                    label="Selection type *"
                                    fullWidth
                                    margin="dense"
                                    error={Boolean(errors.clientSelectionType)}
                                >
                                    {Object.values(ClientSelectionType).map(m => (
                                        <MenuItem key={m} value={m} data-test={m}>
                                            {m}
                                        </MenuItem>
                                    ))}
                                </ReactHookFormSelect>
                                {errors?.clientSelectionType && (
                                    <FormHelperText error className={classes.helperMessage}>
                                        {errors?.clientSelectionType?.message}
                                    </FormHelperText>
                                )}
                                {clientSelectionType === ClientSelectionType.IncludeCertainApps && (
                                    <AutocompleteWithRecordOptions
                                        options={
                                            _.sortBy(
                                                preFetchData?.applicationsV2.results,
                                                'appBundleId',
                                            ) ?? []
                                        }
                                        valueKey="appBundleId"
                                        labelKey="appBundleId"
                                        control={control}
                                        name="includeApps"
                                        label="Include apps *"
                                        placeholder="Select apps to include..."
                                        required
                                        loading={!preFetchData?.applicationsV2.results}
                                        loadingText={LOADING_APPS_TEXT}
                                        error={Boolean(errors.includeApps)}
                                        helperText={
                                            (errors.includeApps as FieldError | undefined)?.message
                                        }
                                    />
                                )}
                                {clientSelectionType === ClientSelectionType.ExcludeCertainApps && (
                                    <AutocompleteWithRecordOptions
                                        options={
                                            _.sortBy(
                                                preFetchData?.applicationsV2.results,
                                                'appBundleId',
                                            ) ?? []
                                        }
                                        valueKey="appBundleId"
                                        labelKey="appBundleId"
                                        control={control}
                                        name="excludeApps"
                                        label="Exclude apps *"
                                        placeholder="Select apps to exclude..."
                                        required
                                        loading={!preFetchData?.applicationsV2.results}
                                        loadingText={LOADING_APPS_TEXT}
                                        error={Boolean(errors.excludeApps)}
                                        helperText={
                                            (errors.excludeApps as FieldError | undefined)?.message
                                        }
                                    />
                                )}
                            </OutlinedSection>
                        </Grid>
                        {preFetchData?.tags && (
                            <AutocompleteWithRecordOptions
                                options={preFetchData.tags ?? []}
                                valueKey="id"
                                labelKey="name"
                                control={control}
                                name="tagIds"
                                label="Tags"
                                placeholder="Select tags ..."
                                loading={!preFetchData?.tags}
                                loadingText={LOADING_TAGS_TEXT}
                                error={Boolean(errors.tagIds)}
                            />
                        )}
                        <Grid container item xs={12}>
                            <ReactHookFormSelect
                                control={control}
                                defaultValue=""
                                name="clockType"
                                variant="outlined"
                                label="Clock Type"
                                fullWidth
                                margin="dense"
                                disabled={isEditMode}
                                error={!!errors.type}
                            >
                                <MenuItem
                                    key={ClockType.FixedDate}
                                    value={ClockType.FixedDate}
                                    data-test={ClockType.FixedDate}
                                >
                                    Fixed Date
                                </MenuItem>
                                <MenuItem
                                    key={ClockType.Timeline}
                                    value={ClockType.Timeline}
                                    data-test={ClockType.Timeline}
                                >
                                    Timeline
                                </MenuItem>
                            </ReactHookFormSelect>
                        </Grid>
                        {clockType === ClockType.Timeline && (
                            <Grid>
                                <OutlinedSection title="Timeline">
                                    <Grid item xs={12}>
                                        <ReactHookFormSelect
                                            control={control}
                                            defaultValue=""
                                            name="timelineId"
                                            variant="outlined"
                                            label="Select Timeline"
                                            fullWidth
                                            margin="dense"
                                            disabled={isEditMode}
                                            error={!!errors.timelineId}
                                        >
                                            {_.sortBy(
                                                preFetchData?.whatsNewTimelines?.filter(
                                                    t => t.type === WhatsNewTimelineType.Profile,
                                                ),
                                                'label.en',
                                            ).map(({ id, label }) => (
                                                <MenuItem key={id} value={id}>
                                                    {label?.en}
                                                </MenuItem>
                                            ))}
                                        </ReactHookFormSelect>
                                        {errors?.timelineId && (
                                            <FormHelperText error className={classes.helperMessage}>
                                                {errors?.timelineId?.message}
                                            </FormHelperText>
                                        )}
                                        <Grid item xs={12}>
                                            <span className={classes.sectionLabel}>
                                                Available From
                                            </span>
                                            <div className={classes.offsetWrap}>
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    label="Days *"
                                                    margin="dense"
                                                    {...register('offsetDays')}
                                                    InputProps={{
                                                        inputProps: { min: 0, step: 1 },
                                                    }}
                                                    className={classes.offsetDate}
                                                    defaultValue={0}
                                                    error={Boolean(errors.offsetDays)}
                                                    helperText={errors.offsetDays?.message}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    label="Months *"
                                                    margin="dense"
                                                    {...register('offsetMonths')}
                                                    InputProps={{
                                                        inputProps: { min: 0, step: 1 },
                                                    }}
                                                    className={classes.offsetDate}
                                                    defaultValue={0}
                                                    error={Boolean(errors.offsetMonths)}
                                                    helperText={errors.offsetMonths?.message}
                                                />
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    label="Years *"
                                                    margin="dense"
                                                    {...register('offsetYears')}
                                                    InputProps={{
                                                        inputProps: { min: 0, step: 1 },
                                                    }}
                                                    className={classes.offsetDate}
                                                    defaultValue={0}
                                                    error={Boolean(errors.offsetYears)}
                                                    helperText={errors.offsetYears?.message}
                                                />
                                                <ReactHookFormSelect
                                                    className={classes.timeDropdown}
                                                    control={control}
                                                    name="offsetType"
                                                    variant="outlined"
                                                    label="Offset Type"
                                                    margin="dense"
                                                    defaultValue="Before"
                                                    error={!!errors.offsetType}
                                                >
                                                    {Object.values(OffsetType).map(u => (
                                                        <MenuItem key={u} value={u}>
                                                            {u}
                                                        </MenuItem>
                                                    ))}
                                                </ReactHookFormSelect>
                                                {errors?.offsetType && (
                                                    <FormHelperText
                                                        error
                                                        className={classes.helperMessage}
                                                    >
                                                        {errors?.offsetType?.message}
                                                    </FormHelperText>
                                                )}
                                            </div>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <span className={classes.sectionLabel}>Show For</span>
                                            <div className={classes.offsetWrap}>
                                                <TextField
                                                    variant="outlined"
                                                    type="number"
                                                    margin="dense"
                                                    InputProps={{
                                                        inputProps: { min: 1, step: 1 },
                                                    }}
                                                    {...register('duration')}
                                                    className={classes.offsetDate}
                                                    defaultValue={1}
                                                    error={Boolean(errors.duration)}
                                                    helperText={errors.duration?.message}
                                                />
                                                <ReactHookFormSelect
                                                    className={classes.timeDropdown}
                                                    control={control}
                                                    name="durationTimeUnit"
                                                    variant="outlined"
                                                    label="Time Unit"
                                                    margin="dense"
                                                    defaultValue="Days"
                                                    error={!!errors.durationTimeUnit}
                                                >
                                                    {Object.values(TimeUnit).map(u => (
                                                        <MenuItem key={u} value={u}>
                                                            {u}
                                                        </MenuItem>
                                                    ))}
                                                </ReactHookFormSelect>
                                                {errors?.durationTimeUnit && (
                                                    <FormHelperText
                                                        error
                                                        className={classes.helperMessage}
                                                    >
                                                        {errors?.durationTimeUnit?.message}
                                                    </FormHelperText>
                                                )}
                                            </div>
                                        </Grid>
                                    </Grid>
                                </OutlinedSection>
                            </Grid>
                        )}
                        {clockType === ClockType.FixedDate && (
                            <Grid item>
                                <OutlinedSection title="Fixed Date">
                                    <Controller
                                        control={control}
                                        name="startDate"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DateEditor
                                                    title="Start Date"
                                                    setSelectedDate={d => onChange(d)}
                                                    initialDate={value || new Date().toISOString()}
                                                />
                                            );
                                        }}
                                    />
                                    {errors?.startDate && (
                                        <FormHelperText error className={classes.helperMessage}>
                                            {errors?.startDate?.message}
                                        </FormHelperText>
                                    )}
                                    <Controller
                                        control={control}
                                        name="endDate"
                                        render={({ field: { onChange, value } }) => {
                                            return (
                                                <DateEditor
                                                    title="End Date"
                                                    setSelectedDate={d => onChange(d)}
                                                    initialDate={value || new Date().toISOString()}
                                                />
                                            );
                                        }}
                                    />
                                    {errors?.endDate && (
                                        <FormHelperText error className={classes.helperMessage}>
                                            {errors?.endDate?.message}
                                        </FormHelperText>
                                    )}
                                </OutlinedSection>
                            </Grid>
                        )}
                        <Grid item>
                            <ReactHookFormSelect
                                control={control}
                                name="priority"
                                variant="outlined"
                                label="Priority"
                                margin="dense"
                                defaultValue=""
                            >
                                {Priorities.map(i => (
                                    <MenuItem key={i} value={i}>
                                        {i}
                                    </MenuItem>
                                ))}
                            </ReactHookFormSelect>
                            <span style={{ padding: '15px' }}>
                                Icon:{' '}
                                <select
                                    value={selectedIcon}
                                    onChange={e => {
                                        setSelectedIcon(e.target.value as HighlightIcon);
                                    }}
                                >
                                    {Object.values(HighlightIcon).map(v => (
                                        <option key={v} value={v}>
                                            {toName(v)}
                                        </option>
                                    ))}
                                </select>
                            </span>
                            <Controller
                                name="closeable"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        label="Can be Closed?"
                                        labelPlacement="start"
                                        control={
                                            <Checkbox
                                                id="closeable"
                                                checked={value === true}
                                                onChange={e => {
                                                    onChange(e.target.checked);
                                                }}
                                            />
                                        }
                                    />
                                )}
                            />
                            <Controller
                                name="highlighted"
                                control={control}
                                render={({ field: { onChange, value } }) => (
                                    <FormControlLabel
                                        label="Highlight?"
                                        labelPlacement="start"
                                        disabled={Boolean(displayAsModal)}
                                        control={
                                            <Checkbox
                                                id="highlighted"
                                                checked={value === true}
                                                onChange={e => {
                                                    onChange(e.target.checked);
                                                }}
                                            />
                                        }
                                    />
                                )}
                            />
                        </Grid>
                        {highlightType !== HighlightType.PushNotification && (
                            <>
                                <Grid item>
                                    <OutlinedSection title="Title Markup">
                                        <Grid container spacing={2} className={classes.editorsWrap}>
                                            <Grid item xs={6}>
                                                <RichTextEditor
                                                    onChange={value => {
                                                        setTitleEn(value);
                                                    }}
                                                    placeholder="English"
                                                    label="English"
                                                    initialValue={titleEn}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <RichTextEditor
                                                    onChange={value => {
                                                        setTitleEs(value);
                                                    }}
                                                    placeholder="Spanish"
                                                    label="Spanish"
                                                    initialValue={titleEs}
                                                />
                                            </Grid>
                                        </Grid>
                                    </OutlinedSection>
                                </Grid>
                                <Grid item>
                                    <OutlinedSection title="Header Markup">
                                        <Grid container spacing={2} className={classes.editorsWrap}>
                                            <Grid item xs={6}>
                                                <RichTextEditor
                                                    onChange={value => {
                                                        setHeaderEn(value);
                                                    }}
                                                    placeholder="English"
                                                    label="English"
                                                    initialValue={headerEn}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <RichTextEditor
                                                    onChange={value => {
                                                        setHeaderEs(value);
                                                    }}
                                                    placeholder="Spanish"
                                                    label="Spanish"
                                                    initialValue={headerEs}
                                                />
                                            </Grid>
                                        </Grid>
                                    </OutlinedSection>
                                </Grid>
                                <Grid item>
                                    <OutlinedSection title="Body Markup">
                                        <Grid container spacing={2} className={classes.editorsWrap}>
                                            <Grid item xs={6}>
                                                <RichTextEditor
                                                    onInit={setBodyEditorCommandsEn}
                                                    onChange={value => {
                                                        setBodyEn(value);
                                                    }}
                                                    placeholder="English"
                                                    label="English"
                                                    initialValue={bodyEn}
                                                />
                                            </Grid>
                                            <Grid item xs={6}>
                                                <RichTextEditor
                                                    onInit={setBodyEditorCommandsEs}
                                                    onChange={value => {
                                                        setBodyEs(value);
                                                    }}
                                                    placeholder="Spanish"
                                                    label="Spanish"
                                                    initialValue={bodyEs}
                                                />
                                            </Grid>
                                        </Grid>
                                    </OutlinedSection>
                                </Grid>
                            </>
                        )}
                        <ImageContentPicker
                            selectedImageContentId={selectedImageId}
                            setSelectedImageContentId={setSelectedImageId}
                            onEdit={() => {
                                // no op
                            }}
                        />
                        {highlightType === HighlightType.QuestionCard && (
                            <OutlinedSection title="Select Survey">
                                {preFetchData?.surveyDefs && (
                                    <div>
                                        <select
                                            value={selectedSurveyId || ''}
                                            onChange={e => setSelectedSurveyId(e.target.value)}
                                        >
                                            {_.sortBy(preFetchData?.surveyDefs, 'name').map(a => (
                                                <option
                                                    key={a.id}
                                                    value={a.id}
                                                >{`${a.name}`}</option>
                                            ))}
                                        </select>
                                    </div>
                                )}
                            </OutlinedSection>
                        )}
                        {(highlightType === HighlightType.AppLink ||
                            highlightType === HighlightType.PushNotification) && (
                            <div>
                                Link type:
                                <select
                                    value={profileLink ? '1' : '0'}
                                    onChange={e => linkTypeChange(e.target.value === '1')}
                                    disabled={isEditMode}
                                >
                                    <option value="0">Simple Content Link</option>
                                    <option value="1">From Profile Value</option>
                                </select>
                            </div>
                        )}
                        {(highlightType === HighlightType.AppLink ||
                            highlightType === HighlightType.PushNotification) &&
                            profileLink && (
                                <OutlinedSection title="Profile Definition Link">
                                    <div>
                                        Select Profile Definition:
                                        <select
                                            value={selectedProfileDefId}
                                            onChange={e => setSelectedProfileDefId(e.target.value)}
                                        >
                                            <option value="">Select a Profile Definition</option>
                                            {_.sortBy(
                                                preFetchData?.userProfileDefs.filter(
                                                    p => p.valueType === UserProfileValueType.Str,
                                                ),
                                                'name',
                                            ).map(p => (
                                                <option value={p.id}>{p.name}</option>
                                            ))}
                                        </select>
                                        <div style={{ color: 'red' }}>
                                            {errors.profileDefId?.message}
                                        </div>
                                    </div>
                                    <div>
                                        <OutlinedSection title="Link Text*">
                                            <TextField
                                                variant="outlined"
                                                label="English"
                                                type="text"
                                                fullWidth
                                                margin="dense"
                                                defaultValue=""
                                                {...register('linkText.en')}
                                                error={!!errors.linkText}
                                                helperText={errors.linkText?.message}
                                            />
                                            <TextField
                                                variant="outlined"
                                                label="Spanish"
                                                type="text"
                                                fullWidth
                                                margin="dense"
                                                defaultValue=""
                                                {...register('linkText.es')}
                                            />
                                        </OutlinedSection>
                                    </div>
                                </OutlinedSection>
                            )}
                        {(highlightType === HighlightType.AppLink ||
                            highlightType === HighlightType.PushNotification) &&
                            !profileLink && (
                                <div>
                                    <NonImageContentPicker
                                        titleText="Select App Link"
                                        selectedContentId={selectedContentId}
                                        setSelectedContentId={setSelectedContentId}
                                        allowedTypes={[
                                            ContentType.BabyBoostLink,
                                            ContentType.AppointmentHistory,
                                            ContentType.CareTeam,
                                            ContentType.MessageCenter,
                                            ContentType.QListLink,
                                            ContentType.Milestones,
                                            ContentType.WeekByWeek,
                                            ContentType.WhoToCall,
                                            ContentType.LearnLibrary,
                                            ContentType.Surveys,
                                            ContentType.Todolist,
                                            ContentType.DueDateCalculator,
                                            ContentType.Resources,
                                            ContentType.ExploreScreen,
                                            ContentType.TrackerView,
                                            ContentType.ArticleLink,
                                            ContentType.ExternalLink,
                                            ContentType.SurveyLink,
                                            ContentType.ContentDirectoryLink,
                                            ContentType.AddDependentProfile,
                                            ContentType.UserBio,
                                            ContentType.SurveyInBio,
                                            ContentType.EligibilityCheckLink,
                                        ]}
                                        required={false}
                                    />
                                    {errors?.simpleContentId && (
                                        <FormHelperText error className={classes.helperMessage}>
                                            {errors?.simpleContentId?.message}
                                        </FormHelperText>
                                    )}
                                    <OutlinedSection title="Link Text Override">
                                        <TextField
                                            variant="outlined"
                                            label="English"
                                            type="text"
                                            fullWidth
                                            margin="dense"
                                            defaultValue=""
                                            {...register('linkText.en')}
                                        />
                                        <TextField
                                            variant="outlined"
                                            label="Spanish"
                                            type="text"
                                            fullWidth
                                            margin="dense"
                                            defaultValue=""
                                            {...register('linkText.es')}
                                        />
                                    </OutlinedSection>
                                </div>
                            )}
                        {(clockType === ClockType.Timeline ||
                            highlightType === HighlightType.PushNotification) && (
                            <OutlinedSection title="Notification">
                                <Grid item xs={12}>
                                    <Controller
                                        name="shouldSendNotification"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControlLabel
                                                label="Send Notification"
                                                control={
                                                    <Checkbox
                                                        checked={value}
                                                        onChange={e => onChange(e.target.checked)}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                    {sendNotification && (
                                        <LocalizedStringTextFields
                                            name="notificationText"
                                            label="Text"
                                            register={register}
                                            required={{ en: true, es: false }}
                                        />
                                    )}
                                </Grid>
                                {highlightType === HighlightType.PushNotification &&
                                    sendNotification && (
                                        <Grid item xs={12}>
                                            <Controller
                                                name="useMessageTemplate"
                                                control={control}
                                                render={({ field: { value, onChange } }) => (
                                                    <FormControlLabel
                                                        label="Use Message Template"
                                                        control={
                                                            <Checkbox
                                                                checked={value ?? false}
                                                                onChange={e =>
                                                                    onChange(e.target.checked)
                                                                }
                                                            />
                                                        }
                                                    />
                                                )}
                                            />
                                            {useMessageTemplate && (
                                                <Controller
                                                    name="messageTemplateId"
                                                    control={control}
                                                    render={({ field: { onChange } }) => (
                                                        <Autocomplete
                                                            options={
                                                                preFetchData?.messageTemplatesV2
                                                                    .results ?? []
                                                            }
                                                            getOptionLabel={option => option.name}
                                                            onChange={(_, newValue) => {
                                                                onChange(newValue?.id);
                                                                setSelectedMessageTemplate(
                                                                    newValue,
                                                                );
                                                            }}
                                                            value={selectedMessageTemplate}
                                                            renderInput={params => (
                                                                <TextField
                                                                    {...params}
                                                                    label="Select Message Template"
                                                                    variant="outlined"
                                                                    margin="dense"
                                                                    error={Boolean(
                                                                        errors.messageTemplateId,
                                                                    )}
                                                                    helperText={
                                                                        errors.messageTemplateId
                                                                            ?.message
                                                                    }
                                                                />
                                                            )}
                                                        />
                                                    )}
                                                />
                                            )}
                                        </Grid>
                                    )}
                            </OutlinedSection>
                        )}
                        {highlightType !== HighlightType.PushNotification && (
                            <OutlinedSection title="Take an Action Modal">
                                <Grid item xs={12}>
                                    <Controller
                                        name="displayAsModal"
                                        control={control}
                                        render={({ field: { value, onChange } }) => (
                                            <FormControlLabel
                                                label="Display as modal window"
                                                control={
                                                    <Checkbox
                                                        checked={value}
                                                        onChange={e => {
                                                            onChange(e.target.checked);
                                                            setValue('highlighted', false);
                                                        }}
                                                    />
                                                }
                                            />
                                        )}
                                    />
                                    <LocalizedStringTextFields
                                        name="displayAsModalButtonText"
                                        label="Modal window button label"
                                        register={register}
                                        required={{ en: true, es: false }}
                                    />
                                </Grid>
                            </OutlinedSection>
                        )}

                        <Grid
                            container
                            item
                            spacing={2}
                            justifyContent="flex-end"
                            direction="row"
                            className={classes.actionButtons}
                            alignItems="flex-end"
                        >
                            <Button
                                onClick={handleFormSubmit(handleSubmit)}
                                startIcon={<SaveIcon />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                            >
                                Save
                            </Button>
                            <Button
                                onClick={() => {
                                    setClose(true);
                                    handleFormSubmit(handleSubmit);
                                }}
                                startIcon={<SaveIcon />}
                                type="submit"
                                color="secondary"
                                variant="contained"
                            >
                                Save &amp; Close
                            </Button>
                        </Grid>
                    </form>
                </Card>
            </Grid>
        </Grid>
    );
};

export default HighlightEditor;
