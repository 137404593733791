import {
    Button,
    Checkbox,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControlLabel,
    FormGroup,
    Grid,
} from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { makeStyles } from 'tss-react/mui';
import moment from 'moment';
import React, { Dispatch, SetStateAction, useState, useCallback, useEffect } from 'react';
import { useUser } from '~/hooks';
import { AlertSeverity, FetchPatientForPatientOverviewPageQuery } from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import { getPatientSummaryWorkbook } from './PatientSummaryExcelWorkbook';

const useStyles = makeStyles()({
    root: {},
    buttonWrap: {
        paddingLeft: '5px',
        paddingBottom: '5px',
    },
    checkboxWrap: {
        paddingLeft: '10px',
    },
});

type PatientModalProps = {
    setOpen: Dispatch<SetStateAction<boolean>>;
    patient: FetchPatientForPatientOverviewPageQuery['patient'];
};

const PatientModal: React.FC<PatientModalProps> = ({ setOpen, patient }) => {
    const { classes } = useStyles();
    const { data: userData } = useUser();
    const [notesTab, setNotesTab] = useState(true);
    const [measurementsTab, setMeasurementsTab] = useState(true);
    const [surveysTab, setSurveysTab] = useState(true);
    const [useDateRange, setUseDateRange] = useState(false);
    const [startDate, setStartDate] = useState<Date | null>(null);
    const [endDate, setEndDate] = useState<Date | null>(null);
    const [dateRangeError, setDateRangeError] = useState<boolean>(false);

    const setAllTabs = useCallback((flag: boolean) => {
        setNotesTab(flag);
        setMeasurementsTab(flag);
        setSurveysTab(flag);
    }, []);

    const handleCancel = () => {
        setOpen(false);
    };
    const resetForm = useCallback(() => {
        setAllTabs(false);
        setUseDateRange(false);
        setStartDate(null);
        setEndDate(null);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleDownloadPatientSummary = () => {
        const downloadErrorMessage = 'Unable to download patient summary spreadsheet.';
        if (useDateRange && (!startDate || !endDate)) {
            setDateRangeError(true);
            return;
        }
        setDateRangeError(false);
        if (patient && userData?.currentUser) {
            getPatientSummaryWorkbook(
                patient,
                userData.currentUser,
                notesTab,
                measurementsTab,
                surveysTab,
                startDate ? moment(startDate).local().utc(true).startOf('day').toDate() : null,
                endDate ? moment(endDate).local().utc(true).endOf('day').toDate() : null,
            );
            resetForm();
            setOpen(false);
        } else if (!patient) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `${downloadErrorMessage} Patient is not defined.`,
            });
        } else if (!userData || !userData.currentUser) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: `${downloadErrorMessage} Current User is not defined.`,
            });
        } else {
            TriggerGlobalAlert({ severity: AlertSeverity.Error, message: downloadErrorMessage });
        }
    };
    useEffect(() => {
        if (!useDateRange) {
            setStartDate(null);
            setEndDate(null);
            setDateRangeError(false);
        }
    }, [useDateRange]);

    return (
        <div className={classes.root}>
            <DialogTitle id="form-dialog-title">Patient Summary Export</DialogTitle>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} className={classes.checkboxWrap}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={notesTab}
                                        onClick={() => setNotesTab(n => !n)}
                                    />
                                }
                                label="Notes"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={measurementsTab}
                                        onClick={() => setMeasurementsTab(m => !m)}
                                    />
                                }
                                label="Trackers"
                            />
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={surveysTab}
                                        onClick={() => setSurveysTab(s => !s)}
                                    />
                                }
                                label="Surveys"
                            />
                        </FormGroup>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item className={classes.buttonWrap}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={() => setAllTabs(true)}
                        >
                            Select All
                        </Button>
                    </Grid>
                    <Grid item className={classes.buttonWrap}>
                        <Button
                            variant="outlined"
                            color="secondary"
                            onClick={() => setAllTabs(false)}
                        >
                            Select None
                        </Button>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogContent>
                <Grid container>
                    <Grid item xs={12} className={classes.checkboxWrap}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        checked={useDateRange}
                                        onClick={() => setUseDateRange(s => !s)}
                                    />
                                }
                                label="Date Range"
                            />
                        </FormGroup>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6}>
                            <DesktopDatePicker
                                label="Start Date"
                                disabled={!useDateRange}
                                showDaysOutsideCurrentMonth
                                value={startDate}
                                disableFuture
                                onChange={(val: Date) => {
                                    setStartDate(val);
                                }}
                                maxDate={endDate}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        error: dateRangeError && !startDate,
                                    },
                                }}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <DesktopDatePicker
                                label="End Date"
                                disabled={!useDateRange}
                                showDaysOutsideCurrentMonth
                                value={endDate}
                                disableFuture
                                minDate={startDate}
                                onChange={(val: Date) => {
                                    setEndDate(val);
                                }}
                                slotProps={{
                                    textField: {
                                        size: 'small',
                                        error: dateRangeError && !endDate,
                                    },
                                }}
                            />
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                style={{
                    position: 'sticky',
                    bottom: 0,
                    backgroundColor: 'white',
                    zIndex: 1000,
                }}
            >
                <Button onClick={handleCancel} color="secondary" variant="outlined">
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    type="submit"
                    disabled={!(notesTab || measurementsTab || surveysTab)}
                    onClick={handleDownloadPatientSummary}
                >
                    Download Patient Summary
                </Button>
            </DialogActions>
        </div>
    );
};

export default PatientModal;
