import { atomWithStorage } from 'jotai/utils';
import { atom } from 'jotai';
import { EligibilityCheckResultFieldsOrder } from '~/views/Dashboard/EligibilityConfigs/EligibilityConfigs';
import { PAGESIZE } from '~/constants';
import { OrderByDirectionEnum } from '~/schemaTypes';

export type EligibilityConfigFilters = {
    name?: string;
    appBundleId?: string;
    unitIds?: string[];
    suspendEligibilityCheck?: boolean;
    suspendEligibilityImport?: boolean;
};

export const EligibilityConfigFiltersState = atomWithStorage<EligibilityConfigFilters>(
    'EligibilityConfigFilters',
    {},
);

export type EligibilityConfigs = {
    page: number;
    rowsPerPage: number;
    order: EligibilityCheckResultFieldsOrder;
};

export const initialOrder: EligibilityCheckResultFieldsOrder = Object.freeze({
    field: 'createdAt',
    direction: OrderByDirectionEnum.Desc,
    isInit: true,
});

export const EligibilityConfigsState = atom<EligibilityConfigs>({
    page: 0,
    rowsPerPage: PAGESIZE,
    order: { ...initialOrder },
});

type EligibilityConfigsListQueryVariables = {
    input: {
        filter: {
            fields: {
                name?: string;
                appBundleId?: string;
                suspendEligibilityCheck?: boolean;
                suspendEligibilityImport?: boolean;
            };
            fieldsInList: {
                unitId?: string[];
            };
        };
        orderBy: {
            field: string;
            order: OrderByDirectionEnum;
        };
        pagination: {
            limit: number;
            skip: number;
        };
    };
};

export const EligibilityConfigsListQueryVariablesState = atom<EligibilityConfigsListQueryVariables>(
    get => {
        const { page, rowsPerPage, order } = get(EligibilityConfigsState);
        const { name, appBundleId, suspendEligibilityCheck, suspendEligibilityImport, unitIds } =
            get(EligibilityConfigFiltersState);

        return {
            input: {
                filter: {
                    fields: {
                        name,
                        appBundleId,
                        suspendEligibilityCheck,
                        suspendEligibilityImport,
                    },
                    fieldsInList: {
                        unitId: unitIds,
                    },
                },
                orderBy: {
                    field: order.field,
                    order: order.direction,
                },
                pagination: {
                    limit: rowsPerPage,
                    skip: page * rowsPerPage,
                },
            },
        };
    },
);
