import { FilterFields } from './types';

export const PORTAL_FILTERS = [
    FilterFields.filteredTags,
    FilterFields.filteredAdvocate,
    FilterFields.filteredFirstName,
    FilterFields.filteredLastName,
    FilterFields.filteredExternalId,
    FilterFields.filteredBirthDate,
    FilterFields.filteredEmail,
    FilterFields.filteredPhoneNumber,
    FilterFields.filteredMemberId,
    FilterFields.filterTestData,
    FilterFields.filteredClinics,
    FilterFields.enableAdvocateFilter,
    FilterFields.filteredRiskScore,
    FilterFields.filteredLanguage,
];

export const AFFILIATE_FILTERS = [
    FilterFields.filteredFirstName,
    FilterFields.filteredLastName,
    FilterFields.filteredBirthDate,
    FilterFields.filteredEmail,
    FilterFields.filteredAffiliateId,
];

export const DOULA_FILTERS = [
    FilterFields.filteredFirstName,
    FilterFields.filteredLastName,
    FilterFields.filteredBirthDate,
    FilterFields.filteredEmail,
];

export const LC_FILTERS = [
    FilterFields.filteredFirstName,
    FilterFields.filteredLastName,
    FilterFields.filteredBirthDate,
    FilterFields.filteredEmail,
    FilterFields.filteredAffiliateId,
];
