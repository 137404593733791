import React, { useEffect, useState } from 'react';
import { faPenToSquare, faTrash, faAdd, faLink } from '@fortawesome/free-solid-svg-icons';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { Button, List, ListItem, ListItemIcon, ListItemText, Tooltip } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { SubstitutionItem } from '~/schemaTypes';
import { makeStyles } from 'tss-react/mui';
import RichTextEditorSubstTokenDialog from './RichTextEditorSubstTokenDialog';

export enum valueTypes {
    profileDef,
    configDef,
    systemToken,
}

const useStyles = makeStyles()({
    pointerCursor: {
        minWidth: 'auto',
        cursor: 'pointer',
        marginRight: '20px',
    },
    container: {
        paddingTop: '10px',
    },
});
type RichTextEditorSubstTokenSectionProps = {
    onSubstTokensChange: (substTokens: SubstitutionItem[]) => void;
    predefinedSubstTokens: SubstitutionItem[];
    onInsertToText: (alias: string) => void;
    onInsertAsLink: (alias: string, name: string) => void;
};

export const RichTextEditorSubstTokenSection: React.FC<RichTextEditorSubstTokenSectionProps> = ({
    onSubstTokensChange,
    predefinedSubstTokens,
    onInsertToText,
    onInsertAsLink,
}) => {
    const [sectionSubst, setSectionSubst] = useState<SubstitutionItem[]>([]);
    const { classes } = useStyles();
    const [showSubstModal, setShowSubstModal] = useState(false);
    const [isEditModeForModal, setIsEditModeForModal] = useState(false);
    const [initialFormValues, setInitialFormValues] = useState<SubstitutionItem | undefined>(
        undefined,
    );
    useEffect(() => {
        setSectionSubst(predefinedSubstTokens);
    }, [predefinedSubstTokens]);
    function deleteSubsTokenHandler(index: number) {
        const updatedNamedSubs = sectionSubst.filter(
            substToken => substToken.substitutionId !== index,
        );
        setSectionSubst(updatedNamedSubs);
        onSubstTokensChange(updatedNamedSubs);
    }
    const editSubsTokenModalHandler = (substToken: SubstitutionItem) => {
        setShowSubstModal(true);
        setIsEditModeForModal(true);
        setInitialFormValues(substToken);
    };
    const addSubsTokenModalHandler = () => {
        setShowSubstModal(true);
    };
    const closeTokenDialogHandler = () => {
        setShowSubstModal(false);
    };
    const insertTokenAsTextHandler = (substToken: SubstitutionItem) => {
        onInsertToText(`@@${substToken.substitutionId}@@`);
    };

    const insertTokenAsLinkHandler = (substToken: SubstitutionItem) => {
        onInsertAsLink(`@@${substToken.substitutionId}@@`, substToken.name);
    };

    const addSubstTokenHandler = (substToken: SubstitutionItem) => {
        const isAlreadyUsedId = sectionSubst.some(
            item => item.substitutionId === sectionSubst.length,
        );
        const substTokenComplete = {
            ...substToken,
            substitutionId: isAlreadyUsedId ? sectionSubst.length + 1 : sectionSubst.length,
        };
        const updatedNamedSubs = [...sectionSubst, substTokenComplete];
        setSectionSubst(updatedNamedSubs);
        onSubstTokensChange(updatedNamedSubs);
    };
    return (
        <div className={classes.container}>
            <OutlinedSection title="Substitution Tokens">
                <div>
                    <Button
                        color="secondary"
                        onClick={addSubsTokenModalHandler}
                        startIcon={<AddIcon />}
                    >
                        Add Token
                    </Button>
                </div>
                <List>
                    {sectionSubst.map((substToken, index) => (
                        <ListItem key={substToken.substitutionId || index}>
                            <Tooltip title="Delete">
                                <ListItemIcon
                                    className={classes.pointerCursor}
                                    onClick={() => {
                                        deleteSubsTokenHandler(substToken.substitutionId || index);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faTrash} />
                                </ListItemIcon>
                            </Tooltip>
                            <Tooltip title="Edit">
                                <ListItemIcon
                                    className={classes.pointerCursor}
                                    onClick={() => {
                                        editSubsTokenModalHandler(substToken);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                </ListItemIcon>
                            </Tooltip>
                            <Tooltip title="Insert into text">
                                <ListItemIcon
                                    className={classes.pointerCursor}
                                    onClick={() => {
                                        insertTokenAsTextHandler(substToken);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faAdd} />
                                </ListItemIcon>
                            </Tooltip>
                            <Tooltip title="Insert as link">
                                <ListItemIcon
                                    className={classes.pointerCursor}
                                    onClick={() => {
                                        insertTokenAsLinkHandler(substToken);
                                    }}
                                >
                                    <FontAwesomeIcon icon={faLink} />
                                </ListItemIcon>
                            </Tooltip>
                            <ListItemText
                                primary={`${substToken.name}, ${
                                    substToken.failIfMissing
                                        ? 'required'
                                        : `default: "${substToken.missingValueText}"`
                                }, alias: @@${substToken.substitutionId}@@`}
                            />
                        </ListItem>
                    ))}
                </List>
            </OutlinedSection>
            <RichTextEditorSubstTokenDialog
                isOpen={showSubstModal}
                onClose={closeTokenDialogHandler}
                onSubstTokenAdd={addSubstTokenHandler}
                isEditMode={isEditModeForModal}
                initialFormValues={initialFormValues}
            />
        </div>
    );
};
