import React from 'react';
import {
    OrderByDirectionEnum,
    useFetchOrganizationsV2ForEligibilityConfigViewQuery,
} from '~/schemaTypes';
import { Control, Controller } from 'react-hook-form';
import { Autocomplete, TextField } from '@mui/material';

type OrganizationSelectProps = {
    control: Control<any>;
    name: string;
    error: boolean;
    helperText?: string;
    disabled?: boolean;
};

const OrganizationSelect = ({
    control,
    name,
    error,
    helperText,
    disabled,
}: OrganizationSelectProps) => {
    const { data, loading } = useFetchOrganizationsV2ForEligibilityConfigViewQuery({
        variables: {
            input: {
                orderBy: {
                    field: 'name',
                    order: OrderByDirectionEnum.Asc,
                },
            },
        },
    });

    const items = data?.organizationsV2.results ?? [];

    return (
        <Controller
            control={control}
            name={name}
            render={({ field: { onChange, value, ref } }) => (
                <Autocomplete
                    options={items}
                    getOptionLabel={option => option.name ?? ''}
                    isOptionEqualToValue={(option, val) => option.id === val.id}
                    filterSelectedOptions
                    value={items.find(item => item.id === value) || null}
                    onChange={(_, v) => onChange(v ? v.id : null)}
                    renderInput={params => (
                        <TextField
                            {...params}
                            label="Organization"
                            placeholder="Select Organization"
                            inputRef={ref}
                            error={error}
                            helperText={helperText}
                            disabled={disabled}
                        />
                    )}
                    loading={loading}
                    loadingText="Loading..."
                    noOptionsText="No options"
                    disabled={disabled}
                />
            )}
        />
    );
};

export default OrganizationSelect;
