import { yupResolver } from '@hookform/resolvers/yup';
import { Close } from '@mui/icons-material';
import { Dialog, Grid, IconButton } from '@mui/material';
import moment from 'moment';
import React, { useEffect } from 'react';
import { FormProvider, useForm as useFormReact } from 'react-hook-form';
import Loading from '~/components/Loading/Loading';
import {
    AlertSeverity,
    EligibilityRefreshOrigin,
    useCheckEligibilityVirtualCareFormMutation,
    usePatientDataForEligibilityRecheckLazyQuery,
    useUpdatePatientInsurancePlanMutation,
} from '~/schemaTypes';
import { SuppressNextGlobalAlert, TriggerGlobalAlert, TriggerGlobalBanner } from '~/state';
import { useVCFContext } from '~/views/VirtualCareSurvey/hooks/useVCF';
import VirtualCareSurveyWrapper from './components/VirtualCareSurveyWrapper';
import {
    DOULA_ELIGIBILITY_DENIED_MSG,
    GENERAL_ELIGIBILITY_DENIED_MSG,
    OUT_OF_NETWORK_ELIGIBILITY_DENIED_MSG,
} from './constants';
import { preparePayload } from './helpers';
import PatientInfo from './steps/PatientInfo';
import useStyles from './styles';
import { VirtualCareSurveyInput } from './types';
import VirtualCareSurveySchemas from './yupSchema';

SuppressNextGlobalAlert(true);

type EligibilityRecheckModalProps = {
    isOpen: boolean;
    onClose: (recheckSuccess: boolean) => void;
    patientId: string;
    origin: EligibilityRefreshOrigin;
    affiliateId?: string;
};

const ELIGIBILITY_RECHECK_DESCRIPTION =
    'Verify the information below, then submit to refresh eligibility information.';

export const EligibilityRecheckModal: React.FC<EligibilityRecheckModalProps> = ({
    isOpen,
    onClose,
    patientId,
    origin,
    affiliateId,
}) => {
    const { classes } = useStyles();
    const [showLoading, setShowLoading] = React.useState<boolean>(true);

    const formProps = useFormReact<VirtualCareSurveyInput>({
        resolver: yupResolver(VirtualCareSurveySchemas(false, false)[0] as any),
    });
    const { handleSubmit, setValue, watch } = formProps;
    const [selfSelected, setSelfSelected] = React.useState<boolean>(true);
    const vcfProps = useVCFContext();
    const [requestPatientData, { data: patientData, loading: patientDataLoading }] =
        usePatientDataForEligibilityRecheckLazyQuery({
            fetchPolicy: 'no-cache',
            nextFetchPolicy: 'no-cache',
        });

    useEffect(() => {
        if (isOpen) {
            requestPatientData({
                variables: { input: { id: patientId } },
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isOpen]);

    const [firstName, lastName, sex, birthDate] = watch([
        'firstName',
        'lastName',
        'sex',
        'birthDate',
    ]);

    useEffect(() => {
        if (selfSelected) {
            setValue('insuredFirstName', firstName);
            setValue('insuredLastName', lastName);
            setValue('subscriberSex', sex);
            setValue('subscriberBirthDate', birthDate);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [firstName, lastName, sex, birthDate, selfSelected]);

    const [checkEligibility, { loading: checkEligibilityLoading }] =
        useCheckEligibilityVirtualCareFormMutation({
            onCompleted: data => {
                if (
                    !data.checkVCFEligibility?.isEligible &&
                    data.checkVCFEligibility?.message === 'Eligibility failed'
                ) {
                    TriggerGlobalBanner({
                        open: true,
                        severity: AlertSeverity.Error,
                        actionText: '',
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        callback: () => {},
                        message: vcfProps.isDoulaView
                            ? DOULA_ELIGIBILITY_DENIED_MSG
                            : GENERAL_ELIGIBILITY_DENIED_MSG,
                    });
                    return;
                }
                if (data.checkVCFEligibility?.message === 'Group Out of Network') {
                    TriggerGlobalBanner({
                        open: true,
                        severity: AlertSeverity.Error,
                        actionText: '',
                        // eslint-disable-next-line @typescript-eslint/no-empty-function
                        callback: () => {},
                        message: OUT_OF_NETWORK_ELIGIBILITY_DENIED_MSG,
                    });
                    return;
                }
                if (!data.checkVCFEligibility?.isEligible && data.checkVCFEligibility?.message) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Error,
                        message: data.checkVCFEligibility?.message,
                    });
                }
            },
        });

    const [updatePatientInsurancePlan, { loading: createVCFLoading }] =
        useUpdatePatientInsurancePlanMutation({
            onError: error => {
                setShowLoading(false);
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: error.message,
                });
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                'AffiliateCarePatient',
                'VirtualCareAffiliatePatients',
                'FetchPatientsForPatientsPageQuery',
            ],
        });

    const onSubmit = async (data: VirtualCareSurveyInput) => {
        const payload = preparePayload({
            formData: data,
            insuredAddressSameAsPatient: true,
            isAffiliateView: false,
            isDoulaView: vcfProps.isDoulaView,
            affiliateData: { id: affiliateId, selfCheck: false },
        });
        SuppressNextGlobalAlert(true);
        const result = await checkEligibility({
            variables: {
                input: { ...payload, eligibilityRefreshOrigin: origin },
            },
        });

        const insurancePlan = result.data?.checkVCFEligibility?.insurancePlan;
        if (!insurancePlan) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Failed to retrieve insurance plan data',
            });
            return;
        }

        updatePatientInsurancePlan({
            variables: {
                input: {
                    birthDate: payload.birthDate,
                    email: payload.email,
                    firstName: payload.firstName,
                    insurancePlan: {
                        name: insurancePlan.name,
                        type: insurancePlan.type,
                        eligibilityStartDate: insurancePlan.eligibilityStartDate,
                        eligibilityEndDate: insurancePlan.eligibilityEndDate,
                        groupNumber: insurancePlan.groupNumber,
                        groupName: insurancePlan.groupName,
                        allottedLactationVisits: insurancePlan.allottedLactationVisits,
                    },
                    lastName: payload.lastName,
                    practiceId: patientData?.patient?.practice.id,
                },
            },
        });
    };

    useEffect(() => {
        const prefillData = patientData?.patient;

        type SetValueParams = Parameters<typeof setValue>;
        const setValueIfExists = (name: SetValueParams[0], value?: SetValueParams[1] | null) =>
            value && setValue(name, value);

        if (prefillData) {
            setValueIfExists('firstName', prefillData.firstName);
            setValueIfExists('lastName', prefillData.lastName);
            setValue('birthDate', moment(new Date(prefillData.birthDate)).format('MM/DD/YYYY'));
            setValueIfExists('email', prefillData.email);
            setValueIfExists('sex', prefillData.gender);
            setValueIfExists('insuranceId', prefillData.insuranceId);
            setValueIfExists('insuranceGroup', prefillData.insuranceName);
            setValueIfExists('phoneNumber', prefillData.virtualCarePhoneNumber);

            const isSelfSelected = prefillData.patientRelationshipToInsured === 'self';
            setValueIfExists(
                'patientRelationshipToInsured',
                prefillData.patientRelationshipToInsured,
            );

            if (!isSelfSelected) {
                setValueIfExists('insuredFirstName', prefillData.insuredFirstName);
                setValueIfExists('insuredLastName', prefillData.insuredLastName);
                setValueIfExists(
                    'subscriberBirthDate',
                    moment(new Date(prefillData.subscriberBirthDate)).format('MM/DD/YYYY'),
                );
                setValueIfExists('subscriberSex', prefillData.subscriberSex);
            }

            setSelfSelected(isSelfSelected);
            setShowLoading(false);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [patientData]);

    return (
        <Dialog
            scroll="paper"
            onClose={(_, reason) => reason !== 'backdropClick' && onClose(false)}
            open={isOpen}
            fullWidth
            maxWidth="lg"
        >
            <IconButton className={classes.closeButton} onClick={() => onClose(false)}>
                <Close />
            </IconButton>
            {showLoading || patientDataLoading ? (
                <Loading />
            ) : (
                <VirtualCareSurveyWrapper
                    formDescriptionText={ELIGIBILITY_RECHECK_DESCRIPTION}
                    showFormDescription
                >
                    <Grid item xs={12}>
                        <form className={classes.fullHeight}>
                            <Grid
                                className={classes.fullHeight}
                                alignItems="flex-start"
                                container
                                rowSpacing={{ md: 1, xs: 0 }}
                            >
                                <FormProvider {...formProps}>
                                    <PatientInfo
                                        stepHandler={handleSubmit(onSubmit)}
                                        setSelfSelected={setSelfSelected}
                                        stepLoading={checkEligibilityLoading || createVCFLoading}
                                        selfSelected={selfSelected}
                                        selfCheck={false}
                                        isEligibilityRecheck
                                    />
                                </FormProvider>
                            </Grid>
                        </form>
                    </Grid>
                </VirtualCareSurveyWrapper>
            )}
        </Dialog>
    );
};
