import { addDays } from 'date-fns';
import { AdvocateTaskTypeStatus } from '~/schemaTypes';
import { AdvocateTaskFormInput } from '../types';

export const getDefaultFormValues = (): AdvocateTaskFormInput => {
    const defaultDisplayDate = new Date();
    const defaultDueDate = addDays(defaultDisplayDate, 3);

    return {
        label: '',
        description: '',
        checklist: [],
        priority: 5,
        typeId: '',
        newType: '',
        status: AdvocateTaskTypeStatus.Open,
        dueDate: defaultDueDate.toISOString(),
        displayDate: defaultDisplayDate.toISOString(),
        comments: [],
        history: [],
        watchedBy: [],
        createdBy: '',
        assignedTo: '',
    };
};
