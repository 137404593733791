import { Autocomplete, TextField } from '@mui/material';
import React from 'react';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { useAudienceListQuery } from '~/schemaTypes';

interface AudiencePickerParams {
    audienceIds: string[] | null | undefined;
    setAudienceIds: (ids: string[]) => void;
}

export const AudiencePicker: React.FC<AudiencePickerParams> = ({ setAudienceIds, audienceIds }) => {
    const { data, loading } = useAudienceListQuery();
    if (loading) {
        return <Loading />;
    }
    return (
        <OutlinedSection title="Select Audiences">
            <Autocomplete
                multiple
                value={audienceIds?.map(a => ({
                    id: a,
                    name: data?.audiences.find(i => i.id === a)?.name || '',
                }))}
                options={
                    (data &&
                        data.audiences
                            .slice()
                            .filter(a => !audienceIds?.includes(a.id))
                            .sort((a, b) => {
                                if (a.name < b.name) return -1;
                                if (a.name === b.name) return 0;
                                return 1;
                            })
                            .map(org => ({ id: org.id, name: org.name }))) ||
                    []
                }
                onChange={(_, val) => {
                    setAudienceIds(val.map(v => v.id));
                }}
                getOptionLabel={tag => tag.name}
                renderInput={params => (
                    <TextField
                        variant="outlined"
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        label="Audiences"
                        placeholder="Select Audiences"
                    />
                )}
            />
        </OutlinedSection>
    );
};
