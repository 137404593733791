import { add, differenceInDays, differenceInMonths, differenceInYears, sub } from 'date-fns';
import moment from 'moment';
import { TodoCreateInput, TodoUpdateInput } from '~/schemaTypes';
import { FIELD_NAMES, HA_CREATED } from './enums';
import { INotifyIntervalUnit, IToDoEditFormInput } from './interfaces';
import { dateToISOString } from '../../../../../helpers';

export const intervalFromEffectiveDate = (
    dueDate: Date,
    effectiveDate: Date,
): INotifyIntervalUnit => {
    const offsetYears = Math.abs(differenceInYears(dueDate, effectiveDate));
    const dueDateWithOffsetYears = add(dueDate, { years: offsetYears });
    const offsetMonths = Math.abs(differenceInMonths(dueDateWithOffsetYears, effectiveDate));
    const dueDateWithOffsetMonths = add(dueDate, { months: offsetMonths, years: offsetYears });
    const offsetDays = Math.abs(differenceInDays(dueDateWithOffsetMonths, effectiveDate));
    return {
        days: offsetDays,
        months: offsetMonths,
        years: offsetYears,
    };
};

const _calculateEffectiveDate = (
    dueDate: Date,
    effectiveDateDaysInterval: number,
    effectiveDateMonthsInterval: number,
    effectiveDateYearsInterval: number,
): string => {
    // SYS-8938 todos has only negative effective date. ("show several days(months , etc.) before")
    const effectiveDate = sub(new Date(dueDate), {
        days: effectiveDateDaysInterval,
        months: effectiveDateMonthsInterval,
        years: effectiveDateYearsInterval,
    });

    return dateToISOString(effectiveDate);
};

export const mapFormInputToQueryInput = (
    formInput: IToDoEditFormInput,
    id: string,
): TodoUpdateInput => {
    const {
        [FIELD_NAMES.effectiveDateDaysInterval]: effectiveDateDaysInterval,
        [FIELD_NAMES.effectiveDateMonthsInterval]: effectiveDateMonthsInterval,
        [FIELD_NAMES.effectiveDateYearsInterval]: effectiveDateYearsInterval,
        [FIELD_NAMES.dueDate]: dueDate,
        [FIELD_NAMES.priority]: priority,
        [FIELD_NAMES.isEssential]: isEssential,
        [FIELD_NAMES.title]: title,
        [FIELD_NAMES.description]: description,
    } = formInput;

    const queryInput: TodoUpdateInput = {
        id,
    };
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (dueDate !== undefined) queryInput.dueDate = moment.utc(dueDate).tz(timeZone).toISOString();
    if (priority !== undefined) queryInput.priority = priority;
    if (isEssential !== undefined) queryInput.isEssential = isEssential;
    if (title !== undefined) queryInput.title = title;
    if (description !== undefined) queryInput.description = { en: description };

    const isEfficientDateChanged =
        effectiveDateDaysInterval !== undefined ||
        effectiveDateMonthsInterval !== undefined ||
        effectiveDateYearsInterval !== undefined;

    if (isEfficientDateChanged) {
        queryInput.effectiveDate = _calculateEffectiveDate(
            dueDate,
            effectiveDateDaysInterval,
            effectiveDateMonthsInterval,
            effectiveDateYearsInterval,
        );
    }

    return queryInput;
};
export const mapFormInputToCreateQueryInput = (
    formInput: IToDoEditFormInput,
    patientId: string,
): TodoCreateInput => {
    const {
        [FIELD_NAMES.effectiveDateDaysInterval]: effectiveDateDaysInterval,
        [FIELD_NAMES.effectiveDateMonthsInterval]: effectiveDateMonthsInterval,
        [FIELD_NAMES.effectiveDateYearsInterval]: effectiveDateYearsInterval,
        [FIELD_NAMES.dueDate]: dueDate,
        [FIELD_NAMES.priority]: priority,
        [FIELD_NAMES.isEssential]: isEssential,
        [FIELD_NAMES.title]: title,
        [FIELD_NAMES.description]: description,
    } = formInput;

    const queryInput: TodoCreateInput = {
        patientId,
        title: { en: '' },
        createdBy: HA_CREATED,
        isComplete: false,
    };
    const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
    if (dueDate !== undefined) queryInput.dueDate = moment.utc(dueDate).tz(timeZone).toISOString();
    if (priority !== undefined) queryInput.priority = priority;
    if (isEssential !== undefined) queryInput.isEssential = isEssential;
    if (title !== undefined) queryInput.title = title;
    if (description !== undefined)
        queryInput.description =
            String.prototype.trim.call(description) !== '' ? { en: description } : null;

    const isEfficientDateChanged =
        effectiveDateDaysInterval !== undefined ||
        effectiveDateMonthsInterval !== undefined ||
        effectiveDateYearsInterval !== undefined;

    if (isEfficientDateChanged) {
        queryInput.effectiveDate = _calculateEffectiveDate(
            dueDate,
            effectiveDateDaysInterval,
            effectiveDateMonthsInterval,
            effectiveDateYearsInterval,
        );
    }

    return queryInput;
};
