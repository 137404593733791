import React, { useState, useMemo, useCallback } from 'react';
import { Grid, Dialog } from '@mui/material';
import AwayModeStatusButton from './AwayModeStatusButton';
import AwayModeDialog from './AwayModeDialog';
import { getMode } from './helpers';

type AwayModeProps = {
    awayMode: boolean;
    endAwayModeDate?: Date;
    startAwayModeDate?: Date;
    affiliateId: string;
};

const AwayMode: React.FC<AwayModeProps> = props => {
    const { awayMode, endAwayModeDate, startAwayModeDate, affiliateId } = props;
    const [showDialog, setShowDialog] = useState(false);
    const mode = useMemo(
        () => getMode(awayMode, startAwayModeDate, endAwayModeDate),
        [awayMode, endAwayModeDate, startAwayModeDate],
    );
    const onClickHandler = useCallback(() => {
        setShowDialog(true);
    }, []);
    return (
        <>
            <Grid container item xs={12}>
                <AwayModeStatusButton
                    mode={mode}
                    onClick={onClickHandler}
                    startAwayModeDate={startAwayModeDate}
                    endAwayModeDate={endAwayModeDate}
                />
            </Grid>
            {showDialog && (
                <Dialog open={showDialog}>
                    <AwayModeDialog
                        onClose={() => setShowDialog(false)}
                        mode={mode}
                        affiliateId={affiliateId}
                        startDate={startAwayModeDate}
                        endDate={endAwayModeDate}
                    />
                </Dialog>
            )}
        </>
    );
};

export default AwayMode;
