/* eslint-disable camelcase */
import { faPenToSquare, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { yupResolver } from '@hookform/resolvers/yup';
import {
    Button,
    Card,
    CardHeader,
    Checkbox,
    Chip,
    Dialog,
    Grid,
    Link,
    List,
    ListItem,
    ListItemIcon,
    ListItemSecondaryAction,
    TextField,
    Typography,
} from '@mui/material';
import { Add, ArrowBack, Delete, Save } from '@mui/icons-material';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import _ from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import ContentIconSelect from '~/components/ContentIconSelect/ContentIconSelect';
import Draggable from '~/components/Draggable/Draggable';
import Droppable from '~/components/Droppable/Droppable';
import ImageContentPicker from '~/components/ImageContentPicker/ImageContentPicker';
import Loading from '~/components/Loading/Loading';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import {
    ContentDirectoryInput,
    ContentDirectoryListDocument,
    ContentDirectoryListQuery,
    ContentIcon,
    DirectoryDisplayStyle,
    DirectoryItemInput,
    DirectoryItemType,
    TagUsageType,
    useContentDirectoryByIdLazyQuery,
    useCreateContentDirectoryMutation,
    usePreFetchAppsQuery,
    useReportingCategoryListQuery,
    useSurveyDefListQuery,
    useTagListQuery,
    useUpdateContentDirectoryMutation,
} from '~/schemaTypes';
import { TriggerGlobalConfirm } from '~/state';
import { ClientSelectionType } from '~/enums/enums';
import * as Yup from 'yup';
import ArticleModal from './components/ArticleModal';
import SimpleModal from './components/SimpleModal';
import SubDirModal from './components/SubDirModal';
import SurveyModal from './components/SurveyModal';
import { useStyles } from './styles';
import { ItemDisplay } from './types';
import { TaggedImageModal } from './components/TaggedImageModal';

const CD_VALIDATION_SCHEMA = Yup.object().shape({
    name: Yup.string().required('Required'),
    label: Yup.object().shape({
        en: Yup.string().required('Required'),
        es: Yup.string(),
    }),
});

export const toClientSelectionType = (
    includeApps?: string[],
    excludeApps?: string[],
): ClientSelectionType => {
    if (includeApps && includeApps.length > 0) return ClientSelectionType.IncludeCertainApps;
    if (excludeApps && excludeApps.length > 0) return ClientSelectionType.ExcludeCertainApps;
    return ClientSelectionType.IncludeAllApps;
};

export type TaggedImage = {
    tagName: string;
    tagId: string;
    imageName: string;
    imageContentId: string;
};

const ContentDirectoryEditor: React.FC = () => {
    const history = useNavigate();
    const { classes } = useStyles();
    const [isModified, setIsModified] = useState(false);
    const [close, setClose] = useState(false);
    const [selectedIcon, setSelectedIcon] = useState<ContentIcon>(ContentIcon.None);
    const [selectedStyle, setSelectedStyle] = useState<DirectoryDisplayStyle>(
        DirectoryDisplayStyle.Listwithicon,
    );
    const [articleModalOpen, setArticleModalOpen] = useState(false);
    const [surveyModalOpen, setSurveyModalOpen] = useState(false);
    const [simpleModalOpen, setSimpleModalOpen] = useState(false);
    const [subdirModalOpen, setSubdirModalOpen] = useState(false);
    const [taggedImageModalOpen, setTaggedImageModalOpen] = useState(false);
    const [selectedImageId, setSelectedImageId] = useState<string>();
    const [selectedItem, setSelectedItem] = useState<ItemDisplay | null>(null);
    const [reportingCats, setReportingCats] = useState<string[]>([]);
    const [taggedImages, setTaggedImages] = useState<TaggedImage[] | undefined>();
    const [apps, setApps] = useState<string[]>([]);
    const { id: contentDirectoryId } = useParams<{ id: string }>();
    const [items, setItems] = useState<ItemDisplay[]>([]);
    let isEditMode = contentDirectoryId !== 'new';
    const {
        register,
        handleSubmit,
        setValue,

        formState: { errors },
    } = useForm<ContentDirectoryInput>({
        resolver: yupResolver(CD_VALIDATION_SCHEMA as any),
    });
    const { data: surveyList, loading: surveysLoading } = useSurveyDefListQuery();
    const { data: reportingList, loading: reportingListLoading } = useReportingCategoryListQuery();
    const { data: tagData, loading: tagsLoading } = useTagListQuery({
        variables: { tagListInput: [TagUsageType.Filtering, TagUsageType.PatientRiskScore] },
    });
    const { data: appData, loading: appDataLoading } = usePreFetchAppsQuery();

    const [fetchCDById, { data: cdData, loading: cdLoading }] = useContentDirectoryByIdLazyQuery({
        onCompleted: ({ contentDirectory }) => {
            if (contentDirectory) {
                setValue('name', contentDirectory.name);
                setValue('description', contentDirectory.description);
                setValue('keywords', contentDirectory.keywords);
                setValue('label.en', contentDirectory.label.en);
                setValue('label.es', contentDirectory.label.es);
                setValue('appDescription.en', contentDirectory.appDescription?.en || '');
                setValue('appDescription.es', contentDirectory.appDescription?.es || '');
                if (contentDirectory.taggedImages)
                    setTaggedImages(
                        contentDirectory.taggedImages.map(i => ({
                            imageContentId: i.imageContentId,
                            imageName: i.imageName,
                            tagId: i.tagId,
                            tagName: i.tagName,
                        })),
                    );
                else setTaggedImages(undefined);
                setReportingCats(contentDirectory.reportingCategorys || []);
                if (contentDirectory.displayStyle) setSelectedStyle(contentDirectory.displayStyle);
                if (contentDirectory.icon) setSelectedIcon(contentDirectory.icon);
                if (contentDirectory.imageContentId)
                    setSelectedImageId(contentDirectory.imageContentId);
                if (contentDirectory.items) {
                    setItems(
                        contentDirectory.items.map(i => {
                            return {
                                id: i.id,
                                type: i.type,
                                articleName: i.article?.name,
                                articleId: i.articleId,
                                simpleContentId: i.simpleContentId,
                                simpleContentName: i.simpleContent?.name,
                                surveyId: i.surveyId,
                                surveyName: i.survey?.name,
                                subdirId: i.subDirectoryId,
                                subdirName: i.subdirectory?.name,
                                includeApps: i.includeApps || [],
                                excludeApps: i.excludeApps || [],
                                tagList: i.tagList?.map(tag => ({
                                    id: tag,
                                    name:
                                        tagData?.getTagListByUsageTypes.find(t => t.id === tag)
                                            ?.name || 'Tag Not Found',
                                })),
                            };
                        }),
                    );
                }
            }
        },
    });

    const [updateCD, { loading: updateLoading }] = useUpdateContentDirectoryMutation({
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    setIsModified(true);
                    setClose(false);
                },
                message: `There was a problem saving the content directory: ${error.message}`,
            });
        },
        onCompleted: () => {
            if (close) {
                history('/app-config/contentdirectory/');
            }
        },
        update: (cache, response) => {
            const updatedCD = response.data?.updateContentDirectory?.resourceUpdated;
            if (response.data?.updateContentDirectory?.success && updatedCD) {
                const currentData = cache.readQuery<ContentDirectoryListQuery>({
                    query: ContentDirectoryListDocument,
                });
                if (currentData?.contentDirectorysV2) {
                    cache.writeQuery<ContentDirectoryListQuery>({
                        query: ContentDirectoryListDocument,
                        data: {
                            contentDirectorysV2: {
                                ...currentData.contentDirectorysV2,
                                results: [
                                    ...currentData.contentDirectorysV2.results.map(cd => {
                                        if (cd.id === updatedCD.id) {
                                            return updatedCD;
                                        }
                                        return cd;
                                    }),
                                ],
                            },
                        },
                    });
                }
            }
        },
    });

    const [createCD, { loading: createLoading }] = useCreateContentDirectoryMutation({
        onCompleted: data => {
            isEditMode = true;
            if (close) {
                history('/app-config/contentdirectory/');
            } else {
                history(
                    `/app-config/contentdirectory/${
                        data.createContentDirectory?.resourceCreated?.id ?? 'new'
                    }`,
                );
            }
        },
        onError: error => {
            TriggerGlobalConfirm({
                callback: () => {
                    setIsModified(true);
                    setClose(false);
                },
                message: `There was a problem saving the content directory: ${error.message}`,
            });
        },
        update: (cache, response) => {
            const newCD = response.data?.createContentDirectory?.resourceCreated;
            if (response.data?.createContentDirectory?.success && newCD) {
                const currentData = cache.readQuery<ContentDirectoryListQuery>({
                    query: ContentDirectoryListDocument,
                });
                if (currentData?.contentDirectorysV2.results) {
                    cache.writeQuery<ContentDirectoryListQuery>({
                        query: ContentDirectoryListDocument,
                        data: {
                            contentDirectorysV2: {
                                ...currentData.contentDirectorysV2,
                                results: [...currentData.contentDirectorysV2.results, newCD],
                            },
                        },
                    });
                }
            }
        },
    });
    const onSubmit = (values: any) => {
        const itemInput: DirectoryItemInput[] = items.map(i => {
            return {
                id: i.id,
                type: i.type,
                simpleContentId: i.simpleContentId,
                articleId: i.articleId,
                surveyId: i.surveyId,
                subDirectoryId: i.subdirId,
                tagList: i.tagList?.map(tag => tag.id),
                includeApps: i.includeApps,
                excludeApps: i.excludeApps,
            };
        });
        if (isEditMode && contentDirectoryId) {
            updateCD({
                variables: {
                    updateContentDirectoryInput: {
                        id: contentDirectoryId,
                        data: {
                            ...values,
                            ...(selectedIcon ? { icon: selectedIcon } : {}),
                            displayStyle: selectedStyle,
                            imageContentId: selectedImageId || null,
                            items: itemInput,
                            reportingCategorys: reportingCats,
                            taggedImages: taggedImages?.map(t => ({
                                imageContentId: t.imageContentId,
                                tagId: t.tagId,
                                tagName: t.tagName,
                                imageName: t.imageName,
                            })),
                        },
                    },
                },
            });
        } else {
            createCD({
                variables: {
                    input: {
                        ...values,
                        ...(selectedIcon ? { icon: selectedIcon } : {}),
                        ...(selectedImageId ? { imageContentId: selectedImageId } : {}),
                        displayStyle: selectedStyle,
                        reportingCategorys: reportingCats,
                        taggedImages: taggedImages?.map(t => ({
                            imageContentId: t.imageContentId,
                            tagId: t.tagId,
                            tagName: t.tagName,
                            imageName: t.imageName,
                        })),
                        items: itemInput,
                    },
                },
            });
        }
        setIsModified(false);
    };
    useEffect(() => {
        if (
            isEditMode &&
            tagData?.getTagListByUsageTypes &&
            tagData?.getTagListByUsageTypes.length > 0 &&
            !tagsLoading
        ) {
            fetchCDById({ variables: { input: { id: contentDirectoryId } } });
        }
        if (appData?.applicationsV2.results)
            setApps(
                _.sortBy(appData.applicationsV2.results, 'appBundleId').map(a => a.appBundleId) ||
                    [],
            );
    }, [
        fetchCDById,
        isEditMode,
        contentDirectoryId,
        tagData?.getTagListByUsageTypes,
        tagsLoading,
        appData?.applicationsV2.results,
    ]);
    const onEdit = () => {
        setIsModified(true);
    };
    const onNavigateAway = () => {
        if (isModified)
            TriggerGlobalConfirm({
                message: `You have unsaved changes. Are you sure you want to return to the content directory page?`,
                callback: () => {
                    history(`/app-config/contentdirectory/`);
                },
            });
        else history(`/app-config/contentdirectory/`);
    };
    const closeAllDialogs = () => {
        setArticleModalOpen(false);
        setSurveyModalOpen(false);
        setSubdirModalOpen(false);
        setSimpleModalOpen(false);
        setSelectedItem(null);
    };
    const handleDeleteItem = (id: string) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this item?`,
            callback: () => {
                setItems(items.filter(i => i.id !== id));
            },
        });
    };
    const handleModalSubmit = (data: ItemDisplay) => {
        if (items.find(item => item.id === data.id)) {
            const indexOfData = items.findIndex(item => item.id === data.id);
            items[indexOfData] = data;
        } else {
            items.push(data);
        }

        setItems(items);
        closeAllDialogs();
        onEdit();
    };
    const handleModalClose = () => {
        closeAllDialogs();
    };
    const handleTaggedImageSave = (item: TaggedImage) => {
        setTaggedImages(taggedImages?.concat(item));
        setTaggedImageModalOpen(false);
    };
    const handleTaggedImageDelete = (tagId: string, imageContentId: string) => {
        TriggerGlobalConfirm({
            message: `Are you sure you want to delete this tagged image?`,
            callback: () => {
                setTaggedImages(
                    taggedImages?.filter(
                        t => !(t.tagId === tagId && t.imageContentId === imageContentId),
                    ),
                );
            },
        });
    };
    const reportingCatSelected = (selected: string, checked: boolean) => {
        if (checked) {
            setReportingCats(reportingCats.concat(selected));
        } else setReportingCats(reportingCats.filter(c => c !== selected));
        onEdit();
    };

    const onDragEnd = useCallback(
        (result: { source: any; destination: any }) => {
            const { source, destination } = result;
            const hasSource = source && source.index != null;
            const hasDestination = destination && destination.index != null;
            const hasData = hasSource && hasDestination;
            const shouldMoveItem = hasData && source.index !== destination.index;
            if (!shouldMoveItem) {
                return;
            }
            const itemsCopy = [...items];
            const [removed] = itemsCopy.splice(source.index, 1);
            itemsCopy.splice(destination.index, 0, removed);
            setItems(itemsCopy);
        },
        [items],
    );

    const itemInfo = (item: ItemDisplay) => {
        const tagLabels =
            (item.tagList &&
                item.tagList.map(tag => <Chip className={classes.tagBlock} label={tag.name} />)) ||
            null;
        let apps = 'All Apps';
        if (item.includeApps && item.includeApps.length > 0)
            apps = `Include: ${item.includeApps.join()}`;
        if (item.excludeApps && item.excludeApps.length > 0)
            apps = `Exclude: ${item.excludeApps.join()}`;
        switch (item.type) {
            case DirectoryItemType.Article:
                return (
                    <div className={classes.itemBlock}>
                        <Button
                            className={classes.editIcon}
                            onClick={() => {
                                setSelectedItem(item);
                                setArticleModalOpen(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                        <Link
                            href={`/app-config/articles/${item.articleId}`}
                            underline="hover"
                        >{`Article - ${item.articleName}`}</Link>
                        {tagLabels}
                        <Chip className={classes.tagBlock} label={apps} />
                    </div>
                );
            case DirectoryItemType.SimpleContent:
                return (
                    <div className={classes.itemBlock}>
                        <Button
                            className={classes.editIcon}
                            onClick={() => {
                                setSelectedItem(item);
                                setSimpleModalOpen(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                        <Typography>{`Simple Content - ${item.simpleContentName}`}</Typography>
                        {tagLabels}
                        <Chip className={classes.tagBlock} label={apps} />
                    </div>
                );
            case DirectoryItemType.Survey:
                return (
                    <div className={classes.itemBlock}>
                        <Button
                            className={classes.editIcon}
                            onClick={() => {
                                setSelectedItem(item);
                                setSurveyModalOpen(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                        <Typography>{`Survey - ${item.surveyName}`}</Typography>
                        {tagLabels}
                        <Chip className={classes.tagBlock} label={apps} />
                    </div>
                );
            case DirectoryItemType.SubDirectory:
                return (
                    <div className={classes.itemBlock}>
                        <Button
                            className={classes.editIcon}
                            onClick={() => {
                                setSelectedItem(item);
                                setSubdirModalOpen(true);
                            }}
                        >
                            <FontAwesomeIcon icon={faPenToSquare} />
                        </Button>
                        <Typography>{`Sub-Directory - ${item.subdirName}`}</Typography>
                        {tagLabels}
                        <Chip className={classes.tagBlock} label={apps} />
                    </div>
                );
            default:
                return <Typography>Unknown Type</Typography>;
        }
    };

    if (
        createLoading ||
        updateLoading ||
        cdLoading ||
        tagsLoading ||
        surveysLoading ||
        reportingListLoading ||
        appDataLoading
    ) {
        return <Loading />;
    }
    return (
        <>
            {simpleModalOpen && (
                <SimpleModal
                    tags={tagData?.getTagListByUsageTypes || []}
                    apps={apps}
                    submitHandler={handleModalSubmit}
                    closeHandler={handleModalClose}
                    selectedItem={selectedItem}
                />
            )}
            {taggedImageModalOpen && (
                <TaggedImageModal
                    tags={tagData?.getTagListByUsageTypes || []}
                    closeHandler={() => setTaggedImageModalOpen(false)}
                    submitHandler={handleTaggedImageSave}
                />
            )}
            <Dialog scroll="body" open={articleModalOpen} aria-labelledby="form-dialog-title">
                <ArticleModal
                    tags={tagData?.getTagListByUsageTypes || []}
                    apps={apps}
                    submitHandler={handleModalSubmit}
                    closeHandler={handleModalClose}
                    selectedItem={selectedItem}
                />
            </Dialog>
            <Dialog scroll="body" open={surveyModalOpen} aria-labelledby="form-dialog-title">
                <SurveyModal
                    parms={{
                        surveys: _.sortBy(surveyList?.surveyDefs, 'name') || [],
                        apps,
                        tags: tagData?.getTagListByUsageTypes || [],
                        submitHandler: handleModalSubmit,
                        closeHandler: handleModalClose,
                        selectedItem,
                    }}
                />
            </Dialog>
            <Dialog scroll="body" open={subdirModalOpen} aria-labelledby="form-dialog-title">
                <SubDirModal
                    parms={{
                        tags: tagData?.getTagListByUsageTypes || [],
                        apps,
                        submitHandler: handleModalSubmit,
                        closeHandler: handleModalClose,
                        selectedItem,
                    }}
                />
            </Dialog>
            <Grid container spacing={2} className={classes.root}>
                <Grid item xs={12}>
                    <Button onClick={onNavigateAway} startIcon={<ArrowBack />}>
                        Back to Content Directories
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <form noValidate>
                        <Card>
                            <CardHeader
                                title={`Content Directory Settings: ${
                                    cdData?.contentDirectory?.name ?? 'New Content Directory'
                                }`}
                            />
                            <OutlinedSection title="Name *">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={cdData?.contentDirectory?.name}
                                    {...register('name')}
                                    error={!!errors.name}
                                    helperText={errors.name?.message}
                                    onChange={onEdit}
                                />
                            </OutlinedSection>
                            <OutlinedSection title="Label">
                                <TextField
                                    variant="outlined"
                                    label="English *"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={cdData?.contentDirectory?.label?.en}
                                    {...register('label.en')}
                                    error={!!errors.label?.en}
                                    helperText={errors.label?.en?.message}
                                    onChange={onEdit}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Spanish"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={cdData?.contentDirectory?.label?.es}
                                    {...register('label.es')}
                                    error={!!errors.label?.es}
                                    helperText={errors.label?.es?.message}
                                    onChange={onEdit}
                                />
                            </OutlinedSection>
                            <OutlinedSection title="Description">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={cdData?.contentDirectory?.description}
                                    {...register('description')}
                                    onChange={onEdit}
                                />
                            </OutlinedSection>
                            <OutlinedSection title="Description for App">
                                <TextField
                                    variant="outlined"
                                    label="English"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={cdData?.contentDirectory?.appDescription?.en}
                                    {...register('appDescription.en')}
                                    onChange={onEdit}
                                />
                                <TextField
                                    variant="outlined"
                                    label="Spanish"
                                    type="text"
                                    fullWidth
                                    margin="dense"
                                    defaultValue={cdData?.contentDirectory?.appDescription?.es}
                                    {...register('appDescription.es')}
                                    onChange={onEdit}
                                />
                            </OutlinedSection>
                            <OutlinedSection title="Keywords">
                                <TextField
                                    variant="outlined"
                                    type="text"
                                    fullWidth
                                    multiline
                                    minRows={4}
                                    margin="dense"
                                    defaultValue={cdData?.contentDirectory?.keywords}
                                    {...register('keywords')}
                                    onChange={onEdit}
                                />
                            </OutlinedSection>
                            <OutlinedSection title="Reporting categories">
                                {reportingList &&
                                    _.sortBy(reportingList.reportingCategorys, 'name').map(rc => (
                                        <span style={{ marginLeft: '10px' }}>
                                            {rc.name}
                                            <Checkbox
                                                value={rc.id}
                                                key={rc.id}
                                                checked={
                                                    reportingCats.find(cat => cat === rc.id) !==
                                                    undefined
                                                }
                                                onChange={e =>
                                                    reportingCatSelected(
                                                        e.target.value,
                                                        e.target.checked,
                                                    )
                                                }
                                            />
                                        </span>
                                    ))}
                            </OutlinedSection>
                            <OutlinedSection title="Display Style">
                                <select
                                    value={selectedStyle}
                                    onChange={e =>
                                        setSelectedStyle(e.target.value as DirectoryDisplayStyle)
                                    }
                                >
                                    <option value={DirectoryDisplayStyle.Listwithicon}>
                                        List with Icon
                                    </option>
                                    <option value={DirectoryDisplayStyle.Simplelist}>
                                        Simple List
                                    </option>
                                    <option value={DirectoryDisplayStyle.Listwithimage}>
                                        List with Image
                                    </option>
                                    <option value={DirectoryDisplayStyle.Tiledimage}>
                                        Tiled Images
                                    </option>
                                </select>
                            </OutlinedSection>
                            <ContentIconSelect
                                selectedIcon={selectedIcon}
                                setSelectedIcon={setSelectedIcon}
                                onEdit={onEdit}
                            />
                            <ImageContentPicker
                                selectedImageContentId={selectedImageId}
                                setSelectedImageContentId={setSelectedImageId}
                                onEdit={onEdit}
                                title="Default Image"
                            />
                            {taggedImages && taggedImages.length > 0 && (
                                <div>
                                    Tagged Images
                                    <table>
                                        <tr>
                                            <td>Action</td>
                                            <td>Tag</td>
                                            <td>Image</td>
                                        </tr>
                                        {taggedImages.map(t => (
                                            <tr>
                                                <td>
                                                    <Button
                                                        aria-label="delete"
                                                        onClick={() =>
                                                            handleTaggedImageDelete(
                                                                t.tagId,
                                                                t.imageContentId,
                                                            )
                                                        }
                                                    >
                                                        <FontAwesomeIcon icon={faTrash} />
                                                    </Button>
                                                </td>
                                                <td>{t.tagName}</td>
                                                <td>{t.imageName}</td>
                                            </tr>
                                        ))}
                                    </table>
                                </div>
                            )}
                            <Button
                                aria-label="Add Tagged Image"
                                startIcon={<Add />}
                                color="primary"
                                variant="contained"
                                onClick={() => setTaggedImageModalOpen(true)}
                            >
                                Add Tagged Image
                            </Button>
                            <Grid container justifyContent="flex-end">
                                <Button
                                    aria-label="save"
                                    startIcon={<Save />}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => handleSubmit(onSubmit)()}
                                >
                                    Save
                                </Button>
                                <Button
                                    aria-label="saveandclose"
                                    startIcon={<Save />}
                                    color="secondary"
                                    variant="contained"
                                    onClick={() => {
                                        setClose(true);
                                        handleSubmit(onSubmit)();
                                    }}
                                >
                                    Save &amp; Close
                                </Button>
                            </Grid>
                        </Card>

                        <div>
                            <h2>
                                Items{' '}
                                <Button onClick={() => setArticleModalOpen(true)}>
                                    Add Article
                                </Button>
                                {surveyList?.surveyDefs && surveyList.surveyDefs.length > 0 && (
                                    <Button onClick={() => setSurveyModalOpen(true)}>
                                        Add Survey
                                    </Button>
                                )}
                                <Button onClick={() => setSimpleModalOpen(true)}>
                                    Add Simple Content
                                </Button>
                                <Button onClick={() => setSubdirModalOpen(true)}>
                                    Add Sub-Directory
                                </Button>
                            </h2>
                            {items.length > 0 && (
                                <Grid item xs={12}>
                                    <List>
                                        <Droppable onDragEnd={onDragEnd} droppableId="itemsOrder">
                                            {items?.map((item, index) => {
                                                if (!item?.id) return null;
                                                return (
                                                    <Draggable
                                                        id={item.id}
                                                        index={index}
                                                        key={item.id}
                                                    >
                                                        <div key={item.id}>
                                                            <ListItem>
                                                                <ListItemIcon
                                                                    className={classes.deleteIcon}
                                                                    onClick={() =>
                                                                        handleDeleteItem(item.id)
                                                                    }
                                                                >
                                                                    <Delete color="primary" />
                                                                </ListItemIcon>
                                                                {itemInfo(item)}
                                                                <ListItemSecondaryAction>
                                                                    <ListItemIcon className="drag-handle">
                                                                        <DragHandleIcon />
                                                                    </ListItemIcon>
                                                                </ListItemSecondaryAction>
                                                            </ListItem>
                                                        </div>
                                                    </Draggable>
                                                );
                                            })}
                                        </Droppable>
                                    </List>
                                </Grid>
                            )}
                        </div>
                    </form>
                </Grid>
            </Grid>
        </>
    );
};

export default ContentDirectoryEditor;
