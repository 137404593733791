import { FacadeCollectionName } from '~/schemaTypes';
import { MergeRow } from '../MergePatient';

export const getFacadeCollectionRows = (): MergeRow[] => {
    return [
        {
            attribute: FacadeCollectionName.AdvocateTasks,
            attributeDisplay: 'Advocate Tasks',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.AdvocateTasks,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.Appointments,
            attributeDisplay: 'Appointments',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.Appointments,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.Measurements,
            attributeDisplay: 'Measurements',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.Measurements,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.Questions,
            attributeDisplay: 'Questions',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.Questions,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.SurveyResponses,
            attributeDisplay: 'Survey Response',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.SurveyResponses,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.PatientNotes,
            attributeDisplay: 'Patient Notes',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.PatientNotes,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.ChatMessages,
            attributeDisplay: 'Chat Messages',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.ChatMessages,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.ClinicalSummaries,
            attributeDisplay: 'Clinical Summaries',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.ClinicalSummaries,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.Orders,
            attributeDisplay: 'Orders',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.Orders,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.PatientActions,
            attributeDisplay: 'Patient Actions',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.PatientActions,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.Virtualcareforms,
            attributeDisplay: 'Virtual Care Forms',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.Virtualcareforms,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.ClaimSubmitted,
            attributeDisplay: 'Claims Submitted',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.ClaimSubmitted,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.Eligibilitymatch,
            attributeDisplay: 'Eligibility Match',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.Eligibilitymatch,
            isFacadeCollection: true,
        },
        {
            attribute: FacadeCollectionName.SurveyResponsesExpanded,
            attributeDisplay: 'Survey Responses Expanded',
            sourceValue: null,
            targetValue: null,
            finalValue: 'merge',
            selectedOption: 'merge',
            facadeCollection: FacadeCollectionName.SurveyResponsesExpanded,
            isFacadeCollection: true,
        },
    ];
};
