import { useMemo } from 'react';
import { Organization } from '~/schemaTypes';
import { VC_PARENT_ORG_ID } from '~/constants';
import { useUser, useVcUserView } from '~/hooks';

type Org = Pick<Organization, 'parentId' | 'id' | 'ancestors'>;

const isVirtualCareOrg = (currentOrg?: Org): boolean => {
    if (!currentOrg) {
        return false;
    }
    const { parentId, ancestors } = currentOrg;
    if (parentId === VC_PARENT_ORG_ID || currentOrg.id === VC_PARENT_ORG_ID) {
        return true;
    }
    if (ancestors && ancestors.length) {
        return ancestors.includes(VC_PARENT_ORG_ID);
    }
    return false;
};

export default () => {
    const { data: userData } = useUser();
    const { isVcUser } = useVcUserView();
    const isVirtualCareView = useMemo(
        () => isVirtualCareOrg(userData?.currentUser?.currentOrganization),
        [userData?.currentUser?.currentOrganization],
    );
    return { isVirtualCareView: isVirtualCareView && !isVcUser };
};
