import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import {
    useGetTrackerHistoryForPatientTranscriptQuery,
    useGetProfileValueHistoriesByPatientIdForPatientTranscriptQuery,
    useGetPatientNotesForPatientTranscriptPageQuery,
    useGetAdvocateTaskCheckListChangesForPatientTranscriptPageQuery,
    useGetSurveyResponsesForPatientTranscriptQuery,
    useGetChatMessagesForPatientTranscriptQuery,
    useGetSentMessageByPatientIdForPatientTranscriptQuery,
    OrderByDirectionEnum,
} from '~/schemaTypes';
import { useDateRangeFilter } from './hooks/useDateRangeFilter';
import { usePatientTranscriptQueries } from './hooks/usePatientTranscriptQueries';
import { useLoadMore } from './hooks/useLoadMore';
import { TranscriptFilters } from './components/TranscriptFilters';
import { TranscriptCards } from './components/TranscriptCards';
import { useCardTypes } from './hooks/useCardTypes';
import type { TranscriptCard } from './types';

type RouteParams = {
    id: string;
};

export const PatientTranscript = () => {
    const { id: patientId } = useParams<RouteParams>() as { id: string };

    // Initialize state from localStorage with defaults
    const [dateRange, setDateRange] = useState<string>(() => {
        return localStorage.getItem('patientTranscriptDateRange') || 'lastDay';
    });

    const [customStartDate, setCustomStartDate] = useState<Date | null>(() => {
        const savedStartDate = localStorage.getItem('patientTranscriptCustomStartDate');
        return savedStartDate ? new Date(savedStartDate) : null;
    });

    const [customEndDate, setCustomEndDate] = useState<Date | null>(() => {
        const savedEndDate = localStorage.getItem('patientTranscriptCustomEndDate');
        return savedEndDate ? new Date(savedEndDate) : null;
    });

    // Create wrapper functions to update both state and localStorage
    const handleSetDateRange = (newDateRange: string) => {
        setDateRange(newDateRange);
        localStorage.setItem('patientTranscriptDateRange', newDateRange);
    };

    const handleSetCustomStartDate = (date: Date | null) => {
        setCustomStartDate(date);
        if (date) {
            localStorage.setItem('patientTranscriptCustomStartDate', date.toISOString());
        } else {
            localStorage.removeItem('patientTranscriptCustomStartDate');
        }
    };

    const handleSetCustomEndDate = (date: Date | null) => {
        setCustomEndDate(date);
        if (date) {
            localStorage.setItem('patientTranscriptCustomEndDate', date.toISOString());
        } else {
            localStorage.removeItem('patientTranscriptCustomEndDate');
        }
    };

    const dateRangeFilter = useDateRangeFilter(dateRange, customStartDate, customEndDate);

    // Regular queries with loading states
    const { data: trackerHistory, loading: loadingTrackerHistory } =
        useGetTrackerHistoryForPatientTranscriptQuery({
            variables: {
                input: {
                    orderBy: {
                        field: 'createdAt',
                        order: OrderByDirectionEnum.Desc,
                    },
                    patientId,
                    types: ['BloodPressureTrackerRecord', 'GlucoseTrackerRecord'],
                    ...dateRangeFilter,
                },
            },
        });

    const { data: profileValueHistory, loading: loadingProfileValueHistory } =
        useGetProfileValueHistoriesByPatientIdForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: patientNotes, loading: loadingPatientNotes } =
        useGetPatientNotesForPatientTranscriptPageQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: advocateTasks, loading: loadingAdvocateTasks } =
        useGetAdvocateTaskCheckListChangesForPatientTranscriptPageQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
            fetchPolicy: 'no-cache',
        });

    const { data: surveyResponses, loading: loadingSurveyResponses } =
        useGetSurveyResponsesForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: chatMessages, loading: loadingChatMessages } =
        useGetChatMessagesForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    const { data: sentMessages, loading: loadingSentMessages } =
        useGetSentMessageByPatientIdForPatientTranscriptQuery({
            variables: {
                input: {
                    patientId,
                    ...dateRangeFilter,
                },
            },
        });

    // Event queries
    const {
        queries,
        loading: eventQueriesLoading,
        pagination,
        fetchMore,
    } = usePatientTranscriptQueries(patientId, dateRangeFilter);

    const { handleLoadMore, loadingStates } = useLoadMore(
        queries,
        fetchMore,
        pagination.setEventPaginationTokens,
        dateRangeFilter,
        patientId,
    );
    const [cardTypes, setCardTypes] = useCardTypes();

    // Add ref to store previous cards
    const previousCardsRef = React.useRef<TranscriptCard[]>([]);

    const cards = React.useMemo(() => {
        const newCards = [
            ...(cardTypes.Tracker
                ? trackerHistory?.getTrackerHistoryForPatientTranscript ?? []
                : []),
            ...(cardTypes.ProfileValue
                ? profileValueHistory?.getProfileValueHistoriesByPatientId ?? []
                : []),
            ...(cardTypes.PatientNotes
                ? patientNotes?.getPatientNotesForPatientTranscript ?? []
                : []),
            ...(cardTypes.AdvocateTasks
                ? advocateTasks?.getAdvocateTaskCheckListChangesForPatientTranscript ?? []
                : []),
            ...(cardTypes.SurveyResponseExpanded
                ? surveyResponses?.getSurveyResponsesExpandedForPatientTranscript ?? []
                : []),
            ...(cardTypes.ChatMessages
                ? chatMessages?.getChatMessagesForPatientTranscript ?? []
                : []),
            ...(cardTypes.SentComms ? sentMessages?.getSentMessageByPatientId ?? [] : []),
            ...(cardTypes.ResolvedTagEvent
                ? queries.resolvedTagEvents?.getResolvedTagsForPatientTranscript?.items ?? []
                : []),
            ...(cardTypes.TriggerInterventionStartEpisode
                ? queries.triggerInterventionStartEpisodes
                      ?.getTriggerInterventionStartEpisodesForPatientTranscript?.items ?? []
                : []),
            ...(cardTypes.TriggerInterventionEndEpisode
                ? queries.triggerInterventionEndEpisodes
                      ?.getTriggerInterventionEndEpisodesForPatientTranscript?.items ?? []
                : []),
            ...(cardTypes.TriggerInterventionSetProfileValue
                ? queries.triggerInterventionSetProfileValue
                      ?.getTriggerInterventionSetProfileValueForPatientTranscript?.items ?? []
                : []),
            ...(cardTypes.TriggerInterventionSendMessageCenter
                ? queries.triggerInterventionSendMessageCenter
                      ?.getTriggerInterventionSendMessageCenterForPatientTranscript?.items ?? []
                : []),
            ...(cardTypes.TriggerInterventionCreateAdvocateTask
                ? queries.triggerInterventionCreateAdvocateTask
                      ?.getTriggerInterventionCreateAdvocateTaskForPatientTranscript?.items ?? []
                : []),
            ...(cardTypes.ExpressionEvaluated
                ? queries.expressionEvaluated?.getExpressionEvaluatedForPatientTranscript?.items ??
                  []
                : []),
            ...(cardTypes.TriggerFiredEvent
                ? queries.triggerFiredEvents?.getTriggerFiredEventsForPatientTranscript?.items ?? []
                : []),
            ...(cardTypes.TriggerInterventionClearProfileValue
                ? queries.triggerInterventionClearProfileValue
                      ?.getTriggerInterventionClearProfileValueForPatientTranscript?.items ?? []
                : []),
            ...(cardTypes.TriggerInterventionClearProfileChoice
                ? queries.triggerInterventionClearProfileChoice
                      ?.getTriggerInterventionClearProfileChoiceForPatientTranscript?.items ?? []
                : []),
        ] as TranscriptCard[];

        // If any loading states are active, include previous cards
        const isLoading = Object.values(loadingStates).some(loading => loading);
        const mergedCards = isLoading ? [...previousCardsRef.current, ...newCards] : newCards;

        // Remove duplicates by id
        const uniqueCards = mergedCards.filter(
            (card, index, self) => index === self.findIndex(c => c?.id === card?.id),
        );

        // Sort cards by date
        const sortedCards = uniqueCards.sort((a, b) =>
            new Date(a?.createdAt).getTime() >= new Date(b?.createdAt).getTime() ? -1 : 1,
        );

        // Update ref with current cards
        previousCardsRef.current = sortedCards;

        return sortedCards;
    }, [
        cardTypes,
        queries,
        trackerHistory,
        profileValueHistory,
        patientNotes,
        advocateTasks,
        surveyResponses,
        chatMessages,
        sentMessages,
        loadingStates,
    ]);

    // Add this useMemo to compute a single boolean for event loading state
    const eventLoading = React.useMemo(
        () => Object.values(eventQueriesLoading).some(isLoading => isLoading),
        [eventQueriesLoading],
    );

    return (
        <div className="gap-2 flex flex-col">
            <TranscriptFilters
                cardTypes={cardTypes}
                setCardTypes={setCardTypes}
                dateRange={dateRange}
                setDateRange={handleSetDateRange}
                customStartDate={customStartDate}
                setCustomStartDate={handleSetCustomStartDate}
                customEndDate={customEndDate}
                setCustomEndDate={handleSetCustomEndDate}
            />

            <TranscriptCards
                cards={cards}
                cardTypes={cardTypes}
                handleLoadMore={handleLoadMore}
                queries={queries}
                loading={{
                    trackerHistory: loadingTrackerHistory,
                    profileValueHistory: loadingProfileValueHistory,
                    patientNotes: loadingPatientNotes,
                    advocateTasks: loadingAdvocateTasks,
                    surveyResponses: loadingSurveyResponses,
                    chatMessages: loadingChatMessages,
                    sentMessages: loadingSentMessages,
                    events: eventLoading,
                }}
                loadingStates={loadingStates}
            />
        </div>
    );
};
