import { useState, useEffect } from 'react';
import { AffiliateApprovalStatus, OnboardingStatus, PortalVcUserType } from '~/schemaTypes';
import useUser from './useUser';

const needOnboardingStatuses = [
    OnboardingStatus.Started,
    OnboardingStatus.FirstStepCompleted,
    OnboardingStatus.SecondStepCompleted,
    OnboardingStatus.ThirdStepCompleted,
    OnboardingStatus.FourthStepCompleted,
];

const useAffiliateCareView = () => {
    const [isAffiliateView, setIsAffiliateView] = useState(false);
    const { data: userData } = useUser();
    useEffect(() => {
        if (userData?.currentUser?.vcType === PortalVcUserType.Affiliate) {
            setIsAffiliateView(true);
        }
    }, [userData?.currentUser]);
    const isDenied =
        userData?.currentUser?.affiliate?.data?.approvalStatus === AffiliateApprovalStatus.Denied ||
        !!userData?.currentUser?.currentOrganization.waystarEligibilityConfig
            ?.claimSubmissionDeniedMessage;
    const legacyAffiliate = !userData?.currentUser?.affiliate?.data?.onboardingStatus;
    const onboardingStep = userData?.currentUser?.affiliate?.data?.onboardingStatus || null;
    const isOnboarding = !!(onboardingStep && needOnboardingStatuses.includes(onboardingStep));
    const allowSettings = !userData?.currentUser || onboardingStep === OnboardingStatus.Completed;
    return {
        isAffiliateView,
        userAffiliateId: userData?.currentUser?.affiliate?.data?.affiliateId || '',
        affiliateName: userData?.currentUser?.affiliate?.data?.externalName,
        affiliateURL: userData?.currentUser?.affiliate?.affiliateURL || '',
        onboardingStep,
        legacyAffiliate,
        isDenied,
        allowSettings,
        isOnboarding,
        id: userData?.currentUser?.affiliate?.data?.id || '',
        deniedMessage:
            userData?.currentUser?.currentOrganization.waystarEligibilityConfig
                ?.claimSubmissionDeniedMessage,
        awayMode: userData?.currentUser?.affiliate?.data?.awayMode || false,
        startAwayModeDate: userData?.currentUser?.affiliate?.data?.startAwayModeDate,
        endAwayModeDate: userData?.currentUser?.affiliate?.data?.endAwayModeDate,
    };
};

export default useAffiliateCareView;
