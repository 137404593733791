import { useMemo } from 'react';
import useUser from './useUser';
import { isLargeOrg } from '../helpers/isLargeOrg';

export function useCheckIsLargeOrgSelected() {
    const { data: userData } = useUser();

    const selectedOrg = userData?.currentUser?.currentOrganization.id;

    return useMemo(() => isLargeOrg(selectedOrg), [selectedOrg]);
}
