import { faPenToSquare } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    Checkbox,
    Dialog,
    Drawer,
    FormControlLabel,
    Grid,
    IconButton,
    MenuItem,
    Typography,
} from '@mui/material';
import { ChevronLeft, ChevronRight, Info, Restore } from '@mui/icons-material';
import _ from 'lodash';
import MaterialTable from '@material-table/core';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import { displayDate, displayDateWithAbbrTimeZone } from '~/helpers';
import tableIcons from '~/helpers/tableIcons';
import { useUser, useUserPermissions } from '~/hooks';
import {
    HaPermission,
    ProfileValueHistory,
    useFetchPatientByIdForPatientModalQuery,
    useFetchPatientProfileVariablesQuery,
    useFetchProfileValueHistoriesByProfileKeysForPatientLazyQuery,
    useProfileKeysQuery,
} from '~/schemaTypes';
import DialogTitleWithClose from '~/components/DialogTitleWthClose/DialogTitleWithClose';
import WeightValue, { LBS, WEIGHT_UNIT_DEFAULT } from '~/components/WeightValue/WeightValue';
import PatientProfileVariableEpisodesModal from './PatientProfileVariableEpisodesModal/PatientProfileVariableEpisodesModal';
import { PatientProfileVariableHistoryModal } from './PatientProfileVariableHistoryModal/PatientProfileVariableHistoryModal';
import PatientProfileVariableModal from './PatientProfileVariableModal/PatientProfileVariableModal';
import { PatientSelectFilter } from './shared/components';
import {
    EpisodesHistoryModalDataType,
    PatientProfileVariable,
    PatientProfileVariables,
    PatientProfileVariablesFilterType,
} from './shared/types';
import useStyles from './styles';

const convertDates = ({ data }: { data: PatientProfileVariables }): PatientProfileVariables => {
    // handle dates not being in the right format
    const handleDate = data.filter(i => i.value.date != null);
    if (handleDate.length) {
        const fixedDateRows = handleDate.map(row => {
            const fixedDate = row.value.date
                ? displayDate({
                      isoDateStr: row.value.date,
                  })
                : row.value.date;
            return {
                ...row,
                stringifiedValue: fixedDate,
                value: {
                    ...row.value,
                    date: fixedDate,
                },
            } as PatientProfileVariable;
        });
        return [...data.filter(row => row.value.date == null), ...fixedDateRows];
    }
    return data;
};
export const PROFILE_VALUES_TO_CONVERT_WEIGHT = new Set([
    'pre_pregnancy_weight',
    'BCNC_birth-weight',
    'BSW_birth-weight',
    'current_weight',
]);

export const getPVToEditCOnvertedWeight = (
    patientProfileVariables: PatientProfileVariable[],
    profileVariableToEditId: string,
    supportedWeightUnit: string,
): PatientProfileVariable => {
    const toEditPV = patientProfileVariables.find(
        profileVariable => profileVariable.profileVariableDefId === profileVariableToEditId,
    ) as PatientProfileVariable;
    const isWeightUnitSupported = PROFILE_VALUES_TO_CONVERT_WEIGHT.has(toEditPV.name);
    if (
        isWeightUnitSupported &&
        supportedWeightUnit === WEIGHT_UNIT_DEFAULT &&
        toEditPV.value.num
    ) {
        const parsedPV = {
            ...toEditPV,
            value: {
                // somehow this value is integer in edit modal control
                num: Math.round(toEditPV.value.num * LBS),
            },
        };
        return parsedPV;
    }
    return toEditPV;
};
const PatientProfileVariablesView: React.FC = () => {
    const { pagePermissions } = useUserPermissions();
    const { id: patientId } = useParams<{ id: string }>();
    const { classes } = useStyles();
    const [filter, setFilter] = useState<PatientProfileVariablesFilterType>({
        conditionName: '',
        categoryName: '',
        onlyWithValues: true,
    });
    const [systemGenerated, setSystemGenerated] = useState<boolean>(false);
    const [isFilterSidebarOpen, setFilterSidebarOpen] = useState<boolean>(true);
    const [profileVariableToEditId, setProfileVariableToEditId] = useState<string | null>(null);
    const [historyModalOpen, setHistoryModalOpen] = useState<boolean>(false);
    const [variableName, setVariableName] = useState<string>('');
    const [patientProfileVariable, setPatientProfileVariable] = useState<PatientProfileVariable>();
    const [episodesHistoryData, setEpisodesHistoryData] =
        useState<EpisodesHistoryModalDataType | null>(null);
    const [viewOnly, setViewOnly] = useState<boolean>(false);
    const tableRef = useRef<HTMLDivElement>(null);
    const [page, setPage] = useState<number>(0);
    const [rowsPerPage, setRowsPerPage] = useState<number>(50);
    const [supportedUnitName, setSupportedUnitName] = useState<string>(WEIGHT_UNIT_DEFAULT);
    const { data: userData } = useUser();

    const { data: { patientProfileVariables } = {}, loading: isPatientProfileVariablesLoading } =
        useFetchPatientProfileVariablesQuery({
            variables: { patientId: patientId ?? '', systemGenerated },
        });

    const { data: patientData, loading: patientLoading } = useFetchPatientByIdForPatientModalQuery({
        variables: { input: { id: patientId } },
    });

    const { data: profileValueKeyData } = useProfileKeysQuery({
        variables: {
            input: {
                filter: {
                    fields: {
                        patientId: patientId ?? '',
                    },
                },
            },
        },
    });

    const [
        fetchHistories,
        { data: profileValueHistories, refetch, loading: profileValueHistoriesLoading },
    ] = useFetchProfileValueHistoriesByProfileKeysForPatientLazyQuery();

    const variableHistory = useMemo(() => {
        return (
            (profileValueHistories?.getProfileValueHistoriesByProfileKeys?.filter(
                i => i?.profileVariableDefId === patientProfileVariable?.profileVariableDefId,
            ) as ProfileValueHistory[]) ?? []
        );
    }, [profileValueHistories, patientProfileVariable]);

    const handleDialogClose = () => {
        setProfileVariableToEditId(null);
        if (profileValueKeyData?.profileKeys.results && refetch) {
            refetch({
                keyIds: profileValueKeyData.profileKeys.results.map(key => key.id),
            });
        }
    };

    const handleCloseEpisodesHistoryModal = () => {
        setEpisodesHistoryData(null);
    };

    const handleCloseHistoryModal = () => {
        setHistoryModalOpen(false);
    };

    useEffect(() => {
        if (profileValueKeyData) {
            fetchHistories({
                variables: {
                    keyIds: profileValueKeyData.profileKeys.results.map(key => key.id),
                },
            });
        }
        if (userData?.currentUser) {
            const currentOrgWeightUnit =
                userData?.currentUser?.currentOrganization.organizationMeasurementConfig?.weight
                    ?.units;
            const rootOrgWeightUnit =
                userData?.currentUser?.rootOrg.organizationMeasurementConfig?.weight?.units;
            const weightUnitFromOrg = currentOrgWeightUnit ?? rootOrgWeightUnit;
            if (weightUnitFromOrg) {
                const weightUnit = weightUnitFromOrg ?? WEIGHT_UNIT_DEFAULT;
                setSupportedUnitName(weightUnit);
            }
        }
    }, [profileValueKeyData, fetchHistories, userData]);

    const handleViewHistory = (name: string, patientProfileVariable: PatientProfileVariable) => {
        setPatientProfileVariable(patientProfileVariable);
        setVariableName(name);

        setHistoryModalOpen(true);
    };

    const conditions = useMemo<string[]>(() => {
        return _.uniq(
            patientProfileVariables?.map(i => i.conditionName).filter(Boolean) as string[],
        );
    }, [patientProfileVariables]);

    const categories = useMemo(() => {
        return _.uniq(patientProfileVariables?.map(i => i.categoryName));
    }, [patientProfileVariables]);

    const tableData = useMemo(() => {
        return convertDates({
            data: patientProfileVariables ?? [],
        }).filter(t => {
            return [
                t.haPermission !== HaPermission.Hide,
                filter.categoryName ? t.categoryName.includes(filter.categoryName ?? '') : true,
                filter.conditionName ? t.conditionName?.includes(filter.conditionName ?? '') : true,
                filter.onlyWithValues ? Boolean(t.stringifiedValue) : true,
            ].every(Boolean);
        });
    }, [patientProfileVariables, filter]);

    if (isPatientProfileVariablesLoading || patientLoading) {
        return <Loading />;
    }

    const handleChangePage = (nextPageNumber: number, pageSize: number) => {
        setRowsPerPage(pageSize);
        setPage(nextPageNumber);

        if (tableRef.current) {
            tableRef.current.scrollIntoView();
        }
    };

    const handleChangeRowsPerPage = (pageSize: number) => {
        setRowsPerPage(pageSize);

        setTimeout(() => {
            if (tableRef.current) {
                tableRef?.current?.scrollIntoView({
                    behavior: 'smooth',
                });
            }
        }, 500);
    };
    return (
        <Grid container item direction="row" spacing={2} wrap="nowrap">
            <Grid item style={{ flex: 5 }} ref={tableRef}>
                <MaterialTable
                    title="Profile Variables"
                    actions={[
                        rowData => ({
                            icon: () =>
                                pagePermissions?.PatientProfileVariables.Edit ||
                                rowData.haPermission === HaPermission.Edit ? (
                                    <FontAwesomeIcon icon={faPenToSquare} />
                                ) : (
                                    <Info />
                                ),

                            tooltip:
                                pagePermissions?.PatientProfileVariables.Edit ||
                                rowData.haPermission === HaPermission.Edit
                                    ? 'Edit'
                                    : 'View',
                            onClick: (event, rowDataClick) => {
                                setProfileVariableToEditId(
                                    (rowDataClick as PatientProfileVariable).profileVariableDefId,
                                );
                                setViewOnly(
                                    !pagePermissions?.PatientProfileVariables.Edit &&
                                        rowData.haPermission !== HaPermission.Edit,
                                );
                            },
                        }),
                        {
                            icon: () => <Restore />,
                            tooltip: 'Profile Variable History',
                            onClick: (event, rowDataClick) => {
                                handleViewHistory(
                                    (rowDataClick as PatientProfileVariable).name,
                                    rowDataClick as PatientProfileVariable,
                                );
                            },
                        },
                        {
                            icon: () =>
                                isFilterSidebarOpen ? (
                                    <ChevronRight fontSize="small" />
                                ) : (
                                    <ChevronLeft fontSize="small" />
                                ),
                            tooltip: `${isFilterSidebarOpen ? 'Hide' : 'Show'} Filters`,
                            isFreeAction: true,
                            onClick: () => {
                                setFilterSidebarOpen(!isFilterSidebarOpen);
                            },
                        },
                    ]}
                    icons={tableIcons}
                    columns={[
                        {
                            title: 'Name',
                            field: 'name',
                        },
                        {
                            title: 'Value',
                            render: ({ stringifiedValue, value, name }) => {
                                const isWeightData = PROFILE_VALUES_TO_CONVERT_WEIGHT.has(name);
                                if (isWeightData && value.num) {
                                    return <WeightValue value={value.num} showUnit />;
                                }
                                return <Typography>{stringifiedValue}</Typography>;
                            },
                        },
                        {
                            title: 'Category',
                            field: 'categoryName',
                            searchable: false,
                            customSort: (a, b) => {
                                const current = a.categoryName.toLowerCase();
                                const next = b.categoryName.toLowerCase();

                                if (current < next) {
                                    return -1;
                                }
                                if (current > next) {
                                    return 1;
                                }
                                return 0;
                            },
                        },
                        {
                            title: 'Condition',
                            field: 'conditionName',
                            searchable: false,
                        },
                        {
                            title: 'Active Episode Start',
                            field: 'episodeStartDate',
                            searchable: false,
                            render: ({ episodeStartDate }) => {
                                return episodeStartDate
                                    ? displayDateWithAbbrTimeZone({
                                          isoDateStr: episodeStartDate,
                                          format: 'MM/DD/yyyy, hh:mm:ss A',
                                          timeZone:
                                              Intl.DateTimeFormat().resolvedOptions().timeZone,
                                      })
                                    : episodeStartDate;
                            },
                            customSort: (a, b) => {
                                const current = a?.episodeStartDate;
                                const next = b?.episodeStartDate;

                                if (current !== null && next !== null && current < next) {
                                    return -1;
                                }
                                if (current !== null && next !== null && current > next) {
                                    return 1;
                                }
                                return 0;
                            },
                        },
                        {
                            title: 'Episodes History',
                            searchable: false,
                            render: ({ conditionId, conditionName }) =>
                                conditionId && conditionName ? (
                                    <IconButton
                                        onClick={() => {
                                            setEpisodesHistoryData({
                                                conditionId,
                                                conditionName,
                                            });
                                        }}
                                        size="large"
                                    >
                                        <Restore />
                                    </IconButton>
                                ) : (
                                    ''
                                ),
                        },
                        {
                            title: 'Source',
                            field: 'sourceType',
                            searchable: false,
                        },
                    ]}
                    data={_.cloneDeep(tableData)}
                    localization={{ header: { actions: '' } }}
                    page={page}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    options={{
                        pageSize: rowsPerPage,
                        grouping: true,
                        pageSizeOptions: [25, 50, 100],
                    }}
                    isLoading={isPatientProfileVariablesLoading}
                />
            </Grid>
            <Grid
                item
                style={{
                    display: isFilterSidebarOpen ? 'block' : 'none',
                    flex: 1,
                }}
            >
                <Drawer
                    open={isFilterSidebarOpen}
                    variant="persistent"
                    PaperProps={{
                        className: classes.drawerPaper,
                    }}
                    anchor="right"
                    transitionDuration={{
                        enter: 0,
                        exit: 0,
                    }}
                >
                    <PatientSelectFilter
                        name="Condition"
                        value={filter?.conditionName ?? ''}
                        onChangeHandler={e => {
                            setFilter(cur => ({
                                ...cur,
                                conditionName: e.target.value as string,
                            }));
                        }}
                    >
                        <MenuItem value="">None</MenuItem>
                        {_(conditions)
                            .sortBy()
                            .map(conditionName => (
                                <MenuItem key={conditionName} value={conditionName}>
                                    {conditionName}
                                </MenuItem>
                            ))
                            .value()}
                    </PatientSelectFilter>
                    <PatientSelectFilter
                        name="Category"
                        value={filter?.categoryName ?? ''}
                        onChangeHandler={e => {
                            setFilter(cur => ({
                                ...cur,
                                categoryName: e.target.value as string,
                            }));
                        }}
                    >
                        <MenuItem value="">None</MenuItem>
                        {_(categories)
                            .sortBy()
                            .map(categoryName => (
                                <MenuItem key={categoryName} value={categoryName}>
                                    {categoryName}
                                </MenuItem>
                            ))
                            .value()}
                    </PatientSelectFilter>
                    <FormControlLabel
                        style={{
                            paddingLeft: '8px',
                        }}
                        control={
                            <Checkbox
                                onChange={e => {
                                    setFilter(cur => ({
                                        ...cur,
                                        onlyWithValues: e.target.checked,
                                    }));
                                }}
                                checked={filter.onlyWithValues}
                                size="small"
                            />
                        }
                        label="Only show variables with values"
                    />
                    <FormControlLabel
                        style={{
                            paddingLeft: '8px',
                            paddingTop: '8px',
                        }}
                        control={
                            <Checkbox
                                onChange={e => {
                                    setSystemGenerated(e.target.checked);
                                }}
                                checked={systemGenerated}
                                size="small"
                            />
                        }
                        label="Show System Generated Variables"
                    />
                </Drawer>
            </Grid>
            {patientId &&
                profileVariableToEditId &&
                patientProfileVariables &&
                patientData?.patient?.appBundleId && (
                    <Dialog
                        scroll="paper"
                        open={Boolean(profileVariableToEditId)}
                        onClose={handleDialogClose}
                        fullWidth
                    >
                        <DialogTitleWithClose id="a" onClose={handleDialogClose}>
                            Edit Profile Value
                        </DialogTitleWithClose>
                        <PatientProfileVariableModal
                            patientProfileVariable={getPVToEditCOnvertedWeight(
                                patientProfileVariables,
                                profileVariableToEditId,
                                supportedUnitName,
                            )}
                            patientId={patientId}
                            systemGenerated={systemGenerated}
                            closeHandler={handleDialogClose}
                            viewOnly={viewOnly}
                            appBundleId={patientData?.patient?.appBundleId}
                        />
                    </Dialog>
                )}
            {episodesHistoryData && (
                <Dialog
                    scroll="paper"
                    open={Boolean(episodesHistoryData)}
                    onClose={handleCloseEpisodesHistoryModal}
                    fullWidth
                >
                    <DialogTitleWithClose
                        id="episode-history-modal"
                        onClose={handleCloseEpisodesHistoryModal}
                    >
                        Episode History for &apos;{episodesHistoryData.conditionName}&apos;
                    </DialogTitleWithClose>

                    <PatientProfileVariableEpisodesModal
                        episodesHistoryData={episodesHistoryData}
                        closeHandler={handleCloseEpisodesHistoryModal}
                    />
                </Dialog>
            )}
            {historyModalOpen && (
                <Dialog
                    scroll="paper"
                    open={historyModalOpen}
                    onClose={handleCloseHistoryModal}
                    fullWidth
                >
                    <DialogTitleWithClose id="pv-history-modal" onClose={handleCloseHistoryModal}>
                        Profile Variable History for &apos;{variableName}&apos;
                    </DialogTitleWithClose>
                    {profileValueHistoriesLoading ? (
                        <Loading />
                    ) : (
                        <PatientProfileVariableHistoryModal
                            variableHistories={variableHistory}
                            patientProfileVariable={
                                patientProfileVariable as PatientProfileVariable
                            }
                            onClose={handleCloseHistoryModal}
                        />
                    )}
                </Dialog>
            )}
        </Grid>
    );
};

export default PatientProfileVariablesView;
