import * as Yup from 'yup';

export const CREATE_NEW_TYPE = 'Create New Action Type';
export const ADVOCATE_TASK_SCHEMA = Yup.object().shape({
    label: Yup.string().required('Label is required'),
    description: Yup.string().required('Label is required'),
    newType: Yup.string().when('typeId', ([typeId], schema) => {
        return typeId === CREATE_NEW_TYPE ? schema.required('Type is required') : schema;
    }),
    typeId: Yup.string().required('Type is required'),
    dueDate: Yup.date().required('Date To Completed is required'),
    displayDate: Yup.date().required('Date To Show is required'),
});
