import {
    Button,
    Card,
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    Typography,
} from '@mui/material';
import { ArrowBack } from '@mui/icons-material';
import React from 'react';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import OutlinedSection from '~/components/OutlinedSection/OutlinedSection';
import { JsonViewer } from '@textea/json-viewer';
import { useFetchEligibilityCheckResultForEligibilityCheckResultQuery } from '~/schemaTypes';
import Loading from '~/components/Loading/Loading';

const EligibilityCheckResult = () => {
    const history = useNavigate();
    const location = useLocation();

    const { id } = useParams<{ id: string }>();

    const { data, loading } = useFetchEligibilityCheckResultForEligibilityCheckResultQuery({
        variables: { input: { id } },
    });

    const handleBackClick = () => {
        history(-1);
    };

    if (loading) {
        return <Loading />;
    }

    const eligibilityCheckResult = data?.eligibilityCheckResult?.data;

    const getBackButtonLabel = () => {
        const fromPathname = location.state?.from?.pathname;

        if (fromPathname?.includes('eligibility-configs')) {
            return 'Back to Eligibility Configs';
        }

        return 'Back to Eligibility Results';
    };

    return (
        <Grid container spacing={2}>
            <Grid item xs={12}>
                <Button component={Link} startIcon={<ArrowBack />} onClick={handleBackClick}>
                    {getBackButtonLabel()}
                </Button>
            </Grid>
            <Card>
                <Typography variant="h6" paragraph>
                    Eligibility Check Result
                </Typography>
                <Grid container>
                    <Grid item xs={4}>
                        <TextField
                            label="App Bundle Id"
                            variant="outlined"
                            fullWidth
                            value={eligibilityCheckResult?.appBundleId ?? ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Unit Id"
                            variant="outlined"
                            fullWidth
                            value={eligibilityCheckResult?.unitId ?? ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Config Version"
                            variant="outlined"
                            fullWidth
                            value={eligibilityCheckResult?.configVersion ?? ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="First Name"
                            variant="outlined"
                            fullWidth
                            value={eligibilityCheckResult?.firstName ?? ''}
                            disabled
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <TextField
                            label="Last Name"
                            variant="outlined"
                            fullWidth
                            value={eligibilityCheckResult?.lastName ?? ''}
                            disabled
                        />
                    </Grid>
                    <FormControlLabel
                        control={
                            <Checkbox checked={eligibilityCheckResult?.success ?? false} disabled />
                        }
                        label="Is Success"
                    />
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={eligibilityCheckResult?.isEligible ?? false}
                                disabled
                            />
                        }
                        label="Is Eligible"
                    />

                    <OutlinedSection title="Request Body">
                        <JsonViewer
                            value={JSON.parse(eligibilityCheckResult?.requestBody ?? '{}')}
                        />
                    </OutlinedSection>
                    <OutlinedSection title="Response Body">
                        <JsonViewer
                            value={JSON.parse(eligibilityCheckResult?.responseBody ?? '{}')}
                        />
                    </OutlinedSection>
                    <OutlinedSection title="Logs">
                        <ul>
                            {eligibilityCheckResult?.logs?.map(log => (
                                <li key={log}>{log}</li>
                            ))}
                        </ul>
                    </OutlinedSection>
                </Grid>
            </Card>
        </Grid>
    );
};

export default EligibilityCheckResult;
