import React from 'react';
import moment from 'moment/moment';
import { Button, Grid } from '@mui/material';
import { AwayModeStatus } from './enums';
import { DATE_FORMAT } from './constants';
import { AwaySign, ScheduledSign } from './icons';

type VacationModeProps = {
    mode: AwayModeStatus;
    startAwayModeDate?: Date;
    endAwayModeDate?: Date;
    onClick?: () => void;
};

type ButtonContentProps = {
    firstLine: React.ReactNode;
    secondLine?: React.ReactNode;
    columnFlow?: boolean;
};

const ButtonContent: React.FC<ButtonContentProps> = props => {
    const { firstLine, secondLine, columnFlow = false } = props;
    return (
        <Grid container flexDirection={columnFlow ? 'column' : 'row'} justifyContent="center">
            <Grid item pr={1}>
                {firstLine}
            </Grid>
            {secondLine && <Grid item>{secondLine}</Grid>}
        </Grid>
    );
};

const AwayModeStatusButton: React.FC<VacationModeProps> = props => {
    const { mode, endAwayModeDate, startAwayModeDate, onClick } = props;
    if (mode === AwayModeStatus.off) {
        return (
            <Button variant="outlined" onClick={onClick}>
                <ButtonContent firstLine={<>Away Mode is: OFF</>} />
            </Button>
        );
    }
    if (mode === AwayModeStatus.vacation) {
        return (
            <Button variant="contained" onClick={onClick}>
                <ButtonContent
                    firstLine={
                        <>
                            <AwaySign /> Away from{' '}
                        </>
                    }
                    secondLine={
                        <>
                            {moment(startAwayModeDate).format(DATE_FORMAT)} -{' '}
                            {moment(endAwayModeDate).format(DATE_FORMAT)}
                        </>
                    }
                    columnFlow
                />
            </Button>
        );
    }
    return (
        <Button variant="contained" color="success" onClick={onClick}>
            <ButtonContent
                firstLine={
                    <>
                        <ScheduledSign /> Away Scheduled
                    </>
                }
                secondLine={
                    <>
                        {moment(startAwayModeDate).format(DATE_FORMAT)} -{' '}
                        {moment(endAwayModeDate).format(DATE_FORMAT)}
                    </>
                }
                columnFlow
            />
        </Button>
    );
};

export default AwayModeStatusButton;
