import React from 'react';
import { GetResolvedTagsForPatientTranscriptQuery } from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type ResolvedTag = NonNullable<
    NonNullable<
        GetResolvedTagsForPatientTranscriptQuery['getResolvedTagsForPatientTranscript']
    >['items']
>[number];

export const ResolvedTagEventCard = ({ resolvedTagEvent }: { resolvedTagEvent: ResolvedTag }) => {
    return (
        <BaseCard title="Resolved Tag Event" data={resolvedTagEvent}>
            <p className="m-0">{`${resolvedTagEvent?.tag?.name} resolved to ${resolvedTagEvent?.event?.data?.state}`}</p>
        </BaseCard>
    );
};
