import React from 'react';
import { GetTriggerInterventionSendMessageCenterForPatientTranscriptQuery } from '~/schemaTypes';
import { BaseCard } from '../BaseCard';

type TriggerInterventionSendMessageCenter = NonNullable<
    NonNullable<
        GetTriggerInterventionSendMessageCenterForPatientTranscriptQuery['getTriggerInterventionSendMessageCenterForPatientTranscript']
    >['items']
>[number];

export const TriggerInterventionSendMessageCenterCard = ({
    intervention,
}: {
    intervention: TriggerInterventionSendMessageCenter;
}) => {
    return (
        <BaseCard title="Trigger Intervention Send Message" data={intervention}>
            <p className="m-0">
                {`Title: ${intervention?.messageCenterTemplate?.title || 'Unknown'}`}
            </p>
            <p className="m-0">
                {`Content: ${intervention?.messageCenterTemplate?.content?.en || 'Unknown'}`}
            </p>
        </BaseCard>
    );
};
