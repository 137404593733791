import React from 'react';
import _ from 'lodash';
import { useFetchEligibilityConfigForEligibilityConfigViewQuery } from '~/schemaTypes';
import { useParams } from 'react-router-dom';
import Loading from '~/components/Loading/Loading';
import EligibilityConfigForm from './EligibilityConfigForm/EligibilityConfigForm';

const deepCleanNullFields = <T extends Record<string, any> | any[]>(obj: T): T => {
    return _.cloneDeepWith(obj, value => {
        if (_.isArray(value)) {
            return value.map(deepCleanNullFields);
        }
        if (_.isObject(value)) {
            return _.omitBy(_.mapValues(value, deepCleanNullFields), _.isNull);
        }
        return value;
    });
};

function deepClenByFieldValue<T>(obj: T, fieldName: string): T {
    if (Array.isArray(obj)) {
        return obj.map(item => deepClenByFieldValue(item, fieldName)) as T;
    }
    if (typeof obj === 'object' && obj !== null) {
        return Object.fromEntries(
            Object.entries(obj)
                .filter(([key]) => key !== fieldName)
                .map(([key, value]) => [key, deepClenByFieldValue(value, fieldName)]),
        ) as T;
    }
    return obj;
}

const EligibilityConfig = () => {
    const { id } = useParams<{ id: string }>();
    const { data, loading } = useFetchEligibilityConfigForEligibilityConfigViewQuery({
        variables: {
            input: {
                id,
            },
        },
        fetchPolicy: 'network-only',
    });

    if (loading) {
        return <Loading />;
    }

    if (!data?.eligibilityConfig?.data || !id) {
        return <div>Eligibility Config not found</div>;
    }

    const eligibilityConfig = deepClenByFieldValue(
        deepCleanNullFields(data.eligibilityConfig.data),
        '__typename',
    );

    return <EligibilityConfigForm id={id} data={eligibilityConfig} />;
};

export default EligibilityConfig;
