import { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProcessingError } from '~/views/AffiliateClaimSubmission/types';
import {
    useClaimsBuilderCreateAffiliateClaimMutation,
    useCreateDoulaClaimFromBuilderMutation,
    useCreateLcClaimFromBuilderMutation,
    AlertSeverity,
} from '~/schemaTypes';
import { useVcUserView, useUser } from '~/hooks';
import { SuppressNextGlobalAlert, TriggerGlobalAlert } from '~/state';
import { TabsId } from '~/views/AffiliateCare/Claims/Claims';
import {
    getAffiliateClaimsPayload,
    getDoulaClaimsPayload,
} from '~/views/AffiliateCare/ClaimsBuilder/helpers';
import { Claim } from './types';

type UseClaimBuilderProps = {
    portalView: boolean;
    affiliateId?: string;
};

const showNoValidationError = (error: string) => {
    SuppressNextGlobalAlert(false);
    TriggerGlobalAlert({
        severity: AlertSeverity.Error,
        message: error,
    });
};

const useClaimBuilder = (props: UseClaimBuilderProps) => {
    const { portalView, affiliateId } = props;
    const { isDoulaView, isLcView, getUrlWithViewPrefix } = useVcUserView();
    const { data: userData } = useUser();
    const history = useNavigate();
    const [error, setError] = useState<ProcessingError>(null);
    const [createClaims, { loading: generalClaimLoading }] =
        useClaimsBuilderCreateAffiliateClaimMutation({
            onCompleted: response => {
                if (response.createAffiliateClaimFromBuilder?.success) {
                    if (portalView) {
                        history(`/app-config/vcaffiliates/claims/${affiliateId}`);
                    } else {
                        history('/affiliate-care-portal/claims', { state: TabsId.Patients });
                    }
                } else if (response.createAffiliateClaimFromBuilder?.error) {
                    setError(response.createAffiliateClaimFromBuilder.error);
                } else if (response.createAffiliateClaimFromBuilder?.message) {
                    showNoValidationError(response.createAffiliateClaimFromBuilder.message);
                }
            },
        });
    const [createDoulaClaims, { loading: createDoulaLoading }] =
        useCreateDoulaClaimFromBuilderMutation({
            onCompleted: response => {
                if (response.createDoulaClaimFromBuilder?.success) {
                    history(getUrlWithViewPrefix('/portal/claims'));
                } else if (response.createDoulaClaimFromBuilder?.error) {
                    setError(response.createDoulaClaimFromBuilder.error);
                } else if (response.createDoulaClaimFromBuilder?.message) {
                    showNoValidationError(response.createDoulaClaimFromBuilder.message);
                }
            },
        });
    const [createLcClaims, { loading: createLcLoading }] = useCreateLcClaimFromBuilderMutation({
        onCompleted: response => {
            if (response.createLCClaimFromBuilder?.success) {
                history(getUrlWithViewPrefix('/portal/claims'));
            } else if (response.createLCClaimFromBuilder?.error) {
                setError(response.createLCClaimFromBuilder.error);
            } else if (response.createLCClaimFromBuilder?.message) {
                showNoValidationError(response.createLCClaimFromBuilder.message);
            }
        },
    });
    const createClaim = useCallback(
        (claims: Claim[]) => {
            setError(null);
            SuppressNextGlobalAlert(true);
            if (isDoulaView) {
                const doulaPayload = {
                    userId: userData?.currentUser?.id,
                    claims: getDoulaClaimsPayload(claims),
                };
                createDoulaClaims({
                    variables: {
                        input: doulaPayload,
                    },
                });
            } else if (isLcView) {
                const lcPayload = {
                    userId: userData?.currentUser?.id,
                    claims: getAffiliateClaimsPayload(claims),
                };
                createLcClaims({
                    variables: {
                        input: lcPayload,
                    },
                });
            } else {
                const affiliatePayload = {
                    affiliateId,
                    claims: getAffiliateClaimsPayload(claims),
                };
                createClaims({
                    variables: {
                        input: affiliatePayload,
                    },
                });
            }
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [affiliateId, isLcView, isDoulaView, userData],
    );
    const loading = createLcLoading || createDoulaLoading || generalClaimLoading;
    return { createClaim, error, loading };
};

export default useClaimBuilder;
