import React from 'react';
import { Grid, Typography } from '@mui/material';

type SectionHeaderProps = {
    title: string;
};

const SectionHeader: React.FC<SectionHeaderProps> = props => {
    const { title } = props;
    return (
        <Grid item xs={12} container alignItems="center" spacing={1}>
            <Grid item>
                <Typography color="primary" variant="h6" align="center">
                    {title}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default SectionHeader;
