import React from 'react';
import { Grid, Typography } from '@mui/material';
import RecheckEligibilityButton from '~/views/AffiliateCare/components/RecheckEligibilityButton/RecheckEligibilityButton';
import { EligibilityRefreshOrigin, PatientInsurancePlan } from '~/schemaTypes';

type InsuranceOverviewProps = {
    insurancePlanName: string;
    activeInsurancePlan: PatientInsurancePlan | null;
    isEligible: boolean;
    portalView: boolean;
    patientId: string;
    affiliateId?: string;
};

const InsuranceOverview: React.FC<InsuranceOverviewProps> = props => {
    const {
        insurancePlanName,
        activeInsurancePlan,
        isEligible,
        portalView,
        patientId,
        affiliateId,
    } = props;
    return (
        <>
            <Grid item xs={6}>
                <Typography color="primary" gutterBottom variant="body1">
                    Insurance Plan Name:
                </Typography>
                <Typography variant="subtitle1">{insurancePlanName}</Typography>
            </Grid>
            <Grid container item xs={3}>
                <Grid
                    item
                    sx={{
                        marginX: -2,
                        marginY: -1,
                        paddingX: 2,
                        paddingY: 1,
                        ...(isEligible ? {} : { backgroundColor: '#f2dada' }),
                    }}
                >
                    <Typography color="primary" gutterBottom variant="body1">
                        Insurance Eligibility Dates:
                    </Typography>
                    <Grid container alignItems="center">
                        <Typography variant="subtitle1">
                            {activeInsurancePlan
                                ? `${activeInsurancePlan.eligibilityStartDate} - ${activeInsurancePlan.eligibilityEndDate}`
                                : 'None'}
                        </Typography>
                        {isEligible || (
                            <RecheckEligibilityButton
                                origin={
                                    portalView
                                        ? EligibilityRefreshOrigin.AdminPortal
                                        : EligibilityRefreshOrigin.AffiliatePortal
                                }
                                patientId={patientId}
                                affiliateId={affiliateId}
                            />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </>
    );
};

export default InsuranceOverview;
