import {
    useFetchCurrentUserForStaffModalQuery,
    useFetchOrganizationsFromCurrentUserQuery,
    useFetchOrgListForReferenceLinkPageQuery,
} from '~/schemaTypes';

const useOrgFetch = () => {
    const { data: currentUserQuery, loading: currentUserLoading } =
        useFetchCurrentUserForStaffModalQuery();
    const { data: allOrgsData, loading: allOrgDataLoading } =
        useFetchOrgListForReferenceLinkPageQuery();
    const { data: orgsData, loading: orgsDataLoading } =
        useFetchOrganizationsFromCurrentUserQuery();
    return {
        currentUserQuery,
        allOrgsData,
        orgsData,
        loading: currentUserLoading || allOrgDataLoading || orgsDataLoading,
    };
};

export { useOrgFetch };
