import { FetchSurveyResponsesExpandedForPatientSurveyPageQuery } from '~/schemaTypes';

export type SurveyResponseExpandedResult = NonNullable<
    NonNullable<FetchSurveyResponsesExpandedForPatientSurveyPageQuery['surveyResponseExpandedsV2']>
>['results'][0];

const FLAGGED_SURVEY_TYPES = new Set([
    'Postpartum',
    'EPDS Survey',
    'Cigna_EPDS Postpartum Survey',
    'Axia_EPDS Survey',
    'BCNC_EPDS Survey',
    'IEHP_EPDS Postpartum Survey',
    // all the above types are deprecated and are here for backawrd compatibility
    'EPDS',
]);

const EPDS_SELF_HARM_QUESTION_IDS = new Set(['Self-Harm', 'Harming yourself', 'Thoughts']);

const EPDS_SELF_HARM_RIGHT_ANSWERS = new Set(['Never', 'Not at all']);

function removeQuotes(input: string): string {
    return input.replace(/^["']|["']$/g, '');
}

const epdsScoreThreshold = 10;

export const shouldFlagSurvey = (survey: SurveyResponseExpandedResult): boolean => {
    const { surveyScore, surveyType, answers } = survey;
    if (answers && Array.isArray(answers) && surveyType) {
        const isFlaggedSurvey = FLAGGED_SURVEY_TYPES.has(surveyType);

        if (!isFlaggedSurvey) {
            return false;
        }

        const isAboveThreshold = surveyScore ? surveyScore >= epdsScoreThreshold : false;

        const selfHarmAnswerRaw = answers.find(({ questionId }) =>
            questionId ? EPDS_SELF_HARM_QUESTION_IDS.has(questionId) : false,
        );
        if (
            !selfHarmAnswerRaw ||
            selfHarmAnswerRaw.answerText === 'no value was provided by this ProfileVariable'
        ) {
            return isAboveThreshold;
        }
        const selfHarmAnswer = removeQuotes(selfHarmAnswerRaw?.answerText ?? '');
        const isSelfHarmAnswer = selfHarmAnswerRaw
            ? !EPDS_SELF_HARM_RIGHT_ANSWERS.has(selfHarmAnswer)
            : false;

        if (isSelfHarmAnswer || isAboveThreshold) {
            return true;
        }

        return false;
    }
    return false;
};
