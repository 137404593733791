import React from 'react';
import { Grid, Typography } from '@mui/material';
import { AffiliateCarePatientQuery } from '~/schemaTypes';
import { format, toDate } from 'date-fns-tz';

type BabySectionProps = {
    patient?: AffiliateCarePatientQuery;
    fullView?: boolean;
};

const BabySection: React.FC<BabySectionProps> = props => {
    const { patient, fullView = false } = props;
    const virtualCareBabyData = patient?.patient?.virtualCareBabyData;
    const babyArrived = virtualCareBabyData?.arrived;
    const shouldDisplayBabyDate = babyArrived !== null && babyArrived !== undefined;
    const babyDate = babyArrived ? virtualCareBabyData?.birthDate : virtualCareBabyData?.dueDate;
    if (!fullView && !shouldDisplayBabyDate) {
        return null;
    }
    return fullView ? (
        <Grid item xs={12} container>
            <Grid item xs={3}>
                <Typography color="primary" gutterBottom variant="body1">
                    Due date:
                </Typography>
                <Typography variant="subtitle1">
                    {patient?.patient?.virtualCareBabyData?.dueDate
                        ? format(
                              toDate(patient?.patient?.virtualCareBabyData?.dueDate.split('T')[0]),
                              'MM/dd/yyyy',
                          )
                        : 'N/A'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography color="primary" gutterBottom variant="body1">
                    Birth date:
                </Typography>
                <Typography variant="subtitle1">
                    {patient?.patient?.virtualCareBabyData?.birthDate
                        ? format(
                              toDate(
                                  patient?.patient?.virtualCareBabyData?.birthDate.split('T')[0],
                              ),
                              'MM/dd/yyyy',
                          )
                        : 'N/A'}
                </Typography>
            </Grid>
            <Grid item xs={3}>
                <Typography color="primary" gutterBottom variant="body1">
                    Multiples:
                </Typography>
                <Typography variant="subtitle1">
                    {patient?.patient?.virtualCareBabyData?.multiples ? 'Yes' : 'No'}
                </Typography>
            </Grid>
        </Grid>
    ) : (
        <Grid item xs={12} container>
            <Grid item xs={3}>
                <Typography color="primary" gutterBottom variant="body1">
                    {babyArrived ? 'Baby Birth Date:' : 'Baby Estimated Due Date:'}
                </Typography>
                <Typography variant="subtitle1">
                    {babyDate && format(toDate(babyDate.split('T')[0]), 'MM/dd/yyyy')}
                </Typography>
            </Grid>
        </Grid>
    );
};

export default BabySection;
