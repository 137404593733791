import React, { useState } from 'react';
import {
    Card,
    Grid,
    Typography,
    Button,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    Dialog,
    DialogTitle,
    DialogContent,
    Backdrop,
    CircularProgress,
} from '@mui/material';
import { useParams, useNavigate } from 'react-router-dom';
import {
    AlertSeverity,
    useFetchPatientForMergeQuery,
    useMergePatientsForPatientPageMutation,
    Patient,
    useFetchProfileDefsForMergeQuery,
    ProfileValueType,
    FacadeCollectionName,
    PatientMergeInput,
    AddressInput,
    FetchPatientForPatientSummaryPageQueryDocument,
    UserProfileValueType,
} from '~/schemaTypes';
import { TriggerGlobalAlert } from '~/state';
import PatientSearchModal from '~/components/PatientsSearchModal';
import ObjectID from 'bson-objectid';
import { displayDateWithLocalTimeZoneAbbr } from '~/helpers';
import { IS_VBC_ELIGIBLE, PATIENT_CREATED_BY_EXTERNAL_DATA_SOURCE_REQUEST } from '~/constants';
import MergeSection from './MergeSection/MergeSection';
import { renderCustomInput } from './RenderCustomInput/RenderCustomInput';
import { getFacadeCollectionRows } from './GetRows/FacadeCollectionRows';
import { displayAttribute, getPatientAttributeRows } from './GetRows/PatientAttributeRows';

export interface MergeRow {
    attribute: string;
    attributeDisplay?: string;
    sourceValue: string | null;
    targetValue: string | null;
    finalValue: string;
    selectedOption: 'source' | 'target' | 'custom' | 'merge' | 'donotmerge';
    facadeCollection?: FacadeCollectionName | null;
    isPatientAttribute?: boolean;
    isProfileValue?: boolean;
    isFacadeCollection?: boolean;
}

const redoxConnectionAttributes = ['redoxSourceId', 'redoxEhrId', 'externalId'];

const getIsRedoxConnectionMoved = (mergeRows: MergeRow[]) => {
    const redoxConnection = mergeRows.filter(
        i => i.isPatientAttribute && redoxConnectionAttributes.includes(i.attribute),
    );

    if (!redoxConnection.length) return false;

    return redoxConnection.every(i => i.sourceValue && i.sourceValue === i.finalValue);
};

const MergePatient: React.FC = () => {
    const { id } = useParams<{ id: string }>();
    const navigate = useNavigate();
    const [sourcePatientId, setSourcePatientId] = useState<string | null>(null);
    const [mergeRows, setMergeRows] = useState<MergeRow[]>([]);
    const [showPatientSearchModal, setShowPatientSearchModal] = useState(false);
    const [patientsList, setPatientsList] = useState<Patient[]>([]);
    const [totalPatientsCount, setTotalPatientsCount] = useState(0);
    const [selectedPatient, setSelectedPatient] = useState<Patient | null>(null);
    const [patientsNotFound, setPatientsNotFound] = useState(false);
    const [groupSelections, setGroupSelections] = useState<
        Record<string, 'source' | 'target' | 'custom' | 'merge' | 'donotmerge'>
    >({
        'Patient Attributes': 'target',
        'Profile Values': 'target',
    });
    const [expandedSections, setExpandedSections] = useState({
        profileValues: true,
        patientAttributes: true,
        facadeCollections: true,
    });
    const [showConfirmDialog, setShowConfirmDialog] = useState(false);
    const [showRedoxConnectionMovedDialog, setShowRedoxConnectionMovedDialog] = useState(false);
    const [defaultPatient, setDefaultPatient] = useState<'source' | 'target'>('target');

    const { data: targetPatientData } = useFetchPatientForMergeQuery({
        variables: { input: { id } },
        skip: !id,
    });

    const { data: profileDefsData } = useFetchProfileDefsForMergeQuery();

    const getProfileDefName = (id: string) => {
        return profileDefsData?.userProfileDefsV2.results?.find(def => def.id === id)?.name || id;
    };

    const [mergePatient, { loading: isMergePatientLoading }] =
        useMergePatientsForPatientPageMutation({
            onCompleted: data => {
                if (data?.patientMerge?.success) {
                    TriggerGlobalAlert({
                        severity: AlertSeverity.Success,
                        message: 'Patients merged successfully',
                    });
                    navigate(`/portal/patients/${id}`);
                }
            },
            awaitRefetchQueries: true,
            refetchQueries: [
                {
                    query: FetchPatientForPatientSummaryPageQueryDocument,
                    variables: { input: { id } },
                },
            ],
        });

    const handleSetMergeRow = (index: number | null, mergeRows: MergeRow[]) => {
        if (
            (index === null || redoxConnectionAttributes.includes(mergeRows[index]?.attribute)) &&
            getIsRedoxConnectionMoved(mergeRows)
        ) {
            setShowRedoxConnectionMovedDialog(true);
        }
    };

    const getProfileValueByType = (value: string, profileVarId: string) => {
        const profileDef = profileDefsData?.userProfileDefsV2.results?.find(
            def => def.id === profileVarId,
        );

        if (!profileDef) {
            return { type: ProfileValueType.Str, valueStr: value };
        }

        switch (profileDef.valueType) {
            case 'bool':
                return {
                    type: ProfileValueType.Bool,
                    valueBool: value.toLowerCase() === 'true',
                };
            case 'choice':
                return {
                    type: ProfileValueType.Choice,
                    valueId: value || null,
                };
            case 'choices':
                return {
                    type: ProfileValueType.Choices,
                    valueIds: value.split(','),
                };
            case 'date':
                return {
                    type: ProfileValueType.Date,
                    valueDate: value || null,
                };
            case 'num':
                return {
                    type: ProfileValueType.Num,
                    valueNum: parseFloat(value),
                };
            case 'str':
                return {
                    type: ProfileValueType.Str,
                    valueStr: value || null,
                };
            case 'localized':
                return {
                    type: ProfileValueType.Localized,
                    valueStr: value || null,
                };
            default:
                return {
                    type: ProfileValueType.Str,
                    valueStr: value || null,
                };
        }
    };

    const handleSourcePatientSelect = (patient: Patient) => {
        if (patient.id === id) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Info,
                message: 'Cannot select same patient',
            });
            return;
        }
        setSourcePatientId(patient.id);
        setSelectedPatient(patient);
        if (targetPatientData?.patient) {
            const patientDetailRows: MergeRow[] = getPatientAttributeRows({
                sourcePatient: patient,
                targetPatient: targetPatientData.patient,
            });

            // Get all unique profile variable IDs from both patients
            const sourceProfileVarIds = new Set(
                patient.profileValues?.map(pv => pv.profileVariableDefId) || [],
            );
            const targetProfileVarIds = new Set(
                targetPatientData.patient.profileValues?.map(pv => pv.profileVariableDefId) || [],
            );
            const allProfileVarIds = Array.from(
                new Set([...sourceProfileVarIds, ...targetProfileVarIds]),
            );
            // Create profile value rows for all variables
            const profileValueRows: MergeRow[] = allProfileVarIds.map(profileVarId => {
                const sourceProfileValue = patient.profileValues?.find(
                    pv => pv.profileVariableDefId === profileVarId,
                );
                const targetProfileValue = targetPatientData.patient?.profileValues?.find(
                    pv => pv.profileVariableDefId === profileVarId,
                );

                const sourceValue = sourceProfileValue
                    ? sourceProfileValue.valueStr ||
                      sourceProfileValue.valueNum?.toString() ||
                      sourceProfileValue.valueDate ||
                      sourceProfileValue.valueBool?.toString() ||
                      sourceProfileValue?.valueId
                    : '';

                const targetValue = targetProfileValue
                    ? targetProfileValue.valueStr ||
                      targetProfileValue.valueNum?.toString() ||
                      targetProfileValue.valueDate ||
                      targetProfileValue.valueBool?.toString() ||
                      targetProfileValue?.valueId
                    : '';

                return {
                    attribute: profileVarId,
                    attributeDisplay: getProfileDefName(profileVarId),
                    sourceValue: sourceValue || '',
                    targetValue: targetValue || '',
                    finalValue: targetValue || '', // Default to target value
                    selectedOption: 'target' as const,
                    isProfileValue: true,
                };
            });

            // Sort profile values alphabetically by display name
            profileValueRows.sort((a, b) =>
                (a.attributeDisplay || '').localeCompare(b.attributeDisplay || ''),
            );

            const facadeCollectionRows = getFacadeCollectionRows();

            const initialMergeRows: MergeRow[] = [
                ...patientDetailRows,
                {
                    attribute: 'Profile Values',
                    attributeDisplay: 'Profile Values',
                    sourceValue: '',
                    targetValue: '',
                    finalValue: '',
                    selectedOption: 'target',
                },
                ...profileValueRows,
                {
                    attribute: 'Facade Collections',
                    attributeDisplay: 'Facade Collections',
                    sourceValue: '',
                    targetValue: '',
                    finalValue: '',
                    selectedOption: 'target',
                    isFacadeCollection: true,
                },
                ...facadeCollectionRows,
            ];

            setMergeRows(initialMergeRows);
        }
    };

    const handleFinalValueChange = (index: number, value: string) => {
        const updatedRows = [...mergeRows];
        updatedRows[index].finalValue = value;
        updatedRows[index].selectedOption = 'custom' as const;
        setMergeRows(updatedRows);
        handleSetMergeRow(index, updatedRows);
    };

    const handleOptionChange = (
        index: number,
        option: 'source' | 'target' | 'custom' | 'merge' | 'donotmerge',
    ) => {
        const updatedRows = [...mergeRows];
        const row = updatedRows[index];

        if (!row) {
            return;
        }

        switch (option) {
            case 'source':
                row.finalValue = row.sourceValue || '';
                break;
            case 'target':
                row.finalValue = row.targetValue || '';
                break;
            case 'custom':
                row.finalValue = '';
                break;
            case 'merge':
            case 'donotmerge':
                row.finalValue = option;
                break;
            default:
                break;
        }

        row.selectedOption = option;
        setMergeRows(updatedRows);
        handleSetMergeRow(index, updatedRows);
    };

    const handleGroupSelectionChange = (
        group: string,
        value: 'source' | 'target' | 'merge' | 'donotmerge' | 'custom',
    ) => {
        const updatedRows = [...mergeRows];
        updatedRows.forEach(row => {
            if (group === 'Patient Attributes' && row.isPatientAttribute) {
                row.selectedOption = value;
                row.finalValue = value === 'source' ? row.sourceValue || '' : row.targetValue || '';
            }
            if (group === 'Profile Values' && row.isProfileValue) {
                row.selectedOption = value;
                row.finalValue = value === 'source' ? row.sourceValue || '' : row.targetValue || '';
            }
            if (group === 'Facade Collections' && row.isFacadeCollection) {
                row.selectedOption = value;
                row.finalValue = value === 'merge' ? 'merge' : 'donotmerge';
            }
        });
        setMergeRows(updatedRows);
        handleSetMergeRow(null, updatedRows);
        setGroupSelections(prev => ({ ...prev, [group]: value }));
    };

    const handleMerge = async () => {
        setShowConfirmDialog(false);

        try {
            if (!id || !sourcePatientId) {
                TriggerGlobalAlert({
                    severity: AlertSeverity.Error,
                    message: `id or sourcePatientId was not found.`,
                });
                return;
            }

            const unFormatAddress = (addressStr: string): AddressInput | null => {
                if (!addressStr) return null;
                const [street1, city, state, code] = addressStr.split(',').map(part => part.trim());
                return {
                    street1: street1 || '',
                    city: city || '',
                    state: state || '',
                    code: code || '',
                };
            };
            let practiceId: ObjectID | undefined;
            const practiceIdFinalValue = mergeRows.find(
                row => row.attribute === 'practiceId',
            )?.finalValue;
            if (practiceIdFinalValue != null) {
                practiceId = new ObjectID(practiceIdFinalValue);
            }

            const finalValues = {
                firstName: mergeRows.find(row => row.attribute === 'firstName')?.finalValue,
                lastName: mergeRows.find(row => row.attribute === 'lastName')?.finalValue,
                email: mergeRows.find(row => row.attribute === 'email')?.finalValue,
                phoneNumber: mergeRows.find(row => row.attribute === 'phoneNumber')?.finalValue,
                insuranceId: mergeRows.find(row => row.attribute === 'insuranceId')?.finalValue,
                insuranceName: mergeRows.find(row => row.attribute === 'insuranceName')?.finalValue,
                memberId: mergeRows.find(row => row.attribute === 'memberId')?.finalValue,
                groupNumber: mergeRows.find(row => row.attribute === 'groupNumber')?.finalValue,
                mailingAddress: unFormatAddress(
                    mergeRows.find(row => row.attribute === 'mailingAddress')?.finalValue || '',
                ),
                birthDate: mergeRows.find(row => row.attribute === 'birthDate')?.finalValue,
                practiceId,
                hgPatientId: mergeRows.find(row => row.attribute === 'hgPatientId')?.finalValue,
                insuranceGroupNumber: mergeRows.find(
                    row => row.attribute === 'insuranceGroupNumber',
                )?.finalValue,
                insuredFirstName: mergeRows.find(row => row.attribute === 'insuredFirstName')
                    ?.finalValue,
                insuredLastName: mergeRows.find(row => row.attribute === 'insuredLastName')
                    ?.finalValue,
                insuredPhoneNumber: mergeRows.find(row => row.attribute === 'insuredPhoneNumber')
                    ?.finalValue,
                insuredAddress: unFormatAddress(
                    mergeRows.find(row => row.attribute === 'insuredAddress')?.finalValue || '',
                ),
                dueDate: mergeRows.find(row => row.attribute === 'dueDate')?.finalValue || null,
                deliveryDate:
                    mergeRows.find(row => row.attribute === 'deliveryDate')?.finalValue || null,
                gender: mergeRows.find(row => row.attribute === 'gender')?.finalValue,
                race: mergeRows.find(row => row.attribute === 'race')?.finalValue,
                patientRelationshipToInsured: mergeRows.find(
                    row => row.attribute === 'patientRelationshipToInsured',
                )?.finalValue,
                virtualCarePhoneNumber: mergeRows.find(
                    row => row.attribute === 'virtualCarePhoneNumber',
                )?.finalValue,
                redoxEhrId: mergeRows.find(row => row.attribute === 'redoxEhrId')?.finalValue,
                redoxSourceId: mergeRows.find(row => row.attribute === 'redoxSourceId')?.finalValue,
                externalId: mergeRows.find(row => row.attribute === 'externalId')?.finalValue,
                appBundleId: mergeRows.find(row => row.attribute === 'appBundleId')?.finalValue,
            } as Omit<PatientMergeInput, 'profileValues' | 'sourcePatientId' | 'targetPatientId'>;

            const profileValueRows = mergeRows.filter(
                row =>
                    row.isProfileValue &&
                    row.selectedOption !== 'target' &&
                    (row.sourceValue !== row.targetValue || row.selectedOption === 'custom'),
            );

            const facadeCollections = mergeRows
                .filter(row => row.facadeCollection != null && row.finalValue === 'merge')
                .map(row => row.facadeCollection) as FacadeCollectionName[];

            await mergePatient({
                variables: {
                    input: {
                        sourcePatientId,
                        targetPatientId: id,
                        defaultToSourcePatient: defaultPatient === 'source',
                        profileValues: profileValueRows.map(row => ({
                            profileVariableDefId: row.attribute,
                            ...getProfileValueByType(row.finalValue, row.attribute),
                        })),
                        collectionsToMerge: facadeCollections,
                        ...finalValues,
                    },
                },
            });
        } catch (error) {
            TriggerGlobalAlert({
                severity: AlertSeverity.Error,
                message: 'Failed to merge patients',
            });
        }
    };

    const handleMoveRedoxConnection = () => {
        const updatedRows = [...mergeRows];

        [
            {
                attribute: IS_VBC_ELIGIBLE,
                attributeDisplay: 'is_user_VBC_eligible',
            },
            {
                attribute: PATIENT_CREATED_BY_EXTERNAL_DATA_SOURCE_REQUEST,
                attributeDisplay: 'patient_created_by_external_data_source_request',
            },
        ].forEach(({ attribute, attributeDisplay }) => {
            const row = updatedRows.find(i => i.attribute === attribute);
            if (row) {
                row.selectedOption = 'custom';
                row.finalValue = 'true';
            } else {
                updatedRows.push({
                    attribute,
                    attributeDisplay,
                    finalValue: 'true',
                    isProfileValue: true,
                    selectedOption: 'custom',
                    sourceValue: null,
                    targetValue: null,
                });
            }
        });
        setMergeRows(updatedRows);
        setShowRedoxConnectionMovedDialog(false);
    };

    const handleDefaultPatientChange = (newDefault: 'source' | 'target') => {
        setDefaultPatient(newDefault);

        // Update merge rows
        const updatedRows = mergeRows.map(row => {
            if (row.isFacadeCollection || row.attribute === 'ID') return row;

            return {
                ...row,
                selectedOption: newDefault,
                finalValue: newDefault === 'source' ? row.sourceValue || '' : row.targetValue || '',
            };
        });

        // Update group selections
        const newGroupSelections = Object.keys(groupSelections).reduce((acc, group) => {
            if (group === 'Facade Collections') {
                return { ...acc, [group]: groupSelections['Facade Collection'] };
            }
            return { ...acc, [group]: newDefault };
        }, {});

        setMergeRows(updatedRows);
        handleSetMergeRow(null, updatedRows);
        setGroupSelections(newGroupSelections);
    };

    return (
        <>
            <Backdrop
                sx={{
                    color: '#fff',
                    zIndex: theme => theme.zIndex.drawer + 1,
                    display: 'flex',
                    flexDirection: 'column',
                    gap: 2,
                }}
                open={isMergePatientLoading}
            >
                <CircularProgress color="inherit" />
                <Typography variant="h5">Merging patients...</Typography>
            </Backdrop>

            <Card sx={{ p: 2 }}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant="h5">Merge Patient</Typography>
                    </Grid>

                    <Grid item xs={12} md={6}>
                        <Typography variant="h5">Destination</Typography>
                        <Typography variant="subtitle1">(Patient that will be kept)</Typography>
                        <Typography variant="h6">
                            {targetPatientData?.patient?.firstName}{' '}
                            {targetPatientData?.patient?.lastName}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <Typography variant="h5">Additional Source</Typography>
                        <Typography variant="subtitle1">
                            (Patient that will be merged and deleted)
                        </Typography>
                        <Button
                            variant="contained"
                            onClick={() => setShowPatientSearchModal(true)}
                            sx={{ mt: 1 }}
                        >
                            Search Patient
                        </Button>
                        {selectedPatient && (
                            <Typography sx={{ mt: 1 }} variant="h6">
                                {selectedPatient.firstName} {selectedPatient.lastName}
                            </Typography>
                        )}
                    </Grid>

                    <Grid item xs={12}>
                        <div className="bg-white p-4 rounded-lg shadow-sm border border-gray-200">
                            <Typography variant="subtitle1" className="font-semibold mb-2">
                                {`Please select which values should be kept or added to ${targetPatientData?.patient?.firstName} ${targetPatientData?.patient?.lastName}`}
                            </Typography>
                            <RadioGroup
                                row
                                value={defaultPatient}
                                onChange={e =>
                                    handleDefaultPatientChange(
                                        e.target.value as 'source' | 'target',
                                    )
                                }
                            >
                                <Typography className="flex items-center pr-4">
                                    Quick Select
                                </Typography>
                                <FormControlLabel
                                    value="target"
                                    control={<Radio color="primary" />}
                                    label={
                                        <div className="flex items-center space-x-2">
                                            <Typography>Destination Profile:</Typography>
                                            <Typography className="font-medium">
                                                {targetPatientData?.patient?.firstName}{' '}
                                                {targetPatientData?.patient?.lastName}
                                            </Typography>
                                        </div>
                                    }
                                />
                                <FormControlLabel
                                    value="source"
                                    control={<Radio color="primary" />}
                                    label={
                                        <div className="flex items-center space-x-2">
                                            <Typography>Additional Source Profile:</Typography>
                                            <Typography className="font-medium">
                                                {selectedPatient?.firstName}{' '}
                                                {selectedPatient?.lastName}
                                            </Typography>
                                        </div>
                                    }
                                />
                            </RadioGroup>
                        </div>
                    </Grid>

                    <Grid item xs={12}>
                        <div className="overflow-x-auto rounded-lg border border-gray-200">
                            <div className="min-w-full divide-y divide-gray-200">
                                <div className="bg-gray-50">
                                    <div className="grid grid-cols-4 gap-2 px-2 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                                        <div>Attribute</div>
                                        <div>Destination Value</div>
                                        <div>Additional Source Value</div>
                                        <div>Custom</div>
                                    </div>
                                </div>
                                <div className="bg-white divide-y divide-gray-200">
                                    {mergeRows.map((row, index) => {
                                        if (row.attribute === 'Patient Attributes') {
                                            return (
                                                <MergeSection
                                                    key="patient-attributes"
                                                    title="Patient Attributes"
                                                    expanded={expandedSections.patientAttributes}
                                                    onToggle={() =>
                                                        setExpandedSections(prev => ({
                                                            ...prev,
                                                            patientAttributes:
                                                                !prev.patientAttributes,
                                                        }))
                                                    }
                                                    groupSelection={
                                                        groupSelections['Patient Attributes'] ||
                                                        'target'
                                                    }
                                                    onGroupSelectionChange={value =>
                                                        handleGroupSelectionChange(
                                                            'Patient Attributes',
                                                            value,
                                                        )
                                                    }
                                                >
                                                    {mergeRows
                                                        .slice(index + 1)
                                                        .filter(r => r.isPatientAttribute)
                                                        .map((attrRow, offset) => {
                                                            const originalIndex =
                                                                index + 1 + offset;
                                                            return (
                                                                <FormControl className="w-full p-0">
                                                                    <RadioGroup
                                                                        row
                                                                        value={
                                                                            attrRow.selectedOption
                                                                        }
                                                                        onChange={e =>
                                                                            handleOptionChange(
                                                                                originalIndex,
                                                                                e.target.value as
                                                                                    | 'source'
                                                                                    | 'target'
                                                                                    | 'custom',
                                                                            )
                                                                        }
                                                                    >
                                                                        <div
                                                                            key={attrRow.attribute}
                                                                            className="grid grid-cols-4 w-full gap-2 px-2 py-4 items-center"
                                                                        >
                                                                            <div className="text-sm font-medium text-gray-900 whitespace-normal">
                                                                                {attrRow.attributeDisplay ||
                                                                                    attrRow.attribute}
                                                                            </div>
                                                                            <div className="text-sm text-gray-500 whitespace-normal break-words truncate">
                                                                                <FormControlLabel
                                                                                    value="target"
                                                                                    control={
                                                                                        <Radio size="small" />
                                                                                    }
                                                                                    label={
                                                                                        attrRow.attribute ===
                                                                                            'Profile Values' ||
                                                                                        attrRow.attribute ===
                                                                                            'Facade Collections'
                                                                                            ? ''
                                                                                            : displayAttribute(
                                                                                                  attrRow,
                                                                                                  attrRow.targetValue,
                                                                                              )
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="text-sm text-gray-500 whitespace-normal break-words truncate">
                                                                                <FormControlLabel
                                                                                    value="source"
                                                                                    control={
                                                                                        <Radio size="small" />
                                                                                    }
                                                                                    label={
                                                                                        attrRow.attribute ===
                                                                                            'Profile Values' ||
                                                                                        attrRow.attribute ===
                                                                                            'Facade Collections'
                                                                                            ? ''
                                                                                            : displayAttribute(
                                                                                                  attrRow,
                                                                                                  attrRow.sourceValue,
                                                                                              )
                                                                                    }
                                                                                    disabled={
                                                                                        attrRow.attribute ===
                                                                                        'ID'
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="text-sm text-gray-900 whitespace-normal">
                                                                                <FormControlLabel
                                                                                    value="custom"
                                                                                    control={
                                                                                        <Radio size="small" />
                                                                                    }
                                                                                    label="Custom"
                                                                                />
                                                                                {renderCustomInput({
                                                                                    row: attrRow,
                                                                                    index: originalIndex,
                                                                                    handleFinalValueChange,
                                                                                    profileDefsData,
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            );
                                                        })}
                                                </MergeSection>
                                            );
                                        }
                                        if (row.attribute === 'Profile Values') {
                                            return (
                                                <MergeSection
                                                    key="profile-values"
                                                    title="Profile Values"
                                                    expanded={expandedSections.profileValues}
                                                    onToggle={() =>
                                                        setExpandedSections(prev => ({
                                                            ...prev,
                                                            profileValues: !prev.profileValues,
                                                        }))
                                                    }
                                                    groupSelection={
                                                        groupSelections['Profile Values'] ||
                                                        'target'
                                                    }
                                                    onGroupSelectionChange={value =>
                                                        handleGroupSelectionChange(
                                                            'Profile Values',
                                                            value,
                                                        )
                                                    }
                                                >
                                                    {mergeRows
                                                        .slice(index + 1)
                                                        .filter(r => r.isProfileValue)
                                                        .map((profileRow, offset) => {
                                                            const profileDef =
                                                                profileDefsData?.userProfileDefsV2.results.find(
                                                                    pd =>
                                                                        pd.id ===
                                                                        profileRow.attribute,
                                                                );
                                                            let { sourceValue } = profileRow;
                                                            let { targetValue } = profileRow;
                                                            if (
                                                                profileDef?.valueType === 'choice'
                                                            ) {
                                                                sourceValue =
                                                                    profileDef.choices?.find(
                                                                        choice =>
                                                                            choice?.id ===
                                                                            profileRow.sourceValue,
                                                                    )?.label?.en || null;
                                                                targetValue =
                                                                    profileDef.choices?.find(
                                                                        choice =>
                                                                            choice?.id ===
                                                                            profileRow.targetValue,
                                                                    )?.label?.en || null;
                                                            }
                                                            const originalIndex =
                                                                index + 1 + offset;
                                                            return (
                                                                <FormControl className="w-full p-0">
                                                                    <RadioGroup
                                                                        row
                                                                        value={
                                                                            profileRow.selectedOption
                                                                        }
                                                                        onChange={e =>
                                                                            handleOptionChange(
                                                                                originalIndex,
                                                                                e.target.value as
                                                                                    | 'source'
                                                                                    | 'target'
                                                                                    | 'custom',
                                                                            )
                                                                        }
                                                                    >
                                                                        <div
                                                                            key={
                                                                                profileRow.attribute
                                                                            }
                                                                            className="grid w-full grid-cols-4 gap-2 px-2 py-4 items-center"
                                                                        >
                                                                            <div className="text-sm font-medium text-gray-900 whitespace-normal">
                                                                                {profileRow.attributeDisplay ||
                                                                                    profileRow.attribute}
                                                                            </div>
                                                                            <div className="text-sm text-gray-500 whitespace-normal break-words truncate">
                                                                                <FormControlLabel
                                                                                    value="target"
                                                                                    control={
                                                                                        <Radio size="small" />
                                                                                    }
                                                                                    label={
                                                                                        targetValue !=
                                                                                            null &&
                                                                                        profileDef?.valueType ===
                                                                                            UserProfileValueType.Date
                                                                                            ? displayDateWithLocalTimeZoneAbbr(
                                                                                                  {
                                                                                                      isoDateStr:
                                                                                                          targetValue,
                                                                                                  },
                                                                                              )
                                                                                            : targetValue
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="text-sm text-gray-500 whitespace-normal break-words truncate">
                                                                                <FormControlLabel
                                                                                    value="source"
                                                                                    control={
                                                                                        <Radio size="small" />
                                                                                    }
                                                                                    label={
                                                                                        sourceValue !=
                                                                                            null &&
                                                                                        profileDef?.valueType ===
                                                                                            UserProfileValueType.Date
                                                                                            ? displayDateWithLocalTimeZoneAbbr(
                                                                                                  {
                                                                                                      isoDateStr:
                                                                                                          sourceValue,
                                                                                                  },
                                                                                              )
                                                                                            : sourceValue
                                                                                    }
                                                                                />
                                                                            </div>
                                                                            <div className="text-sm text-gray-900 whitespace-normal">
                                                                                <FormControlLabel
                                                                                    value="custom"
                                                                                    control={
                                                                                        <Radio size="small" />
                                                                                    }
                                                                                    label="Custom"
                                                                                />
                                                                                {renderCustomInput({
                                                                                    row: profileRow,
                                                                                    index: originalIndex,
                                                                                    handleFinalValueChange,
                                                                                    profileDefsData,
                                                                                })}
                                                                            </div>
                                                                        </div>
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            );
                                                        })}
                                                </MergeSection>
                                            );
                                        }
                                        if (row.attribute === 'Facade Collections') {
                                            return (
                                                <MergeSection
                                                    key="facade-collections"
                                                    title="Facade Collections"
                                                    expanded={expandedSections.facadeCollections}
                                                    onToggle={() =>
                                                        setExpandedSections(prev => ({
                                                            ...prev,
                                                            facadeCollections:
                                                                !prev.facadeCollections,
                                                        }))
                                                    }
                                                    groupSelection={
                                                        groupSelections['Facade Collections'] ||
                                                        'merge'
                                                    }
                                                    onGroupSelectionChange={value =>
                                                        handleGroupSelectionChange(
                                                            'Facade Collections',
                                                            value,
                                                        )
                                                    }
                                                    groupType="merge"
                                                >
                                                    {mergeRows
                                                        .slice(index + 1)
                                                        .filter(r => r.isFacadeCollection)
                                                        .map((facadeRow, offset) => {
                                                            const originalIndex =
                                                                index + 1 + offset;
                                                            return (
                                                                <FormControl className="w-full p-0">
                                                                    <RadioGroup
                                                                        row
                                                                        value={
                                                                            facadeRow.selectedOption
                                                                        }
                                                                        onChange={e =>
                                                                            handleOptionChange(
                                                                                originalIndex,
                                                                                e.target.value as
                                                                                    | 'merge'
                                                                                    | 'donotmerge',
                                                                            )
                                                                        }
                                                                    >
                                                                        <div
                                                                            key={
                                                                                facadeRow.attribute
                                                                            }
                                                                            className="w-full grid grid-cols-4 gap-2 px-2 py-4 items-center"
                                                                        >
                                                                            <div className="text-sm font-medium col-span-1 text-gray-900 whitespace-normal">
                                                                                {
                                                                                    facadeRow.attributeDisplay
                                                                                }
                                                                            </div>
                                                                            <div className="text-sm text-gray-500 col-span-1 whitespace-normal">
                                                                                <FormControlLabel
                                                                                    value="merge"
                                                                                    control={
                                                                                        <Radio size="small" />
                                                                                    }
                                                                                    label="Merge"
                                                                                />
                                                                            </div>
                                                                            <div className="text-sm text-gray-500 col-span-1 whitespace-normal">
                                                                                <FormControlLabel
                                                                                    value="donotmerge"
                                                                                    control={
                                                                                        <Radio size="small" />
                                                                                    }
                                                                                    label="Don't Merge"
                                                                                />
                                                                            </div>
                                                                            <div className="text-sm text-gray-900 col-span-1 whitespace-normal">
                                                                                {/* empty */}
                                                                            </div>
                                                                        </div>
                                                                    </RadioGroup>
                                                                </FormControl>
                                                            );
                                                        })}
                                                </MergeSection>
                                            );
                                        }

                                        if (
                                            row.isPatientAttribute ||
                                            row.isProfileValue ||
                                            row.isFacadeCollection
                                        ) {
                                            return null;
                                        }

                                        return (
                                            <FormControl className="w-full p-0">
                                                <RadioGroup
                                                    row
                                                    value={row.selectedOption}
                                                    onChange={e =>
                                                        handleOptionChange(
                                                            index,
                                                            e.target.value as
                                                                | 'source'
                                                                | 'target'
                                                                | 'custom',
                                                        )
                                                    }
                                                >
                                                    <div
                                                        key={row.attribute}
                                                        className="grid grid-cols-4 gap-2 px-2 py-4 items-center w-full"
                                                    >
                                                        <div className="text-sm font-medium text-gray-900 whitespace-normal">
                                                            {row.attributeDisplay || row.attribute}
                                                        </div>
                                                        <div className="text-sm text-gray-500 whitespace-normal truncate">
                                                            <FormControlLabel
                                                                value="target"
                                                                control={<Radio size="small" />}
                                                                label={row.targetValue}
                                                            />
                                                        </div>
                                                        <div className="text-sm text-gray-500 whitespace-normal truncate">
                                                            <FormControlLabel
                                                                value="source"
                                                                control={<Radio size="small" />}
                                                                label={row.sourceValue}
                                                                disabled={row.attribute === 'ID'}
                                                            />
                                                        </div>
                                                        <div className="text-sm text-gray-900 whitespace-normal">
                                                            {row.attribute !== 'ID' && (
                                                                <>
                                                                    <FormControlLabel
                                                                        value="custom"
                                                                        control={
                                                                            <Radio size="small" />
                                                                        }
                                                                        label="Custom"
                                                                    />
                                                                    {renderCustomInput({
                                                                        row,
                                                                        index,
                                                                        handleFinalValueChange,
                                                                        profileDefsData,
                                                                    })}
                                                                </>
                                                            )}
                                                        </div>
                                                    </div>
                                                </RadioGroup>
                                            </FormControl>
                                        );
                                    })}
                                </div>
                            </div>
                        </div>
                    </Grid>

                    <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => setShowConfirmDialog(true)}
                            disabled={!sourcePatientId}
                        >
                            Merge Patients
                        </Button>
                    </Grid>
                </Grid>
            </Card>

            <Dialog
                open={showPatientSearchModal}
                onClose={() => setShowPatientSearchModal(false)}
                maxWidth="md"
                fullWidth
            >
                <DialogTitle>Search Source Patient</DialogTitle>
                <DialogContent>
                    <PatientSearchModal
                        patientsList={patientsList}
                        totalPatientsCount={totalPatientsCount}
                        selectedPatient={selectedPatient}
                        setPatientsList={setPatientsList}
                        setShowPatientSearchModal={setShowPatientSearchModal}
                        setTotalPatientsCount={setTotalPatientsCount}
                        setSelectedPatient={(patient: Patient | null) => {
                            setSelectedPatient(patient);
                            if (patient) {
                                handleSourcePatientSelect(patient);
                                setShowPatientSearchModal(false);
                            }
                        }}
                        patientsNotFound={patientsNotFound}
                        setPatientsNotFound={setPatientsNotFound}
                    />
                </DialogContent>
            </Dialog>

            <Dialog
                open={showConfirmDialog}
                onClose={() => setShowConfirmDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle className="text-lg font-semibold">Confirm Patient Merge</DialogTitle>
                <DialogContent>
                    <div className="p-4 space-y-6">
                        <Typography variant="body1" className="text-gray-700">
                            Merging patients will permanently delete the source patient (
                            {selectedPatient?.firstName} {selectedPatient?.lastName}) and all
                            associated data. This action cannot be undone.
                        </Typography>
                        <div className="flex justify-end space-x-3">
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setShowConfirmDialog(false)}
                                className="px-4 py-2"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleMerge}
                                className="px-4 py-2"
                            >
                                Confirm Merge
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>

            <Dialog
                open={showRedoxConnectionMovedDialog}
                onClose={() => setShowRedoxConnectionMovedDialog(false)}
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle className="text-lg font-semibold">
                    Confirm Profile Variables Set
                </DialogTitle>
                <DialogContent>
                    <div className="p-4 space-y-6">
                        <Typography variant="body1" className="text-gray-700">
                            Redox Connection moves from the source patient to the target patient.
                            Set profile variables is_user_VBC_eligible and
                            patient_created_by_external_data_source_request to true for the target
                            patient?
                        </Typography>
                        <div className="flex justify-end space-x-3">
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setShowRedoxConnectionMovedDialog(false)}
                                className="px-4 py-2"
                            >
                                Cancel
                            </Button>
                            <Button
                                variant="contained"
                                color="primary"
                                onClick={handleMoveRedoxConnection}
                                className="px-4 py-2"
                            >
                                Set PVs
                            </Button>
                        </div>
                    </div>
                </DialogContent>
            </Dialog>
        </>
    );
};

export default MergePatient;
