import * as Yup from 'yup';
import moment from 'moment';
import { AwayModeStatus } from './enums';

export const AWAY_MODE_FORM_SCHEMA = () =>
    Yup.object().shape({
        startDate: Yup.date()
            .typeError('Invalid date')
            .dateFormatVCF()
            .when(['$startDateHidden'], ([$startDateHidden], schema) => {
                if (!$startDateHidden) {
                    return schema.min(
                        new Date(moment().subtract(1, 'day').startOf('day').toDate()),
                        'Please enter future date',
                    );
                }
                return schema;
            }),
        endDate: Yup.date()
            .typeError('Invalid date')
            .dateFormatVCF()
            .when(['startDate', '$startDateHidden'], ([startDate, $startDateHidden], schema) => {
                if ($startDateHidden) {
                    return schema.min(
                        new Date(moment().subtract(1, 'day').startOf('day').toDate()),
                        'Please enter future date',
                    );
                }
                if (Number.isInteger(startDate.getTime())) {
                    return schema.min(
                        new Date(startDate.getTime() - 1),
                        "Last Day Away can't be before First Day Away",
                    );
                }
                return schema;
            }),
        mode: Yup.number().oneOf([
            AwayModeStatus.off,
            AwayModeStatus.vacation,
            AwayModeStatus.scheduled,
        ]),
    });
