import { useCallback } from 'react';
import { PortalVcUserType } from '~/schemaTypes';
import { VCRouteSuffixMap } from '~/components/PrivateRoute/PrivateRoute';
import useUser from './useUser';

const useVcUserView = () => {
    const currentUser = useUser();
    const isDoulaView = currentUser.data?.currentUser?.vcType === PortalVcUserType.Doula;
    const isLcView = currentUser.data?.currentUser?.vcType === PortalVcUserType.LactationConsultant;
    const getUrlWithViewPrefix = useCallback(
        (pathname: string) => {
            if (isDoulaView) {
                return `${VCRouteSuffixMap.get(PortalVcUserType.Doula)}${pathname}`;
            }
            if (isLcView) {
                return `${VCRouteSuffixMap.get(PortalVcUserType.LactationConsultant)}${pathname}`;
            }
            return pathname;
        },
        [isLcView, isDoulaView],
    );
    const getPathname = useCallback(
        (url: string) => {
            if (isDoulaView) {
                return url.replace(VCRouteSuffixMap.get(PortalVcUserType.Doula) || '', '');
            }
            if (isLcView) {
                return url.replace(
                    VCRouteSuffixMap.get(PortalVcUserType.LactationConsultant) || '',
                    '',
                );
            }
            return url;
        },
        [isLcView, isDoulaView],
    );
    return {
        isDoulaView,
        isLcView,
        isVcUser: isDoulaView || isLcView,
        getUrlWithViewPrefix,
        getPurePathname: getPathname,
        userId: currentUser.data?.currentUser?.id,
    };
};

export default useVcUserView;
