import React from 'react';
import { Box, Grid, Typography } from '@mui/material';
import { EligibilityRefreshOrigin, PatientInsurancePlan } from '~/schemaTypes';
import {
    getActiveInsurancePlanData,
    isEligibleForLactationServices,
    isAnthemInsurancePlan,
} from '~/utils/lactationServicesEligibility';
import RecheckEligibilityButton from '~/views/AffiliateCare/components/RecheckEligibilityButton/RecheckEligibilityButton';
import { VCFContext, useInitVCF } from '~/views/VirtualCareSurvey/hooks/useVCF';

type CoverageExpirationProps = {
    insurancePlans?: (PatientInsurancePlan | null)[];
    patientId: string;
    isDoulaView?: boolean;
    isAffiliateView?: boolean;
    affiliateId?: string;
    origin: EligibilityRefreshOrigin;
};

const CoverageExpiration: React.FC<CoverageExpirationProps> = props => {
    const {
        insurancePlans,
        patientId,
        affiliateId,
        isDoulaView = false,
        isAffiliateView = true,
        origin,
    } = props;
    const activeInsurancePlan = getActiveInsurancePlanData(insurancePlans);
    const isEligible = isEligibleForLactationServices(activeInsurancePlan);
    const vcfProps = useInitVCF({ isDoulaView, isAffiliateView });
    const showRecheckButton = !isAnthemInsurancePlan(insurancePlans);
    if (!activeInsurancePlan) {
        return (
            <VCFContext.Provider value={vcfProps}>
                <Grid container sx={{ paddingLeft: 2 }} justifyContent="center">
                    <RecheckEligibilityButton
                        origin={origin}
                        patientId={patientId}
                        affiliateId={affiliateId}
                    />
                </Grid>
            </VCFContext.Provider>
        );
    }
    if (isEligible) {
        return (
            <Typography textAlign="center" sx={{ paddingLeft: 2 }}>
                {activeInsurancePlan.eligibilityEndDate}
            </Typography>
        );
    }

    return (
        <Box
            sx={{
                backgroundColor: '#f2dada',
                alignItems: 'center',
                justifyContent: 'center',
                display: 'flex',
                minHeight: 86,
                height: '100%',
                paddingRight: 1,
            }}
        >
            <VCFContext.Provider value={vcfProps}>
                {showRecheckButton && (
                    <RecheckEligibilityButton
                        origin={origin}
                        patientId={patientId}
                        affiliateId={affiliateId}
                    />
                )}
                <Typography>{activeInsurancePlan.eligibilityEndDate}</Typography>
            </VCFContext.Provider>
        </Box>
    );
};

export default CoverageExpiration;
