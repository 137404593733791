import { useCallback } from 'react';
import {
    FetchUsersForStaffPageDocument,
    FetchUsersForStaffPageQuery,
    User,
    GetUsersByOrgInput,
    PortalVcUserType,
} from '~/schemaTypes';
import { ApolloCache } from '@apollo/client';

const useUpdateCache = (currentOrgId: string, VCType?: PortalVcUserType | '', isVCView = false) => {
    const getCache = useCallback(
        (cache: ApolloCache<FetchUsersForStaffPageQuery>, input?: Partial<GetUsersByOrgInput>) => {
            return cache.readQuery<FetchUsersForStaffPageQuery>({
                query: FetchUsersForStaffPageDocument,
                variables: {
                    input: {
                        orgId: currentOrgId,
                        ...(input && { ...input }),
                    },
                },
            });
        },
        [currentOrgId],
    );
    const writeCache = useCallback(
        (
            cache: ApolloCache<FetchUsersForStaffPageQuery>,
            data: User[],
            input?: Partial<GetUsersByOrgInput>,
        ) => {
            cache.writeQuery<FetchUsersForStaffPageQuery>({
                query: FetchUsersForStaffPageDocument,
                data: { getUsersByOrg: data },
                variables: {
                    input: {
                        orgId: currentOrgId,
                        ...(input && { ...input }),
                    },
                },
            });
        },
        [currentOrgId],
    );

    const updateAfterDelete = useCallback(
        (
            cache: ApolloCache<FetchUsersForStaffPageQuery>,
            deleteUserId?: string | null,
            input?: Partial<GetUsersByOrgInput>,
        ) => {
            const staffQuery = getCache(cache, input);
            const filteredUsers = (staffQuery?.getUsersByOrg?.filter(
                user => user?.id !== deleteUserId,
            ) ?? []) as User[];
            if (staffQuery?.getUsersByOrg) {
                writeCache(cache, filteredUsers, input);
            }
        },
        [getCache, writeCache],
    );
    const updateAfterCreate = useCallback(
        (
            cache: ApolloCache<FetchUsersForStaffPageQuery>,
            newUser: User,
            input?: Partial<GetUsersByOrgInput>,
        ) => {
            const currentData = getCache(cache, input)?.getUsersByOrg as User[];
            if (currentData) {
                writeCache(cache, [newUser, ...currentData], input);
            }
        },
        [getCache, writeCache],
    );
    const handleDelete = useCallback(
        (
            cache: ApolloCache<FetchUsersForStaffPageQuery>,
            deleteUserId?: string | null,
            deleteUserVCType?: PortalVcUserType | null,
        ) => {
            updateAfterDelete(cache, deleteUserId);
            if (isVCView && VCType) {
                updateAfterDelete(cache, deleteUserId, { VCType });
            }
            if (isVCView && !VCType) {
                updateAfterDelete(cache, deleteUserId, {
                    VCType: deleteUserVCType || PortalVcUserType.Affiliate,
                });
            }
        },
        [isVCView, VCType, updateAfterDelete],
    );
    const handleCreate = useCallback(
        (
            cache: ApolloCache<FetchUsersForStaffPageQuery>,
            success: boolean,
            newUser?: User | null,
        ) => {
            if (success) {
                if (newUser && newUser.id) {
                    updateAfterCreate(cache, newUser);
                    if (isVCView && VCType) {
                        updateAfterCreate(cache, newUser, { VCType });
                    }
                    if (isVCView && !VCType && newUser.vcType) {
                        updateAfterCreate(cache, newUser, { VCType: newUser.vcType });
                    }
                }
            }
        },
        [isVCView, VCType, updateAfterCreate],
    );
    return { updateAfterDelete: handleDelete, updateAfterCreate: handleCreate };
};

export default useUpdateCache;
