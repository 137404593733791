export const templateTokens = [
    {
        text: 'Patient Name',
        token: '@@PATIENT_NAME@@',
        testId: 'patient-name',
    },
    {
        text: 'Organization Name',
        token: '@@ORGANIZATION_NAME@@',
        testId: 'organization-name',
    },
    {
        text: 'Assigned HA Name',
        token: '@@HA_NAME@@',
        testId: 'ha-name',
    },
    {
        text: 'Current User Name',
        token: '@@CURRENT_USER@@',
        testId: 'user-name',
    },
];
